import * as React from 'react';
import Svg, {Path, Mask} from 'react-native-svg';

interface IProps {
  size?: number;
  color?: string;
}

const BackIcon = ({size = 32, color = '#727179'}: IProps) => (
  <Svg width={size} height={size}>
    <Path
      fill={color}
      fillRule="evenodd"
      d="m6 12.5 10.08-9.917L18 4.472 9.84 12.5 18 20.528l-1.92 1.89L6 12.5Z"
      clipRule="evenodd"
    />
    <Mask
      id="a"
      width={size}
      height={size}
      x={6}
      y={2}
      maskUnits="userSpaceOnUse"
    >
      <Path
        fill={color}
        fillRule="evenodd"
        d="m6 12.5 10.08-9.917L18 4.472 9.84 12.5 18 20.528l-1.92 1.89L6 12.5Z"
        clipRule="evenodd"
      />
    </Mask>
  </Svg>
);
export default BackIcon;
