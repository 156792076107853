import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, Rect} from 'react-native-svg';

interface IProps {
  color?: string;
  width?: number;
  height?: number;
}
const EditLocation = ({width = 17, height = 16, color = '#066DFF'}: IProps) => (
  <Svg width={width} height={height} viewBox="0 0 17 16" fill="none">
    <G clipPath="url(#clip0_7343_782)">
      <Path
        d="M15.0349 1.41561L15.413 1.79374C15.8036 2.18436 15.8036 2.81874 15.413 3.20936L14.5786 4.04374L12.7849 2.24999L13.6192 1.41561C14.0099 1.02499 14.6442 1.02499 15.0349 1.41561ZM6.73486 8.29999L12.0786 2.95624L13.8724 4.74999L8.52861 10.0937C8.39736 10.225 8.22861 10.3187 8.04736 10.3625L5.99736 10.8344L6.46924 8.78124C6.50986 8.59999 6.60361 8.43124 6.73799 8.29999H6.73486ZM12.913 0.706238L6.02861 7.59374C5.76299 7.85936 5.57861 8.19374 5.49424 8.55936L4.84111 11.3906C4.80361 11.5594 4.85361 11.7344 4.97549 11.8562C5.09736 11.9781 5.27236 12.0281 5.44111 11.9906L8.27236 11.3375C8.63799 11.2531 8.97236 11.0687 9.23799 10.8031L16.1224 3.91561C16.9036 3.13436 16.9036 1.86874 16.1224 1.08749L15.7442 0.706238C14.963 -0.0750122 13.6974 -0.0750122 12.9161 0.706238H12.913ZM3.32861 1.99999C1.94736 1.99999 0.828613 3.11874 0.828613 4.49999V13.5C0.828613 14.8812 1.94736 16 3.32861 16H12.3286C13.7099 16 14.8286 14.8812 14.8286 13.5V9.49999C14.8286 9.22499 14.6036 8.99999 14.3286 8.99999C14.0536 8.99999 13.8286 9.22499 13.8286 9.49999V13.5C13.8286 14.3281 13.1567 15 12.3286 15H3.32861C2.50049 15 1.82861 14.3281 1.82861 13.5V4.49999C1.82861 3.67186 2.50049 2.99999 3.32861 2.99999H7.32861C7.60361 2.99999 7.82861 2.77499 7.82861 2.49999C7.82861 2.22499 7.60361 1.99999 7.32861 1.99999H3.32861Z"
        fill={color}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_7343_782">
        <Rect
          width={16}
          height={16}
          fill="white"
          transform="translate(0.828613)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);
export default EditLocation;
