import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IProps {
  color?: string;
  size?: number;
}
const DiningIcon = ({color = '#22212D', size = 32}: IProps) => (
  <Svg fill="none" height={size} width={size}>
    <Path
      fill={color}
      clipRule="evenodd"
      fillRule="evenodd"
      d="m26.807 12.683-.003 3.288c0 1.118-.007 2.235-.015 3.352-.017 2.626-.034 5.252.043 7.875.063 2.103-1.1 2.099-2.339 2.094-.253 0-.509-.002-.758.015-1.63.111-2.572-.39-2.323-2.21.031-.222.064-.445.098-.668.1-.667.2-1.334.224-2.004.013-.382.033-.76.052-1.136.14-2.687.272-5.223-1.923-7.816-3.182-3.759-.403-10.762 4.322-12.52 1.825-.679 2.596-.178 2.61 1.79.02 2.647.017 5.293.012 7.94Zm-13.314-8.6c.002-.692.242-1.24.996-1.358.691-.108 1.3.112 1.438.853.07.375.145.75.219 1.124.435 2.202.871 4.41.551 6.675-.157 1.11-.566 2.384-1.506 2.87-2.034 1.056-1.907 2.66-1.757 4.489.13 1.595.202 3.196.274 4.796.056 1.232.111 2.463.193 3.693.103 1.536-.533 2.196-2.052 2.074a7.587 7.587 0 0 0-1.453.003c-1.866.212-2.45-.655-2.322-2.435.118-1.638.193-3.278.269-4.92.077-1.674.154-3.349.276-5.02l.001-.02c.055-.757.094-1.292-.658-1.7-2.318-1.257-2.957-3.386-2.765-5.81.088-1.107.264-2.207.44-3.307.109-.672.216-1.344.304-2.018.116-.89.5-1.46 1.458-1.358 1.014.107 1.097.883 1.095 1.694-.005 1.512-.004 3.024-.003 4.536v1.815l.726.03c.048-.585.098-1.17.147-1.754.123-1.44.245-2.88.352-4.321.009-.118.014-.238.018-.359.033-.796.065-1.598 1.207-1.635 1.207-.039 1.261.82 1.315 1.678l.014.219c.107 1.459.236 2.916.364 4.373l.156 1.78c.754-.032.72-.471.692-.853a2.47 2.47 0 0 1-.01-.222c.023-1.324.022-2.648.02-3.972l.001-1.64Z"
    />
  </Svg>
);
export default DiningIcon;
