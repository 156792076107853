import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

interface IProps {
  width?: number;
  height?: number;
  color?: string;
}

function ScanProductBarCode(props: IProps) {
  return (
    <Svg
      width={props.width ?? 27}
      height={props.height ?? 24}
      viewBox="0 0 27 24"
      fill="none"
    >
      <G clipPath="url(#clip0_9550_9942)">
        <Path
          d="M2.25 1.5a.752.752 0 00-.75.75v4.5c0 .412-.337.75-.75.75A.752.752 0 010 6.75v-4.5A2.25 2.25 0 012.25 0h4.5c.412 0 .75.338.75.75 0 .413-.338.75-.75.75h-4.5zM6.75 6c.412 0 .75.338.75.75v10.5c0 .413-.338.75-.75.75a.752.752 0 01-.75-.75V6.75c0-.412.338-.75.75-.75zM9 6.75c0-.412.338-.75.75-.75s.75.338.75.75v10.5c0 .413-.338.75-.75.75a.752.752 0 01-.75-.75V6.75zm10.5 0c0-.412.337-.75.75-.75s.75.338.75.75v10.5c0 .413-.337.75-.75.75a.752.752 0 01-.75-.75V6.75zM12.75 6c.412 0 .75.338.75.75v10.5c0 .413-.338.75-.75.75a.752.752 0 01-.75-.75V6.75c0-.412.338-.75.75-.75zm3 .75c0-.412.337-.75.75-.75s.75.338.75.75v10.5c0 .413-.337.75-.75.75a.752.752 0 01-.75-.75V6.75zm9.75-4.5a.752.752 0 00-.75-.75h-4.5a.752.752 0 01-.75-.75c0-.412.337-.75.75-.75h4.5A2.25 2.25 0 0127 2.25v4.5c0 .412-.337.75-.75.75a.752.752 0 01-.75-.75v-4.5zM2.25 22.5h4.5c.412 0 .75.337.75.75s-.338.75-.75.75h-4.5A2.25 2.25 0 010 21.75v-4.5c0-.413.338-.75.75-.75.413 0 .75.337.75.75v4.5c0 .413.337.75.75.75zm23.25-.75v-4.5c0-.413.337-.75.75-.75s.75.337.75.75v4.5A2.25 2.25 0 0124.75 24h-4.5a.752.752 0 01-.75-.75c0-.413.337-.75.75-.75h4.5c.413 0 .75-.337.75-.75z"
          fill={props.color ?? '#fff'}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_9550_9942">
          <Path fill={props.color ?? '#fff'} d="M0 0H27V24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default ScanProductBarCode;
