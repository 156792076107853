import * as React from 'react';
import {View} from 'react-native';
import Svg, {Path, Circle, Polygon} from 'react-native-svg';

export const DietaryHealthGood = ({width = 25, height = 25}) => (
  <View testID="healthgood" style={{marginTop: -6, marginLeft: 5}}>
    <Svg viewBox="0 0 640 640" width={width} height={height}>
      <Path
        d="m640,320c0,176.73-143.27,320-320,320S0,496.73,0,320,143.27,0,320,0s320,143.27,320,320"
        fill="#00854d"
        fillRule="evenodd"
      />
      <Circle cx="320" cy="320" r="224" fill="#ffffff" />
      <Path
        d="m430.79,421.47c-10.07,12.06-24.31,21.42-42.71,28.07-18.41,6.65-38.8,9.98-61.18,9.98-23.5,
        0-44.11-5.13-61.83-15.39-17.72-10.26-31.4-25.15-41.04-44.67-9.64-19.52-14.58-42.46-14.83-68.83v-18.47c0-27.11,
        4.57-50.58,13.71-70.41,9.14-19.83,22.32-35,39.54-45.51,17.22-10.51,37.4-15.76,60.53-15.76,32.2,0,57.39,7.68,
        75.54,23.03,18.15,15.36,28.91,37.71,32.27,67.06h-54.46c-2.49-15.54-7.99-26.92-16.51-34.13-8.52-7.21-20.24-10.82-35.16-10.82-19.03,
        0-33.51,7.15-43.46,21.45-9.95,14.3-14.99,35.56-15.11,63.79v17.35c0,28.48,5.41,49.99,16.23,64.54,10.82,14.55,
        26.67,21.82,47.56,21.82s36-4.48,44.95-13.43v-46.82h-50.92v-41.22h106.88v108.37Z"
        fill="#000000"
        fillRule="evenodd"
      />
    </Svg>
  </View>
);

export const DietaryHealthWarning = ({width = 25, height = 25}) => (
  <View testID="healthwarning" style={{marginTop: -6, marginLeft: 5}}>
    <Svg viewBox="0 0 640 640" width={width} height={height}>
      <Path
        d="m640,320c0,176.73-143.27,320-320,320S0,496.73,0,320,143.27,0,320,0s320,143.27,320,320"
        fill="#ffd32e"
        fillRule="evenodd"
      />
      <Circle cx="320" cy="320" r="224" fill="#ffffff" />
      <Polygon
        points="319.91 306.57 376.42 184.21 437.6 184.21 348.45 357.3 348.45 455.79 291.56 455.79 291.56 357.3 202.4 184.21 263.76 184.21 319.91 306.57"
        fill="#000000"
        fillRule="evenodd"
      />
    </Svg>
  </View>
);

export const DietaryHealthAlert = ({width = 25, height = 25}) => (
  <View testID="healthAlert" style={{marginTop: -6, marginLeft: 5}}>
    <Svg viewBox="0 0 640 640" width={width} height={height}>
      <Path
        d="m640,320c0,176.73-143.27,320-320,320S0,496.73,0,320,143.27,0,320,0s320,143.27,320,320"
        fill="#eb0014"
        fillRule="evenodd"
      />
      <Circle cx="320" cy="320" r="224" fill="#ffffff" />
      <Path
        d="m367,342.2c16.29-7.33,28.44-17.19,36.47-29.56,8.02-12.37,12.03-27.82,12.03-46.35,
        0-26.11-8.71-46.32-26.11-60.62-17.41-14.3-42.15-21.45-74.24-21.45h-100.91v271.58h55.96v-99.42h44.58l50.92,
        99.42h60.06v-2.61l-58.75-110.98Zm-19.03-41.87c-7.71,7.15-18.59,10.73-32.64,10.73h-45.14v-81.51h44.95c15.04,
        0,26.21,3.67,33.48,11,7.28,7.34,10.91,17.41,10.91,30.22s-3.86,22.42-11.56,29.56Z"
        fill="#000000"
        fillRule="evenodd"
      />
    </Svg>
  </View>
);
