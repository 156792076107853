import React from 'react';
import {View, StyleSheet} from 'react-native';
import Styles from 'src/components/Styles';
import RecentOrdersButton from './RecentOrdersButton';
import CustomSearchbar from 'src/components/elements/CustomSearchField';
import SearchIcon from 'src/components/img/svg/SearchIcon';
import {getDescriber} from 'src/components/elements/orderAhead/descriptor/DescriptorType';

const {getMenuListCompnentsFontFamily} = getDescriber();

type SearchContainerProps = {
  onChangeText: (text: string) => void;
  searchValue: string;
  placeholder?: string;
  onPressRecentOrders: () => void;
  showRecentOrdersButton?: boolean;
};

const ProductSearchContainer: React.FC<SearchContainerProps> = ({
  onChangeText,
  searchValue,
  placeholder = 'Search Products',
  onPressRecentOrders,
}) => {
  return (
    <>
      <CustomSearchbar
        onChangeText={onChangeText}
        value={searchValue}
        placeholder={placeholder}
        containerStyle={styles.searchbar}
        elevation={1}
        inputStyle={{
          color: Styles.Colors.PayNew.darkHuesBase04,
          fontSize: Styles.Fonts.f7,
          fontFamily: getMenuListCompnentsFontFamily(),
        }}
        searchIcon={<SearchIcon />}
      />

      <View style={styles.buttonView}>
        <RecentOrdersButton onPress={onPressRecentOrders} />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  searchbar: {
    width: '63%',
    borderRadius: 32,
    backgroundColor: 'white',
    height: 51,
    borderWidth: 1,
    borderColor: Styles.Colors.PayNew.neutralHuesBase09,
    alignItems: 'center',
  },
  buttonView: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    flex: 1,
  },
});

export default ProductSearchContainer;
