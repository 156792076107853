import React from 'react';
import {StyleSheet, View, FlatList} from 'react-native';
import Styles from '../../Styles';
import {CampusLocationType} from 'src/components/elements/home/CampusLocationView';
import {IsConnectedProps} from 'src/types/Screen';
import ScanAndPayLocationView from 'src/components/elements/home/ScanAndPayLocationView';
import {getDescriber} from './descriptor/DescriptorType';

type ScanAndPayLocationsListProps = IsConnectedProps & {
  locations: CampusLocationType[];
  onLocationClick: (
    machine: CampusLocationType,
    autoAdvanced?: boolean,
  ) => Promise<void>;
  scrollEnabled?: boolean;
};

const {getScanAndPayTile} = getDescriber();

const ScanAndPayLocationsList = (props: ScanAndPayLocationsListProps) => {
  //Returns Scan And Pay Location Item UI
  const renderItem = ({item}: {item: CampusLocationType}) => {
    return (
      <ScanAndPayLocationView
        location={item}
        onLocationClick={props.onLocationClick}
      />
    );
  };

  return (
    <View style={[styles.container, getScanAndPayTile()['listContainer']]}>
      <FlatList
        renderItem={renderItem}
        style={styles.scanAndPayLocationListView}
        scrollEnabled={props.scrollEnabled ?? false}
        data={props.locations}
        keyExtractor={(item, index) => item?.locationId + index}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    marginBottom: Styles.Spacing.m1,
    justifyContent: 'flex-start',
  },
  scanAndPayLocationListView: {
    width: '100%',
  },
});
export default ScanAndPayLocationsList;
