import React from 'react';
import {StyleSheet, View, FlatList, Dimensions} from 'react-native';
import Localized from 'src/constants/AppStrings';
import Styles from '../../Styles';
import {CampusLocationType} from 'src/components/elements/home/CampusLocationView';
import {IsConnectedProps} from 'src/types/Screen';
import VendingLocationView from 'src/components/elements/home/VendingLocationView';
import AVText from 'src/components/elements/AVText';
import {MachineType} from 'src/types/MachineType';

const width = Dimensions.get('window').width;

type VendingLocationListProps = IsConnectedProps & {
  locations: CampusLocationType[];
  onLocationClick: (machine, autoAdvanced?: boolean) => Promise<void>;
  onPlanogramClick?: (machine: MachineType) => void;
};

const VendingLocationList = (props: VendingLocationListProps) => {
  const renderItem = ({item}) => {
    return (
      <VendingLocationView
        location={item}
        onLocationClick={props.onLocationClick}
        onPlanogramClick={props.onPlanogramClick}
      />
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.headerView}>
        <AVText
          style={styles.headerText}
          accessible={true}
          accessibilityLabel={Localized.Labels.vending}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.vending}, text`}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
        >
          {Localized.Labels.vending}
        </AVText>
      </View>
      <FlatList
        showsHorizontalScrollIndicator={false}
        horizontal={true}
        renderItem={renderItem}
        style={styles.campusLocationListView}
        data={props.locations}
        keyExtractor={(item, index) =>
          Localized.Labels.vending + item?.locationId + index
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: Styles.Spacing.m3,
    flex: 1,
  },
  headerView: {
    width: width,
    marginLeft: Styles.Spacing.m3,
  },
  headerText: {
    color: Styles.black,
    fontSize: Styles.Fonts.headerFont,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.comodoVintage,
  },
  campusLocationListView: {},
});
export default VendingLocationList;
