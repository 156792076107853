import React from 'react';
import {View} from 'react-native';
import AVText from '../../AVText';
import AVFormattedCurrency from '../../AVFormattedCurrency';
import {formatCurrency} from 'src/components/helpers/HistoryHelper';
import Styles from 'src/components/Styles';
import Settings from 'src/Settings';

import {getDescriber} from '../descriptor/DescriptorType';
type BottleDepositComponentProps = {
  depositAmount: string | number;
  currency?: string;
  bottleDepositLabel?: string;
};

const BottleDepositComponent: React.FC<BottleDepositComponentProps> = ({
  bottleDepositLabel,
  depositAmount,
  currency,
}) => {
  if (!depositAmount || depositAmount <= 0) return null;

  return (
    <View style={Styles.Style.revolveRow}>
      <AVText
        accessible={true}
        accessibilityLabel={`${bottleDepositLabel} label`}
        accessibilityRole="text"
        aria-label={`${bottleDepositLabel} label, text`}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
        style={getDescriber().PurchaseDetailDepositStyleDescriptor()}
      >
        {bottleDepositLabel}
      </AVText>

      <AVFormattedCurrency
        {...formatCurrency(depositAmount, bottleDepositLabel, currency)}
        style={[
          Settings.isRefive() && {
            fontFamily: Styles.FontFamily.figtreeRegular,
            fontWeight: '400',
            color: Styles.darkColor,
            fontSize: Styles.Fonts.f1 + 2,
          },
        ]}
      />
    </View>
  );
};

export default BottleDepositComponent;
