import * as React from 'react';
import Svg, {
  ClipPath,
  Defs,
  G,
  LinearGradient,
  Path,
  Stop,
} from 'react-native-svg';

interface IRecentComboIconProps {
  color?: string;
  width?: number;
  height?: number;
}
const RecentComboIcon = (props: IRecentComboIconProps) => (
  <Svg
    width={props?.width || 203}
    height={props?.height || 41}
    viewBox="0 0 203 41"
    fill="none"
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.702 5.595c-7.959 0-12.655 8.924-8.175 15.523l4.304 6.344-4.815 7.702 5.738 3.605 4.323-6.914.236-.375h14.168l.235.375 4.323 6.915 5.738-3.606-4.814-7.702 4.303-6.344c4.48-6.58-.216-15.523-8.174-15.523-7.958 0-7.074 2.167-8.685 5.18-1.631-2.994-4.775-5.18-8.686-5.18h-.019zm-3.459 12.233c-1.65-2.442.079-5.732 3.026-5.732 2.948 0 4.638 3.191 3.085 5.654l-2.986 4.688-3.125-4.61zm24.288 0c1.65-2.442-.079-5.732-3.026-5.732-2.948 0-4.638 3.191-3.086 5.654l2.987 4.688 3.125-4.61zm-12.144 1.32l3.91 6.62h-7.82l3.91-6.62zM81.14 11.583h10.434L87.192 39.46H74.026a7.079 7.079 0 001.219-1.734h2.633v-9.968h-2.79a10.282 10.282 0 00-6.092-5.654l-1.65-10.52H77.76V6.146l1.06-.433 6.112-2.443 1.572-.63 1.258 3.152-1.572.63-5.05 2.01v3.152zM75.343 33.53H54.258v-3.388h2.024a7.826 7.826 0 017.644-6.166h1.749a7.826 7.826 0 017.644 6.166h2.024v3.388zm-19.06 2.541h17.016a4.307 4.307 0 01-4.107 2.995H60.39a4.332 4.332 0 01-4.107-2.994zM187.564 6.265a5.078 5.078 0 00-.55 1.556c1.611-1.005 3.616-1.753 5.679-1.93 1.631-.139 3.34.078 4.932.886 1.592.808 3.006 2.147 4.087 4.176 1.317 2.463 1.336 5.339.727 8.176-.609 2.836-1.886 5.713-3.301 8.293a57.582 57.582 0 01-4.166 6.482 50.602 50.602 0 01-2.024 2.6l-.039.04-.472.551-7.172-4.314-7.172 4.314-.472-.551-.039-.04c-.177-.217-.373-.433-.55-.67a43.683 43.683 0 01-1.474-1.93 60.537 60.537 0 01-4.166-6.482c-1.395-2.56-2.672-5.457-3.301-8.293-.609-2.837-.59-5.713.727-8.176 1.081-2.029 2.496-3.368 4.087-4.176 1.592-.788 3.282-1.025 4.932-.887 2.083.178 4.107.966 5.738 1.99a8.62 8.62 0 011.022-3.25c1.061-1.911 3.124-3.881 6.917-4.63l.668 3.33c-2.81.55-4.028 1.91-4.618 2.954v-.02z"
      fill="#066DFF"
    />
    <G clipPath="url(#clip0_19135_10600)" fillRule="evenodd" clipRule="evenodd">
      <Path
        d="M27.992 4.916H6.72v5.727h21.272V4.916zm-8.181 14.727l6.545-6.546H6.72L.174 19.643h19.637zm16.363 21.273V22.098l-7.363-8.182-7.364 8.182v18.818h14.727zm-17.182 0V22.098H.174v18.818h18.818z"
        fill="url(#paint0_linear_19135_10600)"
      />
      <Path
        d="M4.08 28.648v-.817h1.633v8.573H4.08v-7.757.001zm11.061 0v-.817h-1.633v8.573h1.633v-7.757.001zm-3.143-.816v4.899h-1.633v-4.899h1.633zm-1.633 7.757v.817h1.633v-2.041h-1.633v1.224zm-1.51-7.757v4.899H7.222v-4.899h1.633zm-1.633 7.757v.817h1.633v-2.041H7.222v1.224z"
        fill="#111"
      />
    </G>
    <Defs>
      <LinearGradient
        id="paint0_linear_19135_10600"
        x1={0.659534}
        y1={44.2568}
        x2={35.0675}
        y2={8.93219}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#066DFF" />
        <Stop offset={1} stopColor="#FEE0E6" />
      </LinearGradient>
      <ClipPath id="clip0_19135_10600">
        <Path fill="#fff" transform="translate(.174 4.916)" d="M0 0H36V36H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default RecentComboIcon;
