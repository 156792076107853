import * as React from 'react';
import Svg, {Path, Rect} from 'react-native-svg';
type LogoProps = {
  size: number;
  style?: Record<string, unknown>;
}; // tslint:disable

export default class EmptyCards extends React.Component<LogoProps> {
  getWidth() {
    if (this.props.style && this.props.style['size']['width']) {
      return this.props.style['size']['width'];
    } else {
      return 115;
    }
  }

  getHeight() {
    if (this.props.style && this.props.style['size']['height']) {
      return this.props.style['size']['height'];
    } else {
      return 115;
    }
  }

  render() {
    return (
      <>
        <Svg
          width={this.getWidth()}
          height={this.getHeight()}
          viewBox="0 0 115 115"
          fill="none"
        >
          <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M99.0625 24.2794H15.9375C12.6507 24.2794 10.0212 26.8771 10 30.0982V30.1605V84.8983V84.9607C10.0424 88.1818 12.6507 90.7794 15.9375 90.7794H99.0625C102.371 90.7794 105 88.1402 105 84.8983V30.1605C105 26.9186 102.371 24.2794 99.0625 24.2794ZM19.5636 30.9294H95.4152C96.8783 30.9294 97.981 31.9893 98.1931 33.4232V37.5794H16.7857V33.3608C16.9978 31.9477 18.1217 30.9294 19.5636 30.9294ZM95.4364 84.1294H19.5636C18.1004 84.1294 16.9978 83.1111 16.7857 81.698V57.5294H98.2143V81.6357C98.0022 83.0696 96.8784 84.1294 95.4364 84.1294Z"
            fill="#707070"
          />
          <Rect
            x="23.5713"
            y="67.5044"
            width="40.7143"
            height="3.325"
            fill="#707070"
          />
          <Rect
            x="23.5713"
            y="74.1543"
            width="20.3571"
            height="3.325"
            fill="#707070"
          />
          <Rect
            x="77.8574"
            y="67.5044"
            width="13.5714"
            height="9.975"
            fill="#707070"
          />
        </Svg>
      </>
    );
  }
}
