import * as React from 'react';
import {
  StyleProp,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  StyleSheet,
} from 'react-native';
import Styles from 'src/components/Styles';
import {getButtonDescriber} from './buttonDescriptor/DescriptorType';

export enum RoundedAppButtonType {
  Solid = 'Solid',
  Outline = 'Outline',
}

interface RoundedAppButtonProps {
  onPress?: () => void;
  isDisabled?: boolean;
  titleText: string;
  buttonContainerStyle?: StyleProp<ViewStyle>;
  buttonViewStyle?: StyleProp<ViewStyle>;
  inActiveButtonViewStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  inActiveTextStyle?: StyleProp<TextStyle>;
  maxFontSizeMultiplier?: number;
  buttonIcon?: React.ReactNode;
  buttonType: RoundedAppButtonType;
  hideAccessibleElement?: boolean;
  accessibilityHintValue?: string;
  accessibilityLabelValue?: string;
  iconStyle?: StyleProp<ViewStyle>;
}
export default function RoundedAppButton(props: RoundedAppButtonProps) {
  const getButtonViewStyle = (): StyleProp<ViewStyle> => {
    let buttonHeight;
    if (props.isDisabled) {
      buttonHeight =
        props.inActiveButtonViewStyle?.['height'] ??
        Styles.Heights.touchTargetHeight1;
    } else {
      buttonHeight =
        props.buttonViewStyle?.['height'] ?? Styles.Heights.touchTargetHeight1;
    }

    if (props.buttonType === RoundedAppButtonType.Solid) {
      return [
        {
          justifyContent: props?.buttonIcon ? 'flex-start' : 'center',
          borderRadius: buttonHeight / 2,
        },
        styles.buttonViewFilled,
        {
          backgroundColor:
            props.buttonViewStyle?.['backgroundColor'] ??
            getButtonDescriber().checkRoundedBtnColor(),
        },
        props.isDisabled
          ? props?.inActiveButtonViewStyle
          : props?.buttonViewStyle,
      ];
    } else {
      return [
        {
          justifyContent: props?.buttonIcon ? 'flex-start' : 'center',
          borderRadius: buttonHeight / 2,
        },
        styles.buttonViewOutline,
        props.isDisabled
          ? props?.inActiveButtonViewStyle
          : props?.buttonViewStyle,
        {
          borderColor:
            props.buttonViewStyle?.['borderColor'] ??
            getButtonDescriber().checkRoundedBtnColor(),
        },
      ];
    }
  };

  const getButtonTextStyle = (): StyleProp<TextStyle> => {
    if (props.buttonType === RoundedAppButtonType.Solid) {
      return [
        styles.textFilled,
        props.isDisabled ? props.inActiveTextStyle : props.textStyle,
        getButtonDescriber().roundAppButtonSolidTextStyle(props.isDisabled),
        {fontFamily: getButtonDescriber().buttonTextFontFamily()},
      ];
    } else {
      return [
        styles.textOutline,
        props.isDisabled ? props.inActiveTextStyle : props.textStyle,
        {
          color:
            props.textStyle?.['color'] ??
            getButtonDescriber().checkRoundedBtnColor(),
        },
        getButtonDescriber() &&
          getButtonDescriber()['roundAppButtonOutlineTextStyle'] &&
          getButtonDescriber()['roundAppButtonOutlineTextStyle'](
            props.isDisabled,
          ),
        {fontFamily: getButtonDescriber().buttonTextFontFamily()},
      ];
    }
  };

  return (
    <TouchableOpacity
      style={[styles.container, props.buttonContainerStyle]}
      disabled={props.isDisabled}
      onPress={props.onPress}
      accessibilityElementsHidden={props?.hideAccessibleElement}
      importantForAccessibility={
        props?.hideAccessibleElement ? 'no-hide-descendants' : 'yes'
      }
      accessible={true}
      accessibilityLabel={props?.accessibilityLabelValue}
      role="button"
      accessibilityRole={'button'}
      accessibilityHint={props?.accessibilityHintValue}
    >
      <View style={getButtonViewStyle()}>
        {props.buttonIcon && (
          <View style={[{marginRight: 8}, props.iconStyle]}>
            {props.buttonIcon}
          </View>
        )}
        <Text
          style={getButtonTextStyle()}
          maxFontSizeMultiplier={
            props.maxFontSizeMultiplier ?? Styles.FontSizeMultiplier.maxfm4
          }
        >
          {props.titleText}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: Styles.Heights.touchTargetHeight1,
  },
  buttonViewFilled: {
    flexDirection: 'row',
    overflow: 'hidden',
    alignItems: 'center',
    height: Styles.Heights.touchTargetHeight1,
    backgroundColor: Styles.Colors.PayNew.primary01,
    paddingHorizontal: Styles.Spacing.m3,
  },
  buttonViewOutline: {
    flexDirection: 'row',
    overflow: 'hidden',
    alignItems: 'center',
    height: Styles.Heights.touchTargetHeight1,
    borderWidth: 1,
    borderColor: Styles.Colors.PayNew.primary01,
    paddingHorizontal: Styles.Spacing.m3,
  },
  textFilled: {
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    color: Styles.Colors.PayNew.white01,
    textAlignVertical: 'center',
  },
  textOutline: {
    fontSize: Styles.Fonts.f8,
    fontWeight: '700',
    color: Styles.Colors.PayNew.primary01,
    textAlignVertical: 'center',
  },
});
