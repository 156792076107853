import {
  Animated,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
  PixelRatio,
  Platform,
  TextStyle,
} from 'react-native';
import moment from 'moment';
import Icon from 'react-native-vector-icons/FontAwesome';

import Styles from 'src/components/Styles';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import {
  CommonUtilsSwitchProps,
  DealProps,
  SnackItemDescriberProps,
  CollapsibleComponentProps,
  StylesDescriptor,
  NumberSpinnerStylesDescriptor,
  ReferralItemDescriberProps,
} from 'src/components/elements/descriptor/DescriptorType';
import Settings from 'src/Settings';
import TimeUtils from 'src/services/TimeUtils';
import Localized from 'src/constants/AppStrings';
import React from 'react';
import CloseIcon from 'src/components/img/svg/CloseIcon';
import ChevronDown from 'src/components/img/svg/ChevronDown';
import Util, {capitalizeFirstWord} from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import Referral from 'src/components/img/svg/inbox/referral-reward';
import SignUp from 'src/components/img/svg/inbox/signup-reward';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import UnreadReferral from 'src/components/img/svg/UnreadReferral';
import UnreadSignUp from 'src/components/img/svg/UnreadSignUp';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import CustomToggleSwitch from 'src/components/elements/CustomToggleSwitch';
import GiftBoxRefive from 'src/components/img/svg/GiftBoxRefive';
import GiftBoxRefiveUnReadDot from 'src/components/img/svg/GiftBoxRefiveUnReadDot';
import DealIconUnreadDotRefive from 'src/components/img/svg/DealIconUnreadDotRefive';
import DealIconRefive from 'src/components/img/svg/DealIconRefive';

export function addNewPaymentTemplateDescriptor(): JSX.Element {
  return <FontAwesome5Pro name="plus-circle" color="#555" size={26} light />;
}

type _Formatted = number | string | JSX.Element;

export function snackMessageDescriptor(
  props?: SnackItemDescriberProps,
): string | _Formatted[] {
  const {Labels: _locaLbls} = Localized;
  return _locaLbls.formatString(
    _locaLbls.you_have_received_a_snack,
    Util.formatCurrency(
      props?.snack,
      props?.snack.amount ?? 0,
      AccountStore.getCurrency(),
    ),
    props?.snack.senderName ?? '',
  );
}

export function getNotificationScreenStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    readUnreadText: {
      fontSize: Styles.Fonts.f1,
      paddingTop: Styles.Spacing.m1,
      color: Styles.Colors.PayNew.primary01,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '700',
    },
    unreadNumText: {
      fontSize: Styles.Fonts.f1,
      color: Styles.tabBarTextColor,
      fontFamily: Styles.FontFamily.figtreeRegular,
      marginTop: Styles.Spacing.m1,
      fontWeight: '400',
    },
    emptyMessageText: {
      fontSize: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.figtreeRegular,
      marginTop: Styles.Spacing.m2,
      //lineHeight: Styles.Fonts.f7,
      fontWeight: '700',
      color: '#111',
    },
  };
}

export function homePageTitlesScreenStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    viewAll: {
      color: Styles.blackBase01,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    title: {
      fontSize: Styles.Fonts.headerFont,
      fontWeight: '400',
      color: Styles.blackBase01,
      fontFamily: Styles.FontFamily.comodoVintage,
    },
  };
}

export function dealRenderExpirationTxtTemplate(
  props?: DealProps,
): JSX.Element {
  const dateTime = TimeUtils.getTimeWithAMorPM(props?.deal?.endDate);
  return (
    <Text
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
      accessibilityLabel={dateTime}
      style={[dealStyleDescriptor()['expirationDate'], {textAlign: 'right'}]}
    >
      {dateTime}
    </Text>
  );
}
export function getuserInitialsStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    initialsText: {
      fontSize: Styles.Fonts.f10,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '700',
    },
    initialsBtn: {
      flexDirection: 'row',
      justifyContent: 'center',
      margin: PixelRatio.roundToNearestPixel(0.5),
      backgroundColor: Styles.Colors.Refive.activeFooterBlue,
      borderRadius: 8,
      height: 72,
      width: 72,
    },
    text: {
      alignSelf: 'center',
      color: Styles.primaryColor,
      fontWeight: 'bold',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
  };
}

export function getStyleWithColorOnly(): {[index: string]: string} {
  return {
    backgroundColor: '#DBE7FB',
    color: Styles.tabBarTextColor,
    allReadTextUnderLine: Styles.Colors.PayNew.primary01,
  };
}
export function snackBarColorDescriptor(): string {
  return Styles.Colors.PayNew.primary01;
}
export function onboardingLocaionBorderColor() {
  return '#022649';
}
export function onboardingLocaionBgColor() {
  return 'rgba(2, 38, 73, 0.15)';
}
export function onboardingSelectLocationTextDescriptor(): string {
  return Localized.Labels.turn_on_location_services_refive;
}
export function checkRewardsBackgroundColor() {
  return [
    Styles.tabBarBackgroundColor,
    Styles.tabBarBackgroundColor,
    `${Styles.tabBarBackgroundColor}99`,
  ];
}
export function onboardingFlowStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    instructions: {
      color: Styles.darkBlueRevolveColor,
      fontSize: Styles.Fonts.f7,
      fontWeight: '400',
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      paddingStart: Styles.Spacing.m3,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontStyle: 'normal',
    },
    descriptionText: {
      color: '#111',
      fontSize: Styles.Fonts.f7,
      fontWeight: '400',
      textAlign: 'center',
      marginTop: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    nearByLocationView: {
      fontSize: Styles.Fonts.f8,
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.Colors.PayNew.black01,
    },
    nearByLocationViewDesc: {
      fontSize: Styles.Fonts.f8,
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.footerTabInactive,
    },
  };
}

export function getSettingsMenusStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    switchWithOptionText: {
      paddingHorizontal: Styles.Spacing.m3,
      marginVertical:
        Platform.OS == 'ios' ? Styles.Spacing.m3 : Styles.Spacing.m2,
    },
    optionText: {
      textAlign: 'center',
      paddingTop: 3,
      color: Styles.black,
      fontSize: Styles.Fonts.f7,
      fontWeight: '400',
      minHeight: Styles.Heights.touchTargetHeight0,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    divider: {
      backgroundColor: Styles.dividerColor,
      height: 1,
    },
    enclosureContainer: {
      marginHorizontal: Styles.Spacing.m3,
      borderColor: Styles.inputBgColor,
      borderWidth: 1,
      borderRadius: 8,
    },
    listShadow: Styles.Style.shadow,
  };
}
export function stylePayUnAvailOption(styles) {
  return styles.payUnavailableOption;
}
export function settingsMenuFontColorDescriptor(): string {
  return Styles.Colors.PayNew.darkHuesBase04;
}

export function claimBtnColorDescriptor(): string {
  return Styles.Colors.PayNew.primary01;
}
export function claimedBtnColorDescriptor(): string {
  return Styles.Colors.PayNew.darkHuesBase04;
}
export function claimeSucessdBtnColorDescriptor(): string {
  return '#066dff26';
}
export function claimedErrorBtnColorDescriptor(): string {
  return '#d7263d26';
}
export function referralDetailBgColorDescriptor(): string {
  return Platform.OS === 'web' ? Styles.white : Styles.tabBarBackgroundColor;
}
export function renderReferralStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    referralSuccErrorText: {
      color: Styles.black,
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    referralHederText: {
      color: Styles.black,
      fontSize: Styles.Fonts.sectionHeader,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    referralDescText: {
      color: Styles.black,
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    allReadText: {
      fontSize: Styles.Fonts.f1,
      paddingTop: Styles.Spacing.m1,
      color: Styles.Colors.PayNew.primary01,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '700',
    },
  };
}

export function dealRenderDealIcon(props?: DealProps): JSX.Element {
  return (
    <View style={{justifyContent: 'center'}}>
      {!props.deal.isRead && (
        <View style={dealStyleDescriptor()['icon']}>
          <DealIconUnreadDotRefive
            height={Styles.Fonts.f5}
            bgColor={'##FEE0E6'}
            width={Styles.Fonts.f5}
          />
        </View>
      )}

      {props.deal.isRead && (
        <DealIconRefive
          height={Styles.Fonts.f5}
          bgColor={'##FEE0E6'}
          width={Styles.Fonts.f5}
        />
      )}
    </View>
  );
}

export function stylePayAvailOption(styles) {
  return styles.payAvailableOption;
}
export function scanScreenQRCodeStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    balanceText: {
      fontFamily: Styles.FontFamily.comodoVintage,
      textTransform: 'capitalize',
      fontSize: Styles.Fonts.sectionHeader,
      color: '#A5CF4F',
    },
    btnAddFund: {
      paddingRight: 24,
      paddingTop: 8,
      paddingLeft: 24,
      paddingBottom: 8,
    },
    btnDone: {
      paddingRight: 12,
      paddingTop: 6,
      paddingLeft: 12,
      paddingBottom: 6,
    },
    btnAddToWallet: {
      paddingTop: 8,
      paddingRight: 12,
      paddingLeft: 12,
      paddingBottom: 8,
      borderWidth: 2,
    },
    btnEmailReceipt: {
      paddingTop: 6,
      paddingRight: 12,
      paddingLeft: 12,
      paddingBottom: 6,
      borderWidth: 2,
    },
    addFundsText: {
      fontSize: Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: '#066DFF',
      fontWeight: '700',
    },
    addToWalletText: {
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: '#066DFF',
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
    },
    addFundsButtonContainer: {
      alignItems: 'center',
      marginTop: Styles.Spacing.m3 + 6,
      justifyContent: 'center',
    },
  };
}
export function scanAtKioskLbl() {
  return capitalizeFirstWord(Localized.Labels.Scan_at_kiosk);
}
export function payAtKioskLbl() {
  return capitalizeFirstWord(Localized.Labels.pay_at_kiosk);
}
export function getSnackItemStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    message: {
      fontWeight: '400',
      fontSize: Styles.Fonts.f8,
      color: Styles.tabBarTextColor,
      fontFamily: Styles.FontFamily.figtreeRegular,
      flex: 1,
    },
    container: {
      marginTop: Styles.Spacing.m3,
      justifyContent: 'center',
      flex: 1,
      marginHorizontal: Styles.Spacing.m3,
      paddingHorizontal: Styles.Spacing.m3,
      flexDirection: 'row',
      borderRadius: Styles.Spacing.m2,
      paddingVertical: Styles.Spacing.m3,
    },
    subject: {
      marginTop: Styles.Spacing.m1,
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1,
      marginBottom: Styles.Spacing.m1,
      fontWeight: '400',
    },
    topRight: {
      flexDirection: 'row',
    },
    arrow: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    referalTitle: {
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.darkColor,
    },
    chevronRight: {
      width: 24,
      height: 24,
      right: 10,
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
    },
    date: {
      color: Styles.Colors.PayNew.darkHuesBase04,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '400',
      fontSize: Styles.Fonts.f1,
      left: Styles.Spacing.m4 + 4,
    },
    referralDate: {
      fontSize: Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.lightGray,
      fontWeight: '400',
      left: Styles.Spacing.m4 + 4,
      marginLeft: -14,
    },
    messageLeft: {
      fontWeight: '400',
      marginRight: Styles.Spacing.m3,
      marginLeft: Styles.Spacing.m3,
      flex: 1,
    },
    from: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    titleHeader: {
      fontSize: Styles.Fonts.f7,
      color: Styles.darkColor,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '700',
    },
    claimedBtnText: {
      paddingHorizontal: 10,
      paddingVertical: 3,
      borderWidth: 1,
      backgroundColor: '#066dff26',
      borderColor: '#066dff26',
      borderRadius: 4,
      minWidth: 75,
    },
    expiredBtnText: {
      paddingHorizontal: 10,
      paddingVertical: 3,
      borderWidth: 1,
      backgroundColor: '#D7263D26',
      borderColor: '#D7263D26',
      borderRadius: 4,
      minWidth: 75,
    },
    referralStatusText: {
      fontSize: 14,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
  };
}

export function snackItemGiftBoxTemplateDescriptor(
  props?: SnackItemDescriberProps,
): JSX.Element {
  return (
    <View style={getSnackItemStyleDescriptor()['arrow']}>
      {!props.snack.isRead && (
        <GiftBoxRefiveUnReadDot
          width={Styles.Fonts.f2}
          height={Styles.Fonts.f5}
        />
      )}
      {props.snack.isRead && (
        <View style={getSnackItemStyleDescriptor()['icon']}>
          <GiftBoxRefive
            width={Styles.Fonts.f3}
            color={Styles.lightGreenRevolve}
            height={Styles.Fonts.f3}
          />
        </View>
      )}
    </View>
  );
}

export function referralImageTemplateDescriptor(
  props?: ReferralItemDescriberProps,
): JSX.Element {
  if (props.isSender) {
    return (
      <View style={{justifyContent: 'center', alignItems: 'center'}}>
        {props.referral?.isRead ? (
          <Referral width={50} height={50} />
        ) : (
          <UnreadReferral width={50} height={50} />
        )}
      </View>
    );
  } else {
    return (
      <View style={{justifyContent: 'center', alignItems: 'center'}}>
        {props.referral?.isRead ? (
          <SignUp width={50} height={50} />
        ) : (
          <UnreadSignUp width={50} height={50} />
        )}
      </View>
    );
  }
}

export function snackItemDateDescriptor(
  props?: SnackItemDescriberProps,
): string {
  return TimeUtils.getTimeWithAMorPM(props?.snack.snackSent);
}

export function referralItemDateDescriptor(
  props?: ReferralItemDescriberProps,
): string {
  return TimeUtils.getTimeWithAMorPM(props.referral.dateCreated);
}

export function snackItemNumberDescriptor(): number {
  return 2;
}

export function getTypoFontSize() {
  return 18;
}

export function dealRedeemCount(text: string): JSX.Element {
  return (
    <Text
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
      style={[
        dealStyleDescriptor()['successText'],
        dealStyleDescriptor()['dealTarget'],
      ]}
    >
      {text}
    </Text>
  );
}

export function dealItemNumberDescriptor(): number {
  return 2;
}
export function dealRenderDealTopSection(): string {
  return Localized.Labels.offers;
}

export function dealRenderDealNameDesc(props?: DealProps): JSX.Element {
  return (
    <React.Fragment>
      <Text
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
        accessibilityLabel={props?.deal?.name}
        style={dealStyleDescriptor()['dealDesc']}
        numberOfLines={1}
      >
        {props?.deal?.name}
      </Text>
      <Text numberOfLines={2} style={dealStyleDescriptor()['message']}>
        {props?.deal?.message}
      </Text>
    </React.Fragment>
  );
}

function formatExpirationDate(endDate) {
  const now = moment();
  const expirationDate = moment(endDate);
  if (expirationDate.isSame(now, 'day')) {
    return expirationDate.format('hh:mm A');
  } else {
    return expirationDate.format('MM/DD/YYYY'); // Two-digit year
  }
}

export function getSuccessDialogDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    header: {
      color: Styles.black,
      fontSize: Styles.Fonts.f9,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
      marginTop: Styles.Spacing.m2,
    },
    title: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
      marginTop: Styles.Spacing.m3,
    },
    okayButtonText: {
      color: Styles.white,
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
      textAlign: 'center',
      marginLeft: Styles.Spacing.m1,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
  };
}

export function getSuccessDialogBtn(callBack: () => void) {
  return (
    <RoundedAppButton
      buttonType={RoundedAppButtonType.Solid}
      onPress={callBack}
      buttonViewStyle={{width: 100, height: 42}}
      buttonContainerStyle={{
        alignSelf: 'center',
        marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
      }}
      accessibilityLabelValue={`${Localized.Buttons.okay}`}
      titleText={Localized.Buttons.okay}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
      textStyle={getSuccessDialogDescriptor()['okayButtonText']}
    />
  );
}

export function dealStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    arrow: {
      marginLeft: Styles.Spacing.m3,
      alignItems: 'center',
      justifyContent: 'center',
      right: 10,
    },
    container: {
      marginTop: Styles.Spacing.m3,
      justifyContent: 'center',
      marginHorizontal: Styles.Spacing.m3,
      paddingHorizontal: Styles.Spacing.m3,
      flexDirection: 'row',
      borderRadius: Styles.Spacing.m2,
      paddingVertical: Styles.Spacing.m3,
    },
    expirationDate: {
      //left: 45,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.f1,
      color: Styles.Colors.PayNew.darkHuesBase04,
      left: Styles.Spacing.m4 - 8,
    },
    dealName: {
      marginRight: Styles.Spacing.m2,
      fontSize: Styles.Fonts.f7,
      marginLeft: Styles.Spacing.m2,
      flex: 1,
      fontWeight: 'bold',
      marginBottom: Styles.Spacing.m1,
      color: Styles.Colors.PayNew.black01,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },

    message: {
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.f8,
      color: Styles.Colors.PayNew.black01,
      marginLeft: Styles.Spacing.m2,
      fontWeight: '400',
      width: '80%',
    },
    titleHeader: {
      fontWeight: '700',
      color: Styles.darkColor,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.f7,
    },
    successText: {
      fontWeight: 'bold',
      color: Styles.positiveColor,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    dealDesc: {
      fontFamily: Styles.FontFamily.figtreeRegular,
      marginBottom: Styles.Spacing.m1,
      fontSize: Styles.Fonts.f1,
      marginLeft: Styles.Spacing.m2,
      color: Styles.Colors.PayNew.black01,
      fontWeight: '400',
      width: '80%',
    },
    dealTarget: {
      alignSelf: 'flex-end',
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f1,
    },
    inboxTabBgColor: {
      backgroundColor: Styles.tabBarBackgroundColor,
    },
  };
}
export function sendSnackOTSNBtnStyleDescriptor(isDelete?: boolean): string {
  return !isDelete ? '#066DFF' : '#D7263D';
}
export function activeTabShadowColor(): string {
  return Platform.OS === 'web' ? Styles.white : Styles.tabBarBackgroundColor;
}
export function historyHeaderName(): string {
  return Localized.Labels.receipts;
}
export function fundingAmountColor(): string {
  return Styles.Colors.PayNew.success;
}
export function snackItemTitleDescriptor(): string {
  return Settings.appDisplayName;
}

export function cardsMainViewBgColor(): string {
  return 'rgba(69, 136, 253, 0.15)';
}

export function walletForntStyleText(): string {
  return Styles.FontFamily.figtreeRegular;
}

export function walletScreenCardStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    descriptionText: {
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      marginHorizontal: 24,
      marginTop: 24,
      color: Styles.black,
    },
    txtInput: {
      fontSize: Styles.Fonts.f8,
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.black,
      fontWeight: '400',
      height: 56,
      marginTop: 12,
    },
    cardNumberLabel: {
      fontSize: Styles.Spacing.m3,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '400',
      color: '#707070',
    },
    cardLabels: {
      fontSize: Styles.Spacing.m3 + 2,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '400',
      color: '#707070',
    },
    setDefaultLabel: {
      fontSize: Styles.Fonts.f1,
      marginRight: Styles.Spacing.m2,
      color: Styles.black,
      marginLeft: Styles.Spacing.m1,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    saveFontStyle: {
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    balancesTitle: {
      fontFamily: Styles.FontFamily.comodoVintage,
      fontSize: Styles.Fonts.sectionHeader,
      color: Styles.tabBarTextColor,
      marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
      marginBottom: Styles.Spacing.m2 + Styles.Spacing.m2 - 2,
    },
  };
}
export function sendSnackOTSNotificationStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    acceptedSnactSubDesc: {
      fontSize: Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '400',
      color: Styles.black,
    },
    acceptSnackText: {
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
      color: Styles.black,
    },
    acceptSnackHeaderText: {
      fontWeight: '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.sectionHeader,
      color: Styles.black,
    },
    acceptedSnactHeaderText: {
      color: Styles.black,
      marginLeft: Styles.Spacing.m2,
      fontSize: Styles.Fonts.sectionHeader + 2,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    acceptSnackSubDescText: {
      fontSize: Styles.Fonts.f1,
      color: Styles.black,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    saveContainer: {
      paddingVertical: 6,
      paddingHorizontal: 14,
    },
    resetPinLablStyle: {
      fontSize: Styles.Spacing.m3 + 2,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '400',
      color: '#707070',
    },
  };
}
export function historyListStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    historyHeaderTitle: {
      fontWeight: '700',
      fontSize: Styles.Fonts.sectionHeader,
      paddingBottom: Styles.Spacing.m3,
      paddingTop: Styles.Heights.h2,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    historyHeaderTitleDes: {
      alignSelf: 'flex-start',
      color: Styles.black,
      flex: 2,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    historyTabFontFamily: {
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    historyTabActiveFontColor: {
      color: Styles.white,
    },
    historyTabInactiveFontColor: {
      color: Styles.black,
    },
  };
}
const __styles = StyleSheet.create({
  chevron: {
    alignSelf: 'center',
    paddingLeft: Styles.Spacing.m3,
  },
});
export function getAnimatedViewDescriptor(
  rotate: Animated.AnimatedInterpolation<string>,
  props: {header: React.ReactNode},
  expandValue: boolean,
): React.ReactNode {
  return (
    <>
      <View style={{flex: 3}}>{props.header}</View>
      <Animated.View style={[__styles.chevron]}>
        <View
          style={{flex: 0.5, justifyContent: 'center', alignItems: 'center'}}
        >
          {expandValue ? (
            <FontAwesome5Pro
              name={'chevron-down'}
              color={Styles.lightGray}
              size={Styles.Fonts.f2}
              light
            />
          ) : (
            <FontAwesome5Pro
              name={'chevron-up'}
              color={Styles.lightGray}
              size={Styles.Fonts.f2}
              light
            />
          )}
        </View>
      </Animated.View>
    </>
  );
}

export function switchColor(): string {
  return Styles.white;
}

// allyinput
export function getStyles(): StylesDescriptor {
  return {
    container: {
      marginTop: Styles.Spacing.m3,
    },
    help: {
      marginTop: Styles.Spacing.m1,
      marginLeft: Styles.Spacing.m1,
      fontSize: 12,
      color: Styles.lightGray,
    },
    paymentDropDownInputContainer: {
      height: 55,
      padding: 2,
      flexDirection: 'row',
      borderColor: Styles.lightGray,
      marginBottom: Styles.Spacing.m2,
      borderWidth: 1,
      borderRadius: 10,
      overflow: 'hidden',
    },
    paymentInput: {
      backgroundColor: Styles.white,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.f7,
      color: Styles.black,
      fontWeight: '700',
      flex: 1,
    },
    leftIcon: {
      marginRight: 4,
      paddingEnd: 2,
      paddingVertical: 2,
      backgroundColor: Styles.white,
      borderRadius: 6,
    },
    imageContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      bottom: 50,
      right: 10,
    },
    labelContainer: {
      position: 'absolute',
      paddingHorizontal: 8,
      //backgroundColor: Styles.white,
      backgroundColor: Styles.tabBarBackgroundColor,
    },
    label: {
      fontSize: 14,
      color: Styles.lightGray,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    error: {
      marginTop: Styles.Spacing.m1,
      marginLeft: Styles.Spacing.m1,
      fontSize: 12,
      color: Styles.dangerColor,
    },
    input: {
      padding: Styles.Spacing.m3,
      borderWidth: 1,
      borderRadius: 4,
      fontSize: 16,
      fontWeight: '600',
      marginBottom: 5,
      backgroundColor: Styles.white,
      borderColor: '#CFCFCF',
    },

    shadow: {
      ...Platform.select({
        ios: {
          shadowColor: '#000',
          shadowOffset: {
            width: 1,
            height: 1,
          },
          shadowOpacity: 0.3,
          shadowRadius: 2,
        },
        android: {
          elevation: 2, // Adjust this value to match the shadow intensity
        },
      }),
      borderBottomWidth: 0.6, // Optional for border
      borderRadius: 8,
      backgroundColor: '#fff', // Required for Android shadow to render
    },
  };
}

export function getTextInputHeight(multiline?: boolean): {
  [index: string]: number | string;
} {
  return {
    textAlignVertical: multiline ? 'top' : 'center',
    inputHeight: multiline ? 98 : null,
  };
}
export function collapsibleComponentDescriptor(): CollapsibleComponentProps {
  return {
    toValue: 180,
    toDegree: '180deg',
    content: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingHorizontal: Styles.Spacing.m3,
      paddingVertical: 8,
      marginHorizontal: Styles.Spacing.m3,
      marginVertical: Styles.Spacing.m2,
      ...Styles.Style.shadow,
      borderRadius: 8,
      borderWidth: 1,
      borderTopColor: '#E7E7E7',
      borderBottomColor: '#E7E7E7',
      borderLeftColor: '#E7E7E7',
      borderRightColor: '#E7E7E7',
    },
    parentContainer: {
      flex: 1,
      flexDirection: 'row',
      paddingVertical: Styles.Spacing.m2,
      justifyContent: 'flex-end',
    },
  };
}
export function getTopPadding(): {
  [index: string]: number | string;
} {
  return {paddingTop: 23};
}
export function scanScreenQRCodeHeaderText(): string {
  return Localized.Labels.pay_at_kiosk;
}
export function getColor(isFocused: boolean, hasError: boolean) {
  if (hasError) {
    return Styles.dangerColor;
  }
  return isFocused
    ? Settings.buildType == 'canteen'
      ? Styles.primaryColor
      : Styles.lightGray
    : Styles.lightGray;
}

export function getAnimationConfig(label?: string, iconName?: string) {
  return {
    // scale: [1, 0.75],
    // translateY: [20, -12],
    // translateX: [16, 0],
    scale: [1, 0.8],
    translateY: [18, !iconName ? 2 : 9],
    translateX: [2, (label || '').length >= 11 ? 7 - (label || '').length : 2],
  };
}
export function getBackgroundColor(
  isFocused: boolean,
  value: string | undefined,
) {
  if (isFocused || value) {
    return Styles.white;
  }
  return Styles.white;
}
export function getDropDownMenuStyles(props): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    style: {
      width: Platform.OS !== 'web' ? '90%' : 'auto',
    },
    contentStyle: {
      backgroundColor: Styles.white,
      borderRadius: props.dropDownHeader ? 10 : 0,
    },
    touchableOpacity: {},
  };
}

export function getDropDownMenuListStyle(dropDownSize, maxWidth, props, index) {
  return {
    options: {
      padding: Styles.Spacing.m3,

      flexDirection: 'row',
      alignItems: 'center',
      minHeight: Styles.Heights.touchTargetHeight1,
      width: dropDownSize?.width || maxWidth,
    },
    optionImageExtraMargins: {},
    amexSpecificMargin: {
      top: -3,
    },
    optionTextStylingForPayment: {},
    optionTextStyling: {
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '700',
      fontSize: 16,
      color: '#000000',
    },
    optionPaymentText: {},
    parentContainer: {
      props,
      index,
    },
  };
}
export function referralTransactionId(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: 14,
    fontWeight: '400',
    color: '#111',
    paddingBottom: Styles.Spacing.m3,
  };
}
export function getDropDownMenuIcon(props, styles) {
  return (
    <View
      style={[
        props.flexWidth ? styles.flexWidthDropDownIcon : styles.dropDownIcon,
        {bottom: 22},
      ]}
    >
      <ChevronDown />
    </View>
  );
}

export function getIcon(props, isFocused?: boolean) {
  if (props.hasIcon) {
    return (
      <TouchableWithoutFeedback
        accessible={true}
        accessibilityLabel={props.iconLabel}
        aria-label={props.iconLabel}
        accessibilityRole="button"
        role="button"
        onPress={props.iconPressed}
      >
        <View
          style={[
            styless.iconContainer,
            props.iconName === 'closecircleo' && {top: -40, right: 15},
          ]}
        >
          {props.iconName !== 'closecircleo' && (
            <Icon
              style={styless.icon}
              name={props.iconName}
              size={28}
              color={isFocused ? Styles.primaryColor : Styles.darkColor}
            />
          )}
          {props.iconName === 'closecircleo' && (
            <CloseIcon color={'#727179'} width={28} height={28} />
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
  return null;
}
const styless = StyleSheet.create({
  chevron: {
    alignSelf: 'baseline',
    marginEnd: Styles.Spacing.m3,
  },
  iconContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    top: Platform.OS == 'ios' ? 5 : 0,
  },
  icon: {
    backgroundColor: 'transparent',
    position: 'absolute',
    right: 0,
    width: 50,
    height: 50,
    bottom: 0,
  },
});
export function rendercommonSecNewStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
      minHeight: Styles.Heights.touchTargetHeight0,
    },
    headerContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: Styles.Spacing.m2,
      paddingHorizontal: Styles.Spacing.m3,
    },
    header: {
      color: Styles.black,
      fontSize: Styles.Fonts.sectionHeader,
      fontFamily: Styles.FontFamily.comodoVintage,
    },
  };
}

export function navigateToWalletScreen() {
  NavActions.push(AppRoutes.PDCards, {hideBackArrow: false});
}

export function PasswordInputMaxFontSizeMultipleDescriptor(): number {
  return Styles.FontSizeMultiplier.maxfm11;
}

export function PasswordInputmaxlength(): number {
  return 24;
}

export function commonutilsRenderSwitchTemplate(
  props?: CommonUtilsSwitchProps,
): JSX.Element {
  const {switchStatus, onSwitchToggle} = props;
  if (switchStatus !== undefined && onSwitchToggle) {
    return (
      <CustomToggleSwitch
        accessible={true}
        accessibilityLabel={props.title}
        accessibilityState={{checked: props.switchStatus}}
        accessibilityHint="Double tap to activate the switch"
        accessibilityRole="switch"
        accessibilityValue={{text: props.switchStatus.toString()}}
        aria-label={props.title}
        aria-checked={props.switchStatus}
        aria-valuetext={props.switchStatus.toString()}
        value={props.switchStatus}
        onValueChange={() => onSwitchToggle()}
        //containerStyle={{alignItems: 'flex-end', marginRight: -16}}
      />
    );
  } else {
    return null;
  }
}
export function referralDate(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: 16,
    fontWeight: '400',
    color: '#111',
    paddingBottom: Styles.Spacing.m3,
  };
}

export function textInputColor(): string {
  return Styles.darkColor;
}

export function historyDateBtnColor(): string {
  return Styles.footerTabInactive;
}

export function checkEmailSuccessMsg(): string {
  return Localized.Success.receipt_send_success;
}
export function checkEmailreceiptBtnTxt(): string {
  return Localized.Labels.email_receipt;
}

export function checkRoundedBtnColor(): string {
  return Styles.darkGreenRevolve;
}
export function numberSpinnerStyles(): NumberSpinnerStylesDescriptor {
  return StyleSheet.create({
    num: {
      alignItems: 'center',
      paddingHorizontal: Styles.Spacing.m3,
      justifyContent: 'center',
      marginBottom: 2,
    },
    numText: {
      textAlign: 'center',
      fontSize: Styles.Fonts.f3,
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.Colors.PayNew.black01,
      fontWeight: '400',
    },
  });
}

export function numberSpinnerColor(): string {
  return Styles.tabBarBackgroundColor;
}

export function referralSummaryTotal(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: 24,
    fontWeight: '700',
    color: '#111',
  };
}

export function referrallocationName(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: 16,
    fontWeight: '700',
    color: '#111',
    paddingBottom: Styles.Spacing.m2 - 2,
  };
}
export function renderpointsLbl() {
  return <></>;
}
export function tabBarRoundedBtnColor(): string {
  return Styles.Colors.PayNew.primary01;
}

export function getInboxTabStyles(): {
  [index: string]: number | string;
} {
  return {
    marginTop: null,
  };
}

export function RewardPointStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: 24,
    fontWeight: '400',
    color: '#111',
  };
}
export function rewardPoints(
  styles,
  i,
  rewardOptions,
  largestAvailableRewardAmount,
  isRewardButtonShown,
) {
  return (
    <View
      style={[
        styles.payGraph,
        isRewardButtonShown && {
          marginTop:
            PixelRatio.getFontScale() > Styles.FontSizeMultiplier.maxfm0
              ? Styles.Spacing.m5
              : Styles.Spacing.m4,
        },
      ]}
      accessible={true}
      accessibilityLabel={`${largestAvailableRewardAmount} in rewards available`}
      accessibilityRole="image"
      aria-label={`${largestAvailableRewardAmount} in rewards available`}
      role="img"
      key={-1}
    >
      <View style={styles.line}>
        <View key={i++} style={[styles.rewardOption, styles.flex1]}>
          <View
            style={[
              styles.payAvailableOption,
              styles.firstSection,
              {
                flex: Styles.Spacing.m1,
              },
            ]}
          />
        </View>
      </View>

      <View style={[styles.line, styles.flex15]}>{rewardOptions}</View>

      <View style={styles.line}>
        <View key={i++} style={[styles.rewardOption, styles.flex1]}>
          <View
            style={[
              styles.payUnavailableOption,
              styles.lastSection,
              {
                flex: Styles.Spacing.m1,
              },
            ]}
          />
        </View>
      </View>
    </View>
  );
}

export function getScanKiosk() {
  return {
    borderWidth: 2,
    paddingHorizontal: 24,
    paddingVertical: 12,
  };
}
export function getPayKiosk() {
  return {
    borderWidth: 2,
    paddingLeft: 8,
    paddingRight: 16,
    paddingVertical: 4,
  };
}
export function rewardsPointLbl(): string {
  return Localized.Labels.points;
}

export function payOverlayTxt() {
  return {right: -7};
}

export function rewardAmtTxt(_styles, isAvailable?: boolean) {
  if (!isAvailable) {
    return {..._styles.payUnAvailableText, marginTop: Styles.Spacing.m1};
  } else {
    return {..._styles.payAvailableText, marginTop: Styles.Spacing.m1};
  }
}

export function rewardPointTxt(_styles, isAvailable?: boolean) {
  if (!isAvailable) {
    return {..._styles.payOverlayPoint, ..._styles.payUnAvailablePoint};
  } else {
    return {..._styles.payOverlayPoint, ..._styles.payAvailablePoint};
  }
}
export function toggleColor() {
  return ['#707070', '#066DFF'];
}
export function fontStyleSuccessModal() {
  return {fontFamily: Styles.FontFamily.figtreeRegular};
}
export function successModalSnackGifDescriptor() {
  return require('../../img/refiveSnackGift.gif');
}
export function getBalanceValueTextColor(): TextStyle {
  return {
    color: Styles.primaryColor,
  };
}
export function referralIconColor(): string | null {
  return null;
}

export function eGiftCardSuccesNavigation() {
  return NavActions.popToRoute(AppRoutes.Cards);
}

export default {
  getInboxTabStyles,
  getNotificationScreenStyles,
  successModalSnackGifDescriptor,
  addNewPaymentTemplateDescriptor,
  getuserInitialsStyleDescriptor,
  fontStyleSuccessModal,
  sendSnackOTSNotificationStyleDescriptor,
  getSettingsMenusStyleDescriptor,
  renderpointsLbl,
  payOverlayTxt,
  getSuccessDialogDescriptor,
  getSuccessDialogBtn,
  settingsMenuFontColorDescriptor,
  snackMessageDescriptor,
  snackItemGiftBoxTemplateDescriptor,
  getSnackItemStyleDescriptor,
  sendSnackOTSNBtnStyleDescriptor,
  snackItemTitleDescriptor,
  snackItemDateDescriptor,
  snackItemNumberDescriptor,
  dealItemNumberDescriptor,
  dealRenderExpirationTxtTemplate,
  dealRenderDealIcon,
  dealRenderDealNameDesc,
  dealRenderDealTopSection,
  dealStyleDescriptor,
  activeTabShadowColor,
  historyDateBtnColor,
  tabBarRoundedBtnColor,
  dealRedeemCount,
  PasswordInputMaxFontSizeMultipleDescriptor,
  PasswordInputmaxlength,
  collapsibleComponentDescriptor,
  getAnimatedViewDescriptor,
  getStyles,
  getColor,
  rewardsPointLbl,
  getBackgroundColor,
  renderReferralStyleDescriptor,
  getIcon,
  scanAtKioskLbl,
  getStyleWithColorOnly,
  getAnimationConfig,
  rendercommonSecNewStyleDescriptor,
  commonutilsRenderSwitchTemplate,
  textInputColor,
  getDropDownMenuStyles,
  getDropDownMenuListStyle,
  getTextInputHeight,
  getTopPadding,
  scanScreenQRCodeStyleDescriptor,
  scanScreenQRCodeHeaderText,
  referralDetailBgColorDescriptor,
  walletScreenCardStyles,
  numberSpinnerStyles,
  numberSpinnerColor,
  referralImageTemplateDescriptor,
  claimBtnColorDescriptor,
  claimedBtnColorDescriptor,
  stylePayAvailOption,
  onboardingSelectLocationTextDescriptor,
  checkEmailreceiptBtnTxt,
  checkRoundedBtnColor,
  referralItemDateDescriptor,
  claimeSucessdBtnColorDescriptor,
  claimedErrorBtnColorDescriptor,
  referralSummaryTotal,
  referralTransactionId,
  referrallocationName,
  getTypoFontSize,
  stylePayUnAvailOption,
  referralDate,
  RewardPointStyleDescriptor,
  snackBarColorDescriptor,
  rewardAmtTxt,
  checkRewardsBackgroundColor,
  rewardPointTxt,
  rewardPoints,
  getScanKiosk,
  walletForntStyleText,
  checkEmailSuccessMsg,
  switchColor,
  historyListStyleDescriptor,
  getPayKiosk,
  toggleColor,
  homePageTitlesScreenStyles,
  fundingAmountColor,
  cardsMainViewBgColor,
  onboardingLocaionBgColor,
  historyHeaderName,
  navigateToWalletScreen,
  onboardingLocaionBorderColor,
  onboardingFlowStyleDescriptor,
  eGiftCardSuccesNavigation,
  getBalanceValueTextColor,
  referralIconColor,
  payAtKioskLbl,
};
