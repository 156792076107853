import * as React from 'react';
import Svg, {Path, Rect} from 'react-native-svg';
type LogoProps = {
  size: number;
  style?: {[index: string]: {[index: string]: number | string}};
}; // tslint:disable

export default class Visa extends React.Component<LogoProps> {
  getWidth() {
    if (this.props.style && this.props.style['size']['width']) {
      return this.props.style['size']['width'];
    } else {
      return 53;
    }
  }

  getHeight() {
    if (this.props.style && this.props.style['size']['height']) {
      return this.props.style['size']['height'];
    } else {
      return 34;
    }
  }

  render() {
    return (
      <>
        <Svg
          width={this.getWidth()}
          height={this.getHeight()}
          viewBox="0 0 53 34"
          fill="none"
        >
          <Rect x={1} y={1} width={51} height={32} rx={4} fill="#fff" />
          <Rect
            x={0.5}
            y={0.5}
            width={52}
            height={33}
            rx={4.5}
            stroke="#C2C2C5"
          />
          <Path
            d="M35.342 11.492A8.618 8.618 0 0032.414 11c-3.229 0-5.509 1.578-5.525 3.843-.02 1.664 1.62 2.602 2.861 3.157 1.274.572 1.7.932 1.695 1.443-.008.777-1.017 1.13-1.955 1.13-1.29 0-1.993-.175-3.077-.611l-.402-.182-.458 2.607c.785.317 2.2.59 3.66.613 3.433 0 5.677-1.568 5.703-3.978.029-1.329-.86-2.333-2.728-3.163-1.136-.539-1.84-.899-1.84-1.445 0-.483.603-1 1.864-1 .84-.02 1.674.133 2.448.448l.303.131.458-2.52-.079.018zm8.378-.28h-2.522c-.785 0-1.376.21-1.72.965l-4.85 10.66h3.432l.686-1.748 4.19.004c.098.41.4 1.744.4 1.744h3.028L43.72 11.212zm-21.474-.098h3.27l-2.045 11.631h-3.267l2.042-11.635v.004zm-8.304 6.41l.34 1.616 3.196-7.928h3.464l-5.147 11.61h-3.457L9.513 12.99a.666.666 0 00-.3-.39A14.294 14.294 0 006 11.448l.044-.244h5.265c.714.027 1.29.244 1.481.984l1.15 5.343.002-.006zm25.742 1.187l1.304-3.253c-.016.035.268-.672.434-1.108l.224 1.004.757 3.355h-2.72l.002.002z"
            fill="#1434CB"
          />
        </Svg>
      </>
    );
  }
}
