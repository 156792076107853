import * as React from 'react';
import Svg, {ClipPath, Defs, G, Path, Rect} from 'react-native-svg';
type LogoProps = {
  size: number;
  style?: Record<string, unknown>;
}; // tslint:disable

export default class AmexRedesign extends React.Component<LogoProps> {
  getWidth() {
    if (this.props.style && this.props.style['size']['width']) {
      return this.props.style['size']['width'];
    } else {
      return 53;
    }
  }

  getHeight() {
    if (this.props.style && this.props.style['size']['height']) {
      return this.props.style['size']['height'];
    } else {
      return 49;
    }
  }

  render() {
    return (
      <>
        <Svg
          width={this.getWidth()}
          height={this.getHeight()}
          viewBox="0 0 45 30"
          fill="none"
        >
          <Rect x={1} y={1} width={38.25} height={24} rx={4} fill="#006FCF" />
          <Rect
            x={0.5}
            y={0.5}
            width={39.25}
            height={25}
            rx={4.5}
            stroke="#CFCFCF"
          />
          <G clipPath="url(#clip0_6163_24390)">
            <Path d="M39.25 25h-24V1h24v24z" fill="#fff" />
            <Path
              d="M39.25 25v-3.803h-2.895l-1.491-1.648-1.498 1.648h-9.547v-7.682h-3.08l3.821-8.65h3.686l1.316 2.963V4.865h4.562l.792 2.233.797-2.233h3.537V1h-24v24h24zm-2.408-4.765h2.408l-3.185-3.377 3.185-3.34h-2.37l-1.966 2.15-1.948-2.15h-2.408l3.166 3.359-3.166 3.358h2.341l1.977-2.169 1.967 2.169zm.564-3.374l1.844 1.962v-3.908l-1.844 1.946zm-10.734 1.82v-1.046h3.79v-1.516h-3.79v-1.046h3.886V13.52h-5.719v6.716h5.719v-1.554h-3.886zm10.765-6.137h1.813V5.827h-2.821l-1.507 4.184-1.496-4.184h-2.87v6.717h1.814V7.842l1.727 4.702h1.612l1.727-4.711v4.711zm-8.943 0h2.063l-2.965-6.717h-2.36l-2.965 6.717h2.015l.556-1.344h3.09l.566 1.344zm-1.2-2.85h-1.822l.911-2.197.912 2.197z"
              fill="#006FCF"
            />
          </G>
          <Defs>
            <ClipPath id="clip0_6163_24390">
              <Rect x={15.25} y={1} width={45} height={30} rx={4} fill="#fff" />
            </ClipPath>
          </Defs>
        </Svg>
      </>
    );
  }
}
