export const historyListStructure = (
  offset: number,
  limit: number | string,
) => {
  return {
    items: [],
    metadata: {
      pagination: {
        limit,
        offset,
        previousOffset: null,
        nextOffset: null,
        currentPage: null,
        pageCount: 0,
        totalCount: 0,
      },
      sortedBy: [],
    },
  };
};
