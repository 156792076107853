import {TextStyle, ViewStyle} from 'react-native';
import Styles from 'src/components/Styles';

export function getRightReadMarkIconColor(): string {
  return Styles.Colors.CompanyKitchen.black;
}

export function getUnreadMessageIconColor(): string {
  return '#409F68';
}

export function tabBarRoundedBtnColor(): string {
  return Styles.Colors.CompanyKitchen.green;
}

export function tabBarTextColorColor(isFocused: boolean): TextStyle {
  return isFocused
    ? {color: Styles.Colors.CompanyKitchen.black}
    : {color: Styles.Colors.CompanyKitchen.black};
}

export function getDealClaimButtonViewStyle(): ViewStyle {
  return {
    borderColor: Styles.Colors.CompanyKitchen.green,
    backgroundColor: Styles.Colors.CompanyKitchen.green,
  };
}

export function getDealClaimButtonTextStyle(): TextStyle {
  return {
    color: Styles.Colors.CompanyKitchen.black,
  };
}

export default {
  getRightReadMarkIconColor,
  getUnreadMessageIconColor,
  tabBarRoundedBtnColor,
  tabBarTextColorColor,
  getDealClaimButtonViewStyle,
  getDealClaimButtonTextStyle,
};
