import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import RedesignDescriptor from './campus-module-redesign-descriptor';
import CanteenDescriptor from './campus-module-canteen-descriptor';
import RefiveDescriptor from './campus-module-refive-descriptor';
import CompanyKitchenDescriptor from './campus-module-companykitchen-descriptor';

const typeDescriber = {
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
