import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IProps {
  color?: string;
  size?: number;
}
const CheckBoxUnchecked = ({color = '#707070', size = 24}: IProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      d="M2.66669 0.5H21.3333C22.524 0.5 23.5 1.47602 23.5 2.66669V21.3333C23.5 22.524 22.524 23.5 21.3333 23.5H2.66669C1.47602 23.5 0.5 22.524 0.5 21.3333V2.66669C0.5 1.47602 1.47602 0.5 2.66669 0.5Z"
      fill="white"
      stroke={color}
    />
  </Svg>
);
export default CheckBoxUnchecked;
