import React from 'react';
import {
  StyleSheet,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
} from 'react-native';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import AVText from 'src/components/elements/AVText';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import DealModel from 'src/models/Moblico/Deal';
import DefaultOffers from 'src/components/img/svg/DefaultOffers';
import {ensureHttps} from 'src/services/StringUtils';
import moment from 'moment';
import AddIconBold from 'src/components/img/svg/AddIconBold';
import {ActivityIndicator} from 'react-native-paper';
import {DealStatus} from 'src/services/aws/DealRepository';
import CheckIcon from 'src/components/img/svg/CheckIcon';
import {OffersButtonStates} from 'src/components/screens/offers/OffersScreen';
import {getDescriber} from './descriptor/DescriptorType';

const {
  primaryColor,
  deafultIconInnerColor,
  offerFontFamily,
  getTextColor,
  offerStyles,
  buttonAndIconColor,
  addIconColor,
  checkIconSize,
} = getDescriber();

type OfferItemProps = {
  offerItem: DealModel;
  onQuickApplyPress: (offerItem: DealModel) => void;
  openModal: (offerItem: DealModel) => void;
  buttonState?: string;
  index?: number;
};

/** dynamic card width */
const totalHorizontalPadding = 32;
const gapBetweenColumns = 12;

// Screen dimensions
const screenWidth = Dimensions.get('window').width;

const availableWidth = screenWidth - totalHorizontalPadding - gapBetweenColumns;

// Calculate the width of each card
const cardWidth = availableWidth / 2;
/** */

const OfferItem = (props: OfferItemProps) => {
  let buttonText: string;
  let buttonIcon;
  if (props.buttonState === OffersButtonStates.applied) {
    buttonText = Localized.Labels.applied;
    buttonIcon = (
      <CheckIcon
        color={addIconColor()}
        width={checkIconSize()}
        height={checkIconSize()}
      />
    );
  } else {
    buttonText = Localized.Buttons.quick_apply;
    buttonIcon = <AddIconBold color={addIconColor()} />;
  }

  const getDealStatusText = (format) => {
    if (
      (props.offerItem.dealStatus === DealStatus.redeemed ||
        props.offerItem.dealStatus === DealStatus.accepted) &&
      props.offerItem.targetGoalQuantity > 0
    ) {
      return `${Localized.Labels.bought} ${props.offerItem.currentGoalQuantity} ${Localized.Labels.of} ${props.offerItem.targetGoalQuantity}`;
    }
    return `${Localized.Labels.expires} ${moment(
      props.offerItem.endDate,
    ).format(format)}`;
  };

  return (
    <AVTouchableOpacity
      onPress={() => props.openModal(props.offerItem)}
      accessible
    >
      <View
        style={[
          styles.container,
          props.index % 2 === 0
            ? {marginLeft: Styles.Spacing.m3, marginRight: Styles.Spacing.m1}
            : {marginLeft: Styles.Spacing.m1, marginRight: Styles.Spacing.m3},
        ]}
        accessibilityLabel={props.offerItem.name}
        accessible
        aria-label={`${props.offerItem.name}`}
      >
        <View>
          {!props.offerItem.image ? (
            <View
              style={{alignSelf: 'center'}}
              accessible
              accessibilityLabel="Default-Icon"
              aria-label="Default-Icon"
            >
              <DefaultOffers
                color={deafultIconInnerColor()}
                primaryColor={primaryColor()}
              />
            </View>
          ) : (
            <View accessible>
              <Image
                accessible
                accessibilityLabel={`${props.offerItem.name} Image`}
                accessibilityRole="image"
                aria-label={`${props.offerItem.name} Image`}
                role="img"
                style={styles.imageView}
                source={{uri: ensureHttps(props.offerItem.image)}}
                resizeMode="contain"
              />
            </View>
          )}

          <AVText
            accessible
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
            style={styles.offerNameText}
            numberOfLines={2}
            accessibilityLabel={`${props.offerItem.name} Text`}
            accessibilityRole="text"
            aria-label={`${props.offerItem.name} Text`}
          >
            {props.offerItem.name}
          </AVText>
        </View>
        <View style={{marginTop: 19}} accessible>
          <AVText
            accessible
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
            style={offerStyles().offerExpiry}
            numberOfLines={1}
            accessibilityLabel={getDealStatusText('MM/DD/YYYY')}
            accessibilityRole="text"
            aria-label={getDealStatusText('MM/DD/YYYY')}
          >
            {getDealStatusText('MM/DD')}
          </AVText>

          {props.offerItem.dealStatus === DealStatus.pulled && (
            <>
              {props.buttonState === OffersButtonStates.loading ? (
                <View style={styles.spinnerView} accessible>
                  <ActivityIndicator color={buttonAndIconColor()} />
                </View>
              ) : (
                <TouchableOpacity
                  onPress={() => props.onQuickApplyPress(props.offerItem)}
                  style={{
                    minHeight: 44,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <View style={styles.quickApplyBtn} accessible>
                    {buttonIcon}
                    <AVText
                      accessible
                      style={[styles.buttonTextStyle]}
                      accessibilityLabel={`${buttonText}, button`}
                      aria-label={`${buttonText}, button`}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                    >
                      {buttonText}
                    </AVText>
                  </View>
                </TouchableOpacity>
              )}
            </>
          )}
          {(props.offerItem.dealStatus === DealStatus.redeemed ||
            props.offerItem.dealStatus === DealStatus.accepted) && (
            <View
              style={styles.appliedDeal}
              accessibilityLabel={Localized.Labels.applied}
              accessible
            >
              <View
                style={offerStyles().appliedIcon}
                accessibilityLabel="Checkmark Icon"
                accessible
                aria-label="Checkmark Icon"
              >
                <CheckIcon
                  color={addIconColor()}
                  width={checkIconSize()}
                  height={checkIconSize()}
                />
              </View>
              <AVText
                accessible
                style={styles.appliedText}
                numberOfLines={1}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                accessibilityLabel={Localized.Labels.applied}
                accessibilityRole="text"
                aria-label={`${Localized.Labels.applied} Text`}
              >
                {Localized.Labels.applied}
              </AVText>
            </View>
          )}
        </View>
      </View>
    </AVTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: cardWidth,
    height: 205,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Styles.white,
    borderRadius: Styles.Spacing.m2,
    marginVertical: Styles.Spacing.m3,
    borderColor: Styles.inputBgColor,
    borderWidth: 1,
    ...Styles.Style.shadow,
  },
  imageView: {
    width: Styles.Heights.h4,
    height: Styles.Heights.h4,
    alignSelf: 'center',
  },
  offerNameText: {
    color: getTextColor(),
    fontSize: Styles.Fonts.f8,
    fontWeight: '700',
    marginTop: Styles.Fonts.f0,
    textAlign: 'center',
    fontFamily: offerFontFamily(),
    minHeight: 34,
  },
  quickApplyBtn: {
    flexDirection: 'row',
    paddingHorizontal: Styles.Heights.h1 + 2,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingVertical: Styles.Spacing.m2,
    borderRadius: Styles.Spacing.m4,
    borderWidth: 1,
    borderColor: buttonAndIconColor(),
    overflow: 'hidden',
  },
  buttonTextStyle: {
    fontSize: Styles.Fonts.f8,
    fontWeight: '700',
    marginLeft: Styles.Spacing.m1,
    fontFamily: offerFontFamily(),
    color: addIconColor(),
  },
  appliedDeal: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingVertical: 8,
  },
  appliedText: {
    fontSize: Styles.Fonts.f8,
    color: addIconColor(),
    fontWeight: '700',
    marginLeft: Styles.Spacing.m1,
    textAlign: 'center',
    fontFamily: offerFontFamily(),
  },
  spinnerView: {
    height: 33,
    width: 124,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderColor: buttonAndIconColor(),
    borderWidth: 1,
    borderRadius: Styles.Spacing.m4,
    paddingHorizontal: Styles.Heights.h1 + 2,
    paddingVertical: Styles.Spacing.m2,
    overflow: 'hidden',
  },
});

export default OfferItem;
