import React, {ComponentProps, Fragment} from 'react';
import {MemberTierIcon} from './MemberTierIcon';
import {SmartShopperTierIcon} from './SmartShopperTierIcon';
import {TrailblazerTierIcon} from './TrailblazerTierIcon';
import {EliteBuyerTierIcon} from './EliteBuyerTierIcon';
import AccountStore from 'src/stores/AccountStore';

const tierImages = {
  1: MemberTierIcon,
  2: SmartShopperTierIcon,
  3: TrailblazerTierIcon,
  4: EliteBuyerTierIcon,
};

export const UserRewardsTierIcon = ({
  tierName,
  ...props
}: ComponentProps<typeof MemberTierIcon> & {tierName: string}) => {
  const tiers = AccountStore?.getRewardStatusTiers();
  const _tierLevelObj = tiers?.filter((item) => item.name === tierName)[0];
  const _level = _tierLevelObj?.tierLevel || 1;
  const TierIcon = tierImages[_level] ?? Fragment;
  return <TierIcon {...props} />;
};
