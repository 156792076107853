import Settings from 'src/Settings';
import RedesignDescriptor from './onboarding-module-redesign';
import CanteenDescriptor from './onboarding-module-canteen';
import CompanyKitchenDescriptor from './onboarding-module-companykitchen';
import RefiveDescriptor from './onboarding-module-refive';
import BuildTypes from 'src/constants/BuildTypeConstants';

const typeDescriber = {
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
};

export function getOnboardingDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
