import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function SnackDetailsRedesign(props) {
  return (
    <Svg width={54} height={54} viewBox="0 0 54 54" fill="none" {...props}>
      <Path
        d="M21.079 10.077l3.146 5.351H17.598a3.615 3.615 0 01-3.616-3.616 3.615 3.615 0 013.616-3.616h.2c1.346 0 2.603.714 3.28 1.88zM9.643 11.812c0 1.302.316 2.532.868 3.616H6.75a2.89 2.89 0 00-2.893 2.893v5.786A2.89 2.89 0 006.75 27h40.5a2.89 2.89 0 002.893-2.893V18.32a2.89 2.89 0 00-2.893-2.893h-3.76a7.955 7.955 0 00-7.087-11.571h-.2a8.158 8.158 0 00-7.024 4.014L27 11.586 24.821 7.88a8.147 8.147 0 00-7.024-4.023h-.199a7.956 7.956 0 00-7.955 7.955zm30.375 0a3.615 3.615 0 01-3.616 3.616h-6.627l3.146-5.351a3.81 3.81 0 013.282-1.88h.199a3.615 3.615 0 013.616 3.615zM6.75 29.892v15.911a4.34 4.34 0 004.34 4.34h13.017v-20.25H6.75zm23.143 20.25H42.91a4.34 4.34 0 004.339-4.339v-15.91H29.893v20.25z"
        fill="#066DFF"
      />
    </Svg>
  );
}

export default SnackDetailsRedesign;
