import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

type SnackProductProps = {
  width?: number;
  height?: number;
  color?: string;
  iconColor?: string;
};

const SnackProduct = (props: SnackProductProps) => (
  <Svg width={32} height={32} viewBox="0 0 32 32" fill="none">
    <Path
      d="M0 8a8 8 0 018-8h16a8 8 0 018 8v16a8 8 0 01-8 8H8a8 8 0 01-8-8V8z"
      fill={props.color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.03 9.883H17.324a.21.21 0 00.208-.233l-.1-.93a.21.21 0 00-.208-.187H16.13a.21.21 0 00-.209.188l-.1.93a.21.21 0 00.209.232zM18.82 23.039a.21.21 0 01-.221-.028l-.206-.167-.24.112a.21.21 0 01-.278-.281l.334-.693.791-6.385-.155-.754a.21.21 0 01.339-.205l.176.145v-.83c0-2.675-2.026-3.205-2.026-3.814H16.029c0 .516-1.457.977-1.9 2.736l.002.001.213-.186a.21.21 0 01.225-.033l.257.117.213-.186a.21.21 0 01.225-.033l.258.117.213-.186a.21.21 0 01.225-.033l.257.117.213-.186a.209.209 0 01.133-.052h.01a.21.21 0 01.201.252l-.138.788c.265.851.525 2.081.717 3.388.192 1.307.296 2.56.287 3.451l.362.72a.21.21 0 01-.274.286l-.258-.117-.213.186a.21.21 0 01-.225.033l-.258-.117-.213.186a.21.21 0 01-.224.034l-.258-.117-.213.186a.21.21 0 01-.225.033l-.258-.117-.213.186a.21.21 0 01-.225.033l-.257-.117-.213.186a.21.21 0 01-.225.033l-.247-.112v.452c0 .772.626 1.398 1.398 1.398h2.561c.448 0 .846-.21 1.102-.537l-.003-.003-.24.113z"
      fill={props.iconColor ?? '#fff'}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.387 15.214l-.36.169-.307-.252-.36.17-.307-.252-.36.169-.308-.252-.36.169-.307-.252-.36.17-.307-.252.162.788-.8 6.453-.349.724.36-.169.307.252.36-.169.308.252.36-.17.307.252.36-.169.307.252.36-.169.308.252-.163-.788.8-6.453.35-.725zM13.711 19.451a2.155 2.155 0 01-1.727-3.443 2.142 2.142 0 011.732-.87 2.155 2.155 0 011.728 3.444 2.142 2.142 0 01-1.733.87zm.94 1.811l.381.173.315-.275.38.173.316-.275.38.173.315-.275.38.172.316-.274.38.172-.384-.766c.012-.85-.083-2.095-.285-3.469-.202-1.375-.468-2.593-.725-3.404l.149-.845-.315.275-.38-.173-.316.275-.38-.173-.315.275-.381-.173-.315.275-.38-.172-.316.275-.38-.173-.315.275-.38-.173-.316.275-.38-.173-.315.275-.381-.173-.315.275-.38-.173-.316.275-.38-.173.385.767c-.013.85.082 2.094.284 3.469.202 1.374.468 2.593.725 3.404l-.148.845.315-.275.38.173.315-.275.38.172.316-.274.38.172.315-.275.381.173.315-.275.38.173.316-.275z"
      fill={props.iconColor ?? '#fff'}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.789 17.578a1.946 1.946 0 103.851-.566 1.946 1.946 0 00-3.851.566z"
      fill={props.iconColor ?? '#fff'}
    />
  </Svg>
);
export default SnackProduct;
