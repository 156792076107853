import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IArrowStep3Props {
  color?: string;
}

function ArrowStep3(props: IArrowStep3Props) {
  return (
    <Svg width={130} height={121} viewBox="0 0 130 121" fill="none">
      <Path
        d="M12.986 119.27c-3.815-9.79-.429-20.335 4.423-29.817 4.32-8.268 9.733-16.2 15.87-23.677C45.485 51.06 61.154 38.099 79.047 27.805c10.073-5.778 20.905-10.796 32.246-14.72 2.855-1.022 4.514 2.556 1.651 3.502-19.45 6.782-37.396 16.621-52.593 28.682C45.24 57.243 32.68 71.384 23.917 86.856c-5.419 9.548-10.456 20.744-6.503 30.904.397.954-.142 2.093-1.338 2.538-1.203.37-2.693-.074-3.09-1.028z"
        fill={props.color ?? '#fff'}
      />
      <Path
        d="M94.477 50.178c5.377-9.081 10.65-18.228 16.027-27.31.643-1.074 1.2-2.061 1.843-3.135.558-.988 1.399-2.008 1.655-3.114.41-1.432-1.561-1.897-3.025-2.113-5.681-1.037-11.516-1.748-17.368-2.612-6.731-.993-13.47-2.061-20.201-3.054-2.927-.432-2.126-4.293.81-3.786 6.438.95 12.876 1.9 19.322 2.925 6.146.907 12.368 1.65 18.445 2.795 2.454.487 5.036 1.266 6.21 3.13 1.416 2.299-.025 4.772-1.311 6.92l-8.412 14.356a1609.224 1609.224 0 01-9.698 16.503c-1.278 2.224-5.566.796-4.297-1.505z"
        fill={props.color ?? '#fff'}
      />
    </Svg>
  );
}

export default ArrowStep3;
