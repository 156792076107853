import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function GiftIconCP(props) {
  return (
    <Svg width={36} height={32} viewBox="0 0 36 32" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.91 16v12.727a3.182 3.182 0 01-3.183 3.182H5.273a3.182 3.182 0 01-3.182-3.182V16A1.59 1.59 0 01.5 14.41V9.635a3.182 3.182 0 013.182-3.181h5.043A4.773 4.773 0 0113.227.09c1.591 0 2.991.795 3.866 1.973v-.016L18 3.273l.907-1.225v.016C19.782.886 21.182.09 22.773.09a4.773 4.773 0 014.502 6.364h5.043A3.182 3.182 0 0135.5 9.636v4.773A1.59 1.59 0 0133.91 16zM5.272 28.727h11.136V16H5.273v12.727zM30.727 16v12.727H19.591V16h11.136zm-17.5-12.727a1.59 1.59 0 100 3.181 1.59 1.59 0 000-3.181zm7.955 1.59a1.59 1.59 0 113.182 0 1.59 1.59 0 01-3.182 0zm-17.5 4.773v3.182h12.727V9.636H3.682zm15.909 3.182V9.636h12.727v3.182H19.591z"
        fill="#22212D"
      />
    </Svg>
  );
}

export default GiftIconCP;
