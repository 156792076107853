import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import Settings from 'src/Settings';
type ReferralRewardProps = {
  width?: number;
  color?: string;
  height?: number;
  style?: {[index: string]: {[index: string]: number | string}};
};
export default class Referral extends React.Component<ReferralRewardProps> {
  render() {
    return (
      <>
        {Settings.is365Pay() ? (
          <Svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 50 51"
            fill="none"
          >
            <Path
              d="M0 25.5C0 11.693 11.193.5 25 .5s25 11.193 25 25-11.193 25-25 25-25-11.193-25-25z"
              fill="#066DFF"
            />
            <Path
              d="M32.108 29.26a3.01 3.01 0 00-2.872 3.913l-.82.258A3.87 3.87 0 0134.175 29l.511-.513.345.344v1.548h-1.548l-.344-.344.407-.408a2.995 2.995 0 00-1.44-.366zm3.01 3.01a3 3 0 00-.138-.903l.82-.258a3.87 3.87 0 01-5.76 4.432l-.512.513-.344-.344v-1.548h1.548l.344.344-.408.408a3.01 3.01 0 004.45-2.644zm-3.526-1.548h.172v-.516h.688v.516h.172c.38 0 .688.308.688.688v.172h-.688v-.172h-1.031v.32l1.166.233a.688.688 0 01.553.675v.492c0 .38-.308.688-.688.688h-.172v.516h-.688v-.516h-.172a.688.688 0 01-.688-.688v-.172h.688v.172h1.032v-.492l-1.167-.233a.688.688 0 01-.553-.675v-.32c0-.38.308-.688.688-.688z"
              fill="#FEE0E6"
            />
            <Path
              d="M25.308 15.174a5.226 5.226 0 100 10.452 5.226 5.226 0 000-10.452zM24.68 26.66c-3.647 0-7.077.929-10.054 2.56l-.575.312v5.105h13.442l-.183-1.648c-.122-1.097.092-2.215.66-3.161a5.054 5.054 0 011.656-1.685l.49-.306c-.948-.388-3.447-1.179-5.436-1.177z"
              fill="#FEE0E6"
            />
          </Svg>
        ) : (
          <Svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 38 38"
            fill="none"
          >
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28.646 24.104a4.085 4.085 0 00-3.898 5.31l-1.113.35a5.252 5.252 0 017.817-6.015l.695-.695.467.467v2.1h-2.1l-.467-.466.553-.554a4.066 4.066 0 00-1.954-.496zm4.085 4.085c0-.428-.065-.84-.187-1.225l1.114-.35a5.252 5.252 0 01-7.817 6.015l-.696.695-.467-.467v-2.1h2.101l.467.466-.554.554a4.085 4.085 0 006.04-3.588zm-4.785-2.1h.234v-.701h.934v.7h.233c.516 0 .934.418.934.934v.234h-.934v-.234h-1.4v.434l1.583.317c.437.087.75.47.75.915v.668a.934.934 0 01-.933.934h-.233v.7h-.934v-.7h-.234a.934.934 0 01-.933-.934v-.233h.933v.233h1.4v-.667l-1.583-.317a.933.933 0 01-.75-.915v-.434c0-.516.418-.934.933-.934z"
              fill="#003349"
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.419 4.986a7.093 7.093 0 100 14.186 7.093 7.093 0 000-14.186zm-.854 15.589c-4.95 0-9.604 1.26-13.645 3.473l-.78.424V31.4h18.242l-.248-2.236c-.165-1.49.125-3.006.897-4.29a6.86 6.86 0 012.245-2.286l.665-.416c-1.286-.527-4.677-1.6-7.376-1.597z"
              fill="#003349"
            />
          </Svg>
        )}
      </>
    );
  }
}
