import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import StackTransitionPreset from '../StackTransitionPreset';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import BalancesScreenNew from 'src/components/screens/account/BalancesScreen';
import ScanAccountScreen from 'src/components/screens/scanAccount/ScanAccountScreen';
import CardsScreen from 'src/components/screens/funding/CreditCardsScreen';
import EditMarketCardScreen from 'src/components/screens/account/EditMarketCardScreen';
import SnackDetailScreen from 'src/components/screens/sendSnack/SnackDetailScreen';

const ScanStack = createStackNavigator();

const components = [
  {component: ScanAccountScreen, name: AppRoutes.ScanAccount},
  {component: BalancesScreenNew, name: AppRoutes.Balances},
  {component: CardsScreen, name: AppRoutes.PDCards},
  {component: EditMarketCardScreen, name: AppRoutes.EditMarketCard},
  {
    component: SnackDetailScreen,
    name: AppRoutes.SnackDetail,
  },
];

const ScanNavigator = () => {
  return (
    <ScanStack.Navigator
      initialRouteName={AppRoutes.ScanAccount}
      screenOptions={{
        ...StackTransitionPreset,
        title: DeviceInfo.getApplicationName(),
        headerShown: false,
      }}
    >
      {components.map((screen, index) => (
        <ScanStack.Screen
          key={index}
          name={screen.name}
          component={screen.component}
        />
      ))}
    </ScanStack.Navigator>
  );
};

export default ScanNavigator;
