import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import OfferScreen from 'src/components/screens/offers/OffersScreen';
import SnackDetailScreen from 'src/components/screens/sendSnack/SnackDetailScreen';

const Stack = createStackNavigator();
export default function OffersNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={AppRoutes.OffersScreen}
      screenOptions={{
        headerShown: false,
        presentation: 'transparentModal',
        cardStyle: {
          backgroundColor: 'transparent',
        },
        cardOverlayEnabled: true,
      }}
    >
      <Stack.Screen name={AppRoutes.OffersScreen} component={OfferScreen} />
      <Stack.Screen
        name={AppRoutes.SnackDetail}
        component={SnackDetailScreen}
      />
    </Stack.Navigator>
  );
}
