import React, {useEffect, useState} from 'react';
import {View, StyleSheet, Keyboard} from 'react-native';
import Styles from '../../Styles';

import {WithLocalizedProps} from 'src/types/PropTypes';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import {getDescriber} from './descriptor/DescriptorType';

type SearchFieldProps = WithLocalizedProps & {
  onChangeText: (text: string) => void;
  value: string;
  currentCategory?: string;
  isproductCat?: boolean;
  isChooseContact?: boolean;
};

const SearchField = (props: SearchFieldProps) => {
  const [keyboardStatus, setKeyboardStatus] = useState('Keyboard Hidden');
  const [placeholder, setPlaceholder] = useState(
    props.isproductCat &&
      getDescriber().searchFieldLabelDescriptor(props.strings),
  );
  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardStatus('Keyboard Shown');
    });
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardStatus('Keyboard Hidden');
    });
    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);
  const searchBottomSpacing = () => {
    if (keyboardStatus === 'Keyboard Hidden') {
      return props.value.length >= 1 ? 30 : 10;
    } else if (keyboardStatus === 'Keyboard Shown') {
      return props.value.length >= 1 ? 10 : 30;
    }
  };
  const searchTopSpacing = () => {
    if (keyboardStatus === 'Keyboard Hidden') {
      return props.value.length >= 1 ? 20 : 0;
    } else if (keyboardStatus === 'Keyboard Shown') {
      return props.value.length >= 1 ? 0 : 20;
    }
  };
  return (
    <View
      style={[
        styles.searchFieldContainer,
        props.isproductCat && {
          paddingBottom: searchBottomSpacing(),
          top: searchTopSpacing(),
        },
      ]}
    >
      <AllyTextInput
        accessible={true}
        accessibilityLabel={`Search for products under the "${props.currentCategory}" category`}
        accessibilityValue={{text: props.value}}
        accessibilityRole="search"
        label={
          props.isproductCat &&
          getDescriber().searchFieldLabelDescriptor(props.strings)
        }
        value={props.value}
        onFocus={() =>
          setPlaceholder(
            props.isproductCat &&
              getDescriber().searchFieldLabelDescriptor(props.strings),
          )
        }
        onChangeText={props.onChangeText}
        autoCapitalize="none"
        hasIcon={props.value ? true : false}
        iconName="closecircleo"
        iconLabel="close icon"
        iconPressed={
          props.isproductCat || props.isChooseContact
            ? () => props.onChangeText('')
            : null
        }
        textStyle={
          props.isproductCat
            ? {
                borderRadius: 32,
                borderColor: '#C2C2C5',
                shadowOffset: {
                  width: Styles.Spacing.m1,
                  height: Styles.Spacing.m1,
                },
                shadowOpacity: 0.07,
                shadowRadius: Styles.Spacing.m1,
                elevation: 5,
                shadowColor: '#000',
                borderWidth: 1,
              }
            : null
        }
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
        isProductSearch={true}
        keyboardStatus={keyboardStatus}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  searchFieldContainer: {
    paddingTop: Styles.Spacing.m2,
    paddingBottom: Styles.Spacing.m3,
    paddingHorizontal: Styles.Spacing.m3,
  },
  searchText: {
    flex: 1,
    fontSize: Styles.Fonts.f1,
    marginLeft: Styles.Spacing.m2,
  },
});

export default SearchField;
