import _ from 'lodash';
import React from 'react';
import {AppState, InteractionManager, Linking, Platform} from 'react-native';
import {connect} from 'react-redux';
import ActionsFactory from 'src/actions/ActionsFactory';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Localized from 'src/constants/AppStrings';
import BuildTypes from 'src/constants/BuildTypeConstants';
import CartTypes from 'src/constants/cart/CartTypes';
import {authStore} from 'src/init';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import {CreditCard} from 'src/models/CreditCard';
import {EnvironmentKey} from 'src/models/Environment';
import LocationFeatures from 'src/models/LocationFeatures';
import {PaymentCredentials} from 'src/models/PaymentCredentials';
import OneSignal from 'src/nativeModules/OneSignal';
import PassKit from 'src/nativeModules/PassKit';
import TabNavigator from 'src/navigators/TabNavigator';
import BottomTabNavigator from 'src/navigators/BottomTabNavigator';
import MainConsumerProvider from 'src/providers/mainConsumer';
import {store, RootState} from 'src/redux/store';
import MessageRepository from 'src/services/aws/MessageRepository';
import PersistentStore from 'src/services/PersistentStoreService';
import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import MachineStore from 'src/stores/MachineStore';
import {DiscountItem, PromotionType} from 'src/types/Promotions';
import {RewardType} from 'src/types/Rewards';
import Util from 'src/Util';
import DeepLinkHandler from '../elements/DeepLinkHandler';
import uuid from 'src/nativeModules/UUID';
import {
  alertError,
  alertSuccess,
  confirm,
  confirmNonDismissable,
} from '../helpers/AlertHelper';
import ScreenContext from '../ScreenContext';
import LoadingScreen from './LoadingScreen';
import CrashlyticsService from 'src/nativeModules/Crashlytics';
import {UrlTypes} from '../../types/UrlTypes';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
import {NavigationProp} from '@react-navigation/native';
import {NativeEventSubscription} from 'react-native/Libraries/EventEmitter/RCTNativeAppEventEmitter';
import {fetchRemoteConfig} from 'src/redux/slices/firebaseRemoteConfigSlice';
import {linkPayroll} from 'src/components/helpers/LinkPayrollHelper';
import {MachineType} from '../../types/MachineType';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import DealService from 'src/services/DealService';
import DealModel from 'src/models/Moblico/Deal';

enum AppUpdateAlertType {
  SOFT_UPDATE,
  FORCE_UPDATE,
  NO_UPDATE_ALERT,
}
enum AllAlertStatus {
  ALERT_SHOWN,
  NO_ALERT_SHOWN,
}

let appUpdateAlertType: AppUpdateAlertType = AppUpdateAlertType.NO_UPDATE_ALERT;
let allAlertStatus: AllAlertStatus = AllAlertStatus.NO_ALERT_SHOWN;

const DISCOUNT_AMOUNT_TYPE = 2;
type MainConsumerScreenProps = {
  navigation: NavigationProp<MainConsumerScreen>;
  paymentCredentials: PaymentCredentials;
  creditCards: Array<CreditCard>;
  balance: number;
  rewards: number;
  env: EnvironmentKey;
  serviceUrls: {[key: string]: string};
  firebaseConfig: unknown;
  payrollId: string;
  rewardPrograms?: Array<{
    [index: string]: {[index: string]: number | string};
  }>;
};
type MainConsumerScreenState = {
  offline: boolean;
  balance: number;
  rewards: number;
  email: string;
  fullName: string;
  canAddPass: boolean;
  passUrl: string;
  passExists: boolean;
  taxId: string;
  showEatItOrDeleteIt: boolean;
  machinesCount: number;
  messagesCount: number;
  loaded: boolean;
  rewardSteps: Array<RewardType>;
  hasShownPayrollPopup: boolean;
  showOffersTab: boolean;
  showRewardsTab: boolean;
};

class MainConsumerScreen extends React.Component<
  MainConsumerScreenProps,
  MainConsumerScreenState
> {
  currentPrivacyVersion: string;
  currentPrivacyType: string;
  handleAppStateChangeSubscription: NativeEventSubscription;

  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: MainConsumerScreenProps) {
    super(props);
    this.state = {
      offline: false,
      loaded: false,
      balance: props.balance,
      rewards: props.rewards,
      fullName: AccountStore.getFullName(),
      email: AccountStore.getEmail(),
      taxId: AccountStore.getTaxId(),
      canAddPass: false,
      passExists: true,
      passUrl: `${
        props.serviceUrls?.[UrlTypes.gmaapi]
      }/passkit/v1/pass/${AccountStore.getAccountId()}`,
      showEatItOrDeleteIt: this.showEatItOrDeleteIt(),
      machinesCount: MachineStore.getShops().length,
      messagesCount: 0,
      rewardSteps: this.getRewardSteps(),
      hasShownPayrollPopup: false,
      showOffersTab: false,
      showRewardsTab: false,
    };
    this.onPrivacyConfirm = this.onPrivacyConfirm.bind(this);
    this.onPrivacyDecline = this.onPrivacyDecline.bind(this);
    this.onCloseAccount = this.onCloseAccount.bind(this);
    this.showEatItOrDeleteIt = this.showEatItOrDeleteIt.bind(this);
    this.onPromotionSelected = this.onPromotionSelected.bind(this);
    this.getPromotionDescription = this.getPromotionDescription.bind(this);
    this.getScanScreenParams = this.getScanScreenParams.bind(this);
    this._onAccountChange = this._onAccountChange.bind(this);
    this._onBalanceChange = this._onBalanceChange.bind(this);
    this.onMessagesChanged = this.onMessagesChanged.bind(this);
    this.onMachineStoreChange = this.onMachineStoreChange.bind(this);
    this.passAdded = this.passAdded.bind(this);
    this._handleAppStateChange = this._handleAppStateChange.bind(this);
    this.closePayrollLinkModal = this.closePayrollLinkModal.bind(this);
    this.navigatetoLinkPayroll = this.navigatetoLinkPayroll.bind(this);
    this.showPayrollLink = this.showPayrollLink.bind(this);
    this.checkForActiveOffers = this.checkForActiveOffers.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.balance !== prevState.balance) {
      return {
        balance: nextProps.balance,
        rewards: nextProps.rewards,
      };
    }
    if (nextProps.rewardPrograms?.length > 0 || nextProps.rewards > 0) {
      return {showRewardsTab: true};
    }
    return prevState;
  }

  async componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'MainConsumerScreen', {
      ...this.props,
      ...this.state,
    });
    if (Platform.OS != 'web') {
      CrashlyticsService.setUser({
        accountId: AccountStore.getAccountId(),
        email: AccountStore.getEmail(),
        org: AccountStore.getOrg(),
        location: AccountStore.getLocationId(),
        region: AccountStore.getRegion(),
        city: AccountStore.getCity(),
        zip: AccountStore.getZip(),
        currency: AccountStore.getCurrency(),
        environment: this.props.env,
      });
    }

    if (!(await authStore.getRefreshToken()) && !AccountStore.isDemo()) {
      await ActionsFactory.getAccountActions().logout();
      this.context.actions.changeRootRoute(AppRoutes.Login);
      return;
    }

    if (
      Settings.buildType !== BuildTypes.pantry &&
      Settings.buildType !== BuildTypes.social
    ) {
      const result = await PassKit.canAddPass();
      const numUnreadMessages = await MessageRepository.getNumUnreadMessages();

      let passExistsResult = true;

      if (result) {
        passExistsResult = await PassKit.doesPassExist(this.state.passUrl);
      }

      this.setState({
        passExists: passExistsResult,
        canAddPass: result,
        messagesCount: numUnreadMessages,
      });
    }
    await ActionsFactory.getAccountActions().loadRewardStatus(
      AccountStore.getAccountId(),
    );
    await ActionsFactory.getAccountActions().loadRewardProduct(
      AccountStore.getAccountId(),
    );
    this.checkForActiveOffers();
    MessageRepository.addMessageListener(this.onMessagesChanged);
    MachineStore.addChangeListener(this.onMachineStoreChange);
    this.setState({
      loaded: true,
    });

    await ActionsFactory.getAccountActions().loadMoblicoPromotions(
      AccountStore.getEmail(),
    );

    AccountStore.addChangeListener(this._onAccountChange);
    AccountStore.addBalanceChangedListener(this._onBalanceChange);

    this._onAccountChange();

    this._onBalanceChange();

    if (Platform.OS === 'web') {
      OneSignal.promptForPushNotificationsWithUserResponse(() => ({}));
      AccountStore.waitForData().then(() =>
        OneSignal.getTags((tags) => {
          if (
            !AccountStore.isDemo() &&
            (!tags || tags.mka !== AccountStore.getAccountId())
          ) {
            OneSignal.sendTags({
              email: AccountStore.getEmail(),
              mka: AccountStore.getAccountId(),
              env: this.props.env,
            });
            OneSignal.setExternalUserId(AccountStore.getAccountId());
          }
        }),
      );
    }

    InteractionManager.runAfterInteractions(async () => {
      // this.context.actions.showSpinner();
      try {
        const accountResponse =
          await ActionsFactory.getAccountActions().reloadConsumerData({
            accountId: AccountStore.getAccountId(),
            accountBalanceId: AccountStore.getAccountBalanceId(),
            email: AccountStore.getEmail(),
            userInitiated: true,
          });
        if (accountResponse) {
          if (!accountResponse?.privacy.version) {
            this.currentPrivacyVersion = accountResponse?.version;
            this.currentPrivacyType = accountResponse?.type;
            NavActions.replace(AppRoutes.PrivacyPolicy, {
              title: Localized.Labels.updated_privacy_policy,
              onDecline: this.onPrivacyDecline,
              onConfirm: this.onPrivacyConfirm,
              version: this.currentPrivacyVersion,
              locale: accountResponse?.localization?.locale,
              type: this.currentPrivacyType,
            });
          }

          const hasSentExternalId = await PersistentStore.hasSentExternalId();

          if (!hasSentExternalId) {
            OneSignal.setExternalUserId(AccountStore.getAccountId());
            await PersistentStore.setSentExternalId();
          }

          if (Platform.OS !== 'android') {
            Events.AppleWallet.trackEvent('AppleWallet:Usage', {
              canUse: this.props.paymentCredentials.applePayAvailable
                ? 'Yes'
                : 'No',
            });
          }
        }
        this.context.actions.hideSpinner();
        this.showAlerts(accountResponse);
        store.dispatch(fetchRemoteConfig(false));
      } catch {
        this.setState({
          offline: true,
        });
      }
    });

    this.handleAppStateChangeSubscription = AppState.addEventListener(
      'change',
      this._handleAppStateChange,
    );
  }

  componentDidUpdate(prevProps: Readonly<MainConsumerScreenProps>): void {
    this.updateAlertState(prevProps);
  }

  async showPayrollLink(currentState: boolean) {
    if (currentState) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      await delay(1000);
    }
    const payrolldata = await PersistentStore.getPayrollImportData();
    if (
      (payrolldata?.importId && !this.props.payrollId) ||
      (payrolldata?.importId &&
        this.props.payrollId &&
        payrolldata.importId !== this.props.payrollId)
    ) {
      confirm(
        Localized.Labels.do_you_want_to_link_payroll,
        () => {
          this.navigatetoLinkPayroll(
            payrolldata?.importId,
            payrolldata?.locationId,
          );
        },
        () => {
          this.closePayrollLinkModal();
        },
        Localized.Labels.payroll_link,
        Localized.Buttons.cancel,
        Localized.Buttons.link_now,
      );

      this.setState({hasShownPayrollPopup: true});
    }
  }

  updateAlertState(prevProps) {
    const firebaseConfig = this.props.firebaseConfig;
    if (
      firebaseConfig !== null &&
      prevProps.firebaseConfig !== firebaseConfig
    ) {
      if (firebaseConfig['softUpdate']) {
        appUpdateAlertType = AppUpdateAlertType.SOFT_UPDATE;
      } else {
        appUpdateAlertType = AppUpdateAlertType.FORCE_UPDATE;
      }
      this.showAlerts(null);
    }
  }

  async showAlerts(accountResponse) {
    const payrolldata = await PersistentStore.getPayrollImportData();
    new Promise((resolve, reject) => {
      if (appUpdateAlertType == AppUpdateAlertType.FORCE_UPDATE) {
        // Reject further alert flow as this is a force update
        allAlertStatus = AllAlertStatus.ALERT_SHOWN;
        const firebaseConf = this.props.firebaseConfig;
        this.showForceUpdate(firebaseConf);
        reject();
      } else resolve(true);
    })
      .then(async () => {
        if (!this.state.hasShownPayrollPopup) {
          await this.showPayrollLink(false);
        }
      })
      .then(async () => {
        if (this.state.hasShownPayrollPopup && payrolldata?.importId) {
          return;
        }
        const email = AccountStore.getEmail();
        const hasSeenPayrollMessage =
          await PersistentStore.hasSeenPayrollDeductAvailableMessage(email);
        if (
          accountResponse &&
          !accountResponse?.data?.currentprivacyversion &&
          accountResponse?.locationFeatures?.showPayroll &&
          !hasSeenPayrollMessage
        ) {
          await PersistentStore.setSeenPayrollDeductAvailableMessage(email);
          allAlertStatus = AllAlertStatus.ALERT_SHOWN;
          return new Promise((resolve) => {
            confirm(
              Localized.Labels.would_you_like_to_manage_payroll_deduct,
              () => {
                if (!Settings.is365Pay()) {
                  this.navigateToPayrollDeduct();
                  this._setToNoAlertShown();
                  resolve(false);
                } else {
                  FirebaseAnalytic.trackEvent(
                    'navigateToPayrollDeduct',
                    'MainConsumerScreen',
                    {
                      ...this.props,
                      ...this.state,
                    },
                  );
                  this._setToNoAlertShown();

                  NavActions.navigate(AppRoutes.Account);
                  NavActions.navigate(AppRoutes.PayrollDeduct);
                  resolve(false);
                }
              },
              () => {
                this._setToNoAlertShown();
                resolve(true);
              },
              Localized.Labels.payroll_deduct_now_available,
              Settings.is365Pay()
                ? Localized.Labels.not_now
                : Localized.Labels.no,
            );
          });
        } else return true;
      })
      .then((result) => {
        if (
          result &&
          appUpdateAlertType == AppUpdateAlertType.SOFT_UPDATE &&
          this._isNoAlertShowing()
        ) {
          const firebaseConfig = this.props.firebaseConfig;
          allAlertStatus = AllAlertStatus.ALERT_SHOWN;
          confirm(
            firebaseConfig['alertMessage'],
            () => {
              this._setToNoAlertShown();
              appUpdateAlertType = AppUpdateAlertType.NO_UPDATE_ALERT;
              Linking.openURL(firebaseConfig['alertUpdateActionUrl']);
              FirebaseAnalytic.trackEvent(
                'softUpdateAlertLinkClick',
                'MainConsumerScreen',
                {
                  ...this.props,
                  ...this.state,
                },
              );
            },
            () => {
              this._setToNoAlertShown();
              appUpdateAlertType = AppUpdateAlertType.NO_UPDATE_ALERT;
              FirebaseAnalytic.trackEvent(
                'softUpdateAlertCloseClick',
                'MainConsumerScreen',
                {
                  ...this.props,
                  ...this.state,
                },
              );
            },
            firebaseConfig['alertTitle'],
            firebaseConfig['alertCancelButtonTitle'],
            firebaseConfig['alertUpdateButtonTitle'],
          );

          FirebaseAnalytic.trackEvent('softUpdateAlert', 'MainConsumerScreen', {
            ...this.props,
            ...this.state,
          });
        }
      })
      .catch(async (error) => {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'MainConsumerScreen showAlerts',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'MainConsumerScreen showAlerts',
          generateErrorMessage(error),
          guid,
        );
      });
  }

  showForceUpdate(firebaseConfig) {
    confirmNonDismissable(
      firebaseConfig['alertMessage'],
      () => {
        const config = firebaseConfig;
        this.showForceUpdate(config);
        Linking.openURL(firebaseConfig['alertUpdateActionUrl']);
        FirebaseAnalytic.trackEvent(
          'forceUpdateAlertLinkClick',
          'MainConsumerScreen',
          {
            ...this.props,
            ...this.state,
          },
        );
      },
      firebaseConfig['alertTitle'],
      firebaseConfig['alertUpdateButtonTitle'],
    );
    FirebaseAnalytic.trackEvent('forceUpdateAlert', 'MainConsumerScreen', {
      ...this.props,
      ...this.state,
    });
  }

  async componentWillUnmount() {
    AccountStore.removeChangeListener(this._onAccountChange);
    AccountStore.removeBalanceChangedListener(this._onBalanceChange);
    MachineStore.removeChangeListener(this.onMachineStoreChange);
    this.handleAppStateChangeSubscription?.remove();
    MessageRepository.removeMessageListener(this.onMessagesChanged);
  }

  _isNoAlertShowing = () => allAlertStatus != AllAlertStatus.ALERT_SHOWN;
  _setToNoAlertShown = () => (allAlertStatus = AllAlertStatus.NO_ALERT_SHOWN);
  _enableShowAlertAfterMinimized = () => Platform.OS == 'ios';

  async closePayrollLinkModal() {
    await PersistentStore.setPayrollImportData(null);
  }

  _handleAppStateChange(currentAppState) {
    FirebaseAnalytic.trackEvent('_handleAppStateChange', 'MainConsumerScreen', {
      ...this.props,
      ...this.state,
      currentAppState,
    });
    switch (currentAppState) {
      case 'active':
        //Dont reload data if the user is funding the account
        const params = NavActions.navigatorRef.getCurrentRoute()
          ?.params as Record<string, string>;
        if (AccountStore.getAccountId() && !params?.cartType) {
          ActionsFactory.getAccountActions()
            .reloadConsumerData(this.getReloadParams())
            .catch();
        }

        this.showPayrollLink(true);
        if (this._enableShowAlertAfterMinimized() && this._isNoAlertShowing()) {
          store.dispatch(fetchRemoteConfig(true));
        }
        break;
    }
  }

  getReloadParams() {
    return {
      accountId: AccountStore.getAccountId(),
      accountBalanceId: AccountStore.getAccountBalanceId(),
      email: AccountStore.getEmail(),
      userInitiated: true,
    };
  }

  async checkForActiveOffers() {
    let dealList: DealModel[] = [];
    if (
      AccountStore.getConsumerEngagementId() &&
      AccountStore.isConsumerEngagementEnabled()
    ) {
      await DealService.loadMoblicoDeals(AccountStore.getEmail());
      dealList = DealService.getDeals();
    }
    const activeDealList = dealList.filter((deal) =>
      DealService.isDealActive(deal),
    );
    if (activeDealList.length > 0) {
      !this.state.showOffersTab && this.setState({showOffersTab: true});
    } else {
      this.state.showOffersTab && this.setState({showOffersTab: false});
    }
  }

  navigateToPayrollDeduct() {
    FirebaseAnalytic.trackEvent(
      'navigateToPayrollDeduct',
      'MainConsumerScreen',
      {
        ...this.props,
        ...this.state,
      },
    );

    NavActions.navigate(AppRoutes.Account);
    NavActions.navigate(AppRoutes.PayrollDeduct);
  }
  async navigatetoLinkPayroll(importId, locationId) {
    FirebaseAnalytic.trackEvent('navigateLinkPayroll', 'MainConsumerScreen', {
      ...this.props,
      ...this.state,
    });
    try {
      const linkResult = await linkPayroll(
        importId,
        locationId,
        AccountStore.getAccountId(),
        AccountStore.getLocationId(),
      );
      if (linkResult) {
        await PersistentStore.setPayrollImportData(null);

        // Reload consumer data after linking payroll
        const callbackFn = () => {
          setTimeout(() => {
            ActionsFactory.getAccountActions()
              .reloadConsumerData({
                accountId: AccountStore.getAccountId(),
                accountBalanceId: AccountStore.getAccountBalanceId(),
                email: AccountStore.getEmail(),
                userInitiated: true,
              })
              .then((accountResponse) => {
                this.showAlerts(accountResponse);
              });
          }, 1000);
        };
        alertSuccess(Localized.Success.payroll_linked_successfully, callbackFn);
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'MainConsumerScreen:navigatetoLinkPayroll',
        error.message ? error.message : error.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MainConsumerScreen:navigatetoLinkPayroll',
        error.message ? error.message : error.toString(),
      );
    }
  }

  passAdded() {
    this.setState(
      {
        passExists: true,
      },
      () => {
        FirebaseAnalytic.trackEvent('passAdded', 'MainConsumerScreen', {
          ...this.props,
          ...this.state,
        });
      },
    );
  }

  async onMessagesChanged() {
    this.setState(
      {
        messagesCount: await MessageRepository.getNumUnreadMessages(),
      },
      () => {
        FirebaseAnalytic.trackEvent('onMessagesChanged', 'MainConsumerScreen', {
          ...this.props,
          ...this.state,
        });
      },
    );
  }

  onMachineStoreChange() {
    const shops = MachineStore.getShops();
    this.setState(
      {
        machinesCount: shops.length,
      },
      () => {
        FirebaseAnalytic.trackEvent(
          'onMachineStoreChange',
          'MainConsumerScreen',
          {
            ...this.props,
            ...this.state,
          },
        );
      },
    );
  }

  getRewardSteps() {
    const thresholdResponse = AccountStore.getThresholdsResponse();
    let rewardSteps = [];

    if (thresholdResponse?.status === 'ok') {
      rewardSteps = thresholdResponse.data;
    }
    FirebaseAnalytic.trackEvent('getRewardSteps', 'MainConsumerScreen', {
      ...this.props,
      ...this.state,
      thresholdResponse,
      rewardSteps,
    });
    return rewardSteps;
  }

  _onAccountChange() {
    this.checkForActiveOffers();
    const newName = AccountStore.getFullName();
    const newEmail = AccountStore.getEmail();
    const newTaxId = AccountStore.getTaxId();
    const showEatIt = this.showEatItOrDeleteIt();
    const rewardSteps = this.getRewardSteps();
    FirebaseAnalytic.trackEvent('_onAccountChange', 'MainConsumerScreen', {
      ...this.props,
      ...this.state,
      newName,
      newEmail,
      newTaxId,
      showEatIt,
      rewardSteps,
    });
    if (
      newName !== this.state.fullName ||
      newEmail !== this.state.email ||
      newTaxId !== this.state.taxId ||
      showEatIt !== this.state.showEatItOrDeleteIt ||
      !_.isEqual(rewardSteps, this.state.rewardSteps)
    ) {
      this.setState({
        fullName: newName,
        email: newEmail,
        taxId: newTaxId,
        showEatItOrDeleteIt: showEatIt,
        rewardSteps,
      });
    }
    const accountId = AccountStore.getAccountId();
    if (accountId === '-1') {
      this.context.actions.changeRootRoute(AppRoutes.Login);
    }
  }

  showEatItOrDeleteIt() {
    return (
      AccountStore.getShowEatItOrDeleteIt() &&
      Settings.buildType === BuildTypes.canteen
    );
  }

  getCurrentRoute = () => {
    return NavActions.navigatorRef.getCurrentRoute().name;
  };

  _onBalanceChange() {
    const newBalance = AccountStore.getAccountBalance();
    const newRewards = AccountStore.getAccountRewards();
    FirebaseAnalytic.trackEvent('_onBalanceChange', 'MainConsumerScreen', {
      ...this.props,
      ...this.state,
      newBalance,
      newRewards,
    });
    if (
      this.state.balance !== newBalance ||
      this.state.rewards !== newRewards
    ) {
      this.setState({
        balance: newBalance,
        rewards: newRewards,
      });
    }
  }

  getScanScreenParams(
    machine: MachineType | any,
    promotionDescription = '',
  ): any {
    FirebaseAnalytic.trackEvent('getScanScreenParams', 'MainConsumerScreen', {
      ...this.props,
      ...this.state,
      machine,
      promotionDescription,
    });
    return {
      marketName: machine.name,
      locationId: machine.deviceId.toString(),
      locationType: machine.locationType,
      balance: this.state.balance,
      promotionDescription,
      beaconId: machine.broadcastid,
    };
  }

  getPromotionSavingsDescription(promotion: PromotionType): string | string[] {
    FirebaseAnalytic.trackEvent(
      'getPromotionSavingsDescription',
      'MainConsumerScreen',
      {
        ...this.props,
        ...this.state,
        promotion,
      },
    );
    if (promotion.type === DISCOUNT_AMOUNT_TYPE) {
      return Localized.Labels.formatString(
        Localized.Labels.promotion_description_savings,
        Util.formatCurrency(
          this.props,
          promotion.amount,
          AccountStore.getCurrency(),
        ),
      );
    } else {
      return Localized.Labels.formatString(
        Localized.Labels.promotion_description_savings,
        `${promotion.percentage}%`,
      );
    }
  }

  getPromotionMinDescription(promotion: PromotionType) {
    FirebaseAnalytic.trackEvent(
      'getPromotionMinDescription',
      'MainConsumerScreen',
      {
        ...this.props,
        ...this.state,
        promotion,
      },
    );
    return Localized.Labels.formatString(
      Localized.Labels.promotion_description_minimum,
      Util.formatCurrency(
        this.props,
        promotion.transactionMinimum,
        AccountStore.getCurrency(),
      ),
    );
  }

  getPromotionLimitDescription(promotion: PromotionType) {
    return `\n${Localized.Labels.formatString(
      Localized.Labels.promotion_description_limit,
      promotion.score,
    )}`;
  }

  getPromotionItemText(items: Array<DiscountItem>) {
    const description = [];

    if (items) {
      description.push(this.getItemText(items[0]));
      items.slice(1).forEach((item) => {
        description.push(Localized.Labels.or);
        description.push(this.getItemText(item));
      });
    }
    FirebaseAnalytic.trackEvent('getPromotionItemText', 'MainConsumerScreen', {
      ...this.props,
      ...this.state,
      items,
      description,
    });
    return description;
  }

  getItemText(item: DiscountItem): string {
    FirebaseAnalytic.trackEvent('getItemText', 'MainConsumerScreen', {
      ...this.props,
      ...this.state,
      item,
    });
    if (item.category) {
      return item.category;
    }

    return item.item;
  }

  async onPromotionSelected(
    promotion: PromotionType,
    discountItems: Array<DiscountItem>,
  ) {
    FirebaseAnalytic.trackEvent('onPromotionSelected', 'MainConsumerScreen', {
      ...this.props,
      ...this.state,
      promotion,
      discountItems,
    });
    if (promotion.video) {
      Linking.openURL(
        'https://visa.com/NFLUltimateFanExperienceFullRules',
      ).catch();
    } else if (Platform.OS === 'web') {
      alertSuccess(
        Localized.Errors.promotion_only_available_market,
        undefined,
        Localized.Errors.sorry,
      );
    } else {
      const locationId = AccountStore.getLocationId();

      if (locationId) {
        try {
          const location = await ActionsFactory.getAccountActions().getLocation(
            locationId,
          );
          const locationFeatures = new LocationFeatures(
            location.locationFeatures,
          ); // do after confirmation

          if (!locationFeatures.HasScanAndPay) {
            alertError(Localized.Errors.discount_available_at_kiosk);
          } else if (!MachineStore.isLocationInList(locationId)) {
            alertError(
              Localized.Errors.you_must_be_within_range_to_redeem_discount,
              null,
              undefined,
              '',
            );
          } else {
            const params = this.getScanScreenParams(
              {
                name: AccountStore.getLocationName(),
                deviceId: locationId,
                locationType: AccountStore.getLocationType(),
                broadcastid:
                  AccountStore.getLocationBroadcastId() || locationId,
              },
              this.getPromotionDescription(promotion, discountItems),
            );
            FirebaseAnalytic.trackEvent(
              'onPromotionSelected AppRoutes.Scan',
              'MainConsumerScreen',
              {
                ...this.props,
                ...this.state,
                promotion,
                discountItems,
                params,
                navigate: AppRoutes.Scan,
              },
            );
            NavActions.navigate(AppRoutes.Scan, {
              ...params,
              cartType: CartTypes.ScanAndPay,
            });
          }
        } catch (error) {
          CrashlyticsEvents.log(
            'Exception',
            'MainConsumerScreen:onPromotionSelected',
            generateErrorMessage(error),
          );
          Events.Error.trackEvent(
            'Exception',
            'MainConsumerScreen:onPromotionSelected',
            generateErrorMessage(error),
          );
        }
      }
    }
  }

  getPromotionDescription(
    promotion: PromotionType,
    discountItems: Array<DiscountItem>,
  ): string {
    let description = [Localized.Labels.add_a];
    const items = discountItems.filter(
      (item) => item.discountHeaderId === promotion.discountHeaderId,
    );
    description = description.concat(this.getPromotionItemText(items));

    const promotionSavingsDescription =
      this.getPromotionSavingsDescription(promotion);
    if (typeof promotionSavingsDescription === 'string') {
      description.push(promotionSavingsDescription);
    } else {
      description.concat(promotionSavingsDescription);
    }

    if (promotion.transactionMinimum > 0) {
      const promotionMinDescription =
        this.getPromotionMinDescription(promotion);
      if (typeof promotionMinDescription === 'string') {
        description.push(promotionMinDescription);
      } else {
        description.concat(promotionMinDescription);
      }
    }

    if (promotion.score > 0) {
      description.push(this.getPromotionLimitDescription(promotion));
    }

    FirebaseAnalytic.trackEvent(
      'getPromotionDescription',
      'MainConsumerScreen',
      {
        ...this.props,
        ...this.state,
        promotion,
        discountItems,
        description,
      },
    );
    return description.join(' ');
  }

  onPrivacyConfirm() {
    ActionsFactory.getAccountActions()
      .acceptPrivacy(
        AccountStore.getAccountId(),
        this.currentPrivacyVersion,
        Util.getCurrentDate(),
      )
      .catch();
    NavActions.replace(AppRoutes.Main);
  }

  async onCloseAccount() {
    ActionsFactory.getAccountActions()
      .deleteAccount(AccountStore.getAccountId(), AccountStore.getEmail())
      .catch();
    // Need to navigate away from the privacy-policy screen
    // because it's in the LoginNavigator as well.
    NavActions.replace(AppRoutes.MyModal);
    await AccountStore.logout();
    this.context.actions.changeRootRoute(AppRoutes.Login);
  }

  onPrivacyDecline() {
    confirm(
      Localized.Labels.declining_privacy_policy,
      this.onCloseAccount,
      undefined,
      undefined,
      Localized.Buttons.cancel,
      Localized.Buttons.confirm,
    );
  }

  render() {
    if (!this.state.loaded) {
      return <LoadingScreen />;
    } else {
      return (
        <MainConsumerProvider
          accountId={AccountStore.getAccountId()}
          oldContext={{
            state: {
              creditCards: this.props.creditCards,
              ...this.context.state,
              ...this.state,
              shopBadgeCount: this.state.machinesCount,
              inboxCount: this.state.messagesCount,
            },
            actions: {
              ...this.context.actions,
              promotionSelected: this.onPromotionSelected,
              getScanScreenParams: this.getScanScreenParams,
              passAdded: this.passAdded,
            },
          }}
        >
          <DeepLinkHandler />
          {Settings.isNewUI() && Platform.OS !== 'web' ? (
            <BottomTabNavigator
              showOffer={this.state.showOffersTab}
              showRewards={this.state.showRewardsTab}
            />
          ) : (
            <TabNavigator />
          )}
        </MainConsumerProvider>
      );
    }
  }
}

const ConnectedMainConsumerScreen = connect((state: RootState) => ({
  paymentCredentials: state.account.paymentCredentials,
  creditCards: state.account.creditCards,
  balance: state.account.account.defaultBalance?.balance,
  rewards: state.account.account.points,
  rewardPrograms: state.account.account.rewardPrograms,
  env: state.environment?.env,
  serviceUrls: state.environment?.serviceUrls,
  firebaseConfig: state.firebaseConfig?.data,
  payrollId: state.account.account.payrollIdentifier?.id,
}))(MainConsumerScreen);

export default ConnectedMainConsumerScreen;
