import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const CoffeeIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    {...props}
  >
    <Path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 8h1a4 4 0 1 1 0 8h-1m0-8H1v9a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V8ZM5 1v3m4-3v3m4-3v3"
    />
  </Svg>
);
export default CoffeeIcon;
