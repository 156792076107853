import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

type DealIconProps = {
  width: number;
  height: number;
  bgColor: string;
};

export default class DealDetailIcon extends React.Component<DealIconProps> {
  render() {
    return (
      <>
        <Svg
          width={this.props.width}
          height={this.props.height}
          viewBox="0 0 54 54"
          fill="none"
        >
          <Path
            d="M3.85693 8.43773V24.2053C3.85693 25.9983 4.56663 27.7174 5.83774 28.983L24.4806 47.5455C27.1287 50.1823 31.4187 50.1823 34.0668 47.5455L48.2079 33.4655C50.856 30.8287 50.856 26.5573 48.2079 23.9205L29.565 5.35804C28.2939 4.09242 26.5673 3.38578 24.7666 3.38578H8.94135C6.13433 3.37523 3.85693 5.64281 3.85693 8.43773ZM15.7206 11.8127C16.6196 11.8127 17.4817 12.1683 18.1174 12.8012C18.7531 13.4342 19.1102 14.2926 19.1102 15.1877C19.1102 16.0828 18.7531 16.9413 18.1174 17.5742C17.4817 18.2072 16.6196 18.5627 15.7206 18.5627C14.8216 18.5627 13.9594 18.2072 13.3238 17.5742C12.6881 16.9413 12.331 16.0828 12.331 15.1877C12.331 14.2926 12.6881 13.4342 13.3238 12.8012C13.9594 12.1683 14.8216 11.8127 15.7206 11.8127Z"
            fill="#066DFF"
          />
        </Svg>
      </>
    );
  }
}
