import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

export default class Chat extends React.Component {
  render() {
    return (
      <Svg width="100" height="92" viewBox="0 0 100 92" fill="none">
        <Path
          d="M65.2233 64.3697C60.4188 72.9109 49.5499 78.8846 36.9371 78.8846C32.827 78.8846 28.8307 78.2562 25.0618 77.0163L23.7911 76.5984L22.7141 77.3896C16.9769 81.6072 10.9832 84.4051 6.6335 86.0998C10.9387 79.1351 12.6364 72.2779 12.8239 71.4835L13.1466 70.1176L12.1786 69.1005C8.14999 64.866 6.02199 59.8356 6.02199 54.5548C6.02199 45.2531 12.6902 37.156 22.4621 33.0628C22.3745 32.1379 22.3284 31.2052 22.3284 30.268C22.3284 29.3308 22.373 28.412 22.4606 27.4932C18.475 28.9159 14.8044 30.9364 11.6193 33.4946C4.66376 39.0811 0.833374 46.5606 0.833374 54.5548C0.833374 60.736 3.10733 66.593 7.42632 71.5818C6.73337 73.9572 4.95876 79.2242 1.63541 84.2899C0.655145 85.7833 0.573713 87.6086 1.41723 89.1697C2.26074 90.7322 3.86942 91.7018 5.61483 91.7018C6.18486 91.7018 6.75181 91.5988 7.30033 91.396C11.7637 89.7397 18.3106 86.8496 24.7161 82.3401C28.6294 83.4894 32.7349 84.0717 36.9371 84.0717C46.4769 84.0717 55.4683 81.0679 62.2564 75.6135C66.457 72.2394 69.5146 68.174 71.28 63.7228C69.2903 64.0639 67.2683 64.279 65.2217 64.3682L65.2233 64.3697Z"
          fill="#066DFF"
        />
        <Path
          d="M98.3646 60.0035C95.0397 54.9347 93.2666 49.6693 92.5737 47.2955C96.8927 42.3066 99.1666 36.4481 99.1666 30.2684C99.1666 22.2758 95.3362 14.7963 88.3822 9.2082C81.5942 3.75378 72.6028 0.75 63.0629 0.75C53.5231 0.75 44.5317 3.75378 37.7436 9.2082C30.7896 14.7963 26.9592 22.2742 26.9592 30.2684C26.9592 38.2626 30.7896 45.7406 37.7436 51.3287C44.5317 56.7831 53.5246 59.7869 63.0629 59.7869C67.2651 59.7869 71.3706 59.2046 75.2839 58.0553C81.691 62.5648 88.2363 65.4564 92.6997 67.1112C93.2482 67.314 93.8152 67.4169 94.3852 67.4169C96.1306 67.4169 97.7393 66.4474 98.5828 64.8848C99.4263 63.3223 99.3449 61.4985 98.3646 60.0035ZM76.2073 52.3105L75.2856 51.3214C71.5152 52.5614 68.86 30.25 64.7499 30.25C47.7029 30.25 64.7499 43.6664 64.7499 30.25C47.7029 30.25 64.7499 16.8337 64.7499 30.25C81.7969 30.25 56.3214 14.7265 56.3214 28.1429C56.3214 33.4252 62.4556 51.3012 58.4285 55.5357L86.855 45.8312L88.3822 42.8929C88.5697 43.6857 63.0355 26.4645 67.3437 33.4323C62.994 31.7376 71.0714 55.5357 71.0714 52.7284L76.2073 52.3105Z"
          fill="#066DFF"
        />
      </Svg>
    );
  }
}
