import React from 'react';
import {View} from 'react-native';
import {withGlobalize} from 'react-native-globalize';
import Util from 'src/Util';
import NBDropdown from '../NBDropdown';
import PaymentMethodDropdown from '../PaymentMethodDropdown';
import AmountDropdown from './AmountDropdown';
import type {ExpressFundingControlProps} from 'src/types/ExpressFundingControlProps';
import Localized from 'src/constants/AppStrings';

class ExpressFundingControlLight extends React.Component<ExpressFundingControlProps> {
  static defaultProps = {
    style: {},
    cardSelected: null,
    amountSelected: null,
    fallBelowSelected: null,
  };

  constructor(props: ExpressFundingControlProps) {
    super(props);
    this.formatNumber = this.formatNumber.bind(this);
  }

  formatNumber(value: number) {
    return Util.formatNumber(this.props, value);
  }

  render() {
    return (
      <View style={[this.props.style]}>
        <NBDropdown
          anchorPosition="bottom"
          accessibilityLabel={Localized.Labels.when_funds_fall_below}
          aria-label={Localized.Labels.when_funds_fall_below}
          label={Localized.Labels.when_funds_fall_below}
          onSelect={(value) => this.props.fallBelowSelected?.(value)}
          selectedValue={this.props.selectedMinAmount}
          options={[
            {
              value: 5,
              text: this.formatNumber(5),
            },
            {
              value: 10,
              text: this.formatNumber(10),
            },
            {
              value: 15,
              text: this.formatNumber(15),
            },
            {
              value: 20,
              text: this.formatNumber(20),
            },
          ]}
        />
        <AmountDropdown
          anchorPosition="top"
          onSelect={(value) => this.props.amountSelected?.(value)}
          ascendingOrder={false}
          selectedValue={this.props.selectedAmount}
          formatNumber={this.formatNumber}
        />
        <PaymentMethodDropdown
          anchorPosition="top"
          onSelect={(value) => this.props.cardSelected?.(value)}
          value={this.props.selectedCard}
          cards={this.props.cards}
          applePayAvailable={false}
          googlePayAvailable={false}
          payrollAvailable={this.props.payrollAvailable}
          showAddNewPayment={false}
        />
      </View>
    );
  }
}

export default withGlobalize(ExpressFundingControlLight);
