// Adapted from https://github.com/RNComponents/rn-spinner
import * as React from 'react';
import Styles from '../Styles';
import {View, Text, StyleSheet} from 'react-native';
import AVTouchableOpacity from './AVTouchableOpacity';
import Settings from 'src/Settings';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';
import MinusIcon from 'src/components/img/svg/MinusIcon';
import PlusIcon from 'src/components/img/svg/PlusIcon';

const {numberSpinnerStyles, numberSpinnerColor} = getDescriber();
type SpinnerProps = {
  min: number;
  max: number;
  default: number;
  onNumChange: (value: number) => void;
  disabled: boolean;
  showBorder: boolean;
  color: string;
  buttonTextColor: string;
  btnFontSize: number;
  numColor: string;
  numBgColor: string;
  fontSize: number;
  maxFontSizeMultiplier?: number;
};
type SpinnerState = {
  min: number;
  max: number;
  num: number;
};

class Spinner extends React.Component<SpinnerProps, SpinnerState> {
  static defaultProps = {
    min: 0,
    max: 99,
    default: 0,
    color: Styles.primaryColor,
    numColor: Styles.darkColor,
    numBgColor: numberSpinnerColor(),
    showBorder: true,
    fontSize: Styles.Fonts.f1,
    btnFontSize: Styles.Fonts.f1,
    buttonTextColor: Styles.primaryColor,
    disabled: false,
    width: 90,
  };

  constructor(props: SpinnerProps) {
    super(props);
    this.state = {
      min: this.props.min,
      max: this.props.max,
      num: this.props.default,
    };
    this._onNumChange = this._onNumChange.bind(this);
    this._increase = this._increase.bind(this);
    this._decrease = this._decrease.bind(this);
  }

  _onNumChange(num) {
    if (this.props.onNumChange) {
      this.props.onNumChange(num);
    }
  }

  _increase() {
    if (this.props.disabled) {
      return;
    }

    if (this.state.max > this.state.num) {
      let {num} = this.state;
      num += 1;
      this.setState({
        num,
      });

      this._onNumChange(num);
    }
  }

  _decrease() {
    if (this.props.disabled) {
      return;
    }

    if (this.state.min < this.state.num) {
      let {num} = this.state;
      num -= 1;
      this.setState({
        num,
      });

      this._onNumChange(num);
    }
  }

  render() {
    return (
      <View
        style={[
          styles.container,
          {
            borderColor: this.props.showBorder
              ? this.props.color
              : Styles.transparent,
          },
        ]}
      >
        <AVTouchableOpacity
          style={[
            styles.btn,
            {
              borderColor: this.props.showBorder
                ? this.props.color
                : Styles.transparent,
            },
          ]}
          onPress={this._decrease}
          accessible={true}
          aria-label="Decrease quantity"
          role="button"
          accessibilityLabel="Decrease quantity"
          accessibilityRole="button"
          accessibilityValue={{text: (this.state.num - 1).toString()}}
        >
          {Settings.isRefiveAnd365Pay() ? (
            <MinusIcon
              fillColor={
                this.state.num > 1
                  ? Styles.Colors.PayNew.primary01
                  : Styles.Colors.PayNew.darkHuesBase04
              }
              color={
                this.state.num > 1
                  ? Styles.Colors.PayNew.white01
                  : Styles.Colors.PayNew.neutralHuesBase09
              }
            />
          ) : (
            <Text
              style={[
                styles.btnText,
                {
                  color: this.props.buttonTextColor,
                  fontSize: this.props.btnFontSize,
                },
              ]}
              maxFontSizeMultiplier={this.props.maxFontSizeMultiplier}
            >
              -
            </Text>
          )}
        </AVTouchableOpacity>
        <View
          style={[
            numberSpinnerStyles().num,
            {
              borderColor: this.props.showBorder
                ? this.props.color
                : Styles.transparent,
              backgroundColor: this.props.numBgColor,
            },
          ]}
        >
          <Text
            accessible={true}
            accessibilityLabel={`${this.state.num}`}
            aria-label={`${this.state.num}`}
            style={[
              numberSpinnerStyles().numText,
              {
                color: this.props.numColor,
                fontSize: this.props.fontSize,
              },
            ]}
            maxFontSizeMultiplier={this.props.maxFontSizeMultiplier}
          >
            {this.state.num}
          </Text>
        </View>
        <AVTouchableOpacity
          style={[
            styles.btn,
            {
              borderColor: this.props.showBorder
                ? this.props.color
                : Styles.transparent,
            },
          ]}
          onPress={this._increase}
          accessible={true}
          aria-label="Increase quantity"
          role="button"
          accessibilityLabel="Increase quantity"
          accessibilityRole="button"
          accessibilityValue={{text: (this.state.num + 1).toString()}}
        >
          {Settings.isRefiveAnd365Pay() ? (
            <PlusIcon />
          ) : (
            <Text
              style={[
                styles.btnText,
                {
                  color: this.props.buttonTextColor,
                  fontSize: this.props.btnFontSize,
                  bottom: 2,
                },
              ]}
              maxFontSizeMultiplier={this.props.maxFontSizeMultiplier}
            >
              +
            </Text>
          )}
        </AVTouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    justifyContent: 'center',
    height: Styles.Heights.touchTargetHeight2,
    width: Styles.Heights.touchTargetHeight2,
  },
  btnText: {
    textAlign: 'center',
    bottom: 2,
  },
  container: {
    borderRadius: 4,
    borderWidth: 0.5,
    flexDirection: 'row',
    overflow: 'hidden',
    marginBottom: 10,
  },
  num: {
    alignItems: 'center',
    paddingHorizontal: Styles.Spacing.m3,
    justifyContent: 'center',
    marginTop: 5,
  },
  numText: {
    textAlign: 'center',
  },
});
export default Spinner;
