export enum MemberCodes {
  MEMBER,
  SMART_SHOPPER,
  TRAIL_BLAIZER,
  ELITE_BUYER,
}

const MAX_PRODUCTS_TIER_LEVEL = 3;
const MAX_USER_TIER_LEVEL = 4;

interface UserTierInfo {
  tierName: MemberCodes;
  pointsThreshold: number;
  nextTierName?: string;
}

const getCurrentUserPointsName = (userTierObj) => {
  return userTierObj?.tierName;
};

const getRewardProgramForUserTier = (userTierObj, tierArray) => {
  return tierArray.find((item) => {
    return item.name === userTierObj.tierName;
  });
};

const getRewardProgramForTierStatus = (tierStatus, tierArray) => {
  return tierArray.find((item) => {
    return item.rewardStatusId === tierStatus;
  });
};

const getMaxPointsRewardProgram = (tierArray) => {
  const maxTier = tierArray.find((item) => {
    return item.tierLevel === MAX_USER_TIER_LEVEL;
  });
  return maxTier.pointsThreshold;
};

const getMaxPointValueDiff = (productThresholds, rewardProducts) => {
  if (productThresholds?.length >= MAX_PRODUCTS_TIER_LEVEL) {
    const difference = productThresholds[2] - productThresholds[1]; // 3rd - 2nd
    return getMaxRewardProductsThreshold(rewardProducts) + difference;
  }
};

const getMemberTierInfo = (userTierName, tierArray) => {
  let tierName: MemberCodes;
  let pointsThreshold: number;
  tierArray?.map((item) => {
    if (item.name === userTierName) {
      pointsThreshold = item.pointsThreshold;

      switch (item.tierLevel) {
        case 1:
          tierName = MemberCodes.MEMBER;
          break;
        case 2:
          tierName = MemberCodes.SMART_SHOPPER;
          break;
        case 3:
          tierName = MemberCodes.TRAIL_BLAIZER;
          break;
        case 4:
          tierName = MemberCodes.ELITE_BUYER;
          break;
      }
    }
  });
  const userTierInfo: UserTierInfo = {tierName, pointsThreshold};
  return userTierInfo;
};

const getMaxRewardProductsThreshold = (rewardProducts) => {
  const maxTier = rewardProducts?.find((item) => {
    return item?.tierLevel === MAX_PRODUCTS_TIER_LEVEL;
  });
  return maxTier?.pointsRequired;
};

const getNextTierInfo = (userTierObj, tierArray) => {
  let tierName: MemberCodes;
  let pointsThreshold: number;
  let nextTierName: string;

  tierArray.map((item) => {
    if (item.name === userTierObj.nextTierName) {
      pointsThreshold = item.pointsThreshold;
      nextTierName = item.name;
      switch (item.tierLevel) {
        case 1:
          tierName = MemberCodes.MEMBER;
          break;
        case 2:
          tierName = MemberCodes.SMART_SHOPPER;
          break;
        case 3:
          tierName = MemberCodes.ELITE_BUYER;
          break;
        case 4:
          tierName = MemberCodes.TRAIL_BLAIZER;
          break;
      }
    }
  });
  const userTierInfo: UserTierInfo = {tierName, pointsThreshold, nextTierName};
  return userTierInfo;
};

export {
  getMemberTierInfo,
  getCurrentUserPointsName,
  getRewardProgramForUserTier,
  getRewardProgramForTierStatus,
  getNextTierInfo,
  getMaxPointsRewardProgram,
  getMaxRewardProductsThreshold,
  getMaxPointValueDiff,
};
