import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import StackTransitionPreset from './StackTransitionPreset';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import AccountFundingScreen from 'src/components/screens/funding/AccountFundingScreen';
import AutoReloadScreen from 'src/components/screens/funding/AutoReloadScreen';
import MainConsumerScreen from 'src/components/screens/MainConsumerScreen';
import PrivacyPolicyScreen from 'src/components/screens/account/PrivacyPolicyScreen';
import BalancesScreen from 'src/components/screens/account/BalancesScreen';
import RedeemRewardScreen from 'src/components/elements/rewards/RedeemRewardScreen';

const Stack = createStackNavigator();
export default function MainConsumerNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
        ...StackTransitionPreset,
        title: DeviceInfo.getApplicationName(),
        headerShown: false,
      }}
    >
      <Stack.Screen name={AppRoutes.Main} component={MainConsumerScreen} />
      <Stack.Screen
        name={AppRoutes.PrivacyPolicy}
        component={PrivacyPolicyScreen}
      />
      <Stack.Screen name={AppRoutes.MyModal} component={AccountFundingScreen} />
      <Stack.Screen
        name={AppRoutes.AutoReloadPanel}
        component={AutoReloadScreen}
      />
      <Stack.Screen
        name={AppRoutes.RedeemRewardPanel}
        component={RedeemRewardScreen}
      />
      <Stack.Screen name={AppRoutes.Balances} component={BalancesScreen} />
    </Stack.Navigator>
  );
}
