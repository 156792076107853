import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import Styles from '../../Styles';
import AVFormattedCurrency from '../AVFormattedCurrency';
import {Balance} from 'src/models/Balance';
import {BalanceTypes} from 'src/types/serverTypes/Account';
import Localized from 'src/constants/AppStrings';
import AppRoutes from 'src/AppRoutes';
import NavActions from 'src/actions/NavActions';
import UIManager from 'src/components/elements/ui/UIManager';
import AccountStore from 'src/stores/AccountStore';
import Util from 'src/Util';
import {getDescriber} from './descriptor/DescriptorType';

type BalanceItemProps = {
  balanceItem: Balance;
};

const BalanceItem = ({balanceItem}: BalanceItemProps) => {
  const getBalanceName = () => {
    const {type} = balanceItem;
    switch (type) {
      case BalanceTypes.Default:
      case BalanceTypes.Stripe:
        return Localized.Labels.my_account;
      case BalanceTypes.RollOver:
      case BalanceTypes.TopOff:
        return Localized.Labels.subsidy;
      default:
        return type;
    }
  };

  const getBalanceAmount = () => {
    return balanceItem.balance;
  };

  const getBalanceIcon = () => {
    switch (balanceItem.type) {
      case BalanceTypes.Default:
      case BalanceTypes.Stripe:
        return (
          <View
            accessible={true}
            accessibilityLabel={`${Localized.Labels.user_icon}, `}
            aria-label={`${Localized.Labels.user_icon}, `}
            accessibilityRole="image"
          >
            {getDescriber().getBalanceStripeIcon()}
          </View>
        );
      case BalanceTypes.RollOver:
      case BalanceTypes.TopOff:
        return (
          <View
            accessible={true}
            accessibilityLabel={`${Localized.Labels.subsidy_icon}, `}
            aria-label={`${Localized.Labels.subsidy_icon}, `}
            accessibilityRole="image"
          >
            {getDescriber().getBalanceRollOverIcon()}
          </View>
        );
      default:
        return null;
    }
  };

  const fund = () => {
    NavActions.navigate(AppRoutes.MyModal, {transDate: Util.getCurrentDate()});
  };

  const getBalanceButton = () => {
    if (UIManager.shouldHideFunding()) {
      return null;
    }
    switch (balanceItem.type) {
      case BalanceTypes.Default:
      case BalanceTypes.Stripe: {
        return getDescriber().getFundButtonForBalanceScreen(fund);
      }

      default:
        return null;
    }
  };

  const icon = getBalanceIcon();
  const name = getBalanceName();
  const amount = getBalanceAmount();

  return (
    <View>
      <View style={getDescriber().getBalanceItemStyleDescriptor()['container']}>
        {icon}
        <View
          style={
            getDescriber().getBalanceItemStyleDescriptor()['balanceContainer']
          }
        >
          <AVFormattedCurrency
            style={[
              styles.balanceAmountText,
              getDescriber().balanceAmountStyleDescriptor(),
            ]}
            value={amount}
            accessible={true}
            accessibilityLabel={`${name}: ${Util.formatCurrency(
              null,
              amount,
              AccountStore.getCurrency(),
            )}`}
            aria-label={`${name}: ${Util.formatCurrency(
              null,
              amount,
              AccountStore.getCurrency(),
            )}`}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
            currency={balanceItem.currency}
          />
          <Text
            accessible={false}
            accessibilityLabel={name}
            aria-label={name}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            style={[
              styles.balanceTypeText,
              getDescriber().balanceTypeStyleDescriptor(),
            ]}
          >
            {name}
          </Text>
        </View>
      </View>
      {getBalanceButton()}
    </View>
  );
};

const styles = StyleSheet.create({
  balanceContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingHorizontal: Styles.Spacing.m1,
  },
  balanceAmountText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    marginHorizontal: Styles.Spacing.m2,
    textAlign: 'left',
    flex: 1,
  },
  balanceTypeText: {
    color: Styles.lightGray,
    marginHorizontal: Styles.Spacing.m2,
    textAlign: 'left',
    flex: 1,
  },
  fundButtonText: {
    paddingHorizontal: Styles.Spacing.m2,
  },
});
export default BalanceItem;
