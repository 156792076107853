import React from 'react';
import {FlatList, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import type {ProductCategory} from 'src/types/ProductCategory';
import Localized from '../../../constants/AppStrings';
import Styles from '../../Styles';
import {scanAndPayTemplate} from 'src/components/screens/scanAndPay/templates/scanandPayThemeHelper';

type ProductCategoriesProps = {
  categories: Array<ProductCategory>;
  selectedCategory: string | number;
  onCategoryPress: (category: ProductCategory) => void;
};

const CategoryItem = ({
  item,
  selectedCategory,
  onCategoryPress,
}: {
  item: ProductCategory;
  selectedCategory: string | number;
  onCategoryPress: (category: ProductCategory) => void;
}) => {
  return (
    <TouchableOpacity
      role="button"
      onPress={() => onCategoryPress(item)}
      style={styles.button}
      accessibilityRole="button"
    >
      <View
        accessible
        style={[
          styles.view,
          selectedCategory === item.key && styles.viewSelect,
        ]}
      >
        <Text
          style={[
            styles.text,
            selectedCategory === item.key && styles.textSelect,
          ]}
          accessibilityHint="Tap to filter products by selected category"
          accessibilityLabel={`${item.name} ${Localized.Buttons.category}`}
          aria-label={`${item.name} ${Localized.Buttons.category}`}
        >
          {item?.name}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default (props: ProductCategoriesProps) => {
  const renderItem = ({item}) => (
    <CategoryItem
      item={item}
      onCategoryPress={props.onCategoryPress}
      selectedCategory={props.selectedCategory}
    />
  );
  return (
    <View style={styles.flatList}>
      <FlatList
        data={props.categories}
        renderItem={renderItem}
        horizontal={true}
        keyExtractor={(item) => `${item.key}`}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  viewSelect: {
    backgroundColor: scanAndPayTemplate.categorySelected.selectedCategoryColor,
    borderColor: scanAndPayTemplate.categorySelected.selectCategoryBorderColor,
  },
  textSelect: {
    fontWeight: 'bold',
    color: Styles.Colors.PayNew.white01,
  },
  text: {
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
    color: Styles.Colors.PayNew.black01,
    fontFamily: scanAndPayTemplate.textFontFamily,
  },
  view: {
    backgroundColor: Styles.Colors.PayNew.white01,
    borderRadius: Styles.Spacing.m4,
    marginRight: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
    borderWidth: 1,
    borderColor: Styles.Colors.PayNew.neutralHuesBase09,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 4,
    marginBottom: Styles.Spacing.m2,
  },
  flatList: {
    marginTop: Styles.Spacing.m3,
    paddingLeft: Styles.Spacing.m3,
  },
});
