import React, {useEffect} from 'react';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import HistoryComponent from 'src/components/elements/account/newHistoryUI/HistoryComponent';
import {NavigationProp} from '@react-navigation/native';
import AccountConstants from '../../../../constants/AccountConstants';

type HistoryComponentProps = {
  histroyfilter: AccountConstants;
  navigation: NavigationProp<HistoryComponentProps>;
};

const AllHistoryList = (props: HistoryComponentProps | any) => {
  useEffect(() => {
    FirebaseAnalytic.trackEvent(
      'useEffect:componentDidMount',
      'AllHistoryList',
      {
        ...props,
      },
    );
  }, []);
  return <HistoryComponent {...props} histroyfilter={AccountConstants.All} />;
};
export default AllHistoryList;
