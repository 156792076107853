import * as React from 'react';
import {StyleSheet} from 'react-native';
import SafeAreaView from '../elements/SafeAreaView';
import KeyboardAvoidingView from '../elements/365KeyboardAvoidingView';
import BackSubheader from '../elements/BackSubheader';
import Styles from '../Styles';
import AVText from '../elements/AVText';
import {getDescriber as getCommonModuleDescriber} from './descriptor/DescriptorType';
type BaseAccountSetupScreenProps = {
  children: React.ReactNode;
  headlineText: string;
  instructionText: string;
  previousRoute?: string;
  onBackSelect?: () => void;
};
export default class BaseAccountSetupScreen extends React.Component<BaseAccountSetupScreenProps> {
  renderContent() {
    return (
      <KeyboardAvoidingView style={styles.content}>
        <AVText
          accessibilityLabel={this.props.instructionText}
          accessible={true}
          accessibilityRole="text"
          aria-label={this.props.instructionText}
          style={styles.instructions}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {this.props.instructionText}
        </AVText>
        {this.props.children}
      </KeyboardAvoidingView>
    );
  }

  renderContainer() {
    const {baseAccountContainerDescriptor} = getCommonModuleDescriber();
    return (
      <SafeAreaView
        style={[
          Styles.Style.flex,
          baseAccountContainerDescriptor()['container'],
        ]}
        forceInset={{
          top: 'never',
        }}
        setPaddingBottomsafeArea
      >
        <BackSubheader
          title={this.props.headlineText}
          onBackSelect={this.props.onBackSelect}
          accessibilityLabel={'Back arrow'}
          accessibilityHint={`Press to navigate back to the ${this.props.previousRoute} screen`}
        >
          {this.renderContent()}
        </BackSubheader>
      </SafeAreaView>
    );
  }

  render() {
    return this.renderContainer();
  }
}
const styles = StyleSheet.create({
  content: {
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  instructions: {
    alignSelf: 'stretch',
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    marginVertical: Styles.Spacing.m1,
    textAlign: 'left',
  },
});
