import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, Rect} from 'react-native-svg';

const PayNewLogo = (props) => (
  <Svg width={280} height={39} viewBox="0 0 280 39" fill="none" {...props}>
    <G clipPath="url(#clip0_126_2387)">
      <Path
        d="M62.4679 23.8102C62.4679 27.2302 59.5895 29.5669 55.0867 29.5669C50.5838 29.5669 47.7054 27.2015 47.7054 23.0978H50.8117C50.8688 25.5486 52.7213 26.9452 55.2576 26.9452C57.5089 26.9452 59.2475 25.6627 59.2475 23.6107C59.2475 21.7582 57.7941 20.5612 55.0296 20.5612H53.2055V17.9392H54.8585C57.4805 17.9392 58.7913 16.6283 58.7913 14.8042C58.7913 12.9802 57.1668 11.8972 55.0864 11.8972C52.8351 11.8972 51.2106 13.2652 51.1535 15.3456H48.0756C48.104 11.7547 50.9826 9.27522 55.0864 9.27522C59.3897 9.27522 62.0117 11.4127 62.0117 14.6047C62.0117 17.0555 60.5868 18.5092 58.6772 19.0221C60.6435 19.4212 62.4676 20.8175 62.4676 23.81L62.4679 23.8102Z"
        fill="#111111"
      />
      <Path
        d="M64.0325 19.564C64.0325 13.0948 67.0249 9.27576 71.8982 9.27576C75.5745 9.27576 78.2536 11.4132 78.7381 14.9186H75.6032C75.1757 13.0661 73.8362 11.8978 71.6987 11.8978C68.6208 11.8978 66.9108 14.5766 66.9108 18.709C66.9108 18.9369 66.9108 19.1081 66.9392 19.4785C66.9963 19.3644 67.0247 19.1935 67.1388 18.994C68.0508 17.2556 69.9317 16.1156 72.3257 16.1156C76.287 16.1156 79.0515 18.7944 79.0515 22.8414C79.0515 26.7743 76.1445 29.5672 71.8982 29.5672C69.1337 29.5672 67.0533 28.3702 65.714 26.3468C64.6595 24.6085 64.0325 22.243 64.0325 19.564ZM71.9266 18.709C69.5042 18.709 67.8229 20.3906 67.8229 22.8698C67.8229 25.2923 69.4474 26.9736 71.8698 26.9736C74.2923 26.9736 75.8882 25.2636 75.8882 22.8412C75.8882 20.4187 74.3777 18.7087 71.9269 18.7087L71.9266 18.709Z"
        fill="#111111"
      />
      <Path
        d="M95.3816 22.6987C95.3816 27.1731 92.0471 29.5385 88.1429 29.5385C83.6116 29.5385 80.9326 26.6602 80.733 23.0978H83.868C84.0959 25.1782 85.6063 26.8881 88.1713 26.8881C90.2233 26.8881 92.1896 25.4632 92.1896 22.6987C92.1896 20.2762 90.6222 18.5378 88.1713 18.5378C86.9743 18.5378 84.9509 18.9369 84.153 20.9887L81.1035 20.9316L81.7305 9.44638H94.1846V12.2109H84.6089L84.2668 17.6258C85.3784 16.4288 86.8888 15.9442 88.7126 15.9442C92.6739 15.9442 95.3814 18.6801 95.3814 22.6984L95.3816 22.6987Z"
        fill="#111111"
      />
      <Path
        d="M104.843 9.44666H112.709C116.87 9.44666 119.663 11.9262 119.663 15.802C119.663 18.4808 118.124 20.6183 115.787 21.4449L120.005 29.3961H116.357L112.509 22.1573H108.121V29.3961H104.843V9.44666ZM108.121 12.2966V19.3074H112.453C114.79 19.3074 116.3 17.9395 116.3 15.802C116.3 13.6645 114.79 12.2966 112.453 12.2966H108.121Z"
        fill="#111111"
      />
      <Path
        d="M120.814 22.1287C120.814 17.6258 123.692 14.6333 127.996 14.6333C132.213 14.6333 135.035 17.3121 135.12 21.5871C135.12 21.9575 135.092 22.3566 135.035 22.7555H124.034V22.955C124.12 25.4345 125.687 27.0588 128.11 27.0588C129.99 27.0588 131.358 26.1183 131.786 24.4938H134.864C134.351 27.3722 131.9 29.5667 128.281 29.5667C123.692 29.5667 120.814 26.6031 120.814 22.1287ZM131.928 20.5041C131.672 18.3383 130.161 17.1129 128.024 17.1129C126.058 17.1129 124.376 18.4237 124.148 20.5041H131.928Z"
        fill="#111111"
      />
      <Path
        d="M138.024 17.512H135.488V14.8045H138.024V10.7291H141.131V14.8045H144.693V17.512H141.131V25.1211C141.131 26.2611 141.53 26.6886 142.698 26.6886H144.864V29.3961H142.014C139.221 29.3961 138.024 28.0852 138.024 25.4348V17.512H138.024Z"
        fill="#111111"
      />
      <Path
        d="M160.319 26.7173V29.396H158.695C156.7 29.396 156.016 28.5127 156.016 27.059C155.047 28.5411 153.565 29.5669 151.2 29.5669C148.008 29.5669 145.785 27.9995 145.785 25.292C145.785 22.2996 147.951 20.6182 152.026 20.6182H155.702V19.7349C155.702 18.1103 154.534 17.1129 152.51 17.1129C150.686 17.1129 149.461 17.9678 149.233 19.2503H146.212C146.525 16.4004 148.977 14.6333 152.653 14.6333C156.529 14.6333 158.752 16.4858 158.752 19.9342V25.7766C158.752 26.5174 159.065 26.717 159.692 26.717H160.319L160.319 26.7173ZM155.702 22.8985H151.884C149.974 22.8985 148.92 23.611 148.92 25.093C148.92 26.3184 149.946 27.1734 151.627 27.1734C154.192 27.1734 155.674 25.663 155.703 23.4114V22.8985H155.702Z"
        fill="#111111"
      />
      <Path
        d="M161.306 10.9C161.306 9.81709 162.132 9.04755 163.186 9.04755C164.241 9.04755 165.067 9.81709 165.067 10.9C165.067 11.9829 164.241 12.7525 163.186 12.7525C162.132 12.7525 161.306 11.9829 161.306 10.9ZM161.619 14.8045H164.697V29.3961H161.619V14.8045Z"
        fill="#111111"
      />
      <Path
        d="M167.222 9.44666H170.299V29.3961H167.222V9.44666Z"
        fill="#111111"
      />
      <Path
        d="M180.473 9.44666H184.72L191.103 25.1498L197.373 9.44666H201.534V29.3961H198.371V14.7191L192.386 29.3961H189.621L183.636 14.7191V29.3961H180.473L180.473 9.44666Z"
        fill="#111111"
      />
      <Path
        d="M218.369 26.7173V29.396H216.744C214.749 29.396 214.065 28.5127 214.065 27.059C213.096 28.5411 211.615 29.5669 209.249 29.5669C206.057 29.5669 203.834 27.9995 203.834 25.292C203.834 22.2996 206 20.6182 210.075 20.6182H213.752V19.7349C213.752 18.1103 212.583 17.1129 210.56 17.1129C208.736 17.1129 207.51 17.9678 207.282 19.2503H204.261C204.575 16.4004 207.026 14.6333 210.702 14.6333C214.578 14.6333 216.801 16.4858 216.801 19.9342V25.7766C216.801 26.5174 217.114 26.717 217.741 26.717H218.368L218.369 26.7173ZM213.752 22.8985H209.933C208.023 22.8985 206.969 23.611 206.969 25.093C206.969 26.3184 207.995 27.1734 209.677 27.1734C212.242 27.1734 213.724 25.663 213.752 23.4114L213.752 22.8985Z"
        fill="#111111"
      />
      <Path
        d="M227.825 14.8045V17.6829H226.457C223.721 17.6829 222.837 19.8203 222.837 22.0716V29.3958H219.759V14.8042H222.495L222.837 16.9988C223.578 15.7733 224.747 14.8042 227.141 14.8042H227.825V14.8045Z"
        fill="#111111"
      />
      <Path
        d="M229.039 9.44666H232.117V21.8723L238.728 14.8045H242.348L236.847 20.7037L242.69 29.3961H239.042L234.739 22.9553L232.117 25.6911V29.3961H229.039V9.44666Z"
        fill="#111111"
      />
      <Path
        d="M242.165 22.1287C242.165 17.6258 245.044 14.6333 249.347 14.6333C253.565 14.6333 256.386 17.3121 256.472 21.5871C256.472 21.9575 256.443 22.3566 256.386 22.7555H245.386V22.955C245.471 25.4345 247.039 27.0588 249.461 27.0588C251.342 27.0588 252.71 26.1183 253.137 24.4938H256.215C255.702 27.3722 253.251 29.5667 249.632 29.5667C245.044 29.5667 242.165 26.6031 242.165 22.1287ZM253.28 20.5041C253.023 18.3383 251.513 17.1129 249.375 17.1129C247.409 17.1129 245.727 18.4237 245.5 20.5041H253.28Z"
        fill="#111111"
      />
      <Path
        d="M259.376 17.512H256.84V14.8045H259.376V10.7291H262.482V14.8045H266.045V17.512H262.482V25.1211C262.482 26.2611 262.881 26.6886 264.05 26.6886H266.216V29.3961H263.366C260.573 29.3961 259.376 28.0852 259.376 25.4348L259.376 17.512Z"
        fill="#111111"
      />
      <Path
        d="M270.006 24.5798C270.12 26.0902 271.431 27.1731 273.654 27.1731C275.564 27.1731 276.817 26.4323 276.817 25.2636C276.817 23.6961 275.478 23.582 273.227 23.2973C269.892 22.8982 267.27 22.1857 267.27 19.1649C267.27 16.4004 269.75 14.605 273.255 14.6336C276.846 14.6336 279.383 16.2865 279.582 19.279H276.561C276.39 17.9394 275.136 16.999 273.312 16.999C271.488 16.999 270.291 17.7399 270.291 18.9085C270.291 20.2765 271.688 20.4476 273.797 20.6756C277.131 21.0747 279.839 21.7585 279.839 25.0075C279.839 27.8004 277.188 29.5675 273.654 29.5675C269.75 29.5675 267.071 27.7434 266.986 24.58H270.006L270.006 24.5798Z"
        fill="#111111"
      />
      <Path
        d="M19.6171 32.6009C12.3686 32.6009 6.47156 26.7038 6.47156 19.4554H9.62649C9.62649 24.9642 14.1083 29.446 19.6171 29.446C25.1259 29.446 29.6077 24.9642 29.6077 19.4554C29.6077 13.9466 25.1259 9.46479 19.6171 9.46479V6.30986C26.8655 6.30986 32.7626 12.2069 32.7626 19.4554C32.7626 26.7038 26.8655 32.6009 19.6171 32.6009Z"
        fill="#066DFF"
      />
      <Path
        d="M19.6171 38.9108C8.88933 38.9108 0.161743 30.1832 0.161743 19.4554C0.161743 8.72759 8.88933 0 19.6171 0C30.345 0 39.0725 8.72759 39.0725 19.4554H35.9176C35.9176 10.4673 28.6053 3.15493 19.6171 3.15493C10.629 3.15493 3.31667 10.4673 3.31667 19.4554C3.31667 28.4435 10.629 35.7559 19.6171 35.7559V38.9108Z"
        fill="#066DFF"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_126_2387">
        <Rect width={280} height={38.9108} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default PayNewLogo;
