import Settings from 'src/Settings';
import RedesignDescriptor from './toast-redesign';
import RefiveDescriptor from './toast-refive';
import CanteenDescriptor from './toast-canteen';
import CompanyKitchenDescriptor from './toast-companykitchen';
import BuildTypes from 'src/constants/BuildTypeConstants';

const typeToastDescriber = {
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export function getToastDescriber() {
  return typeToastDescriber[
    Settings.buildType as keyof typeof typeToastDescriber
  ];
}
