import RedesignDescriptor from 'src/components/elements/offers/descriptor/offer-redesign-descriptor';
import RefiveDescriptor from 'src/components/elements/offers/descriptor/offer-refive-descriptor';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import {StyleProp, ViewStyle, TextStyle} from 'react-native';
import Video, {VideoProperties} from 'react-native-video';

const typeDescriber = {
  [BuildTypes.canteen]: RedesignDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.companykitchen]: RedesignDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export interface OfferStylesDescriptor {
  noteTextStyle?: StyleProp<TextStyle>;
  noteStyle?: StyleProp<TextStyle>;
  offerExpiry?: StyleProp<TextStyle>;
  offerNoteContainer?: StyleProp<ViewStyle>;
  appliedDeal?: StyleProp<ViewStyle>;
  appliedIcon?: StyleProp<ViewStyle>;
  offersWithImageView?: StyleProp<ViewStyle>;
  backgroundVideo?: StyleProp<ViewStyle>;
  offerTitleinModal?: StyleProp<TextStyle>;
  offersWithImageText?: StyleProp<TextStyle>;
  validityText?: StyleProp<TextStyle>;
}
export type OfferDetailsArrowGifProps = {
  checkTopSpacing?: () => number;
  videoRef?: React.RefObject<Video>;
  onBuffer?: VideoProperties['onBuffer'];
  onError?: VideoProperties['onError'];
  isImage?: boolean;
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
