import React, {useRef, useEffect, useContext, useState, useMemo} from 'react';
import {View, StyleSheet, FlatList, TouchableOpacity} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import AppRoutes from 'src/AppRoutes';
import NavActions from 'src/actions/NavActions';
import Localized from 'src/constants/AppStrings';
import MainConsumerContext from 'src/components/MainConsumerContext';
import TimeUtils from 'src/services/TimeUtils';
import Styles from '../../Styles';
import moment from 'moment';
import 'moment-timezone';
import uuid from 'src/nativeModules/UUID';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import BackSubheader from '../../elements/BackSubheader';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import type {MenuType, TimeSlotType} from 'src/types/Menu';
import type {DateType, LocationType} from 'src/types/Location';
import {confirm, alertError} from '../../helpers/AlertHelper';
import PickupTimeTimer from '../../elements/orderAhead/PickupTimeTimer';
import TransactionActions from 'src/actions/TransactionActions';
import MenuService from 'src/services/MenuService';
import TransactionStore from 'src/stores/TransactionStore';
import CartService from 'src/services/CartService';
import MenuActions from 'src/actions/MenuActions';
import {PickupLocation} from 'src/types/serverTypes/Location';
import LocationMarker from 'src/components/img/svg/LocationMarker';
import AVText from 'src/components/elements/AVText';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import EditIcon from 'src/components/img/svg/EditIcon';
import CustomTilesButton from 'src/components/elements/orderAhead/CustomTilesButton';
import CalendarIcon from 'src/components/img/svg/CalendarIcon';
import ErrorIcon from 'src/components/img/svg/ErrorIcon';
import {handleClearCart} from './orderAheadUtils';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import PickupTimeMarker from 'src/components/img/svg/PickupTimeMarker';
import Settings from 'src/Settings';
import {getDescriber} from './descriptor/DescriptorType';
import EditLocation from 'src/components/img/svg/EditLocation';
import TagManager from 'src/components/utils/TagManager';
import AppLifecycleTracker from 'src/components/utils/AppLifecycleTracker';

const {
  getInactiveButtonColor,
  getPickupLocationStyles,
  textColor,
  fontFamilyStyle,
  getPickupTimeStyles,
  getActiveButtonColor,
  dayErrorMessage,
  timeErrorMessage,
  getEditLocationIconColor,
  activeCalendarIconColor,
  inActiveCalendarIconColor,
} = getDescriber();

type NewPickupTimeScreenProps = {
  navigation: NavigationProp<NewPickupTimeScreenProps>;
  availableTimes: Array<TimeSlotType>;
  selectedPickupLocation: PickupLocation;
  location: LocationType;
  fromCart?: boolean;
};

const NewPickupTimeScreen: React.FC<NewPickupTimeScreenProps> = (props) => {
  const context = useContext(MainConsumerContext);
  const pickupTime = TransactionStore.getPickupTime();
  const sessionStart = useRef<moment.Moment | null>(null);
  const previousRouteName = useRef<string | null>(null);
  const selectedBackButton = useRef<boolean>(false);
  const [availableTimes, setAvailableTimes] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [showDayErrorText, setShowDayErrorText] = useState(false);
  const [showTimeErrorText, setShowTimeErrorText] = useState(false);
  const [pickupDateTypes, setPickupDateTypes] = useState([]);
  const displayTransactionItemsRef = useRef([]);

  const selectedIconColor = activeCalendarIconColor();
  const inactiveIconColor = inActiveCalendarIconColor();

  const checkForCartAbandonment = () => {
    if (!selectedBackButton.current) {
      const transactionItems = TransactionStore.getDisplayItems();
      displayTransactionItemsRef.current = transactionItems;
    }
    if (displayTransactionItemsRef.current.length > 0) {
      TagManager.updateDynamicTags('cart_abandoned').catch((error) => {
        console.error('Error updating cancel tag:', error);
      });
    }
  };

  useEffect(() => {
    const routeName = getPreviousRouteName(
      props.navigation?.getState()?.routes,
    );
    previousRouteName.current = routeName;
    if (!props.fromCart) {
      sessionStart.current = moment();
    }
    FirebaseAnalytic.trackEvent('useEffect', 'NewPickupTimeScreen', {
      ...props,
      ...{...availableTimes, ...pickupTime},
      routeName,
    });
    return () => {
      checkForCartAbandonment();
      AppLifecycleTracker.clearCartState();
    };
  }, [props.fromCart, props.navigation]);

  const getTimeSlots = (dateVal) => {
    return props?.availableTimes.filter((item) => {
      const itemDate = moment(item.date);
      const targetMoment = dateVal;
      return itemDate.isSame(targetMoment, 'day');
    });
  };

  const checkTodayTimeSlots = useMemo(() => {
    return getTimeSlots(MenuService.getPickupDateTime());
  }, [props.navigation]);

  const checkTomTimeSlots = useMemo(() => {
    return getTimeSlots(MenuService.getTomorrowPickupDateTime());
  }, [props.navigation]);

  useEffect(() => {
    //updatePickupTimefromStore();

    setPickupDateTypes([]);
    // check today timeslots
    if (checkTodayTimeSlots.length > 0) {
      setPickupDateTypes((result) => [
        ...result,
        {
          label: Localized.Labels.today,
          dateValue: MenuService.getPickupDateTime(),
        },
      ]);
    }
    // check tomorrow timeslots
    if (checkTomTimeSlots.length > 0) {
      setPickupDateTypes((result) => [
        ...result,
        {
          label: Localized.Labels.tomorrow,
          dateValue: MenuService.getTomorrowPickupDateTime(),
        },
      ]);
    }

    // set the default value of day and set timeslots
    if (checkTodayTimeSlots.length != 0) {
      setSelectedDay({
        label: Localized.Labels.today,
        dateValue: MenuService.getPickupDateTime(),
      });
      setAvailableTimes(checkTodayTimeSlots);
    } else {
      setSelectedDay({
        label: Localized.Labels.tomorrow,
        dateValue: MenuService.getTomorrowPickupDateTime(),
      });
      setAvailableTimes(checkTomTimeSlots);
    }
  }, [checkTomTimeSlots, checkTodayTimeSlots]);

  /** Update the state using the API response */
  const updatePickupTimefromStore = async () => {
    const timeSlotsArray = await MenuService.getAvailableTimeSlotsFromList(
      TransactionStore.getAvailableTimesFromServer(),
      props.location.onlineOrderConfig.kitchenSchedule,
    );

    setAvailableTimes(
      timeSlotsArray.filter((item) => {
        const itemDate = moment(item.date);
        const targetMoment = selectedDay
          ? selectedDay.dateValue
          : moment(MenuService.getPickupDateTime());
        return itemDate.isSame(targetMoment, 'day');
      }),
    );
  };

  /** back arrow press */
  const onBackSelect = () => {
    const {pickupLocations} = props.location;
    const transactionItems = TransactionStore.getDisplayItems();
    if (!props.fromCart && pickupLocations && pickupLocations.length === 1) {
      FirebaseAnalytic.trackEvent('onBackSelect', 'NewPickupTimeScreen', {
        ...props,
        ...{...availableTimes, ...pickupTime},
        transactionItems,
      });
      displayTransactionItemsRef.current = transactionItems;
      handleClearCart(transactionItems, sessionStart.current, confirm);
      selectedBackButton.current = true;
    } else {
      NavActions.push(AppRoutes.NewPickupLocation, {
        strings: Localized,
        location: props.location,
        availableTimes,
      });
    }
  };

  /** check if cart is valid */
  const cartIsInvalid = () => {
    FirebaseAnalytic.trackEvent('cartIsInvalid', 'NewPickupTimeScreen', {
      props,
    });

    CartService.clearCart(sessionStart.current);
    alertError(
      Localized.Errors.cart_expired_message,
      null,
      () => ({}),
      Localized.Errors.cart_expired,
    );
    NavActions.popToTop();
  };
  /** fn: on press navigate to menu */
  const onContinuetoMenuPress = async () => {
    if (selectedDay && selectedTime) {
      setShowDayErrorText(false);
      setShowTimeErrorText(false);
      const timeZoneOffsetMinutes = MenuService.getTimezoneOffsetMinutes();
      const leadTime = props.location.onlineOrderConfig.kitchenSchedule;
      const now = moment()
        .add(leadTime, 'minutes')
        .add(timeZoneOffsetMinutes, 'minutes');
      /** check selected time is after current time */
      if (!moment(selectedTime.date).isAfter(now)) {
        try {
          context.actions.showSpinner();
          await MenuActions.getAvailableTimeSlots(
            props.location,
            TransactionStore.getPickupLocationId(),
          );
          alertError(
            Localized.Errors.pickup_time_not_available,
            undefined,
            () => {
              updatePickupTimefromStore();
            },
          );
        } catch (error) {
          const guid = await uuid.getRandomUUID();
          CrashlyticsEvents.log(
            'Exception',
            'NewPickupTimeScreen:onContinuetoMenuPress',
            generateErrorMessage(error),
            guid,
          );
          Events.Error.trackEvent(
            'Exception',
            'NewPickupTimeScreen:onContinuetoMenuPress',
            generateErrorMessage(error),
            guid,
          );
        } finally {
          context.actions.hideSpinner();
        }
      } else {
        context.actions.showSpinner();
        const availableTime = await MenuActions.getAvailableTimeSlots(
          props.location,
          TransactionStore.getPickupLocationId(),
        );
        const isTimeSlotPresent = availableTime.some(
          (obj) =>
            obj.timeString === selectedTime.timeString &&
            moment(selectedTime.date).isSame(moment(obj.date), 'day'),
        );
        const newPickupLocationId = TransactionStore.getPickupLocationId();
        /** selected time is after current time and not yet reached max order limit */
        if (moment(selectedTime.date).isAfter(now) && isTimeSlotPresent) {
          const newMenu = await MenuActions.getMenuByTimeSlot(
            props.location.locationId,
            newPickupLocationId,
            selectedTime,
          );
          const newMenuHasItems = MenuService.menuHasProducts(
            newMenu,
            TransactionStore.getScanCodes(),
          );

          FirebaseAnalytic.trackEvent(
            'onContinuetoMenuPress',
            'NewPickupTimeScreen',
            {
              ...props,
              ...{...availableTimes, ...pickupTime},
              newMenu,
              newMenuHasItems,
            },
          );

          context.actions.hideSpinner();
          if (newMenuHasItems) {
            updateTime(selectedTime, newMenu);
          } else {
            const selectedNewPickupLocation =
              props.location.pickupLocations.find(
                (location) => location.pickupLocationId === newPickupLocationId,
              );

            const updateNewPickupTime = () => {
              if (selectedNewPickupLocation) {
                TransactionActions.pickupLocationUpdated(
                  selectedNewPickupLocation,
                );
                updateTime(selectedTime, newMenu);
              } else {
                NavActions.pop();
              }
            };

            confirm(
              Localized.Labels.pickup_time_selected_serving_different_menu,
              () => {
                CartService.clearCart(sessionStart.current);
                setTimeout(updateNewPickupTime, 200);
              },
              () => ({}),
              Localized.Labels.menu_change,
              Localized.Buttons.cancel,
              Localized.Buttons.continue,
            );
          }
        } else {
          context.actions.hideSpinner();
          alertError(
            Localized.Errors.order_limit_reached_for_pickup_time,
            undefined,
            () => {
              updatePickupTimefromStore();
            },
          );
        }
      }
    } else if (!selectedDay) {
      setShowDayErrorText(true);
    } else {
      setShowTimeErrorText(true);
    }
  };

  const updateTime = (time: TimeSlotType, menu?: MenuType) => {
    TransactionActions.pickupTimeUpdated(time);
    FirebaseAnalytic.trackEvent('updateTime', 'NewPickupTimeScreen', {
      ...props,
      ...{...availableTimes, ...pickupTime},
      time,
      menu,
    });

    if (props.fromCart) {
      NavActions.pop();
    } else {
      MenuService.setCurrentMenu(menu);
      NavActions.push(AppRoutes.Menu, {
        location: props.location,
      });
    }
  };
  /** fn: on press of date tile */
  const onDateTilePress = async (date: DateType) => {
    FirebaseAnalytic.trackEvent('onDateTilePress', 'NewPickupTimeScreen', {
      ...props,
      ...{...availableTimes, ...pickupTime},
      date,
    });

    const _availableTimes = await MenuActions.getAvailableTimeSlots(
      props.location,
      TransactionStore.getPickupLocationId(),
    );
    const slots = MenuService.getAvailableTimeSlotsFromList(
      _availableTimes,
      props.location.onlineOrderConfig.kitchenSchedule,
    );
    const timeSlots = slots.filter((item) => {
      const itemDate = moment(item.date);
      const targetMoment = date.dateValue;
      return itemDate.isSame(targetMoment, 'day');
    });
    setSelectedDay(date);
    setSelectedTime(null);
    setShowTimeErrorText(false);
    setAvailableTimes(timeSlots);
  };

  /** fn: render pick up time */
  const renderPickupTimes = ({item, index}) => {
    const pickupDayLabel = TimeUtils.isDateGreaterThanToday(item.date)
      ? Localized.Labels.tomorrow
      : Localized.Labels.today;
    const accessibleStringText = `${pickupDayLabel} ${item.dateString}, ${item.time} ${item.timezone}`;
    return (
      <View style={getPickupTimeStyles().tilesStyle} key={index}>
        <CustomTilesButton
          item={item}
          text={item.time}
          accessibleString={accessibleStringText}
          onTilePress={(selectedPickupTime) =>
            setSelectedTime(selectedPickupTime)
          }
          selected={item.date === selectedTime?.date}
          tileStyle={getPickupTimeStyles().customTileButtonTime}
        />
      </View>
    );
  };

  return (
    <BackSubheader
      previousRoute={previousRouteName.current}
      accessibilityLabel={'Back arrow'}
      accessibilityHint={`Press to navigate back to the ${previousRouteName.current} screen`}
      title={Localized.Labels.pickup_time}
      onBackSelect={onBackSelect}
      pop={false}
    >
      <View style={getPickupTimeStyles().pickupTimeContainer}>
        {!props.fromCart && pickupTime ? (
          <PickupTimeTimer
            interval={props.location?.onlineOrderConfig.orderSchedule}
            onCartIsInvalid={cartIsInvalid}
            leadTime={props.location?.onlineOrderConfig.kitchenSchedule}
          />
        ) : null}
        <View style={getPickupTimeStyles().pickupLocationHeaderView}>
          <View style={styles.pickupLocationView}>
            {Settings.isRefiveAnd365Pay() ? (
              <PickupTimeMarker />
            ) : (
              <LocationMarker size={Styles.Fonts.f3} color={Styles.darkColor} />
            )}
            <AVText
              style={styles.selectedLocationText}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
              accessibilityLabel={
                props.selectedPickupLocation?.name ??
                TransactionStore.getPickupLocationName()
              }
              aria-label={`${
                props.selectedPickupLocation?.name ??
                TransactionStore.getPickupLocationName()
              }, text`}
            >
              {props.selectedPickupLocation?.name
                ? props.selectedPickupLocation?.name
                : TransactionStore.getPickupLocationName()}
            </AVText>
          </View>
          <TouchableOpacity
            onPress={onBackSelect}
            style={{
              minHeight: 44,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View style={getPickupTimeStyles().editButton}>
              {Settings.isRefiveAnd365Pay() ? (
                <EditLocation color={getEditLocationIconColor()} />
              ) : (
                <EditIcon color={getEditLocationIconColor()} />
              )}
              <AVText style={getPickupTimeStyles().buttonTextStyle}>
                {Localized.Buttons.edit_location}
              </AVText>
            </View>
          </TouchableOpacity>
        </View>
        <View style={[getPickupTimeStyles().dateView]}>
          <AVText
            style={styles.dateText}
            accessible={true}
            testID="dateText"
            accessibilityRole={'text'}
            accessibilityLabel={Localized.Labels.select_a_day_below}
            aria-label={Localized.Labels.select_a_day_below}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
          >
            {`${Localized.Labels.select_a_day_below}:`}
          </AVText>
          {showDayErrorText && !selectedDay && (
            <View style={getPickupTimeStyles().errorContainer}>
              <ErrorIcon />
              <AVText
                style={styles.errorText}
                accessible={true}
                testID="errorText"
                accessibilityRole={'text'}
                accessibilityLabel={dayErrorMessage()}
                aria-label={dayErrorMessage()}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
              >
                {dayErrorMessage()}
              </AVText>
            </View>
          )}
          <View style={styles.customTilesView}>
            {pickupDateTypes.map((date, index) => {
              return (
                <View key={index} style={getPickupTimeStyles().tilesStyle}>
                  <CustomTilesButton
                    item={date}
                    text={date.label}
                    icon={
                      <CalendarIcon
                        color={
                          date.label === selectedDay?.label
                            ? selectedIconColor
                            : inactiveIconColor
                        }
                      />
                    }
                    accessibleString={date.label}
                    onTilePress={(item) => onDateTilePress(item)}
                    selected={date.label === selectedDay?.label}
                  />
                </View>
              );
            })}
          </View>
        </View>
        <View style={getPickupTimeStyles().timeView}>
          <AVText
            style={[styles.dateText, {marginLeft: Styles.Spacing.m3}]}
            accessible={true}
            testID="dateText"
            accessibilityRole={'text'}
            accessibilityLabel={Localized.Labels.select_a_time_below}
            aria-label={Localized.Labels.select_a_time_below}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
          >
            {`${Localized.Labels.select_a_time_below}:`}
          </AVText>
          {showTimeErrorText && !selectedTime && (
            <View
              style={[
                getPickupTimeStyles().errorContainer,
                {marginLeft: Styles.Spacing.m3},
              ]}
            >
              <ErrorIcon />
              <AVText
                style={styles.errorText}
                accessible={true}
                testID="errorText"
                accessibilityRole={'text'}
                accessibilityLabel={timeErrorMessage()}
                aria-label={timeErrorMessage()}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
              >
                {timeErrorMessage()}
              </AVText>
            </View>
          )}
          <FlatList
            data={availableTimes}
            renderItem={renderPickupTimes}
            horizontal
            ListHeaderComponent={<View style={styles.headerPadding} />}
            ListHeaderComponentStyle={styles.headerPaddingContainer}
          />
        </View>
      </View>
      <View style={getPickupLocationStyles().buttonContainerStyle}>
        <RoundedButton
          color={
            !selectedDay || !selectedTime
              ? getInactiveButtonColor()
              : getActiveButtonColor()
          }
          buttonType={ButtonType.normal}
          onPress={onContinuetoMenuPress}
          accessibilityLabel={Localized.Buttons.continue_to_menu}
          accessibilityHint={'button'}
          text={Localized.Buttons.continue_to_menu}
          containerStyle={styles.button}
          textStyle={
            getPickupLocationStyles(!selectedDay || !selectedTime).buttonText
          }
        />
      </View>
    </BackSubheader>
  );
};

const styles = StyleSheet.create({
  pickupLocationView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  selectedLocationText: {
    fontWeight: '400',
    fontSize: Styles.Fonts.f7,
    color: textColor(),
    maxWidth: '80%',
    marginLeft: Styles.Spacing.m2,
    marginTop: Settings.is365Pay ? 0 : Styles.Spacing.m1 - 2,
    fontFamily: fontFamilyStyle(),
  },
  dateText: {
    fontWeight: '700',
    fontSize: Styles.Fonts.f7,
    color: Styles.darkColor,
    fontFamily: fontFamilyStyle(),
  },
  customTilesView: {
    flexDirection: 'row',
  },
  button: {
    paddingHorizontal: Styles.Spacing.m3,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: Styles.Spacing.m2,
  },
  errorText: {
    fontWeight: '700',
    fontSize: Styles.Fonts.f7,
    color: Styles.dangerColor,
    paddingHorizontal: Styles.Spacing.m1,
    fontFamily: fontFamilyStyle(),
    alignItems: 'center',
  },
  headerPadding: {
    width: Styles.Spacing.m3,
  },
  headerPaddingContainer: {
    margin: 0,
  },
});

export default withForwardedNavigationParams<NewPickupTimeScreenProps>()(
  NewPickupTimeScreen,
);
