import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import SnackDetailScreen from 'src/components/screens/sendSnack/SnackDetailScreen';
import ScanAccountScreen from 'src/components/screens/scanAccount/ScanAccountScreen';
import {RewardsTabScreenWithHOC} from 'src/components/screens/RewardsHOCScreen';
const Stack = createStackNavigator();

const screens = [
  {name: AppRoutes.RewardsScreen, component: RewardsTabScreenWithHOC},
  {name: AppRoutes.ScanAccount, component: ScanAccountScreen},
  {name: AppRoutes.SnackDetail, component: SnackDetailScreen},
];

export default function RewardsNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={AppRoutes.RewardsScreen}
      screenOptions={{
        headerShown: false,
        presentation: 'transparentModal',
        cardStyle: {
          backgroundColor: 'transparent',
        },
        cardOverlayEnabled: true,
      }}
    >
      {screens.map(({name, component}) => (
        <Stack.Screen key={name} name={name} component={component} />
      ))}
    </Stack.Navigator>
  );
}
