import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import NavActions from 'src/actions/NavActions';
import ReferralActions from 'src/actions/ReferralActions';
import AppRoutes from 'src/AppRoutes';
import AVText from 'src/components/elements/AVText';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import AccountStore from 'src/stores/AccountStore';
import {Referral} from 'src/types/Referral';
import LoadingScreen from 'src/components/screens/LoadingScreen';
import {useGlobalize} from 'react-native-globalize';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import LinearGradient from 'react-native-linear-gradient';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import {referralTemplate} from './referralHelper';
import PersistentStore from 'src/services/PersistentStoreService';

type ReferralReadyScreenProps = {
  referralId: string;
};

const ReferralReadyScreen: React.FC<ReferralReadyScreenProps> = (
  props: ReferralReadyScreenProps,
) => {
  const {formatCurrency} = useGlobalize();
  const [referral, setReferral] = useState<Referral>();

  useEffect(() => {
    fetchReferral();
  }, []);

  const fetchReferral = async () => {
    try {
      const referralDetails = await ReferralActions.getReferralDetails(
        props.referralId,
      );
      await PersistentStore.setReferralId(props.referralId);
      FirebaseAnalytic.trackEvent('fetchReferral', 'ReferralReadyScreen', {
        ...props,
        referralDetails,
      });
      setReferral(referralDetails);
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'ReferralReadyScreen:fetchReferral',
        generateErrorMessage(error),
      );
      CrashlyticsEvents.log(
        'Exception',
        'ReferralReadyScreen:fetchReferral',
        generateErrorMessage(error),
      );
    }
  };

  const onPress = () => {
    FirebaseAnalytic.trackEvent('onPress', 'ReferralReadyScreen', {
      ...props,
      referral,
    });

    NavActions.reset(AppRoutes.Welcome, {triggerAuth0fn: true});
  };

  const commonButtonProps = {
    buttonType: RoundedAppButtonType.Solid,
    onPress: onPress,
    buttonContainerStyle: styles.buttonStyle,
    buttonViewStyle: {
      backgroundColor: referralTemplate.buttonColor,
      paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
      borderRadius: Styles.Spacing.m4,
    },
    textStyle: {fontFamily: referralTemplate.textFontFamily},
  };
  return (
    <LinearGradient
      colors={referralTemplate.colors}
      start={referralTemplate.start}
      end={referralTemplate.end}
      style={styles.gradientBackground}
      locations={referralTemplate.locations}
    >
      {!referral ? (
        <LoadingScreen />
      ) : (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          accessible
        >
          {referralTemplate.icon}
          <View style={{marginTop: 40}}>
            {referral?.isActive && referral?.receiver === null ? (
              <View
                style={{marginHorizontal: Styles.Spacing.m3}}
                accessible
                accessibilityLabel="Referral Active"
              >
                <>
                  <AVText
                    accessible
                    style={[
                      styles.title,
                      {fontFamily: referralTemplate.textFontFamily},
                    ]}
                    accessibilityLabel={Localized.Labels.great_deals_your_free}
                    aria-label={`${Localized.Labels.great_deals_your_free}, text`}
                  >
                    {Localized.Labels.great_deals_your_free}
                  </AVText>
                  <AVText
                    style={[
                      styles.amount,
                      {
                        fontFamily: referralTemplate.amountFontFamily,
                        color: referralTemplate.amountColor,
                      },
                    ]}
                    accessibilityLabel={`${formatCurrency(
                      referral?.amount ?? 0,
                      AccountStore.getCurrency(),
                    )}, amount`}
                    aria-label={`${formatCurrency(
                      referral?.amount ?? 0,
                      AccountStore.getCurrency(),
                    )}, amount`}
                  >
                    {formatCurrency(
                      referral?.amount ?? 0,
                      AccountStore.getCurrency(),
                    )?.replace(AccountStore.getCurrency().substring(0, 2), '')}
                  </AVText>
                  <AVText
                    accessibilityLabel={
                      Localized.Labels.are_just_few_steps_away
                    }
                    aria-label={`${Localized.Labels.are_just_few_steps_away}, text`}
                    style={[
                      styles.text,
                      {fontFamily: referralTemplate.textFontFamily},
                    ]}
                  >
                    {Localized.Labels.are_just_few_steps_away}
                  </AVText>
                </>
                <RoundedAppButton
                  {...commonButtonProps}
                  titleText={Localized.Buttons.lets_do_it}
                  accessibilityLabelValue={`${Localized.Buttons.lets_do_it}, button`}
                  aria-label={`${Localized.Buttons.lets_do_it}, button`}
                />
              </View>
            ) : (
              <View
                accessible
                accessibilityLabel="Referral Expired"
                style={{
                  marginHorizontal: 40,
                }}
              >
                <AVText
                  accessibilityLabel={
                    Localized.Labels.unfortunately_your_reward_has_expired
                  }
                  aria-label={`${Localized.Labels.unfortunately_your_reward_has_expired}, text`}
                  style={[
                    styles.title,
                    {
                      marginBottom: Styles.Spacing.m3,
                      fontFamily: referralTemplate.textFontFamily,
                    },
                  ]}
                >
                  {Localized.Labels.unfortunately_your_reward_has_expired}
                </AVText>
                <AVText
                  style={[
                    styles.text,
                    {fontFamily: referralTemplate.textFontFamily},
                  ]}
                  accessibilityLabel={
                    Localized.Labels.but_savings_and_convenience
                  }
                  aria-label={`${Localized.Labels.but_savings_and_convenience}, text`}
                >
                  {Localized.Labels.but_savings_and_convenience}
                </AVText>
                <RoundedAppButton
                  {...commonButtonProps}
                  titleText={Localized.Buttons.complete_now}
                  accessibilityLabelValue={`${Localized.Buttons.complete_now}, button`}
                  aria-label={`${Localized.Buttons.complete_now}, button`}
                />
              </View>
            )}
          </View>
        </View>
      )}
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  gradientBackground: {
    flex: 1,
  },
  title: {
    fontSize: Styles.Fonts.headerFont,
    fontWeight: '700',
    color: Styles.Colors.PayNew.black01,
    textAlign: 'center',
  },
  text: {
    fontSize: Styles.Fonts.sectionHeader,
    textAlign: 'center',
    color: Styles.Colors.PayNew.black01,
    fontWeight: '400',
  },
  amount: {
    fontSize: Styles.Fonts.f6 - 3.34,
    paddingVertical: Styles.Spacing.m3,
    fontWeight: '700',
    textAlign: 'center',
  },
  buttonStyle: {
    alignSelf: 'center',
    marginTop: 60,
    justifyContent: 'center',
  },
});

export default withForwardedNavigationParams<ReferralReadyScreenProps>()(
  ReferralReadyScreen,
);
