import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function WarningIconSnackDetails(props) {
  return (
    <Svg fill="none" width={22} viewBox="0 0 22 22" {...props} height={22}>
      <Path
        fill="#D7263D"
        fillRule="evenodd"
        d="M22 11c0 6.075-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0s11 4.925 11 11zm-12 1V6h2v6h-2zm0 2v2h2v-2h-2z"
        clipRule="evenodd"
      />
    </Svg>
  );
}

export default WarningIconSnackDetails;
