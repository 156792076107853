import Styles from 'src/components/Styles';
import AVText from 'src/components/elements/AVText';
import {DealDescriberData} from 'src/components/screens/inbox/descriptor/DescriptorType';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Localized from 'src/constants/AppStrings';
import {View, Image} from 'react-native';
import DealIconPure from 'src/components/img/svg/DealIconPure';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {
  filterTypes,
  getTabOptions,
  InboxTab1,
  InboxTab2,
} from 'src/components/screens/inbox/newui/InboxScreenNewUI';
import InboxScreen from 'src/components/screens/inbox/InboxScreen';

const Tab = createMaterialTopTabNavigator();

export function getStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    content: {
      padding: Styles.Spacing.m3,
      flex: 1,
    },
    expiration: {
      textAlign: 'center',
      marginBottom: Styles.Spacing.m3,
      fontSize: Styles.Fonts.f0,
      marginTop: Styles.Spacing.m2,
    },
    topRow: {
      alignItems: 'center',
      marginBottom: Styles.Spacing.m3,
      flexDirection: 'row',
    },
  };
}

export function titleDescriptor(props?: DealDescriberData): string {
  return props.deal.promotionText;
}

export function dealDescDescriptor(props: DealDescriberData): JSX.Element {
  return (
    <AVText
      accessibilityLabel={props.deal.legalese}
      style={props.styles.legalese}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
    >
      {props.deal.legalese}
    </AVText>
  );
}

export function dealDefaultIconDescriptor(): JSX.Element {
  return (
    <Image
      accessibilityLabel={`Default-MenuIcon Image`}
      accessibilityRole="image"
      role="img"
      aria-label="Default-MenuIcon"
      accessible
      style={{width: 100, height: 100}}
      resizeMode="cover"
      source={require('src/components/img/CanteenDealDefault.png')}
    />
  );
}

export function dealIconDescriptor(): JSX.Element {
  return (
    <DealIconPure
      bgColor={'#138665'}
      width={Styles.Heights.h2}
      height={Styles.Heights.h2}
    />
  );
}

export function dealAppliedBtn() {
  return (
    <RoundedButton
      containerStyle={getDealDetailStyleDescriptor().appliedButton}
      text={Localized.Labels.claimed}
      onPress={() => {
        return false;
      }}
      color={'#707070'}
      textStyle={getDealDetailStyleDescriptor().appliedButtonText}
      buttonType={ButtonType.action}
    />
  );
}

export function dealApplyBtn(applyDeal: () => void) {
  return (
    <RoundedButton
      buttonType={ButtonType.action}
      color={Styles.Colors.CompanyKitchen.green}
      text={Localized.Labels.claim}
      textStyle={getDealDetailStyleDescriptor().applyButtonText}
      containerStyle={getDealDetailStyleDescriptor().appliedButton}
      onPress={applyDeal}
    />
  );
}

export function dealAppliedContainer() {
  const offer = Localized.Labels.offer_already_claimed.split(': ');
  return (
    <View style={getDealDetailStyleDescriptor()['alreadyClaimedOffer']}>
      {dealIconDescriptor()}
      <AVText style={getDealDetailStyleDescriptor()['alreadyClaimedOfferText']}>
        <AVText
          style={[
            getDealDetailStyleDescriptor()['alreadyClaimedOfferText'],
            {fontWeight: 'bold'},
          ]}
        >
          {offer[0] + ': '}
        </AVText>
        {offer[1]}
      </AVText>
    </View>
  );
}

export function backHeaderStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    backgroundColor: Styles.white,
  };
}

export function dealValidityKey() {
  return Localized.Labels.ends_on;
}

export function getDealEndDateTime() {
  return null;
}

export function getDealDetailStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    dealTitleRow: {
      alignItems: 'center',
      marginTop: Styles.Spacing.m1,
      marginLeft: Styles.Spacing.m2,
      flexDirection: 'row',
      flex: 1,
    },
    topView: {
      flexDirection: 'row',
      marginTop: Styles.Spacing.m2,
    },
    defaultImageIcon: {
      width: 100,
      borderRadius: Styles.Spacing.m2,
      height: 100,
    },
    image: {
      borderRadius: Styles.Spacing.m2,
      width: 100,
      height: 100,
    },
    content: {
      flex: 1,
      paddingVertical: Styles.Spacing.m3,
      backgroundColor: Styles.white,
      paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m1,
    },
    iconView: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    validUntil: {
      fontWeight: '400',
      fontSize: Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.aeonikRegular,
      marginTop: 40,
      alignSelf: 'center',
    },
    redeemLimit: {
      alignSelf: 'center',
      marginTop: Styles.Spacing.m3,
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    alreadyClaimedOfferText: {
      flex: 1,
      fontSize: Styles.Fonts.f8,
      color: Styles.Colors.CompanyKitchen.black,
      fontFamily: Styles.FontFamily.robotoRegular,
      alignSelf: 'center',
      marginLeft: Styles.Spacing.m2,
      textAlignVertical: 'center',
    },
    alreadyClaimedOffer: {
      flex: 1,
      marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
      borderRadius: Styles.Spacing.m2,
      backgroundColor: '#7FBC0326',
      padding: Styles.Spacing.m3,
      flexDirection: 'row',
    },
    expiration: {
      fontSize: Styles.Fonts.f8,
      fontFamily: Styles.FontFamily.robotoRegular,
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m2,
    },
    name: {
      color: Styles.black,
      justifyContent: 'center',
      width: '100%',
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.robotoRegular,
      position: 'absolute',
    },
    description: {
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
      marginTop: Styles.Spacing.m3,
      fontFamily: Styles.FontFamily.robotoRegular,
      paddingLeft: Styles.Spacing.m1,
    },
    appliedButton: {
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      paddingHorizontal: 8,
      padding: Styles.Heights.h1,
      paddingVertical: 8,
    },
    applyButtonText: {
      fontWeight: '700',
      color: Styles.Colors.CompanyKitchen.black,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
      textAlign: 'center',
    },
    appliedButtonText: {
      color: Styles.white,
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.robotoRegular,
      textAlign: 'center',
    },
  };
}

export function dealAppliedBtnIcon() {
  return null;
}

export function notificationTabscDescriptor(): JSX.Element {
  return (
    <>
      <Tab.Screen
        name={Localized.Labels.messages}
        component={InboxScreen}
        options={getTabOptions(Localized.Labels.messages)}
        initialParams={{...this.props, filterType: filterTypes.all}}
      />
      <Tab.Screen
        name={Localized.Labels.active_offers}
        component={InboxTab1}
        options={getTabOptions(Localized.Labels.active_offers)}
        initialParams={{
          ...this.props,
          filterType: filterTypes.active,
        }}
      />

      <Tab.Screen
        name={Localized.Labels.past_offers}
        component={InboxTab2}
        options={getTabOptions(Localized.Labels.past_offers)}
        initialParams={{...this.props, filterType: filterTypes.past}}
      />
    </>
  );
}

export function announcemetEmptyIcon(): JSX.Element {
  return null;
}

export default {
  dealDescDescriptor,
  getDealDetailStyleDescriptor,
  backHeaderStyleDescriptor,
  getStyleDescriptor,
  titleDescriptor,
  dealAppliedBtn,
  dealValidityKey,
  dealAppliedBtnIcon,
  dealAppliedContainer,
  getDealEndDateTime,
  dealIconDescriptor,
  dealApplyBtn,
  dealDefaultIconDescriptor,
  notificationTabscDescriptor,
  announcemetEmptyIcon,
};
