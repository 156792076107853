import React, {useContext} from 'react';
import {StyleSheet, TouchableOpacity, Dimensions, View} from 'react-native';
import Styles from '../../Styles';
import AVText from 'src/components/elements/AVText';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import {useAppSelector} from 'src/redux/hooks';
import {store} from 'src/redux/store';
import NavActions from 'src/actions/NavActions';
import {
  fetchRecentCampusesAndLocations,
  swicthCurrentCampusesAndLocations,
} from 'src/redux/slices/campusLocationSlice';
import Events from 'src/logging/Events';
import ScreenContext from 'src/components/ScreenContext';
import Settings from 'src/Settings';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {getDescriber} from 'src/components/elements/home/descriptor/DescriptorType';

export type NearbyLocationType = {
  locationId?: string;
  campusId?: string;
  displayName: string;
  distance: number;
  units?: string;
};

export enum LocationDistanceUnit {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC',
}

type NearbyLocationViewProps = {
  nearbyLocation: NearbyLocationType;
};

const width = Dimensions.get('window').width;

const NearbyLocationView = (props: NearbyLocationViewProps) => {
  const accountId = useAppSelector((s) => s.account.account.id);
  const context = useContext(ScreenContext);

  //Select Nearby Location/Campus and fetch all the locations in the selected campus
  const onClickNearbyLocation = async () => {
    try {
      context.actions.showSpinner();
      await store.dispatch(
        swicthCurrentCampusesAndLocations({
          accountId,
          locationId: props.nearbyLocation.locationId,
        }),
      );
      await store.dispatch(fetchRecentCampusesAndLocations(accountId));
      NavActions.pop();
      FirebaseAnalytic.trackEvent(
        'onClickNearbyLocation',
        'NearbyLocationView',
        {
          ...props,
        },
      );
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'NearbyLocationView:onClickNearbyLocation',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'NearbyLocationView:onClickNearbyLocation',
        generateErrorMessage(error),
      );
    } finally {
      context.actions.hideSpinner();
    }
  };

  return (
    <TouchableOpacity
      style={[
        styles.container,
        Settings.isRefiveAnd365Pay() && styles.tileDesignView,
      ]}
      onPress={onClickNearbyLocation}
      accessible={true}
      accessibilityLabel={`NearbyLocation${props.nearbyLocation.displayName}`}
      accessibilityRole="button"
    >
      <View style={styles.icon}>{getDescriber().locationListIcon()}</View>

      <AVText
        numberOfLines={1}
        accessibilityLabel={props.nearbyLocation.displayName}
        aria-label={`${props.nearbyLocation.displayName}, text`}
        accessibilityRole="text"
        accessible={true}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        style={[
          styles.nameText,
          Settings.isRefiveAnd365Pay() && styles.topMargin,
          getDescriber().getCampusLocationStyles()['locationDisplatText'],
        ]}
      >
        {props.nearbyLocation.displayName}
      </AVText>
      <AVText
        numberOfLines={1}
        accessibilityLabel={props.nearbyLocation?.distance.toFixed(1)}
        aria-label={`${props.nearbyLocation?.distance.toFixed(1)}, text`}
        accessibilityRole="text"
        accessible={true}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        style={[
          styles.distanceText,
          Settings.isRefiveAnd365Pay() && styles.topMargin,
          getDescriber().getCampusLocationStyles()['locationDisplatSubText'],
        ]}
      >
        {props.nearbyLocation?.distance.toFixed(1) +
          ' ' +
          (Settings.is365Pay() ? 'mi' : Localized.Labels.miles)}
      </AVText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: 161,
    padding: Styles.Spacing.m3,
    paddingLeft: Styles.Spacing.m2,
    height: 98,
    marginRight: Styles.Spacing.m2,
    marginLeft: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m1,
    marginBottom: Styles.Spacing.m3,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: Styles.Spacing.m2,
    borderColor: Styles.lightGray,
    borderWidth: 1,
  },
  icon: {
    height: Styles.Spacing.m4,
    marginTop: -Styles.Spacing.m2,
  },
  tileDesignView: {
    backgroundColor: '#fff',
    shadowOffset: {width: 0, height: 1},
    width: width / 2.1,
    borderColor: Styles.white,
    shadowOpacity: 0.4,
    elevation: 4,
    shadowColor: '#000',
    shadowRadius: 2,
  },
  nameText: {
    color: Styles.black,
    fontWeight: '400',
    width: 140,
    fontSize: Styles.Fonts.f1,
    textAlignVertical: 'top',
    marginRight: Styles.Spacing.m1,
    height: Styles.Spacing.m3 + Styles.Spacing.m1,
    fontFamily: Styles.FontFamily.robotoRegular,
  },
  distanceText: {
    fontSize: Styles.Fonts.f1,
    color: Styles.lightGray,
    fontWeight: '400',
    width: Styles.Heights.h6 * 2,
    textAlignVertical: 'top',
    height: Styles.Spacing.m3 + Styles.Spacing.m1,
    fontFamily: Styles.FontFamily.robotoRegular,
  },
  topMargin: {
    marginTop: Styles.Spacing.m1,
  },
});

export default NearbyLocationView;
