import {StyleSheet} from 'react-native';
import Styles from 'src/components/Styles';
import AccountStore from 'src/stores/AccountStore';

export const formatCurrency = (value, label, currency) => {
  const amount = parseFloat(value) || 0;
  return {
    style: styles.summaryLabel,
    value: amount,
    accessible: true,
    accessibilityLabel: `${label} $${amount}`,
    ariaLabel: `${label} $${amount}, text`,
    currency: currency ?? AccountStore.getCurrency(),
    maxFontSizeMultiplier: Styles.FontSizeMultiplier.maxfm3,
  };
};
const styles = StyleSheet.create({
  summaryLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginBottom: Styles.Spacing.m1,
  },
});
