import * as React from 'react';
import Svg, {Rect, Path} from 'react-native-svg';
interface IProps {
  color?: string;
  fillColor?: string;
}
const PlusIcon = ({fillColor = '#066DFF', color = 'white'}: IProps) => (
  <Svg width={48} height={49} viewBox="0 0 48 49" fill="none">
    <Rect x={8.5} y={8.95453} width={31} height={31} rx={15.5} fill={color} />
    <Rect
      x={8.5}
      y={8.95453}
      width={31}
      height={31}
      rx={15.5}
      stroke={fillColor}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.3332 25.0405H24.5207V32.7879H23.4139V25.0405H15.6665V23.9337H23.4139V16.1212H24.5207V23.9337H32.3332V25.0405Z"
      fill={fillColor}
    />
  </Svg>
);
export default PlusIcon;
