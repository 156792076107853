import React from 'react';
import {View, StyleSheet, Image, ViewStyle, StyleProp} from 'react-native';
import Styles from '../../Styles';
import AVText from 'src/components/elements/AVText';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import {getDescriber} from './descriptor/DescriptorType';

type OffersErrorViewProps = {
  errorText: string;
  buttonLabel: string;
  onPress: () => void;
  viewStyle?: StyleProp<ViewStyle>;
  isCenter?: boolean;
};

// Helper function to get styles for text and button from the describer
const useDescriberStyles = () => {
  const describer = getDescriber();
  const textColor = describer?.getTextColor?.();
  const fontFamily = describer?.offerFontFamily?.();
  const buttonColor = describer?.buttonAndIconColor?.();

  return {
    textColor,
    fontFamily,
    buttonColor,
  };
};

const OffersErrorView: React.FC<OffersErrorViewProps> = ({
  errorText,
  buttonLabel,
  isCenter,
  onPress,
}) => {
  const {textColor, fontFamily, buttonColor} = useDescriberStyles();

  return (
    <View
      style={[styles.container, isCenter ? {justifyContent: 'center'} : null]}
    >
      <ErrorImage />
      <ErrorText
        errorText={errorText}
        textColor={textColor}
        fontFamily={fontFamily}
      />
      <ErrorButton
        buttonLabel={buttonLabel}
        onPress={onPress}
        buttonColor={buttonColor}
        fontFamily={fontFamily}
      />
    </View>
  );
};

// Extracted component for Error Image
const ErrorImage: React.FC = () => (
  <Image
    source={require('../../img/Alert-Circled.png')}
    resizeMode="contain"
    accessible
    accessibilityLabel="Error Icon"
    aria-label="Error Icon"
  />
);

// Extracted component for Error Text
const ErrorText: React.FC<{
  errorText: string;
  textColor?: string;
  fontFamily?: string;
}> = ({errorText, textColor, fontFamily}) => (
  <AVText
    style={[styles.errorText, {color: textColor, fontFamily: fontFamily}]}
    accessible
    accessibilityLabel={errorText}
    aria-label={errorText}
    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
  >
    {errorText}
  </AVText>
);

// Extracted component for Error Button
const ErrorButton: React.FC<{
  buttonLabel: string;
  onPress: () => void;
  buttonColor?: string;
  fontFamily?: string;
}> = ({buttonLabel, onPress, buttonColor, fontFamily}) => (
  <RoundedAppButton
    accessibilityLabelValue={buttonLabel}
    aria-label={buttonLabel}
    titleText={buttonLabel}
    buttonType={RoundedAppButtonType.Outline}
    buttonViewStyle={styles.buttonViewStyle}
    onPress={onPress}
    textStyle={[
      styles.buttonTextStyle,
      {color: buttonColor, fontFamily: fontFamily},
    ]}
    buttonContainerStyle={{alignSelf: 'center'}}
  />
);

export default OffersErrorView;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: Styles.Spacing.m3,
  },
  errorText: {
    fontSize: Styles.Fonts.f7,
    textAlign: 'center',
    marginBottom: Styles.Spacing.m3,
    fontWeight: '400',
    marginTop: Styles.Spacing.m2 + Styles.Spacing.m1,
  },
  buttonViewStyle: {
    height: 42,
    minWidth: 120,
    overflow: 'hidden',
    borderWidth: 2,
    backgroundColor: Styles.Colors.PayNew.white01,
  },
  buttonTextStyle: {
    fontWeight: '700',
    fontSize: Styles.Fonts.f1,
  },
});
