import React, {useContext, useEffect, useRef, useState} from 'react';
import Localized from 'src/constants/AppStrings';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import Styles from 'src/components/Styles';
import {StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {NavigationProp} from '@react-navigation/native';
import BaseScreen from '../../BaseScreen';
import Util, {getPreviousRouteName} from 'src/Util';
import ScreenContext from 'src/components/ScreenContext';
import ActionsFactory from 'src/actions/ActionsFactory';
import {alertError} from 'src/components/helpers/AlertHelper';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import AccountStore from 'src/stores/AccountStore';
import uuid from 'src/nativeModules/UUID';
import AccountConstants from 'src/constants/AccountConstants';
import FundingDetails from 'src/components/elements/account/newHistoryUI/FundingDetails';
import SnackDetail from 'src/components/elements/account/newHistoryUI/SnackDetail';
import type {HistoryItem as HistoryItemType} from 'src/types/TransactionDetail';
import PurchaseDetail from 'src/components/elements/account/newHistoryUI/PurchaseDetail';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type HistoryDetailsScreenProps = WithGlobalizeProps & {
  navigation?: NavigationProp<HistoryDetailsScreenProps>;
  transactionId: string;
  transactionType: string;
  transactionDate: string;
  transactionCurrency?: string;
  transactionTypeDisplay: string;
  newHistoryItem?: HistoryItemType;
};

const HistoryDetailsScreen: React.FC<HistoryDetailsScreenProps> = (props) => {
  const historyItemData = AccountStore.getPurchaseHistoryItem(
    props.transactionId,
  );
  let transactionDetailsComponent: JSX.Element | null = null;
  const previousRoute = useRef<string | null>(null);
  const context = useContext(ScreenContext);
  const [historyDetails, setHistoryDetails] = useState(null);

  useEffect(() => {
    FirebaseAnalytic.trackEvent('useEffect', 'HistoryDetailsScreen', {
      ...props,
    });
    const routeName = getPreviousRouteName(
      props.navigation?.getState()?.routes,
    );
    previousRoute.current = routeName;
    getHistoryDetails();
  }, [props.navigation]);

  const getHistoryDetails = async () => {
    context.actions.showSpinner();
    try {
      const response =
        await ActionsFactory.getAccountActions().loadPurchaseDetail(
          AccountStore.getAccountId(),
          props.transactionId ?? historyItemData?.Id,
          props.transactionType ?? historyItemData?.Type,
          props.transactionDate ?? historyItemData?.TransDate,
          AccountStore.getLocationId(),
        );

      if (!response || Object.keys(response.Data).length == 0) {
        alertError(Localized.Errors.error_loading_transaction);
        context.actions.hideSpinner();
        return;
      }
      FirebaseAnalytic.trackEvent('getHistoryDetails', 'HistoryDetailsScreen', {
        ...props,
      });
      setHistoryDetails(response);
    } catch (error) {
      const guid = await uuid.getRandomUUID();

      alertError(Localized.Errors.error_loading_transaction, guid);
      CrashlyticsEvents.log(
        'Exception',
        'HistoryDetailsScreen:getHistoryDetails',
        generateErrorMessage(error),
        guid,
        {
          transactionId: props.transactionId,
        },
      );

      Events.Error.trackEvent(
        'Exception',
        'HistoryDetailsScreen:getHistoryDetails',
        generateErrorMessage(error),
        guid,
        {
          transactionId: props.transactionId,
        },
      );
    } finally {
      context.actions.hideSpinner();
    }
  };
  /** email receipt funddetails */
  const handleEmailFundingReceipt = async (transId: string) => {
    context.actions.showSpinner();
    const parsedDateString = Util.parseDateTime(
      historyDetails.Data?.DateString,
    );

    try {
      const response =
        await ActionsFactory.getAccountActions().emailFundingReceipt(
          AccountStore.getEmail(),
          Util.formatCurrency(
            props,
            props.newHistoryItem?.Amount ?? 0,
            props.transactionCurrency ?? AccountStore.getCurrency(),
          ),
          parsedDateString.date,
          parsedDateString.time,
          props.newHistoryItem?.Location ?? AccountStore.getLocationName(),
          historyDetails?.Data?.Id ?? transId,
          Util.formatCurrency(
            props,
            props.newHistoryItem?.Balance ?? 0,
            props.transactionCurrency ?? AccountStore.getCurrency(),
          ),
          AccountStore.getFirstName(),
        );

      if (response.status === 'ok') {
        FirebaseAnalytic.trackEvent(
          'handleEmailFundingReceipt',
          'HistoryDetailsScreen',
          {
            ...props,
          },
        );
        context.actions.showModal(
          Localized.Labels.an_email_was_sent_to_your_inbox,
          Localized.Success.success,
          Localized.Buttons.okay,
          Styles.darkGreenRevolve,
        );
      } else {
        FirebaseAnalytic.trackEvent(
          'handleEmailFundingReceipt:Exception',
          'HistoryDetailsScreen:HandleEmailFundingReceipt',
          {
            ...props,
          },
        );
      }
    } catch (error) {
      const uid = await uuid.getRandomUUID();
      alertError(Localized.Errors.error_emailing_receipt, uid);
      CrashlyticsEvents.log(
        'Exception',
        'HistoryDetailScreen:HandleEmailFundingReceipt',
        generateErrorMessage(error),
        uid,
      );
      Events.Error.trackEvent(
        'Exception',
        'HistoryDetailScreen:HandleEmailFundingReceipt',
        generateErrorMessage(error),
        uid,
      );
    } finally {
      context.actions.hideSpinner();
    }
  };
  const headerLabel =
    props.transactionTypeDisplay === 'Snack Credit'
      ? Localized.Labels.send_a_snack_title
      : props.transactionTypeDisplay;

  switch (props.transactionType) {
    case AccountConstants.SALE_TYPE:
      transactionDetailsComponent = (
        <PurchaseDetail
          purchaseDetail={{
            ...historyDetails?.Data,
            currency: props.transactionCurrency,
          }}
          currency={props.transactionCurrency}
          transactionId={props.transactionId}
          transactionType={props.transactionType}
          Balance={props.newHistoryItem?.Balance}
        />
      );
      break;
    case AccountConstants.FUNDING_TYPE:
      transactionDetailsComponent = (
        <FundingDetails
          strings={Localized}
          fundDetail={{
            ...historyDetails?.Data,
            Location: historyItemData?.Location,
            Balance: props.newHistoryItem?.Balance,
            Source: historyItemData?.Source,
            past90Days: historyItemData?.past90Days,
          }}
          currency={props.transactionCurrency}
          handleEmailReceipt={handleEmailFundingReceipt}
        />
      );
      break;
    case AccountConstants.SEND_SNACK:
    case AccountConstants.SEND_SNACK_CANCEL:
    case AccountConstants.SEND_SNACK_REVERSE:
    case AccountConstants.SEND_SNACK_ACCEPT:
    case AccountConstants.SEND_SNACK_EXPIRE:
      transactionDetailsComponent = (
        <SnackDetail
          snackDetail={{
            ...historyDetails?.Data,
            BalanceTypeDisplay: historyItemData?.BalanceTypeDisplay,
          }}
        />
      );
      break;
  }
  return (
    <BaseScreen
      previousRoute={previousRoute.current}
      accessibilityLabel={Localized.Buttons.back_arrow}
      aria-label={Localized.Buttons.back_arrow}
      accessibilityHint={`Press to navigate back to the ${previousRoute.current} screen`}
      title={headerLabel}
    >
      <View style={styles.container}>{transactionDetailsComponent}</View>
    </BaseScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Styles.tabBarBackgroundColor,
  },
});

export default withForwardedNavigationParams<HistoryDetailsScreenProps>()(
  withGlobalize(HistoryDetailsScreen),
);
