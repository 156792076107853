import RedesignDescriptor from './campus-components-redesign-descriptor';
import ConnectPaydescriptor from './campus-components-connectpay-descriptor';
import RefiveDescriptor from './campus-components-refive-descriptor';
import CompanyKitchenDescriptor from './campus-components-companykitchen-descriptor';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import {StyleProp, ViewStyle, TextStyle} from 'react-native';

const typeDescriber = {
  [BuildTypes.canteen]: ConnectPaydescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export interface SendSnackDescriberData {
  styles: {
    [index: string]: {
      [index: string]: number | string | {width: number; height: number};
    };
  };
  onSendASnackClick: () => void;
}
export interface ReferralDescriberData {
  styles: {
    [index: string]: {
      [index: string]: number | string | {width: number; height: number};
    };
  };
  onInviteClick: () => void;
  title: string | string[];
  stat: string | (string | number)[];
  amount?: number;
  isSnackEnabled?: boolean;
}

export interface RewardsLegacykDescriberData {
  styles: {
    [index: string]: {
      [index: string]: number | string | {width: number; height: number};
    };
  };
  onRewardsClick: () => void;
  stat: string | (string | number)[];
  isOfferAvailable?: boolean;
}

export interface AnnouncementStylesDescriptor {
  container: StyleProp<ViewStyle>;
  titleText: StyleProp<TextStyle>;
  descriptionText: StyleProp<TextStyle>;
}
export interface CampusLocationListStylesDescriptor {
  container: StyleProp<ViewStyle>;
  campusLocationListView: StyleProp<ViewStyle>;
  headerMargin: StyleProp<ViewStyle>;
  diningLocationLabel?: StyleProp<TextStyle>;
}

export interface RewardskDescriberData {
  styles: {
    [index: string]: {
      [index: string]:
        | number
        | string
        | {width: number; height: number}
        | Array<{
            [key: string]: number | string;
          }>;
    };
  };
  onRewardsClick: () => void;
  rewardTier: {
    tierName: string;
    currentYearPoints: number;
    pointsRate: number;
    nextTierName: string;
    pointsToQualify: number;
  };
  marketAccountPoints: number;
  maxPointsLabel: string;
  bgSize: number;
  tileHeight: number;
  productThresholds: number[];
  maxPointValue: number;
}
export interface EngagementCarouselStylesDescriptor {
  activeDotStyle?: StyleProp<ViewStyle>;
  inActiveDotStyle?: StyleProp<ViewStyle>;
  imageContentContainer?: StyleProp<ViewStyle>;
  parralaxContainer?: StyleProp<ViewStyle>;
}

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
