import React from 'react';
import Svg, {Path} from 'react-native-svg';

class SpinnerOutSVG extends React.Component<{size: number}> {
  render() {
    return (
      <Svg
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 927 927"
        fill="none"
      >
        <Path
          d="M463.5 926.693C208.096 926.693.306 718.904.306 463.499.306 208.095 208.096.306 463.5.306c255.404 0 463.194 207.789 463.194 463.193h-52.722c0-226.339-184.132-410.472-410.472-410.472-226.34 0-410.473 184.133-410.473 410.472 0 226.34 184.133 410.473 410.473 410.473v52.721z"
          fill="#066DFF"
        />
      </Svg>
    );
  }
}

export default SpinnerOutSVG;
