import CanteenNewLogo from 'src/components/img/svg/CanteenNewLogo';
import PayNewLogo from 'src/components/img/svg/365PayNewLogo';
import PayRocketIcon from 'src/components/img/svg/365RocketIcon';
import CanteenRocketIcon from 'src/components/img/svg/CanteenRocketIcon';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import Styles from 'src/components/Styles';

const referralThemeConfigurations = {
  [BuildTypes.canteen]: {
    icon: <CanteenNewLogo />,
    colors: [
      Styles.white,
      'rgba(251, 255, 243, 1)',
      'rgba(227, 244, 192, 1)',
      Styles.Colors.Canteen.lightGreen,
    ],
    locations: [0.1, 0.25, 0.5, 1.0],
    start: {x: 0, y: 0},
    end: {x: 0, y: 1},
    buttonColor: Styles.Colors.Canteen.darkGreen,
    amountColor: Styles.Colors.Canteen.darkGreen,
    starIconColor: Styles.Colors.Canteen.green,
    textFontFamily: Styles.FontFamily.robotoRegular,
    amountFontFamily: Styles.FontFamily.robotoRegular,
    accountReadyIcon: <CanteenRocketIcon />,
  },
  [BuildTypes.default]: {
    icon: <PayNewLogo />,
    colors: [
      Styles.Colors.PayNew.support01,
      Styles.Colors.PayNew.blueHuesBase07,
    ],
    start: {x: 0, y: 0},
    end: {x: 0, y: 1},
    locations: [0, 0.4537],
    buttonColor: Styles.Colors.PayNew.primary01,
    amountColor: Styles.Colors.PayNew.primary01,
    starIconColor: Styles.Colors.PayNew.primary01,
    textFontFamily: Styles.FontFamily.aeonikRegular,
    amountFontFamily: Styles.FontFamily.aeonikRegular,
    accountReadyIcon: <PayRocketIcon />,
  },
  [BuildTypes.companykitchen]: {
    icon: <CanteenNewLogo />,
    colors: [
      Styles.white,
      'rgba(251, 255, 243, 1)',
      'rgba(227, 244, 192, 1)',
      Styles.Colors.Canteen.lightGreen,
    ],
    locations: [0.1, 0.25, 0.5, 1.0],
    start: {x: 0, y: 0},
    end: {x: 0, y: 1},
    buttonColor: Styles.Colors.Canteen.darkGreen,
    amountColor: Styles.Colors.Canteen.darkGreen,
    starIconColor: Styles.Colors.Canteen.green,
    textFontFamily: Styles.FontFamily.robotoRegular,
    amountFontFamily: Styles.FontFamily.robotoRegular,
    accountReadyIcon: <CanteenRocketIcon />,
  },
};
export const referralTemplate = referralThemeConfigurations[Settings.buildType];
