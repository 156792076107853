import {Platform} from 'react-native';

class PlatformProvider {
  static platformKey: Record<string, string> = {
    ios: 'IPHONE',
    android: 'ANDROID',
    web: 'ANDROID', // promotions don't show in 'WEB'
  };

  static getPlatformName() {
    return PlatformProvider.platformKey[Platform.OS];
  }
}

export default PlatformProvider;
