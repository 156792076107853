import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import Styles from 'src/components/Styles';

const scanandPayThemeConfigurations = {
  [BuildTypes.default]: {
    textFontFamily: Styles.FontFamily.aeonikRegular,
    deleteIconColor: Styles.Colors.PayNew.primary01,
    discountTagColor: Styles.Colors.PayNew.success,
    emptyCartImage: require('../../../img/scan-product.png'),
    categorySelected: {
      selectedCategoryColor: Styles.Colors.PayNew.primary01,
      selectCategoryBorderColor: Styles.Colors.PayNew.primary01,
    },
    productListingRoundedBorder: Styles.Spacing.m3,
    searchIconColor: Styles.Colors.PayNew.darkHuesBase04,
  },
  [BuildTypes.canteen]: {
    textFontFamily: Styles.FontFamily.robotoRegular,
    deleteIconColor: Styles.primaryColor,
    discountTagColor: Styles.Colors.PayNew.success,
    emptyCartImage: require('../../../img/scan-product.png'),
    categorySelected: {
      selectedCategoryColor: '',
      selectCategoryBorderColor: '',
    },
    productListingRoundedBorder: 0,
    searchIconColor: '',
  },
  [BuildTypes.companykitchen]: {
    textFontFamily: Styles.FontFamily.robotoRegular,
    deleteIconColor: Styles.primaryColor,
    discountTagColor: Styles.Colors.PayNew.success,
    emptyCartImage: require('../../../img/scan-product.png'),
    categorySelected: {
      selectedCategoryColor: '',
      selectCategoryBorderColor: '',
    },
    productListingRoundedBorder: 0,
    searchIconColor: '',
  },
  [BuildTypes.refive]: {
    textFontFamily: Styles.FontFamily.figtreeRegular,
    deleteIconColor: Styles.Colors.PayNew.darkHuesBase04,
    discountTagColor: Styles.Colors.Refive.activeFooterBlue,
    emptyCartImage: require('../../../img/scanProductRefive.png'),
    categorySelected: {
      selectedCategoryColor: Styles.Colors.Refive.activeFooterBlue,
      selectCategoryBorderColor: Styles.Colors.Refive.activeFooterBlue,
    },
    productListingRoundedBorder: Styles.Spacing.m2,
    searchIconColor: Styles.Colors.PayLegacy.grayScaleBase04,
  },
};
export const scanAndPayTemplate =
  scanandPayThemeConfigurations[Settings.buildType];
