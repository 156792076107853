import React from 'react';
import {View} from 'react-native';
import type {TimeSlotType} from 'src/types/Menu';
import TransactionStore from 'src/stores/TransactionStore';
import {WithLocalizedProps} from 'src/types/PropTypes';
import {LocationType} from 'src/types/Location';
import {getDescriber} from './descriptor/DescriptorType';

const {checkoutBarUI} = getDescriber();

type CheckoutBarProps = WithLocalizedProps & {
  location: LocationType;
  onPress?: () => void;
};

type CheckoutBarState = {
  numItems: number;
  pickupTime?: TimeSlotType;
  displayLocation: string;
};

class CheckoutBar extends React.Component<CheckoutBarProps, CheckoutBarState> {
  constructor(props: CheckoutBarProps) {
    super(props);
    this.onTransactionStoreChanged = this.onTransactionStoreChanged.bind(this);
    this.getNumItems = this.getNumItems.bind(this);
    const numItems = this.getNumItems();
    const pickupLocation = TransactionStore.getPickupLocationName();

    this.state = {
      numItems,
      pickupTime: TransactionStore.getPickupTime(),
      displayLocation: pickupLocation || props.location?.name,
    };
  }

  componentDidMount() {
    TransactionStore.addChangeListener(this.onTransactionStoreChanged);
  }

  componentWillUnmount() {
    TransactionStore.removeChangeListener(this.onTransactionStoreChanged);
  }

  onTransactionStoreChanged() {
    const numItems = this.getNumItems();
    const pickupTime = TransactionStore.getPickupTime();
    const pickupLocation = TransactionStore.getPickupLocationName();

    this.setState({
      numItems,
      pickupTime,
      displayLocation: pickupLocation || this.props.location?.name,
    });
  }

  getNumItems() {
    const displayItems = TransactionStore.getDisplayItems();
    return displayItems?.length;
  }

  render() {
    return (
      <View>
        {checkoutBarUI({
          numItems: this.state.numItems,
          pickupTime: this.state.pickupTime,
          displayLocation: this.state.displayLocation,
          onPress: this.props.onPress,
        })}
      </View>
    );
  }
}
export default CheckoutBar;
