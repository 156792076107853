import Settings from 'src/Settings';
import RedesignDescriptor from './button-redesign';
import RefiveDescriptor from './button-refive';
import CanteenDescriptor from './button-canteen';
import CompanyKitchenDescriptor from './button-companykitchen';
import BuildTypes from 'src/constants/BuildTypeConstants';
import {ButtonType} from 'src/components/elements/RoundedButton';

const typeDescriber = {
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export function getButtonDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}

export interface RoundedBtnDescriberProps {
  disabled?: boolean;
  color?: string;
  buttonType: ButtonType;
  textColor?: string;
}
