import AccountScreenRedesignDescriptor from './account-redesign';
import AccountScreenRefiveDescriptor from './account-refive';
import AccountScreenCanteenDescriptor from './account-canteen';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';

const typeDescriber = {
  [BuildTypes.canteen]: AccountScreenCanteenDescriptor,
  [BuildTypes.default]: AccountScreenRedesignDescriptor,
  [BuildTypes.companykitchen]: AccountScreenCanteenDescriptor,
  [BuildTypes.refive]: AccountScreenRefiveDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
