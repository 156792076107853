import {HomeLocationProps} from 'src/components/elements/descriptor/DescriptorType';
import Styles from '../../../Styles';
import React from 'react';
import LocationMarker from 'src/components/img/svg/LocationMarker';
import AVText from 'src/components/elements/AVText';
import FontAwesome5Pro from 'react-native-vector-icons/FontAwesome5Pro';
import NotificationIcon from 'src/components/img/svg/newui/notificationLogo';
import NotificationUnReadIcon from 'src/components/img/svg/newui/notificationUnReadLogo';
import Localized from 'src/constants/AppStrings';
import ShopIcon from 'src/components/img/svg/ShopIcon';

export function getCampusLocationStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    linkLocationBtnStyle: {
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
      color: Styles.Colors.CompanyKitchen.black,
    },
    linkView: {
      alignSelf: 'flex-start',
      borderBottomColor: Styles.Colors.CompanyKitchen.black,
      borderBottomWidth: 1.5,
    },
    descriptionText: {
      fontFamily: Styles.FontFamily.robotoRegular,
    },
  };
}

export function getScanProductBtnStyle(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    applyButtonText: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
      textAlign: 'right',
      fontWeight: '700',
    },
  };
}

export function backGroundColorHomeScreen(): string {
  return Styles.white;
}
export function locationListIcon(): JSX.Element {
  return <ShopIcon size={Styles.Spacing.m4} />;
}
export function locationHeaderName(): string {
  return Localized.Labels.locations;
}

export function getScanProductName(): string {
  return Localized.Labels.scan_product;
}

export function linkLocationDropDown(props?: HomeLocationProps): JSX.Element {
  return (
    <>
      <LocationMarker size={Styles.Fonts.f3} />
      <AVText
        style={[homeLocationHeaderStyleDescriptor()['campusNameText']]}
        numberOfLines={1}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
      >
        {props.locationNameText}
      </AVText>
      <FontAwesome5Pro
        accessible={true}
        accessibilityLabel={
          'Down arrow icon, Double tap to the Locations Screen'
        }
        aria-label={'Down arrow icon, Double tap to the Locations Screen'}
        name="chevron-down"
        color={Styles.lightGray}
        size={Styles.Heights.touchTargetHeight0}
        style={[homeLocationHeaderStyleDescriptor()['campusArrow']]}
      />
    </>
  );
}

export function readUnreadNotificationIcon(
  props?: HomeLocationProps,
): JSX.Element {
  return (
    <>
      {props.readOrUnreadNotificaton ? (
        <NotificationUnReadIcon color={'#003349'} size={Styles.Fonts.f4 + 6} />
      ) : (
        <NotificationIcon color={'#003349'} size={Styles.Fonts.f3} />
      )}
    </>
  );
}

export function homeLocationHeaderStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    campusNameText: {
      marginLeft: Styles.Spacing.m0,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      maxWidth: '100%',
      color: Styles.darkColor,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    campusArrow: {
      marginLeft: Styles.Spacing.m2,
      marginTop: 2,
    },
  };
}
export function greetingBalanceRewardsView(): JSX.Element {
  return null;
}

export default {
  getScanProductName,
  getScanProductBtnStyle,
  getCampusLocationStyles,
  backGroundColorHomeScreen,
  homeLocationHeaderStyleDescriptor,
  linkLocationDropDown,
  readUnreadNotificationIcon,
  locationHeaderName,
  locationListIcon,
  greetingBalanceRewardsView,
};
