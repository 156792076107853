import React, {useContext} from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import MainConsumerContext from 'src/components/MainConsumerContext';
import Localized from 'src/constants/AppStrings';
import HistoryNavigator from 'src/navigators/tabs/HistoryTab';
import WalletNavigator from 'src/navigators/tabs/WalletTab';
import BottomTabBar from 'src/navigators/BottomTabBarComponent';
import Styles from 'src/components/Styles';
import HomeNavigator from 'src/navigators/tabs/HomeTab';
import ManageAccountNavigator from 'src/navigators/tabs/ManageAccountTab';
import Settings from 'src/Settings';
import OffersNavigator from 'src/navigators/tabs/OffersTab';
import RevolveHomeNavigator from 'src/navigators/tabs/RevolveHomeTab';
import ScanNavigator from 'src/navigators/tabs/ScanTab';
import BuildTypeConstants from 'src/constants/BuildTypeConstants';
import RewardsNavigator from 'src/navigators/tabs/RewardsTab';
import NetInfo from '@react-native-community/netinfo';
const BottomTab = createBottomTabNavigator();

export type BottomTabNavigatorProps = {
  showOffer: boolean;
  showRewards?: boolean;
};

const BottomTabNavigator = (prop: BottomTabNavigatorProps) => {
  const context = useContext(MainConsumerContext);
  const getNetworkState = async () => {
    const networkState = await NetInfo.fetch();
    return networkState?.isConnected;
  };
  const isConnected = getNetworkState();
  const allTabs = {
    home: {name: Localized.Labels.home, component: HomeNavigator},
    rewards: {name: Localized.Labels.rewards, component: RewardsNavigator},
    wallet: {name: Localized.Labels.wallet, component: WalletNavigator},
    history: {name: Localized.Labels.history, component: HistoryNavigator},
    account: {
      name: Localized.Labels.account,
      component: ManageAccountNavigator,
    },
    pay: {name: Localized.Labels.pay, component: ScanNavigator},
    homeRevolve: {name: Localized.Labels.home, component: RevolveHomeNavigator},
    offers: {name: Localized.Labels.offers, component: OffersNavigator},
  };

  const generateTabs = () => {
    if (
      Settings.buildType === BuildTypeConstants.default ||
      Settings.buildType === BuildTypeConstants.refive
    ) {
      /** 365 pay tabs */
      let defaultTabs = [
        allTabs.home,
        allTabs.pay,
        allTabs.offers,
        allTabs.rewards,
        //allTabs.history /** TODO: need to remove post new account management ui has been designed in 365pay */,
        allTabs.account,
      ];
      if (!prop.showOffer) {
        defaultTabs = defaultTabs.filter(
          (tab) => tab.name !== Localized.Labels.offers,
        );
      }
      if (!prop.showRewards && isConnected) {
        defaultTabs = defaultTabs.filter(
          (tab) => tab.name !== Localized.Labels.rewards,
        );
      }
      return defaultTabs;
    } else if (
      Settings.buildType === BuildTypeConstants.canteen ||
      Settings.buildType === BuildTypeConstants.companykitchen
    ) {
      /** connect and pay or company kitchen tabs */
      let canteenTabs = [
        allTabs.home,
        allTabs.rewards,
        allTabs.wallet,
        allTabs.history,
        allTabs.account,
      ];
      if (!prop.showOffer) {
        canteenTabs = canteenTabs.filter(
          (tab) => tab.name !== Localized.Labels.offers,
        );
      }

      if (!prop.showRewards && isConnected) {
        canteenTabs = canteenTabs.filter(
          (tab) => tab.name !== Localized.Labels.rewards,
        );
      }
      return canteenTabs;
    }
  };

  const tabBarArray = generateTabs();

  return (
    <BottomTab.Navigator
      tabBar={(props) => <BottomTabBar {...props} />}
      initialRouteName={context?.state.initialTabRoute ?? 'home'}
      screenOptions={{
        headerShown: false,
        lazy: false,
        tabBarActiveTintColor: Styles.primaryColor,
        tabBarInactiveTintColor: Styles.lightGray,
        unmountOnBlur: true,
        tabBarAllowFontScaling: false,
      }}
    >
      {tabBarArray.map((tabs, index) => {
        return (
          <BottomTab.Screen
            key={index}
            name={tabs.name}
            component={tabs.component}
            options={{
              tabBarLabel: tabs.name,
              tabBarAccessibilityLabel: tabs.name,
              tabBarTestID: tabs.name,
            }}
          />
        );
      })}
    </BottomTab.Navigator>
  );
};

export default BottomTabNavigator;
