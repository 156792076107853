import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  color?: string;
  width?: number;
  height?: number;
}
const CheckIcon = ({color = '#025D33', width = 14, height = 14}: IProps) => (
  <Svg width={width} height={height} fill="none">
    <Path
      fill={color}
      fillRule="evenodd"
      d="M7 12.834A5.333 5.333 0 0 0 12.333 7.5h1.334a6.667 6.667 0 1 1-4.154-6.173L8.467 2.373A5.333 5.333 0 1 0 7 12.834ZM3.333 7.167l.94-.947 2.06 2.06 5.727-5.726.94.946-6.667 6.667-3-3Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default CheckIcon;
