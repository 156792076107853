import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, Rect} from 'react-native-svg';

interface IProps {
  width?: number;
  height?: number;
  color?: string;
}

function AccountRedesignDarkIcon({width = 35, height = 34}: IProps) {
  return (
    <Svg width={width} height={height} viewBox="0 0 35 34" fill="none">
      <G clip-path="url(#clip0_718_743)">
        <Path
          d="M22.1071 10.5C22.1071 9.42256 21.6744 8.38925 20.9042 7.62738C20.134 6.86551 19.0893 6.4375 18 6.4375C16.9107 6.4375 15.8661 6.86551 15.0958 7.62738C14.3256 8.38925 13.8929 9.42256 13.8929 10.5C13.8929 11.5774 14.3256 12.6108 15.0958 13.3726C15.8661 14.1345 16.9107 14.5625 18 14.5625C19.0893 14.5625 20.134 14.1345 20.9042 13.3726C21.6744 12.6108 22.1071 11.5774 22.1071 10.5ZM11.4286 10.5C11.4286 8.77609 12.1209 7.12279 13.3533 5.90381C14.5857 4.68482 16.2571 4 18 4C19.7429 4 21.4143 4.68482 22.6467 5.90381C23.8791 7.12279 24.5714 8.77609 24.5714 10.5C24.5714 12.2239 23.8791 13.8772 22.6467 15.0962C21.4143 16.3152 19.7429 17 18 17C16.2571 17 14.5857 16.3152 13.3533 15.0962C12.1209 13.8772 11.4286 12.2239 11.4286 10.5ZM9.03103 27.5625H26.969C26.5121 24.348 23.7192 21.875 20.3462 21.875H15.6538C12.2808 21.875 9.48795 24.348 9.03103 27.5625ZM6.5 28.4918C6.5 23.4898 10.5969 19.4375 15.6538 19.4375H20.3462C25.4031 19.4375 29.5 23.4898 29.5 28.4918C29.5 29.3246 28.8172 30 27.9752 30H8.02478C7.18281 30 6.5 29.3246 6.5 28.4918Z"
          fill="#066DFF"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_718_743">
          <Rect
            width="23"
            height="26"
            fill="white"
            transform="translate(6.5 4)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default AccountRedesignDarkIcon;
