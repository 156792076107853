import React from 'react';
import InboxScreenNewUI from 'src/components/screens/inbox/newui/InboxScreenNewUI';

export function inboxComponentDescriptor(): React.ComponentType {
  return InboxScreenNewUI;
}

export default {
  inboxComponentDescriptor,
};
