import React, {useState} from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Dimensions,
  Image,
} from 'react-native';
import Styles from '../../Styles';
import AVText from 'src/components/elements/AVText';
import {CampusLocationType} from 'src/components/elements/home/CampusLocationView';
import ChevronRight from 'src/components/img/svg/ChevronRight';
import {getDescriber} from '../home/descriptor/DescriptorType';

const {getCampusLocationListStyles} = getDescriber();

type OrderAheadViewProps = {
  onLocationClick: (machine, autoAdvanced?: boolean) => Promise<void>;
  location: CampusLocationType;
  isNotScrollable?: boolean;
};

const width = Dimensions.get('window').width;

const OrderAheadView = (props: OrderAheadViewProps) => {
  const [numLines, setNumLines] = useState(0);
  return (
    <TouchableOpacity
      accessible={true}
      accessibilityHint={`Double tap to select ${
        props.location.displayName ?? props.location.name
      }`}
      accessibilityRole="button"
      accessibilityLabel={`Location${
        props.location.displayName ?? props.location.name
      }`}
      onPress={() => props.onLocationClick(props.location)}
      aria-label={`Location${
        props.location.displayName ?? props.location.name
      }`}
      style={[
        styles.container,
        props.isNotScrollable && {
          width: width - Styles.Spacing.m3 * 2,
          marginRight: Styles.Spacing.m3,
          marginLeft: Styles.Spacing.m3,
        },
      ]}
    >
      <View style={styles.iconView}>
        {numLines > 4 ? (
          <Image
            source={require('src/components/img/diningTile.png')}
            resizeMode="contain"
          />
        ) : (
          <Image
            source={require('src/components/img/dining.png')}
            resizeMode="contain"
          />
        )}
      </View>
      <View style={styles.labelView}>
        <AVText
          accessibilityRole="text"
          accessibilityLabel={`DiningLocationName${
            props.location.displayName ?? props.location.name
          }`}
          style={[
            styles.nameText,
            getCampusLocationListStyles().diningLocationLabel,
          ]}
          aria-label={`DiningLocationName${
            props.location.displayName ?? props.location.name
          }, text`}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
          onTextLayout={(e) => setNumLines(e.nativeEvent.lines.length)}
        >
          {props.location.displayName ?? props.location.name}
        </AVText>
      </View>
      <View style={styles.rightChevronView}>
        <ChevronRight color={Styles.Colors.PayNew.darkHuesBase04} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: Styles.Spacing.m3,
    width: width - Styles.Spacing.m3 * 3,
    justifyContent: 'space-between',
    backgroundColor: Styles.Colors.PayNew.white01,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: Styles.Spacing.m2,
    ...Styles.Style.shadow,
  },
  iconView: {
    height: '100%',
    borderTopLeftRadius: Styles.Spacing.m2,
    borderBottomLeftRadius: Styles.Spacing.m2,
    backgroundColor: Styles.blackBase01,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  labelView: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: Styles.Spacing.m2 + Styles.Spacing.m1,
    paddingVertical: Styles.Spacing.m1,
  },

  nameText: {
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    flexWrap: 'wrap',
    width: '80%',
  },
  rightChevronView: {
    right: Styles.Spacing.m3 - Styles.Spacing.m1,
    position: 'absolute',
  },
});

export default OrderAheadView;
