import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  color?: string;
  width?: number;
  height?: number;
}
const BulletPoint = ({width = 8, height = 14, color = '#111111'}: IProps) => (
  <Svg width={width} height={height} viewBox="0 0 8 14" fill="none">
    <Path
      d="M0 7.321c0-1.027.421-2.013 1.172-2.74A4.066 4.066 0 014 3.446c1.06 0 2.078.409 2.828 1.135A3.815 3.815 0 018 7.321a3.815 3.815 0 01-1.172 2.74A4.066 4.066 0 014 11.196a4.066 4.066 0 01-2.828-1.135A3.815 3.815 0 010 7.321z"
      fill={color}
    />
  </Svg>
);
export default BulletPoint;
