import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import {RenderItemProps} from 'src/components/elements/cart/descriptor/DescriptorType';
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  ViewStyle,
  StyleProp,
  Platform,
} from 'react-native';
type ProductCatButtonStyle = StyleProp<ViewStyle>;

export function searchFieldLabelDescriptor(data: typeof Localized): string {
  return `${data.Labels.searchproducts}...`;
}

export function productCatSelectedStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    backgroundColor: Styles.Colors.PayNew.primary01,
    borderColor: Styles.Colors.PayNew.primary01,
    overflow: 'hidden',
  };
}

export function productCatButtonStyleDescriptor(): ProductCatButtonStyle {
  return {
    backgroundColor: Styles.Colors.PayNew.white01,
    borderRadius: Styles.Spacing.m4,
    minHeight: 38,
    marginRight: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
    borderWidth: 1,
    overflow: 'hidden',
    borderColor: Styles.Colors.PayNew.neutralHuesBase09,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: Styles.Spacing.m1,
    ...Styles.Style.lightShadow,
  };
}

export function productCatTextStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
    color: Styles.Colors.PayNew.black01,
    fontFamily: Styles.FontFamily.aeonikRegular,
    lineHeight: 20,
  };
}
export function productCatSelectedTextStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.Colors.PayNew.white01,
    fontWeight: '700',
  };
}

export function productCatrenderItem({
  item,
  index,
  selectedCategory,
  categoriesLength,
  onCategoryPress,
}: RenderItemProps): JSX.Element {
  return (
    <TouchableOpacity
      role="button"
      onPress={() => onCategoryPress(item)}
      key={index}
      style={{
        backgroundColor: 'transparent',
        ...(Platform.OS == 'web' ? {} : styles.shadow),
      }}
      accessibilityRole="button"
    >
      <View
        accessible
        style={[
          productCatButtonStyleDescriptor(),
          selectedCategory === item.key && productCatSelectedStyleDescriptor(),
          index === 0 && styles.firstItem,
          index === categoriesLength - 1 && styles.lastItem,
        ]}
      >
        <Text
          style={[
            productCatTextStyleDescriptor(),
            selectedCategory === item.key &&
              productCatSelectedTextStyleDescriptor(),
          ]}
          accessibilityHint="Tap to filter products by selected category"
          accessibilityLabel={`${item.name} ${Localized.Buttons.category}`}
          aria-label={`${item.name} ${Localized.Buttons.category}`}
        >
          {item?.name}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  firstItem: {
    marginLeft: Styles.Spacing.m3,
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {width: 2, height: 4},
    shadowOpacity: 0.08,
    shadowRadius: 2,
    elevation: 4,
  },
  lastItem: {
    marginRight: Styles.Spacing.m3,
  },
});

export default {
  searchFieldLabelDescriptor,
  productCatSelectedStyleDescriptor,
  productCatButtonStyleDescriptor,
  productCatTextStyleDescriptor,
  productCatSelectedTextStyleDescriptor,
  productCatrenderItem,
};
