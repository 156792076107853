import React from 'react';
import {View} from 'react-native';
import AVText from '../../AVText';
import AVFormattedCurrency from '../../AVFormattedCurrency';
import {formatCurrency} from 'src/components/helpers/HistoryHelper';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';

import {getDescriber} from '../descriptor/DescriptorType';
import Settings from 'src/Settings';
type SubtotalComponentProps = {
  subtotal: number;
  currency?: string;
};

const SubtotalComponent: React.FC<SubtotalComponentProps> = ({
  subtotal,
  currency,
}) => {
  return (
    <View style={Styles.Style.revolveRow}>
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
        accessible={true}
        accessibilityLabel={`${Localized.Labels.subtotal} label`}
        accessibilityRole="text"
        aria-label={`${Localized.Labels.subtotal} label, text`}
        style={getDescriber().PurchaseDetailSubtotalStyleDescriptor()}
      >
        {Localized.Labels.subtotal}
      </AVText>
      <AVFormattedCurrency
        {...formatCurrency(subtotal, Localized.Labels.subtotal, currency)}
        style={[
          Settings.isRefive() && {
            color: Styles.darkColor,
            fontFamily: Styles.FontFamily.figtreeRegular,
            fontSize: Styles.Fonts.f1 + 2,
            fontWeight: '400',
          },
        ]}
      />
    </View>
  );
};

export default SubtotalComponent;
