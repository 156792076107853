import * as React from 'react';
import Svg, {ClipPath, Defs, G, Path, Rect} from 'react-native-svg';
type LogoProps = {
  size: number;
  style?: {[index: string]: {[index: string]: number | string}};
}; // tslint:disable

export default class ApplePay extends React.Component<LogoProps> {
  getWidth() {
    if (this.props.style && this.props.style['size']['width']) {
      return this.props.style['size']['width'];
    } else {
      return 53;
    }
  }

  getHeight() {
    if (this.props.style && this.props.style['size']['height']) {
      return this.props.style['size']['height'];
    } else {
      return 34;
    }
  }

  render() {
    return (
      <>
        <Svg
          width={this.getWidth()}
          height={this.getHeight()}
          viewBox="0 0 53 34"
          fill="none"
        >
          <Path transform="translate(1 1)" fill="#fff" d="M0 0H51V32H0z" />
          <Rect
            x={0.5}
            y={0.5}
            width={52}
            height={33}
            rx={4.5}
            stroke="#C2C2C5"
          />
          <G clipPath="url(#clip0_5744_6278)">
            <Path
              d="M13.94 11.287c-.444.522-1.157.932-1.87.873-.088-.703.262-1.453.671-1.91.446-.533 1.224-.914 1.859-.943.071.726-.22 1.447-.66 1.98zm.648 1.008c-1.033-.059-1.918.58-2.405.58-.498 0-1.246-.55-2.066-.533a3.058 3.058 0 00-2.589 1.553c-1.116 1.892-.29 4.687.79 6.228.529.762 1.158 1.6 1.99 1.57.789-.029 1.098-.504 2.048-.504.956 0 1.235.504 2.066.493.86-.018 1.401-.762 1.93-1.524.6-.867.849-1.705.86-1.752-.017-.017-1.662-.638-1.68-2.513-.018-1.57 1.3-2.315 1.36-2.362-.742-1.09-1.9-1.207-2.304-1.236zm5.961-2.121v11.42h1.8V17.69h2.487c2.274 0 3.866-1.54 3.866-3.767s-1.568-3.75-3.806-3.75h-4.347zm1.8 1.494h2.072c1.561 0 2.452.82 2.452 2.262 0 1.441-.89 2.273-2.458 2.273h-2.067v-4.535zm9.63 10.014c1.128 0 2.173-.563 2.648-1.46h.036v1.372h1.662V15.91c0-1.646-1.336-2.713-3.39-2.713-1.906 0-3.32 1.078-3.373 2.555h1.621c.137-.703.796-1.166 1.699-1.166 1.098 0 1.715.504 1.715 1.435v.633l-2.244.135c-2.084.123-3.212.967-3.212 2.432.006 1.476 1.17 2.46 2.838 2.46zm.487-1.354c-.956 0-1.568-.457-1.568-1.148 0-.721.588-1.137 1.71-1.201l1.995-.123v.644c0 1.066-.92 1.828-2.137 1.828zm6.086 4.371c1.751 0 2.577-.662 3.295-2.66L45 13.31h-1.829l-2.114 6.744h-.035l-2.114-6.744h-1.876l3.04 8.314-.166.504c-.273.855-.719 1.19-1.514 1.19-.143 0-.416-.018-.529-.03v1.371c.107.024.552.041.689.041z"
              fill="#000"
            />
          </G>
          <Defs>
            <ClipPath id="clip0_5744_6278">
              <Path fill="#fff" transform="translate(7 2)" d="M0 0H38V30H0z" />
            </ClipPath>
          </Defs>
        </Svg>
      </>
    );
  }
}
