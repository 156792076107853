import * as React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import type {MachineType} from 'src/types/MachineType';
import ProductTagSearchScreen from '../../elements/planogram/ProductTagSearch';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import BackSubheader from '../../elements/BackSubheader';
import {StyleSheet, View} from 'react-native';
import PlanogramStore from 'src/stores/PlanogramStore';
import PlanogramAction from 'src/actions/PlanogramActions';
import ProductSearchScreen from '../../elements/planogram/ProductSearch';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type MainProductScreenProps = {
  deviceId: string;
  machine: MachineType;
  onConnect: () => void;
};
type MainProductScreenState = {
  showSearch: boolean;
  loading: boolean;
  title: string;
  isSearching: boolean;
};

class MainProductScreen extends React.Component<
  MainProductScreenProps,
  MainProductScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: MainProductScreenProps) {
    super(props);
    this.state = {
      showSearch: PlanogramStore.showSearch(this.props.deviceId),
      loading: true,
      title: props.machine.name || '',
      isSearching: false,
    };
    this.tagSelected = this.tagSelected.bind(this);
    this.onCloseProductSearchSelected =
      this.onCloseProductSearchSelected.bind(this);
    this.handleSearchFocusChange = this.handleSearchFocusChange.bind(this);
  }

  handleSearchFocusChange(isFocused: boolean) {
    this.setState({isSearching: isFocused});
  }

  componentDidMount() {
    this.requestPlanogram();
  }

  async requestPlanogram() {
    FirebaseAnalytic.trackEvent('requestPlanogram', 'MainProductScreen', {
      ...this.props,
      ...this.state,
    });

    try {
      this.context.actions.showSpinner();
      const planogram = await PlanogramAction.loadPlanogram(
        this.props.deviceId,
      );
      this.setState(
        {
          loading: false,
          showSearch: planogram.ShowCategorySearchView,
        },
        () => {
          FirebaseAnalytic.trackEvent(
            'requestPlanogram loadPlanogram',
            'MainProductScreen',
            {
              ...this.props,
              ...this.state,
              planogram,
            },
          );
        },
      );
    } catch (err) {
      CrashlyticsEvents.log(
        'Exception',
        'MainProductScreen:RequestPlanogram',
        err.message ? err.message : err.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MainProductScreen:RequestPlanogram',
        err.message ? err.message : err.toString(),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  tagSelected(tag: string) {
    FirebaseAnalytic.trackEvent('tagSelected', 'MainProductScreen', {
      ...this.props,
      ...this.state,
      tag,
    });

    if (tag !== Localized.Labels.all_products) {
      PlanogramAction.tagSelected(tag);
    } else {
      PlanogramAction.tagSelected('');
    }

    this.setState({
      title: tag,
      showSearch: false,
    });
  }

  onCloseProductSearchSelected() {
    FirebaseAnalytic.trackEvent(
      'onCloseProductSearchSelected',
      'MainProductScreen',
      {
        ...this.props,
        ...this.state,
      },
    );

    if (
      this.state.showSearch !== PlanogramStore.showSearch(this.props.deviceId)
    ) {
      this.setState({
        showSearch: true,
        title: this.props.machine.name || '',
      });
    } else {
      NavActions.pop();
    }
  }

  render() {
    let content = (
      <ProductSearchScreen
        tagSelected={PlanogramStore.showSearch(this.props.deviceId)}
        deviceId={this.props.deviceId}
        strings={Localized}
        {...this.context.actions}
        onConnect={this.props.onConnect}
        deviceName={this.state.title}
        onSearchFocusChange={this.handleSearchFocusChange}
      />
    );

    if (this.state.loading) {
      content = null;
    } else if (this.state.showSearch) {
      content = (
        <ProductTagSearchScreen
          tagSelected={this.tagSelected}
          deviceId={this.props.deviceId}
          strings={Localized}
          onConnect={this.props.onConnect}
        />
      );
    }

    return (
      <BackSubheader
        title={this.state.title}
        onBackSelect={this.onCloseProductSearchSelected}
        pop={false}
      >
        <View style={[styles.content]}>{content}</View>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
});
export default withForwardedNavigationParams<MainProductScreenProps>()(
  MainProductScreen,
);
