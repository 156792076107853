import React from 'react';
import {View} from 'react-native';
import AVText from '../../AVText';
import AVFormattedCurrency from '../../AVFormattedCurrency';
import {formatCurrency} from 'src/components/helpers/HistoryHelper';
import Styles from '../../../Styles';
import Localized from 'src/constants/AppStrings';
import {Payment} from 'src/types/TransactionDetail';

import {getDescriber} from '../descriptor/DescriptorType';
type DiscountInfoComponentProps = {
  payments: Array<Payment>;
  currency?: string;
};

const DiscountInfoComponent: React.FC<DiscountInfoComponentProps> = ({
  payments,
  currency,
}) => {
  if (!payments) return null;
  const DISCOUNT_PAYMENT_TYPES: Array<string> = ['CARTDISC', 'PROMOTION'];

  const discountPayments = payments.filter((item) =>
    DISCOUNT_PAYMENT_TYPES.includes(item.Type),
  );
  const totalDiscount = discountPayments.reduce(
    (acc, curr) => acc + curr.Amount,
    0,
  );

  if (totalDiscount === 0) return null;

  return (
    <View style={Styles.Style.revolveRow}>
      <AVText
        style={[
          getDescriber().PurchaseDetailDiscountStyleDescriptor(),
          {marginRight: Styles.Spacing.m3},
        ]}
        accessible={true}
        accessibilityLabel={`${Localized.Labels.discounts} label`}
        accessibilityRole="text"
        aria-label={`${Localized.Labels.discounts} label, text`}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
      >
        {Localized.Labels.discounts}
      </AVText>

      <AVFormattedCurrency
        {...formatCurrency(
          totalDiscount * -1,
          Localized.Labels.discounts,
          currency,
        )}
        style={getDescriber().PurchaseDetailDiscountStyleDescriptor()}
      />
    </View>
  );
};

export default DiscountInfoComponent;
