import * as React from 'react';
import Svg, {Circle, Path} from 'react-native-svg';
type UnreadReferralRewardProps = {
  height?: number;
  width?: number;
  style?: {[index: string]: {[index: string]: number | string}};
  color?: string;
};
export default class UnreadReferral extends React.Component<UnreadReferralRewardProps> {
  render() {
    return (
      <Svg
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 54 55"
        fill="none"
      >
        <Path
          d="M0 29.5c0-13.807 11.193-25 25-25s25 11.193 25 25-11.193 25-25 25-25-11.193-25-25z"
          fill="#066DFF"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.108 33.26a3.01 3.01 0 00-2.872 3.913l-.82.258A3.87 3.87 0 0134.175 33l.512-.513.343.344v1.548h-1.547l-.344-.344.407-.408a2.995 2.995 0 00-1.44-.365v-.001zm3.01 3.01a3 3 0 00-.138-.903l.82-.258a3.87 3.87 0 01-5.76 4.432l-.512.513-.344-.344v-1.548h1.548l.344.344-.408.408a3.01 3.01 0 004.45-2.644zm-3.526-1.548h.172v-.516h.688v.516h.172c.38 0 .688.308.688.688v.172h-.688v-.172h-1.031v.32l1.166.233a.688.688 0 01.553.675v.492c0 .38-.308.688-.688.688h-.172v.516h-.688v-.516h-.172a.688.688 0 01-.688-.688v-.172h.688v.172h1.032v-.492l-1.167-.233a.688.688 0 01-.553-.675v-.32c0-.38.308-.688.688-.688z"
          fill="#FEE0E6"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.309 19.174a5.226 5.226 0 100 10.452 5.226 5.226 0 000-10.452zm-.63 11.486c-3.647 0-7.076.929-10.054 2.56l-.575.312v5.105h13.442l-.183-1.648c-.122-1.097.093-2.215.661-3.161a5.055 5.055 0 011.655-1.685l.49-.306c-.948-.388-3.447-1.179-5.435-1.177z"
          fill="#FEE0E6"
        />
        <Circle cx={44} cy={10.5} r={10} fill="#E2607F" />
      </Svg>
    );
  }
}
