import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  color?: string;
  size?: number;
}
const StorageIcon = ({size = 32, color = '#22212D'}: IProps) => (
  <Svg width={size} fill="none" height={size}>
    <Path
      fill={color}
      d="M3.915 11.526a.32.32 0 0 1-.323.318H3.53a.32.32 0 0 1-.322-.318v-2.27a.32.32 0 0 1 .322-.317h.062a.32.32 0 0 1 .323.317v2.27ZM3.499 7.88a.826.826 0 0 0-.832.819v2.806c0 .453.372.82.832.82h7.628c.46 0 .833-.367.833-.82V8.698a.826.826 0 0 0-.833-.82H3.5ZM3.915 5.864a.32.32 0 0 1-.323.318H3.53a.32.32 0 0 1-.322-.318v-2.27a.32.32 0 0 1 .322-.318h.062a.32.32 0 0 1 .323.318v2.27Zm-.416-3.642a.826.826 0 0 0-.832.82v2.806c0 .452.372.819.832.819h7.628c.46 0 .833-.367.833-.82V3.042a.826.826 0 0 0-.833-.819H3.5ZM3.915 16.784a.32.32 0 0 1-.323.318H3.53a.32.32 0 0 1-.322-.318v-2.27a.32.32 0 0 1 .322-.317h.062a.32.32 0 0 1 .323.318v2.27Zm-.416-3.653a.826.826 0 0 0-.832.82v2.806c0 .452.372.819.832.819h7.628c.46 0 .833-.367.833-.82V13.95a.826.826 0 0 0-.833-.819H3.5ZM14.476 16.784a.32.32 0 0 1-.322.318h-.062a.32.32 0 0 1-.323-.318v-2.27a.32.32 0 0 1 .323-.317h.062a.32.32 0 0 1 .322.318v2.27Zm-.472-3.653a.826.826 0 0 0-.832.82v2.806c0 .452.372.819.832.819h7.629c.46 0 .832-.367.832-.82V13.95a.826.826 0 0 0-.832-.819h-7.629ZM2.667 19.647c0-.461.371-.835.83-.835h7.606c.459 0 .83.374.83.835v8.407a.832.832 0 0 1-.83.835H3.497a.832.832 0 0 1-.83-.835v-8.407Zm.922 8.687a.323.323 0 0 0 .322-.324v-2.314a.323.323 0 0 0-.322-.324h-.061a.323.323 0 0 0-.322.324v2.314c0 .18.144.324.322.324h.061ZM14.476 22.365a.32.32 0 0 1-.322.318h-.062a.32.32 0 0 1-.323-.318v-2.27a.32.32 0 0 1 .323-.318h.062a.32.32 0 0 1 .322.318v2.27Zm-.472-3.577a.826.826 0 0 0-.832.819v2.806c0 .453.372.82.832.82h7.629c.46 0 .832-.367.832-.82v-2.806a.826.826 0 0 0-.832-.82h-7.629ZM13.198 25.278c0-.45.372-.816.83-.816h7.607c.458 0 .83.366.83.816v2.795c0 .45-.372.816-.83.816h-7.607a.823.823 0 0 1-.83-.816v-2.795Zm.98 3.069a.32.32 0 0 0 .321-.316V25.77a.32.32 0 0 0-.322-.316h-.061a.32.32 0 0 0-.322.316v2.26a.32.32 0 0 0 .322.317h.061ZM26.91 7.273c0-.778-.546-1.414-1.213-1.414s-1.212.636-1.212 1.414c0 .778.545 1.414 1.212 1.414.667 0 1.212-.636 1.212-1.414Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <Path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.652 11.456a.083.083 0 0 1-.082.084h-7.572a.083.083 0 0 1-.083-.084v-8.4c0-.046.037-.083.083-.083h7.572c.045 0 .082.037.082.083v5.607c-.3-.358-.813-.911-1.552-2.034-.284-.433-.745-.681-1.174-.394-.428.287-.378.727-.094 1.16.74 1.125 1.566 2.405 1.701 2.65.163.371.47.55 1.12.717v.694Zm5.926 3.438.645-4.491a.567.567 0 0 0 .003-.157c-.065-.479-.605-.856-1.256-.856h-2.506a.934.934 0 0 0-.141-.033c-.604-.095-1.376-.196-1.927-.29v-6.01a.83.83 0 0 0-.826-.835h-7.572a.83.83 0 0 0-.826.834v8.4c0 .46.37.834.826.834h7.572a.83.83 0 0 0 .826-.834v-.531l.24.046c.22.042.439.082.635.116l.399 3.712-.096 12.899a1.12 1.12 0 0 0 1.207 1.13c.57-.047.626-1.044.63-1.631l.515-7.322 1.51 7.479c.08.581.265 1.562.835 1.534a1.124 1.124 0 0 0 1.052-1.28l-1.745-12.714Z"
    />
  </Svg>
);
export default StorageIcon;
