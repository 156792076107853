import {StyleSheet, View, PixelRatio, Platform} from 'react-native';
import Styles from 'src/components/Styles';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Localized from 'src/constants/AppStrings';
import Person from 'src/components/img/svg/Person';
import Reward from 'src/components/img/svg/Reward';
import FontAwesome5Pro from 'react-native-vector-icons/FontAwesome5Pro';
import AVFormattedCurrency from 'src/components/elements/AVFormattedCurrency';
import {Key} from 'react';
import AVText from 'src/components/elements/AVText';
import SnackGiftIcon from 'src/components/img/svg/SnackGiftIcon';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';

export function getBalanceStripeIcon(): JSX.Element {
  return <Person size={Styles.Heights.h3} />;
}

export function getBalanceRollOverIcon(): JSX.Element {
  return <Reward size={Styles.Heights.h3} />;
}

export function balanceItemRoundedBtnColorDescriptor(): string {
  return '';
}

export function balanceAmountStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {};
}

export function balanceTypeStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {};
}

export function getBalanceItemStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      flex: 1,
      borderBottomColor: Styles.lightGray,
      borderBottomWidth: StyleSheet.hairlineWidth,
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingHorizontal: Styles.Spacing.m3,
      paddingVertical: Styles.Spacing.m2,
    },
    button: {
      right: Styles.Spacing.m3,
      position: 'absolute',
      top: Styles.Spacing.m2,
    },
    fundButtonText: {
      paddingHorizontal: Styles.Spacing.m2,
    },
    balanceContainer: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      paddingHorizontal: Styles.Spacing.m1,
    },
  };
}

export function SaleDetailModMaxFontSizeMultipleDescriptor(): number {
  return Styles.FontSizeMultiplier.maxfm8;
}

export function getFundButtonForBalanceScreen(fundBtnCallback): JSX.Element {
  return (
    <RoundedButton
      buttonType={ButtonType.outline}
      containerStyle={getBalanceItemStyleDescriptor()['button']}
      textStyle={getBalanceItemStyleDescriptor()['fundButtonText']}
      accessible={true}
      accessibilityRole="button"
      accessibilityLabel={Localized.Labels.fund_account}
      aria-label={Localized.Labels.fund_account}
      role="button"
      onPress={fundBtnCallback}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
      text={Localized.Labels.fund}
      color={balanceItemRoundedBtnColorDescriptor()}
    />
  );
}

export function SaleDetailRowStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    alignItems: 'center',
    backgroundColor: Styles.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: Styles.Spacing.m0,
    paddingHorizontal: Styles.Spacing.m2,
    width: '90%',
  };
}

export function SaleDetailSupLblMaxFontSizeMultipleDescriptor(): number {
  return Styles.FontSizeMultiplier.maxfm10;
}

export function SaleDetailNameStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    flex: 1,
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m2,
    color: Styles.darkColor,
    flexWrap: 'wrap',
  };
}

export function SaleDetailAmtStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
    alignItems: 'flex-end',
    right: -32,
  };
}

export function SaleDetailModNameStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    flex: 1,
    marginRight: Styles.Spacing.m2,
    marginVertical: 10,
    maxWidth: '70%',
    fontSize: Styles.Fonts.f1,
    flexDirection: 'row',
    color: Styles.lightGray,
  };
}

export function PurchaseDetailFormateedDateStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f8,
    fontFamily: Styles.FontFamily.robotoRegular,
    color: Styles.darkColor,
    fontWeight: '400',
  };
}

export function getDashColor(): {
  [index: string]: {[index: string]: string};
} {
  return {
    dashStyle: {
      dashColor: '#999',
    },
  };
}

export function getDashedStyleDescriptor(): {
  [index: string]: {[index: string]: number};
} {
  return {
    dashStyle: {
      dashLength: PixelRatio.roundToNearestPixel(2),
      dashThickness: PixelRatio.roundToNearestPixel(2),
    },
  };
}

export function getSaleAmountTextUI(
  modPrice: number,
  saleCurrency: string,
  modifierStyle: unknown,
  modId: Key,
) {
  return (
    <AVText key={modId} style={modifierStyle}>
      +
      <AVFormattedCurrency
        style={modifierStyle}
        value={modPrice}
        accessibilityLabel={`+$${modPrice}`}
        aria-label={`+$${modPrice}`}
        currency={saleCurrency}
      />
    </AVText>
  );
}

export function fundingdetailTrans(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  };
}

export function getStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    orderStatus: {
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      color: Styles.darkColor,
    },
    remainingBalance: {},
    orderStatusValue: {
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      color: Styles.darkColor,
    },
    pickupTime: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f0,
    },
    instructions: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f0,
      fontWeight: '600',
    },
    location: {
      marginTop: Styles.Spacing.m3,
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
    },
    pickupDate: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f0,
      marginTop: Styles.Spacing.m2,
    },
    points: {
      color: Styles.primaryColor,
      fontSize: Styles.Fonts.f1,
    },
    dashStyleBorder: {
      borderRadius: 100,
      height: PixelRatio.roundToNearestPixel(2),
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      overflow: 'hidden',
      width: '100%',
    },
    totalLabel: {
      marginBottom: Styles.Spacing.m1,
      marginTop: -1 * Styles.Spacing.m1,
      color: Styles.darkColor,
      fontSize: 24,
      fontWeight: '700',
    },
    modifierAmount: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f1,
    },
    sectionMarginLeft: {
      marginLeft: -4,
    },
    summaryTopMargin: {
      marginTop: null,
    },
    sectionMarginLeftWithZero: {
      marginLeft: 0,
    },
    sectionMarginRightWithZero: {
      marginRight: null,
    },
    summaryExtra: {
      marginBottom: Styles.Spacing.m1,
    },
    shareIconColor: {
      color: Styles.white,
    },
    buttonSpacing: {
      marginStart: Styles.Spacing.m2,
      marginBottom: Styles.Spacing.m1,
    },
    rowReverse: {
      flexDirection: 'row-reverse',
      flexWrap: 'wrap',
    },
  };
}
export function fundingDetailTotal(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.black,
    fontSize: Styles.Fonts.f3,
    fontWeight: '300',
    marginBottom: Styles.Spacing.m1,
    marginTop: -1 * Styles.Spacing.m1,
  };
}
export function fundingdetailAccountFndgTxt() {
  return {
    fontSize: Styles.Fonts.f1,
  };
}
export function paystatus() {
  return null;
}
export function paydescription() {
  return null;
}

export function getActionBtns(
  handleDone: () => void,
  shareReceipt: () => void,
) {
  return (
    <View
      style={[
        Styles.Style.row,
        getStyleDescriptor()['rowReverse'],
        {bottom: 22, position: 'absolute', right: Styles.Spacing.m2},
      ]}
    >
      <RoundedButton
        buttonType={ButtonType.normal}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        accessible={true}
        accessibilityLabel={Localized.Buttons.done}
        accessibilityRole="button"
        aria-label={Localized.Buttons.done}
        role="button"
        onPress={handleDone}
        containerStyle={[
          Styles.Style.actionButton,
          getStyleDescriptor()['buttonSpacing'],
        ]}
        textStyle={Styles.Style.actionButtonText}
        text={Localized.Buttons.done}
      />
      <RoundedButton
        testID="openSystemShareButton"
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        buttonType={ButtonType.normal}
        accessible={true}
        accessibilityLabel={Localized.Labels.share}
        accessibilityRole="button"
        aria-label={Localized.Labels.share}
        role="button"
        onPress={shareReceipt}
        containerStyle={[
          Styles.Style.actionButton,
          getStyleDescriptor()['buttonSpacing'],
        ]}
        textStyle={Styles.Style.actionButtonText}
        text={Localized.Labels.share}
        icon={
          <FontAwesome5Pro
            name={'share-alt'}
            color={Styles.white}
            size={Styles.Fonts.f1}
            style={{
              marginEnd: Styles.Spacing.m2,
            }}
            solid
          />
        }
      />
    </View>
  );
}

export function PurchaseDetailDateFormatStyleDescriptor(): string {
  return 'MMMM D, YYYY [at] h:mm A';
}
export function payPrefixTxt() {
  return '';
}

export function payLocationAddr() {
  return null;
}

export function paySnackContainer() {
  return null;
}

export function paySnackRow() {
  return null;
}

export function giftIconSnack() {
  return <SnackGiftIcon />;
}

export function payBalanceType(styles) {
  return styles.paybalanceTypeDisplay;
}
export function payDteDisplay(styles) {
  return styles.payDteDisplay;
}

export function PurchaseDetailIdStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginTop: Styles.Spacing.m1,
    marginBottom: Styles.Spacing.m2,
    marginRight: Styles.Spacing.m3,
    fontFamily: Styles.FontFamily.robotoRegular,
  };
}
export function fundingdetailDate(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m3,
  };
}

export function PurchaseDetailSubtotalStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.lightGray,
    fontFamily: Styles.FontFamily.robotoRegular,
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m3,
  };
}

export function PurchaseDetailDepositStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginBottom: Styles.Spacing.m1,
    fontFamily: Styles.FontFamily.robotoRegular,
  };
}

export function PurchaseDetailtaxStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    fontFamily: Styles.FontFamily.robotoRegular,
    marginRight: Styles.Spacing.m3,
  };
}

export function PurchaseDetailDiscountStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.robotoRegular,
    color: Styles.positiveColor,
    fontSize: Styles.Fonts.f1,
  };
}
export function fundingDetailAccount() {
  return null;
}
export function payOrderNumber() {
  return null;
}
export function PurchaseHistorySubtotalStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.lightGray,
    marginBottom: Styles.Spacing.m1,
    fontSize: Styles.Fonts.f1,
  };
}
export function rightViewStyle() {
  return null;
}
export function payDashedLine() {
  return '#999';
}

export function leftViewStyle() {
  return null;
}
export function fundingHeaderTitle() {
  return Localized.Labels.funding_summary;
}
export function fundingDetailContainer() {
  return Styles.Style.summarySection;
}

export function historyEmailReceiptBtn(
  handleEmailReceipt,
  styles,
  detailID?: string,
  isUSACheck?: string,
  isHistory?: boolean,
) {
  return (
    isHistory && (
      <>
        <RoundedAppButton
          buttonType={RoundedAppButtonType.Solid}
          titleText={Localized.Buttons.email_receipts}
          onPress={() => {
            handleEmailReceipt(detailID || null);
          }}
          buttonContainerStyle={{
            alignSelf: 'flex-end',
            right: Styles.Spacing.m3,
            bottom: 22,
            position: 'absolute',
          }}
          buttonViewStyle={{
            paddingHorizontal: 24,
            paddingVertical: 12,
            minHeight: 42,
          }}
          textStyle={{
            fontWeight: Platform.OS === 'android' ? 'bold' : '700',
            fontFamily: Styles.FontFamily.robotoRegular,
            fontSize: Styles.Fonts.f1,
            color: Styles.white,
          }}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          aria-label={Localized.Buttons.email_receipts}
        />
      </>
    )
  );
}
export function payOrderStatus() {
  return null;
}

export function getEUDigitalRecipts() {
  return null;
}

export default {
  getStyleDescriptor,
  getDashedStyleDescriptor,
  getDashColor,
  getActionBtns,
  paySnackContainer,
  getFundButtonForBalanceScreen,
  payOrderStatus,
  fundingdetailDate,
  getSaleAmountTextUI,
  getBalanceStripeIcon,
  fundingHeaderTitle,
  getBalanceRollOverIcon,
  payLocationAddr,
  giftIconSnack,
  fundingDetailContainer,
  balanceItemRoundedBtnColorDescriptor,
  balanceAmountStyleDescriptor,
  fundingDetailTotal,
  balanceTypeStyleDescriptor,
  getBalanceItemStyleDescriptor,
  leftViewStyle,
  paydescription,
  SaleDetailModMaxFontSizeMultipleDescriptor,
  SaleDetailRowStyleDescriptor,
  SaleDetailSupLblMaxFontSizeMultipleDescriptor,
  SaleDetailNameStyleDescriptor,
  SaleDetailAmtStyleDescriptor,
  payDashedLine,
  SaleDetailModNameStyleDescriptor,
  rightViewStyle,
  fundingDetailAccount,
  payPrefixTxt,
  payOrderNumber,
  PurchaseDetailFormateedDateStyleDescriptor,
  PurchaseDetailDateFormatStyleDescriptor,
  payBalanceType,
  payDteDisplay,
  PurchaseDetailIdStyleDescriptor,
  PurchaseDetailSubtotalStyleDescriptor,
  paystatus,
  PurchaseDetailDepositStyleDescriptor,
  PurchaseDetailtaxStyleDescriptor,
  fundingdetailTrans,
  PurchaseDetailDiscountStyleDescriptor,
  fundingdetailAccountFndgTxt,
  paySnackRow,
  PurchaseHistorySubtotalStyleDescriptor,
  historyEmailReceiptBtn,
  getEUDigitalRecipts,
};
