import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

export default class CoolDrink extends React.Component {
  render() {
    return (
      <Svg width={38} height={38} viewBox="0 0 38 38" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.965 9.583H37.4L33.017 37.46H19.852a7.079 7.079 0 001.218-1.734h2.633v-9.968h-2.79a10.282 10.282 0 00-6.091-5.654L13.17 9.583h10.414V4.146l1.061-.433 6.112-2.443L32.33.64l1.257 3.152-1.572.63-5.05 2.01v3.152zM21.168 31.53H.084v-3.388h2.024a7.826 7.826 0 017.644-6.166H11.5a7.826 7.826 0 017.644 6.166h2.023v3.388zm-19.06 2.542h17.017a4.307 4.307 0 01-4.107 2.994H6.215a4.332 4.332 0 01-4.107-2.994z"
          fill="#066DFF"
        />
      </Svg>
    );
  }
}
