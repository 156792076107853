import * as React from 'react';
import Svg, {Path, G} from 'react-native-svg';
interface IProps {
  width?: number;
  height?: number;
  color?: string;

  style?: Record<string, unknown>;
}
function ChevronUp(props: IProps) {
  const {width = 24, height = 24, style = null} = props;
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      style={style}
    >
      <G id="Chevron Icon Variants">
        <Path
          id="Vector"
          d="M7.46895 3.46885C7.7627 3.1751 8.2377 3.1751 8.52832 3.46885L14.5314 9.46885C14.8252 9.7626 14.8252 10.2376 14.5314 10.5282C14.2377 10.8188 13.7627 10.822 13.4721 10.5282L8.00332 5.05947L2.53145 10.5313C2.2377 10.8251 1.7627 10.8251 1.47207 10.5313C1.18145 10.2376 1.17832 9.7626 1.47207 9.47197L7.46895 3.46885Z"
          fill="#066DFF"
        />
      </G>
    </Svg>
  );
}

export default ChevronUp;
