import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import StackTransitionPreset from '../StackTransitionPreset';
import Settings from '../../Settings';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import LocationCodeScreen from 'src/components/screens/LocationCodeScreen';
import ChangeEmailScreen from 'src/components/screens/account/ChangeEmailScreen';
import ChangePasswordScreen from 'src/components/screens/account/ChangePasswordScreen';
import HelpScreen from 'src/components/screens/account/HelpScreen';
import PurchaseHistoryScreen from 'src/components/screens/account/history/PurchaseHistoryScreen';
import RefundSummaryScreen from 'src/components/screens/account/history/RefundSummaryScreen';
import ManageAccountScreen from 'src/components/screens/account/ManageAccountScreen';
import ResetPinScreen from 'src/components/screens/account/ResetPinScreen';
import ConnectScreen from 'src/components/screens/ConnectScreen';
import CreditCardDetailScreen from 'src/components/screens/funding/CreditCardDetailScreen';
import CreditCardScreen from 'src/components/screens/funding/CreditCardScreen';
import CreditCardStripeScreen from 'src/components/screens/funding/CreditCardStripeScreen';
import CardsScreen from 'src/components/screens/funding/CreditCardsScreen';
import PayrollDeductScreen from 'src/components/screens/funding/PayrollDeductScreen';
import PayrollDeductAgreementScreen from 'src/components/screens/funding/PayrollDeductAgreementScreen';
import FeedbackLocationScanScreen from 'src/components/screens/productFeedback/FeedbackLocationScanScreen';
import ProductFeedbackCompletedScreen from 'src/components/screens/productFeedback/ProductFeedbackCompletedScreen';
import ProductFeedbackInstructionScreen from 'src/components/screens/productFeedback/ProductFeedbackInstructionScreen';
import ProductFeedbackScreen from 'src/components/screens/productFeedback/ProductFeedbackScreen';
import EatItOrDeleteItWelcomeScreen from 'src/components/screens/productFeedback/ProductFeedbackWelcomeScreen';
import WebContentScreen from 'src/components/screens/WebContentScreen';
import FeedbackScreen from 'src/components/screens/account/FeedbackScreen';
import EditMarketCardScreen from 'src/components/screens/account/EditMarketCardScreen';
import ReferralSummaryScreen from 'src/components/screens/account/history/ReferralSummaryScreen';
import ShareReceiptScreen from 'src/components/screens/cart/ShareReceiptScreen';
import SnackBlockedUsersScreen from 'src/components/screens/sendSnack/SnackBlockedUsersScreen';
import BalancesScreen from 'src/components/screens/account/BalancesScreen';
import AddEGiftCard from 'src/components/screens/funding/AddEGiftCard';
import AddEGiftCardManual from 'src/components/screens/funding/AddEGiftCardManual';
import DeleteAccountScreen from 'src/components/screens/account/DeleteAccountScreen';
import AccountScreenNewUI from 'src/components/screens/account/newui/AccountScreenNewUI';
import AccountScreen from 'src/components/screens/account/AccountScreen';
import InboxScreenNewUI from 'src/components/screens/inbox/newui/InboxScreenNewUI';
import HistoryScreen from 'src/components/screens/account/history/HistoryScreen';
import HistoryDetailsScreen from 'src/components/screens/account/history/HistoryDetailsScreen';
import DealDetailScreen from 'src/components/screens/inbox/DealDetailScreen';
import SnackDetailScreen from 'src/components/screens/sendSnack/SnackDetailScreen';
import MessageDetailScreen from 'src/components/screens/inbox/MessageDetailScreen';
import SendSnackScreenNew from 'src/components/screens/sendSnack/SendSnackScreen';

import ChooseContactScreenNew from 'src/components/screens/sendSnack/ChooseContactScreen';
import PurchaseReceiptScreen from '../../components/screens/account/history/PurchaseHistoryDetailScreen';
import SendSnackDetailScreenNew from 'src/components/screens/account/history/SnackSummaryScreen';
import HomeScreenNew from 'src/components/screens/home/HomeScreen';
import ReferralScreen from 'src/components/screens/ReferralScreen';
import PurchaseHistoryDetailScreen from 'src/components/screens/account/history/PurchaseHistoryDetailScreen';
import ScanPay365ReceiptScreen from '../../components/screens/account/history/ScanPay365ReceiptScreen';
import {Platform} from 'react-native';
import ScanKioskQRScreen from 'src/components/screens/account/ScanKioskQrCodeScreenNew';
import {RewardsTabScreenWithHOC} from 'src/components/screens/RewardsHOCScreen';
const Stack = createStackNavigator();

export default function ManageAccountNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={AppRoutes.Account}
      screenOptions={{
        ...StackTransitionPreset,
        title: DeviceInfo.getApplicationName(),
        headerShown: false,
      }}
    >
      <Stack.Screen name={AppRoutes.Help} component={HelpScreen} />
      <Stack.Screen name={AppRoutes.Inbox} component={InboxScreenNewUI} />
      <Stack.Screen
        name={AppRoutes.Account}
        component={
          Settings.isNewUI() && Platform.OS !== 'web'
            ? AccountScreenNewUI
            : AccountScreen
        }
      />
      <Stack.Screen name={AppRoutes.WebContent} component={WebContentScreen} />
      <Stack.Screen name={AppRoutes.Home} component={HomeScreenNew} />
      <Stack.Screen name={AppRoutes.ResetPin} component={ResetPinScreen} />
      <Stack.Screen
        name={AppRoutes.ChangePassword}
        component={ChangePasswordScreen}
      />
      <Stack.Screen
        name={AppRoutes.LocationCode}
        component={LocationCodeScreen}
      />
      <Stack.Screen
        name={AppRoutes.ManageAccount}
        component={ManageAccountScreen}
      />
      <Stack.Screen
        name={AppRoutes.ChangeEmail}
        component={ChangeEmailScreen}
      />
      <Stack.Screen
        name={AppRoutes.PurchaseHistory}
        component={PurchaseHistoryScreen}
      />
      <Stack.Screen name={AppRoutes.PDCards} component={CardsScreen} />
      <Stack.Screen name={AppRoutes.AddEGiftCard} component={AddEGiftCard} />
      <Stack.Screen
        name={AppRoutes.AddEGiftCardManual}
        component={AddEGiftCardManual}
      />
      <Stack.Screen
        name={AppRoutes.RewardsScreen}
        component={RewardsTabScreenWithHOC}
      />
      <Stack.Screen
        name={AppRoutes.EatItOrDeleteItWelcome}
        component={EatItOrDeleteItWelcomeScreen}
      />
      <Stack.Screen
        name={AppRoutes.ProductFeedbackInstruction}
        component={ProductFeedbackInstructionScreen}
      />
      <Stack.Screen
        name={AppRoutes.ProductFeedback}
        component={ProductFeedbackScreen}
      />
      <Stack.Screen
        name={AppRoutes.FeedbackLocationScan}
        component={FeedbackLocationScanScreen}
      />
      <Stack.Screen
        name={AppRoutes.ProductFeedbackComplete}
        component={ProductFeedbackCompletedScreen}
      />
      <Stack.Screen name={AppRoutes.Connect} component={ConnectScreen} />
      <Stack.Screen
        name={AppRoutes.PayrollDeduct}
        component={PayrollDeductScreen}
      />
      <Stack.Screen
        name={AppRoutes.PayrollDeductAgreement}
        component={PayrollDeductAgreementScreen}
      />
      <Stack.Screen name={AppRoutes.CreditCard} component={CreditCardScreen} />
      <Stack.Screen
        name={AppRoutes.CreditCardStripe}
        component={CreditCardStripeScreen}
      />
      <Stack.Screen
        name={AppRoutes.CreditCardDetail}
        component={CreditCardDetailScreen}
      />
      <Stack.Screen
        name={AppRoutes.RefundSummary}
        component={RefundSummaryScreen}
      />
      <Stack.Screen
        name={AppRoutes.PurchaseHistoryDetail}
        component={PurchaseReceiptScreen}
      />
      <Stack.Screen
        name={AppRoutes.ShareReceipt}
        component={ShareReceiptScreen}
      />
      <Stack.Screen name={AppRoutes.Feedback} component={FeedbackScreen} />
      <Stack.Screen
        name={AppRoutes.EditMarketCard}
        component={EditMarketCardScreen}
      />
      <Stack.Screen
        name={AppRoutes.ScanKioskQrCode}
        component={ScanKioskQRScreen}
      />
      <Stack.Screen name={AppRoutes.DealDetail} component={DealDetailScreen} />
      <Stack.Screen
        name={AppRoutes.ReferralSummary}
        component={ReferralSummaryScreen}
      />
      <Stack.Screen
        name={AppRoutes.SnackBlockedUsers}
        component={SnackBlockedUsersScreen}
      />
      <Stack.Screen
        name={AppRoutes.SnackDetail}
        component={SnackDetailScreen}
      />
      <Stack.Screen name={AppRoutes.Balances} component={BalancesScreen} />
      <Stack.Screen
        name={AppRoutes.DeleteAccount}
        component={DeleteAccountScreen}
      />
      <Stack.Screen name={AppRoutes.History} component={HistoryScreen} />
      <Stack.Screen
        name={AppRoutes.HistoryDetailsScreen}
        component={HistoryDetailsScreen}
      />
      <Stack.Screen
        name={AppRoutes.MessageDetail}
        component={MessageDetailScreen}
      />

      <Stack.Screen name={AppRoutes.SendSnack} component={SendSnackScreenNew} />
      <Stack.Screen
        name={AppRoutes.SnackHistoryDetail}
        component={SendSnackDetailScreenNew}
      />
      <Stack.Screen name={AppRoutes.Referral} component={ReferralScreen} />
      <Stack.Screen
        component={PurchaseHistoryDetailScreen}
        name={AppRoutes.PurchaseHistoryDetailNew}
      />
      <Stack.Screen
        name={AppRoutes.ChooseContact}
        component={ChooseContactScreenNew}
      />

      {Settings.isRefiveAnd365Pay() && (
        <Stack.Screen
          component={ScanPay365ReceiptScreen}
          name={AppRoutes.ScanPay365ReceiptScreen}
        />
      )}
    </Stack.Navigator>
  );
}
