import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import Localized from 'src/constants/AppStrings';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import CrashlyticsEvents from 'src/logging/Crashlytics';

const handleTerms = () => {
  try {
    const url = Settings.getTermsUrl(
      Settings.getLocale(),
      AccountStore.getPrivacyTermsType(),
    );
    const backupUrl = Settings.getTermsUrl(
      Settings.getDefaultLocale(),
      AccountStore.getPrivacyTermsType(),
    );
    NavActions.push(AppRoutes.WebContent, {
      title: Localized.Labels.terms,
      url,
      backupUrl,
      strings: Localized,
    });
  } catch (error) {
    CrashlyticsEvents.log(
      'Exception',
      'AccountScreen:handleTerms',
      generateErrorMessage(error),
    );
    Events.Error.trackEvent(
      'Exception',
      'AccountScreen:handleTerms',
      generateErrorMessage(error),
    );
  }
};

const handleChangePin = () => {
  NavActions.push(AppRoutes.ResetPin);
};

const handleChangePassword = () => {
  NavActions.push(AppRoutes.ChangePassword);
};

const handleChangeEmail = () => {
  NavActions.push(AppRoutes.ChangeEmail);
};

const toManageAccount = () => {
  NavActions.push(AppRoutes.ManageAccount);
};

const contactClicked = async () => {
  NavActions.push(AppRoutes.Help, {
    strings: Localized,
  });
};

const blockedUsersClicked = () => {
  NavActions.push(AppRoutes.SnackBlockedUsers);
};

const sendasnackClicked = () => {
  NavActions.push(AppRoutes.SendSnack);
};
const handleMarketCard = () => {
  NavActions.push(AppRoutes.EditMarketCard);
};
const scanKioskQrCode = () => {
  NavActions.push(AppRoutes.ScanKioskQrCode);
};

const handleDeleteAccount = () => {
  NavActions.push(AppRoutes.DeleteAccount);
};

const toBalances = () => {
  NavActions.push(AppRoutes.Balances);
};

const handleLinkLocation = () => {
  NavActions.push(AppRoutes.LocationCode);
};

const toWalletScreen = () => {
  if (Settings.isRefiveAnd365Pay()) {
    NavActions.push(AppRoutes.PDCards, {hideBackArrow: false});
  } else {
    NavActions.push(AppRoutes.Cards, {hideBackArrow: false});
  }
};

const toPurchaseHistoryScreen = () => {
  Settings.isNewUI()
    ? NavActions.push(AppRoutes.History)
    : NavActions.push(AppRoutes.PurchaseHistory);
};

const referralClicked = () => {
  NavActions.navigate(AppRoutes.Referral, {
    referralCampaign: AccountStore.getReferralCampaign(),
    referralStat: AccountStore.getReferralStat(),
  });
};

const handlePrivacyPolicy = (currentPolicy, currentPolicyType) => {
  try {
    const url = Settings.getPrivacyPolicyUrl(
      Settings.getLocale(),
      currentPolicy,
      currentPolicyType,
    );
    const backupUrl = Settings.getPrivacyPolicyUrl(
      Settings.getDefaultLocale(),
      currentPolicy,
      currentPolicyType,
    );
    NavActions.push(AppRoutes.WebContent, {
      title: Localized.Labels.privacy_policy,
      url,
      backupUrl,
      strings: Localized,
    });
  } catch (error) {
    CrashlyticsEvents.log(
      'Exception',
      'AccountScreen:handlePrivacyPolicy',
      generateErrorMessage(error),
    );
    Events.Error.trackEvent(
      'Exception',
      'AccountScreen:handlePrivacyPolicy',
      generateErrorMessage(error),
    );
  }
};

export default {
  handleTerms,
  handleChangePin,
  handleChangePassword,
  toManageAccount,
  contactClicked,
  blockedUsersClicked,
  sendasnackClicked,
  handleMarketCard,
  handleDeleteAccount,
  toBalances,
  toWalletScreen,
  toPurchaseHistoryScreen,
  handlePrivacyPolicy,
  handleChangeEmail,
  handleLinkLocation,
  referralClicked,
  scanKioskQrCode,
};
