import moment from 'moment';
import React from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import BackSubheader from '../../elements/BackSubheader';
import Styles from '../../Styles';
import type {ProductCategory} from 'src/types/ProductCategory';
import TransactionActions from 'src/actions/TransactionActions';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {ProductType} from 'src/api/CartApi';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import CustomSearchbar from '../../elements/CustomSearchField';
import SearchIcon from '../../img/svg/SearchIcon';
import ProductCategories from './ProductCategories';
import SearchProductItem from './SearchProductItem';
import AVText from 'src/components/elements/AVText';
import {scanAndPayTemplate} from 'src/components/screens/scanAndPay/templates/scanandPayThemeHelper';

type SearchProductsScreenProps = {
  displayName?: string;
  marketName: string;
  locationId: string;
  itemSelected: (scancode: string) => void;
};

type SearchProductsScreenState = {
  products: Array<ProductType>;
  query: string;
  categories: Array<ProductCategory>;
  selectedCategoryKey: string | number;
  selectedCategoryName: string;
};

class SearchProductsScreen extends React.Component<
  SearchProductsScreenProps,
  SearchProductsScreenState
> {
  PAGE_SIZE = 50;
  fetching = false;
  ALL_KEY = 'internal-all';
  startTime: moment.Moment;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: SearchProductsScreenProps) {
    super(props);
    this.state = {
      products: [],
      query: '',
      categories: [
        {
          name: Localized.Labels.all,
          key: this.ALL_KEY,
        },
      ],
      selectedCategoryKey: this.ALL_KEY,
      selectedCategoryName: Localized.Labels.all,
    };
    this.startTime = moment();
  }

  componentDidMount() {
    this.fetchCategories().then(() => this.fetchProducts());

    FirebaseAnalytic.trackEvent('componentDidMount', 'SearchProductsScreen', {
      ...this.props,
      ...this.state,
    });
  }

  fetchCategories = async () => {
    FirebaseAnalytic.trackEvent('fetchCategories', 'SearchProductsScreen');
    try {
      let categories = await TransactionActions.getCategories(
        this.props.locationId,
      );
      Logger.Log.LogAPIEvent(
        'CartAPI',
        'GetCategories',
        JSON.stringify({locationId: this.props.locationId}),
        JSON.stringify(categories),
      );
      const categoryKeys = [];
      categories = categories.filter((category) => {
        if (categoryKeys.indexOf(category.key) < 0) {
          categoryKeys.push(category.key);
          return true;
        }

        return false;
      });
      categories.unshift({
        name: Localized.Labels.all,
        key: this.ALL_KEY,
      });
      this.setState({
        categories,
      });

      FirebaseAnalytic.trackEvent(
        'fetchCategories getCategories',
        'SearchProductsScreen',
        {
          categoryKeys,
          categories,
        },
      );
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'SearchProductsScreen:FetchCategories',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'SearchProductsScreen:FetchCategories',
        generateErrorMessage(error),
      );
      Logger.Log.LogAPIEvent(
        'CartAPI',
        'GetCategories',
        JSON.stringify({locationId: this.props.locationId}),
        JSON.stringify(error),
      );
    }
  };

  fetchProducts = async () => {
    FirebaseAnalytic.trackEvent('fetchProducts', 'SearchProductsScreen', {
      fetching: this.fetching,
    });
    if (!this.fetching && this.state.products.length % this.PAGE_SIZE === 0) {
      this.fetching = true;
      let category = this.state.selectedCategoryKey;

      if (category === this.ALL_KEY) {
        category = '';
      }

      try {
        const result = await TransactionActions.searchProducts(
          this.props.locationId,
          this.state.query,
          category,
          this.state.products.length,
          this.PAGE_SIZE,
        );

        if (result && result.length > 0) {
          this.setState({
            products: this.state.products.concat(result),
          });
        } else {
          this.setState({
            products: [],
          });
        }

        this.fetching = false;

        FirebaseAnalytic.trackEvent('fetchProducts', 'SearchProductsScreen', {
          fetching: this.fetching,
        });
      } catch (error) {
        CrashlyticsEvents.log(
          'Exception',
          'SearchProductsScreen:FetchProducts',
          generateErrorMessage(error),
        );
        Events.Error.trackEvent(
          'Exception',
          'SearchProductsScreen:FetchProducts',
          generateErrorMessage(error),
        );
        this.fetching = false;
      }
    }
  };

  onBackSelect() {
    Events.CartSearch.trackEvent(this.startTime, '', true, '');
  }

  onCategoryPress = (category: ProductCategory) => {
    FirebaseAnalytic.trackEvent('onCategoryPress', 'SearchProductsScreen', {
      ...this.props,
      ...this.state,
      category,
    });
    if (this.state.selectedCategoryKey !== category.key) {
      Events.CartSearch.trackEvent(this.startTime, '', false, category.name);
      this.setState(
        {
          selectedCategoryKey: category.key,
          selectedCategoryName: category.name,
          products: [],
        },
        () => this.fetchProducts(),
      );
    }
  };

  onSearchChanged = (search: string) => {
    FirebaseAnalytic.trackEvent('onCategoryPress', 'SearchProductsScreen', {
      search,
    });
    if (this.state.query !== search) {
      this.setState(
        {
          query: search,
          products: [],
        },
        () => this.fetchProducts(),
      );
    }
  };

  itemSelected = (item: ProductType) => {
    Events.CartSearch.trackEvent(this.startTime, item.name);
    FirebaseAnalytic.trackEvent('itemSelected', 'SearchProductsScreen', {
      item,
    });
    this.props.itemSelected(item.scancode);
    setTimeout(() => {
      NavActions.pop();
    }, 200);
  };

  renderProduct = ({item}: ListRenderItemInfo<ProductType>) => {
    return (
      <SearchProductItem
        onPress={() => this.itemSelected(item)}
        price={item.price}
        name={item.name}
        isProductCat={true}
      />
    );
  };

  render() {
    let titleScreen = this.props.marketName;
    if (Platform.OS !== 'web' && this.props.displayName) {
      titleScreen = this.props.displayName;
    }
    return (
      <BackSubheader title={titleScreen} onBackSelect={this.onBackSelect}>
        <CustomSearchbar
          onChangeText={this.onSearchChanged}
          value={this.state.query}
          placeholder={Localized.Labels.searchproducts}
          containerStyle={styles.searchWrapper}
          //elevation={1}
          inputStyle={styles.searchInput}
          searchIcon={<SearchIcon />}
        />

        <ProductCategories
          categories={this.state.categories}
          selectedCategory={this.state.selectedCategoryKey}
          onCategoryPress={this.onCategoryPress}
        />

        <FlatList
          style={styles.productsList}
          data={this.state.products}
          onEndReachedThreshold={0.5}
          onEndReached={() => this.fetchProducts()}
          renderItem={this.renderProduct}
          keyExtractor={(product) => product.id}
          ListFooterComponent={<View style={styles.footerView} />}
          ListEmptyComponent={
            this.fetching &&
            this.state.products.length === 0 && (
              <View style={styles.emptyContainer}>
                <AVText accessibilityRole="text" style={styles.emptyContent}>
                  {Localized.Labels.no_products_available}
                </AVText>
              </View>
            )
          }
        />
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  emptyList: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
    marginHorizontal: Styles.Spacing.m3,
  },
  emptyListText: {
    fontSize: Styles.Fonts.f2,
    fontWeight: '400',
    fontFamily: scanAndPayTemplate.textFontFamily,
    color: Styles.Colors.PayNew.black01,
    textAlign: 'center',
  },
  footerView: {
    height: 50,
  },
  productsList: {
    paddingTop: Styles.Spacing.m2,
  },
  searchInput: {
    fontSize: Styles.Fonts.f2,
    fontWeight: '400',
    fontFamily: scanAndPayTemplate.textFontFamily,
    color: Styles.Colors.PayNew.darkHuesBase04,
  },
  searchWrapper: {
    backgroundColor: Styles.white,
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m3,
    borderRadius: 40,
  },
  emptyContent: {
    fontSize: Styles.Fonts.f2,
    fontWeight: '400',
    fontFamily: scanAndPayTemplate.textFontFamily,
    textAlign: 'center',
    color: '#000',
  },
  emptyContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: Styles.Spacing.m6,
    marginHorizontal: Styles.Spacing.m3,
  },
});

export default withForwardedNavigationParams<SearchProductsScreenProps>()(
  SearchProductsScreen,
);
