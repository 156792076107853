import PersistentStore from 'src/services/PersistentStoreService';
import Events from 'src/logging/Events';
import {generateErrorMessage} from '../logging/generateErrorMessage';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export const CacheKeys = {
  CreditCards: 'credit-cards-v2',
  Account: 'account-v2',
  PaymentCredentials: 'payment-credentials-v2',
  PayrollDeductInfo: 'payroll-deduct-info-v2',
};
export default async function cacheApiCall<T, U>(
  apiPromise: Promise<T>,
  cacheKey: string,
  modelMapper: (response: unknown) => U,
): Promise<U> {
  let response;

  try {
    response = await apiPromise;
  } catch (error) {
    CrashlyticsEvents.log(
      'Exception',
      'cacheApiCall',
      generateErrorMessage(error),
      response,
    );
    Events.Error.trackEvent(
      'Exception',
      'cacheApiCall',
      generateErrorMessage(error),
    );
  } finally {
    if (!response) {
      return await PersistentStore._get(cacheKey);
    }

    const model = modelMapper(response);

    PersistentStore._set(cacheKey, model);

    return model;
  }
}
export function clearCache() {
  Object.keys(CacheKeys).forEach((key) => {
    PersistentStore._delete(CacheKeys[key]);
  });
}
