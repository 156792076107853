import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  color?: string;
  size?: number;
}
const ScansearchIcon = ({size = 22, color = '#9A9A9F'}: IProps) => (
  <Svg width={size} height={size} viewBox="0 0 22 22" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.023 17.236l-4.048-4.1a7.48 7.48 0 001.002-3.718c0-4.182-3.399-7.585-7.572-7.585-4.172 0-7.572 3.403-7.572 7.585 0 4.18 3.4 7.584 7.572 7.584 1.333 0 2.65-.367 3.825-1.07l4.03 4.087a.484.484 0 00.697 0l2.066-2.091a.49.49 0 000-.692zM9.405 4.788a4.63 4.63 0 010 9.26 4.63 4.63 0 010-9.26z"
      fill={color}
    />
  </Svg>
);
export default ScansearchIcon;
