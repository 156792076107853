import React from 'react';
import {StyleSheet, View, Dimensions, FlatList} from 'react-native';
import Styles from '../../Styles';
import AVText from 'src/components/elements/AVText';
import Localized from 'src/constants/AppStrings';
import {IsConnectedProps} from 'src/types/Screen';
import NearbyLocationView, {
  NearbyLocationType,
} from 'src/components/elements/home/NearbyLocationView';
import LocationErrorStatus, {
  LocationErrorStatusType,
} from 'src/components/elements/home/LocationErrorStatus';
import {getDescriber} from 'src/components/elements/home/descriptor/DescriptorType';

type NearbyLocationsListProps = IsConnectedProps & {
  locations: NearbyLocationType[];
};
const width = Dimensions.get('window').width;
const {getCampusLocationStyles} = getDescriber();

const NearbyLocationsList = (props: NearbyLocationsListProps) => {
  //Returns Nearby Location Item UI
  const renderItem = ({item}) => {
    return <NearbyLocationView nearbyLocation={item} />;
  };

  //Return No nearby location error view when there are no locations
  if (props.locations.length <= 0) {
    return (
      <LocationErrorStatus
        locationErrorStatusType={LocationErrorStatusType.NoNearbyLocations}
      />
    );
  }

  return (
    <View style={styles.containerView}>
      <View style={styles.header}>
        <AVText
          aria-label={`${Localized.Labels.near_by}, text`}
          style={[
            getCampusLocationStyles()['descriptionText'],
            styles.textHeader,
          ]}
          accessibilityLabel={Localized.Labels.near_by}
          accessibilityRole="text"
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          accessible={true}
        >
          {Localized.Labels.near_by}
        </AVText>
      </View>
      <FlatList
        horizontal={true}
        style={styles.locationListContainer}
        data={props.locations}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item) => item.campusId ?? item.locationId}
        renderItem={renderItem}
        ListHeaderComponent={<View style={styles.headerView} />}
        ListFooterComponent={<View style={styles.footerView} />}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  containerView: {
    alignItems: 'flex-start',
    marginRight: -Styles.Spacing.m3,
    marginLeft: -Styles.Spacing.m3,
    justifyContent: 'flex-start',
    marginVertical: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m4,
  },
  locationListContainer: {
    width: '100%',
    marginLeft: -Styles.Spacing.m1,
  },
  header: {
    height: Styles.Heights.h3,
    width: width,
  },
  textHeader: {
    fontWeight: '700',
    fontSize: Styles.Fonts.sectionHeader,
    color: Styles.black,
    marginLeft: Styles.Spacing.m4,
  },
  headerView: {
    width: Styles.Spacing.m4,
  },
  footerView: {
    width: Styles.Spacing.m3 + Styles.Spacing.m2,
  },
});
export default NearbyLocationsList;
