// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function notificationReadLogoRefive(props) {
  return (
    <Svg width={28} height={29} viewBox="0 0 28 29" fill="none" {...props}>
      <Path
        d="M17.5 21.5a3.5 3.5 0 11-7 0v-1.167H4.667S7 15.667 7 11c0-3.813 3.187-7 7-7s7 3.187 7 7c0 3.5 2.333 9.333 2.333 9.333H17.5V21.5z"
        fill="#A4DB57"
      />
      <Path
        d="M10.5 20.333V21.5a3.502 3.502 0 004.84 3.234A3.5 3.5 0 0017.5 21.5v-1.167m-7 0H4.667S7 15.667 7 11c0-3.813 3.187-7 7-7s7 3.187 7 7c0 3.5 2.333 9.333 2.333 9.333H17.5m-7 0h7"
        stroke="#0F893D"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const NotificationReadIconRefive = React.memo(notificationReadLogoRefive);
export default NotificationReadIconRefive;
