import React from 'react';
import CreateAccountPrivacyPolicyHandler from '../elements/createAccount/CreateAccountPrivacyPolicyHandler';
import ScreenContext from '../ScreenContext';
import type {SnackAction} from 'src/types/Snack';
import type {ScreenProps} from 'src/types/Screen';
import TextInput from '../elements/AVTextInput';
import {AppDispatch, store} from 'src/redux/store';
import {PulseIndicator} from 'react-native-indicators';
import {
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  Linking,
  AppState,
  Dimensions,
  Image,
  TouchableOpacity,
} from 'react-native';
import Styles from 'src/components/Styles';
import ActionsFactory from 'src/actions/ActionsFactory';
import PersistentStore from 'src/services/PersistentStoreService';
import SyncHelper from 'src/services/SyncService';
import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import AppRoutes from 'src/AppRoutes';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {ScreenWidth} from 'react-native-elements/dist/helpers';
import FocusAwareStatusBar from 'src/components/elements/FocusAwareStatusBar';
import UIManager from 'src/components/elements/ui/UIManager';
import DeepLinkHandler from 'src/components/elements/DeepLinkHandler';
import AVText from 'src/components/elements/AVText';
import Localized from 'src/constants/AppStrings';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import NavActions from 'src/actions/NavActions';
import Header from 'src/components/elements/Header';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {EnvironmentKey} from 'src/models/Environment';
import {RootState} from 'src/redux/store';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import {
  alertError,
  confirm,
  confirmNonDismissable,
} from '../helpers/AlertHelper';
import {fetchRemoteConfig} from 'src/redux/slices/firebaseRemoteConfigSlice';
import {authStore} from 'src/init';
import {getMobileAuth0Client, getWebAuth0Client} from 'src/nativeModules/Auth0';
import Events from 'src/logging/Events';
import uuid from 'src/nativeModules/UUID';
import moment from 'moment';
import DealRepository from 'src/services/aws/DealRepository';
import OneSignal from 'src/nativeModules/OneSignal';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import {NavigationProp} from '@react-navigation/native';
import BuildTypes from 'src/constants/BuildTypeConstants';
import {getDescriber} from './descriptor/DescriptorType';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {getOnboardingDescriber} from 'src/components/screens/descriptor/onboarding/DescriptorType';
import LinearGradient from 'react-native-linear-gradient';
import RedirectLoadingScreen from './RedirectLoadingScreen';
import AppApi from 'src/api/AppApi';
import OtpIcon from 'src/components/img/svg/OtpIcon';

function sessionTimeOut() {
  setTimeout(
    () =>
      alertError(Localized.Errors.login_failure, null, () => {
        ActionsFactory.getAccountActions().logout().catch();
        NavActions.resetMultiple([AppRoutes.Welcome]);
      }),
    300,
  );
}

type WelcomeProps = {
  snack?: SnackAction;
  env: EnvironmentKey;
  firebaseConfig: object;
  dispatch: AppDispatch;
  shouldAutoLogin?: boolean;
  autoLoginEmail?: string;
  triggerAuth0fn?: boolean;
};

type WelcomeState = {
  fullVersion: string;
  touches: number;
  appState: string;
  autoLoginEmail?: string;
};

type SettingsModel = {
  demo: boolean;
  env: string;
  gma: boolean;
  debug: boolean;
  retry: boolean;
};

//auth0
type WelcomeAuth0Props = {
  snack?: SnackAction;
  referralId: string;
  token: string;
  navigation?: NavigationProp<WelcomeScreen>;
};

type WelcomeAuth0State = {
  fullVersion: string;
  previousRoute?: string;
  showRedirectScreen: boolean;
  redirectMessage?: string | null;
};
//------------

const backgroundColor = Platform.select({
  web: 'white',
  default: Styles.loginBackground,
});

let appUpdateAlertShown = false;
const {width} = Dimensions.get('screen');

const {
  getWelcomeStatusBarStyleDescriptor,
  getWelcomeViewBckGrndStyleDescriptor,
  getOrTextDescriptor,
  getVersionTextDescriptor,
  getLogoContainer,
  getLineContainer,
  getWelcomeButtonStyles,
} = getDescriber();
function RenderRedirectScreen({message}) {
  if (Settings.isNewUI()) {
    return <RedirectLoadingScreen signInMessage={message} />;
  }
  return (
    <View style={styles.redirectBackground}>
      <PulseIndicator
        size={120}
        color={Styles.bgColor}
        style={styles.indicator}
      />
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
        style={styles.redirectCaption}
      >
        {Localized.Labels.redirect_message}
      </AVText>
    </View>
  );
}
const Wrapper =
  Platform.OS === 'web'
    ? ({children}: React.PropsWithChildren<object>) => (
        <Header title={Localized.Labels.welcome_back}>{children}</Header>
      )
    : ({children}: React.PropsWithChildren<object>) => (
        <View style={Styles.Style.flex}>{children}</View>
      );
export class WelcomeScreen extends React.Component<
  WelcomeProps & WelcomeAuth0Props,
  WelcomeState & WelcomeAuth0State
> {
  password: TextInput | null;
  privacyHandler: CreateAccountPrivacyPolicyHandler | null;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  REDIRECT_SCREEN_SHOW_DELAY = 500;
  declare myTimeout: NodeJS.Timeout;

  static saveSettingChanges(model: SettingsModel) {
    //TODO will move static func to EnvironmentScreen in componentWillUnmount
    // replace for this.props.saveChanges(this.state);
    ActionsFactory.getAccountActions().isDemoChanged(model.demo);
    // ActionsFactory.getAccountActions().environmentChanged(model.env);
    ActionsFactory.getAccountActions().useGmaChanged(model.gma);
    ActionsFactory.getAccountActions().isDebugChanged(model.debug);
  }

  constructor(props: WelcomeProps & WelcomeAuth0Props) {
    super(props);
    this.state = {
      touches: 0,
      fullVersion: '',
      appState: AppState.currentState,
      showRedirectScreen: false,
      autoLoginEmail: props.autoLoginEmail,
      redirectMessage: null,
    };

    this.logoPress = this.logoPress.bind(this);
    this.webLoginAuth0 = this.webLoginAuth0.bind(this);
    this.goToEnvironmentSettings = this.goToEnvironmentSettings.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  _isNoAppUpdateAlertShowing = () => !appUpdateAlertShown;

  async componentDidMount() {
    const loggedIn = await this.checkIfLoggedIn();
    FirebaseAnalytic.trackEvent('componentDidMount', 'WelcomeScreen', {
      ...this.props,
      ...this.state,
      loggedIn,
    });
    if (!loggedIn) {
      const fullVersion = await Settings.getFullDisplayVersion(Platform.OS);
      this.setState({
        fullVersion,
      });
    }
    AppState.addEventListener('change', this._handleAppStateChange);

    this.props.dispatch(fetchRemoteConfig(false));
    if (this.props.shouldAutoLogin || this.props.triggerAuth0fn) {
      if (Platform.OS === 'web') {
        this.webLoginAuth0();
      } else {
        this.handleClick(true, 'email');
      }
    }

    if (!ActionsFactory.getAccountActions().didIntentionalLogout()) {
      this.context.actions.showModal(
        Localized.Labels.you_were_logged_out,
        Localized.Errors.oops,
        Localized.Buttons.ok,
        Styles.Colors.PayNew.primary01,
        3,
        false,
        false,
        undefined,
        () => {
          ActionsFactory.getAccountActions().setIntentionalLogout(true);
        },
      );
    }
  }

  _handleAppStateChange = (nextAppState) => {
    switch (nextAppState) {
      case 'active':
        if (
          this._isNoAppUpdateAlertShowing() &&
          this._enableShowAlertAfterMinimized()
        ) {
          this.props.dispatch(fetchRemoteConfig(true));
        }

        break;
    }
  };

  _enableShowAlertAfterMinimized = () => Platform.OS == 'ios';

  logoPress() {
    const touches = this.state.touches + 1;
    FirebaseAnalytic.trackEvent('logoPress', 'WelcomeScreen', {
      ...this.props,
      ...this.state,
      touches,
    });
    if (touches >= 5) {
      this.setState({
        touches: 0,
      });
      this.goToEnvironmentSettings();
    } else {
      this.setState({
        touches,
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<WelcomeProps>): void {
    this.showAppUpdateAlert(prevProps);
  }

  showAppUpdateAlert = (prevProps) => {
    const firebaseConfig = this.props.firebaseConfig;
    if (
      firebaseConfig !== null &&
      prevProps.firebaseConfig !== firebaseConfig
    ) {
      if (firebaseConfig['softUpdate'] && this._isNoAppUpdateAlertShowing()) {
        appUpdateAlertShown = true;
        confirm(
          firebaseConfig['alertMessage'],
          () => {
            appUpdateAlertShown = false;
            Linking.openURL(firebaseConfig['alertUpdateActionUrl']);
            this.trackEvent('softUpdateAlertLinkClick', 'WelcomeScreen');
          },
          () => {
            appUpdateAlertShown = false;
            this.trackEvent('softUpdateAlertCloseClick', 'WelcomeScreen');
          },
          firebaseConfig['alertTitle'],
          firebaseConfig['alertCancelButtonTitle'],
          firebaseConfig['alertUpdateButtonTitle'],
        );
        this.trackEvent('softUpdateAlert', 'WelcomeScreen');
      } else {
        this.showForceUpdateAlert(firebaseConfig);
      }
    }
  };

  trackEvent(event: string, screen: string) {
    FirebaseAnalytic.trackEvent(event, screen, {
      ...this.props,
      ...this.state,
    });
  }

  showForceUpdateAlert(firebaseConfig) {
    appUpdateAlertShown = true;
    confirmNonDismissable(
      firebaseConfig['alertMessage'],
      () => {
        this.showForceUpdateAlert(firebaseConfig);
        Linking.openURL(firebaseConfig['alertUpdateActionUrl']);
        this.trackEvent('forceUpdateAlertLinkClick', 'WelcomeScreen');
      },
      firebaseConfig['alertTitle'],
      firebaseConfig['alertUpdateButtonTitle'],
    );
    this.trackEvent('forceUpdateAlert', 'WelcomeScreen');
  }

  goToEnvironmentSettings() {
    const env = this.props.env;
    FirebaseAnalytic.trackEvent('goToEnvironmentSettings', 'WelcomeScreen', {
      ...this.props,
      ...this.state,
    });
    NavActions.push(AppRoutes.Environment, {
      saveChanges: WelcomeScreen.saveSettingChanges,
      env,
      demo: AccountStore.isDemo(),
      debug: AccountStore.isDebug(),
      showRetry: Platform.OS === 'android',
      showGma: true,
      showDemo: true,
      showDebug: true,
      showLanguage: true,
      showCreateAccount: true,
      onCountrySelectionPress: this.onCountrySelectionPress,
    });
  }

  onCountrySelectionPress = () => NavActions.push(AppRoutes.CountrySelection);

  async checkIfLoggedIn() {
    const accDetail = await PersistentStore.getAccountDetails();
    const gmaAccDetails = await PersistentStore.getGmaAccountDetails();
    FirebaseAnalytic.trackEvent('checkIfLoggedIn', 'WelcomeScreen', {
      ...this.props,
      ...this.state,
      accDetail,
    });
    if (
      gmaAccDetails.accountInfo?.FirstName === undefined &&
      gmaAccDetails.accountInfo?.Email
    ) {
      return false;
    }
    if (accDetail && accDetail.accountId !== '-1' && accDetail.username) {
      this.context.actions.changeRootRoute(AppRoutes.App);
      return true;
    }

    return false;
  }

  async handleClick(isSignUp = false, connection: string) {
    if (isSignUp) {
      Events.AccountSignup.trackEvent(DeviceInfo.getModel(), 'Start');
    }
    // Set timeout as 500ms
    // so that redirect screen does not appear immediately
    // this.myTimeout = setTimeout(() => {

    // }, this.REDIRECT_SCREEN_SHOW_DELAY);
    this.setState({showRedirectScreen: true});
    await new Promise((resolve) =>
      setTimeout(resolve, this.REDIRECT_SCREEN_SHOW_DELAY),
    );
    const payrollImportAuth0Data = await PersistentStore.getPayrollImportData();

    const Auth0 = getMobileAuth0Client();
    const env = store.getState().environment.env;

    try {
      const loginHint =
        this.state.autoLoginEmail ??
        (await ActionsFactory.getAccountActions().getPersistedEmail());
      const locale = Settings.getLocale();
      const country = locale.includes('-') ? locale.split('-')[1] : 'US';
      const credentials = await Auth0.webAuth.authorize(
        {
          scope: 'openid offline_access profile email',
          audience: Settings.auth0[env].audience,
          organization: Settings.auth0[env].orgIdAuth0,
          additionalParameters: {
            login_hint: loginHint ?? '',
            accountImportId: payrollImportAuth0Data?.importId ?? '',
            prompt: 'login',
            countryCode: country,
            connection: connection,
          },
        },
        {
          ephemeralSession: true,
        },
      );
      if (isSignUp) {
        Events.AccountSignup.trackEvent(DeviceInfo.getModel(), 'Completed', {
          audience: Settings.auth0[env].audience,
          organization: Settings.auth0[env].orgIdAuth0,
          loginHint,
        });
      }
      this.setState({
        redirectMessage: Localized.Labels.signing_you_in_now,
      });

      if (credentials) {
        const {snack} = this.props;
        this.verifyLogin(this.context, undefined, snack);
        await authStore.storeSession(credentials);
      }
    } catch (error) {
      // no error user cancelled
      clearInterval(this.myTimeout);
      this.setState({showRedirectScreen: false});
      if (
        error &&
        error.message?.toLowerCase().indexOf('user cancelled') >= 0
      ) {
        isSignUp &&
          Events.AccountSignup.trackEvent(
            DeviceInfo.getModel(),
            'End:Cancelled',
          );
        return;
      }

      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log('Exception', 'Login:Error', error.toString(), guid);

      Events.Error.trackEvent(
        'Exception',
        'Login:Error',
        error.toString(),
        guid,
      );

      let parsedError;

      try {
        const match = error.message.match(/CAUSE: (.*)/);
        if (match && match[1]) {
          parsedError = JSON.parse(match[1]);
        }
      } catch (parseError) {
        Events.Error.trackEvent(
          'Exception',
          'Login:parseError',
          parseError.toString(),
          guid,
        );
      }

      if (
        parsedError?.violation &&
        parsedError?.violation === 'Account is Locked/Disabled'
      ) {
        isSignUp &&
          Events.AccountSignup.trackEvent(
            DeviceInfo.getModel(),
            'End:Account is Locked/Disabled',
          );
        alert(Localized.Errors.your_account_is_locked_disabled);
        return;
      }

      isSignUp &&
        Events.AccountSignup.trackEvent(
          DeviceInfo.getModel(),
          'End:Unable to login at this time, please try again later.',
        );
      alertError('Unable to login at this time, please try again later.');
    } finally {
      this.setState({autoLoginEmail: undefined});
    }
  }

  async webLoginAuth0(isSignUp?: boolean) {
    if (isSignUp) {
      Events.AccountSignup.trackEvent('Web', 'Start');
    }
    const payrollImportAuth0Web = await PersistentStore.getPayrollImportData();
    try {
      const auth0WebClient = await getWebAuth0Client();
      await auth0WebClient.loginWithPopup({
        authorizationParams: {
          accountImportId: payrollImportAuth0Web?.importId ?? '',
          prompt: 'login',
        },
      });
      const user = await auth0WebClient.getUser();

      if (!user) {
        return;
      }
      const accessToken = await auth0WebClient.getTokenSilently({
        detailedResponse: true,
      });
      const idInfo = await auth0WebClient.getIdTokenClaims();

      await authStore.storeSession({
        accessToken: accessToken.access_token,
        idToken: idInfo.__raw,
        expiresAt: moment().unix() + accessToken.expires_in,
        tokenType: 'Bearer',
        scope: '',
        refreshToken: 'web',
        isSSO: idInfo['https://365rm.us/isSSO'],
        isNewUser: idInfo['https://365rm.us/isNewUser'],
        hasQRCode: idInfo['https://365rm.us/hasQRCode'],
      });

      const account = await ActionsFactory.getAccountActions().loginLegacy();

      if (account.firstName === null && account.email) {
        this.context.actions.changeRootRoute(AppRoutes.Login);
        const params = {
          accountId: account.accountId,
          email: account.unverifiedEmail,
          isSSO: account.isSSO,
          firstName: account.firstName,
          lastName: account.lastName,
          showPin: !account.pinHash,
          referralId: this.props?.referralId,
          token: this.props.token,
        };

        if (isSignUp) {
          Events.AccountSignup.trackEvent('Web', 'Completed', {
            accountId: account.accountId,
            email: account.unverifiedEmail,
            isSSO: account.isSSO,
            firstName: account.firstName,
            lastName: account.lastName,
          });
        }
        NavActions.push(AppRoutes.CountrySelection, params);
        return;
      } else if (account.unverifiedEmail) {
        sessionTimeOut();
        return;
      }
      const shouldShowQRCode = await authStore.shouldShowQRCode();
      if (shouldShowQRCode) {
        NavActions.push(AppRoutes.MarketCardRegistration, {
          onSuccess: () =>
            WelcomeScreen.onLoginSuccess(
              account,
              this.context,
              this.props.referralId,
              this.props.token,
              undefined,
              this.props.snack,
            ),
        });
      } else {
        await WelcomeScreen.onLoginSuccess(
          account,
          this.context,
          this.props.referralId,
          this.props.token,
          undefined,
          this.props.snack,
        );
      }
    } catch (error) {
      let parsedError;
      try {
        const outerError =
          typeof error === 'string' ? JSON.parse(error) : error;

        if (outerError?.error_description) {
          parsedError = JSON.parse(outerError.error_description);
        }
      } catch (err) {
        Events.Error.trackEvent(
          'Exception',
          'WebLogin:parseError',
          err.toString(),
        );
      }

      if (parsedError?.violation === 'Account is Locked/Disabled') {
        isSignUp &&
          Events.AccountSignup.trackEvent(
            'Web',
            'End:Account is Locked/Disabled',
          );
        alert(Localized.Errors.your_account_is_locked_disabled);
      }
    }
  }

  async verifyLogin(context, initialTabRoute, snack) {
    let account = await ActionsFactory.getAccountActions().loginLegacy(true);

    if (
      account.type?.length === 0 ||
      (account.type === 'PANTRY' && Settings.buildType === BuildTypes.pantry) ||
      (account.type !== 'PANTRY' && Settings.buildType !== BuildTypes.pantry)
    ) {
      account = await ActionsFactory.getAccountActions().loginLegacy();
      const shouldShowQRCode = await authStore.shouldShowQRCode();
      if (shouldShowQRCode) {
        NavActions.push(AppRoutes.MarketCardRegistration, {
          onSuccess: () =>
            WelcomeScreen.onLoginSuccess(
              account,
              context,
              initialTabRoute,
              snack,
            ),
          email: account.email,
        });
      } else {
        await WelcomeScreen.onLoginSuccess(
          account,
          context,
          initialTabRoute,
          snack,
        );
      }
    } else {
      alertError(
        Localized.Errors.user_login_credentials_is_not_valid,
        null,
        () => {
          NavActions.reset(AppRoutes.Welcome);
          ActionsFactory.getAccountActions().logout();
        },
        Localized.Errors.uh_oh,
      );
    }
  }

  static async onLoginSuccess(
    account: any,
    context: ScreenProps,
    referralId: string,
    token: string,
    initialTabRoute?: AppRoutes,
    snack?: SnackAction,
  ) {
    DealRepository.clearDeals();
    if (!AccountStore.isDemo()) {
      OneSignal.sendTags({
        email: account.email,
        mka: account.accountId,
        env: store.getState().environment.env,
      });
      OneSignal.setExternalUserId(account.accountId);
    }

    if (account.accountId) {
      FirebaseAnalytic.setUserId(account.accountId, {
        accountId: account.accountId,
        email: account.email,
        unverifiedEmail: account.unverifiedEmail,
        mka: account.accountId,
        org: account.orgId,
        location: account.locationId,
        region: account.localization.region,
        city: account.city,
        zip: account.zip,
        currency: account.localization.currency,
        env: store.getState().environment.env,
        firstName: account.firstName,
        lastName: account.lastName,
        referralId: referralId,
      });
    }

    Events.AccountLogin.trackEvent(
      DeviceInfo.getModel(),
      account.localization.region,
      account.localization.currency,
      account.isSSO,
      account.email,
    );

    if (snack) {
      context.actions.changeRootRoute(AppRoutes.App, initialTabRoute, {
        nestedNames: [AppRoutes.Inbox],
        name: AppRoutes.SnackDetail,
        params: {snackLocationId: snack.locationId, ...snack},
      });
    } else if (Platform.OS !== 'web') {
      //TODO: Follow up for information about email/unverifiedEmail
      //TODO: unverifiedEmail is null after clicking the link in the email to continue setting up account
      if (account.firstName === null && account.email) {
        NavActions.reset(AppRoutes.Welcome);
        NavActions.push(AppRoutes.CountrySelection, {
          accountId: account.accountId,
          email: account.unverifiedEmail,
          firstName: account.firstName,
          lastName: account.lastName,
          showPin: !account.pinHash,
          referralId: referralId,
          token: token,
        });
      } else if (account.unverifiedEmail) {
        sessionTimeOut();
        return;
      } else {
        const persistedReferralId = await PersistentStore.getReferralId();
        if (persistedReferralId) {
          await AppApi.setupAccount({
            accountId: account.accountId,
            referralId: persistedReferralId,
          });
        }
        context.actions.changeRootRoute(
          AppRoutes.AppProductTour,
          initialTabRoute,
        );
      }
    } else {
      context.actions.changeRootRoute(AppRoutes.App, initialTabRoute);
    }

    SyncHelper.performSyncWithServer();
    ActionsFactory.getAccountActions().refreshMessages();
  }

  close() {
    NavActions.pop();
  }

  renderImage() {
    return (
      <Image
        source={require('src/components/img/WelcomeRefiveDotOverlay.png')}
        style={getWelcomeViewBckGrndStyleDescriptor()['overlay']}
        resizeMode="cover"
      />
    );
  }

  onLinkExistingAccount = () => NavActions.push(AppRoutes.LinkExistingAccount);
  onCreateAccount = () => NavActions.push(AppRoutes.CreateAccountEnterEmail);
  checkIsRevolve = () => {
    return Styles.Colors.PayNew.primary01;
  };
  renderWelcomeView() {
    const isWebSignOn = Platform.OS === 'web';
    const isCanteen =
      Settings.buildType === BuildTypes.canteen ||
      Settings.buildType === BuildTypes.companykitchen;
    const isRedesignRefive =
      Settings.buildType === BuildTypes.default ||
      Settings.buildType === BuildTypes.refive;
    return (
      <View
        style={[
          styles.welcomeView,
          (isRedesignRefive || isCanteen) && {
            paddingBottom: Styles.Spacing.m6,
          },
          Settings.buildType === BuildTypes.refive && {
            top: 50,
          },
        ]}
      >
        {/* To Do: Modify Buttons */}
        {(isRedesignRefive || isCanteen) && (
          <AVText
            style={
              !Settings.isRefive()
                ? styles.commonDescTxtStyl
                : styles.refiveTxtSty
            }
          >
            {getOnboardingDescriber().getWelcomeDescriptionText()}
            {getOrTextDescriptor() && getOrTextDescriptor()['orText'].text}
          </AVText>
        )}
        <RoundedButton
          accessible={true}
          testID={'logInButtonWelcomeScreen'}
          accessibilityHint="Go to the login screen"
          accessibilityRole="button"
          buttonType={ButtonType.normal}
          icon={
            <OtpIcon
              fill={Styles.white}
              style={{marginLeft: Styles.Spacing.m5 + Styles.Spacing.m4}}
            />
          }
          containerStyle={getWelcomeButtonStyles()['topButton']}
          textStyle={[styles.textStyleTop, {color: Styles.white}]}
          color={getOnboardingDescriber().getLoginButtonColor()}
          onPress={() =>
            isWebSignOn ? this.webLoginAuth0() : this.handleClick(true, 'email')
          }
          text={Localized.Buttons.log_in}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
        />
        {isRedesignRefive || isCanteen ? (
          <View style={{marginBottom: 20}}>
            <RoundedButton
              accessibilityRole="button"
              accessible={true}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
              testID="signUptButton"
              color={getOnboardingDescriber().getLoginButtonColor()}
              accessibilityHint="Sign up new account"
              icon={
                <OtpIcon
                  color={getOnboardingDescriber().getLoginButtonColor()}
                  style={{marginLeft: Styles.Spacing.m5 + Styles.Spacing.m4}}
                />
              }
              textStyle={[
                isCanteen ? styles.canteenText : styles.textStyle,
                {color: getOnboardingDescriber().getLoginButtonColor()},
              ]}
              text={Localized.Labels.sign_up}
              containerStyle={[
                getWelcomeButtonStyles()['topButton'],
                {borderWidth: 2},
              ]}
              onPress={() =>
                isWebSignOn
                  ? this.webLoginAuth0(true)
                  : this.handleClick(true, 'email')
              }
              buttonType={ButtonType.outline}
              backgroundColor={Styles.white}
            />
          </View>
        ) : (
          <RoundedButton
            accessibilityRole="button"
            accessible={true}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
            testID="linkExistingAccountButton"
            color={getOnboardingDescriber().getLoginButtonColor()}
            accessibilityHint="Link existing account"
            textStyle={[isCanteen ? styles.canteenText : styles.textStyle]}
            text={Localized.Buttons.link_account}
            containerStyle={[
              getWelcomeButtonStyles()['topButton'],
              Settings.isCanteen() && {borderWidth: 2},
            ]}
            onPress={this.onLinkExistingAccount}
            buttonType={ButtonType.outline}
          />
        )}
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AVText
            style={{
              color: Styles.black,
              fontSize: 16,
              fontWeight: '700',
              fontFamily: Styles.FontFamily.figtreeRegular,
            }}
          >
            {'Have an account with a password?  '}
          </AVText>
          <TouchableOpacity
            onPress={() => this.handleClick(false, 'Consumers')}
            style={{
              alignSelf: 'center',
              borderBottomColor: getOnboardingDescriber().getLoginButtonColor(),
              borderBottomWidth: 1.5,
            }}
          >
            <AVText
              style={{
                color: getOnboardingDescriber().getLoginButtonColor(),
                fontSize: 16,
                fontWeight: '700',
                fontFamily: Styles.FontFamily.figtreeRegular,
              }}
            >
              {'Log in here'}
            </AVText>
          </TouchableOpacity>
        </View>
        {!(isRedesignRefive || isCanteen) && (
          <View>
            <View style={getLineContainer()['lineContainer']}>
              <View
                style={[styles.solidLine, getOrTextDescriptor()['solidLine']]}
              />
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
                testID="orText"
                accessible={true}
                accessibilityLabel="or"
                accessibilityRole="text"
                style={getOrTextDescriptor()['orText']}
              >
                {Localized.Labels.or}
              </AVText>
              <View
                style={[styles.solidLine, getOrTextDescriptor()['solidLine']]}
              />
            </View>
            <RoundedButton
              accessible={true}
              testID="neverUsedThisAppButton"
              accessibilityHint="Create a new account"
              accessibilityRole="button"
              buttonType={ButtonType.outline}
              containerStyle={[
                getWelcomeButtonStyles()['topButton'],
                Settings.isCanteen() && {borderWidth: 2},
              ]}
              color={getOnboardingDescriber().getLoginButtonColor()}
              textStyle={styles.textStyle}
              onPress={this.onCreateAccount}
              text={Localized.Buttons.create_account}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
            />
          </View>
        )}
      </View>
    );
  }

  render() {
    const gradientProps = getOnboardingDescriber().getLogoGradientProps();

    return (
      <LinearGradient {...gradientProps} style={styles.gradientContainer}>
        <Wrapper>
          {Settings.isRefive() && (
            <View style={{top: -10}}>{this.renderImage()}</View>
          )}
          {!this.state.showRedirectScreen ? (
            this.renderMainContent()
          ) : (
            <View style={StyleSheet.absoluteFill}>
              <RenderRedirectScreen
                message={this.state.redirectMessage ?? ''}
              />
            </View>
          )}
        </Wrapper>
      </LinearGradient>
    );
  }

  renderMainContent() {
    return (
      <View
        style={
          getWelcomeViewBckGrndStyleDescriptor() &&
          getWelcomeViewBckGrndStyleDescriptor()['view']
        }
      >
        <FocusAwareStatusBar
          barStyle={UIManager.getLoginStatusBarStyle()}
          backgroundColor={getWelcomeStatusBarStyleDescriptor()}
        />
        <DeepLinkHandler
          launchAuth0Login={
            Platform.OS === 'web'
              ? this.webLoginAuth0
              : this.handleClick.bind(this)
          }
        />
        <CreateAccountPrivacyPolicyHandler
          ref={(privacyHandler) => {
            this.privacyHandler = privacyHandler;
          }}
        />
        {this.renderLogo()}
        {this.renderWelcomeContent()}
        <AVText
          accessible={true}
          accessibilityLabel={`Application version: ${this.state.fullVersion}`}
          style={[
            styles.versionText,
            getVersionTextDescriptor()['versionText'],
          ]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
        >
          {this.state.fullVersion}
        </AVText>
      </View>
    );
  }

  renderWelcomeContent() {
    return (
      <View style={styles.welcomeContent}>{this.renderWelcomeView()}</View>
    );
  }

  renderLogo() {
    return (
      <View
        style={
          Settings.buildType === BuildTypes.default
            ? [styles.logoContainer, styles.logoContainer365Pay]
            : Settings.buildType === BuildTypes.canteen
            ? [styles.logoContainer, styles.canteenLogoContainer]
            : getLogoContainer()['logoContainer']
        }
      >
        <TouchableWithoutFeedback accessible={false} onPress={this.logoPress}>
          <View
            style={[
              Settings.isCanteen() && {paddingTop: 50},
              Settings.isRefive() && {top: -120},
            ]}
          >
            {getOnboardingDescriber().getLoginLogo()}
          </View>
        </TouchableWithoutFeedback>
        {Settings.buildType === BuildTypes.default && (
          <AVText style={styles.payText}>365Pay</AVText>
        )}
        {Settings.buildType === BuildTypes.canteen && (
          <AVText style={styles.canteenTextRedesign}>Connect & Pay</AVText>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  otpIcon2Color: {
    color:
      Settings.buildType === BuildTypes.canteen
        ? '#003349'
        : Settings.buildType === BuildTypes.default
        ? Styles.Colors.PayNew.primary01
        : Styles.Colors.PayNew.primary01,
  },
  gradientContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  view: {
    backgroundColor: Styles.darkBlueRevolveColor,
    flex: 1,
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    marginBottom: Styles.Spacing.m5,
  },
  canteenLogoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
  },
  logoContainer365Pay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
  },
  container: {
    flex: 1,
    alignItems: 'center',
  },
  content: {
    width: ScreenWidth,
  },
  welcomeContent: {
    marginTop: Styles.Spacing.m1,
    width: Platform.OS === 'web' ? null : ScreenWidth,
    justifyContent: 'flex-end',
  },
  lineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: Styles.Spacing.m2,
  },
  solidLine: {
    height: 1,
    flex: 1,
    marginHorizontal: Styles.Spacing.m2,
  },
  instructionsWelcome: {
    color: Styles.black,
    fontSize: Styles.Fonts.f4,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: Styles.Spacing.m1,
  },

  instructions: {
    color: Styles.white,
    fontSize: Styles.Fonts.f4,
    fontWeight: 'bold',
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: Styles.Spacing.m3,
  },
  versionText: {
    fontSize: Styles.Fonts.f8,
    marginTop: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m4,
    marginLeft: Styles.Spacing.m4 + Styles.Spacing.m4,
  },
  indicator: {
    flex: 0.5,
  },
  redirectCaption: {
    color: Styles.bgColor,
    fontSize: Styles.Fonts.f5,
    fontWeight: '700',
    marginTop: 0,
    paddingTop: 0,
    marginStart: 8,
    marginEnd: 8,
    textAlign: 'center',
  },
  topButton: {
    marginTop: Styles.Spacing.m3,
    alignSelf: 'auto',
    paddingVertical: Styles.Spacing.m1,
  },
  createAccountButton: {
    marginTop: -13,
    alignSelf: 'auto',
    paddingVertical: Styles.Spacing.m1,
  },
  textStyleTop: {
    textAlign: 'center',
    fontSize: Styles.Fonts.f1,
    color: Styles.white,
    textTransform: 'capitalize',
    marginRight: Styles.Spacing.m5 + Styles.Spacing.m4,
  },
  textStyle: {
    marginRight: Styles.Spacing.m5 + Styles.Spacing.m4,
    textAlign: 'center',
    fontSize: Styles.Fonts.f1,
    color:
      Settings.buildType === BuildTypes.canteen
        ? '#003349'
        : Settings.buildType === BuildTypes.default
        ? Styles.Colors.PayNew.primary01
        : Styles.Colors.PayNew.primary01,
    textTransform:
      Settings.buildType === BuildTypes.default ? 'capitalize' : 'none',
  },
  canteenText: {
    marginRight: Styles.Spacing.m5 + Styles.Spacing.m4,
    textAlign: 'center',
    fontSize: Styles.Fonts.f1,
    color: '#003349',
  },
  divider: {
    flex: 2,
    height: 2,
    marginHorizontal: Styles.Spacing.m2,
    backgroundColor: Styles.white,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  welcomeView: {
    marginHorizontal: Styles.Spacing.m4,
  },
  logoImg: {
    width: width - (width / 5.5) * 3,
    height: width - (width / 5.5) * 3,
    alignSelf: 'center',
    position: 'absolute',
    marginTop: '15%',
    zIndex: 999,
  },
  redirectBackground: {
    backgroundColor: Styles.primaryColor,
    minHeight: Styles.ScreenHeight,
    minWidth: Styles.ScreenWidth,
    alignSelf: 'center',
  },
  welcomeBackText: {
    color: Styles.Colors.PayNew.black01,
    textAlign: 'center',
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 24,
  },
  payText: {
    color: Styles.Colors.PayNew.black01,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 46,
    fontStyle: 'normal',
    fontWeight: '700',
    textAlign: 'center',
    marginTop: Styles.Spacing.m1,
  },
  canteenTextRedesign: {
    color: Styles.Colors.PayNew.black01,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 46,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 48,
    textAlign: 'center',
  },
  commonDescTxtStyl: {
    color: Styles.Colors.PayNew.black01,
    textAlign: 'center',
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 24,
  },
  refiveTxtSty: {
    color: '#022649',
    textAlign: 'center',
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '700',
  },
});
export default compose(
  withForwardedNavigationParams<WelcomeProps>(),
  connect((state: RootState) => ({
    env: state.environment?.env,
    firebaseConfig: state.firebaseConfig?.data,
  })),
)(WelcomeScreen);
