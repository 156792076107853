import React, {useEffect} from 'react';
import {View, StyleSheet, TouchableOpacity, ScrollView} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import withIsConnected from '../../hoc/withIsConnected';
import Localized from 'src/constants/AppStrings';
import {useNavigation} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import Styles from 'src/components/Styles';
import AVText from 'src/components/elements/AVText';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import BaseScreen from 'src/components/screens/BaseScreen';
import LocationHandler from 'src/handlers/LocationHandler';
import NearbyLocationsList from 'src/components/elements/home/NearbyLocationsList';
import LocationErrorStatus, {
  LocationErrorStatusType,
} from 'src/components/elements/home/LocationErrorStatus';
import RecentLocationsList from 'src/components/elements/home/RecentLocationsList';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import {IsConnectedProps} from 'src/types/Screen';
import {useAppDispatch, useAppSelector} from 'src/redux/hooks';
import {fetchNearbyCampusesAndLocations} from 'src/redux/slices/campusLocationSlice';
import {getDescriber} from './descriptor/DescriptorType';
import Settings from 'src/Settings';

type CampusLocationsScreenProps = IsConnectedProps;
const {getCampusLocationStyles} = getDescriber();

const CampusLocationsScreen = (props: CampusLocationsScreenProps) => {
  const dispatch = useAppDispatch();
  const recentLocations = useAppSelector(
    (s) => s.campusLocation.recentCampusesAndLocations,
  );
  const nearbyLocations = useAppSelector(
    (s) => s.campusLocation.nearbyCampusesAndLocations,
  );

  const navigation = useNavigation();
  const [previousRoute] = React.useState(
    getPreviousRouteName(navigation?.getState()?.routes),
  );

  useEffect(() => {
    getNearbyLocations();
    FirebaseAnalytic.trackEvent('componentDidMount', 'CampusLocationsScreen', {
      ...props,
    });
  }, []);

  //Fetches nearby locations using current location
  const getNearbyLocations = async () => {
    await LocationHandler.getCurrentLocation();
    const position = LocationHandler.currentLocation;
    dispatch(
      fetchNearbyCampusesAndLocations({
        lat: position?.coords?.latitude,
        lon: position?.coords?.longitude,
      }),
    );
  };

  return (
    <BaseScreen
      title={getDescriber().locationHeaderName()}
      previousRoute={previousRoute}
      accessibilityLabel={'Back arrow'}
      accessibilityHint={`Press to navigate back to the ${previousRoute} screen`}
    >
      <ScrollView
        style={[
          styles.content,
          {
            backgroundColor: Settings.isRefiveAnd365Pay()
              ? Styles.tabBarBackgroundColor
              : Styles.white,
          },
        ]}
      >
        <View
          style={[
            styles.container,
            {
              backgroundColor: Settings.isRefiveAnd365Pay()
                ? Styles.tabBarBackgroundColor
                : Styles.white,
            },
          ]}
        >
          {LocationHandler.isLocationPermissionEnabled ? (
            <NearbyLocationsList
              locations={nearbyLocations}
              isConnected={true}
            />
          ) : (
            <LocationErrorStatus
              locationErrorStatusType={
                LocationErrorStatusType.LocationPermission
              }
            />
          )}
          <RecentLocationsList locations={recentLocations} isConnected={true} />
          <View style={styles.buttonView}>
            <TouchableOpacity
              accessible={true}
              accessibilityHint="Double tap to navigate to the link new location screen"
              accessibilityRole="button"
              role="button"
              accessibilityLabel={Localized.Buttons.manually_link_new_location}
              aria-label={Localized.Buttons.manually_link_new_location}
              testID={Localized.Buttons.manually_link_new_location}
              style={[styles.buttonContainer]}
              onPress={() =>
                NavActions.navigate(AppRoutes.LocationCode, {isFromHome: true})
              }
            >
              <View style={getCampusLocationStyles()['linkView']}>
                <AVText
                  style={[getCampusLocationStyles()['linkLocationBtnStyle']]}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                >
                  {Localized.Buttons.manually_link_new_location}
                </AVText>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </BaseScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  buttonView: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: Styles.Heights.h5,
    marginTop: Styles.Spacing.m1,
    marginHorizontal: Styles.Spacing.m3,
  },
  buttonContainer: {
    minHeight: Styles.Heights.touchTargetHeight1,
  },
  buttonContainerMargin: {
    marginTop: Styles.Spacing.m3,
  },
  buttonText: {
    fontSize: Styles.Fonts.f1,
    fontWeight: '700',
    color: Styles.primaryColor,
    textDecorationLine: 'underline',
    fontFamily: Settings.isRefive()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
  },
});

export default withForwardedNavigationParams()(
  withIsConnected(CampusLocationsScreen),
);
