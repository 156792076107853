import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

function ErrorGettingSnackIcon(props) {
  return (
    <Svg width={61} height={60} viewBox="0 0 61 60" fill="none" {...props}>
      <G clipPath="url(#clip0_9071_23386)">
        <Path
          d="M30.5 3.75a26.25 26.25 0 110 52.5 26.25 26.25 0 010-52.5zm0 56.25a30 30 0 100-60 30 30 0 000 60zm0-45a1.88 1.88 0 00-1.875 1.875v15A1.88 1.88 0 0030.5 33.75a1.88 1.88 0 001.875-1.875v-15A1.88 1.88 0 0030.5 15zm2.813 26.25a2.812 2.812 0 10-5.625 0 2.812 2.812 0 005.625 0z"
          fill="#D7263D"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_9071_23386">
          <Path fill="#fff" transform="translate(.5)" d="M0 0H60V60H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default ErrorGettingSnackIcon;
