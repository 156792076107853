import RecentOrder from 'src/components/elements/orderAhead/RecentOrder';
import Localized from 'src/constants/AppStrings';
import {RecentOrderItemType, RecentOrderType} from 'src/types/RecentOrders';
import {Text, View, StyleSheet} from 'react-native';
import Styles from 'src/components/Styles';
import RecentComboIcon from 'src/components/img/svg/RecentComboIcon';

export function getRecentOrderListItem(
  item: RecentOrderType,
  addItem: (item: RecentOrderItemType) => void,
  addAllItems: (order: RecentOrderType) => void,
  onProductCellPressed: (item: RecentOrderItemType) => void,
) {
  return (
    <RecentOrder
      recentOrder={item}
      strings={Localized}
      addItem={addItem}
      addAllItems={addAllItems}
      productCellPressed={onProductCellPressed}
    />
  );
}

export function getEmptyList() {
  return (
    <View style={{justifyContent: 'center', flex: 1}}>
      <Text
        style={styles.emptyText}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
      >
        {Localized.Labels.no_recent_orders}
      </Text>
      <View style={{alignSelf: 'center'}}>
        <RecentComboIcon />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  emptyText: {
    textAlign: 'center',
    color: Styles.darkColor,
    marginTop: Styles.Spacing.m3,
    fontSize: Styles.Fonts.f10,
    fontWeight: 'bold',
    marginBottom: Styles.Spacing.m3,
  },
});
export default {
  getRecentOrderListItem,
  getEmptyList,
};
