import React, {useState, useEffect, useRef} from 'react';
import {Text, StyleSheet, Animated, View} from 'react-native';
import Styles from 'src/components/Styles';
import {getToastDescriber} from 'src/components/elements/toastDescriptor/DescroiptorType';
type SnackbarProps = {
  message: string;
  actionText?: string;
  onActionPress?: () => void;
  duration: number; // Default duration in milliseconds
  position?: 'top' | 'bottom' | 'center';
  backgroundColor: string;
  textColor: string;
  actionTextColor?: string;
  onDismiss: () => void;
};
const Snackbar = (props: SnackbarProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const fadeAnim = useRef(new Animated.Value(1)).current;

  React.useEffect(() => {
    if (isVisible) {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: props.duration,
        useNativeDriver: true,
      }).start();
    }
  }, [isVisible, fadeAnim]);

  useEffect(() => {
    if (isVisible) {
      const timeout = setTimeout(() => {
        setIsVisible(false);
        props.onDismiss();
      }, props.duration);

      return () => clearTimeout(timeout);
    }
  }, [isVisible, props.duration]);

  return isVisible ? (
    <Animated.View
      style={{
        opacity: fadeAnim,
      }}
    >
      <View style={styles.messageView}>
        <Text
          style={[
            styles.messageText,
            {backgroundColor: props.backgroundColor},
            {color: props.textColor},
            {paddingHorizontal: 28, paddingVertical: 12},
          ]}
        >
          {props.message}
        </Text>
      </View>
    </Animated.View>
  ) : null;
};

const styles = StyleSheet.create({
  messageText: {
    fontSize: Styles.Fonts.f1,
    borderRadius: 4,
    borderColor: getToastDescriber().snackBarColorDescriptor(),
    color: Styles.darkColor,
    fontWeight: '700',
    fontFamily: getToastDescriber().snackBarFontFamily(),
    borderWidth: 2,
    textAlign: 'center',
    opacity: 9999999,
  },
  messageView: {
    alignSelf: 'center',
    backgroundColor: Styles.white,
    bottom: 15,
    zIndex: 4,
    position: 'absolute',
  },
});

export default Snackbar;
