import DefaultUIManager from './DefaultUIManager';
import CanteenUIManager from './CanteenUIManager';
import PantryUIManager from './PantryUIManager';
import MarketUIManager from './MarketUIManager';
import Settings from 'src/Settings';
import BuildTypes from 'src/constants/BuildTypeConstants';
let UIManager: DefaultUIManager | CanteenUIManager | PantryUIManager;

if (Settings.buildType === BuildTypes.canteen) {
  UIManager = new CanteenUIManager();
} else if (Settings.buildType === BuildTypes.pantry) {
  UIManager = new PantryUIManager();
} else if (Settings.buildType === BuildTypes.market) {
  UIManager = new MarketUIManager();
} else {
  UIManager = new DefaultUIManager();
}

export default UIManager;
