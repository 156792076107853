import moment from 'moment';
import Localized from 'src/constants/AppStrings';
export function getUtcOffset() {
  const date = new Date();
  return (date.getTimezoneOffset() / 60) * -1;
}
export function getCurrentServerDate(format = 'YYYY-MM-DDTHH:mm:ss') {
  return moment().format(format);
}
export function getCurrentTime() {
  return moment().calendar();
}
export function getCurrentGMTTime() {
  return moment().utc().toJSON();
}
export function formatDate(
  epochDate: moment.Moment,
  format = 'MM/DD/YY',
): string {
  return moment(epochDate).format(format);
}

export function getTime(date?: string) {
  return moment(date).format('hh:mm a');
}

export function getTimeWithAMorPM(date?: string) {
  return moment(date).format('hh:mm A');
}

export function formatInboxDate(date?: string) {
  return moment(date).calendar(null, {
    sameDay: 'LT',
    lastDay: `[${Localized.Labels.yesterday}]`,
    lastWeek: 'dddd',
  });
}

export function isDateGreaterThanToday(dateString: string) {
  const today = moment().format('YYYY-MM-DD');
  const date = moment(dateString).format('YYYY-MM-DD');
  return date > today;
}

export function isDateSmallerThanToday(dateString: string) {
  const today = moment().format('YYYY-MM-DD');
  const date = moment(dateString).format('YYYY-MM-DD');
  return date < today;
}

export default {
  getUtcOffset,
  getCurrentTime,
  getCurrentGMTTime,
  formatDate,
  getTime,
  getTimeWithAMorPM,
  formatInboxDate,
  getCurrentServerDate,
  isDateGreaterThanToday,
  isDateSmallerThanToday,
};
