import * as React from 'react';
import moment from 'moment';
import ActionsFactory from 'src/actions/ActionsFactory';
import Settings from 'src/Settings';
import AppRoutes from 'src/AppRoutes';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import type {ScreenProps} from 'src/types/Screen';
import type {SetupModel} from 'src/types/SetupModel';
import Localized from 'src/constants/AppStrings';
import {alertError, alertSuccess} from '../../helpers/AlertHelper';
import PaymentCredentialsBuilder from 'src/models/PaymentCredentials';
import AppEventTypes from 'src/constants/AppEventTypes';
import Util from 'src/Util';
import uuid from 'src/nativeModules/UUID';
import {PaymentCredentials} from '../../../types/serverTypes/Account';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type CreateAccountHandlerProps = {
  context: ScreenProps;
};

class CreateAccountHandler extends React.Component<CreateAccountHandlerProps> {
  startTime: moment.Moment;

  constructor(props: CreateAccountHandlerProps) {
    super(props);
    this.startTime = moment();
    this.displayAccountSetupError = this.displayAccountSetupError.bind(this);
  }

  async handleCreateAccount(
    account: SetupModel,
    email: string,
    pinRequired: boolean,
  ) {
    let response;
    try {
      const {context} = this.props;
      const hasPayrollImportId = !!account?.payrollImportId;
      context.actions.showSpinner();
      response = (await ActionsFactory.getAccountActions().setupAccount(
        account,
        email,
        pinRequired,
      )) as {accountId: string};
      Events.AccountCreation.trackEvent(this.startTime, '', 'EMAIL_VERIFIED');
      const accountId = account.accountId || response.accountId;
      this.createAccountEvent(accountId);

      // Determine if they can add a credit card
      const processor =
        (await ActionsFactory.getAccountActions().getProcessorCreds(
          accountId,
          hasPayrollImportId,
        )) as PaymentCredentials;

      Logger.Log.LogAPIEvent(
        'AppAPI',
        'GetPaymentCredentials',
        JSON.stringify({accountId}),
        JSON.stringify(processor),
      );
      if (processor?.type === Settings.processorsAsString.heartland) {
        NavActions.replace(AppRoutes.CreditCardAdd, {
          username: email,
          accountId,
          startTime: this.startTime,
          paymentCredentials: await PaymentCredentialsBuilder.build(processor),
          hasPayrollImportId: !!account?.payrollImportId,
        });
      } else {
        alertSuccess(Localized.Success.create_account_success, () =>
          NavActions.reset(AppRoutes.Welcome),
        );
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'CreateAccountHandler:HandleCreateAccount',
        generateErrorMessage(error),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'CreateAccountHandler:HandleCreateAccount',
        generateErrorMessage(error),
      );
      this.displayAccountSetupError(error as string);
    } finally {
      this.props.context.actions.hideSpinner();
    }
  }
  async createAccountEvent(accountId: string) {
    try {
      await ActionsFactory.getAccountActions().updateAppEvent(
        accountId,
        AppEventTypes.AccountCreated,
        Util.getCurrentUTCDate(),
        Util.getCurrentDate(),
        moment.tz.guess(),
        Util.getAppOsName(),
      );
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'AccountCreationScreen:UpdateAccountCreationEvent',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountCreationScreen:UpdateAccountCreationEvent',
        generateErrorMessage(error),
        guid,
      );
    }
  }

  displayAccountSetupError(error: {message: string} | string) {
    let message = Localized.Errors.problem_creating_account;

    if (
      (typeof error === 'string' &&
        error.includes('specified already exists')) ||
      (typeof error !== 'string' &&
        typeof error?.message === 'string' &&
        error.message.includes('specified already exists'))
    ) {
      message += ` ${Localized.Errors.email_already_exists}`;
    } else if (typeof error === 'string' && error.includes('characters long')) {
      message += ` ${Localized.Errors.password_not_long_enough}`;
    } else {
      message += ` ${Localized.Errors.check_internet_connection}`;
    }

    if (!message.endsWith('.')) {
      message += '.';
    }

    alertError(message);
  }

  render() {
    return null;
  }
}

export default CreateAccountHandler;
