import React from 'react';
import {StyleSheet, View, Text, StyleProp, ViewStyle} from 'react-native';
import Styles from '../../Styles';
import {IsConnectedProps} from 'src/types/Screen';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import Localized from 'src/constants/AppStrings';
import Settings from 'src/Settings';
import {getDescriber} from '../../../components/elements/descriptor/DescriptorType';

//         textStyle={getDescriber().walletScreenCardStyles()['txtInput']}
type HomeScreenTitlesProps = IsConnectedProps & {
  label: string;
  onViewAll?: () => void;
  showViewAll?: boolean;
  style?: StyleProp<ViewStyle>;
};

const HomeScreenTitles = (props: HomeScreenTitlesProps) => {
  return (
    <View style={[styles.header, props.style]}>
      <Text style={getDescriber().homePageTitlesScreenStyles()['title']}>
        {props.label}
      </Text>
      {props.showViewAll && Settings.isRefiveAnd365Pay() && (
        <AVTouchableOpacity
          onPress={props.onViewAll}
          style={{
            justifyContent: 'center',
          }}
        >
          <Text style={getDescriber().homePageTitlesScreenStyles()['viewAll']}>
            {Localized.Labels.view_all}
          </Text>
          <View style={styles.textUnderline} />
        </AVTouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: Styles.Spacing.m3,
    marginLeft: Styles.Spacing.m3,
    marginRight: Styles.Heights.h2,
    marginTop: -Styles.Spacing.m1,
  },
  title: {
    fontSize: Styles.Fonts.headerFont,
    fontWeight: '700',
    color: Styles.blackBase01,
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  viewAll: {
    color: Styles.blackBase01,
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  textUnderline: {
    borderBottomWidth: 2,
    borderBottomColor: Styles.blackBase01,
  },
});
export default HomeScreenTitles;
