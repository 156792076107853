import React, {useState, useEffect, useContext, useRef} from 'react';
import {View, StyleSheet, FlatList, Dimensions} from 'react-native';
import {NavigationProp} from '@react-navigation/native';
import type {LocationType} from 'src/types/Location';
import type {TimeSlotType} from 'src/types/Menu';
import BackSubheader from '../../elements/BackSubheader';
import Localized from 'src/constants/AppStrings';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import ScreenContext from '../../ScreenContext';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import Styles from 'src/components/Styles';
import moment from 'moment';
import TransactionStore from 'src/stores/TransactionStore';
import NavActions from 'src/actions/NavActions';
import {confirm, alertError} from '../../helpers/AlertHelper';
import PickupLocationList from 'src/components/elements/orderAhead/PickupLocationList';
import AVText from 'src/components/elements/AVText';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import ErrorIcon from 'src/components/img/svg/ErrorIcon';
import TransactionActions from 'src/actions/TransactionActions';
import AppRoutes from 'src/AppRoutes';
import MenuActions from 'src/actions/MenuActions';
import uuid from 'src/nativeModules/UUID';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import {handleAvailableTimes, handleClearCart} from './orderAheadUtils';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {getDescriber} from './descriptor/DescriptorType';

type NewPickupLocationScreenProps = {
  navigation: NavigationProp<NewPickupLocationScreenProps>;
  location: LocationType;
  availableTimes: Array<TimeSlotType>;
  autoAdvanced: boolean;
  fromCart?: boolean;
};

const {
  getPickupLocationStyles,
  getInactiveButtonColor,
  getActiveButtonColor,
  locationErrorMessage,
} = getDescriber();

const NewPickupLocationScreen: React.FC<NewPickupLocationScreenProps> = (
  props,
) => {
  const context = useContext(ScreenContext);
  const previousRoute = useRef<string | null>(null);
  const sessionStartTime = useRef<moment.Moment | null>(null);
  const {pickupLocations} = props.location;
  const [selectedPickupLocation, setSelectedPickupLocation] = useState(
    pickupLocations.length === 1 ? pickupLocations[0] : null,
  );
  const [errorText, setErrorText] = useState(false);

  useEffect(() => {
    FirebaseAnalytic.trackEvent('useEffect', 'NewPickupLocationScreen', {
      ...props,
      previousRoute,
    });

    const routeName = getPreviousRouteName(
      props.navigation?.getState()?.routes,
    );
    previousRoute.current = routeName;
    if (!props.fromCart) {
      sessionStartTime.current = moment();
    }
  }, [props.navigation, props.fromCart]);

  /** navigate to previous screen */
  const onBackSelect = () => {
    if (!props.fromCart) {
      const displayItems = TransactionStore.getDisplayItems();
      FirebaseAnalytic.trackEvent('onBackSelect', 'PickupLocationScreen', {
        ...props,
        displayItems,
      });
      handleClearCart(displayItems, sessionStartTime.current, confirm);
    } else {
      NavActions.pop();
    }
  };
  /** fn: continue button press */
  const onContinuePress = async () => {
    FirebaseAnalytic.trackEvent('onContinuePress', 'NewPickupLocationScreen', {
      props,
      selectedPickupLocation,
    });
    if (selectedPickupLocation) {
      setErrorText(false);
      TransactionActions.pickupLocationUpdated(selectedPickupLocation);
      if (props.fromCart) {
        navigateAway();
        return;
      }
      try {
        context.actions.showSpinner();
        const availableTimesTimeSlots = await MenuActions.getAvailableTimeSlots(
          props.location,
          selectedPickupLocation?.pickupLocationId,
        );
        FirebaseAnalytic.trackEvent(
          'availableTimes',
          'NewPickupLocationScreen',
          {
            ...props,
            selectedPickupLocation,
            availableTimesTimeSlots,
          },
        );
        handleAvailableTimes(
          availableTimesTimeSlots,
          props.location,
          props.autoAdvanced,
          navigateAway,
          alertError,
        );
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'NewPickupLocationScreen:onContinuePress',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'NewPickupLocationScreen:onContinuePress',
          generateErrorMessage(error),
          guid,
        );
        alertError(Localized.Errors.order_ahead_unavailable, guid);
      } finally {
        context.actions.hideSpinner();
      }
    } else {
      setErrorText(true);
    }
  };
  /** fn: navigate to NewPickupTime or previous screen */
  const navigateAway = (availableTimes?: Array<TimeSlotType>) => {
    FirebaseAnalytic.trackEvent('navigateAway', 'NewPickupLocationScreen', {
      props,
      availableTimes: availableTimes,
    });
    if (props.fromCart) {
      NavActions.pop();
    } else {
      NavActions.push(AppRoutes.NewPickupTime, {
        ...props,
        availableTimes,
        selectedPickupLocation,
      });
    }
  };

  /** update selected pick-up location state */
  const setPickupLocation = (pickupLocation) => {
    setSelectedPickupLocation(pickupLocation);
  };
  /** render pick-up location */
  const renderPickupLocations = ({item, index}) => {
    return (
      <PickupLocationList
        item={item}
        index={index}
        selectPickupLocation={(selectedLocation) =>
          setPickupLocation(selectedLocation)
        }
        selectedValue={
          item.pickupLocationId === selectedPickupLocation?.pickupLocationId
        }
      />
    );
  };
  const headerComponent = () => {
    return (
      <View style={styles.headerView}>
        <AVText
          style={getPickupLocationStyles().headerText}
          accessible={true}
          testID="headerTitleText"
          accessibilityRole={'text'}
          accessibilityLabel={Localized.Labels.select_a_location_below}
          aria-label={Localized.Labels.select_a_location_below}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
        >
          {Localized.Labels.select_a_location_below}:
        </AVText>
        {errorText && !selectedPickupLocation && (
          <View style={getPickupLocationStyles().errorContainer}>
            <ErrorIcon />
            <AVText
              style={getPickupLocationStyles().errorText}
              accessible={true}
              testID="errorText"
              accessibilityRole={'text'}
              accessibilityLabel={locationErrorMessage()}
              aria-label={locationErrorMessage()}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
            >
              {locationErrorMessage()}
            </AVText>
          </View>
        )}
      </View>
    );
  };
  const buttonLabel = props.fromCart
    ? Localized.Buttons.continue_to_checkout
    : Localized.Buttons.continue_to_pickup_time;
  return (
    <BackSubheader
      title={Localized.Labels.pickup_location}
      previousRoute={previousRoute.current}
      accessibilityLabel={'Back arrow'}
      accessibilityHint={`Press to navigate back to the ${previousRoute.current} screen`}
      onBackSelect={onBackSelect}
      pop={false}
    >
      <View style={styles.container}>
        <FlatList
          data={pickupLocations}
          renderItem={renderPickupLocations}
          ListHeaderComponent={headerComponent}
          ListFooterComponent={<View style={styles.footer} />}
        />
      </View>
      <View style={getPickupLocationStyles().buttonContainerStyle}>
        <RoundedButton
          buttonType={ButtonType.normal}
          color={
            selectedPickupLocation === null
              ? getInactiveButtonColor()
              : getActiveButtonColor()
          }
          containerStyle={styles.button}
          accessibilityLabel={buttonLabel}
          onPress={onContinuePress}
          text={buttonLabel}
          accessibilityHint={'button'}
          textStyle={
            getPickupLocationStyles(selectedPickupLocation === null).buttonText
          }
        />
      </View>
    </BackSubheader>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Styles.Spacing.m3,
  },
  button: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
  },
  footer: {
    height: Dimensions.get('screen').width / 4,
  },
  headerView: {
    marginTop: Styles.Heights.h2 + Styles.Spacing.m1,
  },
});

export default withForwardedNavigationParams<NewPickupLocationScreenProps>()(
  NewPickupLocationScreen,
);
