import RedesignDescriptor from 'src/components/elements/orderAhead/descriptor/orderAhead-components-redesign-descriptor';
import ConnectPaydescriptor from 'src/components/elements/orderAhead/descriptor/orderAhead-components-connectpay-descriptor';
import CKdescriptor from 'src/components/elements/orderAhead/descriptor/orderAhead-components-companykitchen-descriptor';
import Refivedescriptor from 'src/components/elements/orderAhead/descriptor/orderAhead-components-refive-descriptor';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import {StyleProp, TextStyle, ViewStyle} from 'react-native';
import type {TimeSlotType} from 'src/types/Menu';

const typeDescriber = {
  [BuildTypes.canteen]: ConnectPaydescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.companykitchen]: CKdescriptor,
  [BuildTypes.refive]: Refivedescriptor,
};

export interface CustomTilesButtonStylesDescriptor {
  tileConatainer: StyleProp<ViewStyle>;
  text: StyleProp<TextStyle>;
  selectedText: StyleProp<TextStyle>;
  selectedView: StyleProp<ViewStyle>;
}
export interface PickupLocationListStylesDescriptor {
  cell: StyleProp<ViewStyle>;
  cellSelected: StyleProp<ViewStyle>;
  label: StyleProp<TextStyle>;
  selectedLabel: StyleProp<TextStyle>;
}
export interface ProductModifierStylesDescriptor {
  customizationDivider?: StyleProp<ViewStyle>;
  productModifierContainer?: StyleProp<ViewStyle>;
  radioButtonWrapper?: StyleProp<ViewStyle>;
  checkboxRow?: StyleProp<ViewStyle>;
  checkboxNameContainer?: StyleProp<ViewStyle>;
  checkboxPriceContainer?: StyleProp<ViewStyle>;
  checkboxCalorieContainer?: StyleProp<ViewStyle>;
  requiredViewStyles?: StyleProp<ViewStyle>;
  checkBoxWrapper?: StyleProp<ViewStyle>;
  options?: StyleProp<ViewStyle>;
  titleRow?: StyleProp<ViewStyle>;
  titleText?: StyleProp<TextStyle>;
  requiredText?: StyleProp<TextStyle>;
  totalCaloriesLabel?: StyleProp<TextStyle>;
  sectionCalStyles?: StyleProp<TextStyle>;
  optionText?: StyleProp<TextStyle>;
  checkBoxText?: StyleProp<TextStyle>;
  helperText?: StyleProp<TextStyle>;
}
export interface ProductModifierRadioButton {
  label?: string;
  price?: string;
  value?: string;
  calories?: number;
}

export interface RecentOrdersButtonStylesDescriptor {
  marginStyle?: StyleProp<ViewStyle>;
  recentOrderText?: StyleProp<TextStyle>;
}

export type CheckoutBarProps = {
  numItems: number;
  onPress: () => void;
  displayLocation: string;
  pickupTime?: TimeSlotType;
  label?: string;
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
