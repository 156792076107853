import React from 'react';
import {Modal, Platform, View, StyleSheet, Dimensions} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import Localized from 'src/constants/AppStrings';
import BackSubheader from '../../elements/BackSubheader';
import Styles from '../../Styles';
import CollapsibleComponent from '../../elements/CollapsibleComponent';
import SupportEmailService from 'src/services/SupportEmailService';
import UIManager from '../../elements/ui/UIManager';
import Settings from 'src/Settings';
import AVText from '../../elements/AVText';

import {getButtonDescriber} from 'src/components/elements/buttonDescriptor/DescriptorType';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {getDescriber as getSnackDescriber} from 'src/components/screens/sendSnack/descriptor/sendasnack/DescriptorType';

import {getDescriber} from './descriptor/DescriptorType';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import CancelIcon from 'src/components/img/svg/CancelIcon';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
type HelpProps = {
  navigation?: NavigationProp<HelpScreen>;
};

type HelpState = {
  previousRoute?: string | null;
  showErrModal?: boolean;
  modalHeight?: number;
};
const screenWidth = Dimensions.get('window').width;
const multipler = Platform.OS === 'web' ? 0.3 : 0.92;
const modalWidth = screenWidth * multipler;
class HelpScreen extends React.Component<HelpProps, HelpState> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: HelpProps) {
    super(props);
    this.state = {
      previousRoute: null,
      showErrModal: false,
      modalHeight: 0,
    };
    this.handleContactClick = this.handleContactClick.bind(this);
    this.renderErrModal = this.renderErrModal.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  handleLayout = (event) => {
    const {height} = event.nativeEvent.layout; // Get the height of the modal
    this.setState({modalHeight: height}); // Update state with height
  };
  hideModal = () => {
    this.setState({
      showErrModal: false,
    });
  };

  renderErrModal = () => {
    const {getHelpStyleDescriptor} = getDescriber();
    return (
      <Modal visible={true} onDismiss={() => this.hideModal} transparent={true}>
        <View style={styles.ModalMain}>
          <View
            style={[
              styles.ModalContainer,
              //{height: this.state.modalHeight + 100},
            ]}
          >
            <AVTouchableOpacity
              aria-label={`X icon, Double tap to ${Localized.Buttons.cancel} offer details`}
              role="button"
              style={styles.closeModalTitleContainer}
              onPress={this.hideModal}
              accessible
              accessibilityLabel={Localized.Buttons.cancel}
              accessibilityRole="button"
            >
              <CancelIcon />
            </AVTouchableOpacity>
            <View
              style={styles.ModalContentContainer}
              onLayout={this.handleLayout}
            >
              <AVText
                aria-label={`${Localized.Success.delete_Acnt} label, text`}
                accessibilityLabel={`${Localized.Success.delete_Acnt} label`}
                style={[
                  getHelpStyleDescriptor() &&
                    getHelpStyleDescriptor()['helpErrTitle'],
                  getSnackDescriber().snackDetailsBtnFontSTyle(),
                ]}
                accessible={true}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                {Localized.Labels.err_send_email}
              </AVText>
              <AVText
                accessible={true}
                accessibilityLabel={`${Localized.Success.delete_acnt_desc} label`}
                aria-label={`${Localized.Success.delete_acnt_desc} label, text`}
                style={[
                  getHelpStyleDescriptor() &&
                    getHelpStyleDescriptor()['helpErrDesc'],
                  getSnackDescriber().snackDetailsBtnFontSTyle(),
                ]}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                {Localized.Labels.err_send_desc}
              </AVText>
            </View>

            <View>
              <RoundedAppButton
                buttonType={RoundedAppButtonType.Solid}
                onPress={() => this.hideModal()}
                buttonViewStyle={{
                  overflow: 'hidden',
                  borderWidth: 2,
                  borderRadius: 32,
                  backgroundColor: getButtonDescriber().checkRoundedBtnColor(),
                  borderTopColor: getButtonDescriber().checkRoundedBtnColor(),
                  borderBottomColor:
                    getButtonDescriber().checkRoundedBtnColor(),
                  borderLeftColor: getButtonDescriber().checkRoundedBtnColor(),
                  borderRightColor: getButtonDescriber().checkRoundedBtnColor(),
                  borderColor: getButtonDescriber().checkRoundedBtnColor(),
                  width: 100,
                  height: 42,
                }}
                buttonContainerStyle={{
                  alignSelf: 'center',
                  marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
                }}
                accessibilityLabelValue={`${Localized.Buttons.okay}`}
                titleText={Localized.Buttons.okay}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                textStyle={
                  getHelpStyleDescriptor() && getHelpStyleDescriptor()['errBtn']
                }
              />
            </View>
          </View>
        </View>
      </Modal>
    );
  };
  handleContactClick() {
    const appname =
      Platform.OS === 'web'
        ? `${Settings.webAppDisplayName}`
        : `${Settings.appDisplayName} App`;
    const subject = `${appname} Help Inquiry`;
    const body = `${Localized.Labels.help_details_prompt}`;

    FirebaseAnalytic.trackEvent('handleContactClick', 'HelpScreen', {
      subject,
      body,
    });

    SupportEmailService.createEmail(
      subject,
      body,
      (email: string, guid: string | null | undefined) => {
        this.setState({
          showErrModal: true,
        });
      },
    );
  }

  render() {
    const {getHelpStyleDescriptor} = getDescriber();
    const faqs: {
      question: any;
      answer: any;
    }[] = [];
    if (Settings.buildType !== 'canteen') {
      faqs.push(
        {
          question: Localized.Labels.formatString(
            Localized.Labels.faq_market_compatible,
            Settings.appDisplayName,
          ),
          answer: Localized.Labels.formatString(
            Localized.Labels.faq_market_compatible_answer,
            Settings.appDisplayName,
          ),
        },
        {
          question: Localized.Labels.faq_auto_reload,
          answer: Platform.select({
            web: Localized.Labels.faq_auto_reload_answer_web,
            default: Localized.Labels.faq_auto_reload_answer,
          }),
        },
        {
          question: Localized.Labels.faq_no_notifications,
          answer: Platform.select({
            web: Localized.Labels.faq_no_notifications_answer_web,
            default: Localized.Labels.formatString(
              Localized.Labels.faq_no_notifications_answer,
              Settings.appDisplayName,
            ),
          }),
        },
        {
          question: Localized.Labels.faq_add_order_ahead_location,
          answer: Localized.Labels.faq_add_order_ahead_location_answer,
        },
        {
          question: Localized.Labels.faq_order_ahead_checkout,
          answer: Localized.Labels.faq_order_ahead_checkout_answer,
        },
        {
          question: Localized.Labels.faq_remove_items,
          answer: Localized.Labels.faq_remove_items_answer,
        },
        {
          question: Localized.Labels.faq_payment,
          answer: Localized.Labels.faq_payment_answer,
        },
      );
    }
    if (Settings.buildType === 'canteen' && Settings.isNewUI()) {
      faqs.push(
        {
          question: Localized.Labels.how_request_refund,
          answer: Localized.Labels.how_request_refund_answer_canteen,
        },
        {
          question: Localized.Labels.faq_change_location_card,
          answer: Localized.Labels.faq_change_location_card_answer,
        },
        {
          question: Localized.Labels.faq_auto_reload,
          answer: Localized.Labels.faq_auto_reload_answer_canteen,
        },
        {
          question: Localized.Labels.faq_scan_market_card,
          answer: Localized.Labels.faq_scan_market_card_answer,
        },
        {
          question: Localized.Labels.faq_report_issue,
          answer: Localized.Labels.faq_report_issue_answer,
        },
        {
          question: Localized.Labels.faq_reward_points,
          answer: Localized.Labels.faq_reward_points_answer,
        },
        {
          question: Localized.Labels.faq_no_notifications,
          answer: Localized.Labels.faq_no_notifications_answer_canteen,
        },
      );
    }

    if (UIManager.showAllFAQs() && Settings.buildType !== 'canteen') {
      faqs.push(
        {
          question: Localized.Labels.faq_diff_balance,
          answer: Localized.Labels.faq_diff_balance_answer,
        },
        {
          question: Localized.Labels.faq_fingerprints,
          answer: Localized.Labels.faq_fingerprints_answer,
        },
        {
          question: Localized.Labels.faq_link_market_card,
          answer: Localized.Labels.formatString(
            Localized.Labels.faq_link_market_card_answer,
            Settings.appDisplayName,
          ),
        },
        {
          question: Localized.Labels.faq_product_issue,
          answer: Localized.Labels.faq_product_issue_answer,
        },
        {
          question: Localized.Labels.faq_reward_points,
          answer: Localized.Labels.faq_reward_points_answer,
        },
      );
    }

    if (Platform.OS !== 'web' && Settings.buildType !== 'canteen') {
      faqs.push(
        {
          question: Localized.Labels.faq_bad_scan,
          answer: Localized.Labels.formatString(
            Localized.Labels.faq_bad_scan_answer,
            Settings.appDisplayName,
          ),
        },
        {
          question: Localized.Labels.faq_no_locations,
          answer: Localized.Labels.faq_no_locations_answer,
        },
      );
    }

    if (Settings.is365Pay()) {
      faqs.splice(0, faqs.length);
      faqs.push(
        {
          question: Localized.Labels.faq_how_request_refund,
          answer: Localized.Labels.faq_how_request_refund_answer,
        },
        {
          question: Localized.Labels.faq_change_location_card,
          answer: Localized.Labels.redesign_change_location_ans,
        },
        {
          question: Localized.Labels.faq_auto_reload,
          answer: Localized.Labels.redesign_auto_reload_desc,
        },

        {
          question: Localized.Labels.faq_report_issue,
          answer: Localized.Labels.faq_report_issue_answer,
        },
        {
          question: Localized.Labels.faq_reward_point,
          answer: Localized.Labels.faq_reward_point_answer,
        },
        {
          question: Localized.Labels.faq_notification,
          answer: Localized.Labels.faq_notification_answer,
        },
      );
    }
    if (Settings.isRefive()) {
      faqs.splice(0, faqs.length);
      faqs.push(
        {
          question: Localized.Labels.faq_how_request_refund,
          answer: Localized.Labels.faq_how_request_refund_answer_refive,
        },
        {
          question: Localized.Labels.faq_change_location_card,
          answer: Localized.Labels.redesign_change_location_ans,
        },
        {
          question: Localized.Labels.faq_auto_reload,
          answer: Localized.Labels.redesign_auto_reload_desc,
        },

        {
          question: Localized.Labels.faq_report_issue,
          answer: Localized.Labels.faq_report_issue_answer,
        },
        {
          question: Localized.Labels.faq_reward_point,
          answer: Localized.Labels.faq_reward_point_answer,
        },
        {
          question: Localized.Labels.faq_notification,
          answer: Localized.Labels.faq_notification_answer_refive,
        },
      );
    }

    const faqElements = faqs
      //.sort((a, b) => a.question.localeCompare(b.question))
      .map((faq, key) => (
        <CollapsibleComponent
          headerAccessibilityLabel={faq.question}
          contentAccessibilityLabel={faq.answer}
          header={
            <AVText
              style={
                getHelpStyleDescriptor() &&
                getHelpStyleDescriptor(Settings.isRefiveAnd365Pay())['question']
              }
            >
              {faq.question}
            </AVText>
          }
          content={
            <AVText
              accessible={true}
              accessibilityLabel={faq.answer}
              style={
                getHelpStyleDescriptor() &&
                getHelpStyleDescriptor(Settings.isRefiveAnd365Pay())['answer']
              }
            >
              {faq.answer}
            </AVText>
          }
          key={key}
        />
      ));
    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        title={UIManager.getHelpLabel()}
        accessibilityLabel={'back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
      >
        <ScrollView
          style={
            Settings.isRefiveAnd365Pay() && Platform.OS !== 'web'
              ? {backgroundColor: Styles.tabBarBackgroundColor}
              : null
          }
        >
          <AVText
            style={
              getHelpStyleDescriptor() &&
              getHelpStyleDescriptor(Settings.isRefiveAnd365Pay())['header']
            }
            accessibilityRole="header"
            accessibilityLabel={Localized.Labels.faq}
          >
            {Localized.Labels.faq}
          </AVText>
          {faqElements}
          <AVText
            style={
              getHelpStyleDescriptor() &&
              getHelpStyleDescriptor(Settings.isRefiveAnd365Pay())['header']
            }
            accessibilityRole="header"
            accessibilityLabel={Localized.Labels.contact_us}
          >
            {Localized.Labels.contact_us}
          </AVText>
          <AVText
            style={[
              getHelpStyleDescriptor() &&
                getHelpStyleDescriptor(Settings.isRefiveAnd365Pay())['text'],
              Settings.isRefiveAnd365Pay()
                ? {
                    fontFamily: Styles.FontFamily.aeonikRegular,
                    fontSize: 16,
                    fontWeight: '400',
                    color: '#111',
                  }
                : null,
              getSnackDescriber().snackDetailsBtnFontSTyle(),
            ]}
            onPress={this.handleContactClick}
            accessibilityRole="link"
            accessibilityHint="Double tap to send a message from your email client"
            accessible={true}
          >
            {Localized.Labels.formatString(
              Localized.Labels.contact_us_answer,
              <AVText
                style={[
                  getHelpStyleDescriptor() && getHelpStyleDescriptor()['link'],
                  Settings.isRefiveAnd365Pay()
                    ? {
                        fontFamily: Styles.FontFamily.aeonikRegular,
                        fontSize: 16,
                        fontWeight: '400',
                        color: '#066dff',
                      }
                    : null,
                  getSnackDescriber().snackDetailsBtnFontSTyle(),
                ]}
              >
                {Settings.supportEmail}
              </AVText>,
            )}
          </AVText>
        </ScrollView>
        <>{this.state.showErrModal && this.renderErrModal()}</>
      </BackSubheader>
    );
  }
}
const styles = StyleSheet.create({
  ModalMain: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  ModalContainer: {
    minHeight: Platform.OS == 'ios' ? 270 : 280,
    width: modalWidth,
    backgroundColor: Styles.white,
    borderRadius: Styles.Spacing.m3,
    paddingHorizontal: 25,
  },
  closeModalTitleContainer: {
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 15,
    top: 10,
    zIndex: 999999,
  },
  ModalContentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
});
export default withForwardedNavigationParams<HelpProps>()(HelpScreen);
