import * as React from 'react';
import Svg, {Path, Mask} from 'react-native-svg';

interface IProps {
  color?: string;
  size?: number;
}
const EditIcon = ({size = 16, color = '#244EC2'}: IProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      fill={color}
      clipRule="evenodd"
      fillRule="evenodd"
      d="M14.42 3.012 12.988 1.58c-.348-.35-.939-.325-1.318.055L10.325 2.98l2.695 2.696 1.345-1.346c.38-.38.404-.97.055-1.318ZM5.232 13.463l-2.695-2.695 7.45-7.45 2.696 2.695-7.45 7.45Zm-3.899 1.204.953-3.475 2.522 2.522-3.475.953Z"
    />
    <Mask width={14} height={14} x={1} y={1} id="a" maskUnits="userSpaceOnUse">
      <Path
        fill="#fff"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.42 3.012 12.988 1.58c-.348-.35-.939-.325-1.318.055L10.325 2.98l2.695 2.696 1.345-1.346c.38-.38.404-.97.055-1.318ZM5.232 13.463l-2.695-2.695 7.45-7.45 2.696 2.695-7.45 7.45Zm-3.899 1.204.953-3.475 2.522 2.522-3.475.953Z"
      />
    </Mask>
  </Svg>
);
export default EditIcon;
