import * as React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Styles from '../../Styles';
import AVText from '../AVText';
import AVTouchableOpacity from '../AVTouchableOpacity';
import {getDescriber} from '../descriptor/DescriptorType';
import Logger from 'src/logging/Logger';
import CustomToggleSwitch from 'src/components/elements/CustomToggleSwitch';
type SettingsMenuOptionProps = {
  listItems?: Array<{
    accessibilityLabel: string;
    accessibilityHint: string;
    onPressed: () => void;
    label: string;
  }>;
  switchStatus?: boolean;
  onSwitchToggle?: () => void;
  title?: string;
};

type ListItem = {
  accessibilityLabel: [];
  accessibilityHint: string;
  onPressed?: () => void;
  label?: StyleProp<ViewStyle>;
  isShareFile?: boolean;
};

const SettingsMenuOption: React.FC<SettingsMenuOptionProps> = (props) => {
  const {title, switchStatus, onSwitchToggle, listItems} = props;

  const renderSwitch = () => {
    if (switchStatus !== undefined && onSwitchToggle) {
      return (
        <CustomToggleSwitch
          accessible={true}
          value={props.switchStatus}
          accessibilityLabel={props.title}
          aria-checked={props.switchStatus}
          accessibilityState={{checked: props.switchStatus}}
          accessibilityRole="switch"
          accessibilityValue={{text: props.switchStatus?.toString()}}
          aria-label={props.title}
          aria-valuetext={props.switchStatus?.toString()}
          role="switch"
          onValueChange={() => onSwitchToggle()}
          accessibilityHint="Double tap to activate the switch"
        />
      );
    } else {
      return null;
    }
  };

  const renderSwitchToggle = () => {
    if (switchStatus !== undefined) {
      return (
        <View>
          <View
            style={[
              styles.innerRow,
              getDescriber() &&
                getDescriber().getSettingsMenusStyleDescriptor()[
                  'switchWithOptionText'
                ],
            ]}
          >
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
              accessible={true}
              accessibilityRole={'header'}
              accessibilityLabel={title}
              role="heading"
              aria-label={title}
              style={
                getDescriber() &&
                getDescriber().getSettingsMenusStyleDescriptor()['optionText']
              }
            >
              {title}
            </AVText>
            {renderSwitch()}
          </View>

          <View
            style={
              getDescriber() &&
              getDescriber().getSettingsMenusStyleDescriptor()['divider']
            }
          ></View>
        </View>
      );
    } else {
      return null;
    }
  };

  const renderSettingsMenu = (value: ListItem, index: number) => {
    return (
      <View key={index}>
        <AVTouchableOpacity
          style={[styles.optionRow]}
          accessible={true}
          accessibilityRole="button"
          accessibilityState={{disabled: false}}
          accessibilityHint={value?.accessibilityHint}
          aria-disabled={false}
          role="button"
          disabled={false}
          onPress={
            value.isShareFile
              ? () => Logger.Log.shareLogFile()
              : value.onPressed
          }
        >
          <View style={[styles.innerRow]}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
              accessibilityLabel={`${value.accessibilityLabel},`}
              aria-label={`${value.accessibilityLabel}, `}
              style={[
                getDescriber() &&
                  getDescriber().getSettingsMenusStyleDescriptor()[
                    'optionText'
                  ],
              ]}
            >
              {value.label}
            </AVText>
            <FontAwesome5Pro
              accessibilityLabel="Right arrow icon"
              aria-label="Right arrow icon"
              name="chevron-right"
              color={
                getDescriber() &&
                getDescriber().settingsMenuFontColorDescriptor()
              }
              size={Styles.Fonts.f2}
            />
          </View>
        </AVTouchableOpacity>
        <View
          style={
            index != listItems?.length - 1
              ? getDescriber() &&
                getDescriber().getSettingsMenusStyleDescriptor()['divider']
              : null
          }
        ></View>
      </View>
    );
  };

  return (
    <View
      style={[
        getDescriber() &&
          getDescriber().getSettingsMenusStyleDescriptor()[
            'enclosureContainer'
          ],
        getDescriber() &&
          getDescriber().getSettingsMenusStyleDescriptor()['listShadow'],
      ]}
    >
      {listItems != undefined &&
        listItems.map((value: any, index) => {
          return renderSettingsMenu(value, index);
        })}
    </View>
  );
};

const styles = StyleSheet.create({
  divider: {
    backgroundColor: Styles.dividerColor,
    height: 1,
  },
  optionRow: {
    justifyContent: 'center',
    paddingHorizontal: Styles.Spacing.m3,
  },
  innerRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: Styles.Spacing.m3,
  },
  enclosureContainer: {
    marginHorizontal: Styles.Spacing.m3,
    borderRadius: 10,
    borderColor: Styles.lightGray,
    borderWidth: 1,
  },
});
export default SettingsMenuOption;
