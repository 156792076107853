import React from 'react';
import {StyleSheet, Dimensions, Text} from 'react-native';
import Tooltip from './Tooltip';
import Styles from '../Styles';
import InfoBlueIconFilled from 'src/components/img/svg/infoBlueFilledIcon';

const maxWidth = Dimensions.get('screen').width * 0.75;
type InfoIconProps = {
  infoMessage: string | string[];
  color?: string;
};

const InfoIconRedesign = (props: InfoIconProps) => {
  return (
    <Tooltip
      accessibilityRole="button"
      accessible={true}
      popover={
        <Text
          accessibilityLabel={props.infoMessage.toString()}
          aria-label={props.infoMessage.toString()}
          style={styles.tooltipText}
        >
          {props.infoMessage}
        </Text>
      }
      backgroundColor={Styles.darkColor}
      width={maxWidth}
    >
      <InfoBlueIconFilled size={28} color={props.color} />
    </Tooltip>
  );
};

const styles = StyleSheet.create({
  tooltipText: {
    color: Styles.white,
    fontSize: Styles.Fonts.f0,
  },
  infoButtonContainer: {
    height: Styles.Heights.touchTargetHeight2,
    width: Styles.Heights.touchTargetHeight2,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default InfoIconRedesign;
