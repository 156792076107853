import {AppState, NativeEventSubscription} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

class AppLifecycleTracker {
  private appState: string = AppState.currentState;
  private appStateSubscription: NativeEventSubscription | null = null;

  async handleAppStateChange(nextAppState: string) {
    if (this.appState === 'active' && nextAppState === 'background') {
      await AsyncStorage.setItem('appLastState', 'background');

      const activeCart = await AsyncStorage.getItem('cartHasItems');
      if (activeCart === 'true') {
        await AsyncStorage.setItem('cartAbandoned', 'true');
      }
    }

    if (this.appState === 'background' && nextAppState === 'active') {
      await AsyncStorage.setItem('appLastState', 'active');
      await AsyncStorage.setItem('cartAbandoned', 'false');
    }

    this.appState = nextAppState;
  }

  startTracking() {
    this.stopTracking();
    this.appStateSubscription = AppState.addEventListener(
      'change',
      this.handleAppStateChange.bind(this),
    );
  }

  stopTracking() {
    if (this.appStateSubscription) {
      this.appStateSubscription.remove();
      this.appStateSubscription = null;
    }
  }

  async wasAppForceClosedWithCart() {
    const lastState = await AsyncStorage.getItem('appLastState');
    const cartAbandoned = await AsyncStorage.getItem('cartAbandoned');

    if (lastState === 'background' && cartAbandoned === 'true') {
      return true;
    }

    await AsyncStorage.removeItem('cartAbandoned');
    return false;
  }

  async updateCartState(hasItems: boolean) {
    await AsyncStorage.setItem('cartHasItems', hasItems ? 'true' : 'false');
  }

  async clearCartState() {
    await AsyncStorage.setItem('cartAbandoned', 'false');
    this.updateCartState(false);
  }
}

export default new AppLifecycleTracker();
