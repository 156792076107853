import React from 'react';
import {useGlobalize} from 'react-native-globalize';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Header from '../../../elements/Header';
import RoundedButton, {ButtonType} from '../../../elements/RoundedButton';
import Styles from '../../../Styles';
import AVText from '../../../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import {StyleSheet, ScrollView, View} from 'react-native';
import {WithLocalizedProps} from 'src/types/PropTypes';
import {getDescriber} from '../../cart/descriptor/Descriptor';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';

type BaseSummaryScreenProps = WithLocalizedProps &
  React.PropsWithChildren<
    {
      titleText: string;
      previousRoute?: string | null;
      accessibilityLabel?: string;
      accessibilityHint?: string;
      showReportBtn?: boolean;
      insideTab?: boolean;
      deviceId?: string;
    } & Partial<{
      headlineText: string;
      accessibilityLabel: string;
      returnRoute: AppRoutes;
      balance: number;
      ccDisplay: string;
      totalDue: number;
      subtotal: number;
      discount: number;
      totalAmt: string;
      taxes: Array<any>;
      deposit: number;
      items: Array<any>;
      points: number;
    }>
  >;

const BaseSummaryScreen: React.FC<BaseSummaryScreenProps> = (props) => {
  const {formatCurrency} = useGlobalize();
  const onDonePressed = () => {
    if (props.returnRoute) {
      NavActions.popToRoute(props.returnRoute);
    } else {
      NavActions.popToTop();
    }
  };
  const onFeedbackSelect = () => {
    FirebaseAnalytic.trackEvent('onFeedbackSelect', 'BaseSummaryScreen', {
      ...props,
      subject: 'Feedback',
      popToTop: true,
      insideTab: props.insideTab,
      deviceId: props.deviceId,
    });

    NavActions.replace(AppRoutes.ContactUs, {
      subject: 'Feedback',
      popToTop: true,
      insideTab: props.insideTab,
      deviceId: props.deviceId,
    });
  };

  return (
    <Header
      accessible={true}
      accessibilityLabel={props.accessibilityLabel}
      aria-label={props.accessibilityLabel}
      title={props.titleText}
    >
      <View style={styles.receipt}>
        {props.discount && props.discount > 0 && (
          <AVText style={[styles.banner, styles.promotionText]}>
            {props.strings.Labels.formatString(
              props.strings.Labels.you_saved_paying,
              formatCurrency(
                props.discount,
                AccountStore.getCurrency(),
              )?.replace(AccountStore.getCurrency().substring(0, 2), ''),
            )}
          </AVText>
        )}
        {props.points && props.points > 0 && (
          <AVText style={[styles.banner, styles.rewardText]}>
            {props.strings.Labels.formatString(
              props.strings.Labels.you_received_rewards,
              props.points,
            )}
          </AVText>
        )}
        {props.headlineText && (
          <View style={styles.header}>
            <AVText style={styles.headline}>{props.headlineText}</AVText>
          </View>
        )}
        <ScrollView style={styles.scrollView}>
          <>
            {props.children}
            <View style={styles.bottom} />
          </>
        </ScrollView>
      </View>
      <View>
        {props.showReportBtn && (
          <RoundedButton
            buttonType={getDescriber().getReportButtonType()}
            containerStyle={styles.button}
            onPress={onFeedbackSelect}
            accessible={true}
            accessibilityLabel={Localized.Buttons.report_problem}
            accessibilityHint='Double tap to go to the "Contact Us" screen'
            accessibilityRole="button"
            role="button"
            aria-label={Localized.Buttons.report_problem}
            text={Localized.Labels.report_problem}
            textStyle={{color: Styles.Colors.PayNew.primary01}}
            color={getDescriber().getReportButtonColor()}
            backgroundColor={Styles.white}
          />
        )}
        <RoundedButton
          containerStyle={{bottom: Styles.Heights.h2 + Styles.Spacing.m1}}
          buttonType={ButtonType.action}
          onPress={onDonePressed}
          accessibilityLabel="Done"
          text={props.strings.Buttons.done}
          backgroundColor={Styles.Colors.PayNew.primary01}
        />
      </View>
    </Header>
  );
};

const styles = StyleSheet.create({
  banner: {
    marginBottom: Styles.Spacing.m2,
    textAlign: 'left',
  },
  bottom: {
    height: Styles.Spacing.m5 + Styles.Spacing.m1,
    width: '100%',
  },
  header: {
    alignItems: 'center',
    alignSelf: 'stretch',
    marginVertical: Styles.Spacing.m2,
  },
  headline: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  promotionText: {
    color: Styles.primaryColor,
    fontSize: Styles.Fonts.f2,
  },
  receipt: {
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  rewardText: {
    color: Styles.positiveColor,
    fontSize: Styles.Fonts.f2,
  },
  scrollView: {
    paddingBottom: Styles.Spacing.m5,
  },
  button: {
    bottom: Styles.Heights.h2 + Styles.Spacing.m1,
    elevation: 6,
    paddingHorizontal: Styles.Spacing.m2,
    paddingVertical: 8,
    right: Styles.Spacing.m6 - 20,
    position: 'absolute',
    borderColor: Styles.Colors.PayNew.primary01,
  },
});
export default BaseSummaryScreen;
