import moment from 'moment';
import React from 'react';
import ActionsFactory from 'src/actions/ActionsFactory';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Util from 'src/Util';
import type {HistoryItem as HistoryItemType} from 'src/types/TransactionDetail';
import AccountConstants from 'src/constants/AccountConstants';
import AccountStore from 'src/stores/AccountStore';
import HistoryItem from '../../elements/account/HistoryItem';
import {FlashList} from '@shopify/flash-list';
import {Platform} from 'react-native';

export const filterTypes = {
  all: '',
  funding: AccountConstants.FUNDING_TYPE,
  sendasnack: AccountConstants.SEND_SNACK,
  orderAhead: AccountConstants.SALE_TYPE,
  purchases: AccountConstants.SALE_TYPE,
  referral: AccountConstants.REFERRAL_TYPE,
  signupRewards: AccountConstants.REFERRAL_SIGNUP_TYPE,
  marketPurchase: AccountConstants.MARKET_PURCHASES,
  mobilePurchase: AccountConstants.MOBILE_PURCHASES,
  loyaltyCredit: AccountConstants.REDEMPTION_TYPE,
};

type AccountHistoryListProps = {
  filterType: string;
};

type HistoryState = {
  isRefreshing: boolean;
  historyItems: HistoryItemType[];
  page: number;
};
export default class AccountHistoryList extends React.Component<
  AccountHistoryListProps,
  HistoryState
> {
  changeEvent = null;
  started = moment();

  constructor(props: AccountHistoryListProps) {
    super(props);
    this._onChange = this._onChange.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this._onRefresh = this._onRefresh.bind(this);

    const historyItems = AccountStore.getPurchaseHistory(this.props.filterType);
    this.state = {
      isRefreshing: false,
      historyItems,
      page: 1,
    };
  }

  componentDidUpdate(prevProps: AccountHistoryListProps) {
    if (prevProps.filterType === this.props.filterType) {
      return;
    }

    const data = AccountStore.getPurchaseHistory(this.props.filterType);
    this.setState({
      historyItems: data,
    });
  }

  componentDidMount() {
    AccountStore.clearPurchaseHistory();
    ActionsFactory.getAccountActions().loadPurchaseHistory(
      AccountStore.getAccountId(),
      this.state.page,
    );
    AccountStore.addHistoryChangedListener(this._onChange);
  }

  componentWillUnmount() {
    AccountStore.removeHistoryChangedListener(this._onChange);
  }

  static onItemPress(historyItem: HistoryItemType, inSettings: boolean) {

    if (
      [
        AccountConstants.SALE_TYPE,
        AccountConstants.FUNDING_TYPE,
        AccountConstants.REFUND_TYPE,
        AccountConstants.REDEMPTION_TYPE,
      ].indexOf(historyItem.Type) > -1
    ) {
      const _route =
        Platform.OS === 'web'
          ? AppRoutes.PurchaseHistoryDetail
          : AppRoutes.PurchaseHistoryDetailNew;
      if (inSettings) {
        NavActions.push(_route, {
          transactionTypeDisplay: historyItem.TransactionTypeDisplay,
          transactionDate: historyItem.TransDate,
          transactionType: historyItem.Type,
          transactionCurrency: historyItem.currency,
          transactionId: historyItem.Id,
        });
      } else {
        NavActions.navigate(_route, {
          transactionId: historyItem.Id,
          transactionType: historyItem.Type,
          transactionDate: historyItem.TransDate,
          transactionCurrency: historyItem.currency,
          transactionTypeDisplay: historyItem.TransactionTypeDisplay,
        });
      }
    } else if (
      [
        AccountConstants.REFERRAL_TYPE,
        AccountConstants.REFERRAL_SIGNUP_TYPE,
      ].indexOf(historyItem.Type) > -1
    ) {
      NavActions.navigate(AppRoutes.ReferralSummary, {
        historyItem,
      });
    } else if (
      [
        AccountConstants.SEND_SNACK,
        AccountConstants.SEND_SNACK_CANCEL,
        AccountConstants.SEND_SNACK_REVERSE,
        AccountConstants.SEND_SNACK_ACCEPT,
        AccountConstants.SEND_SNACK_EXPIRE,
      ].indexOf(historyItem.Type) > -1
    ) {
      NavActions.navigate(AppRoutes.SnackHistoryDetail, {
        historyItem,
      });
    }
  }

  async _onChange() {
    await Util.delayForUX(this.started);
    this.setState({
      isRefreshing: false,
      historyItems: AccountStore.getPurchaseHistory(this.props.filterType),
    });
  }

  _onRefresh(page: number) {
    this.started = moment();
    ActionsFactory.getAccountActions().loadPurchaseHistory(
      AccountStore.getAccountId(),
      page,
    );
    this.setState({
      isRefreshing: true,
      page,
    });
  }

  handleLoadMore() {
    const fullItems = AccountStore.getPurchaseHistory('');
    const lastItem = fullItems[fullItems.length - 1];

    let hasMore = false;
    if (lastItem) {
      hasMore = !lastItem.lastItem;
    }

    if (hasMore && !this.state.isRefreshing) {
      this._onRefresh(this.state.page + 1);
    }
  }

  renderItem({item, index}: {item: HistoryItemType; index: number}) {
    return (
      <HistoryItem
        {...this.props}
        historyItem={item}
        onPress={(historyItem) =>
          AccountHistoryList.onItemPress(historyItem, true)
        }
        index={index}
      />
    );
  }

  render() {
    return (
      <FlashList
        estimatedItemSize={80}
        onRefresh={() => this._onRefresh(1)}
        data={this.state.historyItems}
        refreshing={this.state.isRefreshing}
        renderItem={this.renderItem}
        onEndReached={this.handleLoadMore}
        onEndReachedThreshold={0.5}
      />
    );
  }
}
