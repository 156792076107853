import * as React from 'react';
import {AnimatedCircularProgress} from 'react-native-circular-progress';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import KeepAwake from 'react-native-keep-awake';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import Util from 'src/Util';
import Header from '../../elements/Header';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import {
  StyleSheet,
  View,
  InteractionManager,
  AppState,
  PixelRatio,
  Dimensions,
} from 'react-native';
import MachineStore from 'src/stores/MachineStore';
import AccountStore from 'src/stores/AccountStore';
import TransactionAction from 'src/actions/TransactionActions';
import PlanogramAction from 'src/actions/PlanogramActions';
import TimerMixin from 'react-timer-mixin';
import AVText from '../../elements/AVText';
import reactMixin from 'react-mixin';
import VendSession from 'src/services/TSFInsideVendSession';
import {TSFInsideEvents} from 'src/services/bluetooth/tsfInside/TSFInsideDevice';
import ActionsFactory from 'src/actions/ActionsFactory';
import SyncHelper from 'src/services/SyncService';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {getDescriber} from 'src/components/screens/vending/descriptor/Descriptor';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {compose} from 'redux';
import {connect} from 'react-redux';
import TagManager from 'src/components/utils/TagManager';
import AppLifecycleTracker from 'src/components/utils/AppLifecycleTracker';

const {height, width} = Dimensions.get('window');
type VendingPageProps = WithGlobalizeProps & {
  returnRoute: AppRoutes;
  test: boolean;
  authorizationAmount: number;
};
type VendingPageState = {
  timeRemaining: number;
  label: string;
  machineName: string;
  btnText: string;
  showMessage: boolean;
  itemDesc: string;
  price: string;
  balance: number;
  itemVended: boolean;
};

class VendingPage extends React.Component<VendingPageProps, VendingPageState> {
  handleAppStateChangeSubscription: any;
  static getLabelString(remaining) {
    if (remaining === 60) {
      return '1:00';
    }

    return `0:${`0${remaining}`.slice(-2)}`;
  }

  static defaultProps = {
    returnRoute: AppRoutes.Machines,
    test: false,
  };
  static contextType = ScreenContext;
  setInterval: (callback: () => void, interval: number) => void;
  setTimeout: (callback: () => void, timeout: number) => void;
  itemTimeout: any;
  btnWasPressed = false;
  interval: any;

  constructor(props: VendingPageProps) {
    super(props);
    const timeRemaining = VendSession.TimeoutBetweenVends;

    this.state = {
      machineName: '',
      label: VendingPage.getLabelString(timeRemaining),
      timeRemaining,
      btnText: Localized.Buttons.cancel,
      balance: props.authorizationAmount,
      showMessage: false,
      itemDesc: '',
      price: '',
      itemVended: false,
    };
    this.loadInitialState = this.loadInitialState.bind(this);
    this.onVendApproved = this.onVendApproved.bind(this);
    this.onHighestPriceReceived = this.onHighestPriceReceived.bind(this);
    this._onBalanceChange = this._onBalanceChange.bind(this);
    this.onVendFailed = this.onVendFailed.bind(this);
    this.initialize = this.initialize.bind(this);
    this.onVendSucceeded = this.onVendSucceeded.bind(this);
    this._onConnectionError = this._onConnectionError.bind(this);
    this._onReboot = this._onReboot.bind(this);
    this._handleAppStateChange = this._handleAppStateChange.bind(this);
    this.btnPressed = this.btnPressed.bind(this);
    this.cancelTransaction = this.cancelTransaction.bind(this);
    this.returnToScreen = this.returnToScreen.bind(this);
    this.captureTransaction = this.captureTransaction.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
    this.onSessionEnded = this.onSessionEnded.bind(this);
  }

  componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'VendingScreen', {
      ...this.props,
      ...this.state,
    });

    this.initialize();
    this.handleAppStateChangeSubscription = AppState.addEventListener(
      'change',
      this._handleAppStateChange,
    );
    this.addDeviceEvents();
    InteractionManager.runAfterInteractions(() => {
      this.loadInitialState();
      AccountStore.addBalanceChangedListener(this._onBalanceChange);
      MachineStore.addConnectionErrorListener(this._onConnectionError);
      MachineStore.addRebootListener(this._onReboot);
      this.interval = this.setInterval(() => {
        if (this.state.timeRemaining <= 0) {
          this.btnPressed();
        } else {
          const timeRemaining = this.state.timeRemaining - 1;
          this.setState({
            label: VendingPage.getLabelString(timeRemaining),
            timeRemaining,
          });
        }
      }, 1000);

      if (AccountStore.isDemo()) {
        this.setTimeout(() => {
          this.onVendApproved('demo', 5, 0);
          this.onVendSucceeded();
        }, 15000);
      }
    });
  }

  async componentWillUnmount() {
    AccountStore.removeBalanceChangedListener(this._onBalanceChange);
    MachineStore.removeConnectionErrorListener(this._onConnectionError);
    MachineStore.removeRebootListener(this._onReboot);
    VendSession.Device.removeListener(
      TSFInsideEvents.onSessionEnded,
      this.onSessionEnded,
    );
    VendSession.Device.removeListener(
      TSFInsideEvents.onVendSucceeded,
      this.onVendSucceeded,
    );
    VendSession.Device.removeListener(
      TSFInsideEvents.onVendFailed,
      this.onVendFailed,
    );
    VendSession.Device.removeListener(
      TSFInsideEvents.onVendApproved,
      this.onVendApproved,
    );
    SyncHelper.removeEvents(VendSession.Device);
    this.handleAppStateChangeSubscription.remove();
    AppLifecycleTracker.clearCartState();
    if (this.state.itemVended) {
      try {
        await TagManager.updateDynamicTags('cart_abandoned');
      } catch (error) {
        console.error('Error updating cancel tag:', error);
      }
    }
  }

  addDeviceEvents() {
    VendSession.Device?.addListener(
      TSFInsideEvents.onSessionEnded,
      this.onSessionEnded,
    );
    VendSession.Device?.addListener(
      TSFInsideEvents.onVendSucceeded,
      this.onVendSucceeded,
    );
    VendSession.Device?.addListener(
      TSFInsideEvents.onVendFailed,
      this.onVendFailed,
    );
    VendSession.Device?.addListener(
      TSFInsideEvents.onVendApproved,
      this.onVendApproved,
    );
  }

  onSessionEnded() {
    if (!this.btnWasPressed) {
      this.sendCreditToDevice();
    }

    Events.Vend.trackEvent('SESSION_ENDED');
  }

  sendCreditToDevice() {
    if (this.props.test) {
      VendSession.sendTestCredit();
    } else {
      return VendSession.Device.sendCredit(
        this.state.balance - VendSession.getTotalAmountOfVendedItems(),
        VendSession.getDiscountAmount(),
        this.state.timeRemaining,
      );
    }
  }

  btnPressed() {
    FirebaseAnalytic.trackEvent('btnPressed', 'VendingScreen', {
      ...this.props,
      ...this.state,
    });
    if (!this.btnWasPressed) {
      this.btnWasPressed = true;
      clearInterval(this.interval);

      if (this.state.btnText === Localized.Buttons.cancel) {
        this.cancelTransaction();
      } else {
        this.captureTransaction();
      }
    }
  }

  async cancelTransaction() {
    FirebaseAnalytic.trackEvent('cancelTransaction', 'VendingScreen', {
      ...this.props,
      ...this.state,
    });

    if (!this.props.test) {
      TransactionAction.cancelTransaction(
        VendSession.MachineConfig.TransactionId,
      );
    }

    Events.Vend.trackEvent('VEND_CANCEL');

    if (this.props.test) {
      VendSession.Device.sendEmptyCredit();
      this.returnToScreen(this.props.returnRoute);
    } else {
      await VendSession.Device.disconnect();
      this.returnToScreen(this.props.returnRoute);
    }
  }

  returnToScreen(returnRoute: AppRoutes) {
    FirebaseAnalytic.trackEvent('returnToScreen', 'VendingScreen', {
      ...this.props,
      ...this.state,
      returnRoute,
    });
    if (!NavActions.popToRoute(returnRoute)) {
      NavActions.popToTop();
    }
  }

  async captureTransaction() {
    VendSession.vendSucceeded(); // process any pending items

    const items = VendSession.Items;
    FirebaseAnalytic.trackEvent('captureTransaction', 'VendingScreen', {
      ...this.props,
      ...this.state,
      items,
    });

    if (this.props.test) {
      VendSession.Device.transactionUploaded();
    } else {
      const response = await TransactionAction.captureTransaction(
        VendSession.MachineConfig.TransactionId,
        items,
      );

      if (response) {
        await VendSession.Device.transactionUploaded();
        VendSession.Device.disconnect();
      }

      Events.Vend.trackEvent(
        'VEND_CAPTURE',
        VendSession.MachineConfig.TransactionId,
        items.length,
      );

      try {
        await TagManager.updateDynamicTags('purchase_completed');
      } catch (error) {
        console.error('Error updating purchase tag:', error);
      }
    }

    NavActions.replace(AppRoutes.Receipt, {
      items: VendSession.Items,
      subtotal: VendSession.getTotalAmountOfVendedItems(),
      taxes: [],
      returnRoute: this.props.returnRoute,
      insideTab: !this.props.test,
      deviceId: VendSession.Device.deviceId,
      balance: this.state.balance,
    });
  }

  async initialize() {
    try {
      if (!this.props.test) {
        await PlanogramAction.loadPlanogram(VendSession.Device.deviceId);
        await VendSession.setMaxPlanogramPrice();
      }

      await this.sendCreditToDevice();
      const time = await ActionsFactory.getAccountActions().getServerTime();

      FirebaseAnalytic.trackEvent('initialize', 'VendingScreen', {
        ...this.props,
        ...this.state,
        time,
      });
      VendSession.Device.sendTimeMessage(time);
    } catch (err) {
      CrashlyticsEvents.log(
        'Exception',
        'VendingScreen:Initialize',
        err.message ? err.message : err.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'VendingScreen:Initialize',
        err.message ? err.message : err.toString(),
      );
    }
  }

  async onVendSucceeded() {
    VendSession.vendSucceeded();

    if (!this.props.test) {
      const {btnPressed} = this;
      const credit = VendSession.getCreditAmount();

      if (credit === 0) {
        btnPressed();
      }
    }

    Events.Vend.trackEvent('VEND_SUCCEEDED');
    this.onItemVended();
  }

  _onConnectionError() {
    Events.Vend.trackEvent('CONNECTION_ERROR');
    this.btnPressed();
  }

  _onReboot() {
    const reason = MachineStore.getRebootReason();
    const message = `${Localized.Labels.machine_will_restart} ${reason}`;
    FirebaseAnalytic.trackEvent('_onReboot', 'VendingScreen', {
      ...this.props,
      ...this.state,
      reason,
      message,
    });
    alertError(message);
    this.btnPressed();
  }

  _handleAppStateChange(currentAppState) {
    FirebaseAnalytic.trackEvent('_handleAppStateChange', 'VendingScreen', {
      ...this.props,
      ...this.state,
      currentAppState,
    });
    if (currentAppState !== 'active') {
      this.btnPressed();

      if (VendSession.isVendPending()) {
        Events.Vend.trackEvent('CLOSE_VEND_PENDING');
      }
    }
  }

  async loadInitialState() {
    const machineName = VendSession.Device.name;
    FirebaseAnalytic.trackEvent('loadInitialState', 'VendingScreen', {
      ...this.props,
      ...this.state,
      machineName,
    });
    this.setState({
      machineName,
    });
  }

  onVendApproved(key: string, price: number, discountAmount: number) {
    VendSession.vendApproved(key, price, discountAmount);
    let newTimeRemaining =
      this.state.timeRemaining + VendSession.TimeoutBetweenVends / 2;

    FirebaseAnalytic.trackEvent('onVendApproved', 'VendingScreen', {
      ...this.props,
      ...this.state,
      key,
      price,
      discountAmount,
      newTimeRemaining,
    });
    if (newTimeRemaining > VendSession.TimeoutBetweenVends) {
      newTimeRemaining = VendSession.TimeoutBetweenVends;
    }

    this.setState({
      timeRemaining: newTimeRemaining,
    });
    Events.Vend.trackEvent('VEND_AUTHORIZED');
  }

  onHighestPriceReceived(price: number) {
    if (!this.props.test) {
      VendSession.setMachineHighestPrice(price);
    }
  }

  onItemVended() {
    const lastItem = VendSession.getLastItemVended();
    const price = this.formatCurrency(lastItem ? lastItem.price : 0);
    this.setState({
      itemVended: true,
    });
    AppLifecycleTracker.updateCartState(true);
    FirebaseAnalytic.trackEvent('onItemVended', 'VendingScreen', {
      ...this.props,
      ...this.state,
      lastItem,
      price,
    });

    this.setState({
      btnText: Localized.Buttons.done,
      showMessage: true,
      price,
      itemDesc: lastItem?.desc,
    });

    if (this.itemTimeout) {
      clearTimeout(this.itemTimeout);
    }

    this.itemTimeout = this.setTimeout(() => {
      this.setState({
        showMessage: false,
      });
    }, 5000);
    Events.Vend.trackEvent('ITEM_VENDED');
  }

  _onBalanceChange() {
    const balance =
      this.props.authorizationAmount -
      VendSession.getTotalAmountOfVendedItems();
    this.setState(
      {
        balance: balance,
      },
      () => {
        FirebaseAnalytic.trackEvent('_onBalanceChange', 'VendingScreen', {
          ...this.props,
          ...this.state,
        });
      },
    );
  }

  onVendFailed() {
    VendSession.vendFailed();
    alertError(Localized.Errors.item_failed_to_vend);
    Events.Vend.trackEvent('VEND_FAILED');
  }

  formatCurrency(value) {
    return Util.formatCurrency(this.props, value, AccountStore.getCurrency());
  }

  render() {
    const timerProgres =
      (this.state.timeRemaining / VendSession.TimeoutBetweenVends) * 100;
    const size = Math.min(
      (height - Styles.getHeaderHeight()) / 2,
      width - Styles.Spacing.m4 * 2,
    );

    return (
      <Header title={this.state.machineName}>
        <View style={styles.content}>
          <AVText style={styles.contentHeader}>
            {`${Localized.Labels.select_product_on_the} `}
            <AVText style={getDescriber().getVendingStyles().locationName}>
              {this.state.machineName}
            </AVText>{' '}
            {Localized.Labels.vending_machine}.
          </AVText>
          <View style={getDescriber().getVendingStyles().progressContainer}>
            <AnimatedCircularProgress
              size={size}
              width={Styles.Spacing.m2}
              fill={timerProgres}
              tintColor={getDescriber().getTintColor()}
              backgroundColor={getDescriber().getCircleBackgroundColor()}
              lineCap="round"
              rotation={0}
              duration={1500}
            >
              {() => (
                <View style={styles.progressInside}>
                  <AVText style={styles.balance}>
                    {this.formatCurrency(this.state.balance)}
                  </AVText>
                  <AVText
                    style={getDescriber().getVendingStyles().balanceLabel}
                  >
                    {Localized.Labels.account_balance}
                  </AVText>
                  <View style={styles.lineContainer}>
                    <View style={styles.line} />
                  </View>
                  <AVText style={getDescriber().getVendingStyles().timer}>
                    {this.state.label}
                  </AVText>
                  <AVText style={getDescriber().getVendingStyles().timerLabel}>
                    {Localized.Labels.until_disconnect}
                  </AVText>
                </View>
              )}
            </AnimatedCircularProgress>
          </View>
          {this.state.showMessage ? (
            <View style={[getDescriber().getVendingStyles().messageContainer]}>
              <AVText
                style={getDescriber().getVendingStyles().messageText}
                numberOfLines={1}
              >
                {Localized.Labels.vended} {this.state.price} -{' '}
                {this.state.itemDesc}
              </AVText>
            </View>
          ) : null}
        </View>
        <View style={styles.filler} />
        <View style={styles.horizontalContainer}>
          <RoundedButton
            buttonType={ButtonType.normal}
            onPress={this.btnPressed}
            accessibilityLabel={this.state.btnText}
            text={this.state.btnText}
            backgroundColor={getDescriber().getButtonColor()}
            containerStyle={styles.rightButtonAdjustments}
          />
        </View>
        <KeepAwake />
      </Header>
    );
  }
}

reactMixin(VendingPage.prototype, TimerMixin);
const styles = StyleSheet.create({
  balance: {
    fontSize: Styles.Fonts.f4,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  leftButtonAdjustments: {
    width: 90,
    height: 45,
    borderColor: Styles.Colors.PayNew.primary01,
    borderWidth: 2,
    elevation: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightButtonAdjustments: {
    width: 90,
    height: 45,
    elevation: 6,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: Styles.Spacing.m2,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    padding: Styles.Spacing.m3,
  },
  contentHeader: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    textAlign: 'center',
    marginTop: Styles.Spacing.m2,
  },
  filler: {
    flex: 0.1,
  },
  horizontalContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: Styles.Spacing.m4,
    marginRight: Styles.Spacing.m3,
  },
  line: {
    borderBottomWidth: PixelRatio.roundToNearestPixel(0.5),
  },
  lineContainer: {
    justifyContent: 'center',
  },
  messageText: {
    color: Styles.white,
    flex: 1,
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  progressInside: {
    flex: 1,
    justifyContent: 'space-around',
    padding: Styles.Spacing.m3,
    width: '100%',
  },
});

export default compose(
  withForwardedNavigationParams<VendingPageProps>(),
  withGlobalize,
  connect(null),
)(VendingPage);
