import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  color?: string;
  size?: number;
}
const TogoIcon = ({size = 32, color = '#22212D'}: IProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      fill={color}
      fillRule="evenodd"
      d="M21.85 5.234c.034 1.48-1.148 2.596-2.634 2.545-1.465-.05-2.58-1.268-2.43-2.74.157-1.549 1.115-2.275 2.673-2.372 1.44.228 2.355 1 2.391 2.567Zm3.072 9.097c-1.764.278-2.376-.72-3.035-2.049-2.502-5.048-9.847-6.1-13.627-2.009-.698.755-.88 1.592-.239 2.421.64.826 1.503.93 2.378.348.472-.315.93-.65 1.389-.987.32-.234.639-.469.964-.696.36-.252.773-.338 1.15-.06.384.283.224.603.076.896-.036.072-.072.143-.098.21-.185.477-.39.946-.594 1.414-.209.478-.418.955-.605 1.44-.899 2.329-.668 3.854 1.516 4.92 3.114 1.52 4.647 3.224 2.682 6.686-.506.893-.022 1.971 1.067 2.35 1.11.386 1.888-.21 2.26-1.239.367-1.015.678-2.05.99-3.086l.255-.844c.788-2.577.636-3.4-1.704-4.552-2.07-1.02-2.252-2.218-1.154-3.976.09-.144.168-.296.3-.553.08-.157.18-.353.316-.611.703 3.578 3.138 3.543 5.662 3.387 1.12-.07 1.905-.542 1.92-1.706.015-1.165-.776-1.876-1.869-1.704ZM11.7 19.88c-.084-.29-.566-.694-.744-.268-.616 1.473-1.71 1.393-2.796 1.314-.38-.027-.76-.055-1.118-.016-1.117.122-1.87.624-1.832 1.785.037 1.16.828 1.657 1.943 1.667.415.003.83.003 1.31.002l.765-.001c.235-.01.48-.005.73 0 1.226.024 2.568.05 3.354-1.579.537-1.11-.095-1.54-.725-1.97-.384-.26-.767-.52-.887-.934Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default TogoIcon;
