import * as React from 'react';
import Svg, {Path, Mask, G} from 'react-native-svg';

interface IProps {
  size?: number;
  color?: string;
}
const WalletIcon = ({color = '#727179', size = 34}: IProps) => (
  <Svg height={size} width={size} fill="none">
    <Path
      fill={color}
      fillRule="evenodd"
      d="m23.766 8.543-2.638-2.978c.171-.487-.012-1.178-.508-1.739-.64-.724-1.555-.954-2.044-.517-.49.438-.369 1.378.273 2.102.511.577 1.198.84 1.703.697l2.623 2.963.59-.528ZM20.817 10.851c.47.032.437-.39.437-.39l-2.011-2.27-.783-.883s.322-.59-.054-1.045l-1.221-1.377-.224.2 1.112 1.394-.299.267-1.23-1.337-.207.185 1.172 1.388-.255.228-1.286-1.238-.216.193 1.224 1.377c.403.427.654.281 1.052.15l-.006.004 2.794 3.153.001.001Z"
      clipRule="evenodd"
    />
    <Mask id="a" width={28} height={13} x={1} y={0} maskUnits="userSpaceOnUse">
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M1.313.834h26.883v11.943H1.313V.834Z"
        clipRule="evenodd"
      />
    </Mask>
    <G mask="url(#a)">
      <Path
        fill={color}
        fillRule="evenodd"
        d="M1.33 12.138a.8.8 0 0 0 .782.638h4.224l10.542-9.382c.122-.106.512-.455.925-.824.47-.42 1-.894 1.166-1.037.19-.169.384-.239.593-.216.334.037.712.318 1.036.771l.017.022c.685.757 1.889 2.117 3.163 3.556l.392.443c1.307 1.476 2.6 2.933 3.332 3.74a.848.848 0 0 1-.069 1.195l-1.947 1.733h.721l1.545-1.375a1.328 1.328 0 0 0 .107-1.874c-.805-.886-2.288-2.56-3.722-4.18a602.371 602.371 0 0 0-3.157-3.55c-.24-.332-.73-.886-1.364-.956-.347-.039-.671.073-.963.331-.166.144-.676.6-1.169 1.04-.413.37-.803.718-.921.821l-4.965 4.419-1.51 1.345-8.135 1.93c-.302.071-.64.536-.64 1.051v.198a.788.788 0 0 0 .016.161Z"
        clipRule="evenodd"
      />
    </G>
    <Path
      fill={color}
      fillRule="evenodd"
      d="M15.43 19.542c-.143-.432-.514-1.195-.634-1.303-.173-.156-.5-.126-.632.057-.138.192-.127.266.11.745.355.712.496 1.199.57 1.97.09.94-.094 1.897-.54 2.79-.105.21-.203.42-.217.466-.06.188.09.439.298.498.31.089.528-.134.84-.853.608-1.405.68-2.94.205-4.37ZM21.448 23.53c-.124-.09-.251-.162-.284-.162-.032 0-.102.046-.157.103-.116.122-.134.314-.039.427.082.098 1.22.63 1.355.634.06.001.257-.05.437-.113s.336-.123.346-.132c.01-.01-.025-.123-.078-.252-.107-.264-.09-.257-.473-.185-.414.078-.654.008-1.107-.32ZM21.935 19.12c-.032-.01-.66-.122-1.396-.25-1.305-.225-1.448-.239-1.509-.142-.032.051-.529 2.314-.529 2.41 0 .048.025.108.056.133.03.025.454.115.94.2.942.166.971.171.971.148 0-.008-.178-.16-.396-.335-.54-.436-.655-.6-.655-.922 0-.27.183-.53.477-.68a.869.869 0 0 1 .554-.05c.104.035.574.285 1.226.653l.137.078.113-.528c.116-.54.119-.682.011-.715Z"
      clipRule="evenodd"
    />
    <Path
      fill={color}
      fillRule="evenodd"
      d="M21.166 22.974c.854.644.945.681 1.415.589.17-.034.188-.045.158-.098-.032-.06-1.606-1.343-1.742-1.422-.113-.065-.204-.05-.298.049-.273.288-.24.348.467.881Z"
      clipRule="evenodd"
    />
    <Path
      fill={color}
      fillRule="evenodd"
      d="M27.334 24.634c-.065 0-.111-.043-.207-.192-.069-.105-.513-.785-.99-1.508-1.683-2.563-1.774-2.682-2.243-2.913l-.247-.12h-.723c-.482 0-.73.024-.742.074-.096.41-.114.51-.093.53.013.012.424.242.913.51l.889.49-.056.115c-.03.063-.066.114-.078.114-.013 0-.76-.407-1.662-.904-.901-.498-1.692-.919-1.756-.936-.257-.07-.65.21-.65.46 0 .252.076.33 1.66 1.605.989.795 1.554 1.273 1.64 1.386.158.206.316.509.463.882.257.656.676 1.022 1.363 1.192l.226.055-.019.122a.596.596 0 0 1-.032.137c-.03.033-.485-.11-.748-.235-.413-.195-.696-.472-.974-.953a5.141 5.141 0 0 0-.359.12c-.347.125-.363.135-.69.45-1.398 1.34-3.401 2.196-5.82 2.487-.393.047-1.906.068-2.323.032-2.535-.218-4.772-1.143-6.23-2.574-.83-.816-1.333-1.7-1.56-2.743-.104-.474-.104-1.26 0-1.74.317-1.45 1.27-2.718 2.77-3.685 3.423-2.206 8.666-2.195 12.056.026.45.295.741.53 1.148.925.514.499.876.995 1.257 1.723.014.027.084.061.156.076.24.05.56.233.82.47.2.184.355.385.737.953.385.572 2.13 3.22 2.236 3.394.041.067-.04.175-.133.175h.001Zm.554-11.379H2.112a1.282 1.282 0 0 1-1.279-1.278v15.91c0 .703.576 1.279 1.279 1.279h25.776c.703 0 1.279-.576 1.279-1.279V14.533c0-.703-.576-1.278-1.279-1.278Z"
      clipRule="evenodd"
    />
    <Path
      fill={color}
      fillRule="evenodd"
      d="M16.684 17.83c-.1-.193-.22-.386-.267-.429-.12-.111-.37-.128-.52-.036a.499.499 0 0 0-.21.358c0 .032.097.254.217.492.555 1.113.78 2.041.78 3.235a6.925 6.925 0 0 1-.835 3.357c-.144.274-.167.342-.148.44.029.152.167.311.302.35.06.016.128.024.153.016a.377.377 0 0 1 .09-.013c.126 0 .275-.2.513-.684.394-.8.64-1.618.763-2.534.066-.496.05-1.524-.034-2.066a8.072 8.072 0 0 0-.804-2.486ZM11.257 19.749c-.24.044-.417.3-.353.51.015.05.082.18.148.287.197.318.243.489.243.904 0 .416-.046.586-.243.904-.152.247-.184.353-.146.485a.517.517 0 0 0 .253.296c.222.09.434-.012.624-.3a2.54 2.54 0 0 0 0-2.75c-.186-.285-.325-.374-.526-.337ZM12.873 18.967c-.141 0-.184.016-.284.11-.204.188-.199.284.042.762.548 1.09.542 2.165-.018 3.28-.153.304-.185.395-.166.477.03.136.14.269.27.326.057.026.154.04.214.031.2-.027.298-.131.497-.528.347-.692.472-1.208.473-1.957.001-.73-.132-1.3-.457-1.957-.212-.427-.334-.543-.57-.543l-.001-.001Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default WalletIcon;
