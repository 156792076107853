import {View, StyleSheet} from 'react-native';
import AVText from 'src/components/elements/AVText';
import {ButtonType} from 'src/components/elements/RoundedButton';
import {
  ProductCategorySearchDescriberData,
  ScanDiscountCodeStylesDescriptor,
} from 'src/components/screens/cart/descriptor/Descriptor';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
export function getDoneButtonColor(): string {
  return Styles.primaryColor;
}
export function getReportButtonColor() {
  return null;
}
export function getReportButtonType() {
  return ButtonType.outline;
}

export function getReceiptStyles() {
  return {
    amount: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1,
    },
    bold: {
      fontWeight: 'bold',
    },
    desc: {
      color: Styles.darkColor,
      flex: 1,
      fontSize: Styles.Fonts.f1,
    },
  };
}

export function getProductSearchEmptyContent(
  isloading?: boolean,
  isEmpty?: number,
): JSX.Element {
  return (
    isloading &&
    isEmpty === 0 && (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: Styles.Spacing.m6,
          marginHorizontal: Styles.Spacing.m3,
        }}
      >
        <AVText
          accessibilityRole="text"
          style={{
            fontSize: Styles.Fonts.f2,
            fontWeight: '400',
            fontFamily: Styles.FontFamily.aeonikRegular,
            textAlign: 'center',
            color: '#000',
          }}
        >
          {Localized.Labels.no_products_available}
        </AVText>
      </View>
    )
  );
}
export function searchTopContainerDescriptor(): {
  [index: string]: number | string;
} {
  return {top: -30};
}

export function getSelectedProductCategoryNameContent(
  props: ProductCategorySearchDescriberData,
): JSX.Element {
  return (
    <View style={props.styles.banner}>
      <AVText style={props.styles.bannerText}>{props.category}</AVText>
    </View>
  );
}
export function productCategoryflatListStyleDescriptor(
  props: ProductCategorySearchDescriberData,
): {
  [index: string]: number | string;
} {
  return props.styles.list;
}
export function getScanDiscountStyles(): ScanDiscountCodeStylesDescriptor {
  return StyleSheet.create({
    descriptionText: {
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m3,
      marginHorizontal: Styles.Spacing.m3,
      fontSize: Styles.Fonts.f9,
      fontWeight: '700',
      color: Styles.Colors.PayNew.black01,
      fontFamily: Styles.FontFamily.aeonikRegular,
      marginBottom: Styles.Spacing.m4,
    },
  });
}
export function getManualEntryButton() {
  return null;
}
export function getColorForCheckoutButton() {
  return '#003349';
}

export default {
  getDoneButtonColor,
  getReportButtonColor,
  getReportButtonType,
  getReceiptStyles,
  getProductSearchEmptyContent,
  searchTopContainerDescriptor,
  getSelectedProductCategoryNameContent,
  productCategoryflatListStyleDescriptor,
  getScanDiscountStyles,
  getManualEntryButton,
  getColorForCheckoutButton,
};
