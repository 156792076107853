const VatLabels = {
  AUT: 'USt', //Austria
  BEL: 'BTW', //Belgium
  DNK: 'moms', //Denmark
  FIN: 'ALV', //Finland
  FRA: 'TVA', //France
  DEU: 'MwSt', //Germany
  IRL: 'VAT', //Ireland
  ITA: 'IVA', //Italy
  LUX: 'TVA', //Luxembourg
  NLD: 'BTW', //Netherlands
  NOR: 'MVA', //Norway
  ESP: 'IVA', //Spain
  SWE: 'Moms', // Sweden
  CHE: 'MwSt', // switzerland
  GBR: 'VAT', // UK
};

export function getVATLabel(country: string) {
  return VatLabels[country];
}
