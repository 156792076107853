enum AccountConstants {
  SETUP_ACCOUNT_SUCCESS = 'SETUP_ACCOUNT_SUCCESS',
  ADD_CREDIT_CARD_SUCCESS = 'ADD_CREDIT_CARD_SUCCESS',
  ADD_CREDIT_CARD_FAIL = 'ADD_CREDIT_CARD_FAIL',
  DELETE_CREDIT_CARD_SUCCESS = 'DELETE_CREDIT_CARD_SUCCESS',
  DELETE_CREDIT_CARD_FAIL = 'DELETE_CREDIT_CARD_FAIL',
  UPDATE_DEFAULT_TOKEN_SUCCESS = 'UPDATE_DEFAULT_TOKEN_SUCCESS',
  LOAD_PURCHASE_HISTORY_SUCCESS_GMA = 'LOAD_PURCHASE_HISTORY_SUCCESS_GMA',
  LOAD_PURCHASE_HISTORY_ERROR = 'LOAD_PURCHASE_HISTORY_ERROR',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_GMA_SUCCESS = 'LOGIN_GMA_SUCCESS',
  ACCOUNT_INFO_LOADED = 'ACCOUNT_INFO_LOADED',
  ACCOUNT_INFO_ERROR = 'ACCOUNT_INFO_ERROR',
  ITEM_VENDED = 'ITEM_VENDED',
  GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS',
  GET_BALANCE_ERROR = 'GET_BALANCE_ERROR',
  VEND_REFUND_SUCCESS = 'VEND_REFUND_SUCCESS',
  VEND_REFUND_ERROR = 'VEND_REFUND_ERROR',
  PIN_CHANGED = 'PIN_CHANGED',
  TIME_QUALITY = 'TIME_QUALITY',
  ENVIRONMENT_CHANGED = 'ENVIRONMENT_CHANGED',
  GMA_CHANGED = 'GMA_CHANGED',
  DEBUG_CHANGED = 'DEBUG_CHANGED',
  DEMO_CHANGED = 'DEMO_CHANGED',
  LOGOUT = 'LOGOUT',
  LOAD_SALE_DETAIL = 'LOAD_SALE_DETAIL',
  LOAD_FUNDING_DETAIL = 'LOAD_FUNDING_DETAIL',
  LOAD_REFUND_DETAIL = 'LOAD_REFUND_DETAIL',
  LOAD_SENDASNACK_DETAIL = 'LOAD_SENDASNACK_DETAIL',
  LOAD_REFERRAL_DETAIL = 'LOAD_REFERRAL_DETAIL',
  ENVIRONMENTS_FETCHED = 'ENVIRONMENTS_FETCHED',
  BLUETOOTH_CHECKED = 'BLUETOOTH_CHECKED',
  TALLY_REGISTERED = 'TALLY_REGISTERED',
  APPLE_PAY_TOKEN = 'apple-pay',
  APPLE_PAY_LABEL = 'Apple Pay',
  GOOGLE_PAY_TOKEN = 'google-pay',
  GOOGLE_PAY_LABEL = 'Google Pay',
  PAYROLL_TOKEN = 'payroll',
  PAYROLL_TOKEN_REDESIGN = 'payroll-redesign',
  PAYROLL_LABEL = 'Payroll Deduct',
  ADD_PAYMENT_TOKEN = 'payment',
  ENTITY_NOT_FOUND = 'Entity not found',
  REFUND_TYPE = 'Refund',
  FUNDING_TYPE = 'Funding',
  TRANSFER_IN = 'TransferIn',
  TRANSFER_OUT = 'TransferOut',
  SALE_TYPE = 'Sale',
  REDEMPTION_TYPE = 'Redemption',
  ADJUSTMENT_TYPE = 'Adjustment',
  REFERRAL_TYPE = 'Referral',
  REFERRAL_SIGNUP_TYPE = 'ReferralSignup',
  SEND_SNACK = 'SendaSnack',
  SEND_SNACK_CANCEL = 'SendaSnackCancel',
  SEND_SNACK_REVERSE = 'SendaSnackReverse',
  SEND_SNACK_ACCEPT = 'SendaSnackAccept',
  SEND_SNACK_EXPIRE = 'SendaSnackExpire',
  ADJUSTMENT = 'adjustment',
  VISITED_EAT_IT_OR_DELETE_IT_WELCOME = 'VISITED_EAT_IT_OR_DELETE_IT_WELCOME',
  LOCATION_UPDATED = 'LOCATION_UPDATED',
  ACCOUNT_INFO_UPDATED = 'ACCOUNT_INFO_UPDATED',
  MESSAGE_UPDATE = 'MESSAGE_UPDATE',
  DELETE_MESSAGE = 'DELETE_MESSAGE',
  MESSAGE_READ = 'MESSAGE_READ',
  PROMOTIONS_LOADED = 'PROMOTIONS_LOADED',
  THRESHOLDS_LOADED = 'THRESHOLDS_LOADED',
  AUTO_FUND_LOADED = 'AUTO_FUND_LOADED',
  PRIVACY_VERSION_LOADED = 'PRIVACY_VERSION_LOADED',
  LOAD_REWARD_PRODUCT = 'LOAD_REWARD_PRODUCT',
  LOAD_REWARD_STATUS = 'LOAD_REWARD_STATUS',
  MINIMUM_FUNDING_AMOUNT = 10,
  DEFAULT_FALL_BELOW_AMOUNT = 5,
  MOBLICO_PROMOS_LOADED = 'MOBLICO_PROMOS_LOADED',
  MOBLICO_DEALS_LOADED = 'MOBLICO_DEALS_LOADED',
  MOBLICO_SOURCE = 'moblico',
  CONSUMER_ENGAGEMENT_REGISTER = 'CONSUMER_ENGAGEMENT_REGISTER',
  LOCATION_LINK_ADDED = 'LOCATION_LINK_ADDED',
  LOCATION_LINK_REMOVED = 'LOCATION_LINK_REMOVED',
  LINKED_LOCATIONS_FETCHED = 'LINKED_LOCATIONS_FETCHED',
  NEARBY_LOCATIONS_FETCHED = 'NEARBY_LOCATIONS_FETCHED',
  SOS_LOCATION_TYPE = 'sos',
  MARKET_CARD_UPDATED = 'MARKET_CARD_UPDATED',
  REFERRAL_DETAILS_LOADED = 'REFERRAL_DETAILS_LOADED',
  RECENT_ORDERS_LOADED = 'RECENT_ORDERS_LOADED',
  REMOVE_RECENT_ORDER = 'REMOVE_RECENT_ORDER',
  LOAD_GIFT_FEED_SUCCESS_LOCATION = 'LOAD_GIFT_FEED_SUCCESS_LOCATION',
  ACCOUNT_SEND_SNACK_STATUS_UPDATED = 'ACCOUNT_SEND_SNACK_STATUS_UPDATED',
  SELECT_SHOP = 'SELECT_SHOP',
  REWARDS_STATUS_LOADED = 'REWARDS_STATUS_LOADED',
  REWARDS_PRODUCT_LOADED = 'REWARDS_PRODUCT_LOADED',
  Add_Value = 'addvalue',
  All = '',
  MARKET_PURCHASES = 'Market Purchase',
  MOBILE_PURCHASES = 'Mobile Purchase',
  ORDER_AHEAD = 'Order Ahead',
  SIGN_UP_REWARDS = 'Sign-Up Rewards',
  REFERRAL_REWARDS = 'Referral Reward',
}

export default AccountConstants;
