import * as React from 'react';
import {View, Image, PixelRatio, StyleSheet} from 'react-native';
import Styles from '../../Styles';
import type {InnerProduct} from 'src/types/ProductType';
import AVTouchableOpacity from '../AVTouchableOpacity';
import {ensureHttps} from 'src/services/StringUtils';
import AVText from '../AVText';
import {useState} from 'react';

type ProductProps = {
  productClicked: (product: any) => void;
  style?: any;
  productStyle?: any;
  product: InnerProduct;
};

const Product = (props: ProductProps) => {
  let content: React.ReactNode;
  const [imageLoaded, setImageLoaded] = useState(true);

  if (props.product.ImageUrl && imageLoaded) {
    content = (
      <Image
        resizeMode="contain"
        style={[styles.image, props.productStyle]}
        source={{
          uri: ensureHttps(props.product.ImageUrl),
        }}
        onError={() => setImageLoaded(false)} // Fallback to text if image fails to load
      />
    );
  } else {
    content = (
      <AVText
        style={styles.productText}
        numberOfLines={5}
        accessibilityLabel={'Product Name: ' + props.product.Name}
        accessible={true}
        accessibilityRole="text"
        maxFontSizeMultiplier={1.2}
      >
        {props.product.Name}
      </AVText>
    );
  }

  return (
    <View style={[styles.productsContainer, props.style]}>
      <AVTouchableOpacity
        onPress={() => props.productClicked(props.product)}
        style={[styles.product, props.productStyle]}
        accessibilityLabel={'Product Image ' + props.product.Name}
      >
        {content}
      </AVTouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    height: Styles.Heights.h8,
    width: Styles.Heights.h8,
  },
  product: {
    alignSelf: 'center',
    backgroundColor: Styles.white,
    height: Styles.Heights.h8,
    width: Styles.Heights.h8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  productText: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 18,
    maxHeight: 90,
    overflow: 'hidden',
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  productsContainer: {
    alignItems: 'center',
    borderColor: Styles.lightGray,
    borderRadius: PixelRatio.roundToNearestPixel(4),
    borderWidth: PixelRatio.roundToNearestPixel(1),
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginHorizontal: Styles.Spacing.m1,
    overflow: 'hidden',
  },
});
export default Product;
