import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import GrpReferralRedesign from 'src/components/img/svg/GrpReferralredesign';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';

export function getReferralStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    referralTitle: {
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m3,
      fontSize: Styles.Fonts.f3,
      fontWeight: 'bold',
      textAlign: 'center',
      fontFamily: Styles.FontFamily.aeonikRegular,
      color: Styles.Colors.PayNew.primary01,
    },
    referralDesc: {
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m1,
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
      textAlign: 'center',
      fontFamily: Styles.FontFamily.aeonikRegular,
      color: Styles.tabBarTextColor,
    },
    referralExpiry: {
      marginTop: Styles.Spacing.m3,
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
      textAlign: 'center',
      fontStyle: 'italic',
      fontFamily: Styles.FontFamily.aeonikRegular,
      color: Styles.Colors.PayNew.primary01,
    },
    totalReferralsKey: {
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
      textAlign: 'center',
      fontFamily: Styles.FontFamily.aeonikRegular,
      color: Styles.lightGray,
      lineHeight: 14,
    },
    totalReferralsValue: {
      fontSize: Styles.Fonts.f3,
      fontWeight: 'bold',
      textAlign: 'left',
      fontFamily: Styles.FontFamily.aeonikRegular,
      color: Styles.tabBarTextColor,
    },
    shareViaEmailTitle: {
      fontSize: Styles.Fonts.f7,
      fontWeight: '400',
      textAlign: 'left',
      fontFamily: Styles.FontFamily.aeonikRegular,
      color: Styles.tabBarTextColor,
    },
    errorLabel: {
      color: Styles.dangerColor,
      fontSize: Styles.Fonts.f8,
      fontFamily: Styles.FontFamily.aeonikRegular,
      alignSelf: 'flex-start',
      marginHorizontal: Styles.Spacing.m3,
      marginTop: Styles.Spacing.m1,
    },
    orText: {
      fontSize: Styles.Fonts.f1,
      textTransform: 'uppercase',
      color: Styles.tabBarTextColor,
      fontFamily: Styles.FontFamily.aeonikRegular,
      marginHorizontal: Styles.Spacing.m3,
    },
  };
}

export function getReferralTitleMsg(amount: string): string {
  return Localized.Labels.formatString(
    Localized.Labels.referral_invite_earn_desc,
    '365Pay',
    amount,
    amount,
  ).toString();
}

export function getReferralHeaderIcon() {
  return (
    <GrpReferralRedesign
      width={78}
      height={74}
      gradColor1={Styles.Colors.PayNew.primary01}
      gradColor2={Styles.Colors.PayNew.support01}
    />
  );
}

export function getSendInviteButton(
  checkDisabled: () => boolean,
  callback: () => void,
) {
  return (
    <RoundedAppButton
      buttonType={RoundedAppButtonType.Solid}
      onPress={callback}
      isDisabled={checkDisabled()}
      inActiveButtonViewStyle={{
        backgroundColor: Styles.Colors.PayNew.darkHuesBase04,
      }}
      buttonViewStyle={{
        height: 44,
      }}
      buttonContainerStyle={{
        position: 'absolute',
        right: Styles.Spacing.m3,
        bottom: 20,
      }}
      accessibilityLabelValue="Send Invite"
      titleText={Localized.Buttons.send_invite}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
    />
  );
}

export default {
  getReferralStyles,
  getReferralTitleMsg,
  getReferralHeaderIcon,
  getSendInviteButton,
};
