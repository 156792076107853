import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import StackTransitionPreset from '../StackTransitionPreset';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import SendSnackScreenNew from 'src/components/screens/sendSnack/SendSnackScreen';
import ChooseContactScreenNew from 'src/components/screens/sendSnack/ChooseContactScreen';
import CampusLocationsScreen from 'src/components/screens/home/CampusLocationsScreen';
import LocationCodeScreen from 'src/components/screens/LocationCodeScreen';
import RevolveHomeScreen from 'src/components/screens/home/RevolveHomeScreen';
import DealDetailScreen from 'src/components/screens/inbox/DealDetailScreen';
import InboxNavigator from 'src/navigators/tabs/InboxTab';
import CardsScreen from 'src/components/screens/funding/CreditCardsScreen';
import Vending from 'src/components/screens/vending/VendingScreen';
import VendorsExchangeVendingScreenNew from 'src/components/screens/vending/VendorsExchangeVendingScreen';
import ReceiptScreenNew from 'src/components/screens/cart/ReceiptScreen';
import ContactUsScreenNew from 'src/components/screens/vending/ContactUsScreen';
import ChooseLocationScreen from 'src/components/screens/scanAndPay/ChooseLocationScreen';
import ScanDiscountCodeScreen from 'src/components/screens/cart/ScanDiscountCodeScreen';
import ProductCategorySearchScreen from 'src/components/screens/cart/ProductCategorySearchScreen';
import EnterDiscountCodeScreen from 'src/components/screens/cart/EnterDiscountCodeScreen';
import SnackDetailScreen from 'src/components/screens/sendSnack/SnackDetailScreen';
import ScanScreenNewUI from '../../components/screens/cart/ScanScreen';
import PurchaseReceiptScreen from '../../components/screens/account/history/PurchaseHistoryDetailScreen';

const RevolveHomeStack = createStackNavigator();

const revolveComponents = [
  {name: AppRoutes.RevolveHome, component: RevolveHomeScreen},
  {
    component: ChooseContactScreenNew,
    name: AppRoutes.ChooseContact,
  },
  {
    component: CardsScreen,
    name: AppRoutes.Cards,
  },

  {
    component: DealDetailScreen,
    name: AppRoutes.DealDetail,
  },
  {
    component: CampusLocationsScreen,
    name: AppRoutes.CampusLocations,
  },
  {
    component: LocationCodeScreen,
    name: AppRoutes.LocationCode,
  },
  {name: AppRoutes.SendSnack, component: SendSnackScreenNew},
  {name: AppRoutes.Inbox, component: InboxNavigator},
  //{name: AppRoutes.ScanAccount, component: ScanNavigator}
  {component: CardsScreen, name: AppRoutes.PDCards},
  {name: AppRoutes.Vending, component: Vending},
  {
    component: VendorsExchangeVendingScreenNew,
    name: AppRoutes.VendorsExchangeVending,
  },
  {component: ContactUsScreenNew, name: AppRoutes.ContactUs},
  {component: ReceiptScreenNew, name: AppRoutes.Receipt},
  {name: AppRoutes.Scan, component: ScanScreenNewUI},
  {
    component: ScanDiscountCodeScreen,
    name: AppRoutes.ScanDiscountCode,
  },
  {
    component: ProductCategorySearchScreen,
    name: AppRoutes.ProductCategorySearch,
  },
  {component: ChooseLocationScreen, name: AppRoutes.ChooseLocation},
  {
    component: EnterDiscountCodeScreen,
    name: AppRoutes.EnterDiscountCode,
  },
  {
    component: PurchaseReceiptScreen,
    name: AppRoutes.PurchaseHistoryDetail,
  },
  {
    component: SnackDetailScreen,
    name: AppRoutes.SnackDetail,
  },
];

const RevolveHomeNavigator = () => {
  return (
    <RevolveHomeStack.Navigator
      screenOptions={{
        ...StackTransitionPreset,
        headerShown: false,
        title: DeviceInfo.getApplicationName(),
      }}
      initialRouteName={AppRoutes.RevolveHome}
    >
      {revolveComponents.map((screen, index) => (
        <RevolveHomeStack.Screen
          name={screen.name}
          key={index}
          component={screen.component}
        />
      ))}
    </RevolveHomeStack.Navigator>
  );
};

export default RevolveHomeNavigator;
