import * as React from 'react';
import {
  Animated,
  Platform,
  TouchableOpacity,
  View,
  StyleSheet,
} from 'react-native';
import Collapsible from 'react-native-collapsible';
import Styles from '../Styles';
import {getDescriber} from './descriptor/DescriptorType';
type CollapsibleComponentProps = {
  header: React.ReactNode;
  content: React.ReactNode;
  headerAccessibilityLabel?: string;
  contentAccessibilityLabel?: string;
};
const descriptors = getDescriber();
export default function CollapsibleComponent(props: CollapsibleComponentProps) {
  const [collapsed, setCollapsed] = React.useState(true);
  const [chevronSpin] = React.useState(new Animated.Value(0));

  const {
    toValue,
    toDegree,
    content: contentStyle,
    parentContainer,
  } = descriptors.collapsibleComponentDescriptor();

  function toggleExpanded() {
    const c = !collapsed;
    setCollapsed(c);
    Animated.timing(chevronSpin, {
      toValue: c ? 0 : toValue,
      duration: 500,
      useNativeDriver: Platform.OS !== 'web',
    }).start();
  }

  const rotate = chevronSpin.interpolate({
    inputRange: [0, toValue],
    outputRange: ['0deg', toDegree],
  });
  const animatedView = descriptors.getAnimatedViewDescriptor(
    rotate,
    props,
    collapsed,
  );
  return (
    <View style={contentStyle}>
      <TouchableOpacity
        style={parentContainer}
        accessible={true}
        accessibilityHint="Double tap to expand or collapse the item"
        accessibilityState={{expanded: !collapsed}}
        accessibilityRole="togglebutton"
        accessibilityLabel={props.headerAccessibilityLabel}
        onPress={toggleExpanded}
      >
        {animatedView}
      </TouchableOpacity>

      <View
        accessible={true}
        accessibilityRole="text"
        accessibilityLabel={props.contentAccessibilityLabel}
      >
        <Collapsible collapsed={collapsed} style={styles.collapsible}>
          {props.content}
        </Collapsible>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  parentContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: Styles.Spacing.m2,
    justifyContent: 'flex-end',
  },
  chevron: {
    alignSelf: 'baseline',
    paddingLeft: Styles.Spacing.m3,
  },
  collapsible: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: Styles.Spacing.m1,
  },
  content: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m3,
    marginVertical: Styles.Spacing.m2,
  },
});
