import * as React from 'react';
import Svg, {Path, G} from 'react-native-svg';

type DealIconPureProps = {
  width: number;
  height: number;
  bgColor: string;
};

export default class DealIconPure extends React.Component<DealIconPureProps> {
  render() {
    return (
      <>
        {
          <Svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 28 29"
            fill="none"
          >
            <G id="Icons/Tag">
              <Path
                id="&#240;&#159;&#142;&#168; Color"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.1998 14.7806L14.2571 3.75554L14.179 3.67739H14.1581C13.7258 3.26577 13.2258 3.05215 12.6008 3.03652L7.40812 2.84373L7.17896 2.83331C6.59562 2.84373 6.02271 3.06778 5.58 3.51587L3.0175 6.07414C2.54875 6.54307 2.33521 7.1631 2.33521 7.77792C2.33521 7.77792 2.33521 7.77792 2.33521 7.78313L2.35083 8.00196L2.69979 13.0716C2.69979 13.0768 2.69979 13.082 2.69979 13.082V13.181C2.75187 13.6343 2.93416 14.0824 3.24146 14.4524L3.52791 14.7337L14.2727 25.6129L14.4342 25.7744C15.054 26.3215 15.9967 26.2954 16.5852 25.7015L25.1998 17.0211C25.8144 16.4062 25.8248 15.4006 25.1998 14.7806ZM8.16854 11.1646C6.78833 11.1646 5.66854 10.0444 5.66854 8.66368C5.66854 7.28294 6.78833 6.16272 8.16854 6.16272C9.54875 6.16272 10.6685 7.28294 10.6685 8.66368C10.6685 10.0444 9.54875 11.1646 8.16854 11.1646Z"
                fill={this.props.bgColor}
              />
            </G>
          </Svg>
        }
      </>
    );
  }
}
