import React from 'react';
import {StyleSheet, View} from 'react-native';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Localized from '../../../constants/AppStrings';
import Styles from '../../Styles';
import RevolveSearchIconSvg from '../../img/svg/RevolveSearchIconSvg';
import AVText from '../../elements/AVText';
import AVFormattedCurrency from '../../elements/AVFormattedCurrency';
import AccountStore from '../../../stores/AccountStore';
import {useAppSelector} from '../../../redux/hooks';
import {getTotalDisplayAmount} from '../../../Util';
import {scanAndPayTemplate} from 'src/components/screens/scanAndPay/templates/scanandPayThemeHelper';

function SearchAndBalanceAmount({onPress}: {onPress: () => void}) {
  const balances = useAppSelector((state) => state.account.account.balances);
  const balance = {
    balance: getTotalDisplayAmount(balances),
    currency: AccountStore.getCurrency(),
  };

  return (
    <View style={styles.searchWrapper}>
      <View style={styles.shadow}>
        <RoundedButton
          accessible={true}
          accessibilityLabel={Localized.Labels.searchproducts}
          accessibilityRole="button"
          aria-label={Localized.Labels.searchproducts}
          role="button"
          containerStyle={styles.btnEmptyCartSearch}
          buttonType={ButtonType.normalWhite}
          onPress={onPress}
          backgroundColor={Styles.white}
          text={Localized.Labels.searchproducts}
          textStyle={styles.searchBtnText}
          icon={
            <RevolveSearchIconSvg color={scanAndPayTemplate.searchIconColor} />
          }
          isProductSearch={true}
        />
      </View>

      <View style={styles.balanceContainer}>
        <AVText
          accessibilityLabel={Localized.Labels.your_balance}
          aria-label={Localized.Labels.your_balance}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
          style={styles.balanceAmountText}
        >
          {Localized.Labels.your_balance}
        </AVText>
        <AVFormattedCurrency
          testID={'balanceFormattedCurrency'}
          accessibilityLabel={'0'}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          style={styles.amountText}
          value={balance.balance}
          currency={balance.currency}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  balanceAmountText: {
    fontFamily: scanAndPayTemplate.textFontFamily,
    fontSize: Styles.Fonts.f8,
  },
  amountText: {
    fontFamily: scanAndPayTemplate.textFontFamily,
    fontSize: Styles.Fonts.f2,
    fontWeight: 'bold',
  },
  balanceContainer: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginLeft: Styles.Spacing.m3,
  },
  searchWrapper: {
    marginTop: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m3,
    flexDirection: 'row',
  },
  searchBtnText: {
    fontSize: Styles.Fonts.f2,
    fontWeight: '400',
    fontFamily: scanAndPayTemplate.textFontFamily,
    color: Styles.Colors.PayNew.darkHuesBase04,
  },
  shadow: {
    flex: 1,
    borderRadius: 40,
    ...Styles.Style.shadow,
  },
  btnEmptyCartSearch: {
    width: '100%',
    borderWidth: 1,
    borderTopColor: '#C2C2C5',
    borderBottomColor: '#C2C2C5',
    borderStartColor: '#C2C2C5',
    borderEndColor: '#C2C2C5',
    backgroundColor: Styles.white,
  },
});

export default SearchAndBalanceAmount;
