import React from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  Image,
  Linking,
  Platform,
} from 'react-native';
import Styles from '../../Styles';
import AVText from 'src/components/elements/AVText';
import InfoIconImage from 'src/components/img/svg/InfoIconImage';
import {AnnouncementType} from 'src/types/AnnouncementType';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import {getDescriber} from '../home/descriptor/DescriptorType';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';

type AnnouncementViewProps = {
  announcement: AnnouncementType;
};

const width = Dimensions.get('window').width;

const {
  infoIconPrimaryColor,
  infoIconInnerColor,
  getAnnouncementStyles,
  announcementBtnFont,
  getWidthImageAvailable,
} = getDescriber();

const AnnouncementView = (props: AnnouncementViewProps) => {
  const isImageAvailable = props.announcement?.imageUrl ? true : false;
  const isButtonAvailable = props.announcement?.buttonTitle ? true : false;

  const renderImage = () => {
    const image = {uri: props.announcement.imageUrl};
    return isImageAvailable ? (
      <View
        style={
          Platform.OS === 'web'
            ? styles.imageContainerWeb
            : styles.imageContainer
        }
      >
        <Image
          accessibilityLabel={`${props.announcement.title}Image`}
          accessibilityRole="image"
          aria-label={`${props.announcement.title}Image`}
          role="img"
          style={styles.imageView}
          source={image}
        />
      </View>
    ) : null;
  };

  const renderTitle = () => {
    return (
      <View
        style={Platform.OS === 'web' ? styles.titleViewWeb : styles.titleView}
      >
        {!isImageAvailable ? (
          <View style={styles.infoIcon}>
            <InfoIconImage
              outerColor={infoIconPrimaryColor()}
              innerColor={infoIconInnerColor()}
            />
          </View>
        ) : null}
        <AVText
          numberOfLines={2}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
          style={[
            getAnnouncementStyles().titleText,
            !isImageAvailable && {width: getWidthImageAvailable()},
          ]}
        >
          {props.announcement.title}
        </AVText>
      </View>
    );
  };

  const renderDescription = () => {
    return (
      <View
        style={
          Platform.OS === 'web'
            ? styles.descriptionViewWeb
            : styles.descriptionView
        }
      >
        <AVText style={getAnnouncementStyles().descriptionText}>
          {props.announcement.body}
        </AVText>
      </View>
    );
  };

  const openWebExternalLink = async (url: string) => {
    FirebaseAnalytic.trackEvent(
      'AnnouncementButtonClicked',
      'AnnouncementView',
      {button: props.announcement.buttonTitle},
    );
    const canOpenLink = await Linking.canOpenURL(url);
    if (canOpenLink) {
      Linking.openURL(url);
    }
  };

  const renderButton = () => {
    return (
      isButtonAvailable && (
        <RoundedAppButton
          accessibilityHintValue="Double tap to open announcement link"
          accessibilityLabelValue={props.announcement?.buttonTitle}
          aria-label={props.announcement?.buttonTitle}
          titleText={props.announcement?.buttonTitle ?? ''}
          buttonType={RoundedAppButtonType.Outline}
          buttonContainerStyle={styles.button}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          onPress={() => {
            openWebExternalLink(props.announcement?.externalLink ?? '');
          }}
          buttonViewStyle={{
            borderWidth: 2,
            height: 39,
          }}
          textStyle={[
            {
              fontWeight: '700',
              fontSize: Styles.Fonts.f1,
              fontFamily: announcementBtnFont(),
            },
          ]}
        />
      )
    );
  };

  return (
    <View style={getAnnouncementStyles().container}>
      {renderImage()}
      {renderTitle()}
      {renderDescription()}
      {renderButton()}
    </View>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    flex: 1,
    width: width - (Styles.Spacing.m4 + Styles.Spacing.m4),
    marginTop: Styles.Spacing.m3,
    aspectRatio: 1.94,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: Styles.Spacing.m2,
    overflow: 'hidden',
  },
  imageView: {
    width: '100%',
    height: '100%',
  },
  titleView: {
    flex: 1,
    flexDirection: 'row',
    width: width - (Styles.Spacing.m4 + Styles.Spacing.m3),
    marginTop: Styles.Spacing.m3,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  infoIcon: {
    marginRight: Styles.Spacing.m2,
    marginLeft: Styles.Spacing.m2,
  },
  descriptionView: {
    width: width - (Styles.Spacing.m4 + Styles.Spacing.m3),
    marginVertical: Styles.Spacing.m3,
    justifyContent: 'flex-start',
  },
  button: {
    left: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m3,
  },
  imageContainerWeb: {
    flex: 1,
    width: '100%',
    paddingLeft: Styles.Spacing.m3,
    paddingRight: Styles.Spacing.m3,
    marginLeft: 0,
    marginTop: Styles.Spacing.m3,
    aspectRatio: 1.94,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: Styles.Spacing.m2,
    overflow: 'hidden',
  },
  titleViewWeb: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    paddingLeft: Styles.Spacing.m3,
    paddingRight: Styles.Spacing.m3,
    marginLeft: 0,
    marginTop: Styles.Spacing.m3,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  descriptionViewWeb: {
    width: '100%',
    paddingLeft: Styles.Spacing.m3,
    paddingRight: Styles.Spacing.m3,
    marginLeft: 0,
    marginVertical: Styles.Spacing.m3,
    justifyContent: 'flex-start',
  },
});

export default AnnouncementView;
