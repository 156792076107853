import React from 'react';
import {View, StyleSheet, Linking, Text} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../ScreenContext';
import ScanditView from '../elements/ScanditView';
import NavActions from 'src/actions/NavActions';
import BaseScreen from './BaseScreen';
import Styles from '../Styles';
import AVText from '../elements/AVText';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../helpers/AlertHelper';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
type ConnectScreenProps = Record<string, any> & {
  navigation?: NavigationProp<ConnectScreen>;
};
type ConnectScreenState = {
  scanning: boolean;
  previousRoute?: string | null;
};

class ConnectScreen extends React.Component<
  ConnectScreenProps,
  ConnectScreenState
> {
  didFocusListener: any;
  willBlurListener: any;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ConnectScreenProps) {
    super(props);
    this.state = {
      scanning: true,
      previousRoute: null,
    };
    this.qrCodeScanned = this.qrCodeScanned.bind(this);
  }

  componentDidMount(): void {
    FirebaseAnalytic.trackEvent('componentDidMount', 'ConnectScreen', {
      ...this.props,
      ...this.state,
    });
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  async qrCodeScanned(url: string) {
    FirebaseAnalytic.trackEvent('qrCodeScanned', 'ConnectScreen', {
      ...this.props,
      ...this.state,
      url,
    });
    this.setState({
      scanning: false,
    });
    const canOpen = await Linking.canOpenURL(url);

    if (canOpen) {
      Linking.openURL(url);
      NavActions.pop();
    } else {
      alertError(
        Localized.Errors.unable_to_read_code_please_try_again,
        null,
        () => {
          this.setState({
            scanning: true,
          });
        },
      );
    }
  }

  render() {
    return (
      <BaseScreen
        title={Localized.Labels.connect}
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
      >
        <View style={styles.content}>
          <AVText style={styles.label}>
            {Localized.Labels.scan_qr_canteen_connect}
          </AVText>
          {this.state.scanning && (
            <Text
              accessible={true}
              accessibilityElementsHidden={false}
              importantForAccessibility="auto"
              accessibilityLabel="Viewfinder window is active"
            ></Text>
          )}
          <ScanditView
            style={styles.scanditContainer}
            barcodeScanned={this.qrCodeScanned}
            scanning={this.state.scanning}
          />
        </View>
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingTop: 15,
  },
  label: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f3,
    textAlign: 'center',
  },
  scanditContainer: {
    flex: 1,
    marginTop: 32,
  },
});
export default withForwardedNavigationParams<ConnectScreenProps>()(
  ConnectScreen,
);
