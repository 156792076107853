import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

export default class RefiveEmptyAnnouncementIcon extends React.Component {
  render() {
    return (
      <Svg width={119} height={118} viewBox="0 0 119 118" fill="none">
        <Path
          d="M106.248 46.698h-7.381M22.594 46.698h7.38M64.42 4.871v7.381M34.843 17.125l5.22 5.22M93.994 17.125l-5.22 5.22M70.115 29.178C47.632 48.804 23.424 73.49 14.822 82.389c-2.01 2.08-2.656 5.118-1.494 7.768.631 1.441 1.38 3.015 2.151 4.353.773 1.338 1.762 2.773 2.694 4.04 1.714 2.331 4.668 3.291 7.475 2.59 12.006-3 45.49-11.622 73.73-21.28M68.163 25.793s6.111 6.683 18.271 27.745c12.16 21.061 14.892 29.695 14.892 29.695M56.859 41.33s5.331 5.331 14.37 20.987c9.038 15.655 10.99 22.938 10.99 22.938M59.623 92.74l1.515 5.653c1.672 6.244-2.033 12.662-8.277 14.335s-12.662-2.032-14.335-8.277l-1.263-4.71"
          stroke="#3070ED"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    );
  }
}
