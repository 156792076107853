import React from 'react';
import {StyleSheet, View, FlatList} from 'react-native';
import Styles from '../../Styles';
import {IsConnectedProps} from 'src/types/Screen';
import AnnouncementView from 'src/components/elements/home/AnnouncementView';
import {AnnouncementType} from 'src/types/AnnouncementType';
import Localized from 'src/constants/AppStrings';
import HomeScreenTitles from 'src/components/elements/home/HomeScreenTitles';
type AnnouncementListProps = IsConnectedProps & {
  announcements: AnnouncementType[];
  onViewAll?: () => void;
  showHeader?: boolean;
  showViewAll?: boolean;
  notificationFlow?: boolean;
};

const AnnouncementList = (props: AnnouncementListProps) => {
  //Returns Announcement Item UI
  const renderAnnouncementItem = ({item}: {item: AnnouncementType}) => {
    return <AnnouncementView announcement={item} />;
  };
  const enableScroll = props.notificationFlow == true;

  const styles = StyleSheet.create({
    announcementListView: {
      paddingTop: props.notificationFlow ? 10 : Styles.Spacing.m1,
      width: '100%',
    },
    containerView: {
      flex: 1,
      paddingTop: props.notificationFlow ? 10 : 0,
      marginHorizontal: Styles.Spacing.m0,
    },
  });

  return (
    <View style={styles.containerView}>
      {props.showHeader && (
        <HomeScreenTitles
          label={Localized.Labels.announcements}
          isConnected
          showViewAll={props.showViewAll}
          onViewAll={props.onViewAll}
        />
      )}
      <FlatList
        renderItem={renderAnnouncementItem}
        scrollEnabled={enableScroll}
        style={styles.announcementListView}
        data={props.announcements}
        keyExtractor={(item, index) => index + item.title}
      />
    </View>
  );
};

export default AnnouncementList;
