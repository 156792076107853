import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, Rect} from 'react-native-svg';
const OffersLightBulb = (props) => (
  <Svg width={15} height={20} viewBox="0 0 15 20" fill="none" {...props}>
    <G id="lightbulb-light (1) 1" clipPath="url(#clip0_2295_19813)">
      <Path
        id="Vector"
        d="M11.8103 10.082C12.4269 9.17188 12.7885 8.07031 12.7885 6.875C12.7885 3.76953 10.3336 1.25 7.30773 1.25C4.28189 1.25 1.82696 3.76953 1.82696 6.875C1.82696 8.07031 2.18854 9.17188 2.80512 10.082C2.96117 10.3125 3.14006 10.5625 3.32275 10.8242C3.80612 11.5078 4.3542 12.2773 4.76906 13.0547C5.10781 13.6875 5.2905 14.3555 5.38565 15.0039H4.14867C4.06494 14.5352 3.92411 14.0781 3.69955 13.6562C3.32275 12.9531 2.8546 12.293 2.38645 11.6328C2.18854 11.3555 1.99062 11.0781 1.80031 10.7969C1.05052 9.68359 0.609009 8.33203 0.609009 6.875C0.609009 3.07812 3.60821 0 7.30773 0C11.0072 0 14.0064 3.07812 14.0064 6.875C14.0064 8.33203 13.5649 9.68359 12.8113 10.793C12.621 11.0742 12.4231 11.3516 12.2252 11.6289C11.757 12.2852 11.2889 12.9453 10.9121 13.6523C10.6875 14.0742 10.5467 14.5312 10.463 15H9.2298C9.32495 14.3516 9.50765 13.6836 9.84639 13.0508C10.2613 12.2695 10.8093 11.5 11.2927 10.8203C11.4792 10.5586 11.6543 10.3125 11.8103 10.0781V10.082ZM7.30773 3.75C5.62543 3.75 4.26285 5.14844 4.26285 6.875C4.26285 7.21875 3.98882 7.5 3.65388 7.5C3.31894 7.5 3.04491 7.21875 3.04491 6.875C3.04491 4.45703 4.95176 2.5 7.30773 2.5C7.64266 2.5 7.9167 2.78125 7.9167 3.125C7.9167 3.46875 7.64266 3.75 7.30773 3.75ZM5.58357 17.5C5.83477 18.2266 6.51225 18.75 7.30773 18.75C8.1032 18.75 8.78068 18.2266 9.03189 17.5H5.58357ZM4.26285 16.875V16.668C4.26285 16.4375 4.44555 16.25 4.67011 16.25H9.94535C10.1699 16.25 10.3526 16.4375 10.3526 16.668V16.875C10.3526 18.6016 8.99002 20 7.30773 20C5.62543 20 4.26285 18.6016 4.26285 16.875Z"
        fill="#066DFF"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_2295_19813">
        <Rect width={14.6154} height={20} fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default OffersLightBulb;
