import React, {useContext} from 'react';
import Styles from 'src/components/Styles';
import {
  Dimensions,
  ImageBackground,
  PixelRatio,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import AVText from 'src/components/elements/AVText';
import AVFormattedCurrency from 'src/components/elements/AVFormattedCurrency';
import {useAppSelector} from 'src/redux/hooks';
import Localized from 'src/constants/AppStrings';
import AccountStore from 'src/stores/AccountStore';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import MainConsumerContext from 'src/components/MainConsumerContext';
import {IsConnectedProps} from 'src/types/Screen';
import Util, {getTotalDisplayAmount} from 'src/Util';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import Settings from 'src/Settings';
import QRCode from 'react-native-qrcode-svg';
import BrightnessTouchable from 'src/components/elements/BrightnessTouchable';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';

type QRCodeHeaderProps = IsConnectedProps;

const width = Dimensions.get('window').width;

const QRCodeHeader = (props: QRCodeHeaderProps) => {
  const context = useContext(MainConsumerContext);

  const balances = useAppSelector((state) => state.account.account.balances);
  const totalAmount = getTotalDisplayAmount(balances);
  const defaultBalance = balances.find((b) => b.isDefault);

  return (
    <View style={styles.qrCodeView}>
      <LinearGradient
        colors={['rgba(255, 255, 255, 0.00)', '#FFF']}
        start={{x: 0, y: 0}}
        end={{x: 0, y: 1}}
        style={{
          width: '100%',
          height: 256,
          borderRadius: 8,
          flexShrink: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ImageBackground
          source={
            Settings.buildType === 'canteen'
              ? require('src/components/img/qrCodeBackgroundCanteen.png')
              : require('src/components/img/qrCodeBackground.png')
          }
          resizeMode="cover"
          style={[
            styles.qrCodeImage,
            {height: width * 0.75 - Styles.Spacing.m3 * 2 - 60},
          ]}
          imageStyle={styles.qrCodeBackgroundImage}
        >
          <View style={styles.qrcodeContainer}>
            <BrightnessTouchable
              style={styles.qrCodeInnerView}
              accessibilityLabel="QRCode"
              aria-label="QRCode"
              testID="QRCode"
              timeout={10000}
            >
              <QRCode value={AccountStore.getQrCode() ?? ''} size={width / 3} />
            </BrightnessTouchable>
          </View>
        </ImageBackground>
        <LinearGradient
          colors={['rgba(255, 255, 255, 0.00)', '#FFF']} // You can test with different colors or remove gradient.
          start={{x: 0, y: 0}}
          end={{x: 0, y: 1}}
          style={styles.imageGradient}
        />
        <View style={styles.imageGradientBottom} />
        <View style={styles.quickFundAndBalanceView}>
          <RoundedButton
            accessible={true}
            accessibilityRole="button"
            role="button"
            accessibilityHint="Double tap to navigate to the quick fund"
            accessibilityLabel={Localized.Buttons.quick_fund}
            aria-label={Localized.Buttons.quick_fund}
            testID={Localized.Buttons.quick_fund}
            text={Localized.Buttons.quick_fund}
            textStyle={styles.quickFundText}
            buttonType={ButtonType.outline}
            containerStyle={styles.quickFundButton}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            onPress={() => {
              FirebaseAnalytic.trackEvent('quickFundClick', 'QRCodeHeader', {
                ...props,
              });
              context.actions?.navigateToFunding(true, {
                transDate: Util.getCurrentDate(),
              });
            }}
          />
          <TouchableOpacity
            style={styles.balanceContainer}
            accessible={true}
            accessibilityHint="Double tap to navigate to the balances screen"
            accessibilityRole="button"
            role="button"
            accessibilityLabel={Localized.Labels.balance}
            aria-label={Localized.Labels.balance}
            testID={Localized.Labels.balance}
            onPress={() => {
              FirebaseAnalytic.trackEvent('myBalancesClick', 'QRCodeHeader', {
                ...props,
              });
              NavActions.navigate(AppRoutes.Balances);
            }}
          >
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
              style={styles.balanceText}
              accessibilityRole="text"
              accessible={true}
              accessibilityLabel={Localized.Labels.balance}
              aria-label={`${Localized.Labels.balance}, text`}
            >
              {Localized.Labels.balance + ': '}
            </AVText>
            <AVFormattedCurrency
              testID={'balanceFormattedCurrency'}
              accessibilityLabel={String(totalAmount)}
              aria-label={String(totalAmount)}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
              style={[
                styles.balanceValue,
                getDescriber().getBalanceValueTextColor(),
              ]}
              value={totalAmount}
              currency={defaultBalance?.currency ?? AccountStore.getCurrency()}
            />
          </TouchableOpacity>
        </View>
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  qrCodeView: {
    marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    marginTop: Styles.Spacing.m3,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: width - (Styles.Spacing.m3 + Styles.Spacing.m2) * 2,

    backgroundColor: '#fff',
    borderRadius: Styles.Spacing.m2,
    ...(Platform.OS === 'android' ? {paddingTop: 0} : {}),
    // overflow: 'hidden',
    // Shadow Styles for iOS
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.1,
    shadowRadius: 10,

    // Shadow Styles for Android
    elevation: 5,
  },
  qrCodeBackgroundImage: {
    borderTopLeftRadius: Styles.Spacing.m2,
    borderTopRightRadius: Styles.Spacing.m2,
  },
  qrcodeContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: -PixelRatio.roundToNearestPixel(40),
  },
  qrCodeInnerView: {
    padding: Styles.Spacing.m2,
    backgroundColor: Styles.white,
    borderRadius: 5,
  },
  qrCodeImage: {
    width: '100%',
    height: width * 0.75 - Styles.Spacing.m3 * 2 - 60,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageGradient: {
    width: '100%',
    height: PixelRatio.roundToNearestPixel(50),
    borderRadius: 8,
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageGradientBottom: {
    overflow: 'hidden',
    borderBottomRightRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    flex: 1,
    left: 0,
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: Styles.white,
    zIndex: 101,
    borderBottomLeftRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    height: PixelRatio.roundToNearestPixel(66),
  },
  quickFundAndBalanceView: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 200,
    marginBottom: Styles.Spacing.m2,
  },
  quickFundButton: {
    left: Styles.Spacing.m2,
    bottom: Styles.Spacing.m1,
    borderWidth: 1,
  },
  quickFundText: {
    fontFamily: Styles.FontFamily.robotoRegular,
    fontWeight: '700',
    fontSize: Styles.Fonts.f1,
  },
  balanceContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    right: Styles.Spacing.m2,
    bottom: Styles.Spacing.m1,
    minHeight: Styles.Heights.touchTargetHeight1,
  },
  balanceText: {
    color: Styles.darkColor,
    fontWeight: '400',
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.robotoRegular,
  },
  balanceValue: {
    fontSize: Styles.Fonts.f2,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.robotoRegular,
  },
});

export default QRCodeHeader;
