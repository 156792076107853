import React from 'react';
import Styles from 'src/components/Styles';
import {
  Dimensions,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import AVText from 'src/components/elements/AVText';
import Localized from 'src/constants/AppStrings';
import TagIcon from 'src/components/img/svg/TagIcon';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';

const width = Dimensions.get('window').width;

const OffersView = () => {
  return (
    <View style={styles.container}>
      <View style={styles.iconView}>
        <TagIcon size={Styles.Heights.h3} color={Styles.darkGreenRevolve} />
      </View>
      <View style={styles.offerTextView}>
        <AVText
          style={styles.offerText}
          numberOfLines={1}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
        >
          {Localized.Labels.offers}
        </AVText>
      </View>
      <View
        style={{
          alignSelf: 'center',
        }}
      >
        <TouchableOpacity
          style={[styles.viewOffer]}
          onPress={() => {
            FirebaseAnalytic.trackEvent('OffersTileClicked', 'OffersView');
            NavActions.navigate(AppRoutes.Offers);
          }}
          accessibilityRole="button"
          accessibilityLabel="OffersView"
          role="button"
          aria-label="OffersView"
          testID="OffersView"
        >
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
            testID="OffersView"
            adjustsFontSizeToFit={true}
          >
            {Localized.Labels.view_all}
          </AVText>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    shadowColor: '#000',
    borderRadius: Styles.Spacing.m2,
    shadowOffset: {width: 0, height: 1},
    elevation: 4,
    alignItems: 'flex-start',
    shadowRadius: 2,
    shadowOpacity: 0.4,
    justifyContent: 'center',
    width: width / 2 - Styles.Heights.h2,
    aspectRatio: 1.2,
  },
  iconView: {
    height: '44%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  offerTextView: {
    height: '28%',
    width: '100%',
    justifyContent: 'flex-start',
  },
  offerText: {
    width: '100%',
    textAlign: 'center',
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  // viewOffer: {
  //   //left: 24,
  //   bottom: 10,
  //   borderWidth: 1,
  //   paddingHorizontal: 2,
  //   paddingVertical: 1,
  // },
  viewOffer: {
    borderTopColor: Styles.darkGreenRevolve,
    borderBottomColor: Styles.darkGreenRevolve,
    borderLeftColor: Styles.darkGreenRevolve,
    borderRightColor: Styles.darkGreenRevolve,
    borderRadius: 32,
    borderWidth: Platform.OS === 'android' ? 1.2 : 1,
    paddingVertical: 10,
    paddingHorizontal: 10,
    bottom: 10,
    overflow: 'hidden',
  },
  viewOfferbtnStyle: {
    fontWeight: Platform.OS === 'android' ? 'bold' : '600',
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: Styles.Fonts.f0 + 4,
    color: Styles.darkGreenRevolve,
    textTransform: 'uppercase',
  },
});

export default OffersView;
