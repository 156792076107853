import * as React from 'react';
import Svg, {Circle, Path} from 'react-native-svg';
const DealIconUnreadDotRefive = (props) => (
  <Svg width={54} height={55} viewBox="0 0 54 55" fill="none" {...props}>
    <Path
      d="M0 29.5c0-13.807 11.193-25 25-25s25 11.193 25 25-11.193 25-25 25-25-11.193-25-25z"
      fill="#4588FD"
      fillOpacity={0.15}
    />
    <Path
      d="M13 19.875v8.176c0 .93.368 1.82 1.027 2.477l9.667 9.625a3.525 3.525 0 004.97 0l7.333-7.3a3.488 3.488 0 000-4.95l-9.667-9.625a3.524 3.524 0 00-2.488-1.023h-8.206A2.623 2.623 0 0013 19.875zm6.151 1.75c.467 0 .914.184 1.243.512a1.746 1.746 0 010 2.475 1.762 1.762 0 01-2.485 0 1.746 1.746 0 010-2.474c.33-.329.776-.513 1.242-.513z"
      fill="#3070ED"
    />
    <Circle cx={44} cy={10.5} r={10} fill="#A4DB57" />
  </Svg>
);
export default DealIconUnreadDotRefive;
