import {StyleSheet} from 'react-native';
import Styles from 'src/components/Styles';
import {OfferStylesDescriptor} from 'src/components/elements/offers/descriptor/DescriptorType';
import OffersLightBulbRefive from 'src/components/img/svg/OffersLightBulbRefive';

export function offerNoteIcon(): JSX.Element {
  return <OffersLightBulbRefive />;
}

export function offerStyles(): OfferStylesDescriptor {
  return StyleSheet.create({
    offerNoteContainer: {
      minHeight: 57,
      justifyContent: 'center',
      alignItems: 'flex-start',
      borderWidth: 1.5,
      borderRadius: Styles.Spacing.m1,
      borderColor: Styles.Colors.Refive.notificationBorderColor,
      backgroundColor: 'rgba(164, 219, 87, 0.30)',
      marginTop: Styles.Spacing.m3,
      marginHorizontal: Styles.Spacing.m3,
    },
    noteTextStyle: {
      color: Styles.Colors.PayNew.black01,
      fontWeight: '400',
      fontSize: Styles.Fonts.f8,
      paddingRight: Styles.Heights.h2,
      fontFamily: Styles.FontFamily.figtreeRegular,
      paddingLeft: Styles.Spacing.m2 + Styles.Spacing.m1,
      marginBottom: Styles.Spacing.m2 + Styles.Spacing.m1,
      marginTop: Styles.Spacing.m2,
    },
    noteStyle: {
      fontSize: Styles.Fonts.f8,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.Colors.PayNew.black01,
    },
    offerExpiry: {
      color: Styles.Colors.PayNew.darkHuesBase04,
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
      minHeight: 17,
      textAlign: 'center',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    appliedIcon: {marginTop: 2},
    offerTitleinModal: {
      left: 1,
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    offersWithImageText: {
      textAlign: 'center',
    },
    validityText: {
      textAlign: 'center',
    },
    offersWithImageView: {
      alignSelf: 'center',
    },
  });
}

export function getTextColor(): string {
  return Styles.Colors.PayNew.black01;
}
export function primaryColor(): string {
  return 'rgba(69, 136, 253, 0.15);';
}
export function deafultIconInnerColor(): string {
  return '#3070ED';
}
export function offerFontFamily(): string {
  return Styles.FontFamily.figtreeRegular;
}
export function buttonAndIconColor(): string {
  return Styles.Colors.Refive.activeFooterBlue;
}
export function addIconColor(): string {
  return Styles.Colors.Refive.activeFooterBlue;
}
export function checkIconSize(): number {
  return Styles.Spacing.m3;
}
export function getOfferModalTextColor(): string {
  return Styles.Colors.PayNew.black01;
}
export function descNumberofLines(): number | null {
  return null;
}
export function legaleseNumberofLines(): number | null {
  return null;
}

export function offerDetailsArrowGif(): JSX.Element {
  return null;
}

export default {
  offerNoteIcon,
  offerStyles,
  getTextColor,
  primaryColor,
  deafultIconInnerColor,
  offerFontFamily,
  buttonAndIconColor,
  addIconColor,
  checkIconSize,
  getOfferModalTextColor,
  offerDetailsArrowGif,
  descNumberofLines,
  legaleseNumberofLines,
};
