import moment from 'moment';
import MoblicoApi from 'src/api/MoblicoApi';
import PlatformProvider from 'src/services/PlatformProvider';
type TokenConfig = {
  token: string;
  tokenExpiry: number;
};
export default {
  applicationToken: {} as TokenConfig,
  userTokens: {} as Record<string, Promise<TokenConfig>>,

  getToken(email?: string) {
    if (email) {
      return this.getUserToken(email);
    }

    return this.getApplicationToken();
  },

  async getUserToken(email: string): Promise<string> {
    let userConfigPromise: Promise<TokenConfig> = this.userTokens[email];

    if (!userConfigPromise) {
      userConfigPromise = this.authenticate(email);
    }

    let userConfig = await userConfigPromise;

    if (this.hasTokenExpired(userConfig.tokenExpiry)) {
      userConfigPromise = this.authenticate(email);
    }

    userConfig = await userConfigPromise;
    return userConfig.token;
  },

  authenticate(email: string): Promise<TokenConfig> {
    const promise = MoblicoApi.authenticate(
      email,
      PlatformProvider.getPlatformName(),
    ) as Promise<TokenConfig>;
    this.userTokens[email] = promise;
    return promise;
  },

  async getApplicationToken(): Promise<string> {
    if (
      !this.applicationToken ||
      !this.applicationToken.token ||
      this.hasTokenExpired(this.applicationToken.tokenExpiry)
    ) {
      this.applicationToken = (await MoblicoApi.authenticate()) as TokenConfig;
    }

    return this.applicationToken.token;
  },

  hasTokenExpired(expiry: number) {
    const expiryMoment = moment(expiry);
    return moment().isAfter(expiryMoment);
  },
};
