import * as React from 'react';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

const PayWebLogo = (props) => (
  <Svg
    width={127}
    height={126}
    viewBox="0 0 127 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G clipPath="url(#clip0_17765_1134)" fill="#066DFF">
      <Path d="M53.076 67.965c0 3.952-3.397 6.662-8.786 6.662-5.39 0-8.82-2.743-8.82-7.642h4.671c.066 2.58 1.895 3.952 4.377 3.952 2.123 0 3.789-1.208 3.789-3.266 0-1.796-1.34-2.94-3.985-2.94h-2.254v-3.756h1.993c2.515 0 3.788-1.274 3.788-3.07 0-1.862-1.567-2.842-3.527-2.842-2.22 0-3.789 1.372-3.854 3.494h-4.606c.066-4.279 3.528-7.185 8.525-7.185 5.161 0 8.23 2.416 8.23 6.173 0 2.776-1.73 4.41-3.984 4.997 2.45.49 4.442 2.09 4.442 5.422zM54.588 63.229c0-7.643 3.658-11.856 9.407-11.856 4.279 0 7.643 2.45 8.165 6.63h-4.638c-.425-1.83-1.861-2.842-3.887-2.842-2.94 0-4.605 2.352-4.605 6.565v.556c.098-.164.163-.294.294-.49 1.046-1.634 3.038-2.678 5.389-2.678 4.605 0 7.74 3.136 7.74 7.74 0 4.67-3.429 7.773-8.525 7.773-3.2 0-5.65-1.306-7.25-3.592-1.34-1.927-2.09-4.638-2.09-7.807v.001zm9.374-.457c-2.32 0-3.92 1.633-3.92 4.083s1.535 4.083 3.854 4.083c2.32 0 3.854-1.665 3.854-4.083s-1.47-4.083-3.788-4.083zM91.529 66.691c0 5.259-3.92 7.937-8.656 7.937-5.291 0-8.557-3.364-8.753-7.544h4.67c.23 2.058 1.666 3.69 4.115 3.69 1.993 0 3.888-1.372 3.888-4.083 0-2.416-1.535-4.115-3.92-4.115-1.273 0-3.037.49-3.854 2.385h-4.54l.653-13.392h15.025v3.953H79.41l-.261 4.997c1.143-1.046 2.646-1.666 4.67-1.666 4.606 0 7.709 3.168 7.709 7.838z" />
      <Path d="M63.5 118.007C33.17 118.007 8.493 93.331 8.493 63 8.493 32.67 33.17 7.993 63.5 7.993c30.33 0 55.007 24.676 55.007 55.007h-6.261c0-26.879-21.867-48.746-48.746-48.746S14.754 36.121 14.754 63c0 26.88 21.867 48.746 48.746 48.746v6.261z" />
      <Path d="M63.5 105.485c-23.426 0-42.485-19.06-42.485-42.485h6.26c0 19.974 16.25 36.224 36.225 36.224 19.974 0 36.224-16.25 36.224-36.224 0-19.974-16.25-36.224-36.224-36.224v-6.261c23.426 0 42.485 19.06 42.485 42.485 0 23.426-19.06 42.485-42.485 42.485z" />
    </G>
    <Defs>
      <ClipPath id="clip0_17765_1134">
        <Path
          fill="#fff"
          transform="translate(7.599 7.099)"
          d="M0 0H111.803V111.803H0z"
        />
      </ClipPath>
    </Defs>
  </Svg>
);

export default PayWebLogo;
