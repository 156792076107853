import * as React from 'react';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';
type LocationMarkerProps = {
  size?: number;
  color?: string;
};

export default class LocationMarker extends React.Component<LocationMarkerProps> {
  render() {
    return (
      <Svg
        width={this.props.size ?? 24}
        height={this.props.size ?? 24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <G clipPath="url(#clip0_574_7714)">
          <Path
            d="M18.424 8.712a6.712 6.712 0 10-12.487 3.42L11.711 22l5.776-9.867a6.68 6.68 0 00.936-3.421zm-6.712 2.902a2.902 2.902 0 110-5.804 2.902 2.902 0 010 5.804z"
            fill={this.props.color ?? '#003349'}
          />
        </G>
        <Defs>
          <ClipPath id="clip0_574_7714">
            <Path
              fill="#fff"
              transform="translate(5 2)"
              d="M0 0H13.4237V20H0z"
            />
          </ClipPath>
        </Defs>
      </Svg>
    );
  }
}
