import * as React from 'react';
import {Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import Settings from 'src/Settings';
import {getDescriber} from 'src/components/elements/orderAhead/descriptor/DescriptorType';

const {recentOrderButtonStyle} = getDescriber();

const color = Platform.select({
  web: Styles.lightGray,
  default: Settings.isNewUI() ? Styles.black : Styles.white,
});
type Props = {
  onPress: () => void;
  showIcon?: boolean;
};

export default function RecentOrdersButton(props: Props) {
  return (
    <TouchableOpacity
      accessible={true}
      accessibilityLabel={Localized.Buttons.recent_orders}
      accessibilityRole="button"
      accessibilityHint="Double tap to navigate to the recent orders screen"
      accessibilityState={{disabled: false}}
      aria-label={Localized.Buttons.recent_orders}
      role="button"
      onPress={props.onPress}
      style={styles.recentOrderButton}
    >
      {props.showIcon && (
        <FontAwesome5Pro
          aria-hidden={true}
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
          name="calendar-alt"
          color={color}
          size={Styles.Fonts.f2 * 0.8}
          light
          style={[styles.recentOrderIcon, recentOrderButtonStyle().marginStyle]}
        />
      )}
      <View>
        <Text
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          style={[
            recentOrderButtonStyle().recentOrderText,
            recentOrderButtonStyle().marginStyle,
          ]}
        >
          {Localized.Buttons.recent_orders}
        </Text>
        {!props.showIcon && <View style={styles.textUnderline} />}
      </View>
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  recentOrderButton: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: Styles.Heights.touchTargetHeight2,
    maxWidth: Styles.Heights.splashIconHeight,
  },
  recentOrderIcon: {
    alignSelf: 'center',
  },
  textUnderline: {
    borderBottomWidth: 1,
    borderBottomColor: Styles.Colors.PayNew.black01,
    width: '100%',
  },
});
