import Localized from 'src/constants/AppStrings';
import Styles from 'src/components/Styles';
import WelcomeRefiveLogo from 'src/components/img/svg/WelcomeRefiveLogo';

export function getLogoGradientProps() {
  return {
    colors: [
      'rgba(69, 136, 253, 0.15)', // Gradient color 1 with opacity
      'rgba(69, 136, 253, 0.15)', // Gradient color 2 with opacity
    ],
    start: {x: 0, y: 0},
    end: {x: 0, y: 1},
  };
}

export function getWelcomeLogoImage() {
  return <></>;
}

export function getLoginLogo(): JSX.Element {
  return <WelcomeRefiveLogo />;
}

export function getWelcomeDescriptionText(): string {
  return Localized.Labels.Welcome_refive;
}

export function getWelcomeDescriptionTextStyle() {
  return {
    color: '#022649',
    textAlign: 'center',
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '700',
  };
}

export function getLoginButtonColor() {
  return Styles.Colors.PayNew.primary01;
}

export default {
  getLogoGradientProps,
  getWelcomeLogoImage,
  getLoginLogo,
  getWelcomeDescriptionTextStyle,
  getWelcomeDescriptionText,
  getLoginButtonColor,
};
