export function generateErrorMessage(error: any) {
  let messageError = '';
  if (error instanceof Error) {
    messageError = error.message;
  } else {
    messageError = error?.toString() ?? '';
  }

  return messageError;
}
