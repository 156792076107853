import Settings from 'src/Settings';
import RedesignDescriptor from './app-tour-redesign';
import RefiveDescriptor from './app-tour-refive';
import BuildTypes from 'src/constants/BuildTypeConstants';

const typeDescriber = {
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export function getAppTourDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
