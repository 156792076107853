import * as React from 'react';
import {
  Platform,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import Styles from '../../../Styles';

import Localized from 'src/constants/AppStrings';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {AppDispatch} from 'src/redux/store';
import InboxScreen from 'src/components/screens/inbox/InboxScreen';
import BackSubheader from 'src/components/elements/BackSubheader';
import {loadAllMessages} from 'src/redux/thunks/snackThunks';
import Settings from 'src/Settings';
import AVText from 'src/components/elements/AVText';
import {getDescriber} from '../../../../components/elements/descriptor/DescriptorType';
import {getDescriber as inboxDescriber} from '../../../../components/screens/inbox/descriptor/DescriptorType';
import {getNotificationDescriber} from '../../../../components/elements/notificationDescriptor/DescriptorType';

type InboxScreenNewUIProps = {
  selectedTab?: string;
  route?: {
    [index: string]: {[index: string]: number | string};
  };
};

export enum filterTypes {
  all = 'all',
  active = 'active',
  past = 'past',
}

const Tab = createMaterialTopTabNavigator();
const bordercolor = getNotificationDescriber().tabBarRoundedBtnColor();
const fontFamilyRevolve = Settings.isRefive()
  ? {fontFamily: Styles.FontFamily.figtreeRegular}
  : {fontFamily: Styles.FontFamily.robotoRegular};
const fontWeightVerify = (focused: boolean): TextStyle | null => {
  return !focused ? {fontWeight: '400'} : {fontWeight: '700'};
};
const focuseTabVerticalHeight =
  Settings.isRefive() && Platform.OS === 'android' ? 8 : 9;

export const InboxTab1 = (props) => {
  return (
    <InboxScreen
      {...props}
      key={'InboxTab1'}
      navigation={props.navigation}
      route={props.route}
    />
  );
};

export const InboxTab2 = (props) => {
  return (
    <InboxScreen
      {...props}
      key={'InboxTab2'}
      navigation={props.navigation}
      route={props.route}
    />
  );
};
class InboxScreenNewUI extends React.Component<InboxScreenNewUIProps> {
  render() {
    const checkDefaultSelect =
      this.props.route?.params?.selectedTab || this.props.selectedTab;
    const tabBarIndAndroidStyle = () => {
      return {
        height: '100%',
        backgroundColor: getDescriber().activeTabShadowColor(),
        padding: 20,
      };
    };
    const tabBarIndIOSStyle = () => {
      return {
        backgroundColor: 'transparent',
        height: Styles.Heights.h1 * 0.3,
      };
    };

    return (
      <BackSubheader title={Localized.Labels.notifications}>
        <View
          style={[
            styles.content,
            Platform.OS !== 'web' &&
              getDescriber().dealStyleDescriptor()['inboxTabBgColor'],
          ]}
        >
          <Tab.Navigator
            backBehavior="none"
            initialRouteName={
              checkDefaultSelect === Localized.Labels.messages
                ? Localized.Labels.messages
                : Localized.Labels.announcements
            }
            screenOptions={() => ({
              tabBarScrollEnabled: true,
              tabBarActiveTintColor: Styles.primaryColor,
              tabBarInactiveTintColor: Styles.lightGray,
              tabBarStyle: {},
              tabBarIndicatorStyle:
                Platform.OS == 'android'
                  ? tabBarIndAndroidStyle()
                  : tabBarIndIOSStyle(),
            })}
          >
            {inboxDescriber().notificationTabscDescriptor()}
          </Tab.Navigator>
        </View>
      </BackSubheader>
    );
  }
}

export const getTabOptions = (label) => ({
  tabBarLabel: ({focused}) => {
    return (
      <View style={styles.container}>
        <TouchableOpacity
          accessibilityHint={label}
          role="button"
          accessible={true}
          aria-label={label}
          testID={label}
          accessibilityRole="button"
          accessibilityLabel={label}
          style={[
            styles.button,
            !focused
              ? {
                  borderWidth: 1,
                  borderBottomColor: '#BCBCBC',
                  borderTopColor: '#BCBCBC',
                  borderStartColor: '#BCBCBC',
                  borderEndColor: '#BCBCBC',
                  backgroundColor: Styles.white,
                  paddingHorizontal: Styles.Spacing.m3,
                  paddingVertical: Styles.Spacing.m2,
                }
              : {
                  borderWidth: Platform.OS === 'android' ? 0 : 1,
                  borderBottomColor: bordercolor,
                  borderTopColor: bordercolor,
                  borderStartColor: bordercolor,
                  borderEndColor: bordercolor,
                  backgroundColor:
                    getNotificationDescriber().tabBarRoundedBtnColor(),
                  paddingHorizontal: Platform.OS === 'android' ? 0 : 5,
                  paddingVertical: focuseTabVerticalHeight,
                  justifyContent: 'center',
                },
          ]}
        >
          <AVText
            style={[
              fontWeightVerify(focused),
              {...fontFamilyRevolve, fontSize: 18},
              getNotificationDescriber().tabBarTextColorColor(focused),
            ]}
            testID={label}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
          >
            {label}
          </AVText>
        </TouchableOpacity>
      </View>
    );
  },
  tabBarItemStyle: {
    marginRight: -8,
    width: 'auto',
    top: Platform.OS === 'web' ? 5 : 10,
    marginLeft: -3,
  },
  tabBarAccessibilityLabel: `${label}`,
  tabBarTestID: `${label}`,
  tabBarStyle: {
    backgroundColor: 'transparent',
    marginLeft: 5,
    marginRight: 5,
    paddingBottom: 1,
    ...(Platform.OS === 'web' ? {zIndex: 0} : {}),
  },
});

const styles = StyleSheet.create({
  content: {
    flex: 1,
    marginTop: getDescriber().getInboxTabStyles()['marginTop'],
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
  },
  button: {
    display: 'flex',
    paddingVertical: 8,
    paddingHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    alignSelf: 'stretch',
    borderRadius: 32,
    borderWidth: 1,
    borderColor: '#CFCFCF',
    backgroundColor: '#FFF',
    overflow: 'hidden',
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 1,
    shadowRadius: 4,
    elevation: 5,
  },
});

export default compose(
  withForwardedNavigationParams(),
  connect(null, (dispatch: AppDispatch) => ({
    loadSnacks: (params) => dispatch(loadAllMessages(params)).unwrap(),
  })),
)(InboxScreenNewUI);
