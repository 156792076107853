import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  color?: string;
  size?: number;
}
const CalendarIcon = ({size = 24, color = '#22212D'}: IProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      fill={color}
      fillRule="evenodd"
      d="M7 6.286c.79 0 1.429-.639 1.429-1.429V3.43C8.429 2.639 7.79 2 7 2s-1.429.638-1.429 1.429v1.428c0 .79.639 1.429 1.429 1.429Zm10 0c.79 0 1.429-.639 1.429-1.429V3.43C18.429 2.639 17.79 2 17 2s-1.429.638-1.429 1.429v1.428c0 .79.639 1.429 1.429 1.429Zm2.143-2.857h2.5c.196 0 .357.16.357.357v17.857c0 .196-.16.357-.357.357H2.357A.358.358 0 0 1 2 21.643V3.786c0-.197.16-.357.357-.357h2.5v1.817C4.857 6.25 5.93 7 7 7c1.071 0 2.143-.75 2.143-1.754V3.429h5.714v1.817C14.857 6.25 15.964 7 17.036 7c1.071 0 2.107-.75 2.107-1.754V3.429Zm-15 16.428h15.714V8.43H4.143v11.428Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default CalendarIcon;
