import * as React from 'react';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';

const RewardsFocussedIcon = () => (
  <Svg width={35} height={34} viewBox="0 0 35 34" fill="none">
    <G clipPath="url(#clip0_16833_745)">
      <Path
        d="M16.276 6.255A4.583 4.583 0 0012.331 4h-.112a4.469 4.469 0 00-3.981 6.5h-1.3A2.438 2.438 0 004.5 12.938v3.25c0 1.06.68 1.965 1.625 2.3v8.262A3.253 3.253 0 009.375 30h16.25a3.253 3.253 0 003.25-3.25v-8.262a2.444 2.444 0 001.625-2.3v-3.25a2.438 2.438 0 00-2.438-2.438h-1.3A4.469 4.469 0 0022.782 4h-.111c-1.62 0-3.123.858-3.946 2.255L17.5 8.342 16.276 6.26v-.005zm11.787 6.683v3.25H19.125v-3.25h8.938zm-12.188 0v3.25H6.938v-3.25H15.875zm0 5.687v8.938h-6.5a.815.815 0 01-.813-.813v-8.125h7.313zm3.25 8.938v-8.938h7.313v8.125a.815.815 0 01-.813.813h-6.5zM22.781 10.5H19.06l1.767-3.006a2.14 2.14 0 011.843-1.056h.112a2.03 2.03 0 110 4.062zm-6.906 0h-3.656a2.03 2.03 0 110-4.063h.112c.756 0 1.462.402 1.843 1.057L15.94 10.5h-.066z"
        fill="#3070ED"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_16833_745">
        <Path fill="#fff" transform="translate(4.5 4)" d="M0 0H26V26H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default RewardsFocussedIcon;
