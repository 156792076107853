import * as React from 'react';
import Svg, {Mask, Path, G} from 'react-native-svg';
interface IProps {
  color?: string;
  width?: number;
  height?: number;
}
const AddIconBold = ({color = '#025D33', width = 16, height = 17}: IProps) => (
  <Svg width={width} height={height} fill="none">
    <Mask id="a" width={14} height={15} x={1} y={1} maskUnits="userSpaceOnUse">
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M1.333 1.833h13.334v13.334H1.334V1.834Z"
        clipRule="evenodd"
      />
    </Mask>
    <G mask="url(#a)">
      <Path
        fill={color}
        fillRule="evenodd"
        d="M13.643 7.476H9.024V2.858a1.024 1.024 0 0 0-2.048 0v4.618H2.358a1.024 1.024 0 0 0 0 2.048h4.618v4.619a1.024 1.024 0 0 0 2.048 0V9.524h4.619a1.024 1.024 0 0 0 0-2.048Z"
        clipRule="evenodd"
      />
    </G>
  </Svg>
);
export default AddIconBold;
