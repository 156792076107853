import React from 'react';
import {View, StyleSheet, Text, TouchableOpacity} from 'react-native';
import CustomRadioButton from 'src/components/elements/CustomRadioButton';
import DiningIcon from 'src/components/img/svg/DiningIcon';
import StorageIcon from 'src/components/img/svg/StorageIcon';
import TogoIcon from 'src/components/img/svg/TogoIcon';
import Styles from 'src/components/Styles';
import type {PickupLocation} from 'src/types/Location';
import {getDescriber} from './descriptor/DescriptorType';

const {
  pickupLocationListStyles,
  pickupLocationInnerCircleBackgroundColor,
  pickupLocationOuterCircleBorderColor,
  getActiveIconColor,
  getInActiveIconColor,
} = getDescriber();

type PickupLocationListProps = {
  item: PickupLocation;
  index?: number;
  selectPickupLocation?: (item) => void;
  selectedValue?: boolean;
};
const PickupLocationList = (props: PickupLocationListProps) => {
  const {item, selectPickupLocation, selectedValue} = props;
  const onPressLocation = (locationItem) => {
    selectPickupLocation(locationItem);
  };
  const iconColors = selectedValue
    ? getActiveIconColor()
    : getInActiveIconColor();
  return (
    <TouchableOpacity
      key={item.pickupLocationId + '-' + Date.now()}
      style={
        selectedValue
          ? pickupLocationListStyles().cellSelected
          : pickupLocationListStyles().cell
      }
      onPress={() => onPressLocation(item)}
      accessible={true}
      accessibilityRole="button"
      role="button"
    >
      <View accessibilityLabel={item.name + ', '} style={styles.leftView}>
        <CustomRadioButton
          selected={selectedValue}
          accessibilityLabel={item.name}
          aria-label={item.name}
          onPress={() => onPressLocation(item)}
          touchableAreaPaddingStyle={styles.touchableAreaPaddingStyle}
          outerCircleStyle={{
            ...styles.outerCircleStyle,
            ...(selectedValue && {
              borderColor: pickupLocationOuterCircleBorderColor(),
            }),
          }}
          innerCircleStyle={styles.innerCircleStyle}
        />
        <Text
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
          accessibilityRole="text"
          aria-label={item.name}
          style={
            selectedValue
              ? pickupLocationListStyles().selectedLabel
              : pickupLocationListStyles().label
          }
          accessibilityLabel={item.name}
        >
          {item.name}
        </Text>
      </View>
      <View
        style={styles.rightView}
        accessibilityLabel={'icons'}
        aria-label={'icons'}
      >
        {item.hasDineIn ? (
          <View
            accessible={true}
            accessibilityLabel="Dine In, "
            aria-label="Dine In, "
          >
            <DiningIcon color={iconColors} />
          </View>
        ) : null}
        {item.hasToGo ? (
          <View
            accessible={true}
            accessibilityLabel="To Go, "
            aria-label="To Go, "
          >
            <TogoIcon color={iconColors} />
          </View>
        ) : null}
        {item.hasLocker ? (
          <View accessibilityLabel="Locker" aria-label="Locker">
            <StorageIcon color={iconColors} />
          </View>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};
export default PickupLocationList;
const styles = StyleSheet.create({
  touchableAreaPaddingStyle: {
    padding: 0,
  },
  outerCircleStyle: {
    height: 29,
    width: 29,
    borderRadius: Styles.Heights.h5,
    borderWidth: 2,
    overflow: 'hidden',
  },
  innerCircleStyle: {
    height: 19,
    width: 19,
    borderRadius: Styles.Heights.h5,
    backgroundColor: pickupLocationInnerCircleBackgroundColor(),
    overflow: 'hidden',
  },
  leftView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightView: {
    paddingLeft: Styles.Heights.h2 + 4,
    alignItems: 'center',
    flexDirection: 'row',
  },
});
