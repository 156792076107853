import * as React from 'react';
import Svg, {Circle, Path} from 'react-native-svg';

function NotificationUnReadLogo(props) {
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 44 44" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.898 27.43c-.187-.172-1.711-1.719-1.711-6.6a7.142 7.142 0 00-1.758-4.538 6.619 6.619 0 00-2.146-1.674.677.677 0 000-.123A2.506 2.506 0 0021.765 12a2.506 2.506 0 00-2.517 2.495.677.677 0 000 .123 6.619 6.619 0 00-2.146 1.674 7.142 7.142 0 00-1.758 4.537c0 4.873-1.52 6.416-1.707 6.588a1.292 1.292 0 00-.581 1.488 1.4 1.4 0 001.373.985h4.476a.672.672 0 000 .934A3.94 3.94 0 0021.715 32a3.94 3.94 0 002.81-1.176.672.672 0 000-.907h4.536a1.4 1.4 0 001.373-.985 1.297 1.297 0 00-.536-1.501zM21.78 13.344c.48.04.894.346 1.071.79a5.3 5.3 0 00-2.142 0 1.267 1.267 0 011.071-.79zm0 2.042c2.902 0 5.035 2.903 5.035 5.444a17.06 17.06 0 00.535 4.537H16.21a17.06 17.06 0 00.536-4.537c0-2.54 2.133-5.444 5.034-5.444zm-.032 15.262a2.543 2.543 0 01-1.83-.748h3.615a2.537 2.537 0 01-1.785.748zm-7.29-2.105a4.415 4.415 0 001.272-1.815h12.105c.262.703.7 1.327 1.273 1.815h-14.65z"
        fill={props?.color ?? '#21AD5A'}
      />
      <Circle
        cx={22}
        cy={22}
        r={13.25}
        stroke={props?.color ?? '#00854D'}
        strokeWidth={1.5}
      />
      <Circle cx={35} cy={9} r={7} fill="#EB0014" />
    </Svg>
  );
}

const NotificationUnReadIcon = React.memo(NotificationUnReadLogo);
export default NotificationUnReadIcon;
