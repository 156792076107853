import * as React from 'react';
import Svg, {Circle, Path} from 'react-native-svg';

function notificationUnReadLogoRefive(props) {
  return (
    <Svg width={44} height={45} viewBox="0 0 44 45" fill="none" {...props}>
      <Path
        d="M25.5 29.5a3.5 3.5 0 11-7 0v-1.167h-5.833S15 23.667 15 19c0-3.813 3.187-7 7-7s7 3.187 7 7c0 3.5 2.333 9.333 2.333 9.333H25.5V29.5z"
        fill="#A4DB57"
      />
      <Path
        d="M18.5 28.333V29.5a3.502 3.502 0 004.84 3.234A3.5 3.5 0 0025.5 29.5v-1.167m-7 0h-5.833S15 23.667 15 19c0-3.813 3.187-7 7-7s7 3.187 7 7c0 3.5 2.333 9.333 2.333 9.333H25.5m-7 0h7"
        stroke="#0F893D"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Circle cx={33} cy={11.5} r={7} fill="#D7263D" />
    </Svg>
  );
}

const NotificationUnReadIconRefive = React.memo(notificationUnReadLogoRefive);
export default NotificationUnReadIconRefive;
