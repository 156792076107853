import * as React from 'react';
import Svg, {Path, G, Rect, Defs, ClipPath, SvgProps} from 'react-native-svg';

export default class Tag extends React.Component<SvgProps> {
  render() {
    return (
      <Svg
        width="103"
        height="117"
        viewBox="0 0 103 117"
        fill="none"
        {...this.props}
      >
        <G clip-path="url(#clip0_1542_6969)">
          <Path
            d="M7.625 52.4443V18.2812C7.625 16.2702 9.27031 14.6249 11.2812 14.6249H45.4443C47.3867 14.6249 49.2377 15.4019 50.6088 16.773L90.8275 56.9917C93.684 59.8482 93.684 64.487 90.8275 67.3435L60.3207 97.8275C57.4643 100.684 52.8254 100.684 49.9689 97.8275L9.7502 57.6087C8.3791 56.2376 7.60215 54.3866 7.60215 52.4443H7.625ZM0.3125 52.4443C0.3125 56.329 1.84355 60.0538 4.58574 62.796L44.8045 103.015C50.5174 108.728 59.7723 108.728 65.4852 103.015L95.992 72.5079C101.705 66.795 101.705 57.5402 95.992 51.8273L55.7732 11.6085C53.0311 8.86634 49.3062 7.33528 45.4215 7.33528H11.2812C5.22559 7.31243 0.3125 12.2255 0.3125 18.2812V52.4443ZM25.9062 38.3906C27.3608 38.3906 28.7558 37.8127 29.7843 36.7842C30.8128 35.7557 31.3906 34.3607 31.3906 32.9062C31.3906 31.4516 30.8128 30.0567 29.7843 29.0281C28.7558 27.9996 27.3608 27.4218 25.9062 27.4218C24.4517 27.4218 23.0567 27.9996 22.0282 29.0281C20.9997 30.0567 20.4219 31.4516 20.4219 32.9062C20.4219 34.3607 20.9997 35.7557 22.0282 36.7842C23.0567 37.8127 24.4517 38.3906 25.9062 38.3906Z"
            fill={this.props.color || '#727179'}
          />
        </G>
        <Defs>
          <ClipPath id="clip0_1542_6969">
            <Rect
              width="102.375"
              height="117"
              fill="white"
              transform="translate(0.3125)"
            />
          </ClipPath>
        </Defs>
      </Svg>
    );
  }
}
