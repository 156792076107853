import React from 'react';
import moment, {Moment} from 'moment';
import ActionsFactory from 'src/actions/ActionsFactory';
import Settings from 'src/Settings';
import AppRoutes from 'src/AppRoutes';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import Localized from 'src/constants/AppStrings';
import BuildTypes from 'src/constants/BuildTypeConstants';
import type {Referral} from 'src/types/Referral';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type CreateAccountPrivacyPolicyHandlerProps = {
  params?: {
    location?: string;
    referral?: Referral;
  };
  isFromCreateAccount?: boolean;
  onAcceptPrivacyCallback?: () => void;
};

export class CreateAccountPrivacyPolicyHandler extends React.Component<CreateAccountPrivacyPolicyHandlerProps> {
  currentPrivacyVersion = '';
  currentPrivacyType = '';
  requestPromise!: Promise<void>;
  startTime: Moment;

  constructor(props: CreateAccountPrivacyPolicyHandlerProps) {
    super(props);
    this.startTime = moment();
    this.onPrivacyDecline = this.onPrivacyDecline.bind(this);
    this.onPrivacyConfirm = this.onPrivacyConfirm.bind(this);
    this.onPrivacyConfirmFromCreateAccount =
      this.onPrivacyConfirmFromCreateAccount.bind(this);
  }

  componentDidMount() {
    this.requestPromise = ActionsFactory.getAccountActions()
      .getCurrentPrivacyVersion()
      .then((response) => {
        if (response?.status === 'ok') {
          this.currentPrivacyVersion = response.version || '';
          this.currentPrivacyType = response.type || '';
        }
      })
      .catch((error) => {
        CrashlyticsEvents.log(
          'Exception',
          'CreateAccountPrivacyPolicyHandler:getCurrentPrivacyVersion',
          'generateErrorMessage(error)',
        );
        Events.Error.trackEvent(
          'Exception',
          'CreateAccountPrivacyPolicyHandler:getCurrentPrivacyVersion',
          'generateErrorMessage(error)',
        );
      });
  }

  onPrivacyConfirm() {
    let route = AppRoutes.ExistingAccount;

    if (
      Settings.buildType === BuildTypes.social ||
      Settings.buildType === BuildTypes.pantry
    ) {
      route = AppRoutes.CreateAccountEnterEmail;
    }

    let params = {
      privacyVersion: this.currentPrivacyVersion,
      startTime: this.startTime,
    };

    if (this.props.params) {
      params = {...params, ...this.props.params};
    }

    NavActions.replace(route, params);
  }

  onPrivacyDecline() {
    Events.AccountCreation.trackEvent(this.startTime, AppRoutes.PrivacyPolicy);
    NavActions.pop();
  }

  onPrivacyConfirmFromCreateAccount() {
    NavActions.pop();
    this.props.onAcceptPrivacyCallback?.();
  }

  async showPrivacyPolicy() {
    await this.requestPromise;
    const params = {
      title: Localized.Labels.privacy_policy,
      onDecline: this.onPrivacyDecline,
      onConfirm: this.props.isFromCreateAccount
        ? this.onPrivacyConfirmFromCreateAccount
        : this.onPrivacyConfirm,
      locale: Settings.getLocale(),
      version: this.currentPrivacyVersion,
      type: this.currentPrivacyType,
    };
    NavActions.push(AppRoutes.PrivacyPolicy, params);
  }

  render() {
    return null;
  }
}

export default CreateAccountPrivacyPolicyHandler;
