import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
type PickupTimeMarkerProps = {
  height?: number;
  width?: number;
  color?: string;
};
const PickupTimeMarker = ({
  color = '#111111',
  height = 24,
  width = 18,
}: PickupTimeMarkerProps) => (
  <Svg width={width} height={height} viewBox="0 0 18 24" fill="none">
    <Path
      d="M16.3429 9C16.3429 4.85625 13.0186 1.5 8.91429 1.5C4.81 1.5 1.48571 4.85625 1.48571 9C1.48571 9.73125 1.73643 10.7344 2.25643 11.9719C2.7625 13.1859 3.47286 14.5031 4.28071 15.8203C5.86393 18.4125 7.735 20.8922 8.91893 22.3828C10.0982 20.8922 11.9739 18.4125 13.5571 15.8203C14.3604 14.5031 15.0754 13.1813 15.5814 11.9719C16.0921 10.7344 16.3429 9.73125 16.3429 9ZM17.8286 9C17.8286 13.0969 12.3964 20.3906 10.0146 23.4C9.44357 24.1172 8.385 24.1172 7.81393 23.4C5.43214 20.3906 0 13.0969 0 9C0 4.03125 3.99286 0 8.91429 0C13.8357 0 17.8286 4.03125 17.8286 9ZM6.68571 9C6.68571 9.59674 6.92051 10.169 7.33845 10.591C7.75639 11.0129 8.32323 11.25 8.91429 11.25C9.50534 11.25 10.0722 11.0129 10.4901 10.591C10.9081 10.169 11.1429 9.59674 11.1429 9C11.1429 8.40326 10.9081 7.83097 10.4901 7.40901C10.0722 6.98705 9.50534 6.75 8.91429 6.75C8.32323 6.75 7.75639 6.98705 7.33845 7.40901C6.92051 7.83097 6.68571 8.40326 6.68571 9ZM8.91429 12.75C7.9292 12.75 6.98445 12.3549 6.28789 11.6517C5.59133 10.9484 5.2 9.99456 5.2 9C5.2 8.00544 5.59133 7.05161 6.28789 6.34835C6.98445 5.64509 7.9292 5.25 8.91429 5.25C9.89938 5.25 10.8441 5.64509 11.5407 6.34835C12.2372 7.05161 12.6286 8.00544 12.6286 9C12.6286 9.99456 12.2372 10.9484 11.5407 11.6517C10.8441 12.3549 9.89938 12.75 8.91429 12.75Z"
      fill={color}
    />
  </Svg>
);
export default PickupTimeMarker;
