import {useEffect, useState} from 'react';
import Styles from 'src/components/Styles';
import {Platform, StyleSheet} from 'react-native';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import AccountStore from 'src/stores/AccountStore';
import {getDescriber} from './descriptor/DescriptorType';
import {useAppSelector} from 'src/redux/hooks';
import {
  getMaxPointValueDiff,
  getMaxRewardProductsThreshold,
} from 'src/components/elements/rewards/rewardsUtil';
const lineHeightMultiplier = 1.14;

const RewardsView = () => {
  const account = useAppSelector((s) => s.account.account);
  const [isRewardsEnabled, setIsRewardsEnabled] = useState(false);
  const {rewardTier, rewardStatuses} = AccountStore.getRewardStatus();
  const {rewardProducts} = AccountStore.getRewardProducts();
  const productThresholds =
    rewardProducts?.map((product) => product.pointsRequired) ?? [];

  const getRewardPoints = () => {
    if (account.rewardPrograms?.length > 0) {
      return account.rewardPrograms[0]?.rewardPoints ?? 0;
    } else {
      return 0;
    }
  };

  const getMaxPointLabel = () => {
    return getMaxRewardProductsThreshold(rewardProducts) + '+';
  };

  const maxPointsLabel = getMaxPointLabel();
  const maxPointValue = getMaxPointValueDiff(productThresholds, rewardProducts);
  const marketAccountPoints = getRewardPoints();

  useEffect(() => {
    checkRewardsStatus();
    FirebaseAnalytic.trackEvent('componentDidMount', 'RewardsView');
    AccountStore.addChangeListener(checkRewardsStatus);
    return () => {
      AccountStore.removeChangeListener(checkRewardsStatus);
    };
  }, []);

  //checkRewardsStatus based on User and Home location settings
  const checkRewardsStatus = async () => {
    if (rewardStatuses?.length > 0) {
      setIsRewardsEnabled(true);
    } else {
      setIsRewardsEnabled(false);
    }
  };

  const onRewardsClick = () => {
    FirebaseAnalytic.trackEvent('onRewardsClick', 'RewardsView');
    NavActions.navigate(AppRoutes.Rewards, {screen: AppRoutes.RewardsScreen});
  };

  const describerprops = {
    marketAccountPoints,
    maxPointsLabel,
    styles,
    rewardTier,
    bgSize,
    tileHeight,
    productThresholds,
    maxPointValue,
    onRewardsClick,
  };

  return isRewardsEnabled
    ? getDescriber().rewardsViewDescriptor(describerprops)
    : null;
};

const tileHeight = 134;
const bgSize = tileHeight * 2;

const styles = StyleSheet.create({
  shadowWrapper: {
    paddingHorizontal: Styles.Spacing.m3,
    flexDirection: 'row',
    borderRadius: Styles.Spacing.m2,
    paddingRight: Styles.Spacing.m3,
    borderColor: '#E7E7E7',
    padding: 16,
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    justifyContent: 'center',
    height: tileHeight,
    ...Platform.select({
      android: {
        elevation: 6,
      },
    }),
  },
  container: {
    height: tileHeight,
    borderRadius: Styles.Spacing.m2,
    ...Platform.select({
      ios: {
        shadowOpacity: 0.07,
        shadowColor: Styles.black,
        shadowRadius: 2,
        shadowOffset: {width: 0, height: 4},
      },
    }),
  },
  tierIcon: {
    opacity: 0.35,
    position: 'absolute',
    transform: [
      {translateY: (tileHeight - bgSize) / 1.75},
      {translateX: -(tileHeight - bgSize) / 0.88},
      {rotate: '20deg'},
    ],
  },
  content: {
    marginRight: -Styles.Spacing.m4,
    flex: 1,
    marginTop: Styles.Spacing.m2,
    flexGrow: 1,
    paddingLeft: 16,
  },
  rewardStatus: {flexDirection: 'column', gap: 4, marginTop: -4},
  chevron: {
    alignSelf: 'center',
    ...Platform.select({
      default: {marginRight: -Styles.Spacing.m2},
      web: {marginRight: Styles.Spacing.m2},
    }),
  },
  payEarnEnjoy: {
    height: '50%',
    fontWeight: '700',
    fontSize: 18,
    lineHeight: 18 * lineHeightMultiplier,
    color: Styles.Colors.PayNew.black01,
  },
  rewardStatustxt: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 16 * lineHeightMultiplier,
    color: Styles.Colors.PayNew.black01,
  },
  currentTierName: {
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 16 * lineHeightMultiplier,
    color: Styles.Colors.PayNew.black01,
  },
});

export default RewardsView;
