import Localized from 'src/constants/AppStrings';
import AppApi from 'src/api/AppApi';
import {alertError} from './AlertHelper';
import PlatformApi from 'src/api/PlatformApi';
import Logger from 'src/logging/Logger';
export async function linkPayroll(
  importId?: string,
  importLocationId?: string,
  accountId?: string,
  accountLocationId?: string,
): Promise<boolean> {
  if (importId && accountId && importLocationId) {
    try {
      if (!accountLocationId) {
        // If they don't have a location, add them to the import location
        await PlatformApi.getLocationFromId(importLocationId);
      }

      const payrollResponse = await AppApi.linkPayroll(importId, accountId);
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'LinkPayroll',
        JSON.stringify({accountId, importId}),
        JSON.stringify(payrollResponse),
      );
      return true;
    } catch (e) {
      if (e?.message.includes("Locations don't match")) {
        alertError(Localized.Errors.payroll_link_location_error);
      } else if (e?.message.includes('Identifier(6)')) {
        alertError(Localized.Errors.payroll_id_exists_error);
      } else if (e?.message.includes('Identifier(0)')) {
        alertError(Localized.Errors.scan_code_exists_error);
      } else if (e?.message.includes('Internal Server Error')) {
        alertError(Localized.Errors.internet_issue);
      } else {
        alertError(Localized.Errors.email_already_exists);
      }
    }
  }

  return false;
}
