import React from 'react';
import Commonutils from 'src/components/elements/newui/commonutils';

type SectionProps = {
  children: React.ReactNode;
  accessibilityLabel?: string;
  title: string;
  switchStatus?: boolean;
  onSwitchToggle?: () => void | null | Promise<void>;
};

export default function Section(props: SectionProps) {
  return Commonutils.renderCommonSection(props);
}
