import * as React from 'react';
import Svg, {Circle, Path} from 'react-native-svg';
type UnreadSignUpRewardProps = {
  style?: {[index: string]: {[index: string]: number | string}};
  width?: number;
  color?: string;
  height?: number;
};
export default class UnreadSignUp extends React.Component<UnreadSignUpRewardProps> {
  render() {
    return (
      <Svg
        viewBox="0 0 54 55"
        height={this.props.height}
        fill="none"
        width={this.props.width}
      >
        <Path
          fill="#066DFF"
          d="M0 29.5c0-13.807 11.193-25 25-25s25 11.193 25 25-11.193 25-25 25-25-11.193-25-25z"
        />
        <Path
          clipRule="evenodd"
          fill="#FEE0E6"
          fillRule="evenodd"
          d="M25.374 19.186a5.226 5.226 0 100 10.453 5.226 5.226 0 000-10.453zm-.63 11.487c-3.647 0-7.076.928-10.054 2.559l-.574.313v5.104h13.202v-4.4h2.104v-2.744c-.947-.39-2.689-.834-4.677-.832zm6.16 2.236v2.702h-2.582v2.344l2.666-.018.013 2.72h2.344v-2.702h2.583V35.61h-2.583V32.91h-2.44z"
        />
        <Circle fill="#E2607F" cy={10.5} r={10} cx={44} />
      </Svg>
    );
  }
}
