import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  PermissionsAndroid,
  Platform,
  FlatList,
  Linking,
  AppState,
  NativeEventSubscription,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import withIsConnected from '../../hoc/withIsConnected';
import BaseScreen from '../BaseScreen';
import AVText from '../../elements/AVText';
import Styles from '../../Styles';
import NavActions from 'src/actions/NavActions';
import SearchField from '../../elements/cart/SearchField';
import RequirePermission from '../../elements/RequirePermission';
import Contacts from 'react-native-contacts';
import Events from 'src/logging/Events';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Settings from 'src/Settings';
import CustomSearchbar from 'src/components/elements/CustomSearchField';
import SearchIcon from 'src/components/img/svg/SearchIcon';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import ContactListErrorLogo from 'src/components/img/svg/ContactListErrorLogo';

type ContactItem = {
  firstName: string;
  lastName: string;
  email: string;
};

type ChooseContactScreenProps = {
  onContactSelect: (arg0: string) => void;
};

type ChooseContactScreenState = {
  loading: boolean;
  contacts: Array<ContactItem>;
  filteredContacts: Array<ContactItem>;
  searchText: string;
  contactsAccessCheck: boolean;
};

class ChooseContactScreen extends React.Component<
  ChooseContactScreenProps,
  ChooseContactScreenState
> {
  static contextType = ScreenContext;
  handleAppStateChangeSubscription: NativeEventSubscription;
  appState: string;
  constructor(props: ChooseContactScreenProps) {
    super(props);
    this.appState = 'active';
    this.renderContactItem = this.renderContactItem.bind(this);
    this.getKeyForListItem = this.getKeyForListItem.bind(this);
    this.onClickContact = this.onClickContact.bind(this);
    this.onSearchTextChanged = this.onSearchTextChanged.bind(this);
    this.chooseContactStateChange = this.chooseContactStateChange.bind(this);
    this.state = {
      loading: false,
      contacts: [],
      filteredContacts: [],
      searchText: '',
      contactsAccessCheck: false,
    };
  }

  componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'ChooseContactScreen', {
      ...this.props,
      ...this.state,
    });
    this.checkContactsPermission();
    if (Platform.OS === 'ios') {
      this.loadContacts();
    }
    this.handleAppStateChangeSubscription = AppState.addEventListener(
      'change',
      this.chooseContactStateChange,
    );
  }

  chooseContactStateChange(currentAppState: string) {
    if (currentAppState !== this.appState) {
      this.appState = currentAppState;
      if (currentAppState === 'active') {
        this.checkContactsPermission();
      }
    }
  }
  handleChangeSettings = () => {
    if (Platform.OS === 'ios') {
      Linking.openURL('app-settings:');
    } else {
      Linking.openSettings();
    }
  };
  checkContactsPermission = async () => {
    const permission = await Contacts.checkPermission();
    if (permission === 'authorized') {
      this.setState({contactsAccessCheck: true});
      this.loadContacts();
    }
  };

  onPermissionChange = (granted: string) => {
    if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      this.setState({contactsAccessCheck: true});
      this.loadContacts();
    }
  };

  async loadContacts() {
    const contactsArray: Array<ContactItem> = [];

    try {
      const contacts = await Contacts.getAll();
      contacts.map((contact) => {
        contact.emailAddresses?.forEach((emailAddress) => {
          contactsArray.push({
            firstName: contact.givenName,
            lastName: contact.familyName,
            email: emailAddress.email,
          });
        });
      });
      contactsArray.sort(function (a, b) {
        const nameA = a.firstName.toLowerCase();
        const nameB = b.firstName.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      this.setState(
        {
          contacts: contactsArray,
          loading: false,
        },
        () => {
          FirebaseAnalytic.trackEvent('loadContacts', 'ChooseContactScreen', {
            ...this.props,
            ...this.state,
          });
        },
      );
    } catch (err) {
      this.setState({
        loading: false,
      });
      CrashlyticsEvents.log(
        'Exception',
        'ChooseContactScreen:loadContacts',
        err.message ? err.message : err.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'ChooseContactScreen:loadContacts',
        err.message ? err.message : err.toString(),
      );
    }

    Contacts.checkPermission();
  }

  getKeyForListItem(item) {
    return item.email;
  }

  onClickContact(email) {
    FirebaseAnalytic.trackEvent('onClickContact', 'ChooseContactScreen', {
      ...this.props,
      ...this.state,
      email,
    });
    this.props.onContactSelect(email);
    NavActions.pop();
  }

  onSearchTextChanged(value) {
    const contacts = this.state.contacts;
    const searchText = value.trim().toLowerCase();
    const filteredContacts = contacts.filter((contact) => {
      return (
        `${contact.firstName} ${contact.lastName}`
          .toLowerCase()
          .startsWith(searchText) ||
        contact.lastName.toLowerCase().startsWith(searchText)
      );
    });
    this.setState(
      {
        searchText: value,
        filteredContacts,
      },
      () => {
        FirebaseAnalytic.trackEvent(
          'onSearchTextChanged',
          'ChooseContactScreen',
          {
            ...this.props,
            ...this.state,
          },
        );
      },
    );
  }

  renderContactItem({item}) {
    return (
      <TouchableOpacity onPress={() => this.onClickContact(item.email)}>
        <View style={styles.contactItemContainer}>
          <AVText
            style={styles.nameTextStyle}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
          >
            {item.firstName} {item.lastName}
          </AVText>
          <AVText
            style={styles.emailTextStyle}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
          >
            {item.email}
          </AVText>
        </View>
      </TouchableOpacity>
    );
  }

  render() {
    return (
      <BaseScreen title={Localized.Labels.choose_contact}>
        <View style={styles.content}>
          {Settings.isRefiveAnd365Pay() ? (
            <>
              <CustomSearchbar
                onChangeText={this.onSearchTextChanged}
                value={this.state.searchText}
                placeholder={Localized.Labels.search_contacts}
                containerStyle={[styles.searchWrapper]}
                elevation={2}
                inputStyle={styles.searchInput}
                searchIcon={<SearchIcon />}
              />
              {this.state.contactsAccessCheck && (
                <FlatList
                  refreshing={false}
                  data={
                    this.state.searchText === ''
                      ? this.state.contacts
                      : this.state.filteredContacts
                  }
                  renderItem={this.renderContactItem}
                  keyExtractor={this.getKeyForListItem}
                />
              )}
              {!this.state.contactsAccessCheck && (
                <View style={styles.errorView}>
                  <View style={styles.errorLogo}>
                    <ContactListErrorLogo />
                  </View>
                  <AVText
                    accessible={true}
                    accessibilityHint={
                      Localized.Labels.choose_contact_permission_error
                    }
                    accessibilityLabel={
                      Localized.Labels.choose_contact_permission_error
                    }
                    aria-label={
                      Localized.Labels.choose_contact_permission_error
                    }
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                    style={styles.errorText}
                  >
                    {Localized.Labels.choose_contact_permission_error}
                  </AVText>

                  <RoundedButton
                    accessible={true}
                    accessibilityRole="button"
                    role="button"
                    accessibilityHint={Localized.Buttons.go_to_settings}
                    accessibilityLabel={Localized.Buttons.go_to_settings}
                    aria-label={Localized.Buttons.go_to_settings}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                    containerStyle={styles.settingBtn}
                    backgroundColor={Styles.white}
                    buttonType={ButtonType.outline}
                    color={Styles.footerTabActive}
                    textStyle={styles.errorButton}
                    onPress={async () => {
                      this.handleChangeSettings();
                    }}
                    text={Localized.Buttons.go_to_settings}
                  />
                </View>
              )}
            </>
          ) : (
            <>
              <SearchField
                onChangeText={this.onSearchTextChanged}
                strings={Localized}
                value={this.state.searchText}
                isChooseContact={true}
              />
              <FlatList
                refreshing={false}
                data={
                  this.state.searchText === ''
                    ? this.state.contacts
                    : this.state.filteredContacts
                }
                renderItem={this.renderContactItem}
                keyExtractor={this.getKeyForListItem}
              />
            </>
          )}

          <RequirePermission
            permission={PermissionsAndroid.PERMISSIONS.READ_CONTACTS}
            onPermissionChange={this.onPermissionChange}
          />
        </View>
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingHorizontal: Settings.isNewUI() ? Styles.Spacing.m2 : 0,
  },
  contactItemContainer: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  nameTextStyle: {
    color: Styles.black,
    fontSize: Styles.Fonts.f2,
    marginHorizontal: Styles.Spacing.m3,
  },
  emailTextStyle: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    marginHorizontal: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m1,
  },
  searchWrapper: {
    borderRadius: 32,
    backgroundColor: Styles.white,
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m3,
    height: 51,
    borderWidth: 1,
    borderColor: Styles.Colors.PayNew.neutralHuesBase09,
    alignItems: 'center',
    ...Styles.Style.shadow,
    borderBottomWidth: Platform.OS === 'android' ? 1 : 0,
  },

  searchInput: {
    fontSize: Styles.Fonts.f2,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: Styles.Colors.PayNew.darkHuesBase04,
  },
  settingBtn: {
    alignSelf: 'center',
    marginTop: Styles.Spacing.m2,
    paddingHorizontal: 12,
    borderWidth: 2,
  },
  errorView: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingHorizontal: Styles.Fonts.f0 + Styles.Fonts.f0,
    top: Styles.Fonts.f1 * 3,
  },
  errorLogo: {
    alignSelf: 'center',
    marginBottom: 14,
  },
  errorText: {
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
    textAlign: 'center',
    marginBottom: Styles.Fonts.f0,
  },
  errorButton: {
    color: Styles.footerTabActive,
    fontSize: Styles.Fonts.f1,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
});

export default withForwardedNavigationParams()(
  withIsConnected(ChooseContactScreen),
);
