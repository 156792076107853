import * as React from 'react';
import {
  PixelRatio,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import Styles from '../Styles';
import AVText from './AVText';
import AVTouchableOpacity from './AVTouchableOpacity';
import {TouchableOpacityProps} from 'react-native';
import Settings from 'src/Settings';
import {getButtonDescriber} from './buttonDescriptor/DescriptorType';

export enum ButtonType {
  normal = 'normal',
  outline = 'outline',
  action = 'action',
  normalWhite = 'normalWhite',
  innerWhite = 'innerWhite',
}
interface RoundedButtonProps extends TouchableOpacityProps {
  onPress?: () => void;
  text: string;
  containerStyle?: StyleProp<ViewStyle>;
  wrapperStyle?: StyleProp<ViewStyle>;
  maxFontSizeMultiplier?: number;
  textStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  buttonType: ButtonType;
  color?: string;
  accessibilityLabel?: string;
  accessibilityHint?: string;
  icon?: React.ReactNode;
  addShadow?: boolean;
  hideAccessibleElements?: boolean;
  backgroundColor?: string;
  isProductSearch?: boolean;
}
export default function RoundedButton(props: RoundedButtonProps) {
  const roundedBtnDescriberProps = {
    disabled: props.disabled,
    color: props.color,
    buttonType: props.buttonType,
  };

  return (
    <AVTouchableOpacity
      accessible={true}
      accessibilityElementsHidden={props?.hideAccessibleElements}
      importantForAccessibility={
        props?.hideAccessibleElements ? 'no-hide-descendants' : 'yes'
      }
      accessibilityRole={'button'}
      accessibilityLabel={props?.accessibilityLabel}
      accessibilityHint={props?.accessibilityHint}
      activeOpacity={0.2}
      disabled={props.disabled}
      style={[
        styles.container,
        props.buttonType === 'action' && styles.actionButton,

        props.containerStyle,
        {
          backgroundColor: props.backgroundColor
            ? props.backgroundColor
            : getButtonDescriber() &&
              getButtonDescriber()['roundedBtnColorDescriptor'] &&
              getButtonDescriber()['roundedBtnColorDescriptor'](
                roundedBtnDescriberProps,
              )['fillColor'],
          borderColor:
            getButtonDescriber() &&
            getButtonDescriber()['roundedBtnColorDescriptor'] &&
            getButtonDescriber()['roundedBtnColorDescriptor'](
              roundedBtnDescriberProps,
            )['buttonColor'],
        },
        props.buttonType !== 'action' && {paddingVertical: Styles.Spacing.m2},
      ]}
      onPress={props.onPress}
    >
      <View style={[styles.insideButton, props.wrapperStyle]}>
        {props.icon && <View style={styles.iconWrapper}>{props.icon}</View>}

        <AVText
          maxFontSizeMultiplier={
            props.maxFontSizeMultiplier ?? Styles.FontSizeMultiplier.maxfm8
          }
          style={[
            styles.text,
            {
              color:
                getButtonDescriber() &&
                getButtonDescriber()['roundedBtnColorDescriptor'] &&
                getButtonDescriber()['roundedBtnColorDescriptor'](
                  roundedBtnDescriberProps,
                )['textColor'],
            },
            props.buttonType === 'action' && styles.actionButtonText,
            props.textStyle,
            props.buttonType === 'action' &&
              Settings.isRefive() && {
                fontFamily: Styles.FontFamily.figtreeRegular,
              },
          ]}
        >
          {props.text}
        </AVText>
      </View>
    </AVTouchableOpacity>
  );
}
const styles = StyleSheet.create({
  actionButton: {
    overflow: 'hidden',
    borderRadius: Styles.Spacing.m4,
    bottom: Styles.Spacing.m3,
    elevation: 6,
    paddingHorizontal: Styles.Spacing.m2,
    paddingVertical: 8,
    position: 'absolute',
    right: Styles.Spacing.m3,
    shadowColor: Styles.overlay,
    shadowOffset: {
      width: 4,
      height: 8,
    },
    shadowOpacity: 0.4,
    shadowRadius: Styles.Spacing.m2,
  },
  actionNoShadowButton: {
    overflow: 'hidden',
    borderRadius: Styles.Spacing.m4,
    bottom: Styles.Spacing.m3,
    elevation: 6,
    paddingHorizontal: Styles.Spacing.m2,
    paddingVertical: 8,
    position: 'absolute',
    right: Styles.Spacing.m3,
  },
  actionButtonText: {
    fontSize: Styles.Fonts.f1,
  },
  container: {
    overflow: 'hidden',
    alignSelf: 'flex-start',
    backgroundColor: Styles.white,
    borderColor: Styles.primaryColor,
    borderRadius: PixelRatio.roundToNearestPixel(
      Styles.Spacing.m3 + Styles.Spacing.m2,
    ),
    borderWidth: PixelRatio.roundToNearestPixel(0.5),
  },
  insideButton: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: PixelRatio.roundToNearestPixel(0.5),
    paddingVertical: PixelRatio.roundToNearestPixel(4),
    paddingHorizontal: 12,
  },
  iconWrapper: {
    marginRight: Styles.Spacing.m2,
  },
  text: {
    color: Styles.primaryColor,
    fontWeight: 'bold',
  },
  textFont: {
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
});
