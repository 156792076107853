import {initialState as accountInitialState} from './slices/accountSlice';
import {initialState as environmentInitialState} from './slices/environmentSlice';
import {initialState as menuInitialState} from './slices/menuSlice';
import {initialState as snackInitialState} from './slices/snackSlice';
import {initialState as campusLocationInitialState} from './slices/campusLocationSlice';

export const initialState = {
  account: accountInitialState,
  environment: environmentInitialState,
  menu: menuInitialState,
  snack: snackInitialState,
  campusLocation: campusLocationInitialState,
};
