import React from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  Platform,
  TouchableOpacity,
} from 'react-native';
import {withGlobalize} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../ScreenContext';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import Util, {getClosestValue} from 'src/Util';
import RoundedButton, {ButtonType} from '../elements/RoundedButton';
import withIsConnected from '../hoc/withIsConnected';
import Styles from '../Styles';
import type {IsConnectedProps} from 'src/types/Screen';
import ActionsFactory from 'src/actions/ActionsFactory';
import UIManager from '../elements/ui/UIManager';
import type {RewardType} from 'src/types/Rewards';
import Localized from 'src/constants/AppStrings';
import {alertError, alertSuccess} from '../helpers/AlertHelper';
import AVText from '../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import {RootState, store} from 'src/redux/store';
import {
  adjustDefaultBalance,
  adjustPoints,
} from 'src/redux/slices/accountSlice';
import {connect} from 'react-redux';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
import BaseScreen from 'src/components/screens/BaseScreen';
import Settings from 'src/Settings';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';

import {getDescriber} from 'src/components/screens/descriptor/DescriptorType';
import LoadingScreen from 'src/components/screens/LoadingScreen';

type RewardsScreenProps = IsConnectedProps & {
  availablePoints: number;
  currentBalance: number;
  fulldata?: {[index: string]: {[index: string]: number | string}};
  navigation?: NavigationProp<RewardsScreen>;
};
type RewardsScreenState = {
  rewardSteps: Array<RewardType>;
  availablePoints: number;
  currentBalance: number;
  previousRoute?: string | null;
  nextAvailablePoint: number;
};

class RewardsScreen extends React.Component<
  RewardsScreenProps,
  RewardsScreenState
> {
  redeeming: boolean;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: RewardsScreenProps) {
    super(props);
    this.state = {
      rewardSteps: [],
      availablePoints: props.availablePoints,
      currentBalance: props.currentBalance,
      previousRoute: null,
      nextAvailablePoint: 0,
    };
    this.redeeming = false;
    this.redeemRewards = this.redeemRewards.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<RewardsScreenProps>): void {
    if (prevProps.currentBalance !== this.props.currentBalance) {
      this.setState({currentBalance: this.props.currentBalance});
    }
  }

  async componentDidMount() {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );

    let rewardSteps;
    try {
      const response = AccountStore.getThresholdsResponse();

      if (response?.status === 'ok') {
        rewardSteps = response.data;
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'RewardsScreen:Constructor',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'RewardsScreen:Constructor',
        generateErrorMessage(error),
        guid,
      );
      alertError(Localized.Errors.error, guid);
    }

    this.setState(
      {
        rewardSteps,
        previousRoute,
      },
      () => {
        FirebaseAnalytic.trackEvent('componentDidMount', 'RewardsScreen', {
          ...this.props,
          ...this.state,
        });
        const fetchPointsArr = this.state.rewardSteps?.map(
          (item) => item.points,
        );
        if (fetchPointsArr && fetchPointsArr.length > 0) {
          const nextHighestVal = getClosestValue(
            this.state.availablePoints,
            fetchPointsArr,
          );
          this.setState({
            nextAvailablePoint: nextHighestVal - this.state.availablePoints,
          });
        }
      },
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.availablePoints !== prevState.availablePoints) {
      return {
        availablePoints: nextProps.availablePoints,
        currentBalance: nextProps.currentBalance,
      };
    }
  }

  async redeemRewards(points: number, redeemValue: number) {
    if (!this.redeeming) {
      try {
        this.redeeming = true;
        await ActionsFactory.getAccountActions().redeemRewards(
          AccountStore.getAccountId(),
          points,
          Util.getCurrentDate(),
        );
        Events.RewardsRedeem.trackEvent(points, redeemValue);
        alertSuccess(
          Localized.Success.formatString(
            Localized.Success.hasBeenAddedToYourAccount,
            Util.formatCurrency(
              this.props,
              redeemValue,
              AccountStore.getCurrency(),
            ),
          ),
          () => {
            store.dispatch(
              adjustDefaultBalance({
                amount: redeemValue,
                reason: Localized.Labels.redeem,
              }),
            );
            store.dispatch(adjustPoints({points}));
          },
        );
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'RewardsScreen:RedeemRewards',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'RewardsScreen:RedeemRewards',
          generateErrorMessage(error),
          guid,
        );
        alertError(Localized.Errors.error, guid);
      } finally {
        this.redeeming = false;
      }
    }
  }

  render() {
    const isNewUi = Settings.isNewUI();
    let i = 0;
    const currency = AccountStore.getDisplayCurrency();

    const redeemList = this.state.rewardSteps?.map((reward) => {
      const available = reward.points <= this.state.availablePoints;
      return (
        <View
          key={i++}
          style={[styles.redeemList, available && styles.enableRedeemList]}
        >
          <View
            accessible={true}
            accessibilityRole={'text'}
            style={styles.amountAndCurrencyContainer}
          >
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              accessible={false}
              style={styles.currency}
            >
              {currency}
            </AVText>
            <AVText
              adjustsFontSizeToFit
              numberOfLines={1}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              accessible={false}
              style={styles.redeemAmount}
            >
              {reward.redeemvalue}
            </AVText>
          </View>

          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            style={styles.redeemExplanation}
          >
            {Localized.Labels.formatString(
              Localized.Labels.redeem_explanation,
              Util.formatNumber(this.props, reward.points, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              `${currency}${reward.redeemvalue}`,
            )}
          </AVText>
          {this.props.isConnected && (
            <RoundedButton
              accessibilityLabel={`Redeem ${reward.points} points`}
              text={Localized.Labels.redeem}
              onPress={() =>
                this.redeemRewards(reward.points, reward.redeemvalue)
              }
              containerStyle={styles.btnStyle}
              disabled={!available}
              buttonType={ButtonType.outline}
            />
          )}
        </View>
      );
    });
    const payredeemList = this.state.rewardSteps?.map((reward) => {
      const available = reward.points <= this.state.availablePoints;
      return (
        <React.Fragment>
          <AVTouchableOpacity
            aria-label="onRewardsClick"
            accessible={true}
            accessibilityLabel="onRewardsClick"
            accessibilityHint="Double tap to navigate to the rewards screen"
            accessibilityRole="button"
            role="button"
            style={{top: -25}}
          >
            <View
              style={[
                {marginLeft: 6, marginRight: 6, height: 90},
                styles.container,
                {paddingHorizontal: 12, paddingVertical: 12},
                {flex: 1, flexDirection: 'row'},
              ]}
            >
              <View style={[styles.leftView]}>
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                  accessible={false}
                  style={{
                    fontSize: 20,
                    fontFamily: Styles.FontFamily.aeonikRegular,
                    fontWeight: '700',
                    color: '#111',
                  }}
                >
                  {currency}
                  {reward.redeemvalue}
                </AVText>
                <View>
                  <AVText
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                    style={[
                      styles.redeemExplanation,
                      {
                        fontSize: 16,
                        fontFamily: Styles.FontFamily.aeonikRegular,
                        fontWeight: '400',
                        color: '#111',
                        marginLeft: 0,
                        paddingTop: 4,
                      },
                    ]}
                  >
                    {Localized.Labels.formatString(
                      Localized.Labels.redeem_explanation,
                      Util.formatNumber(this.props, reward.points, {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      }),
                      `${currency}${reward.redeemvalue}`,
                    )}
                  </AVText>
                </View>
              </View>
              <View style={[styles.rightView, {marginVertical: 10, right: 5}]}>
                {this.props.isConnected && (
                  <TouchableOpacity
                    style={[
                      styles.viewOffer,

                      !available
                        ? {
                            backgroundColor: '#707070',
                            borderTopColor: '#707070',
                            borderBottomColor: '#707070',
                            borderLeftColor: '#707070',
                            borderRightColor: '#707070',
                            borderColor: '#707070',
                          }
                        : {
                            backgroundColor: '#066DFF',
                            borderTopColor: '#066DFF',
                            borderBottomColor: '#066DFF',
                            borderLeftColor: '#066DFF',
                            borderRightColor: '#066DFF',
                            borderColor: Styles.Colors.PayNew.primary01,
                          },
                    ]}
                    accessibilityRole="button"
                    accessibilityLabel={`Redeem ${reward.points} points`}
                    role="button"
                    aria-label={`Redeem ${reward.points} points`}
                    testID={`Redeem ${reward.points} points`}
                    onPress={() => {
                      if (available)
                        this.redeemRewards(reward.points, reward.redeemvalue);
                    }}
                  >
                    <AVText
                      style={styles.viewOfferbtnStyle}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
                      testID={Localized.Labels.redeem}
                      adjustsFontSizeToFit={true}
                    >
                      {Localized.Labels.redeem}
                    </AVText>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </AVTouchableOpacity>
        </React.Fragment>
      );
    });
    return (
      <BaseScreen
        title={Localized.Labels.rewards}
        previousRoute={this.state.previousRoute}
        accessibilityLabel={isNewUi ? Localized.Labels.rewards : 'Back arrow'}
        accessibilityHint={
          isNewUi
            ? Localized.Labels.rewards
            : `Press to navigate back to the ${this.state.previousRoute} screen`
        }
        rightView={
          Platform.OS !== 'web' &&
          !Settings.is365Pay() &&
          UIManager.getBalanceContainer(false, Localized, false)
        }
        hideBack={isNewUi && Platform.OS !== 'web'}
      >
        {this.state.rewardSteps?.length > 0 ? (
          <View style={styles.content}>
            {getDescriber().renderYourBalanceRewardLbl(styles, AccountStore)}
            {UIManager.getHomeRewardSection(
              this.state.availablePoints,
              this.state.rewardSteps,
              styles.imageContainer,
              false,
            )}
            {getDescriber().renderPointsToNextRewardLbl(
              styles,
              this.state.nextAvailablePoint,
            )}
            <ScrollView style={styles.list}>
              {Settings.is365Pay() ? payredeemList : redeemList}
            </ScrollView>
          </View>
        ) : (
          <LoadingScreen />
        )}
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  btnStyle: {
    marginTop: Styles.Spacing.m2,
  },
  content: {
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  amountAndCurrencyContainer: {
    flex: 0.35,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: Styles.Spacing.m1,
  },
  currency: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  enableRedeemList: {
    borderBottomColor: Styles.positiveColor,
  },
  imageContainer: {
    height: 90,
    marginBottom: Styles.Spacing.m4,
    marginTop: -1 * Styles.Spacing.m2,
  },
  list: {
    marginTop: Styles.Spacing.m2,
  },
  redeemAmount: {
    color: Styles.black,
    fontSize: Styles.Fonts.f4,
  },
  redeemExplanation: {
    flex: 1,
    flexWrap: 'wrap',
    fontSize: Styles.Fonts.f0,
    marginLeft: Styles.Spacing.m1,
    marginRight: Styles.Spacing.m3,
  },
  redeemList: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: Styles.Spacing.m3,
    paddingHorizontal: Styles.Spacing.m2,
  },
  payBalance: {
    fontSize: 20,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: '#111',
    position: 'relative',
    width: '100%',
    paddingVertical: 4,
    left: 5,
  },
  container: {
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    //marginHorizontal: 24,
    paddingHorizontal: Styles.Spacing.m3,

    borderRadius: Styles.Spacing.m2,
    justifyContent: 'center',
    marginTop: 24,
    shadowColor: '#000',
    shadowOpacity: 0.4,
    shadowOffset: {width: 1, height: 1},
    elevation: 5,
    shadowRadius: 2,
  },
  leftView: {
    flex: 0.7,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rightView: {
    flex: 0.3,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  viewOffer: {
    borderRadius: 32,
    borderWidth: Platform.OS === 'android' ? 1.2 : 1,
    paddingVertical: 8,
    paddingHorizontal: 12,
    overflow: 'hidden',
  },
  viewOfferbtnStyle: {
    fontWeight: Platform.OS === 'android' ? 'bold' : '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f0 + 2,
    color: '#fff',
  },
  pointsToNextRwd: {
    fontSize: 15,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '400',
    color: '#111',
    left: 8,
    top: -10,
  },
});

const ConnectedRewardsScreen = connect((state: RootState) => ({
  availablePoints: state.account.account.points,
  currentBalance: state.account.account.defaultBalance.balance,
  fulldata: state.account.account,
}))(RewardsScreen);

export default withForwardedNavigationParams()(
  withGlobalize(withIsConnected(ConnectedRewardsScreen)),
);
