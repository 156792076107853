import moment from 'moment';
import {Platform} from 'react-native';
import ActionsFactory from 'src/actions/ActionsFactory';
import AsyncLock from 'src/AsyncLock';
import {alertError} from 'src/components/helpers/AlertHelper';
import AccountConstants from 'src/constants/AccountConstants';
import AppDispatcher from 'src/dispatchers/AppDispatcher';
import {authStore} from 'src/init';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import HttpError from 'src/models/HttpError';
import {getMobileAuth0Client, getWebAuth0Client} from 'src/nativeModules/Auth0';
import uuid from 'src/nativeModules/UUID';
import Settings from 'src/Settings';
import SettingsStore from 'src/stores/SettingStore';
import {UrlTypes} from 'src/types/UrlTypes';
import HttpClient from './HttpClient';
import TallyHttpClient from './TallyHttpClient';
import {store} from '../redux/store';
import Localized from 'src/constants/AppStrings';
import {EnvironmentKey} from '../models/Environment';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type RequestResponse = {
  status: string;
  msg: string;
  version?: string;
  data: Record<string, any>;
};

const REGISTRATION_KEY = '8c876eaae545b1b5fa024a2327c330e5';
export const HttpVerbs = {
  Post: 'POST',
  Get: 'GET',
  Delete: 'DELETE',
  Put: 'PUT',
  Patch: 'PATCH',
};
export const HttpResponseCodes = {
  BadRequest: 400,
  Conflict: 409,
};

async function forcedLogout() {
  alertError(Localized.Errors.error + ', ' + Localized.Labels.logout);
  await ActionsFactory.getAccountActions().logout();
}

function isMoblico404Error(
  error: {
    networkResponse: {
      status: number;
      statusText: string;
      ok: boolean;
    };
    statusCode: number;
    code: string;
    message: string;
  },
  url: string,
) {
  return (
    (url.includes('v4/deals') || url.includes('v4/promos')) &&
    error.networkResponse &&
    error.networkResponse.status === 404
  );
}

function handleError(
  error: {
    networkResponse: {
      status: number;
      statusText: string;
      ok: boolean;
    };
    statusCode: number;
    code: string;
    message: string;
  },
  url: string,
  body?: object | string | null,
) {
  // Don't want to log 404s from Moblico, because they're not errors
  const isMoblicoError = isMoblico404Error(error, url);

  if (isMoblicoError) {
    return '' as unknown as HttpError;
  }

  let httpError: HttpError;
  if (!error || !error?.networkResponse) {

    httpError = new HttpError(
      url,
      error.statusCode || -1,
      error.code || 'Unknown',
      false,
      error.message,
      body,
    );
    return httpError;
  }


  httpError = new HttpError(
    url,
    error.networkResponse.status,
    error.networkResponse.statusText,
    error.networkResponse.ok,
    error.message,
    body,
  );
  //Handled at invoke location
  Events.FetchError.trackEvent(httpError);
  return httpError;
}

// all Api methods return promise object
export class Api {
  httpClient: HttpClient;
  externalHttpClient: HttpClient;
  gatewayHttpClient: HttpClient;
  tallyHttpClient: TallyHttpClient;
  tallyLoadedPromise: Promise<unknown> | null = null;
  lock: AsyncLock;

  constructor() {
    this.tallyLoadedPromise = SettingsStore.waitForTally().then(() => {
      const customFetch =
        Platform.OS === 'web' ? window.fetch.bind(window) : undefined;
      this.tallyHttpClient = new TallyHttpClient(
        store.getState().environment.serviceUrls[UrlTypes.tallyapi],
        SettingsStore.getTallyApiKey(),
        SettingsStore.getTallyPassword(),
        REGISTRATION_KEY,
        this.onTallyRegistered,
        customFetch,
      );
      this.httpClient = new HttpClient(
        this.tallyHttpClient.fetch.bind(this.tallyHttpClient) as (
          input: RequestInfo,
          init?: RequestInit,
        ) => Promise<Response>,
      );
      this.externalHttpClient = new HttpClient(customFetch);
      this.gatewayHttpClient = new HttpClient(customFetch);
      this.lock = new AsyncLock();
    });

    this.onSettingsStoreChanged = this.onSettingsStoreChanged.bind(this);
    SettingsStore.addChangeListener(this.onSettingsStoreChanged);
  }

  onSettingsStoreChanged() {
    this.tallyHttpClient?.setCredentials(
      SettingsStore.getTallyApiKey(),
      SettingsStore.getTallyPassword(),
    );
  }

  onTallyRegistered(tallyApiKey: string, tallyPassword: string) {
    if (tallyApiKey !== SettingsStore.getTallyApiKey()) {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.TALLY_REGISTERED,
        data: {
          apiKey: tallyApiKey,
          password: tallyPassword,
        },
      });
    }
  }

  async fetchToken() {
    return await this.lock.runExclusive(async () => {
      const expiresAt = await authStore.getExpiresAt();
      const accessToken = await authStore.getAccessToken();
      const refreshToken = await authStore.getRefreshToken();

      if (
        expiresAt >= moment().unix() + 60 &&
        expiresAt < moment().unix() + 87500
      ) {
        return accessToken;
      }

      try {
        if (Platform.OS !== 'web') {
          Events.Info.trackEvent('fetchToken getMobileAuth0Client', {
            refreshToken: refreshToken?.substring(0, 12),
            expiresAt,
          });

          const credentials = await getMobileAuth0Client()
            .auth.refreshToken({
              refreshToken: refreshToken ?? '',
            })
            .catch((error) => {
              Events.Info.trackEvent(
                'RefreshToken:APP:Expired',
                error.toString(),
              );

              return {
                refreshToken: '',
                accessToken: '',
                expiresAt: 0,
                idToken: '',
                tokenType: '',
              };
            });

          if (!credentials.accessToken) {
            await forcedLogout();
            return '';
          }

          await authStore.storeRefresh({
            ...credentials,
            refreshToken: credentials.refreshToken || '',
          });

          return credentials.accessToken;
        }

        const newAccessToken = await getWebAuth0Client()
          .getTokenSilently({
            detailedResponse: true,
          })
          .catch((error) => {
            Events.Info.trackEvent(
              'RefreshToken:WEB:Expired',
              error.toString(),
            );

            return {
              access_token: '',
              expires_in: 0,
            };
          });

        if (!newAccessToken.access_token) {
          await AppDispatcher.handleViewAction({
            actionType: AccountConstants.LOGOUT,
          });
          return '';
        }

        const idInfo = await getWebAuth0Client().getIdTokenClaims();

        await authStore.storeSession({
          accessToken: newAccessToken.access_token,
          idToken: idInfo?.__raw ?? '',
          expiresAt: moment().unix() + newAccessToken.expires_in,
          tokenType: 'Bearer',
          scope: 'openid offline_access profile email',
          refreshToken: 'web',
        });
        return newAccessToken.access_token;
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'RefreshToken:Error',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'RefreshToken:Error',
          generateErrorMessage(error),
          guid,
        );
      }

      return null;
    });
  }

  async fetchGateway<D>(
    url: string,
    data: object | string | null,
    method: string,
    opened = false,
  ): Promise<any> {
    const fetchedAccessToken = await this.fetchToken();

    if (fetchedAccessToken === null && !opened) {
      return undefined;
    }

    const obj: {
      method: string;
      headers: Record<string, string>;
      body?: string;
    } = {
      method: method,
      headers: {
        Authorization: `Bearer ${fetchedAccessToken}`,
        'Content-Type': 'application/json',
      },
    };

    if (opened) {
      delete obj.headers.Authorization;
    }

    if (data) {
      obj.body = JSON.stringify(data);
    }

    try {
      return (await this.gatewayHttpClient.fetchJSON(url, obj)) as Promise<
        D | HttpError | undefined
      >;
    } catch (err) {
      return handleError(
        err as {
          networkResponse: {
            status: number;
            statusText: string;
            ok: boolean;
          };
          statusCode: number;
          code: string;
          message: string;
        },
        url,
        data,
      );
    }
  }

  async fetch<R>(
    url: string,
    data:
      | Record<string, object | string | null | number | boolean>
      | null
      | object[]
      | object,
    type: string,
    includeOrigin = true,
    external = false,
    contentType = 'application/json',
  ): Promise<any> {
    await this.tallyLoadedPromise;
    const obj: {
      method: string;
      headers: Record<string, string>;
      body?: string;
      [key: string]: string | Record<string, string> | undefined | number;
    } = {
      method: type,
      headers: {
        'Content-Type': contentType,
      },
    };

    if (includeOrigin) {
      obj.headers.Origin = '';
    }

    if (contentType === 'application/x-www-form-urlencoded') {
      obj.body = `data=${JSON.stringify(data)}`;
    } else if (data) {
      obj.body = JSON.stringify(data);
    } else if (type === 'POST' || type === 'PUT') {
      obj.body = '';
    }

    this.tallyHttpClient.setUrl(
      store.getState().environment.serviceUrls[UrlTypes.tallyapi],
    );
    obj.timeout = 30000; // 30 seconds

    try {
      return (await (external
        ? this.externalHttpClient.fetchJSON(url, obj)
        : this.httpClient.fetchJSON(url, obj))) as RequestResponse & HttpError;
    } catch (err) {
      return handleError(
        err as {
          networkResponse: {
            status: number;
            statusText: string;
            ok: boolean;
          };
          statusCode: number;
          code: string;
          message: string;
        },
        url,
        data,
      ) as RequestResponse & HttpError;
    }
  }

  async discoverUrls() {
    const url = this.getFullUrl(Settings.discoveryUrl, '', {
      buildType: 'default',
    });
    // Always send 'default' for now
    // Don't use the regular method in case we don't have a token, etc.
    const response = await fetch(url, {
      method: 'GET',
    });
    return response.json();
  }

  getGatewayUrl(path: string): string {
    const env = store.getState().environment.env as EnvironmentKey;
    const url = Settings.auth0[env]?.audience ?? Settings.auth0.PROD.audience;
    return `${url}/${path}`;
  }

  getTallyUrl(path: keyof typeof UrlTypes) {
    let url = store.getState().environment.serviceUrls[path];

    if (!url) {
      const gmaApiUrl =
        store.getState().environment.serviceUrls[UrlTypes.gmaapi];
      url = gmaApiUrl.replace(UrlTypes.gmaapi, path);
    }

    return url;
  }

  getFullUrl(
    baseUrl: string,
    path: string,
    queryStringParams?: Record<
      string,
      string | number | boolean | undefined | null
    >,
  ) {
    return `${baseUrl}${path}${this.getParamString(queryStringParams)}`;
  }

  getParamString(
    queryStringParams?: Record<
      string,
      string | number | boolean | null | undefined
    >,
  ) {
    if (!queryStringParams) {
      return '';
    }
    const parts: Array<string> = [];
    Object.keys(queryStringParams).forEach((prop: string) => {
      parts.push(
        `${prop}=${encodeURIComponent(queryStringParams[prop] ?? '')}`,
      );
    });

    if (parts.length > 0) {
      return `?${parts.join('&')}`;
    }
  }
}

export default new Api();
