import * as React from 'react';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';
type LogoProps = {
  size: number;
  style?: Record<string, unknown>;
}; // tslint:disable

export default class PaperBill extends React.Component<LogoProps> {
  getWidth() {
    if (this.props.style && this.props.style['size']['width']) {
      return this.props.style['size']['width'];
    } else {
      return 51;
    }
  }

  getHeight() {
    if (this.props.style && this.props.style['size']['height']) {
      return this.props.style['size']['height'];
    } else {
      return 32;
    }
  }

  render() {
    return (
      <>
        <Svg
          width={this.getWidth()}
          height={this.getHeight()}
          viewBox="0 0 51 32"
          fill="none"
        >
          <Path fill="#fff" d="M0 0H51V32H0z" />
          <G clipPath="url(#clip0_5744_6293)">
            <Path
              d="M39.605 6c1.093 0 1.975.894 1.975 2v2c-2.179 0-3.95-1.794-3.95-4h1.975zM15.9 6h19.753c0 3.313 2.655 6 5.926 6v8c-3.271 0-5.926 2.688-5.926 6H15.901c0-3.313-2.654-6-5.926-6v-8c3.272 0 5.926-2.688 5.926-6zM9.975 22c2.18 0 3.95 1.794 3.95 4h-1.974a1.986 1.986 0 01-1.976-2v-2zm27.655 4c0-2.206 1.771-4 3.95-4v2c0 1.106-.883 2-1.975 2H37.63zM13.926 6c0 2.206-1.772 4-3.95 4V8c0-1.106.882-2 1.975-2h1.975zM11.95 4C9.77 4 8 5.794 8 8v16c0 2.206 1.772 4 3.95 4h27.655c2.179 0 3.95-1.794 3.95-4V8c0-2.206-1.771-4-3.95-4H11.95zm11.851 9c0 .55.445 1 .988 1v4h-.494a.997.997 0 00-.987 1c0 .55.444 1 .987 1h2.963c.543 0 .988-.45.988-1s-.445-1-.988-1h-.494v-5c0-.55-.444-1-.987-1h-.988a.997.997 0 00-.988 1zm-3.95 3c0-1.591.624-3.117 1.735-4.243A5.889 5.889 0 0125.777 10c1.572 0 3.08.632 4.191 1.757A6.038 6.038 0 0131.704 16a6.038 6.038 0 01-1.736 4.243A5.889 5.889 0 0125.778 22a5.889 5.889 0 01-4.19-1.757A6.038 6.038 0 0119.851 16zm13.827 0a8.051 8.051 0 00-2.314-5.657A7.852 7.852 0 0025.778 8a7.852 7.852 0 00-5.587 2.343A8.051 8.051 0 0017.877 16c0 2.122.832 4.157 2.314 5.657A7.852 7.852 0 0025.778 24a7.853 7.853 0 005.587-2.343A8.051 8.051 0 0033.679 16z"
              fill="#000"
            />
          </G>
          <Defs>
            <ClipPath id="clip0_5744_6293">
              <Path
                fill="#fff"
                transform="translate(8)"
                d="M0 0H35.5556V32H0z"
              />
            </ClipPath>
          </Defs>
        </Svg>
      </>
    );
  }
}
