import {RenderItemProps} from 'src/components/elements/cart/descriptor/DescriptorType';
import {Text, StyleSheet} from 'react-native';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import Icon, {FontAwesomeIconType} from '../../../icons/FontAwesomeIcon';
import Button from '../../../elements/Button';

export function searchFieldLabelDescriptor(data: typeof Localized): string {
  return `${data.Labels.searchproducts}...`;
}

export function productCatSelectedStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    backgroundColor: Styles.primaryColor,
    borderBottomColor: '#003349',
    borderTopColor: '#003349',
    borderStartColor: '#003349',
    borderEndColor: '#003349',
  };
}

export function productCatButtonStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    backgroundColor: '#FFF',
    borderRadius: 32,
    height: undefined,
    marginRight: Styles.Spacing.m1 + 5,
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: 8,
    // maxWidth: maxWidth,
    marginBottom: 15,
    //minHeight: Styles.Heights.touchTargetHeight2,
    borderWidth: 1,
    marginTop: 1,
    overflow: 'hidden',
    borderBottomColor: '#BCBCBC',
    borderTopColor: '#BCBCBC',
    borderStartColor: '#BCBCBC',
    borderEndColor: '#BCBCBC',
  };
}

export function productCatTextStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
    color: Styles.darkColor,
    fontFamily: Styles.FontFamily.robotoRegular,
    textTransform: 'uppercase',
  };
}
export function productCatSelectedTextStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.white,
  };
}

export function productCatrenderItem({
  item,
  index,
  selectedCategory,
  categoriesLength,
  onCategoryPress,
}: RenderItemProps): JSX.Element {
  const itemName = item.iconName ? (
    <Text maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}>
      <Icon
        name={item.iconName as FontAwesomeIconType}
        size={Styles.Fonts.f1}
        style={{marginRight: Styles.Spacing.m2}}
      />
      {` ${item.name}`}
    </Text>
  ) : (
    item.name
  );

  return (
    <Button
      overrideStyles={[
        productCatButtonStyleDescriptor(),

        selectedCategory === item.key && productCatSelectedStyleDescriptor(),
        index === 0 && styles.firstItemMargin,
        index === categoriesLength - 1 && styles.lastItemMargin,
      ]}
      onPress={() => onCategoryPress(item)}
      overrideTextStyles={[
        productCatTextStyleDescriptor(),
        selectedCategory === item.key &&
          productCatSelectedTextStyleDescriptor(),
      ]}
      text={itemName}
      accessibilityLabel={`${item.name} ${Localized.Buttons.category}`}
      accessibilityHint="Double tap to filter products by selected category"
      accessibilityRole="button"
      aria-label={`${item.name} ${Localized.Buttons.category}`}
      role="button"
    />
  );
}
const styles = StyleSheet.create({
  firstItemMargin: {
    marginLeft: Styles.Spacing.m3,
  },
  lastItemMargin: {
    marginRight: Styles.Spacing.m3,
  },
});

export default {
  searchFieldLabelDescriptor,
  productCatSelectedStyleDescriptor,
  productCatButtonStyleDescriptor,
  productCatTextStyleDescriptor,
  productCatSelectedTextStyleDescriptor,
  productCatrenderItem,
};
