// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import {Device, Characteristic, BleError} from 'react-native-ble-plx';
import moment from 'moment';
import MessageQueue, {MessageQueueEvents} from './MessageQueue';
import type {PacketReceivedArgs} from './MessageQueue';
import BluetoothDevice from '../BluetoothDevice';
import BluetoothDeviceTypes from 'src/constants/BluetoothDeviceTypes';
import {commands, displayCommands} from 'src/constants/BluetoothCommands';
import BluetoothLogger from '../BluetoothLogger';
import {
  encodeValue,
  hex2ascii,
  convertHexStringToAmount,
  convertDecimalToAsciiString,
  convertNumberToAsciiString,
} from '../Encoding';
import Events from 'src/logging/Events';
import Util from 'src/Util';
import MachineActions from 'src/actions/MachineActions';
import type {VendingMachine} from '../VendingMachineInterface';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export const TSFInsideEvents = {
  onConnecting: 'onConnecting',
  onConnected: 'onConnected',
  onBluetoothError: 'onBluetoothError',
  onRequestedWebRequest: 'onRequestedWebRequest',
  onSetupResultAcknowledged: 'onSetupResultAcknowledged',
  onPicUpdatedAcknowledged: 'onPicUpdatedAcknowledged',
  onWebRequestResultAcknowledged: 'onWebRequestResultAcknowledged',
  onReboot: 'onReboot',
  onDexResultAcknowledged: 'onDexResultAcknowledged',
  onSessionEnded: 'onSessionEnded',
  onConnectionError: 'onConnectionError',
  vmcLogReceived: 'vmcLogReceived',
  onWifiConnection: 'onWifiConnection',
  onHighestPriceReceived: 'onHighestPriceReceived',
  onMachineNotAvailable: 'onMachineNotAvailable',
  onInvalidKey: 'onInvalidKey',
  onRequestedSetup: 'onRequestedSetup',
  onRequestedPing: 'onRequestedPing',
  onPingReceived: 'onPingReceived',
  onSetupReceived: 'onSetupReceived',
  onDexReceived: 'onDexReceived',
  onDexDataRead: 'onDexDataRead',
  onWebRequestReceived: 'onWebRequestReceived',
  onDexFailed: 'onDexFailed',
  onPingResultAcknowledged: 'onPingResultAcknowledged',
  onMessageProgress: 'onMessageProgress',
  onMessageReceived: 'onMessageReceived',
  onVendSucceeded: 'onVendSucceeded',
  onVendFailed: 'onVendFailed',
  onVendApproved: 'onVendApproved',
};
type ipAddressessArg = {
  ipv4: any;
  ipv6: any;
};
const OLD_PASSCODE = 'GARRY';
const KEEP_ALIVE_INTERVAL_SECONDS = 4;
const KEEP_ALIVE_HEX_MESSAGE = 'ff';
export default class TSFInsideDevice
  extends BluetoothDevice
  implements VendingMachine
{
  deviceId = '';
  deviceName = '';
  letter: string | null | undefined = '';
  color: string | null | undefined = '';
  operator = false;
  transactionId = '';
  imageUrl: string | null | undefined = '';
  index = 0;
  hidePlanogram: boolean | null | undefined = true;
  btLogger: BluetoothLogger;
  keepAliveInterval: any;
  lastWriteDate: moment.Moment;
  messageQueue: MessageQueue;
  uuids: any = {
    service: '569a1101-b87f-490c-92cb-11ba5ea5167c',
    characteristics: {
      write: '569a2001-b87f-490c-92cb-11ba5ea5167c',
      read: '569a2000-b87f-490c-92cb-11ba5ea5167c',
    },
  };
  deviceRebooted: boolean;
  requestedSetup: boolean;
  creditAmount: number;
  discountAmount: number;
  readOnly: boolean | null | undefined;

  constructor(device?: Device) {
    super(device);

    if (device && device.name) {
      this.btLogger = new BluetoothLogger();
      const pieces = device.name.split(':');
      this.deviceId = pieces[1];
      this.deviceName = pieces[2];
      this.letter = pieces[3];
      this.color = pieces[4];
    }

    this.onMessageReceived = this.onMessageReceived.bind(this);
    this.onMessageProgress = this.onMessageProgress.bind(this);
    this.onPacketReceived = this.onPacketReceived.bind(this);
    this.onCharacteristicWrite = this.onCharacteristicWrite.bind(this);
  }
  localType?: string;
  distance?: number;
  currency?: string;
  incompatibleCurrency?: boolean;
  ccNotConfigured?: boolean;
  incompatibleOrg?: boolean;
  orderAhead?: boolean;
  hiatusMode?: boolean;
  broadcastid?: string;
  id: string;
  beacon?: boolean;
  locationType: string;

  startTransaction(transactionId: string) {
    this.transactionId = transactionId;
    this.operator = false;
    return this.startConnection();
  }

  operatorConnect() {
    this.operator = true;
    return this.startConnection();
  }

  async startConnection() {
    await super.connect();
    const characteristics = await this.device.characteristicsForService(
      this.uuids.service,
    );
    const writeCharacteristic = characteristics.find(
      (characteristic) =>
        characteristic.uuid === this.uuids.characteristics.write,
    );
    const readCharacteristic = characteristics.find(
      (characteristic) =>
        characteristic.uuid === this.uuids.characteristics.read,
    );
    this.setupMessageQueue(writeCharacteristic, readCharacteristic);
    this.emit(TSFInsideEvents.onConnecting);
    this.startKeepAliveInterval();
    this.sendPasscode(OLD_PASSCODE);
  }

  async disconnect() {
    if (!this.operator) {
      await this.sendEmptyCredit();
    }

    this.cleanupMessageQueue();
    this.cleanupKeepAliveInterval();
    await this.sendAsciiMessage(commands.CLOSE_CONNECTION_CODE, 'DIS');
    return super.disconnect();
  }

  cleanupMessageQueue() {
    if (this.messageQueue) {
      this.messageQueue.removeListener(
        MessageQueueEvents.onCharacteristicWrite,
        this.onCharacteristicWrite,
      );
      this.messageQueue.removeListener(
        MessageQueueEvents.onPacketReceived,
        this.onPacketReceived,
      );
      this.messageQueue.removeListener(
        MessageQueueEvents.onMessageReceived,
        this.onMessageReceived,
      );
      this.messageQueue.removeListener(
        MessageQueueEvents.onMessageProgress,
        this.onMessageProgress,
      );
      this.messageQueue.cleanup();
    }
  }

  onMessageReceived() {
    this.emit(TSFInsideEvents.onMessageReceived);
  }

  onMessageProgress(bytes: number, total: number) {
    this.emit(TSFInsideEvents.onMessageProgress, bytes, total);
  }

  setupMessageQueue(
    writeCharacteristic: Characteristic,
    readCharacteristic: Characteristic,
  ) {
    this.messageQueue = new MessageQueue(
      writeCharacteristic,
      readCharacteristic,
    );
    this.messageQueue.addListener(
      MessageQueueEvents.onCharacteristicWrite,
      this.onCharacteristicWrite,
    );
    this.messageQueue.addListener(
      MessageQueueEvents.onPacketReceived,
      this.onPacketReceived,
    );
    this.messageQueue.addListener(
      MessageQueueEvents.onMessageReceived,
      this.onMessageReceived,
    );
    this.messageQueue.addListener(
      MessageQueueEvents.onMessageProgress,
      this.onMessageProgress,
    );
  }

  sendTimeMessage(timeMessage: string) {
    if (!timeMessage.endsWith('0')) {
      timeMessage += ';0';
    }

    this.sendAsciiMessage(commands.SET_TIME_CODE, timeMessage);
  }

  sendEnvironment(env: string) {
    return this.sendAsciiMessage(commands.SET_ENVIRONMENT_CODE, env);
  }

  requestSetup() {
    this.requestedSetup = true;
    this.emit(TSFInsideEvents.onRequestedSetup);
    return this.sendAsciiMessage(commands.SETUP_REQUEST_CODE, '');
  }

  requestPing() {
    this.emit(TSFInsideEvents.onRequestedPing);
    return this.sendAsciiMessage(commands.PING_REQUEST_CODE, '');
  }

  async processPasscodeVerified(message: string) {
    try {
      if (message.length >= 2) {
        const machineStatus = message.substring(0, 2);

        switch (machineStatus) {
          case '02':
            const seed = Util.random(0, 1000000);
            this.sendPasscode(
              JSON.stringify({
                passcode: Util.hashCode(this.deviceId + seed),
                seed,
              }),
            );
            break;

          case '11':
            if (!this.operator) {
              let highestPrice = 0;

              if (message.length === 12) {
                highestPrice = convertHexStringToAmount(
                  message.substring(2, 12),
                );
              }

              this.emit(TSFInsideEvents.onHighestPriceReceived, highestPrice);
            }

            this.emit(TSFInsideEvents.onConnected);
            break;

          case '10':
            // We still connect if we're an operator
            if (this.operator) {
              this.emit(TSFInsideEvents.onConnected);
            }

            MachineActions.machineNotAvailable(this.operator, this.deviceId);
            this.emit(
              TSFInsideEvents.onMachineNotAvailable,
              this.operator,
              this.deviceId,
            );
            break;

          case '01':
          case '00':
            MachineActions.invalidKey();
            this.emit(TSFInsideEvents.onInvalidKey);
            break;
        }
      }
    } catch (err) {
      CrashlyticsEvents.log(
        'Exception',
        'TSFInsideDevice:ProcessPasscodeVerified',
        err.message ? err.message : err.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'TSFInsideDevice:ProcessPasscodeVerified',
        err.message ? err.message : err.toString(),
      );
    }
  }

  handleDeviceDisconnected(error: BleError | null | undefined) {
    super.handleDeviceDisconnected(error);

    if (error) {
      this.emit(TSFInsideEvents.onConnectionError);
      MachineActions.errorConnecting();
    }

    this.cleanupMessageQueue();
    this.cleanupKeepAliveInterval();
  }

  sendEmptyCredit() {
    const bcd = convertDecimalToAsciiString(0, 5);
    return this.sendAsciiMessage(commands.SEND_CREDIT_CODE, bcd);
  }

  pingReceived(pingData: any) {
    this.emit(TSFInsideEvents.onPingReceived, this.deviceId, pingData);
  }

  setupReceived(setupData: any) {
    this.emit(TSFInsideEvents.onSetupReceived, this.deviceId, setupData);
  }

  sendPingResponse(response: any) {
    return this.sendAsciiMessage(
      commands.PING_RESULT_CODE,
      JSON.stringify(response),
    );
  }

  sendDexFileResponse(response: any) {
    return this.sendAsciiMessage(
      commands.DEX_DATA_UPLOADED_CODE,
      JSON.stringify(response),
    );
  }

  sendResetVmc() {
    this.sendAsciiMessage(commands.RESET_VMC, '');
  }

  sendStartVmc(numLines: number) {
    const numLinesVMC = convertNumberToAsciiString(numLines, 4);
    this.sendAsciiMessage(commands.START_VMC, numLinesVMC);
  }

  sendStopVmc() {
    this.sendAsciiMessage(commands.STOP_VMC, '');
  }

  sendPicUpdate(commandJson: any) {
    this.sendAsciiMessage(commands.PIC_UPDATE, JSON.stringify(commandJson));
  }

  sendServiceModeCommand(turnOn: boolean) {
    const message = turnOn ? '1' : '0';
    this.sendAsciiMessage(commands.SERVICE_MODE, message);
  }

  sendDeviceReboot() {
    this.sendAsciiMessage(commands.DEVICE_REBOOT_CODE, '');
  }

  sendIpAddressCheck() {
    this.sendAsciiMessage(commands.CHECK_IP_ADDRESS, '');
  }

  setTransactionId(transactionId: string) {
    this.transactionId = transactionId;
  }

  sendSetupResponse(response: any) {
    // Remove Settings since AirVend Inside doesn't need them
    if (
      response.MachineConfig !== null &&
      response.MachineConfig.Settings !== null
    ) {
      response.MachineConfig.Settings = null;
    }

    if (response.Planogram !== null) {
      response.Planogram = null;
    }

    return this.sendAsciiMessage(
      commands.SETUP_RESULT_CODE,
      JSON.stringify(response),
    );
  }

  sendWebRequestResponse(response: any) {
    return this.sendAsciiMessage(
      commands.WEB_RESULT_CODE,
      JSON.stringify(response),
    );
  }

  async vendApproved(key: string, price: number) {
    this.emit(TSFInsideEvents.onVendApproved, key, price, this.discountAmount);
  }

  vendSucceeded() {
    this.emit(TSFInsideEvents.onVendSucceeded);
  }

  vendFailed() {
    this.emit(TSFInsideEvents.onVendFailed);
  }

  processVendCompleted(message: string) {
    if (message === '00') {
      this.vendFailed();
    } else if (message === '01') {
      this.vendSucceeded();
    }
  }

  processVendRequest(message: string) {
    let approved = false;

    if (message.length === 18) {
      const key = hex2ascii(message.substring(0, 8));
      const price = convertHexStringToAmount(message.substring(8, 18));
      approved = this.creditAmount && price <= this.creditAmount;

      if (approved) {
        this.vendApproved(key, price);
      }
    }

    if (this.transactionId !== null) {
      let prefix = '00';

      if (approved) {
        prefix = '01';
      }

      const returnMessage =
        prefix + encodeValue(this.transactionId, 'ascii', 'hex');
      return this.sendHexMessage(
        commands.VEND_REQUEST_RESPONSE_CODE,
        returnMessage,
      );
    }
  }

  requestWebRequests() {
    return this.sendAsciiMessage(commands.WEB_REQUEST_CODE, 'A');
  }

  webRequestReceived(webRequestData: any) {
    this.emit(
      TSFInsideEvents.onWebRequestReceived,
      this.deviceId,
      webRequestData,
    );
  }

  dexFilesReceived(files: any) {
    this.emit(TSFInsideEvents.onDexReceived, this.deviceId, files);
  }

  requestDexRead(isServiced: boolean) {
    if (isServiced) {
      this.sendAsciiMessage(commands.DEX_REQUEST_CODE, '1');
    } else {
      this.sendAsciiMessage(commands.DEX_REQUEST_CODE, '0');
    }
  }

  processDEXDataRead(message: string) {
    this.emit(TSFInsideEvents.onDexDataRead, message);
  }

  processDEXFailed(message: string) {
    this.emit(TSFInsideEvents.onDexFailed, message);
  }

  processReboot(reason: string) {
    MachineActions.machineRebooting(reason);
  }

  isWifiConnected(ipAddresses: ipAddressessArg) {
    return (
      ipAddresses.ipv4.some((entry) =>
        Object.keys(entry).some((key) => key.startsWith('wlan')),
      ) ||
      ipAddresses.ipv6.some((entry) =>
        Object.keys(entry).some((key) => key.startsWith('wlan')),
      )
    );
  }

  onPacketReceived(args: PacketReceivedArgs) {
    try {
      // We don't want an unexpected message to crash the app. We just won't process that message.
      const command = args.command;
      const message = args.message;
      this.btLogger.logDataReceived(command, message);

      switch (command) {
        case commands.VEND_REQUEST_CODE:
          this.processVendRequest(message);
          break;

        case commands.PASSCODE_VERIFIED_CODE:
          this.processPasscodeVerified(message);
          break;

        case commands.DEX_FILE_CODE:
          this.dexFilesReceived(JSON.parse(hex2ascii(message)));
          break;

        case commands.PING_RESPONSE_CODE:
          this.pingReceived(JSON.parse(hex2ascii(message)));
          break;

        case commands.PING_RESULT_CODE:
          this.emit(TSFInsideEvents.onPingResultAcknowledged, this.deviceId);
          break;

        case commands.BLUETOOTH_ERROR_CODE:
          const messageJson = JSON.parse(hex2ascii(message));
          messageJson.Errors.forEach((err) => {
            err.DisplayCommand =
              displayCommands[err.Command] ||
              `Unknown Command (${err.Command})`;
          });
          this.emit(
            TSFInsideEvents.onBluetoothError,
            this.deviceId,
            messageJson.Errors,
          );
          break;

        case commands.SETUP_RESPONSE_CODE:
          if (this.requestedSetup) {
            this.setupReceived(JSON.parse(hex2ascii(message)));
          }

          break;

        case commands.SETUP_RESULT_CODE:
          if (this.requestedSetup && !this.deviceRebooted) {
            this.requestedSetup = false;
            // This is a check to see if we just finished the initial sync or if we finished our setup request
            this.emit(TSFInsideEvents.onRequestedWebRequest);
            this.requestWebRequests();
          }

          this.emit(TSFInsideEvents.onSetupResultAcknowledged, this.deviceId);
          break;

        case commands.PIC_UPDATE:
          this.emit(
            TSFInsideEvents.onPicUpdatedAcknowledged,
            this.deviceId,
            JSON.parse(hex2ascii(message)),
          );
          break;

        case commands.WEB_RESULT_CODE:
          this.emit(
            TSFInsideEvents.onWebRequestResultAcknowledged,
            this.deviceId,
            JSON.parse(hex2ascii(message)),
          );
          break;

        case commands.WEB_RESPONSE_CODE:
          this.webRequestReceived(JSON.parse(hex2ascii(message)));
          break;

        case commands.VEND_RESPONSE_CODE:
          this.processVendCompleted(message);
          break;

        case commands.REBOOT_CODE:
          this.emit(TSFInsideEvents.onReboot, this.deviceId);
          this.processReboot(hex2ascii(message));
          this.deviceRebooted = true;
          break;

        case commands.DEX_DATA_READ_CODE:
          this.processDEXDataRead(hex2ascii(message));
          break;

        case commands.DEX_FAILED_CODE:
          this.processDEXFailed(hex2ascii(message));
          break;

        case commands.DEX_DATA_UPLOADED_CODE:
          this.emit(TSFInsideEvents.onDexResultAcknowledged, this.deviceId);
          break;

        case commands.SESSION_ENDED_CODE:
          this.emit(TSFInsideEvents.onSessionEnded);
          break;

        case commands.BT_SERVER_BUSY:
          this.emit(TSFInsideEvents.onConnectionError);
          MachineActions.errorConnecting();
          this.disconnect();
          break;

        case commands.VMC_LOG: {
          const ascii = hex2ascii(message).trim();
          const parsedJson = JSON.parse(ascii);

          if (parsedJson.History) {
            this.emit(
              TSFInsideEvents.vmcLogReceived,
              this.deviceId,
              parsedJson.History,
            );
            MachineActions.vmcLogReceived(this.deviceId, parsedJson.History);
          } else {
            this.emit(
              TSFInsideEvents.vmcLogReceived,
              this.deviceId,
              parsedJson.Last,
            );
            MachineActions.vmcLogReceived(this.deviceId, parsedJson.Last);
          }

          break;
        }

        case commands.CHECK_IP_ADDRESS:
          this.emit(
            TSFInsideEvents.onWifiConnection,
            this.isWifiConnected(JSON.parse(hex2ascii(message))),
          );
          break;

        default:
          break;
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'TSFInsideDevice:ProcessMessage',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'TSFInsideDevice:ProcessMessage',
        generateErrorMessage(error),
      );
    }
  }

  onCharacteristicWrite() {
    this.lastWriteDate = moment();
  }

  cleanupKeepAliveInterval() {
    if (this.keepAliveInterval) {
      clearInterval(this.keepAliveInterval);
      this.keepAliveInterval = null;
    }
  }

  startKeepAliveInterval() {
    this.keepAliveInterval = setInterval(() => {
      if (
        this.lastWriteDate &&
        this.lastWriteDate.isBefore(
          moment().subtract(KEEP_ALIVE_INTERVAL_SECONDS, 'seconds'),
        )
      ) {
        this.sendHexMessage(commands.KEEP_ALIVE, KEEP_ALIVE_HEX_MESSAGE);
      }
    }, KEEP_ALIVE_INTERVAL_SECONDS * 1000);
  }

  sendPasscode(passcode: string) {
    this.sendAsciiMessage(commands.PASSCODE_CODE, passcode);
  }

  sendHexMessage(command: string, hexMessage: string) {
    this.btLogger.logHexDataSent(command, hexMessage);
    return this.messageQueue.sendMessage(command, hexMessage);
  }

  sendAsciiMessage(command: string, asciiMessage: string) {
    const hexMessage = encodeValue(asciiMessage, 'ascii', 'hex');
    return this.sendHexMessage(command, hexMessage);
  }

  transactionUploaded() {
    return this.sendAsciiMessage(
      commands.TRANSACTION_UPLOADED_CODE,
      this.transactionId,
    );
  }

  sendMachineName(machineName: string) {
    this.sendAsciiMessage(commands.SET_NAME_CODE, machineName);
  }

  sendUpdateCommand(message: any) {
    const messageStr = JSON.stringify(message);
    this.sendAsciiMessage(commands.UPDATE_COMMAND, messageStr);
  }

  sendCredit(
    creditAmount: number,
    discountAmount: number,
    secondsRemaining: number,
    test = false,
  ) {
    this.creditAmount = creditAmount;
    this.discountAmount = discountAmount;
    const creditAmountFormatted = convertDecimalToAsciiString(creditAmount, 5);
    const secondsRemainingFormatted = convertNumberToAsciiString(
      secondsRemaining,
      3,
    );
    const discountAmountFormatted = convertDecimalToAsciiString(
      discountAmount,
      5,
    );
    const testVend = test ? '1' : '0';
    return this.sendAsciiMessage(
      commands.SEND_CREDIT_CODE,
      creditAmountFormatted +
        secondsRemainingFormatted +
        discountAmountFormatted +
        testVend,
    );
  }

  get type(): string {
    return BluetoothDeviceTypes.tsfInside;
  }

  get rawName(): string {
    return this.device.name;
  }

  get name(): string {
    return this.deviceName;
  }

  setColor(color: string | null | undefined) {
    this.color = color;
  }

  setLetter(letter: string | null | undefined) {
    this.letter = letter;
  }

  setImageUrl(imageUrl: string | null | undefined) {
    this.imageUrl = imageUrl;
  }

  setHidePlanogram(hidePlanogram: boolean | null | undefined) {
    this.hidePlanogram = hidePlanogram;
  }

  setDeviceName(deviceName: string) {
    this.deviceName = deviceName;
  }

  setDeviceId(deviceId: string) {
    this.deviceId = deviceId;
  }

  setReadOnly(readOnly: boolean | null | undefined) {
    this.readOnly = readOnly;
  }
}
