import AVText from 'src/components/elements/AVText';
import React from 'react';
import SnackProduct from 'src/components/img/svg/sendSnackStickers/SnackProduct';
import Styles from '../../../Styles';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import {Dimensions, View, StyleSheet, Platform, Image} from 'react-native';
import Localized from 'src/constants/AppStrings';
import {
  SendSnackDescriberData,
  AnnouncementStylesDescriptor,
  CampusLocationListStylesDescriptor,
  ReferralDescriberData,
  EngagementCarouselStylesDescriptor,
  RewardskDescriberData,
  RewardsLegacykDescriberData,
} from 'src/components/elements/home/descriptor/DescriptorType';
import GrpReferral from 'src/components/img/svg/GrpReferral';
import PointsIndicator from 'src/components/elements/rewards/PointsIndicator';
import ChevronRight from 'src/components/img/svg/ChevronRight';
import {UserRewardsTierIcon} from 'src/components/img/svg/rewards';
import ShopIcon from 'src/components/img/svg/ShopIcon';
import {capitalizeFirstWord} from 'src/Util';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import CoffeeIcon from 'src/components/img/svg/coffee/coffeeIcon';
import RewardCanteenHome from 'src/components/img/svg/RewardCanteenHome';

export function getCampusLocationStyles(): {
  [key: string]: {[key: string]: number | string};
} {
  const _width = Dimensions.get('window').width;
  return {
    recentLocationAddressText: {
      fontWeight: '400',
      color: Styles.lightGray,
      width: _width - Styles.Spacing.m6,
      fontSize: Styles.Fonts.f8,
      fontFamily: Styles.FontFamily.robotoRegular,
      textAlignVertical: 'center',
    },
    recentLocationViewLocationNameText: {
      textAlignVertical: 'center',
      color: Styles.black,
      fontSize: Styles.Fonts.f1,
      width: _width - Styles.Spacing.m6,
      fontWeight: '700',
    },
    separator: {
      height: 1,
      backgroundColor: Styles.borderColor,
    },
    locationListView: {
      margin: Styles.Spacing.m3,
    },
    locationDisplatText: {},
    locationDisplatSubText: {},
    recentListborder: {},
    descriptionText: {
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    greetNameText: {},
  };
}
export function rewardsLegacyViewDescriptor(
  props: RewardsLegacykDescriberData,
): JSX.Element {
  const {onRewardsClick, styles, stat, isOfferAvailable} = props;
  return (
    <React.Fragment>
      <AVTouchableOpacity
        aria-label="onRewardsClick"
        accessible={true}
        accessibilityLabel="onRewardsClick"
        accessibilityHint="Double tap to navigate to the rewards screen"
        accessibilityRole="button"
        role="button"
        onPress={onRewardsClick}
        style={{paddingTop: 10}}
      >
        <View
          style={[styles.container, {paddingHorizontal: Styles.Spacing.m3}]}
        >
          <View style={[styles.starIconView, {bottom: -6}]}>
            <View style={[{top: 8}]} accessibilityLabel={'RewardsIcon'}>
              <RewardCanteenHome />
            </View>
            <View
              style={{
                paddingHorizontal: 16,
                paddingTop: 10,
              }}
            >
              <AVText
                accessibilityRole="text"
                style={[
                  {
                    color: Styles.black,
                    fontFamily: Styles.FontFamily.robotoRegular,
                    fontSize: 18,
                    paddingBottom: 10,

                    fontWeight: '700',
                  },
                ]}
                accessibilityLabel={Localized.Labels.rewards}
                aria-label={`${Localized.Labels.rewards}, text`}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
              >
                {Localized.Labels.rewards}
              </AVText>
              <AVText
                accessibilityRole="text"
                aria-label={`${stat}, text`}
                accessibilityLabel={`${stat}, text`}
                style={[
                  {
                    fontFamily: Styles.FontFamily.robotoRegular,
                    fontWeight: '700',
                    color: Styles.black,

                    fontSize: 18,
                  },
                  isOfferAvailable && {
                    paddingBottom: 10,
                  },
                ]}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
              >
                {stat}
              </AVText>
              {isOfferAvailable && (
                <AVText
                  accessibilityRole="text"
                  aria-label={`${stat}, text`}
                  accessibilityLabel={`${stat}, text`}
                  style={[
                    {
                      fontFamily: Styles.FontFamily.robotoRegular,
                      fontWeight: '400',
                      color: Styles.black,
                      fontSize: 16,
                    },
                  ]}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                >
                  {Localized.Labels.offerAvailable}
                </AVText>
              )}
            </View>
          </View>

          <View
            style={[{alignSelf: 'flex-end', top: isOfferAvailable ? -50 : -30}]}
          >
            <ChevronRight />
          </View>
        </View>
      </AVTouchableOpacity>
    </React.Fragment>
  );
}
export function rewardsViewDescriptor(
  props: RewardskDescriberData,
): JSX.Element {
  const {
    onRewardsClick,
    styles,
    rewardTier,
    bgSize,
    tileHeight,
    productThresholds,
    maxPointValue,
    marketAccountPoints,
    maxPointsLabel,
  } = props;
  return (
    <React.Fragment>
      <AVTouchableOpacity
        activeOpacity={0.75}
        accessibilityLabel={Localized.Labels.pay_earn_enjoy}
        accessibilityHint="Press to navigate to rewards"
        style={{paddingTop: 20}}
        onPress={onRewardsClick}
      >
        <View
          style={[styles.container, {paddingHorizontal: Styles.Spacing.m3 + 8}]}
        >
          <View style={[styles.shadowWrapper]}>
            <UserRewardsTierIcon
              tierName={rewardTier?.tierName}
              size={bgSize}
              style={styles.tierIcon}
            />

            <PointsIndicator
              height={tileHeight - Styles.Spacing.m3 * 3}
              availablePoints={marketAccountPoints}
              thresholds={productThresholds}
              maxPointValue={maxPointValue}
              maxPointValueLabel={maxPointsLabel}
              rewardedColor={'#409F68'}
            />

            <View style={styles.content}>
              <AVText
                style={[
                  styles.payEarnEnjoy,
                  {fontFamily: Styles.FontFamily.robotoRegular},
                ]}
              >
                {Localized.Labels.pay_earn_enjoy}
              </AVText>

              <View style={styles.rewardStatus}>
                <AVText
                  style={[
                    styles.rewardStatustxt,
                    {fontFamily: Styles.FontFamily.robotoRegular},
                  ]}
                >
                  {Localized.Labels.rewards_status}
                </AVText>

                <AVText
                  style={[
                    styles.currentTierName,
                    {fontFamily: Styles.FontFamily.robotoRegular},
                  ]}
                >
                  {rewardTier?.tierName}
                </AVText>
              </View>
            </View>

            <ChevronRight
              color={Styles.Colors.PayNew.darkHuesBase04}
              style={styles.chevron}
            />
          </View>
        </View>
      </AVTouchableOpacity>
    </React.Fragment>
  );
}
export function snackViewDescriptor(
  props: SendSnackDescriberData,
): JSX.Element {
  const {onSendASnackClick, styles} = props;
  return (
    <React.Fragment>
      <View style={styles.container}>
        <View style={styles.headerView}>
          <View style={styles.iconView} accessibilityLabel={'SnackIcon'}>
            <SnackProduct color={Styles.Colors.Canteen.darkGreen} />
          </View>
          <AVText
            style={styles.titleText}
            accessibilityLabel={`Text${Localized.Buttons.send_a_snack}`}
            aria-label={`Text${Localized.Buttons.send_a_snack}`}
            accessibilityRole="text"
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
          >
            {capitalizeFirstWord(Localized.Buttons.send_a_snack)}
          </AVText>
        </View>
        <AVText
          accessibilityLabel={
            Localized.Labels
              .send_your_colleagues_tasty_snacks_to_brighten_their_day
          }
          aria-label={
            Localized.Labels
              .send_your_colleagues_tasty_snacks_to_brighten_their_day
          }
          accessibilityRole="text"
          style={[
            styles.snackDescriptionText,
            {fontFamily: Styles.FontFamily.figtreeRegular, fontWeight: '400'},
          ]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {
            Localized.Labels
              .send_your_colleagues_tasty_snacks_to_brighten_their_day
          }
        </AVText>
        <RoundedButton
          buttonType={ButtonType.outline}
          containerStyle={styles.snackButton}
          onPress={onSendASnackClick}
          text={capitalizeFirstWord(Localized.Buttons.send_a_snack)}
          accessibilityLabel={Localized.Buttons.send_a_snack}
          textStyle={styles.snackButtonText}
          aria-label={Localized.Buttons.send_a_snack}
          accessibilityRole="button"
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
          color="#003349"
        />
      </View>
    </React.Fragment>
  );
}
export function getScanAndPayTile(): {
  [key: string]: {[index: string]: number | string};
} {
  return {
    iconViewBackground: {
      backgroundColor: Styles.primaryColor + '15',
      margin: Styles.Spacing.m3,
      height: Styles.Heights.h5,
      width: Styles.Heights.h5,
    },
    rowSpace: {
      marginVertical: Styles.Spacing.m2,
    },
    titleText: {
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      color: Styles.black,
      marginRight: Styles.Spacing.m6 - Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    listContainer: {
      marginTop: Styles.Spacing.m3,
    },
    nameText: {
      marginRight: Styles.Spacing.m6 - Styles.Fonts.f1,
      color: Styles.black,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
    },
    textView: {
      height: Styles.Heights.h5,
      marginVertical: Styles.Spacing.m3,
    },
  };
}
export function infoIconPrimaryColor() {
  return Styles.primaryColor;
}
export function infoIconInnerColor() {
  return Styles.secondaryColor;
}
export function announcemnentButtonColor() {
  return Styles.primaryColor;
}
export function announcementBtnFont(): string {
  return Styles.FontFamily.robotoRegular;
}

export function getAnnouncementStyles(): AnnouncementStylesDescriptor {
  return StyleSheet.create({
    container: {
      backgroundColor: '#fff',
      marginTop: Styles.Spacing.m3 - 4,
      marginHorizontal: Styles.Spacing.m3,
      marginBottom: Styles.Spacing.m2,
      borderRadius: Styles.Spacing.m2,
      alignItems: 'center',
      justifyContent: 'flex-start',
      ...Styles.Style.shadow,
    },
    titleText: {
      fontSize: Styles.Fonts.headerFont,
      width: '80%',
      fontWeight: '700',
      color: Styles.black,
      marginRight: Styles.Spacing.m3,
      marginLeft: Styles.Spacing.m2,
      textAlign: 'left',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    descriptionText: {
      fontSize: Styles.Fonts.sectionHeader,
      fontWeight: '400',
      color: Styles.darkColor,
      marginRight: Styles.Spacing.m4,
      marginLeft: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
  });
}

export function getWidthImageAvailable() {
  return '70%';
}

export function getCampusLocationListStyles(): CampusLocationListStylesDescriptor {
  return StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    campusLocationListView: {
      width: '100%',
      marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    },
    headerMargin: {
      marginLeft: Styles.Spacing.m3 + Styles.Spacing.m2,
    },
  });
}
export function referralViewDescriptor(
  props?: ReferralDescriberData,
  _amtLocalised?: string,
): JSX.Element {
  const {onInviteClick, styles, title, stat} = props;
  return (
    <React.Fragment>
      <View style={styles.container}>
        <View style={styles.headerView}>
          <View style={styles.iconView} accessibilityLabel={'SnackIcon'}>
            <GrpReferral />
          </View>
          <AVText
            style={[
              {
                fontSize: 20,
                fontFamily: Styles.FontFamily.robotoRegular,
                color: '#22212D',
                fontWeight: '700',
                marginLeft: Styles.Spacing.m3,
              },
            ]}
            accessibilityLabel={`Text${Localized.Buttons.send_a_snack}`}
            aria-label={`Text${Localized.Buttons.send_a_snack}`}
            accessibilityRole="text"
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
          >
            {title}
          </AVText>
        </View>
        <AVText
          accessibilityLabel={Localized.Labels.receive_cash_to_spend}
          aria-label={Localized.Labels.receive_cash_to_spend}
          accessibilityRole="text"
          style={[
            styles.snackDescriptionText,
            {
              fontSize: 18,
              color: '#22212D',
              fontFamily: Styles.FontFamily.robotoRegular,
              fontWeight: '400',
            },
          ]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {Localized.Labels.receive_cash_to_spend}
        </AVText>
        <AVText
          accessibilityLabel={Localized.Labels.receive_cash_to_spend}
          aria-label={Localized.Labels.receive_cash_to_spend}
          accessibilityRole="text"
          style={[
            styles.snackDescriptionText,
            {
              fontSize: 18,
              color: '#727179',
              fontFamily: Styles.FontFamily.robotoRegular,
              fontWeight: '400',
            },
          ]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {stat}
        </AVText>

        <RoundedButton
          buttonType={ButtonType.outline}
          containerStyle={[
            styles.invite,
            {
              paddingTop: 8,
              paddingBottom: 8,
              paddingLeft: 16,
              paddingRight: 16,
            },
          ]}
          onPress={onInviteClick}
          accessibilityLabel={Localized.Buttons.invite}
          role="button"
          aria-label={Localized.Buttons.invite}
          testID={Localized.Buttons.invite}
          text={capitalizeFirstWord(Localized.Buttons.invite)}
          textStyle={{
            fontWeight: Platform.OS === 'android' ? 'bold' : '600',
            fontFamily: Styles.FontFamily.figtreeRegular,
            fontSize: Styles.Fonts.f0 + 6,
            color: '#003349',
            textTransform: 'capitalize',
          }}
          accessibilityRole="button"
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
          color="#003349"
        />
      </View>
    </React.Fragment>
  );
}

export function engagementCarouselMargin(): number {
  return Styles.Spacing.m3 + Styles.Spacing.m2;
}
export function engagementCarouselMarginBottom(): number {
  return -Styles.Spacing.m4;
}
export function engagementCarouselMarginZeroLength(): number | undefined {
  return undefined;
}
export function engagementCarouselPaginationColor(): string {
  return Styles.secondaryColor;
}
export function engagementCarouselPaginationInactiveColor(): string {
  return Styles.inActiveDotColor;
}

export function getEngagementCarouselStyles(): EngagementCarouselStylesDescriptor {
  return StyleSheet.create({
    activeDotStyle: {
      width: 14,
      height: 14,
      borderRadius: 9,
    },
    inActiveDotStyle: {
      width: 14,
      height: 14,
      borderRadius: 9,
    },
    imageContentContainer: {
      paddingLeft: 10,
    },
    parralaxContainer: {
      backgroundColor: Styles.neutralHues,
    },
  });
}
export function locationListIcon(): JSX.Element {
  return <ShopIcon size={Styles.Spacing.m4} />;
}
export function RecentlocationListIcon(): JSX.Element {
  return <ShopIcon size={32} />;
}
export function getScanAndPayTileIcon(coffeMachineType?: string): JSX.Element {
  if (coffeMachineType && coffeMachineType == 'Bunn') {
    return <CoffeeIcon width={26} height={26} color={Styles.primaryColor} />;
  } else {
    return (
      <Image
        source={require('src/components/img/scanIconCanteen.png')}
        style={{
          width: Styles.Heights.h5 / 2,
          height: Styles.Heights.h5 / 2,
        }}
      />
    );
  }
}

export default {
  getAnnouncementStyles,
  getCampusLocationStyles,
  getScanAndPayTile,
  referralViewDescriptor,
  getWidthImageAvailable,
  announcemnentButtonColor,
  snackViewDescriptor,
  infoIconPrimaryColor,
  infoIconInnerColor,
  getCampusLocationListStyles,
  rewardsViewDescriptor,
  announcementBtnFont,
  engagementCarouselMargin,
  engagementCarouselPaginationColor,
  RecentlocationListIcon,
  engagementCarouselPaginationInactiveColor,
  getEngagementCarouselStyles,
  engagementCarouselMarginBottom,
  engagementCarouselMarginZeroLength,
  locationListIcon,
  rewardsLegacyViewDescriptor,
  getScanAndPayTileIcon,
};
