import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

type DealIconProps = {
  width?: number;
  height?: number;
  bgColor?: string;
};

export default class DealIconDetailsSucces extends React.Component<DealIconProps> {
  render() {
    return (
      <>
        <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
          <Path
            d="M1.714 3.75v7.008c0 .797.316 1.561.88 2.124l8.286 8.25a3.021 3.021 0 004.26 0l6.286-6.258a2.99 2.99 0 000-4.242l-8.286-8.25a3.02 3.02 0 00-2.133-.877H3.974a2.248 2.248 0 00-2.26 2.245zm5.273 1.5c.4 0 .783.158 1.065.44a1.497 1.497 0 010 2.12 1.51 1.51 0 01-2.13 0 1.497 1.497 0 010-2.12 1.51 1.51 0 011.065-.44z"
            fill="#022649"
          />
        </Svg>
      </>
    );
  }
}
