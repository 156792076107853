import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function DeleteIcon(props) {
  return (
    <Svg width={12} height={13} viewBox="0 0 12 13" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7.914l4.543 4.543 1.414-1.414L7.414 6.5l4.543-4.543L10.543.543 6 5.085 1.457.543.043 1.957 4.586 6.5.043 11.043l1.414 1.414L6 7.914z"
        fill="#707070"
      />
    </Svg>
  );
}

export default DeleteIcon;
