import RedesignDescriptor from './referral-redesign-descriptor';
import CanteenDescriptor from './referral-canteen-descriptor';
import CompanyKitchenDescriptor from './referral-companykitchen-descriptor';

import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';

const typeDescriber = {
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
  [BuildTypes.refive]: RedesignDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
