import React from 'react';
import Svg, {Path} from 'react-native-svg';

class SpinnerInSVG extends React.Component<{size?: number}> {
  render() {
    return (
      <Svg
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 717 717"
        fill="none"
      >
        <Path
          d="M358.5 716.251C161.24 716.251.749 555.76.749 358.499H53.47c0 168.196 136.834 305.03 305.03 305.03 168.196 0 305.03-136.834 305.03-305.03 0-168.196-136.834-305.03-305.03-305.03V.748c197.26 0 357.751 160.491 357.751 357.751 0 197.261-160.491 357.752-357.751 357.752z"
          fill="#066DFF"
        />
      </Svg>
    );
  }
}

export default SpinnerInSVG;
