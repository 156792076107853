import {createAsyncThunk} from '@reduxjs/toolkit';
import uuid from '../../../nativeModules/UUID';
import Events from '../../../logging/Events';
import {alertError} from '../../../components/helpers/AlertHelper';
import NavActions from '../../../actions/NavActions';
import {hideSpinner, showSpinner} from 'src/redux/slices/screenSlice';
import Api from 'src/api/Api';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export const fetchEnvironments = createAsyncThunk(
  'environment/fetchEnvironments',
  async (_, {dispatch}) => {
    dispatch(showSpinner({}));
    let apiResponse;
    try {
      apiResponse = await Api.discoverUrls();

      if (
        apiResponse.hasOwnProperty('urls') &&
        apiResponse.urls.hasOwnProperty('TEST')
      ) {
        delete apiResponse.urls.TEST;
      }

      if (apiResponse) {
        return apiResponse.urls;
      }
    } catch (exception) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'EnvironmentScreen:ComponentDidMount',
        generateErrorMessage(exception),
        guid,
        apiResponse,
      );
      Events.Error.trackEvent(
        'Exception',
        'EnvironmentScreen:ComponentDidMount',
        generateErrorMessage(exception),
        guid,
      );
      alertError('Failed to load environments.', guid);
      NavActions.pop();
    } finally {
      dispatch(hideSpinner());
    }

    return {error: true};
  },
);
