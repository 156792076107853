import * as React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import Styles from '../../Styles';
import SafeAreaView from '../../elements/SafeAreaView';
import BackSubheader from '../../elements/BackSubheader';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import {StyleSheet, View, ScrollView} from 'react-native';
import MachineAction from 'src/actions/MachineActions';
import TextInput from '../../elements/AVTextInput';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {getDescriber} from 'src/components/screens/vending/descriptor/Descriptor';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type ContactUsScreenProps = {
  popToTop: boolean;
  subject: string;
  insideTab: boolean;
  deviceId: string;
};
type ContactUsScreeState = {
  name: string;
  email: string;
  comment: string;
};

class ContactUsScreen extends React.Component<
  ContactUsScreenProps,
  ContactUsScreeState
> {
  static defaultProps = {
    popToTop: false,
  };
  static contextType = ScreenContext;

  constructor(props: ContactUsScreenProps) {
    super(props);
    this.state = {
      name: '',
      email: '',
      comment: '',
    };
    this.cancelClicked = this.cancelClicked.bind(this);
    this.sendClicked = this.sendClicked.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.validate = this.validate.bind(this);
  }

  cancelClicked() {
    FirebaseAnalytic.trackEvent('cancelClicked', 'ChooseContactScreen', {
      ...this.props,
      ...this.state,
    });
    if (this.props.popToTop) {
      NavActions.popToTop();
    } else {
      NavActions.pop();
    }
  }

  sendClicked() {
    FirebaseAnalytic.trackEvent('sendClicked', 'ChooseContactScreen', {
      ...this.props,
      ...this.state,
    });

    if (this.validate()) {
      this.sendEmail();

      if (this.props.popToTop) {
        NavActions.popToTop();
      } else {
        NavActions.pop();
      }
    } else {
      alertError(Localized.Errors.email_required);
    }
  }

  async sendEmail() {
    FirebaseAnalytic.trackEvent('sendEmail', 'ChooseContactScreen', {
      ...this.props,
      ...this.state,
    });
    try {
      const deviceId = this.props.deviceId;
      MachineAction.sendEmail(
        deviceId,
        this.props.subject,
        this.state.name,
        this.state.email,
        this.state.comment,
      );
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ContactUsScreen:SendEmail',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ContactUsScreen:SendEmail',
        generateErrorMessage(error),
      );
    }
  }

  validate() {
    return this.state.email && this.state.email.length > 0;
  }

  render() {
    return (
      <SafeAreaView
        style={Styles.Style.flex}
        forceInset={{
          top: 'never',
        }}
      >
        <BackSubheader
          title={Localized.Labels.report_problem}
          onBackSelect={this.cancelClicked}
          pop={false}
        >
          <KeyboardAvoidingView
            style={styles.container}
            insideTab={this.props.insideTab}
          >
            <ScrollView style={styles.container}>
              <View style={styles.content}>
                <TextInput
                  onChange={(event) =>
                    this.setState({
                      name: event.nativeEvent.text,
                    })
                  }
                  style={styles.input}
                  value={this.state.name}
                  placeholder={Localized.Labels.name_text}
                />
                <TextInput
                  onChange={(event) =>
                    this.setState({
                      email: event.nativeEvent.text,
                    })
                  }
                  style={styles.input}
                  value={this.state.email}
                  placeholder={Localized.Labels.email}
                />
                <TextInput
                  onChange={(event) =>
                    this.setState({
                      comment: event.nativeEvent.text,
                    })
                  }
                  multiline
                  style={[styles.input, styles.comment]}
                  value={this.state.comment}
                  placeholder={Localized.Labels.comment}
                />
              </View>
            </ScrollView>
            <RoundedButton
              buttonType={ButtonType.action}
              onPress={this.sendClicked}
              accessibilityLabel="Send"
              text={Localized.Buttons.send}
              backgroundColor={getDescriber().getButtonColor()}
              containerStyle={{bottom: Styles.Heights.h2 + Styles.Spacing.m1}}
            />
          </KeyboardAvoidingView>
        </BackSubheader>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  comment: {
    fontSize: Styles.Fonts.f1,
    height: Styles.Heights.h1 * 10,
    paddingTop: Styles.Spacing.m2,
  },
  container: {
    backgroundColor: Styles.bgColor,
    flex: 1,
  },
  content: {
    flex: 1,
    marginHorizontal: Styles.Spacing.m2,
    paddingVertical: Styles.Spacing.m2,
  },
  input: {
    alignSelf: 'stretch',
    backgroundColor: Styles.white,
    borderColor: Styles.lightGray,
    borderRadius: 4,
    borderWidth: 1,
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
    marginVertical: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m1,
    paddingVertical: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams<ContactUsScreenProps>()(
  ContactUsScreen,
);
