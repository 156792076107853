import * as React from 'react';
import Svg, {Path, Defs, G, Rect, ClipPath} from 'react-native-svg';
interface IProps {
  width?: number;
  height?: number;
}
const CircleExclamationOnboarding = ({
  width = 60,
  height = 60,
  fill = '#066DFF',
}: IProps & {fill?: string}) => (
  <Svg width={width} height={height} viewBox="0 0 61 60" fill="none">
    <G id="Circle Exclamation Light (1) 1" clip-path="url(#clip0_3734_15671)">
      <Path
        id="Vector"
        d="M30.5 3.75C37.4619 3.75 44.1387 6.51562 49.0616 11.4384C53.9844 16.3613 56.75 23.0381 56.75 30C56.75 36.9619 53.9844 43.6387 49.0616 48.5616C44.1387 53.4844 37.4619 56.25 30.5 56.25C23.5381 56.25 16.8613 53.4844 11.9384 48.5616C7.01562 43.6387 4.25 36.9619 4.25 30C4.25 23.0381 7.01562 16.3613 11.9384 11.4384C16.8613 6.51562 23.5381 3.75 30.5 3.75ZM30.5 60C38.4565 60 46.0871 56.8393 51.7132 51.2132C57.3393 45.5871 60.5 37.9565 60.5 30C60.5 22.0435 57.3393 14.4129 51.7132 8.7868C46.0871 3.16071 38.4565 0 30.5 0C22.5435 0 14.9129 3.16071 9.2868 8.7868C3.66071 14.4129 0.5 22.0435 0.5 30C0.5 37.9565 3.66071 45.5871 9.2868 51.2132C14.9129 56.8393 22.5435 60 30.5 60ZM30.5 15C29.4688 15 28.625 15.8438 28.625 16.875V31.875C28.625 32.9062 29.4688 33.75 30.5 33.75C31.5312 33.75 32.375 32.9062 32.375 31.875V16.875C32.375 15.8438 31.5312 15 30.5 15ZM33.3125 41.25C33.3125 40.5041 33.0162 39.7887 32.4887 39.2613C31.9613 38.7338 31.2459 38.4375 30.5 38.4375C29.7541 38.4375 29.0387 38.7338 28.5113 39.2613C27.9838 39.7887 27.6875 40.5041 27.6875 41.25C27.6875 41.9959 27.9838 42.7113 28.5113 43.2387C29.0387 43.7662 29.7541 44.0625 30.5 44.0625C31.2459 44.0625 31.9613 43.7662 32.4887 43.2387C33.0162 42.7113 33.3125 41.9959 33.3125 41.25Z"
        fill={fill}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_3734_15671">
        <Rect width="60" height="60" fill="white" transform="translate(0.5)" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default CircleExclamationOnboarding;
