import React, {useEffect, useState} from 'react';
import {
  PixelRatio,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import Styles from '../Styles';
import AVText from './AVText';
import {TouchableOpacityProps} from 'react-native';
import {getDescriber} from './descriptor/DescriptorType';

const UserProfileInitials: React.FC<UserProfileInitialsProps> = (props) => {
  const [componentValue, setComponentValue] = useState('');
  const textColor = Styles.white;
  useEffect(() => {
    const initials = getInitials();
    setComponentValue(initials);
  }, []);

  const getInitials = () => {
    const splitNames = props.text.split(' ');
    let initials = '';
    splitNames.forEach((value: string) => {
      if (initials.length != 2) {
        const char = value.charAt(0).toUpperCase();
        if (char != ' ') {
          initials += char;
        }
      }
    });
    return initials;
  };

  return (
    <View
      style={
        getDescriber() &&
        getDescriber()['getuserInitialsStyleDescriptor'] &&
        getDescriber()['getuserInitialsStyleDescriptor']()['initialsBtn']
      }
    >
      <AVText
        accessibilityRole={'text'}
        accessibilityLabel={props?.accessibilityLabel}
        accessibilityHint={props?.accessibilityHint}
        maxFontSizeMultiplier={
          props.maxFontSizeMultiplier ?? Styles.FontSizeMultiplier.maxfm8
        }
        style={[
          getDescriber() &&
            getDescriber()['getuserInitialsStyleDescriptor'] &&
            getDescriber()['getuserInitialsStyleDescriptor']()['text'],
          {color: textColor},
          getDescriber() &&
            getDescriber()['getuserInitialsStyleDescriptor'] &&
            getDescriber()['getuserInitialsStyleDescriptor']()['initialsText'],
          props.textStyle,
        ]}
      >
        {componentValue}
      </AVText>
    </View>
  );
};

export default UserProfileInitials;
interface UserProfileInitialsProps extends TouchableOpacityProps {
  onPress?: () => void;
  text: string;
  containerStyle?: StyleProp<ViewStyle>;
  maxFontSizeMultiplier?: number;
  textStyle?: StyleProp<TextStyle>;
  accessibilityLabel?: string;
  accessibilityHint?: string;
  icon?: React.ReactNode;
  hideAccessibleElements?: boolean;
}

const styles = StyleSheet.create({
  initialsBtn: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: 72,
    width: 72,
    margin: PixelRatio.roundToNearestPixel(0.5),
    backgroundColor: Styles.blueRevolve,
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m3),
  },
  initialsText: {
    fontSize: Styles.Fonts.f2,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  text: {
    color: Styles.primaryColor,
    fontWeight: 'bold',
    alignSelf: 'center',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
});
