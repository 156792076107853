import React from 'react';
import {TouchableOpacity, StyleSheet, View} from 'react-native';
import CheckBoxChecked from 'src/components/img/svg/CheckBoxChecked';
import CheckBoxUnchecked from 'src/components/img/svg/CheckBoxUnchecked';
import RadioChecked from 'src/components/img/svg/RadioChecked';
import RadioUnchecked from 'src/components/img/svg/RadioUnchecked';

export enum CustomToggleComponentType {
  Checkbox = 'checkbox',
  Radio = 'radio',
}

const CustomToggleComponent = ({
  type = 'checkbox',
  selected = false,
  onPress,
  accessibilityLabel = {},
}) => {
  const images = {
    checkbox: {
      checked: <CheckBoxChecked />,
      unchecked: <CheckBoxUnchecked />,
    },
    radio: {
      checked: <RadioChecked />,
      unchecked: <RadioUnchecked />,
    },
  };

  // Determine the image based on type and checked state
  const selectedImage = selected
    ? images[type].checked
    : images[type].unchecked;

  return (
    <TouchableOpacity
      onPress={onPress}
      accessible
      accessibilityLabel={`${type} ${accessibilityLabel}`}
    >
      <View
        accessible
        aria-label={`${type} ${accessibilityLabel}`}
        accessibilityLabel={`${type} ${accessibilityLabel}`}
        role="button"
        accessibilityHint={`${type} ${accessibilityLabel}`}
        style={styles.buttonContainer}
      >
        {selectedImage}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
    alignItems: 'center',
  },
});

export default CustomToggleComponent;
