import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IArrowStep4Props {
  color?: string;
}

function ArrowStep4(props: IArrowStep4Props) {
  return (
    <Svg width={86} height={122} viewBox="0 0 86 122" fill="none">
      <Path
        d="M84.662 115.618c-14.182-8.651-26.667-20.067-36.645-33.387-10.068-13.406-17.713-28.626-22.3-44.707-2.579-9.002-4.197-18.289-4.672-27.602-.152-2.811-4.547-2.712-4.396.1 1.008 17.305 5.527 34.268 12.833 50.023 7.302 15.58 17.566 29.861 29.975 41.807 7.013 6.702 14.711 12.597 23.007 17.686 2.494 1.351 4.606-2.479 2.198-3.92z"
        fill={props.color ?? '#fff'}
      />
      <Path
        d="M5.29 40.538C6.672 27.752 11.756 15.322 19.795 5.2c-1.24-.324-2.476-.56-3.715-.883 3.842 10.38 9.166 20.2 15.873 28.931.726.952 1.966 1.363 3.008.724.955-.55 1.448-2.056.723-3.007-6.437-8.474-11.664-17.856-15.41-27.887-.56-1.394-2.603-2.316-3.714-.883C7.84 13.3 2.424 26.617.896 40.725c-.15 1.147 1.193 2.173 2.248 2.149 1.317-.118 1.998-1.101 2.146-2.336z"
        fill={props.color ?? '#fff'}
      />
    </Svg>
  );
}

export default ArrowStep4;
