import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import StackTransitionPreset from './StackTransitionPreset';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import PrivacyPolicyScreen from 'src/components/screens/account/PrivacyPolicyScreen';
import AccountSetupAutoFundScreen from 'src/components/screens/createAccount/AccountSetupAutoFundScreen';
import AccountSetupScreen from 'src/components/screens/createAccount/AccountSetupScreen';
import CountrySelectionScreen from 'src/components/screens/createAccount/CountrySelectionScreen';
import CreateAccountEnterEmailScreen from 'src/components/screens/createAccount/CreateAccountEnterEmailScreen';
import DemographicScreen from 'src/components/screens/createAccount/DemographicScreen';
import EnteredEmailScreen from 'src/components/screens/createAccount/EnteredEmailScreen';
import EnterPinScreen from 'src/components/screens/createAccount/EnterPinScreen';
import ExistingAccountScreen from 'src/components/screens/createAccount/ExistingAccountScreen';
import ItalyAccountInfoScreen from 'src/components/screens/createAccount/ItalyAccountInfoScreen';
import KioskChangeEmailScreen from 'src/components/screens/createAccount/KioskChangeEmailScreen';
import KioskCreateAccountScreen from 'src/components/screens/createAccount/KioskCreateAccountScreen';
import KioskCreatePasswordScreen from 'src/components/screens/createAccount/KioskCreatePasswordScreen';
import PantryMarketSearchScreen from 'src/components/screens/createAccount/PantryMarketSearchScreen';
import ScanKioskQRScreen from 'src/components/screens/createAccount/ScanKioskQRScreen';
import ScanMarketCardScreen from 'src/components/screens/createAccount/ScanMarketCardScreen';
import EnvironmentScreen from 'src/components/screens/EnvironmentScreen';
import CreditCardAddScreen from 'src/components/screens/funding/CreditCardAddScreen';
import CreditCardScreen from 'src/components/screens/funding/CreditCardScreen';
import WelcomeScreen from 'src/components/screens/WelcomeScreen';
import WebContentScreen from 'src/components/screens/WebContentScreen';
import ReferralLandingScreen from 'src/components/screens/ReferralLandingScreen';
import AccountReadyScreen from 'src/components/screens/referral/AccountReadyScreen';
import CheckEmailScreen from 'src/components/screens/createAccount/CheckEmailScreen';
import NeverUsedAppScreen from 'src/components/screens/NeverUsedAppScreen';
import LinkExistingAccountScreen from 'src/components/screens/LinkExistingAccountScreen';
import PurchaseReceiptScreen from '../components/screens/account/history/PurchaseHistoryDetailScreen';
import ReferralReadyScreen from 'src/components/screens/referral/ReferralReadyScreen';
import MarketCardRegistrationScreen from 'src/components/screens/account/MarketCardRegistrationScreen';
import RegistrationEnterPinScreen from 'src/components/screens/account/RegistrationEnterPinScreen';
import ExistingAccountWarningScreen from 'src/components/screens/account/ExistingAccountWarningScreen';
import SelectMarketCardLocationScreen from 'src/components/screens/account/SelectMarketCardLocationScreen';

const Stack = createStackNavigator();
export default function LoginStackNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={AppRoutes.Welcome}
      screenOptions={{
        ...StackTransitionPreset,
        title: DeviceInfo.getApplicationName(),
        headerShown: false,
      }}
    >
      <Stack.Screen
        name={AppRoutes.AccountSetupAutoFund}
        component={AccountSetupAutoFundScreen}
      />
      <Stack.Screen
        name={AppRoutes.PantryMarketSearch}
        component={PantryMarketSearchScreen}
      />
      <Stack.Screen
        name={AppRoutes.ScanMarketCard}
        component={ScanMarketCardScreen}
      />
      <Stack.Screen name={AppRoutes.Welcome} component={WelcomeScreen} />
      <Stack.Screen
        name={AppRoutes.CreditCardAdd}
        component={CreditCardAddScreen}
      />
      <Stack.Screen name={AppRoutes.CreditCard} component={CreditCardScreen} />
      <Stack.Screen name={AppRoutes.CheckEmail} component={CheckEmailScreen} />
      <Stack.Screen
        name={AppRoutes.AccountSetup}
        component={AccountSetupScreen}
      />
      <Stack.Screen name={AppRoutes.EnterPin} component={EnterPinScreen} />
      <Stack.Screen name={AppRoutes.WebContent} component={WebContentScreen} />
      <Stack.Screen
        name={AppRoutes.PrivacyPolicy}
        component={PrivacyPolicyScreen}
      />

      <Stack.Screen
        name={AppRoutes.NeverUsedApp}
        component={NeverUsedAppScreen}
      />
      <Stack.Screen
        name={AppRoutes.LinkExistingAccount}
        component={LinkExistingAccountScreen}
      />
      <Stack.Screen
        name={AppRoutes.MarketCardRegistration}
        component={MarketCardRegistrationScreen}
      />
      <Stack.Screen
        name={AppRoutes.ExistingAccountWarningScreen}
        component={ExistingAccountWarningScreen}
      />
      <Stack.Screen
        name={AppRoutes.MarketCardNotUniqueScreen}
        component={SelectMarketCardLocationScreen}
      />
      <Stack.Screen
        name={AppRoutes.RegistrationEnterPinScreen}
        component={RegistrationEnterPinScreen}
      />
      <Stack.Screen
        name={AppRoutes.Environment}
        component={EnvironmentScreen}
      />
      <Stack.Screen
        name={AppRoutes.EnteredEmail}
        component={EnteredEmailScreen}
      />
      <Stack.Screen
        name={AppRoutes.KioskCreateAccount}
        component={KioskCreateAccountScreen}
      />
      <Stack.Screen
        name={AppRoutes.KioskCreatePassword}
        component={KioskCreatePasswordScreen}
      />
      <Stack.Screen
        name={AppRoutes.CountrySelection}
        component={CountrySelectionScreen}
      />
      <Stack.Screen
        name={AppRoutes.ItalyAccountInfo}
        component={ItalyAccountInfoScreen}
      />
      <Stack.Screen
        name={AppRoutes.ScanKioskQR}
        component={ScanKioskQRScreen}
      />
      <Stack.Screen
        name={AppRoutes.CreateAccountEnterEmail}
        component={CreateAccountEnterEmailScreen}
      />
      <Stack.Screen
        name={AppRoutes.KioskChangeEmail}
        component={KioskChangeEmailScreen}
      />
      <Stack.Screen
        name={AppRoutes.ExistingAccount}
        component={ExistingAccountScreen}
      />
      <Stack.Screen
        name={AppRoutes.Demographic}
        component={DemographicScreen}
      />
      <Stack.Screen
        name={AppRoutes.ReferralLanding}
        component={ReferralLandingScreen}
      />
      <Stack.Screen
        name={AppRoutes.AccountReady}
        component={AccountReadyScreen}
      />
      <Stack.Screen
        name={AppRoutes.PurchaseHistoryDetail}
        component={PurchaseReceiptScreen}
      />
      <Stack.Screen
        name={AppRoutes.ReferralReadyScreen}
        component={ReferralReadyScreen}
      />
    </Stack.Navigator>
  );
}
