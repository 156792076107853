import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import Styles from '../../../../Styles';
import {
  FlatList,
  Platform,
  RefreshControl,
  TouchableOpacity,
  View,
} from 'react-native';
import FontAwesome5Pro from 'src/components/icons/FontAwesomeIcon';
import Localized from 'src/constants/AppStrings';
import React from 'react';
import AVText from 'src/components/elements/AVText';
import CreditCard from 'src/models/CreditCard';
import {WithLocalizedProps} from 'src/types/PropTypes';
import CreditCardImage from 'src/components/elements/CreditCardImage';
import Star from 'src/components/img/svg/inbox/Star';
import Settings from 'src/Settings';
import NoCardAvailable from 'src/components/screens/funding/NoCardAvailable';
import AccountConstants from 'src/constants/AccountConstants';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';

export function getWalletTopButtonsSection(): JSX.Element {
  return null;
}

export function getWalletCreditCardsSection(
  props,
  state,
  callbacks,
  onRefresh,
): JSX.Element {
  return (
    <FlatList
      data={props.creditCards}
      renderItem={(item) => renderCreditCard(item, callbacks)}
      automaticallyAdjustContentInsets={false}
      ListFooterComponent={() => renderPayroll(props, state, callbacks)}
      extraData={state}
      keyExtractor={(item) => item.id}
      contentContainerStyle={{
        paddingHorizontal: Settings.isNewUI() ? Styles.Spacing.m3 : 0,
      }}
      refreshControl={
        <RefreshControl
          refreshing={state.isRefreshing}
          onRefresh={onRefresh}
          tintColor={Styles.primaryColor}
          colors={[Styles.primaryColor]}
          titleColor={Styles.black}
        />
      }
    />
  );
}

function renderCreditCard({item}: {item: CreditCard}, callbacks) {
  return (
    <CreditCardCell
      key={item.id}
      strings={Localized}
      creditCard={item}
      onCreditCardClick={callbacks[0]}
    />
  );
}

type CreditCardCellProps = WithLocalizedProps & {
  onCreditCardClick: (creditCard) => void;
  creditCard: CreditCard;
};

const CreditCardCell: React.FC<CreditCardCellProps> = ({
  onCreditCardClick,
  creditCard,
  strings,
}) => (
  <AVTouchableOpacity
    accessible={true}
    accessibilityRole="button"
    accessibilityHint="Double tap to navigate to the screen of the chosen card to update info or delete"
    role="button"
    onPress={() => onCreditCardClick(creditCard)}
    accessibilityLabel="onCreditCardClick"
    aria-label="onCreditCardClick"
  >
    <View style={[getWalletStyles()['creditCard']]}>
      <View style={[getWalletStyles()['creditCardInfo']]}>
        <View
          accessibilityLabel={`Issuer ${creditCard.cardIssuer}`}
          accessibilityRole="image"
          aria-label={`Issuer ${creditCard.cardIssuer}`}
          role="img"
          style={[]}
        >
          <CreditCardImage issuer={creditCard.cardIssuer} />
        </View>
        <View style={{padding: Styles.Spacing.m2 + 4}}>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            accessibilityLabel={strings.Labels.credit_card}
            accessibilityRole="text"
            aria-label={`${strings.Labels.credit_card}, text`}
            style={[getWalletStyles()['label']]}
          >
            {strings.Labels.credit_card}
          </AVText>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            accessibilityLabel={creditCard.name}
            accessibilityRole="text"
            aria-label={`${creditCard.name}, text`}
            style={[getWalletStyles()['cardNumber']]}
          >
            {creditCard.name}
          </AVText>
        </View>
      </View>
      {creditCard.isDefault && (
        <View style={[getWalletStyles()['starIconView'], {left: 25}]}>
          <View
            accessibilityLabel="Default payment method, "
            aria-label="Default payment method, "
            style={getWalletStyles()['starIcon']}
          >
            <Star width={Styles.Fonts.f3} height={Styles.Fonts.f3} />
          </View>
        </View>
      )}
      <View
        style={[
          getWalletStyles()['starIconView'],
          //creditCard.isDefault ? {left: 20} : {left: 40},
        ]}
      >
        {
          <FontAwesome5Pro
            accessibilityLabel="Double tap to edit payment method"
            aria-label="Double tap to edit payment method"
            name="chevron-right"
            color={Styles.lightGray}
            size={Styles.Fonts.f2}
            light
          />
        }
      </View>
    </View>
  </AVTouchableOpacity>
);

function renderPayroll(props, state, callbacks) {
  const footerComp: Array<JSX.Element> = [];
  if (props.creditCards.length === 0) {
    footerComp.push(
      <NoCardAvailable
        title={Localized.Labels.no_cards_available}
        desc={Localized.Labels.add_card_to_fund_account}
        onPress={callbacks[2]}
      />,
    );
  }

  if (state.showPayroll) {
    const chevron = (
      <FontAwesome5Pro
        accessibilityLabel="View payroll information"
        aria-label="View payroll information"
        name="chevron-right"
        color={Styles.lightGray}
        size={Styles.Fonts.f2}
        light
      />
    );
    let rightView;
    let additionalText;

    if (state.payrollPending) {
      additionalText = (
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          accessibilityLabel="Payroll pending"
          accessibilityRole="text"
          aria-label={`${'Payroll pending'}, text`}
          style={[getWalletStyles()['cardNumber']]}
        >
          {Localized.Labels.pending}
        </AVText>
      );
      rightView = (
        <View
          style={[getWalletStyles()['pendingView'], {alignItems: 'center'}]}
        >
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            accessibilityLabel={Localized.Labels.pending_upper_case}
            accessibilityRole="text"
            aria-label={`${Localized.Labels.pending_upper_case}, text`}
            style={[
              getWalletStyles()['cardNumber'],
              getWalletStyles()['pending'],
            ]}
          >
            {Localized.Labels.pending_upper_case}
          </AVText>
          {chevron}
        </View>
      );
    } else {
      const last4 = props.payrollId?.replace(/\d(?=\d{4})/g, 'x');
      additionalText = (
        <AVText
          accessibilityLabel={Localized.Labels.last_four_of_card}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.last_four_of_card}, text`}
          style={[getWalletStyles()['cardNumber']]}
        >
          {last4}
        </AVText>
      );
      rightView = (
        <View
          style={[getWalletStyles()['pendingView'], {alignItems: 'center'}]}
        >
          {<>{chevron}</>}
        </View>
      );
    }

    footerComp.push(
      <TouchableOpacity
        accessible={true}
        onPress={callbacks[1]}
        style={[getWalletStyles()['creditCard']]}
        accessibilityLabel="payrollDeductButton"
        aria-label="payrollDeductButton"
        role="button"
      >
        <View style={getWalletStyles()['creditCardInfo']}>
          <View
            //style={{}}
            accessibilityLabel="Payroll image"
            accessibilityRole="image"
            aria-label="Payroll image"
            role="img"
          >
            <CreditCardImage
              issuer={AccountConstants.PAYROLL_TOKEN.toString()}
            />
          </View>
          <View style={{padding: Styles.Spacing.m2 + 4}}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              accessibilityLabel={Localized.Labels.payroll_deduct}
              accessibilityRole="text"
              aria-label={`${Localized.Labels.payroll_deduct}, text`}
              style={[getWalletStyles()['label']]}
            >
              {Localized.Labels.payroll_deduct}
            </AVText>
            {additionalText}
          </View>
        </View>
        {rightView}
      </TouchableOpacity>,
    );
  }

  return <>{footerComp}</>;
}

export function getWalletStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      flex: 1,
    },
    buttonsView: {
      flexDirection: 'row',
      marginHorizontal: Styles.Spacing.m3,
      marginVertical: Styles.Spacing.m3,
      height: 70,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    buttonContainer: {
      height: 55,
      flex: 1,
      borderRadius: Styles.Spacing.m2,
      borderWidth: 1,
      borderColor: Styles.darkGreenRevolve,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    addButtonText: {
      marginLeft: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.f8,
      fontWeight: '700',
      color: Styles.black,
      textAlignVertical: 'center',
    },
    btnStyle: {
      alignSelf: 'center',
    },
    safeAreaView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },

    creditCardInfo: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    issuerImage: {
      alignSelf: 'flex-start',
      marginRight: Styles.Spacing.m2,
    },
    label: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1,
    },
    pending: {
      color: Styles.warningColor,
      marginRight: Styles.Spacing.m2,
    },
    pendingView: {
      flexDirection: 'row',
    },
    starIconView: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    starIcon: {
      bottom: 3,
    },
    subLabel: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f0,
    },
    blurBackground: {
      position: 'absolute',
      top: Styles.Spacing.m0,
      bottom: Styles.Spacing.m0,
      left: Styles.Spacing.m0,
      right: Styles.Spacing.m0,
      backgroundColor: Styles.overlay,
    },
    modal: {
      flex: 1,
      borderRadius: Styles.Spacing.m2,
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContainer: {
      width: Styles.ScreenWidth / 1.1,
      height:
        Platform.OS === 'ios'
          ? Styles.ScreenHeight / 3
          : Styles.ScreenHeight / 2,
      borderRadius: 10,
    },
    subModalContainer: {
      backgroundColor: Styles.addCardPopupColor,
      paddingHorizontal: Styles.Spacing.m2,
      paddingVertical: Styles.Spacing.m3,
      borderRadius: Styles.Spacing.m2,
    },
    titleContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    cancelText: {
      color: Styles.primaryColor,
    },
    boldText: {
      fontWeight: 'bold',
      color: Styles.darkColor,
    },
    cardForm: {
      height: Styles.ScreenHeight / 2,
      marginTop: Styles.Spacing.m3,
    },
    cardStyle: {
      backgroundColor: Styles.addCardPopupColor,
    },
    cardNumber: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f1,
    },
    addView: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: Styles.Spacing.m3 + 8,
      marginLeft: Styles.Spacing.m2,
    },
    addViewWrapper: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginBottom: Styles.Spacing.m3,
    },
    addBtn: {
      marginRight: Styles.Spacing.m2 + 2,
      //paddingVertical: Styles.Spacing.m1,
      marginBottom: Styles.Spacing.m2,
    },
    nocardsContainer: {
      width: 300,
      height: 450,
      marginVertical: 'auto',
      padding: 120,
      top: -40,
    },
    addBtnIcon: {
      marginLeft: 8,
    },
    circle: {
      width: 50,
      height: 50,
      borderRadius: 100 / 2,
      backgroundColor: Styles.primaryColor,
    },
    creditCard: {
      alignItems: 'center',
      backgroundColor: Styles.white,
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: Styles.Spacing.m3,
      paddingLeft: Styles.Spacing.m2,
      borderBottomColor: Styles.lightGray,
    },
    tileDesign: {
      width: '100%',
      height: 82,
      padding: Styles.Spacing.m3,
      flexDirection: 'row',
      backgroundColor: Styles.white,
      borderRadius: Styles.Spacing.m2,
      marginBottom: Styles.Spacing.m2 + Styles.Spacing.m1,
      justifyContent: 'space-between',
      display: 'flex',
      paddingHorizontal: 15,
      paddingVertical: 10,
      ...Styles.Style.shadow,
    },
    revolveAddTxt: {fontWeight: '400', fontSize: 18, color: Styles.black},
    revolveMainTxt: {fontWeight: '700', fontSize: 18, color: Styles.black},
    revolvePendingRightView: {fontWeight: '400', fontSize: 16},
  };
}

export function getBalancesTitle(): JSX.Element {
  return null;
}

export function getBalancesList() {
  return null;
}

export function getCardsTitle(): JSX.Element {
  return null;
}

export function getWalletBottomSection(
  creditCards,
  showPayroll,
  callbacks,
): JSX.Element {
  return (
    <View style={[getWalletStyles()['addView'], {justifyContent: 'flex-end'}]}>
      {getRoundedButton(
        Localized.Labels.add_egift_card,
        'Double tap to add new egift card',
        callbacks[0],
      )}
      {getRoundedButton(
        Localized.Labels.add_payment_card,
        'Double tap to add new payment card',
        callbacks[1],
      )}
    </View>
  );
}

function getRoundedButton(label, hint, onpress) {
  return (
    <RoundedButton
      accessible={true}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
      accessibilityLabel={label}
      accessibilityHint={hint}
      accessibilityRole="button"
      aria-label={label}
      role="button"
      containerStyle={[
        getWalletStyles()['addBtn'],
        {borderRadius: Styles.Spacing.m4},
      ]}
      buttonType={ButtonType.outline}
      text={label}
      textStyle={null}
      onPress={onpress}
    />
  );
}

export function getWalletBalanceSection(): JSX.Element {
  return null;
}

export default {
  getBalancesList,
  getBalancesTitle,
  getCardsTitle,
  getWalletStyles,
  getWalletTopButtonsSection,
  getWalletBottomSection,
  getWalletBalanceSection,
  getWalletCreditCardsSection,
};
