import * as React from 'react';
import Svg, {Path, Mask} from 'react-native-svg';
interface IProps {
  size?: number;
  color?: string;
}
const pathDrawnValue =
  'M16.707 12 7.816 2 6.121 3.905 13.32 12 6.12 20.095 7.815 22l8.892-10Z';
const RightIcon = ({size = 22, color = '#727179'}: IProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      fill={color}
      fillRule="evenodd"
      d={pathDrawnValue}
      clipRule="evenodd"
    />
    <Mask width={11} height={20} x={6} y={2} maskUnits="userSpaceOnUse" id="a">
      <Path
        d={pathDrawnValue}
        fill="#fff"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Mask>
  </Svg>
);
export default RightIcon;
