import RewardsView from 'src/components/elements/home/RewardsView';
import RewardsLegcayView from 'src/components/elements/home/RewardsLegcayView';
import AccountStore from 'src/stores/AccountStore';
const WithRewardsHOC = (GmaRewards, LegacyRewards) => {
  return ({data}) => {
    if (
      data.rewardPrograms?.length > 0 &&
      AccountStore.getRewardStatus() &&
      Object.keys(AccountStore.getRewardStatus()).length > 0 &&
      AccountStore.getRewardStatus()?.hasOwnProperty('rewardTier') &&
      AccountStore.getRewardStatus()?.hasOwnProperty('rewardStatuses') &&
      AccountStore.getRewardProducts() &&
      Object.keys(AccountStore.getRewardProducts()).length > 0
    ) {
      return <GmaRewards />;
    } else if (data.points > 0 && !data.rewardPrograms?.length) {
      return <LegacyRewards />;
    } else {
      return <></>;
    }
  };
};

export const RewardsWrappedComponent = WithRewardsHOC(
  RewardsView,
  RewardsLegcayView,
);
