import Analytics from 'src/nativeModules/Analytics';

class UrlsChangedEvent {
  trackEvent(
    email: string,
    locationId: string,
    locationName: string,
    environment: string,
    app: string,
    urls: Record<string, string>,
  ) {
    const logData = {
      email,
      locationId,
      locationName,
      environment,
      app,
      ...urls,
    };
    Analytics.trackEvent('UrlsChanged', logData);
  }
}

export default new UrlsChangedEvent();
