import React, {useEffect, useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {useGlobalize} from 'react-native-globalize';
import NavActions from 'src/actions/NavActions';
import ReferralActions from 'src/actions/ReferralActions';
import AppRoutes from 'src/AppRoutes';
import AVText from 'src/components/elements/AVText';
import LoadingScreen from 'src/components/screens/LoadingScreen';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import AccountStore from 'src/stores/AccountStore';
import {Referral} from 'src/types/Referral';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {referralTemplate} from 'src/components/screens/referral/referralHelper';
import LinearGradient from 'react-native-linear-gradient';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import {SafeAreaView} from 'react-native-safe-area-context';
import PersistentStore from 'src/services/PersistentStoreService';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';

type AccountReadyProps = {
  referralId?: string;
};

const AccountReadyScreen: React.FC<AccountReadyProps> = () => {
  const {formatCurrency} = useGlobalize();
  const [referral, setReferral] = useState<Referral>();

  useEffect(() => {
    getReferral();
  }, []);

  const getReferral = async () => {
    const referralIdSaved = await PersistentStore.getReferralId();
    try {
      const referralDetails = (await ReferralActions.getReferralDetails(
        referralIdSaved,
      )) as Referral;
      setReferral(referralDetails);
      FirebaseAnalytic.trackEvent('getReferral', 'ReferralReadyScreen', {
        referralIdSaved,
        referralDetails,
      });
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchReferralDetails',
        JSON.stringify({referralIdSaved}),
        JSON.stringify(referralDetails),
      );
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'AccountReadyScreen:getReferral',
        generateErrorMessage(error),
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchReferralDetails',
        JSON.stringify({referralIdSaved}),
        JSON.stringify(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountReadyScreen:getReferral',
        generateErrorMessage(error),
      );
    }
  };

  const handleInboxClick = async () => {
    await PersistentStore.setReferralId(null);
    FirebaseAnalytic.trackEvent('onPress', 'ReferralReadyScreen', {
      referral,
    });
    NavActions.nestedPush(
      [AppRoutes.Main, AppRoutes.HomeTab],
      AppRoutes.Inbox,
      {
        selectedTab: 'Messages',
      },
    );
  };
  const handleHomeClick = async () => {
    await PersistentStore.setReferralId(null);
    NavActions.reset(AppRoutes.Main);
  };

  return (
    <LinearGradient
      colors={referralTemplate.colors}
      start={referralTemplate.start}
      end={referralTemplate.end}
      style={styles.gradientBackground}
      locations={referralTemplate.locations}
    >
      <SafeAreaView style={{height: 67}} />
      <View
        style={{
          flex: 1,
        }}
      >
        {!referral ? (
          <LoadingScreen />
        ) : (
          <>
            <View
              style={{
                alignSelf: 'center',
              }}
            >
              {referralTemplate.accountReadyIcon}
            </View>

            <View
              style={{
                marginHorizontal: Styles.Spacing.m3,
              }}
            >
              <AVText
                style={[
                  styles.youGotText,
                  {fontFamily: referralTemplate.textFontFamily},
                ]}
              >
                {Localized.Labels.you_got}
              </AVText>
              <AVText
                style={[
                  styles.amount,
                  {
                    fontFamily: referralTemplate.amountFontFamily,
                    color: referralTemplate.amountColor,
                  },
                ]}
              >
                {formatCurrency(
                  referral?.amount ?? 0,
                  AccountStore.getCurrency(),
                )?.replace(AccountStore.getCurrency().substring(0, 2), '')}
              </AVText>
              <AVText
                style={[
                  styles.text,
                  {
                    fontFamily: referralTemplate.textFontFamily,
                    marginHorizontal: Styles.Spacing.m3,
                  },
                ]}
              >
                {Localized.Labels.redeem_from_inbox}
              </AVText>

              <RoundedAppButton
                titleText={Localized.Buttons.claim_your_gift}
                accessibilityHintValue={Localized.Buttons.claim_your_gift}
                accessibilityLabelValue={Localized.Buttons.claim_your_gift}
                aria-label={Localized.Buttons.claim_your_gift}
                buttonType={RoundedAppButtonType.Solid}
                onPress={handleInboxClick}
                buttonContainerStyle={styles.buttonStyle}
                buttonViewStyle={{
                  backgroundColor: referralTemplate.buttonColor,
                  paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
                  borderRadius: Styles.Spacing.m4,
                }}
                textStyle={{fontFamily: referralTemplate.textFontFamily}}
              />
              <TouchableOpacity
                accessible
                style={{marginTop: Styles.Spacing.m4 - 2}}
                onPress={handleHomeClick}
              >
                <AVText
                  style={[
                    styles.hometext,
                    {
                      fontFamily: referralTemplate.textFontFamily,
                      color: referralTemplate.buttonColor,
                    },
                  ]}
                >
                  {Localized.Buttons.home_page}
                </AVText>
                <View
                  style={{
                    borderBottomColor: referralTemplate.buttonColor,
                    borderBottomWidth: 2,
                    width: 100,
                    alignSelf: 'center',
                  }}
                />
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  gradientBackground: {
    flex: 1,
  },
  title: {
    fontWeight: '400',
    fontSize: Styles.Fonts.sectionHeader,
    textAlign: 'center',
    color: Styles.Colors.PayNew.black01,
  },
  youGotText: {
    fontSize: Styles.Fonts.sectionHeader,
    fontWeight: '400',
    textAlign: 'center',
    color: Styles.Colors.PayNew.black01,
    lineHeight: 20,
  },
  amount: {
    fontSize: Styles.Fonts.f6 - 3.34,
    fontWeight: '700',
    paddingVertical: Styles.Spacing.m3,
    textAlign: 'center',
  },
  text: {
    color: Styles.Colors.PayNew.black01,
    fontSize: Styles.Fonts.sectionHeader,
    textAlign: 'center',
    fontWeight: '400',
  },
  buttonStyle: {
    marginTop: 60,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  hometext: {
    textAlign: 'center',
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
  },
});

export default AccountReadyScreen;
