import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Dimensions,
  Image,
} from 'react-native';
import Styles from '../../Styles';
import AVText from 'src/components/elements/AVText';
import {ensureHttps} from 'src/services/StringUtils';
import {LocationDto, LocationType} from 'src/types/Location';

export type CampusLocationType = LocationDto &
  LocationType & {
    displayName?: string;
    imageUrl?: string;
    id: string;
    locationType: string;
  };

export enum CampusLocationCategory {
  Dining = 'Dining',
  ScanAndPay = 'ScanAndPay',
  Vending = 'Venidng',
}

type CampusLocationViewProps = {
  location: CampusLocationType;
  locationCategory: CampusLocationCategory;
  onLocationClick: (
    machine: CampusLocationType,
    autoAdvanced?: boolean,
  ) => Promise<void>;
};

const width = Dimensions.get('window').width;

const CampusLocationView = (props: CampusLocationViewProps) => {
  //Returns location image uri if location has image or else returns placeholder image based on location type
  const imageUri = () => {
    if (props.location.imageUrl) {
      return {uri: ensureHttps(props.location.imageUrl)};
    }
    switch (props.locationCategory) {
      case CampusLocationCategory.Dining:
        return require('src/components/img/DiningPlaceholderImage.png');
      case CampusLocationCategory.Vending:
        return require('src/components/img/VendingPlaceholderImage.png');
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => props.onLocationClick(props.location)}
        accessible={true}
        accessibilityHint={`Double tap to select ${
          props.location.displayName ?? props.location.name
        }`}
        accessibilityLabel={`Location${
          props.location.displayName ?? props.location.name
        }`}
        accessibilityRole="button"
        role="button"
        aria-label={`Location${
          props.location.displayName ?? props.location.name
        }`}
        style={styles.imageContainer}
      >
        <Image
          accessibilityLabel={`${
            props.location.displayName ?? props.location.name
          }Image`}
          accessibilityRole="image"
          aria-label={`${
            props.location.displayName ?? props.location.name
          }Image`}
          role="img"
          style={styles.imageView}
          source={imageUri()}
        />
      </TouchableOpacity>
      <AVText
        accessibilityLabel={`LocationName${
          props.location.displayName ?? props.location.name
        }`}
        accessibilityRole="text"
        aria-label={`${
          props.location.displayName ?? props.location.name
        }, text`}
        numberOfLines={1}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        style={styles.nameText}
      >
        {props.location.displayName ?? props.location.name}
      </AVText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m2,
  },
  imageContainer: {
    flex: 1,
    width: width - Styles.Spacing.m4 - Styles.Spacing.m3,
    height: (width - Styles.Spacing.m4 - Styles.Spacing.m3) / 1.3,
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderRadius: Styles.Spacing.m2,
    overflow: 'hidden',
  },
  imageView: {
    width: width - Styles.Spacing.m4,
    height: (width - Styles.Spacing.m4) / 1.3,
  },
  nameText: {
    color: Styles.darkColor,
    fontWeight: '700',
    fontSize: Styles.Fonts.sectionHeader,
    marginTop: Styles.Spacing.m2,
    paddingLeft: Styles.Spacing.m1,
    fontFamily: Styles.FontFamily.robotoRegular,
    width: width - Styles.Spacing.m4 - Styles.Spacing.m3,
  },
});

export default CampusLocationView;
