import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import BackSubheader from '../../elements/BackSubheader';
import KeyboardAwareScrollView from '../../elements/KeyboardAwareScrollView';
import withIsConnected from '../../hoc/withIsConnected';
import Styles from '../../Styles';
import type {IsConnectedProps} from 'src/types/Screen';
import ActionsFactory from 'src/actions/ActionsFactory';
import PersistentStore from 'src/services/PersistentStoreService';
import Localized from 'src/constants/AppStrings';
import {alertError, confirm} from '../../helpers/AlertHelper';
import CreditCard from 'src/models/CreditCard';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import {NavigationProp} from '@react-navigation/native';
import Util, {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Settings from 'src/Settings';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {getCardDetailsDescriber} from 'src/components/screens/funding/descriptor/DescriptorType';
import AccountStore from 'src/stores/AccountStore';

type CreditCardDetailScreenProps = IsConnectedProps & {
  creditCard: CreditCard;
  navigation: NavigationProp<CreditCardDetailScreen>;
};
type CreditCardDetailScreenState = {
  defaultTokenStatus: boolean;
  previousRoute: string;
};

class CreditCardDetailScreen extends React.Component<
  CreditCardDetailScreenProps,
  CreditCardDetailScreenState
> {
  scrollView: KeyboardAwareScrollView | null;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: CreditCardDetailScreenProps) {
    super(props);
    this.state = {
      defaultTokenStatus: this.props.creditCard.isDefault,
      previousRoute: '',
    };
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleSetAsDefaultClick = this.handleSetAsDefaultClick.bind(this);
    this.defalutSwitchAction = this.defalutSwitchAction.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  async handleDeleteClick() {
    confirm(Localized.Labels.confirm_delete_card, async () => {
      try {
        this.context.actions.showSpinner();
        const accountId = AccountStore.getAccountId();
        const response =
          await ActionsFactory.getAccountActions().deleteCreditCard(
            accountId || '',
            this.props.creditCard.id || '',
            this.props.creditCard.accountBalanceId || '',
            this.props.creditCard.isDefault,
          );
        this.context.actions.hideSpinner();
        if (response?.status === 'ok') {
          FirebaseAnalytic.trackEvent('DeleteCard', 'CreditCardAddScreen', {
            ...this.props,
            ...this.state,
          });
          NavActions.pop();
        } else {
          alertError(Localized.Errors.error_updating_credit_card);
        }
      } catch (e) {
        alertError(Localized.Errors.error_updating_credit_card);
        CrashlyticsEvents.log(
          'Exception',
          'CreditCardDetailScreen:handleDeleteClick',
          e.message ? e.message : e.toString(),
        );
        Events.Error.trackEvent(
          'Exception',
          'CreditCardDetailScreen:handleDeleteClick',
          e.message ? e.message : e.toString(),
        );
      }
    });
  }

  defalutSwitchAction(value: boolean): void {
    this.setState({defaultTokenStatus: value});
    this.handleSetAsDefaultClick();
  }

  async handleSetAsDefaultClick() {
    FirebaseAnalytic.trackEvent(
      'handleSetAsDefaultClick',
      'CreditCardAddScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    confirm(
      Localized.Labels.confirm_set_as_default_card,
      async () => {
        this.context.actions.showSpinner();
        try {
          const accountId = await PersistentStore.getAccountId();
          const response =
            await ActionsFactory.getAccountActions().updateDefaultToken(
              accountId || '',
              this.props.creditCard.accountBalanceId || '',
              this.props.creditCard.id || '',
            );

          FirebaseAnalytic.trackEvent(
            'handleSetAsDefaultClick updateDefaultToken',
            'CreditCardAddScreen',
            {
              ...this.props,
              ...this.state,
              accountId,
              response,
            },
          );
          this.context.actions.hideSpinner();
          NavActions.pop();
        } catch (error) {
          CrashlyticsEvents.log(
            'Exception',
            'CreditCardDetailScreen:HandleSetAsDefaultClick',
            generateErrorMessage(error),
          );
          Events.Error.trackEvent(
            'Exception',
            'CreditCardDetailScreen:HandleSetAsDefaultClick',
            generateErrorMessage(error),
          );
          alertError(Localized.Errors.error_setting_default_credit_card);
        }
      },
      () => NavActions.pop(),
    );
  }

  render() {
    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={getCardDetailsDescriber().getCardDetailsTitle()}
      >
        <View style={styles.content}>
          <KeyboardAwareScrollView
            contentContainerStyle={styles.scrollView}
            keyboardDismissMode="interactive"
            style={styles.scrollView}
            ref={(scrollView) => {
              this.scrollView = scrollView;
            }}
            automaticallyAdjustContentInsets={false}
          >
            {getCardDetailsDescriber().getCardDetailsView(
              this.props.creditCard,
            )}
            {getCardDetailsDescriber().getCardExpiredView(
              Util.isCreditCardExpired(this.props.creditCard),
            )}
            <View style={{marginHorizontal: Styles.Spacing.m3}}>
              {getCardDetailsDescriber().getDefaultStatusView(
                this.props.creditCard,
                this.state.defaultTokenStatus,
                this.defalutSwitchAction,
                Util.isCreditCardExpired(this.props.creditCard),
              )}
            </View>
          </KeyboardAwareScrollView>
          {this.props.isConnected &&
            getCardDetailsDescriber().getDeleteButton(this.handleDeleteClick)}
        </View>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    padding: Settings.isNewUI() ? Styles.Spacing.m2 : 0,
  },
  scrollView: {
    alignSelf: 'stretch',
    paddingTop: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams()(
  withIsConnected(CreditCardDetailScreen),
);
