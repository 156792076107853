import React, {useState} from 'react';
import {
  StyleSheet,
  View,
  Text,
  FlatList,
  Image,
  StyleProp,
  ViewStyle,
  ImageStyle,
} from 'react-native';
import moment from 'moment';
import type {
  RecentOrderType,
  RecentOrderItemType,
  RecentOrderItemModifierType,
} from 'src/types/RecentOrders';
import Styles from '../../Styles';
import AVFormattedCurrency from '../AVFormattedCurrency';
import TouchableOpacity from '../AVTouchableOpacity';
import {WithLocalizedProps} from 'src/types/PropTypes';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import MenuService from 'src/services/MenuService';
import ChevronUp from 'src/components/img/svg/ChevronUp';
import AVText from 'src/components/elements/AVText';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import {getDescriber} from 'src/components/elements/orderAhead/descriptor/DescriptorType';

const {getMenuListCompnentsFontFamily} = getDescriber();

type RecentOrderProps = WithLocalizedProps & {
  recentOrder: RecentOrderType;
  addAllItems: (order: RecentOrderType) => void;
  addItem: (item: RecentOrderItemType) => void;
  productCellPressed: (item: RecentOrderItemType) => void;
};

export default ({
  strings,
  recentOrder,
  addAllItems,
  addItem,
  productCellPressed,
}: RecentOrderProps) => {
  const hasAddAllButton =
    recentOrder.items.filter((item) => item.available === true).length > 0;
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const getMenuItemUrl = (scanCode: string) => {
    const product = MenuService.getProductByScanCode(scanCode);
    const fullProduct = MenuService.getProduct(product?.id);

    return fullProduct?.smallimageurl;
  };

  const getTimeAndDate = (dateString: string) => {
    return moment(dateString).format('MM/DD/YY');
  };

  const getShowHide = (numOfItems: number) => {
    if (expanded) {
      return getHideText(numOfItems);
    } else {
      return getShowText(numOfItems);
    }
  };

  const getHideText = (numOfItems: number) => {
    let displayText = '';
    if (numOfItems > 1) {
      displayText = Localized.Labels.formatString(
        Localized.Labels.hide_details_1,
        numOfItems,
      ) as string;
    } else {
      displayText = Localized.Labels.hide_details_0;
    }
    return displayText;
  };

  const getShowText = (numOfItems: number) => {
    let displayText = '';
    if (numOfItems > 1) {
      displayText = Localized.Labels.formatString(
        Localized.Labels.show_details_1,
        numOfItems,
      ) as string;
    } else {
      displayText = Localized.Labels.show_details_0;
    }
    return displayText;
  };

  const getRecentOrderSubItems = () => {
    return recentOrder.items.length;
  };

  const getSubItems = () => {
    return recentOrder.items;
  };

  const isSingleCartItem = () => {
    return recentOrder.items.length == 1;
  };
  const calculateTotal = (data) => {
    let totalSum = 0;
    data.forEach((item) => {
      if (item.available) {
        const productPrice = item.price; // Product price
        const modifierPrices = item.modifiers.reduce(
          (sum, modifier) => sum + modifier.price,
          0,
        ); // Sum of all modifier prices

        totalSum += productPrice + modifierPrices;
      }
    });

    return totalSum;
  };

  return (
    <View style={[Styles.Style.shadow, styles.container]}>
      <View>
        <View style={styles.titleBar}>
          <View>
            {getMenuItemUrl(recentOrder.items[0].scancode) == null ? (
              <View style={styles.defaultImageContainer}>
                <Image
                  accessible
                  accessibilityLabel={`Default-MenuIcon Image`}
                  accessibilityRole="image"
                  aria-label="Default-MenuIcon"
                  role="img"
                  style={styles.parentListImage}
                  source={require('src/components/img/DefaultMenuItem.png')}
                  resizeMode="cover"
                />
              </View>
            ) : (
              <View style={styles.imageContainer}>
                <Image
                  accessible={true}
                  accessibilityElementsHidden={true}
                  importantForAccessibility="no-hide-descendants"
                  accessibilityRole="image"
                  role="img"
                  source={{
                    uri: getMenuItemUrl(recentOrder.items[0].scancode),
                  }}
                  style={[styles.parentImage]}
                  resizeMode="cover"
                  testID="productImage"
                />
              </View>
            )}
          </View>
          <View style={styles.titleContainer}>
            <Text
              style={styles.titleText}
              numberOfLines={2}
              ellipsizeMode="tail"
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
            >
              {recentOrder.items[0].name}
            </Text>
            <Text
              style={styles.dateText}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
            >
              {getTimeAndDate(recentOrder.transdate)}
            </Text>
            {
              <TouchableOpacity onPress={toggleExpand}>
                <View style={styles.showHideUnderLine}>
                  <Text
                    style={styles.showHideText}
                    numberOfLines={1}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                  >
                    {getShowHide(getRecentOrderSubItems())}
                  </Text>
                </View>
              </TouchableOpacity>
            }
          </View>
          <View>
            {hasAddAllButton ? (
              <RoundedAppButton
                buttonType={RoundedAppButtonType.Outline}
                titleText={Localized.Buttons.reoder_recent_menu}
                onPress={() => addAllItems(recentOrder)}
                buttonContainerStyle={{
                  marginTop: Styles.Spacing.m2,
                  alignSelf: 'center',
                }}
                buttonViewStyle={{
                  height: 32,
                }}
                accessibilityLabelValue="Reorder"
              />
            ) : (
              <Text
                style={styles.unavailable}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                {strings.Labels.unavailable}
              </Text>
            )}
            <AVFormattedCurrency
              style={styles.itemPrice}
              value={calculateTotal(recentOrder.items)}
              accessibilityLabel="price"
              currency={AccountStore.getCurrency()}
            />
          </View>
        </View>
      </View>
      {expanded && (
        <View>
          <View
            style={{
              borderBottomColor: Styles.Colors.PayNew.neutralHuesBase09,
              borderBottomWidth: 1,
              marginHorizontal: Styles.Spacing.m2,
            }}
          ></View>
          <FlatList
            data={getSubItems()}
            keyExtractor={(item) => item.id}
            renderItem={({item}) => {
              return (
                <RecentOrderItem
                  addItem={addItem}
                  singleCartItem={isSingleCartItem()}
                  strings={strings}
                  orderItem={item}
                  productCellPressed={productCellPressed}
                  hasAddAllButton={hasAddAllButton}
                />
              );
            }}
          />
          <TouchableOpacity
            style={{alignSelf: 'center', marginBottom: Styles.Spacing.m3}}
            onPress={toggleExpand}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <View style={styles.showHideUnderLine}>
                <Text
                  style={styles.hideText}
                  numberOfLines={1}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                >
                  {Localized.Labels.hide_details}
                </Text>
              </View>
              <View
                style={{
                  marginTop: Styles.Spacing.m1,
                  marginLeft: Styles.Spacing.m2,
                }}
              >
                <ChevronUp />
              </View>
            </View>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

type RecentOrderItemProps = WithLocalizedProps & {
  orderItem: RecentOrderItemType;
  singleCartItem: boolean;
  addItem: (item: RecentOrderItemType) => void;
  productCellPressed: (item: RecentOrderItemType) => void;
  hasAddAllButton?: boolean;
};

const RecentOrderItem: React.FC<RecentOrderItemProps> = ({
  strings,
  singleCartItem,
  orderItem,
  addItem,
  productCellPressed,
  hasAddAllButton,
}) => {
  const getMenuItemUrl = (scanCode: string) => {
    const product = MenuService.getProductByScanCode(scanCode);
    const fullProduct = MenuService.getProduct(product?.id);

    return fullProduct?.smallimageurl;
  };

  const getModifierTextStyle = (ordItem): StyleProp<ViewStyle> => {
    if (isModifiersPresent(ordItem.modifiers)) {
      return {alignSelf: 'flex-start'};
    } else {
      return {
        alignSelf: 'center',
        marginBottom: ordItem.available ? Styles.Spacing.m2 : 0,
      };
    }
  };

  const getModifierImageStyle = (modifiers): StyleProp<ImageStyle> => {
    if (isModifiersPresent(modifiers)) {
      return {alignSelf: 'flex-start'};
    } else {
      return {alignSelf: 'center'};
    }
  };

  const isModifiersPresent = (modifiers) => {
    if (modifiers?.length > 0) {
      return true;
    }
    return false;
  };

  const calculateRecentTotal = (item) => {
    let totalSum = 0;
    if (item.available) {
      const productPrice = item.price; // Product price
      const modifierPrices = item.modifiers.reduce(
        (sum, modifier) => sum + modifier.price,
        0,
      ); // Sum of all modifier prices

      totalSum += productPrice + modifierPrices;
    }

    return totalSum;
  };
  return (
    <>
      <TouchableOpacity
        accessibilityLabel="Add"
        onPress={() =>
          orderItem.available ? productCellPressed(orderItem) : {}
        }
        style={{
          marginVertical: 8,
        }}
      >
        <View style={styles.itemRowInnerParent}>
          <View style={styles.itemInnerRow}>
            {getMenuItemUrl(orderItem.scancode) == null ? (
              <View style={styles.defaultImageContainer}>
                <Image
                  accessible
                  accessibilityLabel={`Default-MenuIcon Image`}
                  accessibilityRole="image"
                  aria-label="Default-MenuIcon"
                  role="img"
                  style={[
                    styles.sublistImage,
                    getModifierImageStyle(orderItem.modifiers),
                  ]}
                  source={require('src/components/img/DefaultMenuItem.png')}
                  resizeMode="cover"
                />
              </View>
            ) : (
              <Image
                importantForAccessibility="no-hide-descendants"
                role="img"
                source={{
                  uri: getMenuItemUrl(orderItem.scancode),
                }}
                accessibilityRole="image"
                accessible={true}
                accessibilityElementsHidden={true}
                testID="productImage"
                style={[
                  styles.sublistImage,
                  getModifierImageStyle(orderItem.modifiers),
                ]}
                resizeMode="cover"
              />
            )}

            <View style={[styles.itemCenter, getModifierTextStyle(orderItem)]}>
              <Text
                style={styles.item}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                {orderItem.name}
              </Text>
              {orderItem.modifiers.length > 0 && (
                <FlatList
                  data={orderItem.modifiers}
                  keyExtractor={(itemm) => itemm.saleItemModifierId}
                  renderItem={({item}) => (
                    <RecentOrderItemModifier
                      strings={strings}
                      modifier={item}
                    />
                  )}
                />
              )}
            </View>
          </View>

          <View style={styles.itemRight}>
            {!singleCartItem && orderItem.available && (
              <RoundedAppButton
                buttonType={RoundedAppButtonType.Outline}
                buttonContainerStyle={{marginTop: Styles.Spacing.m2}}
                buttonViewStyle={{
                  height: 32,
                }}
                titleText={'+ ' + Localized.Buttons.add}
                onPress={() => addItem(orderItem)}
                accessibilityLabelValue="Add"
              />
            )}
            {!singleCartItem && orderItem.available && (
              <>
                <AVFormattedCurrency
                  style={styles.itemPrice}
                  value={calculateRecentTotal(orderItem)}
                  accessibilityLabel="price"
                  currency={AccountStore.getCurrency()}
                />
              </>
            )}
            {hasAddAllButton && !orderItem.available && (
              <Text
                style={styles.unavailable}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                {strings.Labels.unavailable}
              </Text>
            )}
          </View>
        </View>
      </TouchableOpacity>
    </>
  );
};

type RecentOrderItemModifierProps = WithLocalizedProps & {
  modifier: RecentOrderItemModifierType;
};

const RecentOrderItemModifier: React.FC<RecentOrderItemModifierProps> = ({
  modifier,
  strings,
}) => {
  let label;

  if (true) {
    label = (
      <AVText>
        {'('}
        <AVText style={{color: '#138665'}}>
          {'+'}
          <AVFormattedCurrency
            accessible={true}
            accessibilityLabel={`${strings.Labels.price}: $${modifier.price}`}
            aria-lable={`${strings.Labels.price}: $${modifier.price}`}
            style={[styles.modifierText, styles.modifierPrice]}
            value={modifier.price}
            currency={AccountStore.getCurrency()}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
          />
        </AVText>
        {')'}
      </AVText>
    );
  }
  return (
    <View style={styles.modifierContainer}>
      <Text
        style={styles.modifierText}
        numberOfLines={5}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
      >
        {modifier.name?.substring(0, 30) + ' '}
        {label}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: Styles.Spacing.m2 + Styles.Spacing.m1,
    marginHorizontal: Styles.Spacing.m3,
    borderRadius: Styles.Spacing.m2,
  },
  titleBar: {
    paddingHorizontal: Styles.Spacing.m2,
    paddingVertical: Styles.Spacing.m2 + Styles.Spacing.m1,
    alignItems: 'center',
    flexDirection: 'row',
  },
  showHideUnderLine: {
    alignSelf: 'flex-start',
    borderBottomColor: Styles.Colors.PayNew.primary01,
    borderBottomWidth: 1,
  },
  showHideText: {
    marginTop: Styles.Spacing.m2,
    color: Styles.Colors.PayNew.primary01,
    fontFamily: getMenuListCompnentsFontFamily(),
    fontSize: Styles.Fonts.f0,
  },
  hideText: {
    color: Styles.Colors.PayNew.primary01,
    fontSize: Styles.Fonts.f1,
    fontFamily: getMenuListCompnentsFontFamily(),
  },
  dateText: {
    marginTop: Styles.Spacing.m2,
    fontSize: Styles.Fonts.f8,
    fontFamily: getMenuListCompnentsFontFamily(),
    color: Styles.Colors.PayNew.darkHuesBase04,
  },
  titleContainer: {
    flex: 1,
    paddingHorizontal: Styles.Spacing.m2,
  },
  titleText: {
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
    fontFamily: getMenuListCompnentsFontFamily(),
    fontWeight: 'bold',
  },
  parentListImage: {
    width: 80,
    height: 73,
    padding: 2,
  },
  sublistImage: {
    width: 69,
    height: 58,
    borderRadius: 8,
  },
  defaultImageContainer: {
    padding: 2,
    alignSelf: 'flex-start',
    borderRadius: Styles.Spacing.m2,
    borderWidth: 1,
    borderColor: Styles.Colors.PayNew.neutralHuesBase09,
  },
  imageContainer: {
    width: 83,
    aspectRatio: 1.15,
    borderRadius: 8,
    overflow: 'hidden',
  },
  parentImage: {
    width: 83,
    aspectRatio: 1.15,
  },
  itemRowParent: {
    flexDirection: 'row',
    flex: 1,
  },
  itemRowInnerParent: {
    flexDirection: 'row',
    flex: 1,
  },
  itemInnerRow: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: Styles.Spacing.m1,
    marginRight: Styles.Spacing.m2,
    marginLeft: Styles.Spacing.m2,
  },
  item: {
    marginLeft: Styles.Spacing.m2,
    color: Styles.darkColor,
    alignSelf: 'flex-start',
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m2,
    fontWeight: 'bold',
  },
  itemPrice: {
    alignSelf: 'flex-end',
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f8,
    marginTop: Styles.Spacing.m2,
    fontWeight: 'bold',
    fontFamily: getMenuListCompnentsFontFamily(),
    paddingRight: Styles.Spacing.m0,
  },
  itemRight: {
    marginRight: Styles.Spacing.m2,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  itemCenter: {
    flex: 1,
    flexDirection: 'column',
  },
  modifierContainer: {
    flex: 1,
    paddingHorizontal: Styles.Spacing.m2,
    flexDirection: 'column',
  },
  modifierText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f8,
    fontFamily: getMenuListCompnentsFontFamily(),
  },
  modifierPrice: {
    color: '#138665',
    fontSize: Styles.Fonts.f8,
    fontFamily: getMenuListCompnentsFontFamily(),
    marginRight: 36,
  },
  unavailable: {
    color: Styles.dangerColor,
    fontSize: Styles.Fonts.f1,
    fontFamily: getMenuListCompnentsFontFamily(),
    fontWeight: '700',
  },
  unavailableModifier: {
    textDecorationStyle: 'solid',
    textDecorationColor: Styles.dangerColor,
    textDecorationLine: 'line-through',
  },
});
