import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Dimensions,
  Image,
} from 'react-native';
import Styles from '../../Styles';
import AVText from 'src/components/elements/AVText';
import {CampusLocationType} from 'src/components/elements/home/CampusLocationView';
import ChevronRight from 'src/components/img/svg/ChevronRight';
import Localized from 'src/constants/AppStrings';
import {MachineType} from 'src/types/MachineType';

type VendingLocationViewProps = {
  location: CampusLocationType;
  onLocationClick: (machine, autoAdvanced?: boolean) => Promise<void>;
  onPlanogramClick?: (machine: MachineType) => void;
};

const width = Dimensions.get('window').width;

const VendingLocationView = (props: VendingLocationViewProps) => {
  return (
    <TouchableOpacity
      accessible={true}
      onPress={() => props.onLocationClick(props.location)}
      accessibilityRole="button"
      accessibilityLabel={`Location${
        props.location.displayName ?? props.location.name
      }`}
      aria-label={`Location${
        props.location.displayName ?? props.location.name
      }`}
      accessibilityHint={`Double tap to select ${
        props.location.displayName ?? props.location.name
      }`}
      style={styles.container}
    >
      <View style={styles.iconView}>
        <Image
          source={require('src/components/img/VendingDefaultIcon.png')}
          resizeMode="contain"
        />
      </View>
      <View style={styles.textView}>
        <AVText
          accessibilityLabel={`LocationName${
            props.location.displayName ?? props.location.name
          }`}
          accessibilityRole="text"
          aria-label={`${
            props.location.displayName ?? props.location.name
          }, text`}
          numberOfLines={1}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={styles.nameText}
        >
          {props.location.displayName ?? props.location.name}
        </AVText>
        <TouchableOpacity
          onPress={() => props.onPlanogramClick(props.location)}
          style={styles.nutritionalInfoButton}
          accessibilityLabel="View Nutritional Info"
          accessibilityRole="button"
        >
          <AVText style={styles.buttonText}>
            {Localized.Buttons.view_nutritional_info}
          </AVText>
        </TouchableOpacity>
      </View>
      <View style={styles.arrowView}>
        <ChevronRight color={Styles.darkColor} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: width - Styles.Spacing.m3 * 3,
    marginLeft: Styles.Spacing.m3,
    marginVertical: Styles.Spacing.m3,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: Styles.Spacing.m2,
    ...Styles.Style.shadow,
  },
  iconView: {
    borderTopLeftRadius: Styles.Spacing.m2,
    borderBottomLeftRadius: Styles.Spacing.m2,
    overflow: 'hidden',
  },
  textView: {
    flex: 1,
    justifyContent: 'center',
    paddingVertical: Styles.Spacing.m1,
    marginLeft: Styles.Spacing.m2 + Styles.Spacing.m1,
  },

  nameText: {
    width: '100%',
    color: Styles.darkColor,
    fontWeight: '700',
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  nutritionalInfoButton: {
    marginTop: Styles.Spacing.m2,
    paddingVertical: Styles.Spacing.m1,
    backgroundColor: 'transparent',
    borderRadius: 16,
    borderColor: Styles.Colors.PayNew.primary01,
    alignItems: 'center',
    borderWidth: 2,
    maxWidth: 175,
  },
  buttonText: {
    color: Styles.primaryColor,
    backgroundColor: 'transparent',
    fontSize: Styles.Fonts.f1,
    fontWeight: '600',
    fontStyle: 'normal',
  },
  arrowView: {
    right: Styles.Spacing.m3,
    position: 'absolute',
  },
});

export default VendingLocationView;
