import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import StackTransitionPreset from '../StackTransitionPreset';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import DealDetailScreenNew from 'src/components/screens/inbox/DealDetailScreen';
import SendSnackScreenNew from 'src/components/screens/sendSnack/SendSnackScreen';
import ChooseContactScreenNew from 'src/components/screens/sendSnack/ChooseContactScreen';
import HomeScreenNew from 'src/components/screens/home/HomeScreen';
import WebContentScreenNew from 'src/components/screens/WebContentScreen';
import ResetPinScreenNew from 'src/components/screens/account/ResetPinScreen';
import ChangePasswordScreenNew from 'src/components/screens/account/ChangePasswordScreen';
import ManageAccountScreenNew from 'src/components/screens/account/ManageAccountScreen';
import ChangeEmailScreenNew from 'src/components/screens/account/ChangeEmailScreen';
import CampusLocationsScreenNew from 'src/components/screens/home/CampusLocationsScreen';
import LocationCodeScreenNew from 'src/components/screens/LocationCodeScreen';
import AccountScreen from 'src/components/screens/account/newui/AccountScreenNewUI';
import RecentOrdersScreenNew from 'src/components/screens/orderAhead/RecentOrdersScreen';
import InboxNavigator from 'src/navigators/tabs/InboxTab';
import NewPickupLocationScreenNew from 'src/components/screens/orderAhead/NewPickupLocationScreen';
import DisclaimerScreenNew from 'src/components/screens/orderAhead/DisclaimerScreen';
import ScanAndPayScreenNewUI from 'src/components/screens/scanAndPay/ScanScreenNewUI';
import SearchProductsScreen from 'src/components/screens/scanAndPay/SearchProductsScreen';
import CoffeeMachineScreenNew from 'src/components/screens/cart/coffee/CoffeeMachineScreen';
import BunnDispenseScreenNew from 'src/components/screens/cart/coffee/BunnDispenseScreen';
import InsufficientCreditScreenNew from 'src/components/screens/funding/InsufficientCreditScreen';
import ProductCategorySearchScreenNew from 'src/components/screens/cart/ProductCategorySearchScreen';
import Vending from 'src/components/screens/vending/VendingScreen';
import ProductUpsellScreenNew from 'src/components/screens/orderAhead/ProductUpsellScreen';
import ShareReceiptScreenNew from 'src/components/screens/cart/ShareReceiptScreen';
import OrderNoteScreenNew from 'src/components/screens/orderAhead/OrderNoteScreen';
import NewPickupTimeScreenNew from 'src/components/screens/orderAhead/NewPickupTimeScreen';
import SnackBlockedUsersScreenNew from 'src/components/screens/sendSnack/SnackBlockedUsersScreen';
import BalancesScreenNew from 'src/components/screens/account/BalancesScreen';
import DeleteAccountScreenNew from 'src/components/screens/account/DeleteAccountScreen';
import VendorsExchangeVendingScreenNew from 'src/components/screens/vending/VendorsExchangeVendingScreen';
import ReceiptScreenNew from 'src/components/screens/cart/ReceiptScreen';
import ContactUsScreenNew from 'src/components/screens/vending/ContactUsScreen';
import MainProductScreenNew from 'src/components/screens/planogram/MainProductScreen';
import ProductNutritionScreenNew from 'src/components/screens/planogram/ProductNutritionScreen';
import CreditCardScreenNew from 'src/components/screens/funding/CreditCardScreen';
import Menu from 'src/components/screens/orderAhead/MenuScreen';
import ProductDetailScreenNew from 'src/components/screens/orderAhead/ProductDetailScreen';
import ViewNutritionScreenNew from 'src/components/screens/orderAhead/ViewNutritionScreen';
import ScanDiscountCodeScreenNew from 'src/components/screens/cart/ScanDiscountCodeScreen';
import HelpScreenNewUI from 'src/components/screens/account/HelpScreen';
import EditMarketCardScreenNew from 'src/components/screens/account/EditMarketCardScreen';
import PurchaseHistoryScreen from 'src/components/screens/account/history/PurchaseHistoryScreen';
import CardsScreen from 'src/components/screens/funding/CreditCardsScreen';
import EnterDiscountCodeScreen from 'src/components/screens/cart/EnterDiscountCodeScreen';
import SnackDetailScreen from 'src/components/screens/sendSnack/SnackDetailScreen';
import ChooseLocationScreen from 'src/components/screens/scanAndPay/ChooseLocationScreen';
import ReferralScreen from 'src/components/screens/ReferralScreen';
import PurchaseReceiptScreen from '../../components/screens/account/history/PurchaseHistoryDetailScreen';
import PayrollDeductScreen from 'src/components/screens/funding/PayrollDeductScreen';
import Settings from 'src/Settings';
import ScanScreen from 'src/components/screens/cart/ScanScreen';
import ScanPay365ReceiptScreen from '../../components/screens/account/history/ScanPay365ReceiptScreen';
import {RewardsTabScreenWithHOC} from 'src/components/screens/RewardsHOCScreen';
const HomeStack = createStackNavigator();

const components: {
  name: keyof typeof AppRoutes;
  component: React.ComponentType<object>;
  initialParams?: object;
}[] = [
  {
    name: AppRoutes.Inbox,
    component: InboxNavigator,
    initialParams: {selectedTab: 'Messages'},
  },
  {
    component: ChangePasswordScreenNew,
    name: AppRoutes.ChangePassword,
  },
  {component: ReceiptScreenNew, name: AppRoutes.Receipt},
  {component: BalancesScreenNew, name: AppRoutes.Balances},
  {
    component: ProductUpsellScreenNew,
    name: AppRoutes.ProductUpsell,
  },
  {
    component: RecentOrdersScreenNew,
    name: AppRoutes.RecentOrders,
  },
  {
    component: ManageAccountScreenNew,
    name: AppRoutes.ManageAccount,
  },
  {name: AppRoutes.OrderNote, component: OrderNoteScreenNew},
  {
    component: ShareReceiptScreenNew,
    name: AppRoutes.ShareReceipt,
  },
  {
    name: AppRoutes.ScanAndPayLocations,
    component: ChooseLocationScreen,
  },
  {
    component: SnackBlockedUsersScreenNew,
    name: AppRoutes.SnackBlockedUsers,
  },
  {
    component: WebContentScreenNew,
    name: AppRoutes.WebContent,
  },
  {name: AppRoutes.NewHome, component: HomeScreenNew},
  {
    component: ScanDiscountCodeScreenNew,
    name: AppRoutes.ScanDiscountCode,
  },
  {
    component: ProductNutritionScreenNew,
    name: AppRoutes.ProductNutrition,
  },
  {name: AppRoutes.ResetPin, component: ResetPinScreenNew},
  {
    component: DeleteAccountScreenNew,
    name: AppRoutes.DeleteAccount,
  },
  {
    component: MainProductScreenNew,
    name: AppRoutes.MainProduct,
  },
  {
    component: ViewNutritionScreenNew,
    name: AppRoutes.ViewNutrition,
  },
  {
    component: ProductDetailScreenNew,
    name: AppRoutes.ProductDetail,
  },
  {name: AppRoutes.Menu, component: Menu},
  {
    component: DealDetailScreenNew,
    name: AppRoutes.DealDetail,
  },
  {
    component: ChooseContactScreenNew,
    name: AppRoutes.ChooseContact,
  },
  {
    component: CampusLocationsScreenNew,
    name: AppRoutes.CampusLocations,
  },
  {component: AccountScreen, name: AppRoutes.Settings},
  {component: CardsScreen, name: AppRoutes.Cards},
  {
    component: LocationCodeScreenNew,
    name: AppRoutes.LocationCode,
  },
  {
    name: AppRoutes.Scan,
    component: Settings.isRefiveAnd365Pay()
      ? ScanAndPayScreenNewUI
      : ScanScreen,
  },
  {
    name: AppRoutes.ScanAndPay,
    component: Settings.isRefiveAnd365Pay()
      ? ScanAndPayScreenNewUI
      : ScanScreen,
  },
  {name: AppRoutes.SearchProducts, component: SearchProductsScreen},
  {name: AppRoutes.Vending, component: Vending},
  {
    component: NewPickupLocationScreenNew,
    name: AppRoutes.NewPickupLocation,
  },
  {
    component: CreditCardScreenNew,
    name: AppRoutes.CreditCard,
  },
  {name: AppRoutes.SendSnack, component: SendSnackScreenNew},
  {
    component: DisclaimerScreenNew,
    name: AppRoutes.Disclaimer,
  },
  {
    component: CoffeeMachineScreenNew,
    name: AppRoutes.CoffeeMachine,
  },
  {
    component: ReferralScreen,
    name: AppRoutes.Referral,
  },
  {
    component: RewardsTabScreenWithHOC,
    name: AppRoutes.RewardsScreen,
  },
  {
    component: NewPickupTimeScreenNew,
    name: AppRoutes.NewPickupTime,
  },
  {
    component: InsufficientCreditScreenNew,
    name: AppRoutes.InsufficientCredit,
  },
  {
    component: BunnDispenseScreenNew,
    name: AppRoutes.BunnDispense,
  },
  {component: ContactUsScreenNew, name: AppRoutes.ContactUs},
  {
    component: PurchaseReceiptScreen,
    name: AppRoutes.PurchaseHistoryDetail,
  },
  {
    component: PurchaseHistoryScreen,
    name: AppRoutes.PurchaseHistory,
  },
  {
    component: ChangeEmailScreenNew,
    name: AppRoutes.ChangeEmail,
  },
  {name: AppRoutes.Help, component: HelpScreenNewUI},
  {
    component: EditMarketCardScreenNew,
    name: AppRoutes.EditMarketCard,
  },
  {
    component: VendorsExchangeVendingScreenNew,
    name: AppRoutes.VendorsExchangeVending,
  },
  {
    component: ProductCategorySearchScreenNew,
    name: AppRoutes.ProductCategorySearch,
  },
  {component: CardsScreen, name: AppRoutes.PDCards},
  {
    component: EnterDiscountCodeScreen,
    name: AppRoutes.EnterDiscountCode,
  },
  {
    component: SnackDetailScreen,
    name: AppRoutes.SnackDetail,
  },
  {
    component: PayrollDeductScreen,
    name: AppRoutes.PayrollDeduct,
  },
];

if (Settings.isCanteen()) {
  components.push({
    name: AppRoutes.Wallet,
    component: CardsScreen,
  });
}

if (Settings.isRefiveAnd365Pay()) {
  components.push({
    component: ScanPay365ReceiptScreen,
    name: AppRoutes.ScanPay365ReceiptScreen,
  });
}

const HomeNavigator = () => {
  return (
    <HomeStack.Navigator
      initialRouteName={AppRoutes.NewHome}
      screenOptions={{
        ...StackTransitionPreset,
        title: DeviceInfo.getApplicationName(),
        headerShown: false,
      }}
    >
      {components.map((screen, index) => (
        <HomeStack.Screen
          key={index}
          name={screen.name}
          component={screen.component}
        />
      ))}
    </HomeStack.Navigator>
  );
};

export default HomeNavigator;
