import * as React from 'react';
import Svg, {Path, Circle} from 'react-native-svg';

type DealUnreadIconProps = {
  width: number;
  height: number;
  bgColor?: string;
};

export default class DealUnreadIcon extends React.Component<DealUnreadIconProps> {
  render() {
    return (
      <>
        {
          <Svg width="54" height="55" viewBox="0 0 54 55" fill="none">
            <Path
              d="M0 29.5C0 15.6929 11.1929 4.5 25 4.5C38.8071 4.5 50 15.6929 50 29.5C50 43.3071 38.8071 54.5 25 54.5C11.1929 54.5 0 43.3071 0 29.5Z"
              fill="#066DFF"
            />
            <Path
              d="M13 19.8754V28.0512C13 28.9809 13.368 29.8723 14.0271 30.5285L23.6938 40.1535C25.0669 41.5207 27.2913 41.5207 28.6644 40.1535L35.9968 32.8528C37.3699 31.4856 37.3699 29.2707 35.9968 27.9035L26.3301 18.2785C25.671 17.6223 24.7758 17.2559 23.842 17.2559H15.6364C14.1809 17.2504 13 18.4262 13 19.8754ZM19.1515 21.6254C19.6177 21.6254 20.0647 21.8098 20.3943 22.138C20.7239 22.4662 20.9091 22.9113 20.9091 23.3754C20.9091 23.8395 20.7239 24.2847 20.3943 24.6128C20.0647 24.941 19.6177 25.1254 19.1515 25.1254C18.6854 25.1254 18.2383 24.941 17.9087 24.6128C17.5791 24.2847 17.3939 23.8395 17.3939 23.3754C17.3939 22.9113 17.5791 22.4662 17.9087 22.138C18.2383 21.8098 18.6854 21.6254 19.1515 21.6254Z"
              fill="#FEE0E6"
            />
            <Circle cx="44" cy="10.5" r="10" fill="#E2607F" />
          </Svg>
        }
      </>
    );
  }
}
