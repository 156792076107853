import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  color?: string;
  width?: number;
  height?: number;
}
const SnackGiftIcon = ({
  color = '#025D33',
  height = 32,
  width = 34,
}: IProps) => (
  <Svg width={width} height={height} fill="none">
    <Path
      fill={color}
      fillRule="evenodd"
      d="M32.152 16V28.12a3.03 3.03 0 0 1-3.03 3.03H4.878a3.03 3.03 0 0 1-3.03-3.03V16a1.515 1.515 0 0 1-1.516-1.515V9.939a3.03 3.03 0 0 1 3.03-3.03h4.804a4.545 4.545 0 0 1 4.288-6.06c1.515 0 2.848.757 3.681 1.878v-.015L17 3.879l.864-1.167v.015C18.697 1.606 20.03.848 21.545.848a4.545 4.545 0 0 1 4.288 6.061h4.803a3.03 3.03 0 0 1 3.03 3.03v4.546c0 .837-.678 1.515-1.514 1.515ZM4.879 28.12h10.606V16H4.879V28.12ZM29.12 16V28.12H18.515V16h10.606ZM12.455 3.879a1.515 1.515 0 1 0 0 3.03 1.515 1.515 0 0 0 0-3.03Zm7.575 1.515a1.515 1.515 0 1 1 3.03 0 1.515 1.515 0 0 1-3.03 0ZM3.364 9.939v3.03h12.12V9.94H3.365Zm15.151 3.03V9.94h12.121v3.03h-12.12Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default SnackGiftIcon;
