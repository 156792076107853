import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const DiscountTag = (props) => (
  <Svg width={18} height={20} viewBox="0 0 18 20" fill="none" {...props}>
    <Path
      d="M1.30952 8.86095V2.43712C1.30952 2.059 1.60417 1.74962 1.96429 1.74962H8.08222C8.43006 1.74962 8.76153 1.89572 9.00707 2.15353L16.2094 9.71603C16.721 10.2531 16.721 11.1254 16.2094 11.6625L10.7463 17.3945C10.2347 17.9317 9.40402 17.9317 8.89249 17.3945L1.6901 9.83205C1.44457 9.57423 1.30543 9.22619 1.30543 8.86095H1.30952ZM0 8.86095C0 9.59142 0.274182 10.2918 0.765253 10.8074L7.96763 18.3699C8.9907 19.4442 10.6481 19.4442 11.6711 18.3699L17.1343 12.6336C18.1574 11.5594 18.1574 9.81915 17.1343 8.74494L9.93192 1.18244C9.44085 0.666812 8.77381 0.378921 8.07812 0.378921H1.96429C0.879836 0.374624 0 1.29845 0 2.43712V8.86095ZM4.58333 6.21837C4.84381 6.21837 5.09363 6.10972 5.27781 5.91633C5.462 5.72293 5.56548 5.46063 5.56548 5.18712C5.56548 4.91362 5.462 4.65132 5.27781 4.45792C5.09363 4.26452 4.84381 4.15587 4.58333 4.15587C4.32285 4.15587 4.07304 4.26452 3.88885 4.45792C3.70467 4.65132 3.60119 4.91362 3.60119 5.18712C3.60119 5.46063 3.70467 5.72293 3.88885 5.91633C4.07304 6.10972 4.32285 6.21837 4.58333 6.21837Z"
      fill="#066DFF"
    />
  </Svg>
);
export default DiscountTag;
