import {TextStyle, ViewStyle} from 'react-native';
import Styles from 'src/components/Styles';

export function getRightReadMarkIconColor(): string {
  return '#066DFF';
}

export function getUnreadMessageIconColor(): string {
  return '#7FBC03';
}

export function tabBarRoundedBtnColor(): string {
  return Styles.Colors.Refive.activeFooterBlue;
}

export function tabBarTextColorColor(isFocused: boolean): TextStyle {
  return isFocused ? {color: Styles.white} : {color: Styles.tabBarTextColor};
}

export function getDealClaimButtonViewStyle(): ViewStyle {
  return {
    backgroundColor: '#003349',
    borderColor: Styles.darkGreenRevolve,
  };
}

export function getDealClaimButtonTextStyle(): TextStyle {
  return {
    color: Styles.white,
  };
}

export default {
  getRightReadMarkIconColor,
  getUnreadMessageIconColor,
  tabBarRoundedBtnColor,
  tabBarTextColorColor,
  getDealClaimButtonViewStyle,
  getDealClaimButtonTextStyle,
};
