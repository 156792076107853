import * as React from 'react';
import Svg, {Path, Mask, G} from 'react-native-svg';
interface IQuickApplyIconProps {
  color?: string;
}
const QuickApplyIcon = ({color = '#066DFF'}: IQuickApplyIconProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none">
    <Mask id="a" maskUnits="userSpaceOnUse" x={1} y={1} width={14} height={14}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.333 1.334h13.334v13.333H1.334V1.334z"
        fill="#fff"
      />
    </Mask>
    <G mask="url(#a)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.643 6.977H9.024V2.358a1.024 1.024 0 10-2.048 0v4.619H2.358a1.024 1.024 0 000 2.048h4.618v4.618a1.024 1.024 0 002.048 0V9.025h4.619a1.024 1.024 0 000-2.048z"
        fill={color}
      />
    </G>
  </Svg>
);
export default QuickApplyIcon;
