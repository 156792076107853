import * as React from 'react';
import TextInput from '../AVTextInput';
import Localized from 'src/constants/AppStrings';
import AccountStore from 'src/stores/AccountStore';
import NBDropdown from '../NBDropdown';
import Styles from 'src/components/Styles';
import Settings from 'src/Settings';
import {Dimensions, Platform} from 'react-native';
type AmountDropdownProps = JSX.LibraryManagedAttributes<
  typeof TextInput,
  React.ComponentProps<typeof TextInput>
> & {
  formatNumber: (value: number) => string;
  remainingDue?: number;
  selectedValue: number;
  ascendingOrder: boolean;
  anchorPosition: string;
  onSelect: (value: string) => void;
  onVisibilityChange?: (visible: boolean) => void;
};

const AmountDropdown = (props: AmountDropdownProps) => {
  let amounts = AccountStore.getFundingOptions();

  if (props.remainingDue) {
    amounts = amounts.filter((amount) => amount > (props.remainingDue || 0));
  }
  props.ascendingOrder && amounts.sort((a, b) => a - b);

  const amountOptions = amounts.map((option) => ({
    value: option,
    text: props.formatNumber(option),
    id: option,
  }));

  if (props.remainingDue && props.remainingDue > 1) {
    amountOptions.unshift({
      value: props.remainingDue,
      text: `${props.formatNumber(props.remainingDue)} (${
        Localized.Labels.due_amount
      })`,
      id: props.remainingDue,
    });
  }

  return (
    <NBDropdown
      dropDownHeader={
        Settings.isNewUI() ? Localized.Labels.add_amount : undefined
      }
      menuContainerStyle={
        Platform.OS !== 'web' && {
          width: Dimensions.get('screen').width - 105,
        }
      }
      anchorPosition={props.anchorPosition}
      onVisibilityChange={props.onVisibilityChange}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
      label={Localized.Labels.add_amount}
      options={amountOptions}
      onSelect={props.onSelect}
      selectedValue={props.selectedValue}
    />
  );
};

export default AmountDropdown;
