import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, Rect} from 'react-native-svg';

interface IProps {
  width?: number;
  height?: number;
  color?: string;
}

function AccountRedesignLightIcon({width = 35, height = 34}: IProps) {
  return (
    <Svg width={width} height={height} viewBox="0 0 35 34" fill="none">
      <G clip-path="url(#clip0_718_783)">
        <Path
          d="M22.75 10.5C22.75 9.85981 22.6239 9.22588 22.3789 8.63442C22.1339 8.04296 21.7748 7.50554 21.3221 7.05285C20.8695 6.60017 20.332 6.24108 19.7406 5.99609C19.1491 5.7511 18.5152 5.625 17.875 5.625C17.2348 5.625 16.6009 5.7511 16.0094 5.99609C15.418 6.24108 14.8805 6.60017 14.4279 7.05285C13.9752 7.50554 13.6161 8.04296 13.3711 8.63442C13.1261 9.22588 13 9.85981 13 10.5C13 11.1402 13.1261 11.7741 13.3711 12.3656C13.6161 12.957 13.9752 13.4945 14.4279 13.9471C14.8805 14.3998 15.418 14.7589 16.0094 15.0039C16.6009 15.2489 17.2348 15.375 17.875 15.375C18.5152 15.375 19.1491 15.2489 19.7406 15.0039C20.332 14.7589 20.8695 14.3998 21.3221 13.9471C21.7748 13.4945 22.1339 12.957 22.3789 12.3656C22.6239 11.7741 22.75 11.1402 22.75 10.5ZM11.375 10.5C11.375 8.77609 12.0598 7.12279 13.2788 5.90381C14.4978 4.68482 16.1511 4 17.875 4C19.5989 4 21.2522 4.68482 22.4712 5.90381C23.6902 7.12279 24.375 8.77609 24.375 10.5C24.375 12.2239 23.6902 13.8772 22.4712 15.0962C21.2522 16.3152 19.5989 17 17.875 17C16.1511 17 14.4978 16.3152 13.2788 15.0962C12.0598 13.8772 11.375 12.2239 11.375 10.5ZM8.125 28.375H27.625C27.5641 24.3277 24.2633 21.0625 20.1957 21.0625H15.5543C11.4918 21.0625 8.19102 24.3277 8.125 28.375ZM6.5 28.4918C6.5 23.4898 10.5523 19.4375 15.5543 19.4375H20.1957C25.1977 19.4375 29.25 23.4898 29.25 28.4918C29.25 29.3246 28.5746 30 27.7418 30H8.0082C7.17539 30 6.5 29.3246 6.5 28.4918Z"
          fill="#707070"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_718_783">
          <Rect
            width="22.75"
            height="26"
            fill="white"
            transform="translate(6.5 4)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default AccountRedesignLightIcon;
