// @ts-nocheck AnimatedQRCode
import React, {useState} from 'react';
import {
  Animated,
  Dimensions,
  PixelRatio,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import CanteenPromotionHeaderBackground from '../../img/svg/CanteenPromotionHeaderBackground';
import Styles from '../../Styles';
import BalanceContainer from '../BalanceContainer';
import AnimatedQRCode from './AnimatedQRCode';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import {PromotionsHeaderProps} from './PromotionsHeader';

const CONTAINER_HEIGHT = 210;
const BALANCE_CONTAINER_HEIGHT = 70;
const Background = Animated.createAnimatedComponent(
  CanteenPromotionHeaderBackground,
);
const {width: deviceWidth} = Dimensions.get('window');

const HeaderBackground = ({animationRange}) => {
  const animateHeader = {
    transform: [
      {
        translateY: animationRange.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 0],
          extrapolate: 'clamp',
        }),
      },
    ],
  };
  const animateOpacity = {
    opacity: animationRange.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
    }),
  };
  return (
    <Animated.View
      style={[styles.headerBackground, animateHeader, animateOpacity]}
    />
  );
};

const PromotionsHeader = ({
  animationScrollY,
  qrCode,
  strings,
  scrollRangeForAnimation,
  hideBalance,
}: PromotionsHeaderProps) => {
  const [{y: topBuffer}, setLayout] = useState({
    y: 100,
  });
  const animationRange = animationScrollY.interpolate({
    inputRange: [0, topBuffer, scrollRangeForAnimation + topBuffer],
    outputRange: [0, 0, 1],
    extrapolate: 'clamp',
  });
  const animateHeader = {
    transform: [
      {
        translateY: animationScrollY.interpolate({
          inputRange: [
            0,
            topBuffer,
            scrollRangeForAnimation + topBuffer,
            Number.MAX_SAFE_INTEGER,
          ],
          outputRange: [
            0,
            0,
            0,
            Number.MAX_SAFE_INTEGER - scrollRangeForAnimation - topBuffer,
          ],
          extrapolate: 'clamp',
        }),
      },
    ],
  };
  const animateOpacity = {
    opacity: animationRange.interpolate({
      inputRange: [0, 1],
      outputRange: [1, 0],
      extrapolate: 'clamp',
    }),
  };
  const animateShadowOpacity = {
    shadowOpacity: animationRange.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 0.3],
      extrapolate: 'clamp',
    }),
  };
  const controlWidth = deviceWidth - 2 * Styles.Spacing.m3;
  let height = CONTAINER_HEIGHT;

  if (!hideBalance) {
    height += BALANCE_CONTAINER_HEIGHT;
  }

  return (
    <Animated.View
      style={[styles.container, animateHeader]}
      onLayout={({nativeEvent: {layout}}) => setLayout(layout)}
    >
      <Animated.View
        style={[
          styles.innerContainer,
          animateShadowOpacity,
          {
            height: height,
          },
        ]}
      >
        <HeaderBackground animationRange={animationRange} />

        <AnimatedQRCode
          qrCode={qrCode}
          animationRange={animationRange}
          scrollRangeForAnimation={scrollRangeForAnimation}
          qrCodeSize={105}
          bottomMargin={0}
          scale={0.425}
        />

        <Background
          style={[
            styles.backgroundImage,
            hideBalance && styles.backgroundImageHideBalance,
            animateOpacity,
          ]}
          aspectRatio={controlWidth / CONTAINER_HEIGHT}
        />

        {!hideBalance && (
          <View style={styles.consumerRow}>
            <TouchableOpacity
              onPress={() => {
                NavActions.push(AppRoutes.Balances);
              }}
            >
              <BalanceContainer isCentered primaryColor strings={strings} />
            </TouchableOpacity>
          </View>
        )}
      </Animated.View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    flex: 1,
    height: '75%',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 50,
    shadowColor: Styles.darkColor,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: Styles.Spacing.m2,
    elevation: 8,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  backgroundImageHideBalance: {
    height: '100%',
  },
  consumerRow: {
    alignItems: 'center',
    alignSelf: 'center',
    bottom: 0,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    left: 0,
    position: 'absolute',
    right: 0,
    width: '100%',
    zIndex: 102,
  },
  container: {
    zIndex: 1,
  },
  headerBackground: {
    backgroundColor: Styles.white,
    bottom: 0,
    left: 0,
    marginHorizontal: -Styles.Spacing.m2,
    opacity: 1,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  innerContainer: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: Styles.white,
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m2,
  },
});
export default PromotionsHeader;
