import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function ContactListErrorLogo(props) {
  return (
    <Svg width={79} height={78} viewBox="0 0 79 78" fill="none" {...props}>
      <Path
        d="M39.5 71.5a32.5 32.5 0 100-65 32.5 32.5 0 000 65zm0-48.75a3.04 3.04 0 013.047 3.047v14.219a3.04 3.04 0 01-3.047 3.047 3.04 3.04 0 01-3.047-3.047v-14.22A3.04 3.04 0 0139.5 22.75zm-4.063 28.438a4.062 4.062 0 118.125 0 4.062 4.062 0 01-8.124 0z"
        fill="#D7263D"
      />
    </Svg>
  );
}

export default ContactListErrorLogo;
