import {ImageBackground, Platform} from 'react-native';
import Styles from 'src/components/Styles';

export function getHeaderBackground(
  content: JSX.Element,
  _onboardingHeader?: boolean,
): JSX.Element {
  return _onboardingHeader ? (
    content
  ) : (
    <ImageBackground
      resizeMode="stretch"
      source={require('src/components/img/365GradientHeader.png')}
    >
      {content}
    </ImageBackground>
  );
}

export function headerContainerStyleDescriptor(): {
  [key: string]: {[index: string]: number | string};
} {
  return {
    container: {
      backgroundColor: Styles.tabBarBackgroundColor,
      flexDirection: 'column',
      flex: 1,
    },
    safeArea: {
      backgroundColor: Styles.transparent,
      zIndex: 100,
    },
    headerTextStyle: {
      textAlign: 'left',
      fontSize: Styles.Fonts.headerFont,
      color: Styles.Colors.PayNew.white01,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
      height: Styles.Heights.touchTargetHeight1,
      marginTop: Platform.OS === 'ios' ? Styles.Spacing.m1 : Styles.Spacing.m0,
    },
  };
}

export function getBackIconColor(transparent: boolean): string {
  return transparent ? Styles.Colors.PayNew.black01 : Styles.lightGray;
}

export default {
  getHeaderBackground,
  headerContainerStyleDescriptor,
  getBackIconColor,
};
