import {createAsyncThunk} from '@reduxjs/toolkit';
import {AppDispatch, RootState} from '../../store';
import Logger from 'src/logging/Logger';
import AppApi from 'src/api/AppApi';

export type NotificationMessageParams = {
  locationId: string;
  externalServiceId: string;
  messageType: string;
  messageAction?: string;
};
export const markSingleMessageAsRead = createAsyncThunk<
  boolean | undefined,
  NotificationMessageParams,
  {dispatch: AppDispatch; state: RootState}
>(
  'message/markMessageAsRead',
  async (
    {locationId, externalServiceId, messageType},
    {dispatch, getState},
  ) => {
    const accountId = getState().account.account.id;
    try {
      const messageReadRes = await AppApi.markASingleMessageAsRead(
        accountId,
        locationId,
        externalServiceId,
        messageType,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'NotificationMessageParams',
        JSON.stringify({
          locationId: locationId,
          extServiceId: externalServiceId,
          accountId,
        }),
        JSON.stringify(messageReadRes),
      );
      dispatch(true);
      return true;
    } catch (err) {
      dispatch(false);
    }
  },
);
