import RedesignDescriptor from './common-components-redesign';
import RefiveDescriptor from './common-components-refive';

import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';

const typeDescriber = {
  [BuildTypes.canteen]: RedesignDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.companykitchen]: RedesignDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
