import Settings from 'src/Settings';
import RedesignDescriptor from './common-module-redesign';
import CanteenDescriptor from './common-module-canteen';
import RefiveDescriptor from './common-module-refive';
import CompanyKitchenDescriptor from './common-module-companykitchen';
import BuildTypes from 'src/constants/BuildTypeConstants';

const typeDescriber = {
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
