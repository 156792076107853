import React from 'react';
import {
  Animated,
  LayoutChangeEvent,
  PixelRatio,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import {compose} from 'recompose';
import Styles from '../../Styles';
import buildTransform from '../../utils/BuildTransform';
import {withSelfMeasure} from '../../utils/SelfMeasureBehavior';
import BrightnessTouchable from '../BrightnessTouchable';

const AnimatedQRCode = ({
  animationRange,
  onLayoutSetMeasurements,
  elementX,
  elementY,
  elementHeight,
  elementWidth,
  qrCode,
  scrollRangeForAnimation,
  qrCodeSize,
  bottomMargin,
  scale,
  offset = 0,
}: {
  animationRange: Animated.Value;
  onLayoutSetMeasurements: (event: LayoutChangeEvent) => void;
  elementX: number;
  elementY: number;
  elementHeight: number;
  elementWidth: number;
  qrCode: any;
  scrollRangeForAnimation: number;
  qrCodeSize: number;
  bottomMargin: number;
  scale: number;
  offset: number;
}) => {
  const animateQrCode = buildTransform(
    animationRange,
    elementX,
    elementY,
    elementHeight,
    elementWidth,
    elementX + elementWidth / 4,
    elementY +
      scrollRangeForAnimation -
      qrCodeSize * 0.4 -
      bottomMargin -
      offset,
    scale,
    true,
  ) as unknown as StyleProp<ViewStyle>;
  return (
    <BrightnessTouchable
      accessible={true}
      accessibilityLabel="Q R Code"
      accessibilityHint="Scan the code at a kiosk to checkout with your account"
      onLayout={onLayoutSetMeasurements}
      style={[styles.qrCode, animateQrCode]}
      timeout={10000}
    >
      <QRCode value={qrCode} size={qrCodeSize} />
    </BrightnessTouchable>
  );
};

const borderColor = Styles.white;
const styles = StyleSheet.create({
  qrCode: {
    borderColor: borderColor,
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    borderWidth: PixelRatio.roundToNearestPixel(10),
    marginTop: 46,
    zIndex: 200,
    elevation: 9,
  },
});
const enhance = compose(withSelfMeasure);
// @ts-expect-error is not assignable to parameter of type ComponentType<unknown>
export default enhance(AnimatedQRCode);
