import {
  Animated,
  Platform,
  StyleSheet,
  Text,
  View,
  PixelRatio,
  TouchableWithoutFeedback,
  TextStyle,
} from 'react-native';

import Referral from 'src/components/img/svg/inbox/referral-reward';
import SignUp from 'src/components/img/svg/inbox/signup-reward';
import AccountStore from 'src/stores/AccountStore';
import Icon from 'react-native-vector-icons/FontAwesome';
import moment from 'moment';

import NotifyDealIcon from 'src/components/img/svg/NotifyDealIcon';
import {
  SnackItemDescriberProps,
  CommonUtilsSwitchProps,
  DealProps,
  StylesDescriptor,
  CollapsibleComponentProps,
  NumberSpinnerStylesDescriptor,
  ReferralItemDescriberProps,
  RewardsSection,
} from 'src/components/elements/descriptor/DescriptorType';

import TimeUtils from 'src/services/TimeUtils';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Settings from 'src/Settings';
import React from 'react';

import Localized from 'src/constants/AppStrings';
import Util from 'src/Util';
import Styles from 'src/components/Styles';
import CloseIcon from 'src/components/img/svg/CloseIcon';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import GiftIconCP from 'src/components/img/svg/GiftIconCP';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import CustomToggleSwitch from 'src/components/elements/CustomToggleSwitch';
import {WithGlobalizeProps} from 'react-native-globalize';
type Formatted = number | string | JSX.Element;

export function snackItemDateDescriptor(
  props?: SnackItemDescriberProps,
): string {
  return TimeUtils.formatInboxDate(props.snack.snackSent);
}

export function referralItemDateDescriptor(
  props?: ReferralItemDescriberProps,
): string {
  return TimeUtils.formatInboxDate(props.referral.dateCreated);
}

export function getInboxTabStyles(): {
  [index: string]: number | string;
} {
  return {
    marginTop: null,
  };
}

export function historyHeaderName(): string {
  return Localized.Labels.history;
}

export function onboardingLocaionBorderColor() {
  return '#003349';
}
export function onboardingLocaionBgColor() {
  return 'rgba(64, 159, 104, 0.15)';
}
export function onboardingFlowStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    instructions: {
      color: Styles.Colors.PayNew.black01,
      fontSize: Styles.Fonts.f7,
      fontWeight: '400',
      paddingStart: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontStyle: 'normal',
    },
    descriptionText: {
      color: Styles.black,
      fontSize: Styles.Fonts.f7,
      fontWeight: '400',
      textAlign: 'center',
      marginTop: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    nearByLocationView: {
      fontSize: Styles.Fonts.f8,
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.Colors.PayNew.black01,
    },
    nearByLocationViewDesc: {
      fontSize: Styles.Fonts.f8,
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.footerTabInactive,
    },
  };
}

export function onboardingSelectLocationTextDescriptor(): string {
  return Localized.Labels.turn_on_location_services;
}
export function getNotificationScreenStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    readUnreadText: {
      fontSize: Styles.Fonts.f1,
      paddingTop: Styles.Spacing.m1,
      color: Styles.Colors.PayNew.primary01,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '700',
    },
    unreadNumText: {
      fontSize: Styles.Fonts.f1,
      color: Styles.tabBarTextColor,
      fontFamily: Styles.FontFamily.robotoRegular,
      marginTop: Styles.Spacing.m1,
      fontWeight: '400',
    },
    emptyMessageText: {
      fontSize: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.robotoRegular,
      marginTop: Styles.Spacing.m2,
      lineHeight: Styles.Fonts.f7,
    },
  };
}

export function getSettingsMenusStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    optionText: {
      minHeight: Styles.Heights.touchTargetHeight0,
      paddingTop: 3,
      color: Styles.darkColor,
      textAlign: 'center',
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
    },
    listShadow: null,
    divider: {
      marginHorizontal: 10,
      backgroundColor: Styles.lightGray,
      height: 1,
    },
    enclosureContainer: {
      borderWidth: 1,
      borderColor: Styles.lightGray,
      borderRadius: 10,
      marginHorizontal: Styles.Spacing.m3,
    },
  };
}
export function stylePayAvailOption(styles) {
  return styles.payAvailableOption;
}

export function snackMessageDescriptor(
  props?: SnackItemDescriberProps & WithGlobalizeProps,
): string | Formatted[] {
  const {snack = {amount: 0, senderName: ''}} = props || {};
  return Localized.Labels.formatString(
    Localized.Labels.you_have_received_a_snack,
    Util.formatCurrency(snack, snack?.amount ?? 0, AccountStore.getCurrency()),
    snack?.senderName ?? '',
  );
}
export function settingsMenuFontColorDescriptor(): string {
  return Styles.lightGray;
}
export function claimBtnColorDescriptor(): string {
  return Styles.Colors.Canteen.darkGreen;
}
export function claimedBtnColorDescriptor(): string {
  return Styles.Colors.PayNew.darkHuesBase04;
}
export function claimeSucessdBtnColorDescriptor(): string {
  return Styles.Colors.Canteen.greenSelection;
}
export function claimedErrorBtnColorDescriptor(): string {
  return '#d7263d26';
}
export function renderReferralStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    referralSuccErrorText: {
      color: Styles.black,
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    referralHederText: {
      color: Styles.black,
      fontSize: Styles.Fonts.sectionHeader,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    referralDescText: {
      color: Styles.black,
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
  };
}
export function addNewPaymentTemplateDescriptor(): JSX.Element {
  return <FontAwesome5Pro color="#555" light name="plus-circle" size={26} />;
}

export function getSnackItemStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    message: {
      color: Styles.lightGray,
      fontWeight: '400',
      fontSize: Styles.Fonts.f8,
      fontFamily: Styles.FontFamily.robotoRegular,
      flex: 1,
    },
    messageLeft: {
      marginLeft: Styles.Spacing.m2,
      marginRight: Styles.Spacing.m3,
      flex: 1,
    },
    from: {
      color: Styles.darkColor,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '400',
      fontSize: Styles.Fonts.f1,
    },
    referalTitle: {
      color: Styles.darkColor,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '700',
      fontSize: Styles.Fonts.f1 + 2,
    },
    container: {
      borderBottomWidth: StyleSheet.hairlineWidth,
      flex: 1,
      borderBottomColor: Styles.lightGray,
      flexDirection: 'row',
      minHeight: Styles.Heights.touchTargetHeight2,
      padding: Styles.Spacing.m3,
    },
    subject: {
      color: Styles.darkColor,
      marginTop: Styles.Spacing.m1,
      fontFamily: Styles.FontFamily.robotoRegular,
      marginBottom: Styles.Spacing.m1,
      fontWeight: '400',
      fontSize: Styles.Fonts.f1,
    },
    topRight: {
      flexDirection: 'row',
    },
    chevronRight: {
      width: 24,
      height: 24,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    date: {
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.lightGray,
      fontWeight: '400',
      paddingTop: Styles.Spacing.m1,
      fontSize: Styles.Fonts.f1,
      left: Styles.Spacing.m4 + 4,
    },
    referralDate: {
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.lightGray,
      fontWeight: '400',
      fontSize: Styles.Fonts.f1,
      left: Styles.Spacing.m4 + 12,
    },

    titleHeader: {
      fontWeight: '700',
      color: Styles.darkColor,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
    },
    claimedBtnText: {
      paddingHorizontal: 10,
      paddingVertical: 3,
      borderWidth: 1,
      backgroundColor: '#21ad5a26',
      borderColor: '#21ad5a26',
      borderRadius: 4,
      minWidth: 75,
    },
    expiredBtnText: {
      paddingHorizontal: 10,
      paddingVertical: 3,
      borderWidth: 1,
      backgroundColor: '#D7263D26',
      borderColor: '#D7263D26',
      borderRadius: 4,
      minWidth: 75,
    },
    referralStatusText: {
      fontSize: 14,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
  };
}
export function switchColor(): string {
  return null;
}

export function snackItemTitleDescriptor(): string {
  return Settings.appDisplayName;
}

export function dealRedeemCount(text: string): JSX.Element {
  return (
    <Text
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
      style={[
        dealStyleDescriptor()['dealTarget'],
        dealStyleDescriptor()['successText'],
      ]}
    >
      {text}
    </Text>
  );
}
export function getuserInitialsStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    initialsText: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f2,
    },
    initialsBtn: {
      flexDirection: 'row',
      justifyContent: 'center',
      height: 72,
      width: 72,
      borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m3),
      margin: PixelRatio.roundToNearestPixel(0.5),
      backgroundColor: Styles.primaryColor,
    },
    text: {
      fontWeight: 'bold',
      fontFamily: Styles.FontFamily.robotoRegular,
      alignSelf: 'center',
      color: Styles.primaryColor,
    },
  };
}
export function dealRenderDealIcon(): JSX.Element {
  return (
    <View style={{justifyContent: 'center'}}>
      <NotifyDealIcon height={50} bgColor={'#003349'} width={50} />
    </View>
  );
}
export function dealRenderDealNameDesc(props?: DealProps): JSX.Element {
  return (
    <>
      <Text
        accessibilityLabel={props?.deal?.message}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
        style={dealStyleDescriptor()['dealDesc']}
        numberOfLines={3}
      >
        {props?.deal?.message}
      </Text>
    </>
  );
}
export function dealRenderExpirationTxtTemplate(
  props?: DealProps,
): JSX.Element {
  let _expirationPfx = Localized.Labels.expires;

  if (props.expired) {
    _expirationPfx = Localized.Labels.expired;
  }

  const _exprtnTxt = `${_expirationPfx} ${moment(props.deal.endDate).calendar(
    null,
    {
      sameDay: `[${Localized.Labels.today}]`,
      nextWeek: 'dddd',
    },
  )}`;
  return (
    <Text
      style={dealStyleDescriptor()['expirationDate']}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
    >
      {_exprtnTxt}
    </Text>
  );
}

export function getTypoFontSize() {
  return 18;
}

export function scanScreenQRCodeStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    btnAddFund: {
      paddingRight: 24,
      paddingLeft: 24,
      paddingTop: 8,
      paddingBottom: 8,
    },
    btnDone: {
      paddingRight: 12,
      paddingTop: 6,
      paddingLeft: 12,
      paddingBottom: 6,
    },
    btnAddToWallet: {
      paddingLeft: 12,
      paddingRight: 12,
      borderWidth: 2,
      paddingTop: 8,
      paddingBottom: 8,
    },
    btnEmailReceipt: {
      paddingTop: 6,
      paddingRight: 12,
      paddingLeft: 12,
      paddingBottom: 6,
      borderWidth: 2,
    },
    addFundsText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      color: '#066DFF',
      fontWeight: '700',
      fontSize: Styles.Fonts.f1,
    },
    balanceText: {
      textTransform: 'uppercase',
      fontFamily: Styles.FontFamily.comodoVintage,
      fontSize: Styles.Fonts.sectionHeader,
      fontWeight: '400',
      color: Styles.black,
    },
    addToWalletText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: Styles.Fonts.f1,
      color: '#066DFF',
      fontWeight: '700',
    },
    addFundsButtonContainer: {
      justifyContent: 'center',
      marginTop: Styles.Spacing.m3 + 6,
      alignItems: 'center',
    },
  };
}
export function cardsMainViewBgColor(): string {
  return null;
}

export function scanScreenQRCodeHeaderText(): string {
  return Localized.Labels.pay_at_kiosk;
}
export function snackItemGiftBoxTemplateDescriptor(): JSX.Element {
  return (
    <View style={{justifyContent: 'center', backgroundColor: 'transparent'}}>
      <GiftIconCP />
    </View>
  );
}
export function referralImageTemplateDescriptor(
  props?: ReferralItemDescriberProps,
): JSX.Element {
  return (
    <View
      style={{
        justifyContent: 'center',
        right: 5,
      }}
    >
      {props.isSender ? (
        <Referral width={48} height={48} />
      ) : (
        <SignUp width={48} height={48} />
      )}
    </View>
  );
}

function getAnimatedViewDescriptor(
  rotate: Animated.AnimatedInterpolation<string>,
  props: {header: React.ReactNode},
) {
  return (
    <>
      <Animated.View
        style={[
          {
            transform: [{rotate}],
          },
          styles.chevron,
        ]}
      >
        <FontAwesome5Pro
          size={Styles.Fonts.f2}
          name={'chevron-right'}
          color={Styles.lightGray}
          light
        />
      </Animated.View>
      {props.header}
    </>
  );
}

export function getSuccessDialogDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    header: {
      color: Styles.black,
      fontSize: Styles.Fonts.f9,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.robotoRegular,
      marginTop: Styles.Spacing.m2,
    },
    title: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1,
      fontWeight: '600',
      fontFamily: Styles.FontFamily.robotoRegular,
      marginTop: Styles.Spacing.m3,
    },
    okayButtonText: {
      color: Styles.white,
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
      textAlign: 'center',
      width: 82,
      marginLeft: Styles.Spacing.m1,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
  };
}

export function getSuccessDialogBtn(callBack: () => void) {
  return (
    <RoundedButton
      buttonType={ButtonType.normal}
      onPress={callBack}
      containerStyle={{
        alignSelf: 'center',
        marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
      }}
      accessibilityLabel={`${Localized.Buttons.okay}`}
      text={Localized.Buttons.okay}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
      textStyle={getSuccessDialogDescriptor()['okayButtonText']}
    />
  );
}

export function RewardPointStyleDescriptor(props: RewardsSection): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.robotoRegular,
    fontSize: 24,
    fontWeight: '400',
    color: '#111',
  };
}

export function dealStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    successText: {
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.positiveColor,
      fontWeight: 'bold',
    },

    dealDesc: {
      marginBottom: Styles.Spacing.m1,
      fontSize: Styles.Fonts.f8,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '400',
      color: Styles.lightGray,
      maxWidth: '90%',
      flex: 1,
    },
    arrow: {
      alignItems: 'center',
      marginLeft: Styles.Spacing.m3,
      justifyContent: 'center',
    },
    expirationDate: {
      color: Styles.lightGray,
      alignSelf: 'flex-end',
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '400',
      fontSize: Styles.Fonts.f1,
      left: Styles.Spacing.m4,
    },
    titleHeader: {
      fontSize: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '700',
      color: Styles.darkColor,
    },
    container: {
      borderBottomWidth: StyleSheet.hairlineWidth,
      flexDirection: 'row',
      flex: 1,
      minHeight: Styles.Heights.touchTargetHeight2,
      borderBottomColor: Styles.lightGray,
      padding: Styles.Spacing.m3,
    },
    dealTarget: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f1,
      alignSelf: 'flex-end',
    },
    dealName: {
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1,
      flex: 1,
      marginRight: Styles.Spacing.m2,
      fontWeight: '400',
      marginBottom: Styles.Spacing.m1,
    },
    inboxTabBgColor: {
      backgroundColor: 'transparent',
    },
  };
}

export function dealRenderDealTopSection(props?: DealProps): string {
  return props.deal.name;
}
export function collapsibleComponentDescriptor(): CollapsibleComponentProps {
  return {
    toValue: 90,

    content: {
      paddingHorizontal: Styles.Spacing.m3,
      flexDirection: 'column',
      marginVertical: Styles.Spacing.m2,
      justifyContent: 'flex-start',
    },
    toDegree: '90deg',
    parentContainer: {flex: 1, flexDirection: 'row'},
  };
}
export function successModalSnackGifDescriptor() {
  return require('../../img/SnackGift.gif');
}
// allyinput
export function getStyles(): StylesDescriptor {
  return {
    container: {
      marginTop: Styles.Spacing.m3,
    },
    paymentDropDownInputContainer: {
      height: 55,
      borderRadius: 10,
      overflow: 'hidden',
      flexDirection: 'row',
      borderColor: Styles.lightGray,
      marginBottom: Styles.Spacing.m2,
      borderWidth: 1,
      padding: 2,
    },
    paymentInput: {
      fontSize: Styles.Fonts.f7,
      color: Styles.black,
      fontWeight: '700',
      flex: 1,
      backgroundColor: Styles.white,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    leftIcon: {
      borderRadius: 6,
      marginRight: 4,
      backgroundColor: Styles.white,
      paddingEnd: 2,
      paddingVertical: 2,
    },
    imageContainer: {
      right: 10,
      flex: 1,
      bottom: 50,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    labelContainer: {
      backgroundColor: Styles.tabBarBackgroundColor,
      position: 'absolute',
      paddingHorizontal: 8,
    },
    label: {
      color: Styles.lightGray,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: 14,
    },
    error: {
      marginLeft: Styles.Spacing.m1,
      marginTop: Styles.Spacing.m1,
      color: Styles.dangerColor,
      fontSize: 12,
    },
    input: {
      padding: Styles.Spacing.m3,
      fontWeight: '600',
      borderWidth: 1,
      backgroundColor: Styles.white,
      borderRadius: 4,
      fontSize: 16,
      marginBottom: 5,
    },

    help: {
      marginTop: Styles.Spacing.m1,
      marginLeft: Styles.Spacing.m1,
      fontSize: 12,
      color: Styles.lightGray,
    },
  };
}
export function snackItemNumberDescriptor(): number {
  return 2;
}
export function dealItemNumberDescriptor(): number {
  return 2;
}
export function getTextInputHeight(multiline?: boolean): {
  [index: string]: number | string;
} {
  return {
    inputHeight: multiline ? 55 : null,
    textAlignVertical: null,
  };
}

export function getTopPadding(): {
  [index: string]: number | string;
} {
  return {};
}

export function getAnimationConfig(label?: string, iconName?: string) {
  return {
    translateX: [2, (label || '').length >= 11 ? 7 - (label || '').length : 2],
    translateY: [18, !iconName ? 2 : 9],
    scale: [1, 0.8],
  };
}
export function getBackgroundColor() {
  return Styles.white;
}
export function getDropDownMenuStyles(props): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    touchableOpacity: {},
    style: {
      width: Platform.OS !== 'web' ? '90%' : 'auto',
    },
    contentStyle: {
      borderRadius: props.dropDownHeader ? 10 : 0,
      backgroundColor: Styles.white,
    },
  };
}

export function getDropDownMenuListStyle(dropDownSize, maxWidth, props, index) {
  return {
    options: {
      padding: Styles.Spacing.m3,
      alignItems: 'center',
      flexDirection: 'row',
      width: dropDownSize?.width || maxWidth,
      minHeight: Styles.Heights.touchTargetHeight1,
    },
    optionTextStyling: {
      color: '#000000',
      fontWeight: '700',
      fontSize: 16,
      fontFamily: Styles.FontFamily.robotoRegular,
    },

    optionTextStylingForPayment: {},
    amexSpecificMargin: {},
    optionPaymentText: {},
    parentContainer: {
      props,
      index,
    },
    optionImageExtraMargins: {},
  };
}

export function getIcon(props, isFocused?: boolean) {
  if (props.hasIcon) {
    return (
      <TouchableWithoutFeedback
        onPress={props.iconPressed}
        accessibilityRole="button"
        accessibilityLabel={props.iconLabel}
        aria-label={props.iconLabel}
        accessible={true}
        role="button"
      >
        <View
          style={[
            styles.iconContainer,
            props.iconName === 'closecircleo' && {
              right: 15,
              top: Platform.OS === 'ios' ? -41 : -45,
            },
          ]}
        >
          {props.iconName !== 'closecircleo' && (
            <Icon
              color={isFocused ? Styles.primaryColor : Styles.darkColor}
              size={28}
              style={styles.icon}
              name={props.iconName}
            />
          )}
          {props.iconName === 'closecircleo' && (
            <CloseIcon width={28} height={28} color={'#727179'} />
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
  return null;
}
const styles = StyleSheet.create({
  chevron: {
    marginEnd: Styles.Spacing.m3,
    alignSelf: 'baseline',
  },

  icon: {
    backgroundColor: 'transparent',
    right: 0,
    bottom: 0,
    position: 'absolute',
    width: 50,
    height: 50,
  },
  iconContainer: {
    top: Platform.OS == 'ios' ? 5 : 0,
    flex: 1,
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
});
const checkCanteen = () => {
  return Settings.buildType == 'canteen'
    ? Styles.primaryColor
    : Styles.lightGray;
};
export function payOverlayTxt() {
  return {right: -7};
}

export function stylePayUnAvailOption(_styles) {
  return _styles.payUnavailableOption;
}

export function getColor(isFocused: boolean, hasError: boolean) {
  if (hasError) {
    return Styles.dangerColor;
  }
  return isFocused ? checkCanteen() : Styles.lightGray;
}

export function rendercommonSecNewStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    headerContainer: {
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: Styles.Spacing.m3,
      flexDirection: 'row',
      marginBottom: Styles.Spacing.m2,
    },
    header: {
      fontSize: Styles.Fonts.f2,
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.darkColor,
      fontWeight: '700',
    },
    container: {
      minHeight: Styles.Heights.touchTargetHeight0,
      marginTop: Styles.Spacing.m4,
    },
    inputBoxFontStyle: {
      fontFamily: Styles.FontFamily.robotoRegular,
    },
  };
}

export function navigateToWalletScreen() {
  setTimeout(() => {
    NavActions.push(AppRoutes.Wallet, {hideBackArrow: false});
  }, 1000);
}

export function PasswordInputMaxFontSizeMultipleDescriptor(): number {
  return Styles.FontSizeMultiplier.maxfm11;
}

export function rewardAmtTxt(_styles, isAvailable?: boolean) {
  if (!isAvailable) {
    return {..._styles.payUnAvailableText, marginTop: Styles.Spacing.m1};
  } else {
    return {..._styles.payAvailableText, marginTop: Styles.Spacing.m1};
  }
}
export function referralDetailBgColorDescriptor(): string {
  return Styles.white;
}

export function rewardPointTxt(_styles, isAvailable?: boolean) {
  if (!isAvailable) {
    return {..._styles.payOverlayPoint, ..._styles.payUnAvailablePoint};
  } else {
    return {..._styles.payOverlayPoint, ..._styles.payAvailablePoint};
  }
}

export function commonutilsRenderSwitchTemplate(
  props?: CommonUtilsSwitchProps,
): JSX.Element {
  const {onSwitchToggle, switchStatus} = props;
  if (switchStatus && onSwitchToggle) {
    return (
      <CustomToggleSwitch
        nativeID={props.title}
        accessibilityLabel={props.title}
        accessible={true}
        onValueChange={() => onSwitchToggle()}
        accessibilityHint="Double tap to activate the switch"
        accessibilityState={{checked: props.switchStatus}}
        accessibilityValue={{text: props.switchStatus.toString()}}
        aria-valuetext={props.switchStatus.toString()}
        aria-label={props.title}
        accessibilityRole="switch"
        aria-checked={props.switchStatus}
        value={props.switchStatus}
      />
    );
  } else {
    return null;
  }
}

export function textInputColor(): string {
  return Styles.darkColor;
}
export function PasswordInputmaxlength(): number {
  return 24;
}
export function checkRewardsBackgroundColor() {
  return [Styles.white, Styles.white, `${Styles.white}99`];
}
export function checkRoundedBtnColor(): string {
  return '#003349';
}

export function bottomTabBtnColor(): string {
  return '#003349';
}

export function checkEmailreceiptBtnTxt(): string {
  return Localized.Buttons.email_receipt;
}

export function checkEmailSuccessMsg(): string {
  return Localized.Success.receipt_cp_success;
}

export function activeTabShadowColor(): string {
  return Styles.white;
}

export function numberSpinnerStyles(): NumberSpinnerStylesDescriptor {
  return StyleSheet.create({
    numText: {
      textAlign: 'center',
    },
    num: {
      paddingHorizontal: Styles.Spacing.m3,
      marginTop: 5,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
}
export function numberSpinnerColor(): string {
  return Styles.white;
}
export function referralSummaryTotal(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.robotoRegular,
    fontSize: 24,
    fontWeight: '700',
    color: '#111',
  };
}

export function homePageTitlesScreenStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    viewAll: {
      color: Styles.blackBase01,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    title: {
      fontSize: Styles.Fonts.headerFont,
      fontWeight: '700',
      color: Styles.blackBase01,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
  };
}

export function referralTransactionId(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.robotoRegular,
    fontSize: 14,
    fontWeight: '400',
    color: '#111',
    paddingBottom: Styles.Spacing.m3,
  };
}

export function referrallocationName(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.robotoRegular,
    fontSize: 16,
    fontWeight: '700',
    color: '#111',
    paddingBottom: Styles.Spacing.m2 - 2,
  };
}
export function renderpointsLbl(_styles) {
  return <></>;
}
export function toggleColor() {
  return ['#707070', '#003349'];
}
export function rewardsPointLbl(): string {
  return Localized.Labels.points;
}
export function referralDate(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.robotoRegular,
    fontSize: 16,
    fontWeight: '400',
    color: '#111',
    paddingBottom: Styles.Spacing.m3,
  };
}
export function walletForntStyleText(): string {
  return Styles.FontFamily.robotoRegular;
}
export function fontStyleSuccessModal() {
  return {fontFamily: Styles.FontFamily.aeonikRegular};
}
export function rewardPoints(
  _styles,
  i,
  rewardOptions,
  largestAvailableRewardAmount,
  isRewardButtonShown,
) {
  return (
    <View
      style={[
        _styles.payGraph,
        isRewardButtonShown && {
          marginTop:
            PixelRatio.getFontScale() > Styles.FontSizeMultiplier.maxfm0
              ? Styles.Spacing.m5
              : Styles.Spacing.m4,
        },
      ]}
      accessible={true}
      accessibilityLabel={`${largestAvailableRewardAmount} in rewards available`}
      accessibilityRole="image"
      aria-label={`${largestAvailableRewardAmount} in rewards available`}
      role="img"
      key={-1}
    >
      <View style={_styles.line}>
        <View key={i++} style={[_styles.rewardOption, _styles.flex1]}>
          <View
            style={[
              _styles.payAvailableOption,
              _styles.firstSection,
              {
                flex: Styles.Spacing.m1,
              },
            ]}
          />
        </View>
      </View>

      <View style={[_styles.line, _styles.flex15]}>{rewardOptions}</View>

      <View style={_styles.line}>
        <View key={i++} style={[_styles.rewardOption, _styles.flex1]}>
          <View
            style={[
              _styles.payUnavailableOption,
              _styles.lastSection,
              {
                flex: Styles.Spacing.m1,
              },
            ]}
          />
        </View>
      </View>
    </View>
  );
}

export function sendSnackOTSNBtnStyleDescriptor(): string {
  return Styles.darkGreenRevolve;
}

export function sendSnackOTSNotificationStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    acceptedSnactSubDesc: {
      fontSize: Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontWeight: '400',
      color: Styles.black,
    },
    acceptSnackText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    acceptSnackHeaderText: {
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: Styles.Fonts.sectionHeader,
      color: Styles.black,
    },
    acceptedSnactHeaderText: {
      color: Styles.black,
      marginLeft: Styles.Spacing.m2,
      fontSize: Styles.Fonts.sectionHeader + 2,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    acceptSnackSubDescText: {
      fontSize: Styles.Fonts.f1,
      color: Styles.black,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    saveContainer: {},
    resetPinLablStyle: {},
  };
}

export function walletScreenCardStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    descriptionText: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      marginHorizontal: 24,
      marginTop: 24,
      color: Styles.black,
    },
    txtInput: {
      fontSize: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.black,
      fontWeight: '400',
      height: 56,
      marginTop: 12,
    },
    cardNumberLabel: {
      fontSize: Styles.Spacing.m3,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '400',
      color: '#707070',
    },
    cardLabels: {
      fontSize: Styles.Spacing.m3,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '400',
      color: '#707070',
    },
    setDefaultLabel: {
      fontSize: Styles.Fonts.f1,
      marginRight: Styles.Spacing.m2,
      color: Styles.black,
      marginLeft: Styles.Spacing.m1,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    saveFontStyle: {
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    balancesTitle: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      color: Styles.tabBarTextColor,
      marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
      marginBottom: Styles.Spacing.m2 + Styles.Spacing.m2 - 2,
    },
  };
}
export function historyDateBtnColor(): string {
  return Styles.lightGrayRevolve;
}
export function historyListStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    historyHeaderTitle: {
      fontWeight: '700',
      fontSize: Styles.Fonts.sectionHeader,
      paddingBottom: Styles.Spacing.m3,
      paddingTop: Styles.Heights.h2,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    historyHeaderTitleDes: {
      alignSelf: 'flex-start',
      color: Styles.black,
      flex: 2,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    historyTabFontFamily: {
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    historyTabActiveFontColor: {
      color: Styles.white,
    },
    historyTabInactiveFontColor: {
      color: Styles.black,
    },
  };
}
export function getScanKiosk() {
  return {
    borderWidth: 2,
    paddingHorizontal: 16,
    paddingVertical: 10,
  };
}
export function fundingAmountColor(): string {
  return Styles.darkColor;
}

export function eGiftCardSuccesNavigation() {
  return NavActions.popToRoute(AppRoutes.Cards);
}

export function payAtKioskLbl() {
  return Localized.Labels.pay_at_kiosk;
}

export function scanAtKioskLbl() {
  return Localized.Labels.Scan_at_kiosk;
}
export function getPayKiosk() {
  return {
    borderWidth: 2,
    paddingLeft: 16,
    paddingRight: 16,
    paddingVertical: 10,
  };
}

export function getBalanceValueTextColor(): TextStyle {
  return {
    color: Styles.primaryColor,
  };
}

export function referralIconColor(): string | null {
  return null;
}

export default {
  getInboxTabStyles,
  addNewPaymentTemplateDescriptor,
  getNotificationScreenStyles,
  getTypoFontSize,
  getuserInitialsStyleDescriptor,
  getSuccessDialogDescriptor,
  getSuccessDialogBtn,
  getSettingsMenusStyleDescriptor,
  fundingAmountColor,
  PasswordInputMaxFontSizeMultipleDescriptor,
  snackMessageDescriptor,
  dealRenderExpirationTxtTemplate,
  checkEmailSuccessMsg,
  snackItemGiftBoxTemplateDescriptor,
  getSnackItemStyleDescriptor,
  dealRedeemCount,
  historyDateBtnColor,
  snackItemTitleDescriptor,
  cardsMainViewBgColor,
  historyListStyleDescriptor,
  snackItemNumberDescriptor,
  checkEmailreceiptBtnTxt,
  checkRoundedBtnColor,
  settingsMenuFontColorDescriptor,
  snackItemDateDescriptor,
  dealItemNumberDescriptor,
  dealRenderDealIcon,
  getTopPadding,
  collapsibleComponentDescriptor,
  dealRenderDealTopSection,
  dealStyleDescriptor,
  PasswordInputmaxlength,
  walletForntStyleText,
  getAnimatedViewDescriptor,
  getStyles,
  dealRenderDealNameDesc,
  getColor,
  getBackgroundColor,
  getTextInputHeight,
  getIcon,
  getAnimationConfig,
  rendercommonSecNewStyleDescriptor,
  commonutilsRenderSwitchTemplate,
  textInputColor,
  getDropDownMenuStyles,
  scanScreenQRCodeHeaderText,
  toggleColor,
  getDropDownMenuListStyle,
  historyHeaderName,
  renderpointsLbl,
  homePageTitlesScreenStyles,
  activeTabShadowColor,
  scanScreenQRCodeStyleDescriptor,
  numberSpinnerStyles,
  stylePayAvailOption,
  numberSpinnerColor,
  referralImageTemplateDescriptor,
  referralItemDateDescriptor,
  claimBtnColorDescriptor,
  claimedBtnColorDescriptor,
  claimeSucessdBtnColorDescriptor,
  claimedErrorBtnColorDescriptor,
  renderReferralStyleDescriptor,
  getPayKiosk,
  rewardsPointLbl,
  referralDetailBgColorDescriptor,
  rewardPointTxt,
  rewardAmtTxt,
  switchColor,
  walletScreenCardStyles,
  fontStyleSuccessModal,
  referralSummaryTotal,
  referralTransactionId,
  stylePayUnAvailOption,
  onboardingSelectLocationTextDescriptor,
  referrallocationName,
  successModalSnackGifDescriptor,
  rewardPoints,
  payOverlayTxt,
  onboardingFlowStyleDescriptor,
  referralDate,
  checkRewardsBackgroundColor,
  onboardingLocaionBorderColor,
  onboardingLocaionBgColor,
  RewardPointStyleDescriptor,
  sendSnackOTSNBtnStyleDescriptor,
  scanAtKioskLbl,
  getScanKiosk,
  navigateToWalletScreen,
  sendSnackOTSNotificationStyleDescriptor,
  getBalanceValueTextColor,
  referralIconColor,
  payAtKioskLbl,
  eGiftCardSuccesNavigation,
};
