import {ImageBackground} from 'react-native';
import Styles from 'src/components/Styles';

export function getHeaderBackground(content: JSX.Element): JSX.Element {
  return (
    <ImageBackground
      resizeMode="stretch"
      source={require('src/components/img/CompanyKitchenGradientHeader.png')}
    >
      {content}
    </ImageBackground>
  );
}

export function headerContainerStyleDescriptor(): {
  [key: string]: {[index: string]: number | string};
} {
  return {
    safeArea: {
      backgroundColor: Styles.transparent,
    },
    container: {
      flex: 1,
      backgroundColor: Styles.white,
      flexDirection: 'column',
    },
    headerTextStyle: {
      textAlign: 'left',
      fontSize: Styles.Fonts.headerFont,
      color: Styles.Colors.PayNew.black01,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.robotoRegular,
      height: Styles.Heights.touchTargetHeight1,
    },
  };
}

export function getBackIconColor(_transparent: boolean): string {
  return Styles.Colors.PayNew.black01;
}

export default {
  getHeaderBackground,
  headerContainerStyleDescriptor,
  getBackIconColor,
};
