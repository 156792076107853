import * as React from 'react';
import Svg, {Rect, Path} from 'react-native-svg';
interface IProps {
  color?: string;
  fillColor?: string;
}
const MinusIcon = ({fillColor = '#066DFF', color = 'white'}: IProps) => (
  <Svg width={48} height={49} viewBox="0 0 48 49" fill="none">
    <Rect
      x={8.5}
      y={8.95453}
      width={31}
      height={30.4545}
      rx={15.2273}
      fill={color}
    />
    <Rect
      x={8.5}
      y={8.95453}
      width={31}
      height={30.4545}
      rx={15.2273}
      stroke={fillColor}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 24.6648H14V23.6989H34V24.6648Z"
      fill={fillColor}
    />
  </Svg>
);
export default MinusIcon;
