import React from 'react';
import {
  View,
  StyleSheet,
  Platform,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
} from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import Localized from 'src/constants/AppStrings';
import CanteenUIManager from './CanteenUIManager';
import MarketLogo from '../../img/svg/MarketLogo';
import MarketShopLogo from '../../img/svg/MarketShopLogo';
import Styles from '../../Styles';
import IconWithBadge from '../IconWithBadge';
import BalanceContainer from '../BalanceContainer';
import {RewardType} from 'src/types/Rewards';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import {getDescriber} from '../../descriptor/DescriptorType';

class MarketUIManager extends CanteenUIManager {
  getPromotionsHeader(
    qrCode: string,
    _balance: number,
    _animationScrollY: any,
    strings: typeof Localized,
  ) {
    return (
      <View>
        <View style={styles.header}>
          <View
            accessible={true}
            accessibilityLabel=""
            aria-label=""
            style={styles.headerSide}
          >
            <MarketLogo fillColor={Styles.black} size={Styles.Fonts.f6} />
          </View>
          <View accessible={true} accessibilityLabel="" aria-label="">
            <QRCode value={qrCode} size={Styles.Fonts.f5} />
          </View>
          <View style={styles.headerSide}>
            <TouchableOpacity
              onPress={() => {
                NavActions.push(AppRoutes.Balances);
              }}
            >
              <BalanceContainer strings={strings} primaryColor />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }

  getPromotionsStickyHeaderIndices() {
    return [0];
  }

  getHomeRewardSection(
    rewards: number,
    rewardSteps: Array<RewardType>,
    style: StyleProp<ViewStyle>,
    isRewardButtonShown: boolean,
  ) {
    return super.getHomeRewardSection(
      rewards,
      rewardSteps,
      [style, styles.rewardSection],
      isRewardButtonShown,
    );
  }

  getLoginLogoImage() {
    return (
      <View style={styles.logoContainer}>
        <MarketLogo
          size="100%"
          fillColor={Platform.select({
            web: Styles.black,
            default: Styles.white,
          })}
        />
      </View>
    );
  }

  getMarketLogo() {
    return (
      <View
        accessible={true}
        accessibilityLabel={Localized.Labels.my_market_account}
        aria-label={Localized.Labels.my_market_account}
        style={[styles.marketLogo]}
      >
        <MarketShopLogo size={Styles.Fonts.f4} />
      </View>
    );
  }

  getShopTitle() {
    return Localized.Labels.locations;
  }

  getInboxLabel() {
    return Localized.Labels.inbox;
  }

  getShopLabel() {
    return Localized.Labels.shop;
  }

  getWelcomeHeaderImage() {
    return (
      <View
        accessible={true}
        accessibilityLabel={Localized.Labels.market_logo}
        accessibilityRole="image"
        role="img"
        aria-label={Localized.Labels.market_logo}
        style={styles.headerImage}
      >
        <MarketLogo
          size={Styles.Heights.headerHeight}
          fillColor={Styles.black}
        />
      </View>
    );
  }

  showDemographicScreen() {
    return false;
  }

  getShopIcon(tintColor: string, shopBadgeCount: number) {
    return (
      <IconWithBadge
        iconName="store-alt"
        tintColor={tintColor}
        size={Styles.Fonts.f2}
        badgeCount={shopBadgeCount}
      />
    );
  }

  getLoginStatusBarStyle() {
    return getDescriber().statusBarStyleDescriptor();
  }

  getHelpLabel() {
    return Localized.Labels.faqs_and_tech_support;
  }

  showAllFAQs() {
    return false;
  }

  getScanLabel(): string {
    return '';
  }
}

const styles = StyleSheet.create({
  logoContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  marketLogo: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: Styles.Spacing.m3,
    width: Styles.Spacing.m5,
  },
  headerImage: {
    alignItems: 'center',
    marginVertical: Styles.Spacing.m4,
  },
  header: {
    flexDirection: 'row',
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
    backgroundColor: Styles.white,
    justifyContent: 'space-between',
    shadowColor: Styles.darkColor,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: Styles.Spacing.m2,
    alignItems: 'center',
  },
  rewardSection: {
    paddingVertical: Styles.Spacing.m2,
  },
  headerSide: {
    flex: 1,
  },
});
export default MarketUIManager;
