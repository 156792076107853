import React from 'react';
import {PixelRatio, Platform, ScrollView, StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import AVText from '../../../elements/AVText';
import BaseScreen from '../../BaseScreen';
import ScreenContext from '../../../ScreenContext';
import Util from 'src/Util';
import Styles from '../../../Styles';
import AccountStore from 'src/stores/AccountStore';
import DashedLine from 'react-native-dashed-line';
import AVFormattedCurrency from '../../../elements/AVFormattedCurrency';
import type {ReferralRewardSummary} from 'src/types/Referral';
import Events from 'src/logging/Events';
import type {HistoryItem} from 'src/types/TransactionDetail';
import LoadingScreen from '../../LoadingScreen';
import Localized from 'src/constants/AppStrings';
import ReferralActions from 'src/actions/ReferralActions';
import {alertError} from '../../../helpers/AlertHelper';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import Settings from 'src/Settings';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import uuid from 'src/nativeModules/UUID';
import ActionsFactory from 'src/actions/ActionsFactory';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import {SuccessDialog} from 'src/components/elements/SuccessDialog';
import {getDescriber as getSnackDescriber} from 'src/components/screens/sendSnack/descriptor/sendasnack/DescriptorType';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';

type ReferralSummaryScreenProps = WithGlobalizeProps & {
  historyItem: HistoryItem;
};
type ReferralSummaryScreenState = {
  summary: ReferralRewardSummary;
  showModal?: boolean;
};

class ReferralSummaryScreen extends React.Component<
  ReferralSummaryScreenProps,
  ReferralSummaryScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.renderLabel = this.renderLabel.bind(this);
    this.renderUser = this.renderUser.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.fetchReferralSummaryDetails =
      this.fetchReferralSummaryDetails.bind(this);
    this.handleEmailReferralReceipt =
      this.handleEmailReferralReceipt.bind(this);
    this.state = {
      summary: null,
      showModal: false,
    };
  }

  componentDidMount() {
    this.fetchReferralSummaryDetails();
  }

  async handleEmailReferralReceipt(isSender?: boolean) {
    let response;
    const label = isSender ? 'referral' : 'signup';
    try {
      response = await ActionsFactory.getAccountActions().emailReferralReceipt(
        AccountStore.getEmail(),
        this.state.summary.receiverName,
        this.state.summary?.amount.toString(),
        this.state.summary.locationName,
        this.state.summary.dateCreated,
        this.props.historyItem.currency ?? AccountStore.getCurrency(),
        this.state.summary.transactionDate,
        this.state.summary.transactionId,
        label,
      );
      if (response.status === 'ok') {
        this.setState({
          showModal: true,
        });
      } else {
        throw new Error('Error sending email receipt');
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      alertError(Localized.Errors.error_emailing_receipt, guid);
      CrashlyticsEvents.log(
        'Exception',
        'ReferralSummary:handleEmailReferralReceipt',
        generateErrorMessage(error),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'ReferralSummary:handleEmailReferralReceipt',
        generateErrorMessage(error),
        guid,
      );
    }
  }

  async fetchReferralSummaryDetails() {
    try {
      const summary = await ReferralActions.fetchReferralSummaryV2(
        this.props.historyItem.ReferralId,
        this.props.historyItem.Id,
        this.props.historyItem.TransDate,
      );

      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchReferralSummary',
        JSON.stringify({transactionId: this.props.historyItem.Id}),
        JSON.stringify(summary),
      );
      if (summary?.referralId) {
        this.setState({
          summary,
        });
      } else {
        NavActions.pop();
        if (Settings.isNewUI() && Platform.OS !== 'web') {
          NavActions.navigate(AppRoutes.ServerErrorDialog, {
            errorTitle: Localized.Labels.error_getting_receipt,
            errorDesc: Localized.Labels.error_server,
            popToRoute: AppRoutes.History,
          });
        }
      }
    } catch (e) {
      const errMsg = e.message ? e.message : e.toString();
      CrashlyticsEvents.log(
        'Exception',
        'ReferralSummaryScreen:fetchReferralSummaryDetails',
        errMsg,
      );
      Events.Error.trackEvent(
        'Exception',
        'ReferralSummaryScreen:fetchReferralSummaryDetails',
        errMsg,
      );
      alertError(errMsg);
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchReferralSummary',
        JSON.stringify({transactionId: this.props.historyItem.Id}),
        JSON.stringify(e),
      );
    }
  }

  renderLabel(isSender: boolean) {
    const label = isSender
      ? Localized.Labels.referral_reward
      : Localized.Labels.signup_reward;
    return (
      <AVText
        style={[
          styles.referral,
          Settings.isCanteen() || Settings.isRefiveAnd365Pay()
            ? {
                fontFamily: Styles.FontFamily.robotoRegular,
                fontSize: 22,
                fontWeight: '700',
                color: '#111',
              }
            : null,
          getSnackDescriber()?.snackDetailsBtnFontSTyle(),
        ]}
      >
        {label}
      </AVText>
    );
  }

  getTitle(isSender: boolean) {
    return isSender
      ? Localized.Labels.referral_reward
      : Localized.Labels.signup_reward;
  }

  renderUser(isSender) {
    const {summary} = this.state;
    const canteenSignUp = Localized.Labels.new_signup;
    //Need to remove name string check after API fix
    const {label, value} = isSender
      ? {
          label:
            Settings.isCanteen() || Settings.isRefiveAnd365Pay()
              ? canteenSignUp
              : Localized.Labels.new_sign_up,
          value:
            summary?.receiverName === null ||
            summary?.receiverName === 'null null'
              ? Localized.Labels.name_not_available
              : summary?.receiverName,
        }
      : {
          label: Localized.Labels.referrer_name,
          value:
            summary?.senderName === null || summary?.senderName === 'null null'
              ? Localized.Labels.name_not_available
              : summary?.senderName,
        };
    return (
      <AVText
        style={[
          styles.user,
          Settings.isCanteen() || Settings.isRefiveAnd365Pay()
            ? {
                fontFamily: Styles.FontFamily.robotoRegular,
                fontSize: 18,
                fontWeight: '400',
                color: '#111',
              }
            : null,
          getSnackDescriber()?.snackDetailsBtnFontSTyle(),
        ]}
      >
        {Localized.Labels.formatString(label, value)}
      </AVText>
    );
  }

  render() {
    const {summary} = this.state;

    if (!summary) {
      return <LoadingScreen />;
    }

    const isSender = summary?.sender === AccountStore.getAccountId();
    return (
      <>
        <BaseScreen
          title={
            Settings.buildType !== 'revolve'
              ? this.getTitle(isSender)
              : 'Reward Summary'
          }
        >
          <View style={styles.purchaseContent}>
            <ScrollView contentContainerStyle={styles.historyContainer}>
              <AVText
                style={[
                  styles.date,
                  getDescriber().referralDate(),
                  getSnackDescriber()?.snackDetailsBtnFontSTyle(),
                ]}
              >
                {Util.formatDate(summary?.transactionDate, 'LLL')}
              </AVText>
              <AVText
                style={[
                  styles.location,
                  getDescriber().referrallocationName(),
                  getSnackDescriber()?.snackDetailsBtnFontSTyle(),
                ]}
              >
                {summary?.locationName ?? Localized.Labels.loc_not_available}
              </AVText>
              <AVText
                style={[
                  styles.transactionId,
                  getDescriber().referralTransactionId(),
                  getSnackDescriber()?.snackDetailsBtnFontSTyle(),
                ]}
              >
                {summary?.transactionId ?? ''}
              </AVText>
              <DashedLine
                dashGap={PixelRatio.roundToNearestPixel(1)}
                dashLength={PixelRatio.roundToNearestPixel(4)}
                dashThickness={PixelRatio.roundToNearestPixel(2)}
                dashColor={'#CFCFCF'}
                style={styles.dashStyle}
              />
              {this.renderLabel(isSender)}
              <DashedLine
                dashGap={PixelRatio.roundToNearestPixel(1)}
                dashLength={PixelRatio.roundToNearestPixel(4)}
                dashThickness={PixelRatio.roundToNearestPixel(2)}
                dashColor={'#CFCFCF'}
                style={styles.dashStyle}
              />
              {this.renderUser(isSender)}
              <View style={[Styles.Style.summarySection, styles.summary]}>
                <View style={[Styles.Style.labelColumn, styles.leftView]}>
                  <AVText
                    style={[
                      styles.boldLabel,
                      getDescriber().referralSummaryTotal(),
                    ]}
                  >
                    {Localized.Labels.total}
                  </AVText>
                </View>
                <View style={[Styles.Style.amountColumn, styles.rightView]}>
                  <AVFormattedCurrency
                    style={[
                      styles.boldLabel,
                      getDescriber().referralSummaryTotal(),
                    ]}
                    value={summary?.amount || 0}
                    accessibilityLabel="total"
                    currency={
                      this.props.historyItem.currency ??
                      AccountStore.getCurrency()
                    }
                  />
                </View>
              </View>
            </ScrollView>
          </View>
        </BaseScreen>
        <RoundedAppButton
          buttonType={RoundedAppButtonType.Solid}
          titleText={getDescriber().checkEmailreceiptBtnTxt()}
          onPress={() => this.handleEmailReferralReceipt(isSender)}
          buttonContainerStyle={{
            alignSelf: 'flex-end',
            right: Styles.Spacing.m3,
            bottom: 22,
          }}
          buttonViewStyle={[
            {
              paddingHorizontal: Styles.Spacing.m4,
              paddingVertical: Styles.Spacing.m2 + Styles.Spacing.m1,
              minHeight: 42,
            },
          ]}
          textStyle={[
            {
              fontWeight: Platform.OS === 'android' ? 'bold' : '700',
              fontSize: Styles.Fonts.f1,
              color: Styles.white,
            },
            getSnackDescriber()?.snackDetailsBtnFontSTyle(),
          ]}
          accessibilityLabelValue={getDescriber().checkEmailreceiptBtnTxt()}
          aria-label={getDescriber().checkEmailreceiptBtnTxt()}
        />

        {this.state.showModal && (
          <View>
            <SuccessDialog
              title={getDescriber().checkEmailSuccessMsg()}
              desc="Desc"
            ></SuccessDialog>
          </View>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  date: {
    fontSize: Styles.Fonts.f0,
    fontWeight: 'bold',
    paddingBottom: Styles.Spacing.m2,
  },
  location: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  purchaseContent: {
    flex: 1,
  },
  historyContainer: {
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  transactionId: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
  },
  boldLabel: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f3,
    fontWeight: '300',
    marginBottom: Styles.Spacing.m1,
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    overflow: 'hidden',
    width: '100%',
  },
  referral: {
    fontSize: Styles.Fonts.f2,
  },
  user: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
    fontWeight: 'bold',
  },
  summary: {
    marginTop: Styles.Spacing.m4,
    marginBottom: Styles.Spacing.m1,
  },
  summaryLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginBottom: Styles.Spacing.m1,
  },
  leftView: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: -1 * Styles.Spacing.m2,
  },
  rightView: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
});
export default withForwardedNavigationParams<ReferralSummaryScreenProps>()(
  withGlobalize(ReferralSummaryScreen),
);
