import PersistentStore from 'src/services/PersistentStoreService';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import ActionsFactory from 'src/actions/ActionsFactory';
import type {ScreenProps} from 'src/types/Screen';
import {alertSuccess, alertError} from '../helpers/AlertHelper';

class DealHelper {
  acceptingDeal = false;

  async claimDeal(props: ScreenProps, dealId: string) {
    if (!this.acceptingDeal) {
      try {
        props.actions.showSpinner();
        this.acceptingDeal = true;
        const hasSeenClaimDealMessage =
          await PersistentStore.hasSeenClaimDealMessage(
            AccountStore.getUsername(),
          );

        if (!hasSeenClaimDealMessage) {
          alertSuccess(
            Localized.Labels.claim_deal_message,
            undefined,
            Localized.Labels.claim_deal_title,
          );
          PersistentStore.setSeenClaimDealMessage(AccountStore.getUsername());
        }

        await ActionsFactory.getAccountActions().acceptMoblicoDeal(
          AccountStore.getEmail(),
          AccountStore.getAccountId(),
          dealId,
        );
      } catch (error) {
        if (error) {
          alertError(Localized.Errors.deal_already_claimed);
        }
      } finally {
        this.acceptingDeal = false;
        props.actions.hideSpinner();
      }
    }
  }
}

export default new DealHelper();
