import * as React from 'react';
import {StyleSheet, StyleProp, View, ViewStyle} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import Styles from '../Styles';
type SafeAreaViewProps = {
  style?: StyleProp<ViewStyle>;
  forceInset?: {
    top?: 'never';
    bottom?: 'never';
    left?: 'never';
    right?: 'never';
  };
  backgroundStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  setPaddingBottomsafeArea?: boolean;
};
export default function SafeAreaView(props: SafeAreaViewProps) {
  const insets = useSafeAreaInsets();
  const style = {
    paddingTop: insets.top,
    paddingRight: insets.right,
    paddingBottom: insets.bottom,
    paddingLeft: insets.left,
  };

  if (props.forceInset) {
    if (props.forceInset.top === 'never') {
      style.paddingTop = 0;
    }

    if (props.forceInset.bottom === 'never') {
      style.paddingBottom = 0;
    }

    if (props.forceInset.left === 'never') {
      style.paddingLeft = 0;
    }

    if (props.forceInset.right === 'never') {
      style.paddingRight = 0;
    }
  }

  return (
    <View style={[styles.background, props.backgroundStyle]}>
      <View
        {...props}
        style={[
          style,
          props.style,
          props.setPaddingBottomsafeArea && {paddingBottom: insets.bottom},
        ]}
      />
    </View>
  );
}
const styles = StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: Styles.white,
  },
});
