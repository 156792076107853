import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const SearchIcon = (props) => (
  <Svg width={22} height={23} viewBox="0 0 22 23" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0236 17.736L15.975 13.636C16.6338 12.4857 16.9776 11.2065 16.9776 9.91777C16.9776 5.73657 13.5783 2.33337 9.40554 2.33337C5.2328 2.33337 1.8335 5.73657 1.8335 9.91777C1.8335 14.099 5.2328 17.5022 9.40554 17.5022C10.7376 17.5022 12.0553 17.1346 13.2298 16.433L17.2593 20.5187C17.35 20.6094 17.4789 20.6667 17.6078 20.6667C17.7367 20.6667 17.8656 20.6142 17.9563 20.5187L20.0236 18.4281C20.2146 18.2324 20.2146 17.927 20.0236 17.736ZM9.40554 5.2879C11.955 5.2879 14.0271 7.36418 14.0271 9.91777C14.0271 12.4714 11.955 14.5476 9.40554 14.5476C6.85606 14.5476 4.78401 12.4714 4.78401 9.91777C4.78401 7.36418 6.85606 5.2879 9.40554 5.2879Z"
      fill="#707070"
    />
  </Svg>
);
export default SearchIcon;
