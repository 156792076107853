import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const LeftArrow = () => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <Path
      d="M5.203 11.203a1.12 1.12 0 000 1.589l9 9.005a1.125 1.125 0 001.59-1.59l-8.204-8.203 8.208-8.207a1.125 1.125 0 00-1.59-1.59l-9.004 8.996z"
      fill="#fff"
    />
  </Svg>
);
export default LeftArrow;
