import * as React from 'react';
import {StyleSheet, View, ScrollView, Image, Dimensions} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import ScreenContext from '../../ScreenContext';
import BackSubheader from '../../elements/BackSubheader';
import Styles from '../../Styles';
import DealRepository, {DealStatus} from 'src/services/aws/DealRepository';
import Events, {PromotionActions} from 'src/logging/Events';
import AVText from '../../elements/AVText';
import Localized from 'src/constants/AppStrings';
import Deal from 'src/models/Moblico/Deal';
import {ensureHttps} from 'src/services/StringUtils';
import Util from 'src/Util';
import DealHelper from 'src/components/helpers/DealHelper';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from 'src/redux/store';
import {
  markSingleMessageAsRead,
  NotificationMessageParams,
} from 'src/redux/thunks/notificationThunks/messageRead';
import AccountStore from 'src/stores/AccountStore';
import {getDescriber} from './descriptor/DescriptorType';

type DealDetailScreenProps = WithGlobalizeProps & {
  deal: Deal;
  loading: boolean;
  markMessageAsRead(params: NotificationMessageParams): void;
};
type DealDetailScreenState = {
  deal: Deal;
  loading: boolean;
};

const width = Dimensions.get('window').width;

class DealDetailScreen extends React.Component<
  DealDetailScreenProps,
  DealDetailScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: DealDetailScreenProps) {
    super(props);
    this.state = {
      deal:
        DealRepository.getDeal(String(props.deal.externalServiceId)) ??
        props.deal,
      loading: false,
    };
    this.applyDeal = this.applyDeal.bind(this);
    this.renderApplyButton = this.renderApplyButton.bind(this);
    this.onDealsChanged = this.onDealsChanged.bind(this);
  }

  componentDidMount() {
    const locationId = AccountStore.getLocationId();
    DealRepository.addDealListener(this.onDealsChanged);
    this.props.markMessageAsRead({
      externalServiceId: String(this.props.deal.externalServiceId),
      locationId: locationId,
      messageType: 'Deal',
    });

    Events.Promotion.trackEvent(
      this.state.deal.externalServiceId.toString(),
      this.state.deal.name,
      PromotionActions.ViewedDeal,
      true,
    );
  }

  componentWillUnmount() {
    DealRepository.removeDealListener(this.onDealsChanged);
  }

  onDealsChanged() {
    const deal = DealRepository.getDeal(
      this.state.deal.externalServiceId as string,
    );

    if (deal) {
      this.setState({
        deal,
      });
    }
  }

  async applyDeal() {
    DealHelper.claimDeal(
      this.context,
      this.state.deal.externalServiceId as string,
    );
    FirebaseAnalytic.trackEvent('ApplyNow', 'DealDetailScreen: ApplyDeal', {
      ...this.props,
      ...this.state,
    });
  }

  dealAppliedStatus() {
    return (
      this.state.deal.dealStatus ===
      (DealStatus.accepted || DealStatus.redeemed)
    );
  }

  dealNotAppliedStatus() {
    return this.state.deal.dealStatus === DealStatus.pulled;
  }

  renderApplyButton() {
    if (this.dealNotAppliedStatus()) {
      return getDescriber().dealApplyBtn(this.applyDeal);
    } else if (this.dealAppliedStatus()) {
      return getDescriber().dealAppliedBtn();
    }
    return null;
  }

  render() {
    let uri = this.state.deal.image;

    if (typeof uri === 'string') {
      // force HTTPS
      uri = ensureHttps(uri);
    }

    const dealEndDate = Util.formatDate(
      this.state.deal.endDate,
      'MMM DD, YYYY',
    );
    const dealEndTime = Util.formatDate(this.state.deal.endDate, 'hh:mm A');
    return (
      <BackSubheader
        style={getDescriber() && getDescriber().backHeaderStyleDescriptor()}
        title={Localized.Labels.offers}
      >
        <View style={getDescriber().getDealDetailStyleDescriptor()['content']}>
          <ScrollView contentContainerStyle={styles.falseBottomPadding}>
            <View
              style={getDescriber().getDealDetailStyleDescriptor()['topView']}
            >
              {this.state.deal.image ? (
                <View style={styles.imageView}>
                  <Image
                    source={{uri: ensureHttps(this.state.deal.image)}}
                    style={
                      getDescriber().getDealDetailStyleDescriptor()['image']
                    }
                  />
                </View>
              ) : (
                <View
                  style={
                    getDescriber().getDealDetailStyleDescriptor()['iconView']
                  }
                >
                  {getDescriber().dealDefaultIconDescriptor()}
                </View>
              )}
              <View
                style={
                  getDescriber().getDealDetailStyleDescriptor()['dealTitleRow']
                }
              >
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                  accessibilityLabel={this.state.deal.name}
                  style={[
                    getDescriber().getDealDetailStyleDescriptor()['name'],
                  ]}
                >
                  {this.state.deal.name}
                </AVText>
              </View>
            </View>
            {getDescriber().getDealEndDateTime(
              '' + dealEndDate + ' at ' + dealEndTime,
            )}

            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
              style={
                getDescriber().getDealDetailStyleDescriptor()['description']
              }
              accessibilityLabel={this.state.deal.description}
            >
              {this.state.deal.description}
            </AVText>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
              style={
                getDescriber().getDealDetailStyleDescriptor()['validUntil']
              }
            >
              {`${getDescriber().dealValidityKey()} ${Util.formatDate(
                this.state.deal.endDate,
                'MM/DD/YYYY',
              )}`}
            </AVText>
            {this.state.deal.numberOfUsesPerCode > 0 && (
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
                style={
                  getDescriber().getDealDetailStyleDescriptor()['redeemLimit']
                }
              >
                {Localized.Labels.formatString(
                  Localized.Labels.offers_redemption_limit,
                  this.state.deal.numberOfUsesPerCode,
                )}
              </AVText>
            )}
            {this.dealAppliedStatus() && getDescriber().dealAppliedContainer()}
          </ScrollView>
          {this.renderApplyButton()}
        </View>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    padding: Styles.Spacing.m3,
    flex: 1,
    backgroundColor: Styles.tabBarBackgroundColor,
  },
  falseBottomPadding: {
    paddingBottom: 60,
  },
  topView: {
    marginTop: Styles.Spacing.m3,
  },
  imageView: {
    marginLeft: Styles.Spacing.m0,
  },
  icon: {
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  redeemLimit: {
    marginTop: Styles.Spacing.m3,
    fontSize: Styles.Fonts.f8,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.figtreeRegular,
    alignSelf: 'center',
    color: Styles.Colors.PayNew.darkHuesBase04,
  },
  name: {
    color: Styles.black,
    fontSize: Styles.Fonts.sectionHeader,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: '700',
    flex: 1,
  },
  fromContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  message: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  qrcode: {
    alignItems: 'center',
    marginBottom: Styles.Spacing.m3,
  },
  topRow: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: Styles.Spacing.m2 + Styles.Spacing.m1,
  },
  trashContainer: {
    marginBottom: Styles.Spacing.m1,
  },
  image: {
    width: width / 2.4,
    height: width / 2.4,
    borderRadius: Styles.Spacing.m2,
  },
  statusText: {
    fontSize: Styles.Fonts.f1,
    marginTop: Styles.Spacing.m2,
    textAlign: 'center',
    marginBottom: Styles.Spacing.m1,
  },
  statusSection: {
    marginBottom: Styles.Spacing.m3,
  },
  statusProgressContainer: {
    height: Styles.Spacing.m2,
    backgroundColor: Styles.lightGray,
    borderRadius: Styles.Spacing.m2,
    flexDirection: 'row',
  },
  successText: {
    color: Styles.positiveColor,
    fontWeight: 'bold',
  },
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  markMessageAsRead: (params) => dispatch(markSingleMessageAsRead(params)),
});

const mapStateToProps = (state: RootState) => ({
  loading: state.snack.loading,
});

export default compose(
  withForwardedNavigationParams<DealDetailScreenProps>(),
  withGlobalize,
  connect(mapStateToProps, mapDispatchToProps),
)(DealDetailScreen);
