import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, Rect} from 'react-native-svg';

interface Props {
  size?: number;
  color?: string;
}
function QRIconSVG({size = 40, color = '#707070'}: Props) {
  return (
    <Svg width={size} height={size} viewBox="0 0 40 40" fill="none">
      <G clipPath="url(#clip0_10109_738)">
        <Path transform="translate(8 8)" fill="#fff" d="M0 0H24V24H0z" />
        <G clipPath="url(#clip1_10109_738)">
          <Path
            d="M16.387 11.75H12.13a.7.7 0 00-.71.688v4.124a.7.7 0 00.71.688h4.258c.39 0 .71-.31.71-.688v-4.125a.701.701 0 00-.71-.687zm-4.258-1.375h4.258c1.175 0 2.13.924 2.13 2.063v4.124c0 1.14-.954 2.063-2.13 2.063H12.13c-1.175 0-2.129-.924-2.129-2.063v-4.125c0-1.138.954-2.062 2.129-2.062zm4.258 12.375H12.13a.7.7 0 00-.71.688v4.125a.7.7 0 00.71.687h4.258c.39 0 .71-.31.71-.688v-4.125a.701.701 0 00-.71-.687zm-4.258-1.375h4.258c1.175 0 2.13.924 2.13 2.063v4.125c0 1.138-.954 2.062-2.13 2.062H12.13c-1.175 0-2.129-.924-2.129-2.063v-4.125c0-1.138.954-2.062 2.129-2.062zm11.355-9.625a.7.7 0 00-.71.688v4.124a.7.7 0 00.71.688h4.258a.7.7 0 00.71-.688v-4.125a.7.7 0 00-.71-.687h-4.258zm-2.13.688c0-1.14.954-2.063 2.13-2.063h4.258c1.175 0 2.129.924 2.129 2.063v4.124c0 1.14-.954 2.063-2.13 2.063h-4.257c-1.176 0-2.13-.924-2.13-2.063v-4.125zm0 9.624c0-.378.32-.687.71-.687h2.84c.39 0 .709.31.709.688v2.921h2.839v-2.922c0-.378.319-.687.71-.687.39 0 .709.31.709.688v3.609c0 .378-.32.687-.71.687h-4.258c-.39 0-.71-.309-.71-.687V22.75h-1.419v6.531c0 .378-.32.688-.71.688-.39 0-.71-.31-.71-.688v-7.218zm-7.45-8.593h.709a.7.7 0 01.71.687v.688a.7.7 0 01-.71.687h-.71a.7.7 0 01-.71-.687v-.688a.7.7 0 01.71-.687zm-.71 11.687a.7.7 0 01.71-.687h.709a.7.7 0 01.71.687v.688a.7.7 0 01-.71.687h-.71a.7.7 0 01-.71-.687v-.688zM25.258 13.47h.71a.7.7 0 01.71.687v.688a.7.7 0 01-.71.687h-.71a.7.7 0 01-.71-.687v-.688a.7.7 0 01.71-.687zM24.194 28.25c0-.378.319-.688.71-.688h.709c.39 0 .71.31.71.688v.688c0 .378-.32.687-.71.687h-.71c-.39 0-.71-.31-.71-.688v-.687zm4.258-.688h.71c.39 0 .709.31.709.688v.688c0 .378-.32.687-.71.687h-.71c-.39 0-.71-.31-.71-.688v-.687c0-.378.32-.688.71-.688z"
            fill={color}
          />
        </G>
      </G>
      <Defs>
        <ClipPath id="clip0_10109_738">
          <Rect width={size} height={size} rx={20} fill="#fff" />
        </ClipPath>
        <ClipPath id="clip1_10109_738">
          <Path fill="#fff" transform="translate(10 9)" d="M0 0H19.871V22H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default QRIconSVG;
