import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IProps {
  size?: number;
}

function ShopIcon({size = 32}: IProps) {
  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 28 29">
      <Path
        fill="#22212D"
        fillRule="evenodd"
        d="M14 19.167H7v-5.834H4.665v9.334c0 .645.521 1.166 1.167 1.166h9.333c.645 0 1.166-.521 1.166-1.166v-9.334H14v5.834zm11.469-8.816l-3.11-4.667a1.173 1.173 0 00-.973-.517H6.613c-.39 0-.755.193-.97.517l-3.11 4.667a1.167 1.167 0 00.97 1.816h20.996c.93 0 1.484-1.04.97-1.816zm-4.47 12.899c0 .32.263.583.584.583h1.166c.321 0 .584-.262.584-.583v-9.917h-2.334v9.917z"
        clipRule="evenodd"
      ></Path>
    </Svg>
  );
}

export default ShopIcon;
