import {createRef} from 'react';
import {
  CommonActions,
  createNavigationContainerRef,
  StackActions,
} from '@react-navigation/native';
import AppRoutes from 'src/AppRoutes';

class NavActions {
  navigatorRef =
    createNavigationContainerRef<{[key in AppRoutes]: undefined}>();
  // used to wait for AppNavigator to finish mounting
  // https://reactnavigation.org/docs/navigating-without-navigation-prop/#handling-initialization
  isReadyRef = createRef();

  getLastRoute(): AppRoutes {
    const navigator = this.navigatorRef.current;
    if (!navigator || !this.isReadyRef.current) {
      return AppRoutes.Empty;
    }

    return this.getCurrentRoute(navigator.getRootState());
  }

  getCurrentRoute(rootState: any): AppRoutes {
    if (!rootState) {
      return AppRoutes.Empty;
    }

    const route = rootState.routes[rootState.index];

    if (route.state) {
      return this.getCurrentRoute(route.state);
    }

    return route.name;
  }

  push(name: AppRoutes, params?: any, key?: string) {
    return this.navigate(name, params, key);
  }

  navigate(name: AppRoutes, params?: any, key?: string) {
    const navigator = this.navigatorRef?.current;
    if (navigator && this.isReadyRef.current) {
      navigator.dispatch(
        CommonActions.navigate({
          name,
          params,
          key,
        }),
      );
    }
  }

  nestedPush(nestedNames: Array<AppRoutes>, name?: AppRoutes, params?: any) {
    const navigator = this.navigatorRef.current;

    if (navigator && this.isReadyRef.current) {
      const nestedInfo = nestedNames.reduceRight(
        (previous, current) => ({
          screen: current,
          initial: false,
          params: previous,
        }),
        {
          screen: name,
          initial: false,
          params,
        },
      );
      this.navigate(nestedInfo.screen ?? AppRoutes.Empty, nestedInfo.params);
    }
  }

  pop() {
    const navigator = this.navigatorRef.current;

    if (!navigator || !this.isReadyRef.current) {
      return false;
    }

    if (!navigator.canGoBack()) {
      return false;
    }

    navigator.dispatch(CommonActions.goBack());
    return true;
  }

  popToRoute(name: AppRoutes, params?: any): boolean {
    const navigator = this.navigatorRef.current;

    if (!navigator || !name || !this.isReadyRef.current) {
      return false;
    }

    navigator.dispatch(CommonActions.navigate({name, params}));
    return true;
  }

  popToTop(
    options: {
      immediate?: boolean;
    } = {
      immediate: true,
    },
  ) {
    const navigator = this.navigatorRef.current;

    if (navigator && this.isReadyRef.current) {
      navigator.dispatch(StackActions.popToTop());
    }
  }

  reset(name: AppRoutes, params?: any) {
    const navigator = this.navigatorRef.current;

    if (navigator && this.isReadyRef.current) {
      navigator.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name,
              params,
            },
          ],
        }),
      );
    }
  }

  resetMultiple(names: Array<AppRoutes>, params?: any) {
    const navigator = this.navigatorRef.current;

    if (navigator && this.isReadyRef.current) {
      navigator.dispatch(
        CommonActions.reset({
          index: names.length - 1,
          routes: names.map((name) => ({
            name,
            params,
          })),
        }),
      );
    }
  }

  replace(routeName: AppRoutes, params?: any) {
    const navigator = this.navigatorRef.current;

    if (navigator && this.isReadyRef.current) {
      navigator.dispatch(StackActions.replace(routeName, params));
    }
  }
}

export default new NavActions();
