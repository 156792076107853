import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

function RefiveLocationMarker(props) {
  return (
    <Svg width={13} height={19} viewBox="0 0 13 19" fill="none" {...props}>
      <G clipPath="url(#clip0_15564_987)">
        <Path
          d="M12.888 6.486A6.153 6.153 0 101.442 9.62l5.294 9.046 5.295-9.045c.545-.919.858-1.99.858-3.136zm-6.152 2.66a2.66 2.66 0 110-5.32 2.66 2.66 0 010 5.32z"
          fill="#A4DB57"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_15564_987">
          <Path
            fill="#fff"
            transform="translate(.583 .333)"
            d="M0 0H12.3051V18.3333H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default RefiveLocationMarker;
