import {HomeLocationProps} from 'src/components/elements/descriptor/DescriptorType';
import Styles from '../../../Styles';
import {Dimensions, View} from 'react-native';
import RefiveLocationMarker from 'src/components/img/svg/RefiveLocationMarker';
import AVText from 'src/components/elements/AVText';
import FontAwesome5Pro from 'react-native-vector-icons/FontAwesome5Pro';
import React from 'react';
import NotificationUnReadIconRefive from 'src/components/img/svg/newui/notificationUnReadLogoRefive';
import NotificationReadIconRefive from 'src/components/img/svg/newui/notificationReadLogoRefive';
import Localized from 'src/constants/AppStrings';
import GreetingsAndBalanceView from 'src/components/elements/home/GreetingsAndBalanceView';
import RefiveHomeIcon from 'src/components/img/svg/RefiveHomeIcon';
import {RewardsWrappedComponent} from 'src/components/screens/home/RewardsHoc';

const width = Dimensions.get('window').width;

export function getCampusLocationStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    linkLocationBtnStyle: {
      fontSize: Styles.Fonts.f1 + 2,
      fontWeight: '700',
      color: '#3070ED',
      textDecorationLine: 'none',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    linkView: {
      alignSelf: 'flex-start',
      borderBottomColor: '#066DFF',
      borderBottomWidth: 1.5,
    },
    descriptionText: {
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
  };
}

export function backGroundColorHomeScreen(): string {
  return Styles.white;
}
export function locationHeaderName(): string {
  return Localized.Labels.locations.toUpperCase();
}

export function linkLocationDropDown(props?: HomeLocationProps): JSX.Element {
  return (
    <>
      <View style={homeLocationHeaderStyleDescriptor()['locationMainView']}>
        <RefiveLocationMarker />
      </View>
      <AVText
        style={[
          homeLocationHeaderStyleDescriptor()['campusNameText'],
          homeLocationHeaderStyleDescriptor()['locationText365'],
        ]}
        numberOfLines={1}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
      >
        {props.locationNameText}
      </AVText>
      <FontAwesome5Pro
        accessible={true}
        accessibilityLabel={
          'Down arrow icon, Double tap to the Locations Screen'
        }
        aria-label={'Down arrow icon, Double tap to the Locations Screen'}
        name="chevron-down"
        color={Styles.Colors.PayNew.white01}
        size={Styles.Heights.touchTargetHeight0}
        style={[homeLocationHeaderStyleDescriptor()['campusArrow']]}
      />
    </>
  );
}
export function greetingBalanceRewardsView(_data?: {
  [index: string]: {[index: string]: number | string};
}): JSX.Element {
  return (
    <>
      <View style={[homeLocationHeaderStyleDescriptor()['homeRefiveLogo']]}>
        <RefiveHomeIcon width={width - Styles.Spacing.m3 * 2} />
      </View>
      <GreetingsAndBalanceView />
      <RewardsWrappedComponent data={_data} />
    </>
  );
}
export function readUnreadNotificationIcon(
  props?: HomeLocationProps,
): JSX.Element {
  return (
    <>
      <View style={homeLocationHeaderStyleDescriptor()['locationMainView']}>
        {props.readOrUnreadNotificaton ? (
          <NotificationUnReadIconRefive />
        ) : (
          <NotificationReadIconRefive />
        )}
      </View>
    </>
  );
}

export function getScanProductName(): string {
  return Localized.Labels.scan_product_capital;
}

export function getScanProductBtnStyle(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    applyButtonText: {
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.f7,
      textAlign: 'right',
      fontWeight: '700',
    },
  };
}

export function homeLocationHeaderStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    campusNameText: {
      marginLeft: Styles.Spacing.m2 - 2,
      fontSize: Styles.Fonts.sectionHeader,
      maxWidth: '100%',
      color: Styles.white,
      fontFamily: Styles.FontFamily.comodoVintage,
    },
    campusArrow: {
      marginLeft: Styles.Spacing.m2,
      marginTop: 2,
    },
    locationMainView: {
      marginTop: 2,
    },
    locationText365: {
      color: Styles.white,
      fontFamily: Styles.FontFamily.comodoVintage,
      fontSize: Styles.Fonts.sectionHeader,
    },
    homeRefiveLogo: {
      marginTop: Styles.Spacing.m3,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: Styles.Spacing.m1,
    },
  };
}

export default {
  getScanProductName,
  getScanProductBtnStyle,
  getCampusLocationStyles,
  homeLocationHeaderStyleDescriptor,
  linkLocationDropDown,
  backGroundColorHomeScreen,
  greetingBalanceRewardsView,
  readUnreadNotificationIcon,
  locationHeaderName,
};
