import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import StackTransitionPreset from '../StackTransitionPreset';
import CreditCardDetailScreen from 'src/components/screens/funding/CreditCardDetailScreen';
import CreditCardScreen from 'src/components/screens/funding/CreditCardScreen';
import CreditCardStripeScreen from 'src/components/screens/funding/CreditCardStripeScreen';
import CardsScreen from 'src/components/screens/funding/CreditCardsScreen';
import PayrollDeductScreen from 'src/components/screens/funding/PayrollDeductScreen';
import PayrollDeductAgreementScreen from 'src/components/screens/funding/PayrollDeductAgreementScreen';
import AddEGiftCard from 'src/components/screens/funding/AddEGiftCard';
import AddEGiftCardManual from 'src/components/screens/funding/AddEGiftCardManual';

const WalletStack = createStackNavigator();

const screenComponents = [
  {
    name: AppRoutes.Wallet,
    component: CardsScreen,
    initialParams: {hideBackArrow: true},
  },
  {name: AppRoutes.CreditCard, component: CreditCardScreen},
  {name: AppRoutes.CreditCardDetail, component: CreditCardDetailScreen},
  {name: AppRoutes.CreditCardStripe, component: CreditCardStripeScreen},
  {name: AppRoutes.AddEGiftCard, component: AddEGiftCard},
  {name: AppRoutes.PayrollDeduct, component: PayrollDeductScreen},
  {
    name: AppRoutes.PayrollDeductAgreement,
    component: PayrollDeductAgreementScreen,
  },
  {name: AppRoutes.AddEGiftCardManual, component: AddEGiftCardManual},
];

const WalletNavigator = () => {
  return (
    <WalletStack.Navigator
      initialRouteName={AppRoutes.Cards}
      screenOptions={{
        ...StackTransitionPreset,
        headerShown: false,
      }}
    >
      {screenComponents.map((screen, index) => (
        <WalletStack.Screen
          key={index}
          name={screen.name}
          component={screen.component}
          initialParams={screen.initialParams}
        />
      ))}
    </WalletStack.Navigator>
  );
};

export default WalletNavigator;
