import RedesignDescriptor from './sendSnack-redesign';
import RefiveDescriptor from './sendSnack-refive';
import CanteenDescriptor from './sendSnack-canteen';
import CompanyKitchenDescriptor from './sendSnack-companykitchen';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';

const typeDescriber = {
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
};

export interface SendSnackDescriberData {
  styles: {[index: string]: {[index: string]: number | string}};
  snackDate?: string;
  isSender?: boolean;
  stickerId?: number;
  amount?: number;
  senderName?: string;
  status?: string;
}

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
