import RedesignDescriptor from './vending-redesign-descriptor';
import CompanyKitchenDescriptor from './vending-companykitchen-descriptor';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import {StyleProp, ViewStyle, TextStyle} from 'react-native';

const typeDescriber = {
  [BuildTypes.canteen]: RedesignDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
  [BuildTypes.refive]: RedesignDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}

export interface VendingStylesDescriptor {
  balance: StyleProp<TextStyle>;
  balanceLabel: StyleProp<TextStyle>;
  timer: StyleProp<TextStyle>;
  timerLabel: StyleProp<TextStyle>;
  locationName: StyleProp<TextStyle>;
  messageText: StyleProp<TextStyle>;
  progressContainer: StyleProp<ViewStyle>;
  messageContainer: StyleProp<ViewStyle>;
}
