// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import * as React from 'react';
import Svg, {Circle, Path} from 'react-native-svg';

function NotificationLogo(props) {
  return (
    <Svg
      width={props.size}
      height={props.size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.8984 19.4306C21.7108 19.2582 20.1867 17.711 20.1867 12.8291C20.1509 11.1602 19.5294 9.55582 18.4292 8.29207C17.8387 7.59232 17.1077 7.02215 16.2827 6.61789C16.2864 6.57714 16.2864 6.53614 16.2827 6.49539C16.2827 5.11722 15.1557 4 13.7655 4C12.3752 4 11.2482 5.11722 11.2482 6.49539C11.2445 6.53614 11.2445 6.57714 11.2482 6.61789C10.4232 7.02215 9.69221 7.59232 9.10173 8.29207C8.00151 9.55582 7.38004 11.1602 7.34425 12.8291C7.34425 17.702 5.82476 19.2446 5.63711 19.417C5.12219 19.7223 4.88264 20.3357 5.05586 20.9051C5.23861 21.5017 5.80013 21.9044 6.42889 21.8897H10.905C10.6527 22.1511 10.6527 22.563 10.905 22.8243C11.6433 23.576 12.6568 24 13.7151 24C14.7735 24 15.7869 23.576 16.5253 22.8243C16.7605 22.5668 16.7605 22.1744 16.5253 21.9169H21.0609C21.6896 21.9316 22.2511 21.5289 22.4339 20.9324C22.6211 20.3693 22.4009 19.752 21.8984 19.4306ZM13.7792 5.34296C14.2583 5.38347 14.6731 5.68921 14.8502 6.13242C14.1438 5.98662 13.4146 5.98662 12.7082 6.13242C12.8853 5.68921 13.3001 5.38347 13.7792 5.34296ZM13.7792 7.38467C16.6809 7.38467 18.8137 10.2884 18.8137 12.8292C18.7879 14.3584 18.968 15.8842 19.3491 17.3662H8.20927C8.5904 15.8842 8.77048 14.3584 8.74475 12.8292C8.74475 10.2884 10.8775 7.38467 13.7792 7.38467ZM13.7472 22.6474C13.0597 22.6547 12.3988 22.3844 11.9164 21.8988H15.5321C15.0612 22.3736 14.4189 22.643 13.7472 22.6474ZM6.45636 20.5422C7.02883 20.054 7.46667 19.4295 7.7287 18.7274H19.8343C20.0963 19.4295 20.5341 20.054 21.1066 20.5422H6.45636Z"
        fill={props?.color ?? '#727179'}
      />
      <Circle
        cx="14"
        cy="14"
        r="13.25"
        stroke={props?.color ?? '#727179'}
        stroke-width="1.5"
      />
    </Svg>
  );
}

const NotificationIcon = React.memo(NotificationLogo);
export default NotificationIcon;
