import React, {useContext} from 'react';
import {View, StyleSheet, Platform} from 'react-native';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../ScreenContext';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import Styles from '../Styles';
import ActionsFactory from 'src/actions/ActionsFactory';
import Localized from 'src/constants/AppStrings';
import PinControl from '../elements/PinControl';
import AccountStore from 'src/stores/AccountStore';
import {alertError} from '../helpers/AlertHelper';
import AppRoutes from 'src/AppRoutes';
import NavActions from '../../actions/NavActions';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';

function PasscodeScreen() {
  const context = useContext(ScreenContext);
  let remainingTries = 4;

  async function onPinEntered(enteredPin: string): Promise<boolean> {
    FirebaseAnalytic.trackEvent('onPinEntered', 'PasscodeScreen', {
      enteredPin,
    });
    let response;
    try {
      response = await ActionsFactory.getAccountActions().verifyPin(
        AccountStore.getAccountId(),
        enteredPin,
        AccountStore.getQRValue(),
      );
      FirebaseAnalytic.trackEvent('onPinEntered response', 'PasscodeScreen', {
        enteredPin,
        response,
      });
      if (response) {
        if (Platform.OS === 'web') {
          context?.actions.changeRootRoute(AppRoutes.App);
          NavActions.replace(AppRoutes.MainConsumer);
        } else {
          context?.actions.changeRootRoute(AppRoutes.AppProductTour);
        }
        return false;
      } else {
        remainingTries -= 1;

        if (remainingTries > 0) {
          alertError(
            `${remainingTries} ${Localized.Errors.chances_left_to_enter_pin}`,
          );
          return true;
        } else {
          await ActionsFactory.getAccountActions().logout();
          context?.actions.changeRootRoute(AppRoutes.Login);
          return false;
        }
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      alertError(Localized.Errors.must_have_internet_to_confirm_pin, guid);
      CrashlyticsEvents.log(
        'Exception',
        'PasscodeScreen:PinEntered',
        generateErrorMessage(error),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'PasscodeScreen:PinEntered',
        generateErrorMessage(error),
        guid,
      );
      return true;
    }
  }

  return (
    <View style={styles.pin}>
      <PinControl
        label={Localized.Labels.enter_your_pin}
        onPinEntered={onPinEntered}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  pin: {
    backgroundColor: Styles.white,
    flex: 1,
  },
});
export default PasscodeScreen;
