import * as React from 'react';
import Svg, {Path, Mask, G} from 'react-native-svg';

function CloseEyeIcon(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.02c1.687 0 3.31.52 4.96 1.59 1.096.709 2.068 1.575 2.889 2.396-.93.865-1.987 1.776-3.17 2.507l1.104 1.104c1.679-1.095 3.078-2.47 4.217-3.557-2.313-2.526-5.59-5.56-10-5.56-1.008 0-1.955.16-2.871.463l1.237 1.236A7.528 7.528 0 0112 8.021zM12 15.98c-1.679 0-3.136-.423-4.587-1.331-1.1-.69-2.144-1.621-3.213-2.64 1.104-1.016 2.131-1.856 3.143-2.5L6.246 8.412C4.873 9.328 3.496 10.544 2 12c3.009 2.91 5.536 5.5 10 5.5a9.29 9.29 0 002.872-.461l-1.24-1.24a7.564 7.564 0 01-1.632.18z"
        fill="#707070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.271 15.437l-1.294-1.294-2.12-2.12-1.294-1.294a3.664 3.664 0 004.709 4.709zM10.729 8.563l1.294 1.294 2.12 2.12 1.294 1.294a3.664 3.664 0 00-4.709-4.709z"
        fill="#707070"
      />
      <Mask
        id="a"
        maskUnits="userSpaceOnUse"
        x={3}
        y={3}
        width={18}
        height={18}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.2 3.2h17.6v17.6H3.2V3.2z"
          fill="#fff"
        />
      </Mask>
      <G mask="url(#a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.2 4.291l16.51 16.51 1.09-1.092L4.291 3.2l-1.09 1.091z"
          fill="#707070"
        />
      </G>
    </Svg>
  );
}

export default CloseEyeIcon;
