import * as React from 'react';
import Svg, {Defs, LinearGradient, Path, Stop} from 'react-native-svg';

type GrpReferralRedesignIconProps = {
  width: number;
  height: number;
  color?: string;
  gradColor1: string;
  gradColor2: string;
  style?: Record<string, unknown>;
};

const GrpReferralRedesign = (props?: GrpReferralRedesignIconProps) => (
  <Svg
    width={props.width}
    height={props.height}
    viewBox="0 0 56 53"
    fill="none"
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.77 35.401a7.525 7.525 0 00-7.181 9.782l-2.051.646a9.716 9.716 0 01-.444-2.903c0-5.343 4.332-9.674 9.674-9.674 1.9 0 3.674.548 5.17 1.496l1.28-1.281.86.86v3.87h-3.87l-.859-.86 1.02-1.02a7.49 7.49 0 00-3.6-.915v-.001zm7.524 7.525a7.5 7.5 0 00-.344-2.258l2.051-.645c.294.94.444 1.918.444 2.903 0 5.343-4.332 9.674-9.674 9.674a9.63 9.63 0 01-5.17-1.496l-1.28 1.281-.86-.86v-3.87h3.87l.859.86-1.02 1.02a7.525 7.525 0 0011.125-6.61v.001zm-8.814-3.87h.43v-1.29h1.72v1.29h.431c.95 0 1.72.77 1.72 1.72v.43h-1.72v-.43h-2.58v.8l2.917.584a1.72 1.72 0 011.383 1.685v1.231c0 .95-.77 1.72-1.72 1.72h-.43v1.29h-1.72v-1.29h-.43c-.95 0-1.72-.77-1.72-1.72v-.43h1.72v.43h2.579v-1.23l-2.917-.583a1.72 1.72 0 01-1.382-1.686v-.8c0-.95.77-1.72 1.72-1.72z"
      fill="url(#paint0_linear_8828_1023)"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.771.185c-7.216 0-13.065 5.85-13.065 13.066 0 7.215 5.85 13.065 13.065 13.065 7.216 0 13.066-5.85 13.066-13.065 0-7.216-5.85-13.066-13.066-13.066zM27.2 28.9c-9.119 0-17.693 2.322-25.136 6.398l-1.438.782v12.762H34.23l-.457-4.12c-.305-2.742.23-5.536 1.652-7.902a12.636 12.636 0 014.137-4.21l1.224-.766c-2.369-.972-8.616-2.948-13.587-2.944z"
      fill="url(#paint1_linear_8828_1023)"
    />
    <Defs>
      <LinearGradient
        x2={51.4174}
        y1={22.6481}
        y2={48.0044}
        x1={23.2272}
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_8828_1023"
      >
        <Stop offset={0.28} stopColor={props.gradColor1} />
        <Stop offset={0.92} stopColor={props.gradColor2} />
        <Stop offset={1} stopColor={props.gradColor2} />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_8828_1023"
        x1={0.491294}
        y1={10.6372}
        x2={51.0546}
        y2={52.9522}
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0.16} stopColor={props.gradColor1} />
        <Stop offset={1} stopColor={props.gradColor2} />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default GrpReferralRedesign;
