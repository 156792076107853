import {StyleProp, TextStyle, ViewStyle} from 'react-native';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import ConnectandPayDescriptor from './orderAhead-canteen-descriptor';
import CompanyKitchenDescriptor from './orderAhead-companykitchen-descriptor';
import RedesignDescriptor from './orderAhead-redesign-descriptor';
import RefiveDescriptor from './orderAhead-refive-descriptor';
import {ListRenderItem} from '@shopify/flash-list';
import {DNA} from 'src/types/dnaTypes';
import {MenuProductType} from 'src/types/Menu';

const typeDescriber = {
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.canteen]: ConnectandPayDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
};

export interface PickupLocationStylesDescriptor {
  buttonContainerStyle: StyleProp<ViewStyle>;
  errorContainer: StyleProp<ViewStyle>;
  headerText: StyleProp<TextStyle>;
  buttonText: StyleProp<TextStyle>;
  errorText: StyleProp<TextStyle>;
}
export interface PickupTimeStylesDescriptor {
  dateView?: StyleProp<ViewStyle>;
  timeView?: StyleProp<ViewStyle>;
  pickupTimeContainer?: StyleProp<ViewStyle>;
  pickupLocationHeaderView?: StyleProp<ViewStyle>;
  customTileButtonTime?: StyleProp<ViewStyle>;
  tilesStyle?: StyleProp<ViewStyle>;
  errorContainer?: StyleProp<ViewStyle>;
  editButton?: StyleProp<ViewStyle>;
  buttonTextStyle?: StyleProp<TextStyle>;
}
export interface ProductDetailsStylesDescriptor {
  container: StyleProp<ViewStyle>;
  content: StyleProp<ViewStyle>;
  infoContainer: StyleProp<ViewStyle>;
  title: StyleProp<TextStyle>;
  productPrice: StyleProp<TextStyle>;
  calories: StyleProp<TextStyle>;
  calorieText: StyleProp<TextStyle>;
  healthGrade: StyleProp<ViewStyle>;
  verticalLine: StyleProp<ViewStyle>;
  nutritionButtonView: StyleProp<ViewStyle>;
  outlined: StyleProp<ViewStyle>;
  descriptionContainer: StyleProp<ViewStyle>;
  customizationSection: StyleProp<ViewStyle>;
  quantityContainer: StyleProp<ViewStyle>;
  quantitySelectionView: StyleProp<ViewStyle>;
  dashStyle: StyleProp<ViewStyle>;
  outlinedButtonText: StyleProp<TextStyle>;
  description: StyleProp<TextStyle>;
  quantityLabel: StyleProp<TextStyle>;
}

export interface MenuListStylesDescriptor {
  content?: StyleProp<ViewStyle>;
  searchContainer?: StyleProp<ViewStyle>;
  categories?: StyleProp<ViewStyle>;
}
export interface Identifiable {
  id: string;
}

export type FlashListPropsType<T extends Identifiable> = {
  data: T[];
  renderItem: ListRenderItem<T>;
  refreshKey?: number;
  isSubMenu?: boolean;
};

export interface ProductComponentProps {
  dnaConfiguration?: DNA;
  onPress: () => void;
  name: string;
  item: MenuProductType;
  price: number;
  imageUrl: string | null;
  overrideDna?: string | null;
  isProductCat?: boolean;
  index?: number;
  updateLineCounts?: (index: number, lineCount: number) => void;
  extraPadding?: number;
}

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
