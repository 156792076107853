import _ from 'lodash';
import * as React from 'react';
import {
  Animated,
  PixelRatio,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Localized from 'src/constants/AppStrings';
import Util from 'src/Util';
import RoundedButton, {ButtonType} from '../elements/RoundedButton';
import Styles from '../Styles';
import AVText from './AVText';
import type {RewardType} from 'src/types/Rewards';
import AccountStore from 'src/stores/AccountStore';
import {getDescriber} from './descriptor/DescriptorType';
import EllipseCircle from 'src/components/img/svg/EllipseCircle';
type RewardSectionProps = WithGlobalizeProps & {
  rewards: number;
  style: StyleProp<ViewStyle>;
  isRewardButtonShown?: boolean;
  rewardSteps: Array<RewardType>;
};
type RewardSectionState = {
  rocketSpin: Animated.Value;
  rocketDistance: Animated.Value;
  isFirstLoad: boolean;
  loadingBarWidth: number;
};

class RewardSection extends React.Component<
  RewardSectionProps,
  RewardSectionState
> {
  constructor(props: RewardSectionProps) {
    super(props);
    this.state = {
      rocketSpin: new Animated.Value(0),
      rocketDistance: new Animated.Value(0),
      isFirstLoad: true,
      loadingBarWidth: 0,
    };
  }

  handleLayout = (event) => {
    if (event.nativeEvent.layout.width !== this.state.loadingBarWidth) {
      this.setState({
        loadingBarWidth: event.nativeEvent.layout.width,
      });
    }
  };

  shouldComponentUpdate(
    nextProps: RewardSectionProps,
    nextState: RewardSectionState,
  ) {
    return (
      !_.isEqual(nextProps.rewardSteps, this.props.rewardSteps) ||
      nextProps.rewards !== this.props.rewards ||
      nextState.loadingBarWidth !== this.state.loadingBarWidth
    );
  }

  componentDidUpdate(prevProps: RewardSectionProps) {
    if (
      this.state.isFirstLoad &&
      this.props.rewardSteps &&
      this.props.rewardSteps.length > 0 &&
      this.props.rewards > 0
    ) {
      this.firstMotion(this.props);
      return;
    }

    if (prevProps.rewards !== this.props.rewards) {
      this.getRocketMovementAnimation(this.props).start();
    }
  }

  componentDidMount() {
    if (
      !this.props.rewardSteps ||
      this.props.rewardSteps.length <= 0 ||
      this.props.rewards <= 0
    ) {
      return;
    }

    this.firstMotion(this.props);
  }

  firstMotion(props: RewardSectionProps) {
    if (this.state.loadingBarWidth > 0) {
      this.setState({
        isFirstLoad: false,
      });
      Animated.parallel([
        Animated.timing(this.state.rocketSpin, {
          delay: 300,
          toValue: 60,
          duration: 500,
          useNativeDriver: Platform.OS !== 'web',
        }),
        this.getRocketMovementAnimation(props),
      ]).start();
    }
  }

  getRocketMovementAnimation(props: RewardSectionProps) {
    const maxPoints = props.rewardSteps[props.rewardSteps.length - 1].points;
    const rocketPercent = Math.min(props.rewards / maxPoints, 1) * 0.9375;
    return Animated.timing(this.state.rocketDistance, {
      delay: 300,
      toValue: rocketPercent,
      duration: 2800,
      useNativeDriver: Platform.OS !== 'web',
    });
  }

  render() {
    let i = 0;
    let currentPoints = this.props.rewards;
    let prevPoints = 0;
    let additionalPoints = 0;
    let rewardValue = 0;
    const isAvailable = [];
    const rewardOptions = this.props.rewardSteps?.map((reward) => {
      let availableFlex = 1;
      let unavailableFlex = 1;
      const available = reward.points <= this.props.rewards;
      additionalPoints = reward.points - prevPoints;
      prevPoints = reward.points;

      if (additionalPoints === 0) {
        return null;
      }

      if (this.props.rewards > reward.points) {
        rewardValue = reward.redeemvalue;
      }
      if (currentPoints > 0) {
        if (currentPoints > reward.points) {
          availableFlex = 1;
          unavailableFlex = 0;
        } else {
          availableFlex = currentPoints / additionalPoints;
          unavailableFlex = 1 - availableFlex;
        }
        currentPoints -= additionalPoints;
      } else {
        availableFlex = 0;
      }

      const obj = {};
      obj['index'] = i;
      obj['available'] = available;
      isAvailable.push(obj);
      const found = isAvailable.find((element) => {
        return !element.available;
      });
      return (
        <View
          key={i++}
          style={[
            styles.rewardOption,
            {
              flex: additionalPoints,
            },
          ]}
        >
          <View
            style={[
              getDescriber().stylePayAvailOption(styles),
              {
                flex: availableFlex,
              },
            ]}
          >
            {((found && found?.index === i) ||
              (!found && i === this.props.rewardSteps?.length - 1)) && (
              <View
                style={[
                  styles.roundIndicatorStyle,
                  {
                    marginLeft: !found ? 0 : -22.2,
                  },
                ]}
              >
                <EllipseCircle />
              </View>
            )}
          </View>

          <View
            style={[
              getDescriber().stylePayUnAvailOption(styles),
              {
                flex: unavailableFlex,
                paddingLeft: found && found?.index === i ? 1 : 0,
                marginLeft: found && found?.index === i ? 1 : 0,
              },
            ]}
          ></View>
          <View style={[styles.overlayText, getDescriber().payOverlayTxt()]}>
            <Text
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              accessible={false}
              style={[
                styles.rewardText,
                getDescriber().rewardAmtTxt(styles, available),
              ]}
            >
              {Util.formatCurrency(
                this.props,
                reward.redeemvalue,
                AccountStore.getCurrency(),
                {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0,
                },
              )}
            </Text>
          </View>
          <View style={[getDescriber().rewardPointTxt(styles, available)]} />
        </View>
      );
    });

    const pointsNumber = `${Util.formatNumber(this.props, this.props.rewards, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    })}`;
    const largestAvailableRewardAmount = Util.formatCurrency(
      this.props,
      rewardValue,
      AccountStore.getCurrency(),
      {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      },
    );

    const describerprops = {
      styles,
    };
    return (
      <View key={-1} style={[styles.imageContainer, this.props.style]}>
        <View style={styles.image}>
          <View style={styles.header}>
            <View style={styles.rewardOption}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                style={getDescriber().RewardPointStyleDescriptor(
                  describerprops,
                )}
                accessibilityLabel={`${this.props.rewards} reward points`}
                accessibilityRole="text"
                aria-label={`${this.props.rewards} reward points, text`}
                accessible={true}
              >
                {pointsNumber} {getDescriber().rewardsPointLbl()}
              </AVText>
              {getDescriber().renderpointsLbl(styles)}
            </View>
            {this.props.isRewardButtonShown ? (
              <RoundedButton
                buttonType={ButtonType.normal}
                text={Localized.Labels.redeem}
                containerStyle={styles.button}
                onPress={() => NavActions.navigate(AppRoutes.RewardsScreen)}
                accessible={true}
                accessibilityRole="button"
                accessibilityLabel={Localized.Buttons.redeem_reward_points}
                aria-label={Localized.Buttons.redeem_reward_points}
                role="button"
                color={Styles.Colors.PayNew.primary01}
              />
            ) : null}
          </View>
          {/* {!Settings.isRefiveAnd365Pay() && rocketView} */}
          {getDescriber().rewardPoints(
            styles,
            i,
            rewardOptions,
            largestAvailableRewardAmount,
            this.props.isRewardButtonShown,
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  availableOption: {
    borderBottomWidth: PixelRatio.roundToNearestPixel(2.5),
    borderColor: Styles.primaryColor,
  },
  availablePoint: {
    backgroundColor: Styles.primaryColor,
    borderColor: Styles.primaryColor,
  },
  payAvailablePoint: {
    backgroundColor: '#ffffff',
    borderColor: Styles.primaryColor,
    width: 8,
    borderRadius: 32,
    overflow: 'hidden',
    marginVertical: 2,
  },
  payUnAvailablePoint: {
    backgroundColor: Styles.Colors.PayNew.neutralHuesBase09,
    borderColor: Styles.Colors.PayNew.neutralHuesBase09,
  },
  availableText: {
    color: Styles.primaryColor,
  },
  payAvailableText: {
    paddingTop: 7,
    color: '#111',
    fontSize: 14,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
    left: -6,
  },
  button: {
    alignSelf: 'flex-end',
    position: 'absolute',
    right: 0,
    top: 2,
  },
  flex1: {
    flex: 1,
  },
  flex15: {
    flex: 15,
    elevation: 9,
    zIndex: 99999,
  },
  graph: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    marginTop: Styles.Spacing.m1,
  },
  header: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'row',
  },
  image: {
    alignItems: 'flex-start',
    flex: 1,
    justifyContent: 'flex-start',
  },
  imageContainer: {
    padding: Styles.Spacing.m2,
    paddingTop: Styles.Spacing.m3,
  },
  line: {
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: Styles.Spacing.m1,
  },
  overlayPoint: {
    backgroundColor: Styles.white,
    borderColor: Styles.lightGray,
    borderRadius: PixelRatio.roundToNearestPixel(10),
    borderWidth: PixelRatio.roundToNearestPixel(2),
    height: PixelRatio.roundToNearestPixel(10),
    position: 'absolute',
    right: 0,
    top: -Styles.Spacing.m1,
    width: PixelRatio.roundToNearestPixel(10),
  },
  overlayText: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    position: 'absolute',
    right: -2,
    top: Styles.Spacing.m2,
  },
  pointNumberLabel: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    fontWeight: '300',
  },
  pointsLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginLeft: Styles.Spacing.m1,
    marginTop: 2,
  },
  rewardOption: {
    flexDirection: 'row',
  },
  roundIndicatorStyle: {
    width: Platform.OS == 'web' ? 50 : 17,
    height: Platform.OS == 'web' ? 40 : 12,
    zIndex: 99999,
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    left: 5,
    elevation: 9,
    top: -4,
  },
  rewardText: {
    fontWeight: '300',
  },
  rocket: {
    left: -13,
  },
  rocketView: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'row',
    marginBottom: -2,
  },
  unavailableOption: {
    borderBottomWidth: PixelRatio.roundToNearestPixel(2.5),
    borderColor: Styles.lightGray,
  },
  payGraph: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    marginTop: Styles.Spacing.m3,
  },
  payAvailableOption: {
    height: PixelRatio.roundToNearestPixel(12),
    backgroundColor: '#409F68',
  },
  firstSection: {
    borderBottomLeftRadius: Styles.Spacing.m3,
    borderTopLeftRadius: Styles.Spacing.m3,
  },
  lastSection: {
    borderBottomRightRadius: Styles.Spacing.m3,
    borderTopRightRadius: Styles.Spacing.m3,
  },
  payUnavailableOption: {
    height: PixelRatio.roundToNearestPixel(12),
    backgroundColor: Styles.Colors.PayNew.neutralHuesBase09,
  },
  payOverlayPoint: {
    height: PixelRatio.roundToNearestPixel(8),
    position: 'absolute',
    right: 0,
    width: PixelRatio.roundToNearestPixel(1),
  },
  payUnAvailableText: {
    paddingTop: 7,
    color: '#111',
    fontSize: 14,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
    left: -6,
  },
});
export default withGlobalize(RewardSection);
