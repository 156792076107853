import React, {useEffect} from 'react';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import HistoryComponent from 'src/components/elements/account/newHistoryUI/HistoryComponent';
import {filterTypes} from 'src/components/elements/account/AccountHistoryList';
import {NavigationProp} from '@react-navigation/native';
import AccountConstants from '../../../../constants/AccountConstants';

type HistoryComponentProps = {
  histroyfilter?: AccountConstants;
  navigation: NavigationProp<HistoryComponentProps>;
};

const FundingHistoryList = (props: HistoryComponentProps) => {
  useEffect(() => {
    const filterValue = filterTypes.funding;
    FirebaseAnalytic.trackEvent(
      'useEffect:componentDidMount',
      'FundingHistoryList',
      {
        ...props,
        filterValue,
      },
    );
  }, []);
  return <HistoryComponent histroyfilter={filterTypes.funding} {...props} />;
};

export default FundingHistoryList;
