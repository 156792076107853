//This is autogenerated by running utility-scripts/generateAppSyncEnvironments.js
import {EnvironmentKey} from '../../models/Environment';

type ENV_CONFIG = {
  [key in EnvironmentKey]: {
    aws_project_region: string;
    aws_cognito_identity_pool_id: string;
    aws_cognito_region: string;
    aws_user_pools_id: string;
    aws_user_pools_web_client_id: string;
    oauth: any;
    aws_appsync_graphqlEndpoint: string;
    aws_appsync_region: string;
    aws_appsync_authenticationType: string;
  };
};

const environmentConfigs: ENV_CONFIG = {
  DEV: {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id:
      'us-west-2:14421dce-b172-49f6-840d-9d642e43bbb4',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_1dMUSDzwk',
    aws_user_pools_web_client_id: '4mfscsnv1q3vpf5ntveqosa8e8',
    oauth: {},
    aws_appsync_graphqlEndpoint:
      'https://k36f5ibbtbdrrpise4n32tboca.appsync-api.us-west-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
  QA: {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id:
      'us-west-2:3c94aa90-44b8-425b-ab0c-5e2ef14b548a',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_1zLFl2XHZ',
    aws_user_pools_web_client_id: '2m4dpnhosugroloeiutqh19idj',
    oauth: {},
    aws_appsync_graphqlEndpoint:
      'https://hdu24ww2pbamdk6z5murhz6b6a.appsync-api.us-west-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
  STAGING: {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id:
      'us-west-2:3c94aa90-44b8-425b-ab0c-5e2ef14b548a',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_1zLFl2XHZ',
    aws_user_pools_web_client_id: '2m4dpnhosugroloeiutqh19idj',
    oauth: {},
    aws_appsync_graphqlEndpoint:
      'https://hdu24ww2pbamdk6z5murhz6b6a.appsync-api.us-west-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
  PROD: {
    aws_project_region: 'us-west-2',
    aws_cognito_identity_pool_id:
      'us-west-2:b9992b2e-91ba-4201-ba54-17e672055edb',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_tgj0PNPmR',
    aws_user_pools_web_client_id: '61cbbljn6pe2e1qsnqqj2hfmtd',
    oauth: {},
    aws_appsync_graphqlEndpoint:
      'https://ac57edtiordf3aipuv4bkoc75i.appsync-api.us-west-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
};
export default environmentConfigs;
