import * as React from 'react';
import Svg, {Path, Rect} from 'react-native-svg';
type LogoProps = {
  size: number;
  style?: {[index: string]: {[index: string]: number | string}};
}; // tslint:disable

export default class MasterCard extends React.Component<LogoProps> {
  getWidth() {
    if (this.props.style && this.props.style['size']['width']) {
      return this.props.style['size']['width'];
    } else {
      return 53;
    }
  }

  getHeight() {
    if (this.props.style && this.props.style['size']['height']) {
      return this.props.style['size']['height'];
    } else {
      return 34;
    }
  }

  render() {
    return (
      <>
        <Svg
          width={this.getWidth()}
          height={this.getHeight()}
          viewBox="0 0 53 34"
          fill="none"
        >
          <Rect x={1} y={1} width={51} height={32} rx={4} fill="#fff" />
          <Rect
            x={0.5}
            y={0.5}
            width={52}
            height={33}
            rx={4.5}
            stroke="#C2C2C5"
          />
          <Path d="M31.552 7.565h-10.5v18.87h10.5V7.564z" fill="#FF5F00" />
          <Path
            d="M21.719 17a11.98 11.98 0 014.583-9.434 12 12 0 100 18.868A11.978 11.978 0 0121.72 17z"
            fill="#EB001B"
          />
          <Path
            d="M45.717 17a12 12 0 01-19.415 9.434 12 12 0 000-18.868A12 12 0 0145.717 17zM44.572 24.436v-.386h.156v-.079h-.397v.079h.156v.386h.085zm.77 0v-.466h-.121l-.14.32-.14-.32h-.122v.466h.086v-.351l.131.303h.09l.13-.304v.352h.087z"
            fill="#F79E1B"
          />
        </Svg>
      </>
    );
  }
}
