import {Animated} from 'react-native';

const buildTransform = (
  animationRange: Animated.Value,
  startX: number,
  startY: number,
  startHeight: number,
  startWidth: number,
  endX: number,
  endY: number,
  scaleTo: number,
  includeRotate = false,
) => {
  const complementScaling = 1 - scaleTo;
  const verticalScalingDiff = (complementScaling * startHeight) / 2;
  const horizontalScalingDiff = (complementScaling * startWidth) / 2;
  const transform = {
    transform: [
      {
        translateY: animationRange.interpolate({
          inputRange: [0, 1],
          outputRange: [0, -(startY - endY) - verticalScalingDiff],
        }),
      },
      {
        translateX: animationRange.interpolate({
          inputRange: [0, 1],
          outputRange: [0, -(startX - endX) - horizontalScalingDiff],
        }),
      },
      {
        scale: animationRange.interpolate({
          inputRange: [0, 1],
          outputRange: [1, scaleTo],
        }),
      },
    ],
  } as {
    transform: Array<{
      [key: string]: Animated.AnimatedInterpolation<string | number>;
    }>;
  };

  if (includeRotate) {
    transform.transform.push({
      rotate: animationRange.interpolate({
        inputRange: [0, 1],
        outputRange: ['45deg', '0deg'],
      }),
    });
  }

  return transform;
};

export default buildTransform;
