import LocationTileTutorial from 'src/components/img/svg/tutorials/LocationTileTutorial';
import NotificationTutorialIcon from 'src/components/img/svg/tutorials/NotificationTutorialIcon';
import Styles from 'src/components/Styles';

export function appTourScreenStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    titleText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontWeight: '700',
      fontSize: Styles.Fonts.headerFont,
      color: Styles.white,
      marginBottom: Styles.Spacing.m1,
      textAlign: 'center',
    },
    descriptionText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontWeight: '400',
      fontSize: Styles.Fonts.f7,
      color: Styles.white,
      textAlign: 'center',
      maxWidth: 220,
    },
    stepIndicatorText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontWeight: '700',
      fontSize: Styles.Fonts.sectionHeader,
      color: Styles.white,
    },
    btnBottomViewText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontWeight: '700',
      fontSize: Styles.Fonts.f1,
      textDecorationLine: 'underline',
    },
    nextBtnTextView: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontWeight: '700',
      fontSize: Styles.Fonts.f1,
    },
  };
}
export function appTourNotificationIcon(): JSX.Element {
  return <NotificationTutorialIcon />;
}
export function appTourLocationNotificationIcon(): JSX.Element {
  return <LocationTileTutorial />;
}
export default {
  appTourScreenStyles,
  appTourNotificationIcon,
  appTourLocationNotificationIcon,
};
