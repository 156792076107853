import moment from 'moment';
import type {CartItem, TransactionDetail} from 'src/types/TransactionDetail';
import AVLiveApi from 'src/api/AVLiveApi';
import CartApi, {RequestResponseCartAPI} from 'src/api/CartApi';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import type {PaymentType} from 'src/stores/CartStore';
import AppDispatcher from 'src/dispatchers/AppDispatcher';
import AccountStore from 'src/stores/AccountStore';
import type {PickupLocation} from 'src/types/Location';
import type {TimeSlotType} from 'src/types/Menu';
import PlatformApi from 'src/api/PlatformApi';
import TransactionConstants from 'src/constants/TransactionConstants';
import CartStore from 'src/stores/CartStore';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

class TransactionActions {
  async authorize(deviceId: string, force = false) {
    try {
      let response;

      if (AccountStore.isDemo()) {
        response = {
          AuthResult: {
            AuthorizationAmount: 15.5,
            TimeoutBetweenVends: 60,
            TransactionId: '1234',
          },
        };
      } else {
        response = await AVLiveApi.authorizeTransaction(
          AccountStore.getQRValue(),
          deviceId,
          force,
        );
      }

      AppDispatcher.handleViewAction({
        actionType: TransactionConstants.AUTHORIZE,
        data: {
          response,
          deviceId,
        },
      });
      return response;
    } catch (error) {
      AppDispatcher.handleViewAction({
        actionType: TransactionConstants.AUTHORIZE_ERROR,
        data: {
          error,
          deviceId,
        },
      });
    }
  }

  async cancelTransaction(transactionId: string) {
    try {
      await AVLiveApi.cancelTransaction(
        AccountStore.getAccountId(),
        transactionId,
      );
    } catch (exception) {
      CrashlyticsEvents.log(
        'Exception',
        'TransactionActions:UploadPingData',
        generateErrorMessage(exception),
      );
      Events.Error.trackEvent(
        'Exception',
        'TransactionActions:UploadPingData',
        generateErrorMessage(exception),
      );
    }
  }

  async captureTransaction(
    transactionId: string,
    items: {
      key: string;
      price: number;
      desc: string;
    }[],
  ) {
    const selections = items.map((item) => ({
      Key: item.key,
      Amount: item.price,
      ...item,
    }));
    let response;
    try {
      response = await AVLiveApi.captureTransaction(
        AccountStore.getAccountId(),
        transactionId,
        selections,
      );
      return response;
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'TransactionActions:CaptureTransaction',
        generateErrorMessage(error),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'TransactionActions:CaptureTransaction',
        generateErrorMessage(error),
      );
    }
  }

  async uploadPingData(deviceId: string, pingData: object) {
    try {
      return await AVLiveApi.uploadPing(deviceId, pingData);
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'TransactionActions:UploadPingData',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'TransactionActions:UploadPingData',
        generateErrorMessage(error),
      );
      throw error;
    }
  }

  testTransaction(transactionId: string) {
    AppDispatcher.handleViewAction({
      actionType: TransactionConstants.TEST_TRANSACTION,
      data: {
        transactionId,
      },
    });
  }

  async updateCart(
    locationId: string,
    gmaId: string,
    coupons: Array<string>,
    cartItems: Array<CartItem>,
    type: string,
    pickupDate?: string,
    pickupTime?: string,
    preparationMethod?: string,
    discountCode?: string | null,
  ) {
    let response;

    if (AccountStore.isDemo()) {
      response = this.getDemoCartApiResponse(cartItems);
    } else {
      let pickupTime24: string | undefined;
      if (pickupTime) {
        pickupTime24 = moment(pickupTime, 'LT').format('HH:mm:ss');
      }

      response = await CartApi.updateCart(
        locationId,
        gmaId,
        coupons,
        cartItems,
        type,
        pickupDate,
        pickupTime24,
        preparationMethod,
        discountCode,
      );
      Logger.Log.LogAPIEvent(
        'CartAPI',
        'AddItem',
        JSON.stringify({
          locationId,
          gmaId,
          coupons,
          cartItems,
          type,
          pickupDate,
          pickupTime24,
          preparationMethod,
          discountCode,
        }),
        JSON.stringify(response),
      );


    }
    AppDispatcher.handleViewAction({
      actionType: TransactionConstants.SHOPPING_CART_UPDATED,
      data: {
        response,
      },
    });
    return response as RequestResponseCartAPI;
  }

  getCategories(locationId: string) {
    return CartApi.getCategories(locationId);
  }

  searchProducts(
    locationId: string,
    query: string,
    category: string | number,
    offset: number,
    size: number,
  ) {
    if (AccountStore.isDemo()) {
      return require('../demo/ProductListItem.json');
    }
    return CartApi.searchProducts(locationId, query, category, offset, size);
  }

  async checkout(
    locationId: string,
    gmaId: string,
    payments: Array<PaymentType>,
    coupons: Array<string>,
    cartItems: Array<CartItem>,
    type: string,
    transDate: string,
    transId: string,
    sequenceNumber: number,
    locationName: string,
    pickupTime: string,
    preparationMethod: string,
    pickupDate: string | null,
    pickupLocation: string | null | undefined,
    pickupLocationId: string | null | undefined,
    orderNote: string | null,
    fallbackPreparationMethod: string | null = null,
    balanceType: string,
    salesContext: string | null = null,
    discountCode?: string | null,
  ): Promise<TransactionDetail> {
    let accountAmount;
    let accountPayment;

    if (payments.length > 0) {
      accountPayment = payments.find((payment) => payment.type === 'ACCOUNT');

      if (accountPayment != null) {
        accountAmount = accountPayment.amount;
      } else {
        accountAmount = 0;
      }
    }

    let response;

    if (AccountStore.isDemo()) {
      response = this.getDemoCartApiResponse(cartItems);
    } else {
      let pickupTime24: string | undefined;

      if (pickupTime) {
        pickupTime24 = moment(pickupTime, 'LT').format('HH:mm:ss');
      }

      response = await CartApi.checkout(
        locationId,
        gmaId,
        payments,
        coupons,
        cartItems,
        type,
        transDate,
        transId,
        sequenceNumber,
        pickupTime24,
        preparationMethod,
        pickupDate,
        pickupLocation,
        pickupLocationId,
        orderNote,
        fallbackPreparationMethod,
        salesContext,
        discountCode,
      );
      Logger.Log.LogAPIEvent(
        'CartAPI',
        'Checkout',
        JSON.stringify({
          locationId,
          gmaId,
          payments,
          coupons,
          cartItems,
          type,
          transDate,
          transId,
          sequenceNumber,
          pickupTime24,
          preparationMethod,
          pickupDate,
          pickupLocation,
          pickupLocationId,
          orderNote,
          fallbackPreparationMethod,
          salesContext,
          discountCode,
        }),
        JSON.stringify(response),
      );

    }

    if (accountAmount == null) {
      accountAmount = Number(response?.Due);
    }

    AppDispatcher.handleViewAction({
      actionType: TransactionConstants.CHECKOUT,
      data: {
        response,
        accountAmount,
        locationName,
        transDate,
        balanceType,
      },
    });
    return response as TransactionDetail;
  }

  getDemoCartApiResponse(cartItems: Array<CartItem>) {
    let subtotal = cartItems.length * 1.5;
    let index = 1;
    let discount = 0;

    if (cartItems.length > 2) {
      discount = 0.5;
      subtotal -= discount;
    }

    let itemDiscount = 0;

    if (discount > 0) {
      itemDiscount = discount / cartItems.length;
    }

    const items: Array<CartItem> = cartItems.map((item) => ({
      LineNumber: index,
      BarCode: item.BarCode,
      Id: `index-${index++}`,
      Description: item.Description || "Lay's Potato Chips",
      Name: item.Description || "Lay's Potato Chips",
      Price: item.Price || '1.5',
      Discount: itemDiscount.toString(),
      DiscountDisplayName: 'Cracked-out Employee Deal',
    }));
    const tax = cartItems.length * 0.02;
    return {
      Items: items,
      SubTotal: subtotal,
      Taxes: [
        {
          Description: 'Sales Tax',
          TaxAmount: tax,
        },
      ],
      Deposit: 0.0,
      Total: subtotal + tax,
      Payments: [],
      Due: subtotal + tax,
      Status: 'OK',
      PromotionDiscount: discount,
    };
  }

  shoppingCartCleared() {
    AppDispatcher.handleViewAction({
      actionType: TransactionConstants.SHOPPING_CART_CANCELLED,
    });
  }

  shoppingCartTransactionsCleared() {
    AppDispatcher.handleViewAction({
      actionType: TransactionConstants.SHOPPING_CART_TRANSACTIONS_CLEARED,
    });
    CartStore.resetCart();
  }

  pickupTimeUpdated(pickupTime: TimeSlotType) {
    AppDispatcher.handleViewAction({
      actionType: TransactionConstants.PICKUP_TIME_CHANGED,
      data: {
        pickupTime,
      },
    });
  }

  pickupLocationUpdated(pickupLocation: PickupLocation) {
    AppDispatcher.handleViewAction({
      actionType: TransactionConstants.PICKUP_LOCATION_CHANGED,
      data: {
        pickupLocation,
      },
    });
  }

  availableTimesUpdated(availableTimes: Array<TimeSlotType>) {
    AppDispatcher.handleViewAction({
      actionType: TransactionConstants.AVAILABLE_TIMES_CHANGED,
      data: {
        availableTimes,
      },
    });
  }

  productSoldOutUpdated(items: Array<CartItem>) {
    AppDispatcher.handleViewAction({
      actionType: TransactionConstants.ZERO_INVENTORY_UPDATE,
      data: {
        items,
      },
    });
  }

  async shareReceipt(
    accountId: string,
    transactionId: string,
    appName: string,
    method: string,
    contact: string,
    transactionDate: string,
  ) {
    return await PlatformApi.shareReceipt(
      accountId,
      transactionId,
      appName,
      method,
      contact,
      transactionDate,
    );
  }

  afterTaxPromoUpdated() {
    AppDispatcher.handleViewAction({
      actionType: TransactionConstants.AFTER_TAX_PROMO_APPLIED,
    });
  }
}

export default new TransactionActions();
