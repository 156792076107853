import * as React from 'react';
import Svg, {Path, Mask, G} from 'react-native-svg';
type CartImageProps = {
  width: number;
  height: number;
  color?: string;
  style?: Record<string, unknown>;
};
export default class CartImage extends React.Component<CartImageProps> {
  render() {
    return (
      <>
        <Svg
          width={this.props.width}
          height={this.props.height}
          viewBox="0 0 20 20"
          fill="none"
          style={this.props.style ? this.props.style : null}
        >
          <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.82965 16.7529C5.93296 16.7529 5.20605 17.4798 5.20605 18.3765C5.20605 19.2732 5.93296 20.0001 6.82965 20.0001C7.72634 20.0001 8.45324 19.2732 8.45324 18.3765C8.45324 17.4798 7.72634 16.7529 6.82965 16.7529Z"
            fill="white"
          />
          <Mask id="Mask0_8280_2995" x="16" y="16" width="4" height="4">
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.7529 16.7529H20.0001V20.0001H16.7529V16.7529Z"
              fill="white"
            />
          </Mask>
          <G Mask="url(#Mask0_8280_2995)">
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.3765 16.7529C17.4798 16.7529 16.7529 17.4798 16.7529 18.3765C16.7529 19.2732 17.4798 20.0001 18.3765 20.0001C19.2732 20.0001 20.0001 19.2732 20.0001 18.3765C20.0001 17.4798 19.2732 16.7529 18.3765 16.7529Z"
              fill="white"
            />
          </G>
          <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.9998 15.2071C20.0126 14.5652 19.4744 14.0397 18.8323 14.0397H14.4577H14.3946H6.08822L5.80125 12.4183H7.45116H14.682H17.4531C18.0189 12.4183 18.5032 12.0123 18.6018 11.4551L19.9818 3.65803C20.1083 2.94353 19.5587 2.28819 18.8331 2.28819H14.682H7.45116H4.00835L3.77056 0.944719C3.67385 0.39825 3.19888 0 2.64397 0H1.97472H1.17606H1.14406C0.512188 0 0 0.512219 0 1.14409C0 1.77597 0.512188 2.28819 1.14406 2.28819H1.17606H1.68459L4.00228 15.3831C4.09897 15.9296 4.57388 16.3279 5.12885 16.3279H14.3946H14.4577H18.8559C19.48 16.3279 19.9873 15.8282 19.9998 15.2071Z"
            fill="white"
          />
        </Svg>
      </>
    );
  }
}
