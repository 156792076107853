import * as React from 'react';
import {StyleSheet, ImageBackground, StatusBar} from 'react-native';
import SafeAreaView from '../elements/SafeAreaView';
import KeyboardAvoidingView from '../elements/365KeyboardAvoidingView';
import BackSubheader from '../elements/BackSubheader';
import Styles from '../Styles';
import AVText from '../elements/AVText';
import LinearGradient from 'react-native-linear-gradient';
import Settings from 'src/Settings';
import BuildTypes from 'src/constants/BuildTypeConstants';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';

type BaseAccountSetupScreenRedesignProps = {
  children: React.ReactNode;
  headlineText: string;
  instructionText: string;
  previousRoute?: string;
  onBackSelect?: () => void;
  pop?: boolean;
};
export default class BaseAccountSetupScreenRedesign extends React.Component<BaseAccountSetupScreenRedesignProps> {
  renderContent() {
    return (
      <KeyboardAvoidingView style={styles.content}>
        <AVText
          accessibilityLabel={this.props.instructionText}
          accessible={true}
          accessibilityRole="text"
          aria-label={this.props.instructionText}
          style={getDescriber().onboardingFlowStyleDescriptor()['instructions']}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {this.props.instructionText}
        </AVText>
        {this.props.children}
      </KeyboardAvoidingView>
    );
  }

  renderContainer() {
    const gradientProps =
      Settings.buildType === BuildTypes.canteen
        ? {
            colors: ['#FFFFFF', '#FBFFF3', '#E3F4C0', '#A5CF4F'],
            locations: [0, 0.3, 0.7, 1],
            start: {x: 0.5, y: 0},
            end: {x: 0.5, y: 1},
          }
        : {
            colors: ['#FEE0E6', '#E6ECFF'],
            start: {x: 0, y: 0},
            end: {x: 0, y: 1},
          };

    return (
      <>
        {Settings.isRefive() ? (
          <ImageBackground
            source={require('src/components/img/RefiveOnboardingBackground.png')}
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <StatusBar
              translucent={true}
              backgroundColor="transparent"
              barStyle={'dark-content'}
            />
            <SafeAreaView
              style={[Styles.Style.flex]}
              forceInset={{
                top: 'never',
              }}
              setPaddingBottomsafeArea
              backgroundStyle={{backgroundColor: 'transparent'}}
            >
              <BackSubheader
                title={this.props.headlineText}
                onBackSelect={this.props.onBackSelect}
                accessibilityLabel={'Back arrow'}
                accessibilityHint={`Press to navigate back to the ${this.props.previousRoute} screen`}
                transparentHeader={true}
                pop={this.props.pop}
                onboardingHeader={true}
              >
                {this.renderContent()}
              </BackSubheader>
            </SafeAreaView>
          </ImageBackground>
        ) : (
          <LinearGradient {...gradientProps} style={styles.gradientContainer}>
            <SafeAreaView
              style={[Styles.Style.flex]}
              forceInset={{
                top: 'never',
              }}
              setPaddingBottomsafeArea
              backgroundStyle={{backgroundColor: 'transparent'}}
            >
              <BackSubheader
                title={this.props.headlineText}
                onBackSelect={this.props.onBackSelect}
                accessibilityLabel={'Back arrow'}
                accessibilityHint={`Press to navigate back to the ${this.props.previousRoute} screen`}
                transparentHeader={true}
                pop={this.props.pop}
                onboardingHeader={true}
              >
                {this.renderContent()}
              </BackSubheader>
            </SafeAreaView>
          </LinearGradient>
        )}
      </>
    );
  }

  render() {
    return this.renderContainer();
  }
}
const styles = StyleSheet.create({
  content: {
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  gradientContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
});
