import React from 'react';
import {StyleSheet, View, TouchableOpacity} from 'react-native';
import Styles from '../Styles';

const CustomRadioButton = ({
  onPress,
  accessibilityLabel,
  selected,
  outerCircleStyle = {},
  innerCircleStyle = {},
  touchableAreaPaddingStyle = {},
}) => {
  return (
    <View style={styles.radioButtonContainer}>
      <TouchableOpacity
        accessibilityLabel={accessibilityLabel}
        onPress={onPress}
      >
        <View
          style={{...styles.touchableAreaPadding, ...touchableAreaPaddingStyle}}
        >
          <View style={{...styles.radioButton, ...outerCircleStyle}}>
            {selected ? (
              <View
                style={{...styles.radioButtonHighlight, ...innerCircleStyle}}
              />
            ) : null}
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  radioButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  touchableAreaPadding: {
    padding: Styles.Spacing.m3,
  },
  radioButton: {
    height: 16,
    width: 16,
    backgroundColor: Styles.bgColor,
    borderRadius: Styles.Heights.h1,
    borderWidth: 1,
    borderColor: Styles.lightGray,
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonHighlight: {
    height: 8,
    width: 8,
    borderRadius: 7,
    backgroundColor: Styles.lightGray,
  },
});

export default CustomRadioButton;
