import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
type RewardProps = {
  size: number;
  color?: string;
};
export default class Reward extends React.Component<RewardProps> {
  render() {
    return (
      <Svg
        width={this.props.size}
        height={this.props.size}
        id="Reward"
        x="0px"
        y="0px"
        viewBox="0 0 640 640"
      >
        <Path
          d="M466.12,317.43,577.6,527H448.8L384.4,642,330.88,515.61l60.05-141a194.44,194.44,0,0,0,75.19-57.17Zm-292.24,0C209.31,361.27,261.63,389,320,389a181.7,181.7,0,0,0,45.14-5.69h0L320,489.9,255.6,642,191.2,527H62.4ZM320-2c94.85,0,171.73,82.38,171.73,184S414.85,366,320,366,148.27,283.62,148.27,182,225.15-2,320-2Zm0,46c-71.14,0-128.8,61.78-128.8,138S248.86,320,320,320s128.8-61.78,128.8-138S391.14,44,320,44Zm0,11.5c65.1,0,118.07,56.75,118.07,126.5S385.1,308.5,320,308.5,201.93,251.75,201.93,182,254.9,55.5,320,55.5Z"
          fill={this.props.color ?? '#22212d'}
          fill-rule="evenodd"
        />
      </Svg>
    );
  }
}
