import React, {useCallback, useEffect, useRef} from 'react';
import {
  Alert,
  AppState,
  AppStateStatus,
  NativeModules,
  Platform,
  TouchableOpacity,
} from 'react-native';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import AVTouchableOpacity from './AVTouchableOpacity';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {useFocusEffect} from '@react-navigation/native';

const {BrightnessModule} = NativeModules;

type BrightnessTouchableProps = React.ComponentProps<
  typeof TouchableOpacity
> & {
  timeout: number | null;
};
export default function BrightnessTouchable(props: BrightnessTouchableProps) {
  const originalBrightness = useRef<number>(0);
  const brightnessTimeoutId = useRef<NodeJS.Timeout | null>(null);

  const resetBrightness = useCallback(() => {
    if (originalBrightness.current > 0) {
      BrightnessModule.setBrightness(originalBrightness.current);
    }
  }, []);

  const setBrightness = useCallback(async () => {
    if (Platform.OS === 'android') {
      const hasPermission =
        await BrightnessModule.checkWriteSettingsPermission();
      if (!hasPermission) {
        Alert.alert(
          'Permission Required',
          'This app needs permission to change the device brightness.',
          [
            {text: 'Cancel', style: 'cancel'},
            {
              text: 'Grant Permission',
              onPress: () => BrightnessModule.requestWriteSettingsPermission(),
            },
          ],
        );
        return;
      }
    }

    try {
      if (brightnessTimeoutId.current) {
        clearTimeout(brightnessTimeoutId.current);
      }

      originalBrightness.current = await BrightnessModule.getAppBrightness();
      await BrightnessModule.setBrightness(0.9);
      brightnessTimeoutId.current = setTimeout(
        resetBrightness,
        props.timeout || 3000,
      );
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'BrightnessTouchable:SetBrightness',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'BrightnessTouchable:SetBrightness',
        generateErrorMessage(error),
      );
    }
  }, [props.timeout, resetBrightness]);

  const handleSaveBrightness = useCallback((appState: AppStateStatus) => {
    if (appState !== 'active') {
      resetBrightness();
    }
  }, []);

  useEffect(() => {
    BrightnessModule?.getAppBrightness().then((brightness: number) => {
      originalBrightness.current = brightness;
    });
    const handleAppStateChangeSubscription = AppState.addEventListener(
      'change',
      handleSaveBrightness,
    );

    return () => {
      handleAppStateChangeSubscription.remove();
    };
  }, []);

  useFocusEffect(
    useCallback(() => {
      return () => {
        resetBrightness();
        clearTimeout(brightnessTimeoutId.current);
      };
    }, []),
  );

  return <AVTouchableOpacity onPress={setBrightness} {...props} />;
}
