import React from 'react';
import {View, StyleSheet, Image, Dimensions, Platform} from 'react-native';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import AVText from '../../elements/AVText';
import CartTypes from 'src/constants/cart/CartTypes';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {scanAndPayTemplate} from 'src/components/screens/scanAndPay/templates/scanandPayThemeHelper';
import {getDescriber} from 'src/components/screens/orderAhead/descriptor/DescriptorType';
import {LocationType} from 'src/types/Location';
import Settings from 'src/Settings';
const {width: screenWidth, height: screenHeight} = Dimensions.get('window');

type ListCartEmptyProps = {
  cartType: CartTypes;
  isRefive?: boolean;
  handleGoBack?: () => void;
  location?: LocationType;
};

const ListCartEmpty = (props: ListCartEmptyProps) => {
  const {top} = useSafeAreaInsets();
  const localData = props.isRefive
    ? 'barcode_refocus_and_find_non_barcoded_items_revolve'
    : 'barcode_refocus_and_find_non_barcoded_items';
  if (props.cartType == CartTypes.ScanAndPay) {
    return (
      <View style={styles.container}>
        {Settings.isRefive() && (
          <AVText
            accessible={true}
            accessibilityLabel={Localized.Labels[localData]}
            aria-label={Localized.Labels[localData]}
            style={[styles.labelLocation]}
          >
            {props.location.displayName}
          </AVText>
        )}
        <AVText
          accessible={true}
          accessibilityLabel={Localized.Labels[localData]}
          aria-label={Localized.Labels[localData]}
          style={[styles.label, getDescriber().getEmptyCartlblStyle()]}
        >
          {Localized.Labels[localData]}
        </AVText>
        <Image
          style={[
            styles.scanProductImage,
            {
              marginTop:
                screenHeight -
                (screenWidth * 257) / 512 -
                (Platform.OS === 'ios' ? 430 : 370) -
                top,
            },
          ]}
          resizeMode={'contain'}
          source={scanAndPayTemplate.emptyCartImage}
        />
      </View>
    );
  } else {
    return (
      <View style={styles.orderAheadEmptycontainer}>
        <AVText
          accessible={true}
          accessibilityLabel={Localized.Labels.orderAheadEmptyCart}
          aria-label={Localized.Labels.orderAheadEmptyCart}
          style={styles.orderAheadLabel}
        >
          {Localized.Labels.orderAheadEmptyCart}
        </AVText>
        <View
          style={{
            alignSelf: 'center',
          }}
        >
          <Image
            style={styles.orderAheadEmptyImage}
            resizeMode={'contain'}
            source={require('../../img/OrderAheadEmptyIcon.png')}
          />
        </View>
        <RoundedAppButton
          buttonContainerStyle={{
            marginTop: Styles.Spacing.m4 + Styles.Spacing.m3,
            alignSelf: 'center',
          }}
          onPress={props.handleGoBack}
          buttonType={RoundedAppButtonType.Outline}
          buttonViewStyle={{
            width: 319,
            height: 42,
            backgroundColor: Styles.white,
          }}
          textStyle={{fontSize: Styles.Fonts.f1}}
          accessibilityLabelValue={Localized.Buttons.goback_to_menu}
          aria-label={Localized.Buttons.goback_to_menu}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
          titleText={Localized.Buttons.goback_to_menu}
        />
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: Styles.Spacing.m3,
  },
  orderAheadEmptycontainer: {
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: Styles.Spacing.m3,
  },
  scanProductImage: {
    left: -16,
    width: '100%',
    height: 200, // Adjust as needed
    resizeMode: 'cover',
  },
  orderAheadEmptyImage: {
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
    alignSelf: 'center',
  },
  label: {
    marginTop: Styles.Spacing.m3,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
  },
  orderAheadLabel: {
    marginTop: Styles.Spacing.m3,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.sectionHeader,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  labelLocation: {
    marginTop: Styles.Spacing.m3,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: Styles.Fonts.f1 + 2,
    fontWeight: '700',
    color: '#111',
  },
});

export default ListCartEmpty;
