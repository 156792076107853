import React from 'react';
import {
  Animated,
  Platform,
  PixelRatio,
  StyleSheet,
  Text,
  View,
  TouchableWithoutFeedback,
  TextStyle,
} from 'react-native';
import {
  SnackItemDescriberProps,
  CommonUtilsSwitchProps,
  NumberSpinnerStylesDescriptor,
  ReferralItemDescriberProps,
  RewardsSection,
  DealProps,
  StylesDescriptor,
  CollapsibleComponentProps,
} from 'src/components/elements/descriptor/DescriptorType';
import Referral from 'src/components/img/svg/inbox/referral-reward';
import SignUp from 'src/components/img/svg/inbox/signup-reward';
import AccountStore from 'src/stores/AccountStore';
import Icon from 'react-native-vector-icons/FontAwesome';
import moment from 'moment';
import NotifyDealIcon from 'src/components/img/svg/NotifyDealIcon';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import TimeUtils from 'src/services/TimeUtils';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Settings from 'src/Settings';
import Util from 'src/Util';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import CloseIcon from 'src/components/img/svg/CloseIcon';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import GiftIconCP from 'src/components/img/svg/GiftIconCP';
import CustomToggleSwitch from 'src/components/elements/CustomToggleSwitch';

type Formatted = number | string | JSX.Element;

export function referralItemDateDescriptor(
  props?: ReferralItemDescriberProps,
): string {
  return TimeUtils.formatInboxDate(props.referral.dateCreated);
}
export function payAtKioskLbl() {
  return Localized.Labels.pay_at_kiosk;
}
export function snackItemDateDescriptor(
  props?: SnackItemDescriberProps,
): string {
  return TimeUtils.formatInboxDate(props.snack.snackSent);
}

export function historyHeaderName(): string {
  return Localized.Labels.history;
}
export function onboardingLocaionBorderColor() {
  return '#84BD00';
}
export function onboardingLocaionBgColor() {
  return 'rgba(132, 189, 0, 0.16)';
}
export function getInboxTabStyles(): {
  [index: string]: number | string;
} {
  return {
    marginTop: null,
  };
}

export function onboardingFlowStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    instructions: {},
    descriptionText: {
      color: Styles.black,
      fontSize: Styles.Fonts.f7,
      fontWeight: '400',
      textAlign: 'center',
      marginTop: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    nearByLocationView: {},
    nearByLocationViewDesc: {},
  };
}
export function onboardingSelectLocationTextDescriptor(): string {
  return Localized.Labels.turn_on_location_services;
}
export function getNotificationScreenStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    unreadNumText: {
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
      color: Styles.tabBarTextColor,
      fontFamily: Styles.FontFamily.robotoRegular,
      marginTop: Styles.Spacing.m1,
    },
    readUnreadText: {
      color: Styles.Colors.PayNew.primary01,
      fontSize: Styles.Fonts.f1,
      paddingTop: Styles.Spacing.m1,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    emptyMessageText: {
      fontSize: Styles.Fonts.f7,
      marginTop: Styles.Spacing.m2,
      lineHeight: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
  };
}

export function stylePayAvailOption(styles) {
  return {...styles.payAvailableOption, backgroundColor: '#409F68'};
}

export function getSettingsMenusStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    listShadow: null,
    optionText: {
      minHeight: Styles.Heights.touchTargetHeight0,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
      paddingTop: 3,
      color: Styles.darkColor,
      textAlign: 'center',
      fontWeight: '400',
    },
    enclosureContainer: {
      borderColor: Styles.lightGray,
      borderRadius: 10,
      borderWidth: 1,
      marginHorizontal: Styles.Spacing.m3,
    },
    divider: {
      height: 1,
      marginHorizontal: 10,
      backgroundColor: Styles.lightGray,
    },
  };
}

export function claimBtnColorDescriptor(): string {
  return Styles.Colors.CompanyKitchen.green;
}
export function claimedBtnColorDescriptor(): string {
  return Styles.Colors.CompanyKitchen.lightGray;
}

export function snackMessageDescriptor(
  props?: SnackItemDescriberProps,
): string | Formatted[] {
  const {snack = {amount: 0, senderName: ''}} = props || {};
  return Localized.Labels.formatString(
    Localized.Labels.you_have_received_a_snack,
    Util.formatCurrency(snack, snack?.amount ?? 0, AccountStore.getCurrency()),
    snack?.senderName ?? '',
  );
}
export function claimeSucessdBtnColorDescriptor(): string {
  return Styles.Colors.Canteen.greenSelection;
}
export function claimedErrorBtnColorDescriptor(): string {
  return '#d7263d26';
}
export function settingsMenuFontColorDescriptor(): string {
  return Styles.lightGray;
}
export function renderReferralStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    referralSuccErrorText: {
      color: Styles.black,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
    },
    referralDescText: {
      color: Styles.black,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f8,
    },
    referralHederText: {
      color: Styles.black,
      fontWeight: '700',
      fontSize: Styles.Fonts.sectionHeader,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
  };
}
export function addNewPaymentTemplateDescriptor(): JSX.Element {
  return <FontAwesome5Pro color="#555" light name="plus-circle" size={26} />;
}
export function walletScreenCardStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    descriptionText: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: 17,
      fontWeight: '700',
      marginHorizontal: 24,
      marginTop: 24,
      color: Styles.black,
    },
    txtInput: {
      fontSize: 14,
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.black,
      fontWeight: '400',
      height: 56,
      marginTop: 12,
    },
    cardNumberLabel: {
      fontSize: Styles.Spacing.m3 + 2,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '400',
      color: '#707070',
    },
    cardLabels: {
      fontSize: Styles.Spacing.m3,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '400',
      color: '#707070',
    },
    setDefaultLabel: {
      fontSize: Styles.Fonts.f1,
      marginRight: Styles.Spacing.m2,
      color: Styles.black,
      marginLeft: Styles.Spacing.m1,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    saveFontStyle: {
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    balancesTitle: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      color: Styles.tabBarTextColor,
      marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
      marginBottom: Styles.Spacing.m2 + Styles.Spacing.m2 - 2,
    },
  };
}
export function walletForntStyleText(): string {
  return Styles.FontFamily.robotoRegular;
}
export function getSnackItemStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderBottomColor: Styles.lightGray,
      flexDirection: 'row',
      minHeight: Styles.Heights.touchTargetHeight2,
      flex: 1,
      padding: Styles.Spacing.m3,
    },
    message: {
      color: Styles.lightGray,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '400',
      fontSize: Styles.Fonts.f8,
      flex: 1,
    },
    from: {
      color: Styles.darkColor,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f1,
    },
    messageLeft: {
      flex: 1,
      marginLeft: Styles.Spacing.m2,
      marginRight: Styles.Spacing.m3,
    },
    referalTitle: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '700',
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1 + 2,
    },
    subject: {
      color: Styles.darkColor,
      fontFamily: Styles.FontFamily.robotoRegular,
      marginBottom: Styles.Spacing.m1,
      marginTop: Styles.Spacing.m1,
      fontWeight: '400',
      fontSize: Styles.Fonts.f1,
    },
    chevronRight: {
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      width: 24,
      height: 24,
    },
    topRight: {
      flexDirection: 'row',
    },
    titleHeader: {
      fontWeight: '700',
      color: Styles.darkColor,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
    },
    date: {
      fontFamily: Styles.FontFamily.robotoRegular,
      paddingTop: Styles.Spacing.m1,
      color: Styles.lightGray,
      fontWeight: '400',
      left: Styles.Spacing.m4 + 4,
      fontSize: Styles.Fonts.f1,
    },
    referralDate: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '400',
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f1,
      left: Styles.Spacing.m4 + 12,
    },
    claimedBtnText: {
      minWidth: 75,
      paddingHorizontal: 10,
      paddingVertical: 3,
      backgroundColor: '#21ad5a26',
      borderColor: '#21ad5a26',
      borderWidth: 1,
      borderRadius: 4,
    },
    referralStatusText: {
      fontSize: 14,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '400',
    },
    expiredBtnText: {
      paddingHorizontal: 10,
      paddingVertical: 3,
      backgroundColor: '#D7263D26',
      borderColor: '#D7263D26',
      borderWidth: 1,
      borderRadius: 4,
      minWidth: 75,
    },
  };
}

export function snackItemTitleDescriptor(): string {
  return Settings.appDisplayName;
}

export function switchColor(): string {
  return null;
}

export function getuserInitialsStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    initialsText: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f2,
    },
    initialsBtn: {
      flexDirection: 'row',
      borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m3),
      margin: PixelRatio.roundToNearestPixel(0.5),
      justifyContent: 'center',
      width: 72,
      height: 72,
      backgroundColor: Styles.Colors.CompanyKitchen.blue,
    },
    text: {
      fontWeight: 'bold',
      alignSelf: 'center',
      color: Styles.primaryColor,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
  };
}

export function dealRedeemCount(text: string): JSX.Element {
  return (
    <Text
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
      style={[
        dealStyleDescriptor()['dealTarget'],
        dealStyleDescriptor()['successText'],
      ]}
    >
      {text}
    </Text>
  );
}
export function cardsMainViewBgColor(): string {
  return null;
}
export function dealRenderDealIcon(): JSX.Element {
  return (
    <View style={{justifyContent: 'center'}}>
      <NotifyDealIcon height={50} bgColor={'#003349'} width={50} />
    </View>
  );
}
export function dealRenderDealNameDesc(props?: DealProps): JSX.Element {
  return (
    <>
      <Text
        accessibilityLabel={props?.deal?.message}
        style={dealStyleDescriptor()['dealDesc']}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
        numberOfLines={3}
      >
        {props?.deal?.message}
      </Text>
    </>
  );
}
export function dealRenderExpirationTxtTemplate(
  props?: DealProps,
): JSX.Element {
  let _expirationPfx = Localized.Labels.expires;
  if (props.expired) {
    _expirationPfx = Localized.Labels.expired;
  }
  const _exprtnText = `${_expirationPfx} ${moment(props.deal.endDate).calendar(
    null,
    {
      sameDay: `[${Localized.Labels.today}]`,
      nextWeek: 'dddd',
    },
  )}`;
  return (
    <Text
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
      style={dealStyleDescriptor()['expirationDate']}
    >
      {_exprtnText}
    </Text>
  );
}

export function getTypoFontSize() {
  return 18;
}

export function scanScreenQRCodeStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    btnAddToWallet: {
      borderWidth: 2,
      paddingTop: 8,
      paddingLeft: 12,
      paddingRight: 12,
      paddingBottom: 8,
    },
    btnEmailReceipt: {
      paddingTop: 6,
      paddingRight: 12,
      paddingLeft: 12,
      paddingBottom: 6,
      borderWidth: 2,
    },
    btnAddFund: {
      paddingRight: 24,
      paddingBottom: 8,
      paddingLeft: 24,
      paddingTop: 8,
    },
    btnDone: {
      paddingRight: 12,
      paddingTop: 6,
      paddingLeft: 12,
      paddingBottom: 6,
    },
    addFundsText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: Styles.Fonts.f1,
      color: '#066DFF',
      fontWeight: '700',
    },
    addToWalletText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      color: '#066DFF',
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
    },
    addFundsButtonContainer: {
      marginTop: Styles.Spacing.m3 + 6,
      justifyContent: 'center',
      alignItems: 'center',
    },
    balanceText: {
      textTransform: 'uppercase',
      fontFamily: Styles.FontFamily.comodoVintage,
      fontSize: Styles.Fonts.sectionHeader,
      fontWeight: '400',
      color: Styles.black,
    },
  };
}

export function snackItemGiftBoxTemplateDescriptor(): JSX.Element {
  return (
    <View style={{justifyContent: 'center', backgroundColor: 'transparent'}}>
      <GiftIconCP />
    </View>
  );
}

export function scanScreenQRCodeHeaderText(): string {
  return Localized.Labels.pay_at_kiosk;
}

export function referralImageTemplateDescriptor(
  props?: ReferralItemDescriberProps,
): JSX.Element {
  return (
    <View
      style={{
        right: 5,
        justifyContent: 'center',
      }}
    >
      {props.isSender ? (
        <Referral width={48} height={48} />
      ) : (
        <SignUp width={48} height={48} />
      )}
    </View>
  );
}

function getAnimatedViewDescriptor(
  rotate: Animated.AnimatedInterpolation<string>,
  props: {header: React.ReactNode},
) {
  return (
    <>
      <Animated.View
        style={[
          {
            transform: [{rotate}],
          },
          styles.chevron,
        ]}
      >
        <FontAwesome5Pro
          name={'chevron-right'}
          size={Styles.Fonts.f2}
          color={Styles.lightGray}
          light
        />
      </Animated.View>
      {props.header}
    </>
  );
}

export function RewardPointStyleDescriptor(props: RewardsSection): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.robotoRegular,
    fontSize: 24,
    fontWeight: '400',
    color: '#111',
  };
}

export function getSuccessDialogDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    header: {
      fontWeight: '700',
      color: Styles.black,
      marginTop: Styles.Spacing.m2,
      fontSize: Styles.Fonts.f9,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    okayButtonText: {
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
      textAlign: 'center',
      color: Styles.white,
      width: 82,
      fontFamily: Styles.FontFamily.robotoRegular,
      marginLeft: Styles.Spacing.m1,
    },
    title: {
      color: Styles.darkColor,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '600',
      fontSize: Styles.Fonts.f1,
      marginTop: Styles.Spacing.m3,
    },
  };
}

export function getSuccessDialogBtn(callBack: () => void) {
  return (
    <RoundedButton
      buttonType={ButtonType.normal}
      onPress={callBack}
      text={Localized.Buttons.okay}
      accessibilityLabel={`${Localized.Buttons.okay}`}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
      textStyle={getSuccessDialogDescriptor()['okayButtonText']}
      containerStyle={{
        alignSelf: 'center',
        marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
      }}
    />
  );
}

export function dealStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    dealDesc: {
      marginBottom: Styles.Spacing.m1,
      fontSize: Styles.Fonts.f8,
      maxWidth: '90%',
      flex: 1,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '400',
      color: Styles.lightGray,
    },
    successText: {
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.positiveColor,
      fontWeight: 'bold',
    },
    expirationDate: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f1,
      left: Styles.Spacing.m4,
      fontFamily: Styles.FontFamily.robotoRegular,
      paddingTop: Styles.Spacing.m1,
      fontWeight: '400',
    },
    arrow: {
      marginLeft: Styles.Spacing.m3,
      alignItems: 'center',
      justifyContent: 'center',
    },
    titleHeader: {
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.darkColor,
    },
    dealTarget: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f1,
      alignSelf: 'flex-end',
    },
    inboxTabBgColor: {
      backgroundColor: 'transparent',
    },
    container: {
      borderBottomWidth: StyleSheet.hairlineWidth,
      minHeight: Styles.Heights.touchTargetHeight2,
      borderBottomColor: Styles.lightGray,
      flexDirection: 'row',
      flex: 1,
      padding: Styles.Spacing.m3,
    },
    dealName: {
      fontFamily: Styles.FontFamily.robotoRegular,
      marginRight: Styles.Spacing.m2,
      fontWeight: '400',
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1,
      flex: 1,
      marginBottom: Styles.Spacing.m1,
    },
  };
}

export function dealRenderDealTopSection(props?: DealProps): string {
  return props.deal.name;
}

export function successModalSnackGifDescriptor() {
  return require('../../img/SnackGift.gif');
}

export function collapsibleComponentDescriptor(): CollapsibleComponentProps {
  return {
    toValue: 90,
    toDegree: '90deg',
    content: {
      paddingHorizontal: Styles.Spacing.m3,
      justifyContent: 'flex-start',
      flexDirection: 'column',
      marginVertical: Styles.Spacing.m2,
    },
    parentContainer: {flexDirection: 'row', flex: 1},
  };
}

export function getStyles(): StylesDescriptor {
  return {
    container: {
      marginTop: Styles.Spacing.m3,
    },
    paymentDropDownInputContainer: {
      height: 55,
      padding: 2,
      borderColor: Styles.lightGray,
      marginBottom: Styles.Spacing.m2,
      borderRadius: 10,
      overflow: 'hidden',
      flexDirection: 'row',
      borderWidth: 1,
    },
    paymentInput: {
      fontSize: Styles.Fonts.f7,
      color: Styles.Colors.CompanyKitchen.black,
      backgroundColor: Styles.white,
      fontWeight: '700',
      flex: 1,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    imageContainer: {
      right: 10,
      bottom: 50,
      flex: 1,
      justifyContent: 'flex-end',
      flexDirection: 'row',
    },
    leftIcon: {
      marginRight: 4,
      borderRadius: 6,
      paddingEnd: 2,
      backgroundColor: Styles.white,
      paddingVertical: 2,
    },
    labelContainer: {
      position: 'absolute',
      backgroundColor: Styles.tabBarBackgroundColor,
      paddingHorizontal: 8,
    },
    label: {
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.lightGray,
      fontSize: 14,
    },
    error: {
      marginLeft: Styles.Spacing.m1,
      fontSize: 12,
      marginTop: Styles.Spacing.m1,
      color: Styles.dangerColor,
    },
    help: {
      marginTop: Styles.Spacing.m1,
      fontSize: 12,
      color: Styles.lightGray,
      marginLeft: Styles.Spacing.m1,
    },
    input: {
      padding: Styles.Spacing.m3,
      backgroundColor: Styles.white,
      borderRadius: 4,
      fontWeight: '600',
      marginBottom: 5,
      borderWidth: 1,
      fontSize: 16,
    },
  };
}

export function dealItemNumberDescriptor(): number {
  return 2;
}

export function snackItemNumberDescriptor(): number {
  return 2;
}

export function getTextInputHeight(multiline?: boolean): {
  [index: string]: number | string;
} {
  return {
    textAlignVertical: null,
    inputHeight: multiline ? 55 : null,
  };
}

export function getTopPadding(): {
  [index: string]: number | string;
} {
  return {};
}

export function getBackgroundColor() {
  return Styles.white;
}

export function getAnimationConfig(label?: string, iconName?: string) {
  return {
    scale: [1, 0.8],
    translateX: [2, (label || '').length >= 11 ? 7 - (label || '').length : 2],
    translateY: [18, !iconName ? 2 : 9],
  };
}

export function getDropDownMenuStyles(props): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    style: {
      width: Platform.OS !== 'web' ? '90%' : 'auto',
    },
    contentStyle: {
      backgroundColor: Styles.white,
      borderRadius: props.dropDownHeader ? 10 : 0,
    },
    touchableOpacity: {},
  };
}

export function getDropDownMenuListStyle(dropDownSize, maxWidth, props, index) {
  return {
    options: {
      flexDirection: 'row',
      padding: Styles.Spacing.m3,
      alignItems: 'center',
      minHeight: Styles.Heights.touchTargetHeight1,
      width: dropDownSize?.width || maxWidth,
    },
    optionTextStyling: {
      color: '#000000',
      fontWeight: '700',
      fontSize: 16,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    optionPaymentText: {},
    optionTextStylingForPayment: {},
    amexSpecificMargin: {},
    parentContainer: {
      props,
      index,
    },
    optionImageExtraMargins: {},
  };
}

export function getIcon(props, isFocused?: boolean) {
  if (props.hasIcon) {
    return (
      <TouchableWithoutFeedback
        onPress={props.iconPressed}
        accessible={true}
        accessibilityRole="button"
        accessibilityLabel={props.iconLabel}
        role="button"
        aria-label={props.iconLabel}
      >
        <View
          style={[
            styles.iconContainer,
            props.iconName === 'closecircleo' && {
              top: Platform.OS === 'ios' ? -41 : -45,
              right: 15,
            },
          ]}
        >
          {props.iconName !== 'closecircleo' && (
            <Icon
              size={28}
              color={isFocused ? Styles.primaryColor : Styles.darkColor}
              name={props.iconName}
              style={styles.icon}
            />
          )}
          {props.iconName === 'closecircleo' && (
            <CloseIcon width={28} height={28} color={'#727179'} />
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
  return null;
}
const styles = StyleSheet.create({
  chevron: {
    alignSelf: 'baseline',
    marginEnd: Styles.Spacing.m3,
  },
  iconContainer: {
    top: Platform.OS == 'ios' ? 5 : 0,
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
  },
  icon: {
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    width: 50,
    height: 50,
    position: 'absolute',
  },
});
const checkCanteen = () => {
  return Settings.buildType == 'canteen'
    ? Styles.primaryColor
    : Styles.lightGray;
};

export function stylePayUnAvailOption(_styles) {
  return _styles.payUnavailableOption;
}

export function payOverlayTxt() {
  return {right: -7};
}

export function getColor(isFocused: boolean, hasError: boolean) {
  if (hasError) {
    return Styles.dangerColor;
  }
  return isFocused ? checkCanteen() : Styles.lightGray;
}
export function homePageTitlesScreenStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    viewAll: {
      color: Styles.blackBase01,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    title: {
      fontSize: Styles.Fonts.headerFont,
      fontWeight: '700',
      color: Styles.blackBase01,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
  };
}
export function rendercommonSecNewStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    headerContainer: {
      justifyContent: 'space-between',
      marginBottom: Styles.Spacing.m2,
      alignItems: 'center',
      paddingHorizontal: Styles.Spacing.m3,
      flexDirection: 'row',
    },
    container: {
      marginTop: Styles.Spacing.m4,
      minHeight: Styles.Heights.touchTargetHeight0,
    },
    header: {
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f2,
      fontWeight: '700',
    },
  };
}

export function referralDetailBgColorDescriptor(): string {
  return Styles.white;
}

export function navigateToWalletScreen() {
  setTimeout(() => {
    NavActions.push(AppRoutes.Wallet, {hideBackArrow: false});
  }, 1000);
}

export function rewardAmtTxt(_styles, isAvailable?: boolean) {
  if (!isAvailable) {
    return {..._styles.payUnAvailableText, marginTop: Styles.Spacing.m1};
  } else {
    return {..._styles.payAvailableText, marginTop: Styles.Spacing.m1};
  }
}

export function PasswordInputMaxFontSizeMultipleDescriptor(): number {
  return Styles.FontSizeMultiplier.maxfm11;
}

export function rewardPointTxt(_styles, isAvailable?: boolean) {
  if (!isAvailable) {
    return {..._styles.payOverlayPoint, ..._styles.payUnAvailablePoint};
  } else {
    return {..._styles.payOverlayPoint, ..._styles.payAvailablePoint};
  }
}

export function commonutilsRenderSwitchTemplate(
  props?: CommonUtilsSwitchProps,
): JSX.Element {
  const {onSwitchToggle, switchStatus} = props;
  if (switchStatus && onSwitchToggle) {
    return (
      <CustomToggleSwitch
        value={props.switchStatus}
        nativeID={props.title}
        accessibilityLabel={props.title}
        accessible={true}
        onValueChange={() => onSwitchToggle()}
        aria-label={props.title}
        accessibilityHint="Double tap to activate the switch"
        accessibilityValue={{text: props.switchStatus.toString()}}
        accessibilityState={{checked: props.switchStatus}}
        aria-valuetext={props.switchStatus.toString()}
        accessibilityRole="switch"
        aria-checked={props.switchStatus}
      />
    );
  } else {
    return null;
  }
}

export function textInputColor(): string {
  return Styles.darkColor;
}

export function activeTabShadowColor(): string {
  return Styles.white;
}

export function checkRewardsBackgroundColor() {
  return [Styles.white, Styles.white, `${Styles.white}99`];
}

export function checkRoundedBtnColor(): string {
  return '#003349';
}
export function scanAtKioskLbl() {
  return Localized.Labels.Scan_at_kiosk;
}
export function PasswordInputmaxlength(): number {
  return 24;
}

export function numberSpinnerColor(): string {
  return Styles.white;
}

export function tabBarRoundedBtnColor(): string {
  return Styles.Colors.CompanyKitchen.green;
}
export function numberSpinnerStyles(): NumberSpinnerStylesDescriptor {
  return StyleSheet.create({
    num: {
      paddingHorizontal: Styles.Spacing.m3,
      justifyContent: 'center',
      marginTop: 5,
      alignItems: 'center',
    },
    numText: {
      textAlign: 'center',
    },
  });
}

export function referralSummaryTotal(): {
  [index: string]: number | string;
} {
  return {
    fontWeight: '700',
    fontFamily: Styles.FontFamily.robotoRegular,
    color: '#111',
    fontSize: 24,
  };
}

export function referralTransactionId(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.robotoRegular,
    fontWeight: '400',
    paddingBottom: Styles.Spacing.m3,
    fontSize: 14,
    color: '#111',
  };
}

export function referrallocationName(): {
  [index: string]: number | string;
} {
  return {
    paddingBottom: Styles.Spacing.m2 - 2,
    fontSize: 16,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.robotoRegular,
    color: '#111',
  };
}
export function renderpointsLbl(_styles) {
  return <></>;
}

export function rewardsPointLbl(): string {
  return Localized.Labels.points;
}

export function toggleColor() {
  return ['#707070', Styles.Colors.CompanyKitchen.blue];
}

export function fontStyleSuccessModal() {
  return {fontFamily: Styles.FontFamily.aeonikRegular};
}

export function referralDate(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.robotoRegular,
    color: '#111',
    fontSize: 16,
    fontWeight: '400',
    paddingBottom: Styles.Spacing.m3,
  };
}

export function rewardPoints(
  _styles,
  i,
  rewardOptions,
  largestAvailableRewardAmount,
  isRewardButtonShown,
) {
  return (
    <View
      style={[
        _styles.payGraph,
        isRewardButtonShown && {
          marginTop:
            PixelRatio.getFontScale() > Styles.FontSizeMultiplier.maxfm0
              ? Styles.Spacing.m5
              : Styles.Spacing.m4,
        },
      ]}
      accessible={true}
      accessibilityLabel={`${largestAvailableRewardAmount} in rewards available`}
      accessibilityRole="image"
      aria-label={`${largestAvailableRewardAmount} in rewards available`}
      role="img"
      key={-1}
    >
      <View style={_styles.line}>
        <View key={i++} style={[_styles.rewardOption, _styles.flex1]}>
          <View
            style={[
              _styles.payAvailableOption,
              _styles.firstSection,
              {
                flex: Styles.Spacing.m1,
                backgroundColor: '#409F68',
              },
            ]}
          />
        </View>
      </View>

      <View style={[_styles.line, _styles.flex15]}>{rewardOptions}</View>

      <View style={_styles.line}>
        <View key={i++} style={[_styles.rewardOption, _styles.flex1]}>
          <View
            style={[
              _styles.payUnavailableOption,
              _styles.lastSection,
              {
                flex: Styles.Spacing.m1,
              },
            ]}
          />
        </View>
      </View>
    </View>
  );
}

export function sendSnackOTSNBtnStyleDescriptor(): string {
  return Styles.darkGreenRevolve;
}

export function sendSnackOTSNotificationStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    acceptSnackText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    acceptedSnactSubDesc: {
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.aeonikRegular,
      color: Styles.black,
    },
    acceptSnackHeaderText: {
      fontWeight: '700',
      color: Styles.black,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: Styles.Fonts.sectionHeader,
    },
    saveContainer: {},
    acceptedSnactHeaderText: {
      color: Styles.black,
      fontSize: Styles.Fonts.sectionHeader + 2,
      fontWeight: '700',
      marginLeft: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    acceptSnackSubDescText: {
      color: Styles.black,
      fontWeight: '400',
      fontSize: Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
  };
}

export function historyListStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    historyHeaderTitle: {
      fontWeight: '700',
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.sectionHeader,
      paddingBottom: Styles.Spacing.m3,
      paddingTop: Styles.Heights.h2,
    },
    historyTabFontFamily: {
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    historyHeaderTitleDes: {
      alignSelf: 'flex-start',
      flex: 2,
      fontWeight: '700',
      color: Styles.black,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
    },
    historyTabActiveFontColor: {
      color: Styles.Colors.CompanyKitchen.black,
    },
    historyTabInactiveFontColor: {
      color: Styles.Colors.CompanyKitchen.black,
    },
  };
}

export function historyDateBtnColor(): string {
  return Styles.lightGrayRevolve;
}

export function fundingAmountColor(): string {
  return Styles.darkColor;
}

export function getScanKiosk() {
  return {
    borderWidth: 2,
    alignSelf: 'center',
    paddingHorizontal: 16,
    paddingVertical: 10,
    overflow: 'hidden',
  };
}

export function getPayKiosk() {
  return {
    borderWidth: 2,
    alignSelf: 'center',
    paddingLeft: 16,
    overflow: 'hidden',
    paddingRight: 16,
    paddingVertical: 10,
  };
}

export function getBalanceValueTextColor(): TextStyle {
  return {
    color: Styles.Colors.CompanyKitchen.black,
  };
}

export function referralIconColor(): string | null {
  return Styles.Colors.CompanyKitchen.blue;
}

export function checkEmailSuccessMsg(): string {
  return Localized.Success.receipt_send_success;
}
export function checkEmailreceiptBtnTxt(): string {
  return Localized.Buttons.email_receipts;
}

export function eGiftCardSuccesNavigation() {
  return NavActions.popToRoute(AppRoutes.Cards);
}

export default {
  addNewPaymentTemplateDescriptor,
  historyListStyleDescriptor,
  snackItemNumberDescriptor,
  settingsMenuFontColorDescriptor,
  getNotificationScreenStyles,
  getTypoFontSize,
  onboardingSelectLocationTextDescriptor,
  getInboxTabStyles,
  getuserInitialsStyleDescriptor,
  walletForntStyleText,
  dealRenderExpirationTxtTemplate,
  snackItemGiftBoxTemplateDescriptor,
  getSnackItemStyleDescriptor,
  dealRedeemCount,
  historyDateBtnColor,
  onboardingLocaionBorderColor,
  snackItemTitleDescriptor,
  getSuccessDialogDescriptor,
  getSuccessDialogBtn,
  onboardingLocaionBgColor,
  cardsMainViewBgColor,
  getSettingsMenusStyleDescriptor,
  fundingAmountColor,
  checkEmailreceiptBtnTxt,
  PasswordInputMaxFontSizeMultipleDescriptor,
  snackMessageDescriptor,
  PasswordInputmaxlength,
  getAnimatedViewDescriptor,
  getStyles,
  dealRenderDealNameDesc,
  getColor,
  snackItemDateDescriptor,
  dealItemNumberDescriptor,
  dealRenderDealIcon,
  getTopPadding,
  checkRoundedBtnColor,
  collapsibleComponentDescriptor,
  dealRenderDealTopSection,
  dealStyleDescriptor,
  getAnimationConfig,
  rendercommonSecNewStyleDescriptor,
  commonutilsRenderSwitchTemplate,
  textInputColor,
  payAtKioskLbl,
  getBackgroundColor,
  getTextInputHeight,
  getIcon,
  activeTabShadowColor,
  scanScreenQRCodeStyleDescriptor,
  numberSpinnerStyles,
  stylePayAvailOption,
  claimBtnColorDescriptor,
  claimedBtnColorDescriptor,
  claimedErrorBtnColorDescriptor,
  claimeSucessdBtnColorDescriptor,
  renderReferralStyleDescriptor,
  getDropDownMenuStyles,
  scanScreenQRCodeHeaderText,
  toggleColor,
  getDropDownMenuListStyle,
  walletScreenCardStyles,
  historyHeaderName,
  renderpointsLbl,
  numberSpinnerColor,
  referralImageTemplateDescriptor,
  referralItemDateDescriptor,
  getPayKiosk,
  rewardsPointLbl,
  fontStyleSuccessModal,
  referralSummaryTotal,
  checkRewardsBackgroundColor,
  RewardPointStyleDescriptor,
  referralTransactionId,
  stylePayUnAvailOption,
  referralDetailBgColorDescriptor,
  scanAtKioskLbl,
  rewardPointTxt,
  rewardAmtTxt,
  switchColor,
  referrallocationName,
  successModalSnackGifDescriptor,
  rewardPoints,
  payOverlayTxt,
  referralDate,
  navigateToWalletScreen,
  tabBarRoundedBtnColor,
  homePageTitlesScreenStyles,
  getScanKiosk,
  checkEmailSuccessMsg,
  sendSnackOTSNotificationStyleDescriptor,
  sendSnackOTSNBtnStyleDescriptor,
  getBalanceValueTextColor,
  onboardingFlowStyleDescriptor,
  referralIconColor,
  eGiftCardSuccesNavigation,
};
