import Settings from 'src/Settings';
import RedesignDescriptor from './notification-redesign';
import CanteenDescriptor from './notification-canteen';
import RefiveDescriptor from './notification-refive';
import CompanyKitchenDescriptor from './notification-companykitchen';
import BuildTypes from 'src/constants/BuildTypeConstants';

const typeNotificationDescriber = {
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export function getNotificationDescriber() {
  return typeNotificationDescriber[
    Settings.buildType as keyof typeof typeNotificationDescriber
  ];
}
