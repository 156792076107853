export const ReferralStatus = {
  Active: 'N',
  Claimed: 'Y',
  Expired: 'E',
};
export type Referral = {
  referralId: string;
  campaign: string;
  orgId: string;
  locationId: string;
  sender: string;
  receiver: string | null | undefined;
  method: string;
  contact: string;
  appName: string;
  senderRedeem: string;
  receiverRedeem: string;
  senderName: string;
  receiverName: string | null | undefined;
  isActive: boolean;
  endDate: string | null | undefined;
  amount?: number | null | undefined;
  dateCreated: string;
  lastUpdated: string;
  isRead?: boolean;
  externalServiceId?: string;
};
export type ReferralStat = {
  signUps: number;
  referrals: number;
};
export type ReferralCampaign = {
  campaignId: string;
  org: string;
  orgName: string;
  location: string;
  locationName: string;
  amount: number;
  isActive: boolean;
  endDate: string;
  maxRedeem: number;
  dateCreated: string;
};
export type ReferralRewardSummary = {
  referralId: string;
  orgId: string;
  orgName: string;
  locationId: string;
  locationName: string;
  actionType: string;
  amount: number;
  transactionDate: string;
  transactionId: string;
  status: string;
  name: string;
  reason: string;
  accountId: string;
  balance: number;
  dateCreated: string;
  currency: string;
  referenceId: string;
  receiver: string;
  receiverName: string;
  sender: string;
  senderName: string;
};
