import React from 'react';
import {FlatList, StyleProp, ViewStyle, View} from 'react-native';
import type {ProductCategory} from 'src/types/ProductCategory';

import {getDescriber} from './descriptor/DescriptorType';
type ProductCategoriesProps = {
  categories: Array<ProductCategory>;
  selectedCategory: string | number;
  onCategoryPress: (category: ProductCategory) => void;
  style: StyleProp<ViewStyle>;
};

export default (props: ProductCategoriesProps) => {
  const renderItem = ({
    item,
    index,
  }: {
    item: ProductCategory;
    index: number;
  }) => {
    return getDescriber().productCatrenderItem({
      item,
      index,
      selectedCategory: props.selectedCategory,
      categoriesLength: props.categories.length,
      onCategoryPress: props.onCategoryPress,
    });
  };

  return (
    <View>
      <FlatList
        style={props.style}
        data={props.categories}
        renderItem={renderItem}
        horizontal={true}
        keyExtractor={(item) => `${item.key}`}
      />
    </View>
  );
};
