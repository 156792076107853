import Geolocation from '@react-native-community/geolocation';

class LocationHandler {
  isLocationPermissionEnabled = false;
  currentLocation: GeolocationPosition = null;

  // When called first it will trigger location permission alert
  //Retuns position object if location permission is enabled or else returns error
  async getCurrentLocation() {
    Geolocation.getCurrentPosition(
      (position) => {
        this.isLocationPermissionEnabled = true;
        this.currentLocation = position;
        return position;
      },
      (error) => {
        this.isLocationPermissionEnabled = false;
        this.currentLocation = null;
        return error;
      },
      {
        enableHighAccuracy: false,
        timeout: 30000,
        maximumAge: 1000,
      },
    );
  }
}

export default new LocationHandler();
