import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';
interface IProps {
  size?: number;
  color?: string;
}
const HomeRevolveIcon = ({size = 34, color = '#727179'}: IProps) => (
  <Svg width={size} height={size} viewBox="0 0 34 34" fill="none">
    <G clipPath="url(#clip0_8661_10715)">
      <Path
        d="M18.28 4.287a1.211 1.211 0 00-1.565 0L3.423 15.565a1.21 1.21 0 00-.141 1.702 1.21 1.21 0 001.702.14l1.238-1.047v9.39a4.027 4.027 0 004.028 4.028h14.5a4.027 4.027 0 004.028-4.028v-9.39l1.233 1.047a1.206 1.206 0 001.702-.14 1.21 1.21 0 00-.141-1.702L18.28 4.287zM8.64 25.75V14.311L17.5 6.794l8.861 7.517V25.75a1.61 1.61 0 01-1.611 1.611h-2.417v-7.653a2.013 2.013 0 00-2.014-2.014h-5.638a2.013 2.013 0 00-2.014 2.014v7.653H10.25a1.61 1.61 0 01-1.611-1.611zm6.444 1.611v-7.25h4.834v7.25h-4.834z"
        fill={color}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_8661_10715">
        <Path fill="#fff" transform="translate(3 4)" d="M0 0H29V25.7778H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default HomeRevolveIcon;
