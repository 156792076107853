import RedesignDescriptor from './cart-elements-redesign';
import ConnectandPayDescriptor from './cart-elements-canteen';
import CompanyKitchenDescriptor from './cart-elements-companykitchen';
import RefiveDescriptor from './cart-elements-refive';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import type {ProductCategory} from 'src/types/ProductCategory';

const typeDescriber = {
  [BuildTypes.canteen]: ConnectandPayDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export type RenderItemProps = {
  item: ProductCategory;
  index?: number;
  onCategoryPress?: (item: ProductCategory) => void;
  selectedCategory?: string | number;
  categoriesLength?: number;
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
