import * as React from 'react';
import Svg, {
  G,
  Path,
  Defs,
  LinearGradient,
  Stop,
  ClipPath,
} from 'react-native-svg';

export default class Milk extends React.Component {
  render() {
    return (
      <Svg width={36} height={37} viewBox="0 0 36 37" fill="none">
        <G
          clipPath="url(#clip0_4773_15131)"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <Path
            d="M27.818.916H6.545v5.727h21.273V.916zm-8.182 14.727l6.546-6.546H6.545L0 15.643h19.636zM36 36.916V18.098l-7.364-8.182-7.363 8.182v18.818H36zm-17.182 0V18.098H0v18.818h18.818z"
            fill="url(#paint0_linear_4773_15131)"
          />
          <Path
            d="M3.905 24.648v-.817h1.633v8.573H3.905v-7.757zm11.062 0v-.817h-1.633v8.573h1.633v-7.757zm-3.143-.816v4.899H10.19v-4.9h1.633zm-1.633 7.757v.817h1.633v-2.042H10.19v1.225zm-1.51-7.757v4.899H7.048v-4.9H8.68zm-1.633 7.757v.817H8.68v-2.042H7.048v1.225z"
            fill="#111"
          />
        </G>
        <Defs>
          <LinearGradient
            id="paint0_linear_4773_15131"
            x1={0.485217}
            y1={40.2568}
            x2={34.8932}
            y2={4.93219}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#006DFF" />
            <Stop offset={1} stopColor="#FEE0E6" />
          </LinearGradient>
          <ClipPath id="clip0_4773_15131">
            <Path fill="#fff" transform="translate(0 .916)" d="M0 0H36V36H0z" />
          </ClipPath>
        </Defs>
      </Svg>
    );
  }
}
