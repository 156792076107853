import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  size?: number;
  color?: string;
}
const HomeIcon = ({size = 34, color = '#727179'}: IProps) => (
  <Svg width={size} height={size} viewBox="0 0 34 34" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.8375 19.6832V26.4857C26.8375 27.1171 26.3111 27.625 25.6568 27.625H19.9502C19.6255 27.625 19.3599 27.3687 19.3599 27.0554V21.7387C19.3599 21.4254 19.0942 21.1691 18.7695 21.1691H15.2275C14.9028 21.1691 14.6372 21.4254 14.6372 21.7387V27.0554C14.6372 27.3687 14.3715 27.625 14.0468 27.625H8.34024C7.68595 27.625 7.15956 27.1171 7.15956 26.4857V19.6832C7.15956 19.5124 7.23828 19.351 7.37602 19.2418L16.6247 11.8934C16.8411 11.7225 17.156 11.7225 17.3773 11.8934L26.626 19.2418C26.7588 19.351 26.8375 19.5124 26.8375 19.6832ZM30.9502 16.7923L26.8375 13.5216V6.94701C26.8375 6.63371 26.5719 6.37737 26.2472 6.37737H23.4923C23.1676 6.37737 22.9019 6.63371 22.9019 6.94701V10.3933L18.499 6.8948C17.6282 6.20173 16.3688 6.20173 15.4981 6.8948L3.04687 16.7923C2.79598 16.9917 2.76154 17.3525 2.96816 17.5946L4.22263 19.0661C4.42925 19.3082 4.80313 19.3415 5.05402 19.1421L16.6247 9.94713C16.8411 9.77623 17.156 9.77623 17.3773 9.94713L28.948 19.1421C29.1989 19.3415 29.5727 19.3082 29.7794 19.0661L31.0338 17.5946C31.2404 17.3477 31.2011 16.9917 30.9502 16.7923Z"
      fill={color}
    />
  </Svg>
);
export default HomeIcon;
