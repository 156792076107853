import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
const CanteenRocketIcon = (props) => (
  <Svg width={393} height={221} viewBox="0 0 393 221" fill="none" {...props}>
    <Path
      d="M233.672 78.431L219.661 64.4241C208.065 68.9301 193.344 76.5177 181.008 88.8508C179.022 90.8357 177.118 92.8226 175.299 94.8075C169.633 100.953 164.708 107.11 160.682 112.952L185.126 137.389C192.863 132.058 201.132 125.17 209.236 117.069C221.573 104.736 229.164 90.0192 233.67 78.4269L233.672 78.431ZM204.532 93.5552C200.297 89.3214 200.287 82.454 204.522 78.2223C208.757 73.9885 215.637 73.9885 219.87 78.2223C224.105 82.4561 224.094 89.3235 219.859 93.5552C215.624 97.789 208.767 97.789 204.532 93.5552ZM239.558 58.541C239.558 58.541 233.328 59.6071 224.252 62.7421L235.356 73.8432C238.492 64.7699 239.558 58.541 239.558 58.541ZM217.941 91.6337C214.767 94.8075 209.627 94.8075 206.452 91.6337C203.278 88.4599 203.271 83.3135 206.444 80.1397C209.619 76.9659 214.775 76.9659 217.95 80.1397C221.124 83.3135 221.116 88.4599 217.941 91.6337ZM139.992 113.007L163.871 89.1352L173.405 92.9188C167.74 99.0638 162.815 105.221 158.789 111.063L139.99 113.007H139.992ZM186.984 139.249C192.828 135.224 198.987 130.303 205.134 124.636L208.919 134.168L185.04 158.04L186.984 139.247V139.249ZM157.745 162.28L154.609 159.145L178.83 134.931L181.966 138.066L157.745 162.28ZM144.154 148.691L141.018 145.556L165.238 121.342L168.374 124.477L144.154 148.691ZM160.707 144.686L157.571 141.551L171.512 127.614L174.648 130.749L160.707 144.686ZM149.207 133.188L146.072 130.053L160.013 116.116L163.149 119.251L149.207 133.188Z"
      fill="#409F68"
    />
    <Path
      d="M233.622 78.4076L219.611 64.4007C208.015 68.9066 193.294 76.4943 180.958 88.8273C178.972 90.8122 177.069 92.7992 175.249 94.7841C169.583 100.929 164.658 107.086 160.632 112.929L185.076 137.365C192.813 132.035 201.083 125.147 209.186 117.046C221.523 104.713 229.115 89.9958 233.62 78.4035L233.622 78.4076ZM204.482 93.5317C200.248 89.298 200.237 82.4306 204.472 78.1989C208.707 73.9651 215.587 73.9651 219.82 78.1989C224.055 82.4326 224.044 89.3 219.809 93.5317C215.575 97.7655 208.717 97.7655 204.482 93.5317ZM239.507 58.5176C239.507 58.5176 233.276 59.5837 224.2 62.7186L235.304 73.8198C238.44 64.7465 239.507 58.5176 239.507 58.5176ZM217.892 91.6103C214.717 94.7841 209.577 94.7841 206.402 91.6103C203.228 88.4365 203.222 83.29 206.394 80.1162C209.569 76.9424 214.725 76.9424 217.9 80.1162C221.074 83.29 221.066 88.4365 217.892 91.6103ZM139.942 112.984L163.821 89.1118L173.356 92.8954C167.69 99.0404 162.765 105.198 158.739 111.04L139.94 112.984H139.942ZM186.935 139.226C192.778 135.2 198.938 130.279 205.084 124.613L208.869 134.145L184.99 158.017L186.935 139.224V139.226Z"
      fill="#003349"
    />
    <Path
      d="M61.4247 62.6471L53.6506 54.4456L42.7285 57.3452L50.9324 49.5754L48.032 38.6543L55.806 46.8559L66.7281 43.9563L58.5243 51.7281L61.4247 62.6471Z"
      fill="#409F68"
    />
    <Path
      d="M333.745 148.102L325.971 139.901L315.049 142.8L323.253 135.03L320.352 124.109L328.126 132.311L339.048 129.411L330.845 137.183L333.745 148.102Z"
      fill="#409F68"
    />
    <Path
      d="M80.9559 193.122L73.1819 184.92L62.2598 187.82L70.4636 180.05L67.5632 169.129L75.3372 177.33L86.2594 174.431L78.0555 182.203L80.9559 193.122Z"
      fill="#409F68"
    />
  </Svg>
);
export default CanteenRocketIcon;
