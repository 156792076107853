import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  color?: string;
  size?: number;
}
const ErrorIcon = ({size = 20, color = '#EB0014'}: IProps) => (
  <Svg width={size} height={size} fill="none">
    <Path
      fill={color}
      fillRule="evenodd"
      d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0Zm1.154 15H8.846v-1.923h2.308V15Zm0-4.23H8.846V4.614h2.308v6.154Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default ErrorIcon;
