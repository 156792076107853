import React from 'react';
import {
  View,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native';
import AVText from 'src/components/elements/AVText';
import Styles from 'src/components/Styles';
import type {DateType} from 'src/types/Location';
import {getDescriber} from './descriptor/DescriptorType';

const {customTilesButtonStyles} = getDescriber();

type CustomTilesButtonProps = {
  item: DateType;
  onTilePress: (item) => void;
  accessibleString: string;
  selected?: boolean;
  text: string;
  icon?: React.ReactNode;
  tileStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
};

const CustomTilesButton = (props: CustomTilesButtonProps) => {
  return (
    <TouchableOpacity
      accessibilityLabel={props.accessibleString}
      onPress={() => props.onTilePress(props.item)}
      testID={props.accessibleString}
      accessible={true}
      accessibilityHint={`Tap to select ${props.text} `}
      accessibilityRole="button"
      aria-label={props.accessibleString}
      role="button"
      style={{
        backgroundColor: Styles.Colors.PayNew.white01,
        borderRadius: Styles.Spacing.m2,
        marginBottom: Styles.Spacing.m3,
        ...Styles.Style.lightShadow,
      }}
    >
      <View
        style={[
          customTilesButtonStyles().tileConatainer,
          props.selected && customTilesButtonStyles().selectedView,
          props.tileStyle,
        ]}
      >
        {props.icon}
        <AVText
          accessible={true}
          testID={props.text}
          accessibilityRole={'text'}
          accessibilityLabel={props.text}
          aria-label={props.text}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
          style={[
            customTilesButtonStyles().text,
            props.selected && customTilesButtonStyles().selectedText,
            props.textStyle,
          ]}
        >
          {props.text}
        </AVText>
      </View>
    </TouchableOpacity>
  );
};

export default CustomTilesButton;
