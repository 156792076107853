import {Image} from 'react-native';
import Localized from 'src/constants/AppStrings';
import CKLogo from 'src/components/img/svg/CKLogo';
import Styles from 'src/components/Styles';

export function getLogoGradientProps() {
  return {
    colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
    locations: [0, 0.3, 0.7, 1],
    start: {x: 0.5, y: 0},
    end: {x: 0.5, y: 1},
  };
}

export function getWelcomeLogoImage() {
  return (
    <Image
      source={require('src/components/img/CompanyKitchenWelcome.png')}
      style={{width: 338, height: 298}}
      resizeMode="cover"
    />
  );
}

export function getLoginLogo(): JSX.Element {
  return <CKLogo />;
}

export function getWelcomeDescriptionText(): string {
  return Localized.Labels.a_refreshingly_convenient_way_to_pay_earn_and_enjoy;
}

export function getWelcomeDescriptionTextStyle() {
  return {
    color: Styles.Colors.PayNew.black01,
    textAlign: 'center',
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 24,
  };
}

export function getLoginButtonColor() {
  return Styles.Colors.CompanyKitchen.blue;
}

export default {
  getLogoGradientProps,
  getWelcomeLogoImage,
  getLoginLogo,
  getWelcomeDescriptionText,
  getWelcomeDescriptionTextStyle,
  getLoginButtonColor,
};
