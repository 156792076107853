import React from 'react';
import {StyleSheet, View} from 'react-native';
import Styles from '../../Styles';
import AVText from '../AVText';
import Settings from 'src/Settings';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';

const renderCommonSection = (props) => {
  const {switchStatus, onSwitchToggle, title} = props;

  const styles = Settings.isNewUI()
    ? getDescriber() &&
      getDescriber()['rendercommonSecNewStyleDescriptor'] &&
      getDescriber()['rendercommonSecNewStyleDescriptor']()
    : oldStyles;

  const describerProps = {switchStatus, onSwitchToggle, title};
  return (
    <View style={styles && styles['container']}>
      <View style={styles && styles['headerContainer']}>
        {!props.switchStatus != undefined && (
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
            accessible={true}
            accessibilityRole={'header'}
            accessibilityLabel={props.title}
            role="heading"
            aria-label={props.title}
            style={styles && styles['header']}
          >
            {props.title}
          </AVText>
        )}
        {getDescriber() &&
          getDescriber()['commonutilsRenderSwitchTemplate'] &&
          getDescriber()['commonutilsRenderSwitchTemplate'](describerProps)}
      </View>
      {props.children}
    </View>
  );
};

const oldStyles = StyleSheet.create({
  container: {
    marginBottom: Styles.Spacing.m4,
    minHeight: Styles.Heights.touchTargetHeight0,
  },
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Styles.Spacing.m1,
    paddingHorizontal: Styles.Spacing.m3,
  },
  header: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    fontWeight: '500',
  },
});

export default {
  renderCommonSection,
};
