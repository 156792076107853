import Styles from 'src/components/Styles';

export function bottomTabBtnColor(): string {
  return Styles.blueRevolve;
}
export function getFooterNameTextDesc(isFocused): {
  [key: string]: {[key: string]: number | string};
} {
  return {
    footerNameText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: 10,
      fontWeight: isFocused ? '700' : '400',
      textAlign: 'center',
    },
  };
}

export default {
  bottomTabBtnColor,
  getFooterNameTextDesc,
};
