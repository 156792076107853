import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import GrpReferralRedesign from 'src/components/img/svg/GrpReferralredesign';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';

export function getReferralStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    referralTitle: {
      fontWeight: 'bold',
      marginTop: Styles.Spacing.m2,
      fontSize: Styles.Fonts.f3,
      textAlign: 'center',
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.black,
    },
    referralExpiry: {
      marginTop: Styles.Spacing.m3,
      fontWeight: '400',
      textAlign: 'center',
      fontSize: Styles.Fonts.f1,
      color: Styles.Colors.CompanyKitchen.blue,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    referralDesc: {
      fontSize: Styles.Fonts.f1,
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m1,
      textAlign: 'center',
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.darkColor,
    },
    totalReferralsKey: {
      fontSize: Styles.Fonts.f8,
      color: Styles.lightGray,
      fontWeight: '400',
      height: 18,
      textAlign: 'center',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    totalReferralsValue: {
      fontWeight: 'bold',
      textAlign: 'left',
      fontSize: Styles.Fonts.f3,
      color: Styles.tabBarTextColor,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    shareViaEmailTitle: {
      fontSize: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '400',
      color: Styles.tabBarTextColor,
      textAlign: 'left',
    },
    orText: {
      fontSize: Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.robotoRegular,
      marginHorizontal: Styles.Spacing.m3,
      textTransform: 'uppercase',
      color: Styles.tabBarTextColor,
    },
    errorLabel: {
      fontSize: Styles.Fonts.f8,
      color: Styles.dangerColor,
      fontFamily: Styles.FontFamily.robotoRegular,
      alignSelf: 'flex-start',
      marginTop: Styles.Spacing.m1,
      marginHorizontal: Styles.Spacing.m3,
    },
  };
}

export function getReferralTitleMsg(amount: string): string {
  return Localized.Labels.formatString(
    Localized.Labels.referral_invite_earn_desc,
    'Company Kitchen',
    amount,
    amount,
  ).toString();
}

export function getReferralHeaderIcon() {
  return (
    <GrpReferralRedesign
      width={78}
      height={74}
      gradColor1={Styles.Colors.CompanyKitchen.blue}
      gradColor2={Styles.Colors.CompanyKitchen.blue}
    />
  );
}

export function getSendInviteButton(
  checkDisabled: () => boolean,
  callback: () => void,
) {
  return (
    <RoundedButton
      accessibilityLabel="Send Invite"
      buttonType={ButtonType.action}
      disabled={checkDisabled()}
      onPress={callback}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
      text={Localized.Buttons.send_invite}
    />
  );
}

export default {
  getReferralStyles,
  getReferralTitleMsg,
  getSendInviteButton,
  getReferralHeaderIcon,
};
