import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import Settings from 'src/Settings';
interface IProps {
  size?: number;
  color?: string;
}
const AccountTabIcon = ({size = 34, color = '#727179'}: IProps) => {
  return Settings.isRefiveAnd365Pay() ? (
    <Svg fill="none" width={size} height={size}>
      <Path
        fill={color}
        fillRule="evenodd"
        d="M23.91 12.673c0 4.647-3.102 8.423-6.91 8.423-3.8 0-6.91-3.776-6.91-8.423S13.192 4.25 17 4.25c3.808 0 6.91 3.776 6.91 8.423Zm-2.072 0c0-3.51-2.163-6.317-4.838-6.317-2.674 0-4.837 2.808-4.837 6.317 0 3.51 2.163 6.318 4.837 6.318 2.675 0 4.838-2.808 4.838-6.318Zm.345 8.67a1.046 1.046 0 0 1 1.14-.948c.076 0 7.844.898 7.844 9.469 0 .581-.464 1.053-1.037 1.053H3.87a1.045 1.045 0 0 1-1.037-1.053c0-8.57 7.768-9.47 7.844-9.47a1.046 1.046 0 0 1 1.14.948c.057.582-.36 1.1-.933 1.158-3.204.405-5.684 3.047-5.929 6.318h24.09c-.224-3.28-2.714-5.934-5.929-6.318a1.054 1.054 0 0 1-.933-1.158Z"
        clipRule="evenodd"
      />
    </Svg>
  ) : (
    <Svg width={size || 34} height={size || 34} viewBox="0 0 34 34" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.084 9.917a7.083 7.083 0 10-14.167 0 7.083 7.083 0 0014.167 0zm7.083 17.708c0-3.913-6.34-7.083-14.167-7.083s-14.166 3.17-14.166 7.083v3.542h28.333v-3.542z"
        fill={color}
      />
    </Svg>
  );
};
export default AccountTabIcon;
