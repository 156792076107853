import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

export default class Fruit extends React.Component {
  render() {
    return (
      <Svg width={36} height={38} viewBox="0 0 36 38" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.39 6.265a5.081 5.081 0 00-.55 1.556c1.61-1.005 3.615-1.753 5.678-1.93 1.631-.139 3.34.078 4.932.886 1.592.808 3.007 2.147 4.088 4.176 1.316 2.463 1.336 5.339.727 8.176-.61 2.836-1.887 5.713-3.302 8.293a57.609 57.609 0 01-4.165 6.482 51.164 51.164 0 01-2.024 2.6l-.04.04-.471.551-7.173-4.314-7.172 4.314-.471-.551-.04-.04c-.177-.217-.373-.433-.55-.67a44.63 44.63 0 01-1.474-1.93 60.52 60.52 0 01-4.165-6.482c-1.396-2.56-2.673-5.457-3.302-8.293-.609-2.837-.59-5.713.727-8.176C2.724 8.924 4.14 7.585 5.731 6.777c1.591-.788 3.281-1.025 4.932-.887 2.083.178 4.107.966 5.738 1.99a8.614 8.614 0 011.021-3.25C18.483 2.719 20.547.749 24.34 0l.668 3.33c-2.81.55-4.028 1.91-4.617 2.954v-.02z"
          fill="#066DFF"
        />
      </Svg>
    );
  }
}
