import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import Settings from 'src/Settings';
type SignUpRewardProps = {
  height?: number;
  width?: number;
  color?: string;
  style?: {[index: string]: {[index: string]: number | string}};
};
export default class SignUp extends React.Component<SignUpRewardProps> {
  render() {
    return (
      <>
        {Settings.is365Pay() ? (
          <Svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 50 51"
            fill="none"
          >
            <Path
              d="M0 25.5C0 11.693 11.193.5 25 .5s25 11.193 25 25-11.193 25-25 25-25-11.193-25-25z"
              fill="#066DFF"
            />
            <Path
              d="M25.374 15.187a5.226 5.226 0 100 10.452 5.226 5.226 0 000-10.452zm-.63 11.486c-3.647 0-7.076.928-10.054 2.559l-.574.313v5.104h13.202v-4.4h2.104v-2.744c-.947-.39-2.689-.834-4.677-.832zm6.16 2.236v2.702h-2.582v2.344l2.666-.018.013 2.72h2.344v-2.702h2.583V31.61h-2.583V28.91h-2.44z"
              fill="#FEE0E6"
            />
          </Svg>
        ) : (
          <Svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 38 38"
            fill="none"
          >
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.508 5.004a7.093 7.093 0 100 14.185 7.093 7.093 0 000-14.185zm-.854 15.588c-4.95 0-9.605 1.26-13.645 3.473l-.78.425v6.928h17.917v-5.973h2.856v-3.724c-1.286-.527-3.65-1.13-6.348-1.128zm8.36 3.035v3.668h-3.505v3.18l3.618-.024.018 3.692h3.18v-3.668h3.506v-3.18h-3.505v-3.668h-3.312z"
              fill="#003349"
            />
          </Svg>
        )}
      </>
    );
  }
}
