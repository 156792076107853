import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  color?: string;
  width?: number;
  height?: number;
}
const CloseIcon = ({color = '#22212D', width = 24, height = 24}: IProps) => (
  <Svg fill="none" width={width} height={height}>
    <Path
      fillRule="evenodd"
      fill={color}
      d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm0-9.41L14.59 8 16 9.41 13.41 12 16 14.59 14.59 16 12 13.41 9.41 16 8 14.59 10.59 12 8 9.41 9.41 8 12 10.59Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default CloseIcon;
