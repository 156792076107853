import * as React from 'react';
import Svg, {Path, Mask, G} from 'react-native-svg';

interface IProps {
  size?: number;
  color?: string;
}
const HistoryIcon = ({size = 34, color = '#727179'}: IProps) => (
  <Svg width={size} height={size}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9993 2.83337C9.17577 2.83337 2.83334 9.17663 2.83334 17C2.83334 24.8243 9.17577 31.1667 16.9993 31.1667C24.8235 31.1667 31.1667 24.8243 31.1667 17C31.1667 9.17663 24.8235 2.83337 16.9993 2.83337ZM25.5861 25.5864C24.9474 26.225 24.25 26.7835 23.5062 27.2573L22.4978 25.5105L21.6214 26.0164L22.6305 27.7642C21.0596 28.5883 19.3183 29.0579 17.506 29.1318V27.1191H16.4941V29.1318C14.6818 29.0581 12.9404 28.5886 11.3695 27.7645L12.3787 26.0164L11.5024 25.5104L10.4937 27.2575C9.7499 26.7837 9.05238 26.2251 8.41368 25.5864C7.77498 24.9477 7.21647 24.2502 6.74265 23.5063L8.4897 22.4977L7.98375 21.6214L6.23562 22.6307C5.41155 21.0597 4.94203 19.3183 4.86822 17.506H6.88096V16.4941H4.86822C4.94203 14.6818 5.41161 12.9404 6.23575 11.3695L7.98369 12.3787L8.48964 11.5024L6.74265 10.4938C7.21647 9.75006 7.77492 9.05267 8.41349 8.41403C9.05226 7.7752 9.74978 7.21663 10.4936 6.74274L11.5023 8.4898L12.3786 7.98384L11.3693 6.23578C12.9404 5.41152 14.6818 4.94193 16.4941 4.86825V6.88099H17.506V4.86831C19.3182 4.94225 21.0595 5.4119 22.6304 6.23603L21.6214 7.98372L22.4977 8.48967L23.5061 6.74299C24.2499 7.21682 24.9473 7.77533 25.586 8.41397C26.2247 9.05267 26.7832 9.75006 27.2571 10.4939L25.5104 11.5024L26.0163 12.3787L27.7641 11.3695C28.5883 12.9405 29.0579 14.6818 29.1317 16.494H27.1191V17.5059H29.1317C29.0579 19.3182 28.5883 21.0595 27.7643 22.6305L26.0164 21.6213L25.5104 22.4976L27.2573 23.5061C26.7834 24.2501 26.2248 24.9476 25.5861 25.5864ZM18.7878 16.0514H26.1072V18.0752H18.7143C18.3562 18.6449 17.7226 19.0239 17 19.0239C15.8823 19.0239 14.9762 18.1178 14.9762 17C14.9762 16.2511 15.3834 15.5977 15.9881 15.2477V10.9286H18.0119V15.2477C18.3398 15.4374 18.6096 15.7163 18.7878 16.0514Z"
      fill={color}
    />
    <Mask
      id="mask0_677_6037"
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={30}
      height={30}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9993 2.83337C9.17577 2.83337 2.83334 9.17663 2.83334 17C2.83334 24.8243 9.17577 31.1667 16.9993 31.1667C24.8235 31.1667 31.1667 24.8243 31.1667 17C31.1667 9.17663 24.8235 2.83337 16.9993 2.83337ZM25.5861 25.5864C24.9474 26.225 24.25 26.7835 23.5062 27.2573L22.4978 25.5105L21.6214 26.0164L22.6305 27.7642C21.0596 28.5883 19.3183 29.0579 17.506 29.1318V27.1191H16.4941V29.1318C14.6818 29.0581 12.9404 28.5886 11.3695 27.7645L12.3787 26.0164L11.5024 25.5104L10.4937 27.2575C9.7499 26.7837 9.05238 26.2251 8.41368 25.5864C7.77498 24.9477 7.21647 24.2502 6.74265 23.5063L8.4897 22.4977L7.98375 21.6214L6.23562 22.6307C5.41155 21.0597 4.94203 19.3183 4.86822 17.506H6.88096V16.4941H4.86822C4.94203 14.6818 5.41161 12.9404 6.23575 11.3695L7.98369 12.3787L8.48964 11.5024L6.74265 10.4938C7.21647 9.75006 7.77492 9.05267 8.41349 8.41403C9.05226 7.7752 9.74978 7.21663 10.4936 6.74274L11.5023 8.4898L12.3786 7.98384L11.3693 6.23578C12.9404 5.41152 14.6818 4.94193 16.4941 4.86825V6.88099H17.506V4.86831C19.3182 4.94225 21.0595 5.4119 22.6304 6.23603L21.6214 7.98372L22.4977 8.48967L23.5061 6.74299C24.2499 7.21682 24.9473 7.77533 25.586 8.41397C26.2247 9.05267 26.7832 9.75006 27.2571 10.4939L25.5104 11.5024L26.0163 12.3787L27.7641 11.3695C28.5883 12.9405 29.0579 14.6818 29.1317 16.494H27.1191V17.5059H29.1317C29.0579 19.3182 28.5883 21.0595 27.7643 22.6305L26.0164 21.6213L25.5104 22.4976L27.2573 23.5061C26.7834 24.2501 26.2248 24.9476 25.5861 25.5864ZM18.7878 16.0514H26.1072V18.0752H18.7143C18.3562 18.6449 17.7226 19.0239 17 19.0239C15.8823 19.0239 14.9762 18.1178 14.9762 17C14.9762 16.2511 15.3834 15.5977 15.9881 15.2477V10.9286H18.0119V15.2477C18.3398 15.4374 18.6096 15.7163 18.7878 16.0514Z"
        fill="white"
      />
    </Mask>
    <G mask="url(#mask0_677_6037)" />
  </Svg>
);
export default HistoryIcon;
