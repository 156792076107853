import {type SvgProps, type NumberProp} from 'react-native-svg';

import {getDescriber} from '../../../screens/descriptor/DescriptorType';
import {setStatic} from 'recompose';
setStatic;
export function SmartShopperTierIcon({
  size,
  ...props
}: SvgProps & {size?: NumberProp}) {
  return getDescriber()?.getSmartShopper(size, props);
}
