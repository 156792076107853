import * as React from 'react';
import Styles from '../Styles';
import {View, StyleSheet, Image, Platform} from 'react-native';
import {Modal, Portal} from 'react-native-paper';
import Localized from 'src/constants/AppStrings';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import CancelIcon from 'src/components/img/svg/CancelIcon';
import AVText from 'src/components/elements/AVText';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import ErrorIcon from 'src/components/img/svg/ErrorGettingSnackIcon';
import Settings from 'src/Settings';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';

type SuccessModalProps = {
  isModalVisible: boolean;
  onModalClose?: () => void;
  onModalCloseAndNavigate?: () => void;
  headerTitle: string;
  description: string;
  buttonTitle: string;
  seondaryButtonTitle?: string;
  buttonColor?: string;
  descLines?: number;
  isAcceptSnack?: boolean;
  isMultipleBtn?: boolean;
  oneTimeSendSnackPopUp?: boolean;
  errorSendSnackPopUp?: boolean;
  customAction?: () => void;
  secondaryCustomAction?: () => void;
  isDeleteAcnt?: boolean;
  isOnboardingError?: boolean;
  secondayBtnTxtStyle?: {[index: string]: number | string};
  secondayBtnContainerStyle?: {[index: string]: number | string};
};

const SuccessModal = (props: SuccessModalProps) => {
  const checkHeight = () => {
    if (!props.oneTimeSendSnackPopUp) return {height: 250};
  };
  const checkWebHeight = () => {
    return Platform.OS === 'web' ? 280 : 270;
  };
  return (
    <Portal>
      <Modal
        visible={props.isModalVisible}
        onDismiss={props.onModalClose}
        style={
          (props.oneTimeSendSnackPopUp || props.isDeleteAcnt) &&
          styles.modalContainer
        }
      >
        <View style={styles.modalContainer}>
          <View
            style={[
              styles.modalContent,
              props.isAcceptSnack ? {height: 360} : checkHeight(),
              props.errorSendSnackPopUp && {height: 347},
              props.isMultipleBtn && {
                height: Platform.OS === 'android' ? 275 : checkWebHeight(),
              },
              props.isDeleteAcnt && {
                height: 330,
              },
              props.isOnboardingError && {height: 330},
            ]}
          >
            <AVTouchableOpacity
              accessible={true}
              accessibilityLabel={Localized.Buttons.cancel}
              accessibilityRole="button"
              aria-label={Localized.Buttons.cancel}
              role="button"
              style={styles.titleContainer}
              onPress={props.onModalClose}
            >
              <CancelIcon />
            </AVTouchableOpacity>
            <View
              style={styles.innerContent}
              accessible
              accessibilityLabel="Success Modal"
            >
              {(props.isAcceptSnack || props.oneTimeSendSnackPopUp) && (
                <Image
                  accessible={true}
                  accessibilityLabel="Snack gift"
                  accessibilityRole="image"
                  role="img"
                  aria-label="Snack gift"
                  style={{height: 142, width: 160}}
                  resizeMode="contain"
                  source={getDescriber().successModalSnackGifDescriptor()}
                />
              )}
              {props.errorSendSnackPopUp && !props.isDeleteAcnt && (
                <ErrorIcon />
              )}
              <AVText
                style={[
                  styles.headerText,
                  props.isAcceptSnack
                    ? [
                        styles.acceptSnacktextDesc,
                        getDescriber()[
                          'sendSnackOTSNotificationStyleDescriptor'
                        ]()['acceptSnackText'],
                      ]
                    : null,
                  props.oneTimeSendSnackPopUp || props.errorSendSnackPopUp
                    ? [
                        styles.acceptHeader,
                        getDescriber()[
                          'sendSnackOTSNotificationStyleDescriptor'
                        ]()['acceptSnackHeaderText'],
                      ]
                    : null,
                  props.isMultipleBtn || props.isDeleteAcnt
                    ? [
                        {
                          color: '#111',
                          fontSize: Styles.Fonts.sectionHeader + 2,
                          fontWeight: '700',
                        },
                        getDescriber().fontStyleSuccessModal(),
                      ]
                    : null,
                ]}
                accessible
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                accessibilityLabel={props.headerTitle}
                aria-label={`${props.headerTitle}Text`}
              >
                {props.headerTitle}
              </AVText>
            </View>
            <View
              style={[
                styles.descriptionStyle,
                props.isAcceptSnack ||
                props.oneTimeSendSnackPopUp ||
                props.errorSendSnackPopUp ||
                props.isDeleteAcnt
                  ? {top: 10}
                  : null,
                props.isMultipleBtn && {
                  paddingBottom: 24,
                },
              ]}
              accessible
              accessibilityLabel="Success Modal"
            >
              <AVText
                style={[
                  styles.descText,
                  props.isAcceptSnack ? {textAlign: 'center'} : null,
                  props.descLines === 2 || 5 ? {textAlign: 'center'} : null,
                  props.oneTimeSendSnackPopUp || props.errorSendSnackPopUp
                    ? [
                        styles.subDescText,
                        getDescriber()[
                          'sendSnackOTSNotificationStyleDescriptor'
                        ]()['acceptSnackSubDescText'],
                      ]
                    : null,
                  props.isMultipleBtn || props.isDeleteAcnt
                    ? [
                        {
                          color: '#111',
                          width: 293,
                          lineHeight: 19.2,
                          paddingBottom: 20,
                          fontSize: Styles.Fonts.f7 - 2,
                          fontWeight: '400',
                        },
                        getDescriber().fontStyleSuccessModal(),
                      ]
                    : null,
                ]}
                numberOfLines={props.descLines || 1}
                accessible
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                accessibilityLabel={props.description}
                aria-label={`${props.description} Text`}
              >
                {props.description}
              </AVText>
            </View>
            <RoundedButton
              buttonType={ButtonType.normal}
              containerStyle={[
                styles.buttonStyle,
                props.isAcceptSnack || props.errorSendSnackPopUp
                  ? {paddingTop: 6, paddingHorizontal: 16, paddingBottom: 6}
                  : {padding: Styles.Heights.h1},
                props.oneTimeSendSnackPopUp && {
                  position: 'relative',
                  paddingTop: 6,
                  paddingHorizontal: 16,
                  paddingBottom: 6,
                },
                {bottom: 22},
                props.isMultipleBtn && {
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 9,
                  paddingRight: 9,
                  position: 'relative',
                  bottom: props.description.length <= 40 ? 10 : 30,
                },
                props.isDeleteAcnt && {
                  marginTop: 10,
                  paddingLeft: -25,
                  paddingRight: -25,
                  paddingTop: 4,
                  paddingBottom: 4,
                  marginLeft: -10,
                  right: -5,
                },
              ]}
              onPress={props.customAction ?? props.onModalClose}
              accessibilityLabel={props.buttonTitle}
              text={props.buttonTitle}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
              textStyle={[
                styles.buttonText,

                getDescriber().fontStyleSuccessModal(),
                !props.isDeleteAcnt && {
                  marginLeft: Styles.Spacing.m1,
                },
              ]}
              color={getDescriber()['sendSnackOTSNBtnStyleDescriptor'](
                props.isDeleteAcnt,
              )}
              wrapperStyle={[
                props.isMultipleBtn && {
                  flexDirection: 'row',
                  alignItems: 'center',
                  margin: 0,
                  paddingVertical: 8,
                  paddingHorizontal: 24,
                },
              ]}
            />
            {props.isMultipleBtn || props.isDeleteAcnt ? (
              <RoundedButton
                accessible={true}
                accessibilityRole="button"
                role="button"
                accessibilityHint="Double tap to navigate to home"
                accessibilityLabel={props.seondaryButtonTitle}
                aria-label={props.seondaryButtonTitle}
                testID={props.seondaryButtonTitle}
                text={props.seondaryButtonTitle}
                textStyle={[
                  styles.seondaryButtonText,
                  props.secondayBtnTxtStyle,
                ]}
                color={getDescriber()['sendSnackOTSNBtnStyleDescriptor'](
                  props.isDeleteAcnt,
                )}
                buttonType={ButtonType.outline}
                containerStyle={[
                  props.isOnboardingError
                    ? styles.secondaryBtnContainerError
                    : styles.secondayBtnContainer,
                  props.isMultipleBtn && {
                    borderTopColor: '#066DFF',
                    borderBottomColor: '#066DFF',
                    borderLeftColor: '#066DFF',
                    borderRightColor: '#066DFF',
                    overflow: 'hidden',
                  },
                  props.secondayBtnContainerStyle,
                  props.isDeleteAcnt && {
                    paddingTop: 2,
                    paddingBottom: 2,
                    bottom: Platform.OS === 'ios' ? 24 : 18,
                    overflow: 'hidden',
                  },
                  Platform.OS == 'web' && {
                    paddingBottom: 4,
                    paddingTop: 4,
                  },
                ]}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                onPress={() => {
                  if (props.secondaryCustomAction) {
                    props.secondaryCustomAction();
                    props.onModalClose && props.onModalClose();
                  } else if (props.onModalCloseAndNavigate) {
                    props.onModalCloseAndNavigate();
                  } else {
                    props.onModalClose && props.onModalClose();
                  }
                }}
                wrapperStyle={[
                  props.isMultipleBtn &&
                    !props.isOnboardingError && {
                      flexDirection: 'row',
                      alignItems: 'center',
                      margin: 0,
                      paddingVertical: 8,
                      paddingHorizontal: 24,
                    },
                ]}
              />
            ) : null}
          </View>
        </View>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    alignItems: 'center',
    marginRight: 25,
    justifyContent: 'center',
    marginLeft: 25,
  },
  acceptHeader: {
    top: 15,
    width: 293,
    paddingHorizontal: Styles.Spacing.m1,
    lineHeight: 24,
    marginLeft: 0,
    textAlign: 'center',
  },
  acceptSnacktextDesc: {
    top: 15,
  },
  subDescText: {
    width: 293,
    lineHeight: 19.2,
    paddingBottom: 60,
    textAlign: 'center',
  },
  titleContainer: {
    position: 'absolute',
    justifyContent: 'flex-end',
    right: Styles.Heights.h1,
    top: Styles.Heights.h1,
  },
  modalContent: {
    width: 323,
    paddingHorizontal: 25,
    borderRadius: Styles.Spacing.m3,
    backgroundColor: Styles.white,
  },
  innerContent: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: Styles.Heights.h3 - 5,
    marginTop: 45,
  },
  descriptionStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m3,
  },
  headerText: {
    fontFamily: Settings.is365Pay()
      ? Styles.FontFamily.aeonikRegular
      : Styles.FontFamily.figtreeRegular,
    color: Styles.black,
    fontSize: Styles.Fonts.sectionHeader + 2,
    fontWeight: '700',
    marginLeft: Styles.Spacing.m2,
  },
  descText: {
    fontFamily: Settings.is365Pay()
      ? Styles.FontFamily.aeonikRegular
      : Styles.FontFamily.figtreeRegular,
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
    fontWeight: '400',
  },
  buttonStyle: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  buttonText: {
    color: Styles.white,
    fontSize: Styles.Fonts.f1,
    fontWeight: '700',
    textAlign: 'center',
    fontFamily: Settings.isRefive()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.aeonikRegular,
  },
  seondaryButtonText: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '700',
    fontSize: Styles.Fonts.f1,
    color: '#066DFF',
  },
  secondayBtnContainer: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingHorizontal: 28,
    borderWidth: 2,
    position: 'absolute',
    bottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  secondaryBtnContainerError: {
    width: 138,
    borderWidth: 2,
    position: 'absolute',
    bottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    margin: 0,
    paddingVertical: 8,
  },
});
export default SuccessModal;
