import * as React from 'react';
import {
  Alert,
  Platform,
  ScrollView,
  StyleSheet,
  Switch,
  View,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../ScreenContext';
import Localized from 'src/constants/AppStrings';
import BackSubheader from '../elements/BackSubheader';
import NBDropdown from '../elements/NBDropdown';
import RoundedButton, {ButtonType} from '../elements/RoundedButton';
import Styles from '../Styles';
import AVText from '../elements/AVText';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from '../../redux/store';
import {fetchEnvironments} from '../../redux/thunks/environmentThunks';
import {EnvironmentKey, EnvironmentsType} from '../../models/Environment';
import {environmentChanged} from 'src/redux/slices/environmentSlice';
import {compose} from 'redux';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
import {RestartApp} from '../../nativeModules/Restart';
import AppDispatcher from '../../dispatchers/AppDispatcher';
import AccountConstants from '../../constants/AccountConstants';

type EnvironmentScreenProps = {
  env: string;
  debug: boolean;
  demo: boolean;
  environments: EnvironmentsType;
  saveChanges: (state: EnvironmentScreenState) => void;
  showDemo: boolean;
  showDebug: boolean;
  showLanguage: boolean;
  showCreateAccount: boolean;
  onCountrySelectionPress: () => void;
  fetchEnvironments(): void;
  environmentChanged(env: EnvironmentKey): void;
};
type EnvironmentScreenState = {
  language: string;
  demo: boolean;
  debug: boolean;
  env: EnvironmentKey;
};

class EnvironmentScreen extends React.Component<
  EnvironmentScreenProps,
  EnvironmentScreenState
> {
  static defaultProps = {
    environments: [],
  };
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  errorFetchingEnvironments: boolean;

  constructor(props: EnvironmentScreenProps) {
    super(props);
    this.state = {
      env: props.env.toUpperCase() as EnvironmentKey,
      debug: props.debug,
      demo: props.demo,
      language: Localized.getLanguage(),
    };
    this.onValueChange = this.onValueChange.bind(this);
  }

  async componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'EnvironmentScreen', {
      ...this.props,
      ...this.state,
    });

    this.props.fetchEnvironments();
  }

  componentWillUnmount() {
    this.props.environmentChanged(this.state.env);
    if (!this.errorFetchingEnvironments) {
      if (this.props.saveChanges) {
        this.props.saveChanges(this.state);
        void AppDispatcher.handleViewAction({
          actionType: AccountConstants.ENVIRONMENT_CHANGED,
          data: {
            env: this.state.env,
          },
        });
      }

      Localized.setLanguageTo(this.state.language);
      if (Platform.OS !== 'web' && !this.state.demo) {
        Alert.alert(
          '',
          'You would need to restart the app to update ENV change',
          [{text: 'Restart', onPress: RestartApp}],
        );
      }
    }
  }

  onValueChange(key: string, value: string) {
    const newState = {};
    newState[key] = value;
    this.setState(newState, () => {
      FirebaseAnalytic.trackEvent('onValueChange', 'EnvironmentScreen', {
        ...this.props,
        ...this.state,
      });
    });
  }

  render() {
    let demoCell;

    if (this.props.showDemo) {
      demoCell = (
        <View style={[styles.row]}>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
            style={styles.switchText}
          >
            {Localized.Labels.demo_mode}
          </AVText>
          <Switch
            style={styles.switch}
            accessibilityLabel={'Demo Mode'}
            nativeID="Demo Mode"
            value={this.state.demo}
            onValueChange={(value) =>
              this.setState(
                {
                  demo: value,
                },
                () => {
                  this.props.saveChanges(this.state);
                },
              )
            }
            trackColor={{
              false: Styles.lightGray,
              true: Styles.primaryColor,
            }}
          />
        </View>
      );
    }

    let debugCell;

    if (this.props.showDebug) {
      debugCell = (
        <View style={[styles.row]}>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
            style={styles.switchText}
          >
            {Localized.Labels.debug_messages}
          </AVText>
          <Switch
            style={styles.switch}
            accessibilityLabel={'Debug'}
            nativeID="Debug"
            value={this.state.debug}
            onValueChange={(value) =>
              this.setState({
                debug: value,
              })
            }
            trackColor={{
              false: Styles.lightGray,
              true: Styles.primaryColor,
            }}
          />
        </View>
      );
    }

    let languageCell;

    if (this.props.showLanguage) {
      const items = Localized.getAvailableLanguages().map((language) => ({
        value: language,
        text: language,
      }));
      languageCell = (
        <View style={[styles.cell]}>
          <NBDropdown
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            accessibilityLabel={'Language'}
            label={Localized.Labels.language}
            onSelect={(value: any) =>
              this.setState({
                language: value,
              })
            }
            selectedValue={this.state.language}
            options={items}
          />
        </View>
      );
    }

    let selectCountry;

    if (this.props.showCreateAccount) {
      selectCountry = (
        <RoundedButton
          buttonType={ButtonType.outline}
          accessibilityLabel={'Country Selection Screen'}
          onPress={this.props.onCountrySelectionPress}
          text={'Select Country'}
        />
      );
    }

    const environments = Object.keys(this.props.environments).map(
      (environment) => ({
        value: environment,
        text:
          environment === 'DEV'
            ? 'TEST3'
            : environment === 'QA'
            ? 'TEST4'
            : environment,
      }),
    );
    return (
      <>
        <BackSubheader title={Localized.Labels.environment}>
          <ScrollView>
            <View style={styles.content}>
              {demoCell}
              {debugCell}

              <View style={[styles.cell]}>
                <NBDropdown
                  accessibilityLabel={'Environment'}
                  label={'Environment'}
                  onSelect={(value: any) => {
                    this.setState({
                      env: value,
                    });
                  }}
                  selectedValue={this.state.env}
                  options={environments}
                />
              </View>
              {languageCell}
              {selectCountry}
            </View>
          </ScrollView>
        </BackSubheader>
      </>
    );
  }
}

const styles = StyleSheet.create({
  cell: {
    paddingBottom: Styles.Spacing.m2,
  },
  content: {
    margin: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m2,
  },
  row: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: Styles.Spacing.m1,
  },
  switch: {
    transform: [
      {
        scaleX: 0.85,
      },
      {
        scaleY: 0.85,
      },
    ],
  },
  switchText: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  textContent: {
    fontSize: Styles.Fonts.f1,
    marginVertical: Styles.Spacing.m1,
  },
  versionLabel: {
    color: Styles.darkColor,
    fontSize: 12,
  },
});

export default compose(
  withForwardedNavigationParams(),
  connect(
    (state: RootState) => ({
      environments: state.environment.environments,
    }),
    (dispatch: AppDispatch) => ({
      fetchEnvironments: () => dispatch(fetchEnvironments()),
      environmentChanged: (env) => dispatch(environmentChanged(env)),
    }),
  ),
)(EnvironmentScreen);
