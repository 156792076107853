import Styles from 'src/components/Styles';

export function getStyleWithColorOnly(): {[index: string]: string} {
  return {
    backgroundColor: '#7fbc0340',
    color: '#003349',
    allReadTextUnderLine: Styles.Colors.Canteen.green,
  };
}

export function snackBarColorDescriptor(): string {
  return Styles.Colors.Canteen.lightGreen;
}

export function getToastTextStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    allReadText: {
      fontSize: Styles.Fonts.f1,
      paddingTop: Styles.Spacing.m1,
      color: Styles.Colors.Canteen.green,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '700',
    },
  };
}

export function getRightReadMarkIconColor(): string {
  return null;
}

export function snackBarFontFamily(): string {
  return Styles.FontFamily.robotoRegular;
}

export default {
  getStyleWithColorOnly,
  snackBarColorDescriptor,
  getToastTextStyleDescriptor,
  getRightReadMarkIconColor,
  snackBarFontFamily,
};
