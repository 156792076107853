import * as React from 'react';
import Svg, {Circle} from 'react-native-svg';
interface IProps {
  color?: string;
  size?: number;
}
const RadioChecked = ({color = '#066DFF', size = 24}: IProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Circle cx={12} cy={12} r={11} stroke={color} strokeWidth={2} />
    <Circle cx={11.9998} cy={12} r={7.86207} fill={color} />
  </Svg>
);
export default RadioChecked;
