import Styles from '../../../../Styles';
import Localized from 'src/constants/AppStrings';
import RefiveNoBlockedUser from 'src/components/img/svg/RefiveNoBlockedUser';

export function getItemContainerStyle(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    blockedName: {
      fontSize: Styles.Fonts.f1,
      alignSelf: 'center',
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '700',
      color: '#111111',
      flex: 1,
    },
    itemContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: Styles.Spacing.m3 - 3,
      borderBottomColor: Styles.dividerColor,
      borderWidth: 1,
      borderColor: Styles.inputBgColor,
      borderBottomWidth: 1,
    },
  };
}

export function getBlockedUserTitle() {
  return Localized.Labels.blocked_users;
}
export function blockedUsersEmptyIcon(): JSX.Element {
  return <RefiveNoBlockedUser />;
}

export function getUnBlockUserStyle(): {
  [index: string]: {[index: string]: number | string} | string;
} {
  return {
    btnUnblock: {
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.Colors.Refive.activeFooterBlue,
    },
    color: Styles.Colors.Refive.activeFooterBlue,
    btnUnblockContainer: {
      paddingTop: 4,
      paddingBottom: 4,
      paddingHorizontal: 10,
      borderTopColor: Styles.Colors.Refive.activeFooterBlue,
      borderBottomColor: Styles.Colors.Refive.activeFooterBlue,
      borderLeftColor: Styles.Colors.Refive.activeFooterBlue,
      borderRightColor: Styles.Colors.Refive.activeFooterBlue,
      borderWidth: 2,
      overflow: 'hidden',
      borderColor: Styles.Colors.Refive.activeFooterBlue,
    },
  };
}

export function getNoBlockedUsers(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    noCardsContainer: {},
  };
}

export function getBlockedUsersList(): {[key: string]: unknown} {
  return {
    listWrapper: {
      backgroundColor: '#fff',
      borderWidth: 1.5,
      borderBottomWidth: 1.5,
      borderRadius: 8,
      overflow: 'hidden',
      margin: 16,
      marginTop: 32,
      borderColor: '#EAEAEB',
    },
    //listShadow: Styles.Style.shadow,
    listStyle: {
      flex: 1,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      backgroundColor: '#FFFFFF',
      borderWidth: 1,
      borderBottomColor: '#EAEAEB',
      borderColor: '#EAEAEB',
      borderLeftColor: '#EAEAEB',
      borderRightColor: '#EAEAEB',
      borderTopColor: '#EAEAEB',
      borderStartColor: '#EAEAEB',
      borderEndColor: '#EAEAEB',
      borderRadius: 10,
    },
  };
}
export default {
  getItemContainerStyle,
  getUnBlockUserStyle,
  getBlockedUsersList,
  getBlockedUserTitle,
  blockedUsersEmptyIcon,
};
