import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IProps {
  color?: string;
  fillColor?: string;
  size?: number;
}
const CheckBoxChecked = ({
  fillColor = '#066DFF',
  color = 'white',
  size = 24,
}: IProps) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3333 0H2.66669C1.19987 0 0 1.19987 0 2.66669V21.3333C0 22.8001 1.19987 24 2.66669 24H21.3333C22.8001 24 24 22.8001 24 21.3333V2.66669C24 1.19987 22.8001 0 21.3333 0Z"
      fill={fillColor}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.71426 18L4 12.2307L5.59986 10.6155L9.71426 14.7694L18.4001 6L20 7.61531L9.71426 18Z"
      fill={color}
    />
  </Svg>
);
export default CheckBoxChecked;
