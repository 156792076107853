import * as React from 'react';
import DashedLine from 'react-native-dashed-line';
import Util from 'src/Util';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import AVFormattedCurrency from '../AVFormattedCurrency';
import type {
  Funding,
  PurchaseDetailType,
  TransactionDetail,
} from 'src/types/TransactionDetail';
import {PixelRatio, StyleSheet, View, ScrollView, Platform} from 'react-native';
import AVText from '../AVText';
import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import {WithLocalizedProps} from 'src/types/PropTypes';

import {getDescriber as getSnackDescriber} from 'src/components/screens/sendSnack/descriptor/sendasnack/DescriptorType';
import {getDescriber} from 'src/components/elements/account/descriptor/DescriptorType';

type FundDetailProps = WithLocalizedProps & {
  detail: Funding | TransactionDetail | PurchaseDetailType;
  handleEmailReceipt: (transId: string) => void;
  handleRefund: (transId: string) => void;
  previousRoute?: string;
};

const ALLOWED_REQUEST_REFUND_COUNTRIES = ['USA', 'CAN'];

const FundDetail = (props: FundDetailProps) => {
  // It not use
  // let emailReceiptButton: JSX.Element | null = null;
  if (
    AccountStore.getRegion() === 'USA' &&
    !Util.isDatePastNinetyDays(props.detail.DateString)
  ) {
    // emailReceiptButton = (
    //   <RoundedButton
    //     buttonType={ButtonType.action}
    //     accessible={true}
    //     accessibilityLabel={props.strings.Buttons.email_receipt}
    //     aria-label={props.strings.Buttons.email_receipt}
    //     accessibilityRole="button"
    //     role="button"
    //     onPress={() => {
    //       props.handleEmailReceipt(props.detail.Id);
    //     }}
    //     text={props.strings.Buttons.email_receipt}
    //   />
    // );
  }

  let isRefundAllowed = false;
  if (ALLOWED_REQUEST_REFUND_COUNTRIES.includes(AccountStore.getRegion())) {
    isRefundAllowed = true;
  }
  const formattedDate = Util.formatDate(props.detail.DateString, 'LLL');
  return (
    <>
      <View style={styles.container}>
        <ScrollView style={styles.historyContainer}>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            accessible={true}
            accessibilityLabel={`${props.strings.Labels.date} ${formattedDate}`}
            aria-label={`${props.strings.Labels.date} ${formattedDate}`}
            style={[
              getDescriber()?.fundingdetailDate(),
              getSnackDescriber()?.snackDetailsBtnFontSTyle(),
            ]}
          >
            {formattedDate}
          </AVText>
          {props?.detail?.Location && (
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
              accessible={true}
              accessibilityLabel={`${props.strings.Labels.location} ${props.detail.Location}`}
              aria-label={`${props.strings.Labels.location} ${props.detail.Location}`}
              style={[
                styles.location,
                getSnackDescriber()?.snackDetailsBtnFontSTyle(),
              ]}
            >
              {props.detail.Location}
            </AVText>
          )}
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            accessible={true}
            accessibilityLabel={`${props.strings.Labels.transaction_id}`}
            aria-label={`${props.strings.Labels.transaction_id}`}
            style={[
              getDescriber()?.fundingdetailTrans(),
              getSnackDescriber()?.snackDetailsBtnFontSTyle(),
            ]}
          >
            {props.detail.Id}
          </AVText>

          {false &&
            isRefundAllowed &&
            (props.detail as Funding).Processor !== undefined &&
            (props.detail as Funding).Status !== 'VOID' &&
            ((props.detail as Funding).Processor ===
              Settings.processors.stripe ||
              (props.detail as Funding).Processor ===
                Settings.processors.heartland) && (
              <RoundedButton
                buttonType={ButtonType.outline}
                containerStyle={[
                  styles.refundButton,
                  (props.detail as Funding).past90Days &&
                    styles.disabledRefundButton,
                ]}
                accessible={true}
                accessibilityLabel={props.strings.Buttons.request_refund}
                accessibilityRole="button"
                aria-label={props.strings.Buttons.request_refund}
                role="button"
                onPress={() => {
                  props.handleRefund(props.detail.Id);
                }}
                text={props.strings.Buttons.request_refund}
              />
            )}
          <DashedLine
            dashThickness={PixelRatio.roundToNearestPixel(2)}
            dashGap={PixelRatio.roundToNearestPixel(2)}
            style={styles.dashStyle}
            dashLength={PixelRatio.roundToNearestPixel(2)}
            dashColor={getDescriber().payDashedLine()}
          />
          <View style={[styles.row]}>
            <View style={[styles.leftView]}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                accessible={true}
                accessibilityLabel={props.strings.Labels.account_funding}
                aria-label={props.strings.Labels.account_funding}
                style={[
                  getDescriber()?.fundingdetailAccountFndgTxt(),
                  getSnackDescriber()?.snackDetailsBtnFontSTyle(),
                ]}
              >
                {props.strings.Labels.account_funding}
              </AVText>
            </View>
            {getDescriber().fundingDetailAccount(styles, props)}
          </View>

          <DashedLine
            dashGap={PixelRatio.roundToNearestPixel(2)}
            dashLength={PixelRatio.roundToNearestPixel(2)}
            dashThickness={PixelRatio.roundToNearestPixel(2)}
            dashColor={getDescriber().payDashedLine()}
            style={styles.dashStyle}
          />
          <View
            accessible={true}
            style={[getDescriber().fundingDetailContainer()]}
          >
            <View
              style={[Styles.Style.labelColumn, getDescriber().leftViewStyle()]}
            >
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                accessibilityLabel={props.strings.Labels.total}
                aria-label={props.strings.Labels.total}
                style={[
                  getDescriber()?.fundingDetailTotal(),
                  getSnackDescriber()?.snackDetailsBtnFontSTyle(),
                ]}
              >
                {props.strings.Labels.total}
              </AVText>
            </View>
            <View
              style={[
                Styles.Style.amountColumn,
                getDescriber().rightViewStyle(),
              ]}
            >
              <AVFormattedCurrency
                aria-label={`$${props.detail.Amount}`}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                style={[
                  getDescriber()?.fundingDetailTotal(),
                  getSnackDescriber()?.snackDetailsBtnFontSTyle(),
                ]}
                value={props.detail.Amount}
                currency={AccountStore.getCurrency()}
                accessibilityLabel={`$${props.detail.Amount}`}
              />
            </View>
          </View>
        </ScrollView>
        <View
          style={{
            height: Styles.Spacing.m5 + Styles.Spacing.m3,
            width: '100%',
          }}
        />

        {getDescriber().historyEmailReceiptBtn(
          props.handleEmailReceipt,
          styles,
          props.detail.Id,
          AccountStore.getRegion(),
          props.previousRoute?.includes('History'),
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Styles.white,
    flex: 1,
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    overflow: 'hidden',
    width: '100%',
  },
  date: {
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m3,
  },
  disabledRefundButton: {
    opacity: 0.5,
  },
  historyContainer: {
    flex: 1,
    marginBottom: Styles.Spacing.m5,
    padding: Styles.Spacing.m3,
    paddingVertical: 32,
  },
  largeLabel: {
    fontSize: Styles.Fonts.f1,
  },
  location: {
    fontSize: Styles.Fonts.f1,
  },
  refundButton: {
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m3,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  total: {
    color: Styles.black,
    fontSize: Styles.Fonts.f3,
    fontWeight: '300',
    marginBottom: Styles.Spacing.m1,
    marginTop: -1 * Styles.Spacing.m1,
  },
  trans: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
  leftView: {
    flex: 0.7,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rightView: {
    flex: 0.3,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  buttonSpacing: {
    marginStart: Styles.Spacing.m2,
    marginBottom: Styles.Spacing.m1,
  },
  historyEmailRcptBtn: {
    borderTopColor: '#066DFF',
    borderBottomColor: '#066DFF',
    borderLeftColor: '#066DFF',
    borderRightColor: '#066DFF',
    borderRadius: 32,
    borderWidth: Platform.OS === 'android' ? 1.2 : 1,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 20,
    paddingRight: 20,
    bottom: 10,
    right: 10,
    overflow: 'hidden',
    alignSelf: 'flex-end',
  },
});
export default FundDetail;
