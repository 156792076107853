import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

interface IHomeIconLightProps {
  color?: string;
  size?: number;
}
const HomeIconLight = ({size = 34, color = '#707070'}: IHomeIconLightProps) => (
  <Svg viewBox="0 0 34 34" width={size} fill="none" height={size}>
    <G clipPath="url(#clip0_553_627)">
      <Path
        d="M18.052 4.204a.856.856 0 00-1.104 0L2.78 16.39a.798.798 0 00-.073 1.148.848.848 0 001.177.071l1.948-1.676v10.004c0 2.245 1.865 4.063 4.167 4.063h15c2.302 0 4.166-1.818 4.166-4.063V15.934l1.948 1.676a.853.853 0 001.177-.071.798.798 0 00-.073-1.148L18.052 4.204zM7.5 25.938v-11.44l10-8.603 10 8.602v11.441c0 1.346-1.12 2.438-2.5 2.438h-3.334V20.25c0-.9-.744-1.625-1.666-1.625h-5c-.922 0-1.667.726-1.667 1.625v8.125H10c-1.38 0-2.5-1.092-2.5-2.438zm7.5 2.438V20.25h5v8.125h-5z"
        fill={color}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_553_627">
        <Path fill="#fff" transform="translate(2.5 4)" d="M0 0H30V26H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default HomeIconLight;
