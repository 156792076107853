import React, {useEffect, useCallback} from 'react';
import queryString from 'query-string';
import {NavigationContainer} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/native';
import AppRoutes from 'src/AppRoutes';
import type {RootRoutes} from 'src/AppRoutes';
import NavActions from 'src/actions/NavActions';
import LoginNavigator from './LoginNavigator';
import MainConsumerNavigator from './MainConsumerNavigator';
import {
  changeEnvironment,
  handleCreateAccount,
  handleVerifyEmail,
  handleStartAccount,
  handleLinkAccount,
  handlePayrollUnsubscribe,
  handleReferral,
  handleReceipt,
  handleSnack,
} from 'src/components/elements/DeepLinkFunctions';
import LoadingScreen from 'src/components/screens/LoadingScreen';
import PasscodeScreen from 'src/components/screens/PasscodeScreen';
import SnackActions from 'src/constants/sendSnack/SnackActions';
type AppNavigatorProps = {
  rootRoute: RootRoutes;
  onStateChange: (currentState: NavigationState) => void;
};
type RootNavigatorProps = {
  rootRoute: RootRoutes;
};

export function parseParams(path: string) {
  const index = path.indexOf('?');

  if (index === -1) {
    return {};
  } else {
    return queryString.parse(path.substring(index), {
      arrayFormat: 'separator',
    });
  }
}

function parseUrlEnv() {
  const url = window.location.href;

  if (url.search(/^https?:\/\/localhost:.*$/) === 0) {
    return 'DEV';
  }

  if (url.search(/^https:\/\/365paytest3\.365rm\.us.*$/) === 0) {
    return 'DEV';
  }

  if (url.search(/^https:\/\/(staging|test4)-365pay\.365rm\.us.*$/) === 0) {
    return 'QA';
  }

  return undefined;
}

const linking: any = {
  config: {},
  getStateFromPath: (path: string, options: any) => {
    // change url to hide params and go to home on refresh
    window.history.pushState('', '', '/');
    const params: any = parseParams(path);
    params.env = params.env || parseUrlEnv();

    if (path.startsWith('/newaccount_step2')) {
      handleVerifyEmail(params);
      handleCreateAccount(params);
      return {
        routes: [
          {
            name: AppRoutes.Welcome,
          },
        ],
      };
    } else if (path.toLowerCase().startsWith('/verifyemail')) {
      handleVerifyEmail(params);
      return {
        routes: [
          {
            name: AppRoutes.Welcome,
          },
        ],
      };
    } else if (path.startsWith('/startAccount')) {
      handleStartAccount(params);
      return {
        routes: [
          {
            name: AppRoutes.Welcome,
          },
        ],
      };
    } else if (path.startsWith('/linkAccount')) {
      handleLinkAccount(params);
      return {
        routes: [
          {
            name: AppRoutes.Welcome,
          },
        ],
      };
    } else if (path.startsWith('/payrollUnsubscribe')) {
      handlePayrollUnsubscribe(params);
      return {
        routes: [
          {
            name: AppRoutes.Welcome,
          },
        ],
      };
    } else if (path.startsWith('/changeEnvironment')) {
      // changes environment based on query params or url if env is not a query param
      changeEnvironment(params.env);
    } else if (path.startsWith('/referral')) {
      handleReferral(params);
      return {
        routes: [
          {
            name: AppRoutes.Welcome,
          },
        ],
      };
    } else if (path.startsWith('/canteenpantry')) {
      window.location.href = '/canteenpantry.html';
    } else if (path.startsWith('/receipt')) {
      handleReceipt(params);
      return {
        routes: [
          {
            name: AppRoutes.Welcome,
          },
        ],
      };
    } else if (path.startsWith('/acceptSnack')) {
      handleSnack(params, SnackActions.AcceptSnack as 'AcceptSnack');
      return {
        routes: [
          {
            name: AppRoutes.Welcome,
          },
        ],
      };
    } else if (path.startsWith('/blockSnack')) {
      handleSnack(params, SnackActions.BlockSnack as 'BlockSnack');
      return {
        routes: [
          {
            name: AppRoutes.Welcome,
          },
        ],
      };
    }

    NavActions.pop();
    return {
      routes: [],
    };
  },
  getPathFromState: (state: any, config: any) => {
    return '';
  },
};
export default function AppNavigator(props: AppNavigatorProps) {
  const onReady = useCallback(() => {
    // @ts-expect-error AppNavigator not use
    NavActions.isReadyRef.current = true;
  }, []);
  useEffect(() => {
    return () => {
      // @ts-expect-error AppNavigator not use
      NavActions.isReadyRef.current = false;
    };
  }, []);
  return (
    <NavigationContainer
      ref={NavActions.navigatorRef}
      linking={linking}
      fallback={<LoadingScreen />}
      onStateChange={props.onStateChange}
      onReady={onReady}
    >
      <RootNavigator rootRoute={props.rootRoute} />
    </NavigationContainer>
  );
}

function RootNavigator({rootRoute}: RootNavigatorProps) {
  switch (rootRoute) {
    case 'Login':
      return <LoginNavigator />;

    case 'Passcode':
      return <PasscodeScreen />;

    case 'App':
      return <MainConsumerNavigator />;

    case 'Loading':
      return <LoadingScreen />;
  }

  return null;
}
