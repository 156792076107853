import LocalizedStrings, {
  LocalizedStrings as LocalizedStringsType,
} from 'react-native-localization';

import da from './translations/da.json';
import de from './translations/de.json';
import en from './translations/en.json';
import en_GB from './translations/en-GB.json';
import es from './translations/es.json';
import fi from './translations/fi.json';
import fr from './translations/fr.json';
import it from './translations/it.json';
import nl from './translations/nl.json';
import no from './translations/no.json';
import sv from './translations/sv.json';

class Localized {
  // Use these strings for buttons
  Buttons = new LocalizedStrings({
    en: en.buttons,
    'en-GB': en_GB.buttons,
    es: es.buttons,
    fr: fr.buttons,
    de: de.buttons,
    it: it.buttons,
    nl: nl.buttons,
    sv: sv.buttons,
    da: da.buttons,
    fi: fi.buttons,
    no: no.buttons,
    nb: no.buttons,
    nn: no.buttons,
  }) as LocalizedStringsType<(typeof en)['buttons']>;

  // Use these strings for text that is not a button or message bar.
  Labels = new LocalizedStrings({
    en: en.labels,
    'en-GB': en_GB.labels,
    es: es.labels,
    fr: fr.labels,
    de: de.labels,
    it: it.labels,
    nl: nl.labels,
    sv: sv.labels,
    da: da.labels,
    fi: fi.labels,
    no: no.labels,
    nb: no.labels,
    nn: no.labels,
  }) as LocalizedStringsType<(typeof en)['labels']>;

  // Use these strings for red message bars
  Errors = new LocalizedStrings({
    en: en.errors,
    'en-GB': en_GB.errors,
    es: es.errors,
    fr: fr.errors,
    de: de.errors,
    it: it.errors,
    nl: nl.errors,
    sv: sv.errors,
    da: da.errors,
    fi: fi.errors,
    no: no.errors,
    nb: no.errors,
    nn: no.errors,
  }) as LocalizedStringsType<(typeof en)['errors']>;

  // Use these strings for green message bars
  Success = new LocalizedStrings({
    en: en.success,
    'en-GB': en_GB.success,
    es: es.success,
    fr: fr.success,
    de: de.success,
    it: it.success,
    nl: nl.success,
    sv: sv.success,
    da: da.success,
    fi: fi.success,
    no: no.success,
    nb: no.success,
    nn: no.success,
  }) as LocalizedStringsType<(typeof en)['success']>;

  setLanguageTo(languageCode: string) {
    this.Buttons.setLanguage(languageCode);
    this.Labels.setLanguage(languageCode);
    this.Errors.setLanguage(languageCode);
    this.Success.setLanguage(languageCode);
  }

  getLanguage(): string {
    return this.Buttons.getLanguage();
  }

  getAvailableLanguages(): string[] {
    return this.Buttons.getAvailableLanguages();
  }

  getInterfaceLanguage(): string {
    return this.Buttons.getInterfaceLanguage();
  }
}

export default new Localized();
