import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
type NotifyChatIconProps = {
  width?: number;
  height?: number;
  bgColor?: string;
};
export default class NotifyChatIcon extends React.Component<NotifyChatIconProps> {
  render() {
    return (
      <Svg
        width={this.props.width || 42}
        height={this.props.height || 42}
        viewBox="0 0 42 42"
        fill="none"
      >
        <Path
          d="M26.418 27.895c-1.71 3.04-5.578 5.166-10.067 5.166-1.463 0-2.886-.224-4.227-.665l-.453-.149-.383.282c-2.042 1.5-4.175 2.497-5.724 3.1 1.533-2.48 2.137-4.92 2.204-5.202l.115-.487-.345-.362c-1.434-1.507-2.191-3.297-2.191-5.177 0-3.31 2.373-6.193 5.851-7.65a10.542 10.542 0 010-1.982 13.283 13.283 0 00-3.859 2.136C4.863 18.893 3.5 21.555 3.5 24.401c0 2.2.81 4.285 2.347 6.06-.247.846-.879 2.72-2.062 4.524a1.673 1.673 0 00-.077 1.737 1.7 1.7 0 002.094.792c1.588-.59 3.919-1.618 6.199-3.223 1.393.409 2.854.616 4.35.616 3.395 0 6.595-1.07 9.011-3.01 1.496-1.201 2.584-2.648 3.212-4.233-.708.122-1.428.198-2.156.23z"
          fill={this.props.bgColor || '#003349'}
        />
        <Path
          d="M38.214 26.34c-1.183-1.804-1.814-3.678-2.06-4.523 1.536-1.776 2.346-3.86 2.346-6.06 0-2.845-1.364-5.508-3.839-7.496-2.416-1.942-5.616-3.011-9.012-3.011-3.395 0-6.596 1.07-9.012 3.01-2.475 1.99-3.838 4.651-3.838 7.497 0 2.845 1.363 5.507 3.838 7.496 2.416 1.941 5.617 3.01 9.012 3.01 1.496 0 2.957-.207 4.35-.616 2.28 1.605 4.61 2.634 6.199 3.223a1.701 1.701 0 002.094-.792c.3-.556.271-1.206-.078-1.738zm-7.886-2.738l-.453.149a13.505 13.505 0 01-4.226.665c-6.068 0-11.005-3.885-11.005-8.66s4.938-8.66 11.005-8.66c6.068 0 11.005 3.885 11.005 8.66 0 1.88-.759 3.67-2.192 5.178l-.344.362.114.486c.067.282.67 2.722 2.203 5.203-1.548-.604-3.681-1.6-5.723-3.1l-.384-.282v-.001z"
          fill={this.props.bgColor || '#003349'}
        />
      </Svg>
    );
  }
}
