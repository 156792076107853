import * as React from 'react';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';
type LogoProps = {
  size: number;
};

export default class PaperBillRedeisgn extends React.Component<LogoProps> {
  render() {
    return (
      <>
        <Svg width={54} height={54} viewBox="0 0 54 54" fill="none">
          <G clipPath="url(#clip0_7458_24735)">
            <Path
              d="M43.232 15.55c1.245 0 2.25 1.023 2.25 2.29v2.29c-2.482 0-4.5-2.055-4.5-4.58h2.25zm-27 0h22.5c0 3.792 3.024 6.87 6.75 6.87v9.16c-3.726 0-6.75 3.078-6.75 6.87h-22.5c0-3.792-3.023-6.87-6.75-6.87v-9.16c3.727 0 6.75-3.078 6.75-6.87zm-6.75 18.32c2.482 0 4.5 2.055 4.5 4.58h-2.25c-1.244 0-2.25-1.023-2.25-2.29v-2.29zm31.5 4.58c0-2.526 2.018-4.58 4.5-4.58v2.29c0 1.268-1.005 2.29-2.25 2.29h-2.25zm-27-22.9c0 2.525-2.018 4.58-4.5 4.58v-2.29c0-1.267 1.006-2.29 2.25-2.29h2.25zm-2.25-2.291c-2.482 0-4.5 2.054-4.5 4.58v18.322c0 2.526 2.018 4.58 4.5 4.58h31.5c2.483 0 4.5-2.054 4.5-4.58V17.839c0-2.526-2.017-4.58-4.5-4.58h-31.5zm13.5 10.306c0 .63.507 1.145 1.125 1.145v4.58h-.562c-.619 0-1.125.515-1.125 1.145s.506 1.145 1.125 1.145h3.375c.619 0 1.125-.515 1.125-1.145s-.506-1.145-1.125-1.145h-.563v-5.725c0-.63-.506-1.145-1.125-1.145h-1.125c-.618 0-1.125.515-1.125 1.145zM20.732 27c0-1.822.712-3.57 1.977-4.858a6.69 6.69 0 014.773-2.013 6.69 6.69 0 014.773 2.013A6.933 6.933 0 0134.232 27c0 1.822-.71 3.57-1.977 4.858a6.69 6.69 0 01-4.773 2.012 6.69 6.69 0 01-4.773-2.012A6.933 6.933 0 0120.732 27zm15.75 0c0-2.43-.948-4.76-2.636-6.478a8.92 8.92 0 00-6.364-2.683 8.92 8.92 0 00-6.363 2.683A9.244 9.244 0 0018.482 27c0 2.43.949 4.76 2.637 6.478a8.92 8.92 0 006.363 2.683 8.92 8.92 0 006.364-2.683A9.244 9.244 0 0036.482 27z"
              fill="#066DFF"
            />
          </G>
          <Defs>
            <ClipPath id="clip0_7458_24735">
              <Path
                fill="#fff"
                transform="translate(7.232 8.679)"
                d="M0 0H40.5V36.6429H0z"
              />
            </ClipPath>
          </Defs>
        </Svg>
      </>
    );
  }
}
