import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import Styles from '../../../Styles';
import {Text} from 'react-native';
import FontAwesome5Pro from 'src/components/icons/FontAwesomeIcon';
import Localized from 'src/constants/AppStrings';
import {AccountFundingDescriberData} from 'src/components/screens/funding/descriptor/DescriptorType';
import React from 'react';
import PaperBill from 'src/components/img/svg/cards/paperBill';
import AVText from 'src/components/elements/AVText';

export function getPayrollDeductAgreementTxt() {
  return null;
}
export function getPayrollDeductAgreementUnderline() {
  return null;
}
export function getCreditCardAddStyleDescriptor(): {
  [index: string]: {
    [index: string]:
      | number
      | string
      | Array<{scaleX?: number; scaleY?: number}>;
  };
} {
  return {
    defaultSwitch: {
      transform: [
        {
          scaleX: 0.85,
        },
        {
          scaleY: 0.85,
        },
      ],
    },
    inputsContainer: {
      paddingHorizontal: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      flexDirection: 'column',
    },
  };
}
export function getPayrollDeductPendingDescStyle() {
  return null;
}
export function getPayrollDeductPendingTitleStyle() {
  return null;
}
export function getPayrollDeductImageStyle() {
  return null;
}
export function getPayRollDeductImage() {
  return <PaperBill size={Styles.Fonts.f5} />;
}
export function getAcntFundingHeaderStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    headerNewContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderTopRightRadius: 10,
      backgroundColor: Styles.white,
      paddingHorizontal: Styles.Spacing.m3,
      borderTopLeftRadius: 10,
    },
  };
}

export function normalPayrollAggreement() {
  return null;
}

export function accountFundingHeaderNewDescDescriptor(
  props: AccountFundingDescriberData,
): JSX.Element {
  return (
    <React.Fragment>
      <Text
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
        accessible={true}
        style={props.styles.headerNewText}
        accessibilityLabel="Add money to your account"
        accessibilityRole="header"
        role="heading"
        aria-label="Add money to your account"
      >
        {props.title}
      </Text>

      <AVTouchableOpacity
        accessibilityHint="Double tap X button to close the funding modal"
        style={{
          alignItems: 'flex-end',
          minWidth: Styles.Heights.touchTargetHeight2,
          justifyContent: 'center',
          minHeight: Styles.Heights.touchTargetHeight2,
        }}
        accessibilityLabel={Localized.Buttons.cancel}
        accessibilityRole="button"
        aria-label={Localized.Buttons.cancel}
        role="button"
        onPress={() => props.onClose()}
        accessible={true}
      >
        <FontAwesome5Pro
          name="times"
          style={{
            marginRight: Styles.Spacing.m1,
          }}
          size={Styles.Fonts.f3}
          light
          color={Styles.darkColor}
        />
      </AVTouchableOpacity>
    </React.Fragment>
  );
}
export function getResetLbl() {
  return null;
}
export function getOptinTxt() {
  return null;
}
export function getModalTexts(): {
  [index: string]: number | string;
} {
  return {
    fundModalHeaderTitle: Localized.Labels.quick_fund_your_account_redesign,
  };
}
export function getOptingOutPayrollDeduct() {
  return null;
}

export function getOptingOutPayrollDeductContent(styles) {
  return (
    <AVText
      style={[styles.infoText, getOptingOutPayrollDeduct()]}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
    >
      {Localized.Labels.opting_out_of_payroll_deduct}
    </AVText>
  );
}

export function getOptinView() {
  return null;
}
export function getFilledColor() {
  return Styles.primaryColor;
}
export function getSwitchColor() {
  return {
    false: Styles.lightGray,
    true: Styles.primaryColor,
  };
}
export function getUnfilledColor() {
  return Styles.lightGray;
}
export function getSpendLimit() {
  return null;
}
export function getRemainingStyle() {
  return null;
}
export function switchStyle() {
  return null;
}

export function payrollDeductNormlSectionImage() {
  return null;
}
export default {
  getPayrollDeductPendingTitleStyle,
  getPayrollDeductImageStyle,
  switchStyle,
  getOptinTxt,
  payrollDeductNormlSectionImage,
  getPayRollDeductImage,
  getSpendLimit,
  getOptingOutPayrollDeductContent,
  getFilledColor,
  getPayrollDeductAgreementUnderline,
  getPayrollDeductPendingDescStyle,
  getCreditCardAddStyleDescriptor,
  normalPayrollAggreement,
  getRemainingStyle,
  getSwitchColor,
  getUnfilledColor,
  getModalTexts,
  getOptinView,
  getAcntFundingHeaderStyleDescriptor,
  getPayrollDeductAgreementTxt,
  getOptingOutPayrollDeduct,
  getResetLbl,
};
