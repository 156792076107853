import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function TagIcon(props) {
  return (
    <Svg
      width={props.size ?? 58}
      height={props.size ?? 58}
      viewBox="0 0 58 58"
      fill="none"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.2 29.581L29.533 6.744l-.162-.162h-.043c-.896-.853-1.931-1.295-3.226-1.328l-10.756-.399-.475-.022a4.736 4.736 0 00-3.312 1.414l-5.308 5.3c-.971.97-1.414 2.255-1.414 3.529v.01l.033.454.723 10.501V26.268A5.033 5.033 0 006.715 28.9l.593.583L29.565 52.02l.334.334a3.245 3.245 0 004.456-.15L52.2 34.221c1.273-1.273 1.294-3.356 0-4.64zm-35.28-7.49a5.18 5.18 0 010-10.361 5.181 5.181 0 010 10.361z"
        fill={props.color ?? '#21AD5A'}
      />
    </Svg>
  );
}

export default TagIcon;
