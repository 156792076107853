import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IPayTabProps {
  color?: string;
}

function PayTab(props: IPayTabProps) {
  return (
    <Svg width={44} height={45} viewBox="0 0 44 45" fill="none">
      <Path
        d="M13.068 44v-8.4h3.228c1.68 0 2.784 1.008 2.784 2.58s-1.104 2.592-2.784 2.592h-2.184V44h-1.044zm3.144-7.44h-2.1v3.252H16.2c1.104 0 1.812-.636 1.812-1.632 0-.996-.696-1.62-1.8-1.62zM19.663 44h-1.08l3.204-8.4h1.236l3.192 8.4H25.1l-.888-2.268h-3.66L19.663 44zm2.712-7.26l-1.512 4.044H23.9l-1.524-4.044zm6.055 4.152L25.262 35.6h1.224l2.472 4.164 2.508-4.164h1.176l-3.168 5.292V44H28.43v-3.108zM17 3.875h-6c-.55 0-1 .436-1 .969v5.812c0 .533.45.969 1 .969h6c.55 0 1-.436 1-.969V4.844c0-.533-.45-.969-1-.969zm-6-1.938h6c1.656 0 3 1.302 3 2.907v5.812c0 1.605-1.344 2.906-3 2.906h-6c-1.656 0-3-1.301-3-2.906V4.844c0-1.605 1.344-2.907 3-2.907zm6 17.438h-6c-.55 0-1 .436-1 .969v5.812c0 .533.45.969 1 .969h6c.55 0 1-.436 1-.969v-5.812c0-.533-.45-.969-1-.969zm-6-1.938h6c1.656 0 3 1.302 3 2.907v5.812c0 1.605-1.344 2.907-3 2.907h-6c-1.656 0-3-1.302-3-2.907v-5.812c0-1.605 1.344-2.907 3-2.907zM27 3.876c-.55 0-1 .436-1 .969v5.812c0 .533.45.969 1 .969h6c.55 0 1-.436 1-.969V4.844c0-.533-.45-.969-1-.969h-6zm-3 .969c0-1.605 1.344-2.907 3-2.907h6c1.656 0 3 1.302 3 2.907v5.812c0 1.605-1.344 2.906-3 2.906h-6c-1.656 0-3-1.301-3-2.906V4.844zm0 13.562c0-.533.45-.968 1-.968h4c.55 0 1 .435 1 .968v4.117h4v-4.117c0-.533.45-.968 1-.968s1 .435 1 .968v5.086c0 .533-.45.969-1 .969h-6c-.55 0-1-.436-1-.969v-4.117h-2v9.203c0 .533-.45.969-1 .969s-1-.436-1-.969V18.406zM13.5 6.296h1c.55 0 1 .437 1 .97v.968c0 .533-.45.97-1 .97h-1c-.55 0-1-.437-1-.97v-.968c0-.533.45-.97 1-.97zm-1 16.47c0-.533.45-.97 1-.97h1c.55 0 1 .437 1 .97v.968c0 .533-.45.97-1 .97h-1c-.55 0-1-.437-1-.97v-.968zm17-16.47h1c.55 0 1 .437 1 .97v.968c0 .533-.45.97-1 .97h-1c-.55 0-1-.437-1-.97v-.968c0-.533.45-.97 1-.97zM28 27.126c0-.533.45-.969 1-.969h1c.55 0 1 .436 1 .969v.969c0 .533-.45.968-1 .968h-1c-.55 0-1-.435-1-.968v-.969zm6-.969h1c.55 0 1 .436 1 .969v.969c0 .533-.45.968-1 .968h-1c-.55 0-1-.435-1-.968v-.969c0-.533.45-.969 1-.969z"
        fill={props.color ?? '#066DFF'}
      />
    </Svg>
  );
}

export default PayTab;
