import * as React from 'react';
import Svg, {
  Path,
  G,
  ClipPath,
  Defs,
  Rect,
  LinearGradient,
  Stop,
} from 'react-native-svg';

type LogoProps = {
  size: number;
  style?: Record<string, unknown>;
}; // tslint:disable

export default class JCBIcon extends React.Component<LogoProps> {
  getWidth() {
    if (this.props.style && this.props.style['size']['width']) {
      return this.props.style['size']['width'];
    } else {
      return 28;
    }
  }

  getHeight() {
    if (this.props.style && this.props.style['size']['height']) {
      return this.props.style['size']['height'];
    } else {
      return 19;
    }
  }

  render() {
    return (
      <Svg
        fill="none"
        viewBox="0 0 28 19"
        width={this.getWidth()}
        height={this.getHeight()}
      >
        <Path fill="#fff" d="M1.25 1.53h25.5v16H1.25z" />
        <Rect
          width={26.5}
          height={17}
          x={0.75}
          y={1.029}
          stroke="#CFCFCF"
          rx={2.5}
        />
        <G clipPath="url(#a)">
          <Path
            fill="#fff"
            d="M21.75 13.08a2.402 2.402 0 0 1-2.4 2.397H6.304V5.98A2.402 2.402 0 0 1 8.7 3.581h13.048v9.498Z"
          />
          <Path
            fill="url(#b)"
            d="M17.5 10.643h.991c.029 0 .095-.01.123-.01a.442.442 0 0 0 .35-.443.458.458 0 0 0-.35-.444c-.028-.01-.085-.01-.123-.01H17.5v.907Z"
          />
          <Path
            fill="url(#c)"
            d="M18.378 4.384c-.944 0-1.718.764-1.718 1.718v1.784h2.426a.97.97 0 0 1 .17.01c.548.028.954.311.954.802 0 .387-.274.718-.784.784v.019c.557.038.982.35.982.83 0 .52-.472.86-1.095.86H16.65v3.493h2.521c.944 0 1.719-.764 1.719-1.718V4.384h-2.512Z"
          />
          <Path
            fill="url(#d)"
            d="M18.84 8.812a.402.402 0 0 0-.349-.406c-.018 0-.066-.01-.094-.01H17.5v.831h.897c.028 0 .085 0 .094-.01a.402.402 0 0 0 .35-.405Z"
          />
          <Path
            fill="url(#e)"
            d="M8.88 4.384c-.944 0-1.718.764-1.718 1.718v4.24c.481.235.982.386 1.482.386.595 0 .916-.358.916-.85V7.878h1.473v1.992c0 .774-.482 1.407-2.115 1.407-.991 0-1.766-.217-1.766-.217v3.616h2.521c.944 0 1.719-.765 1.719-1.719V4.385H8.88Z"
          />
          <Path
            fill="url(#f)"
            d="M13.629 4.384c-.945 0-1.719.764-1.719 1.718v2.247c.434-.368 1.19-.604 2.408-.548.651.029 1.35.208 1.35.208v.727c-.35-.18-.765-.34-1.303-.378-.925-.066-1.482.388-1.482 1.18 0 .803.557 1.256 1.482 1.18.538-.037.954-.207 1.303-.377v.727s-.69.18-1.35.208c-1.218.056-1.973-.18-2.408-.548v3.966h2.521c.944 0 1.718-.765 1.718-1.719V4.384h-2.52Z"
          />
        </G>
        <Defs>
          <LinearGradient
            id="b"
            x1={16.658}
            x2={20.901}
            y1={10.191}
            y2={10.191}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#007940" />
            <Stop offset={0.229} stopColor="#00873F" />
            <Stop offset={0.743} stopColor="#40A737" />
            <Stop offset={1} stopColor="#5CB531" />
          </LinearGradient>
          <LinearGradient
            id="c"
            x1={16.658}
            x2={20.901}
            y1={9.53}
            y2={9.53}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#007940" />
            <Stop offset={0.229} stopColor="#00873F" />
            <Stop offset={0.743} stopColor="#40A737" />
            <Stop offset={1} stopColor="#5CB531" />
          </LinearGradient>
          <LinearGradient
            id="d"
            x1={16.658}
            x2={20.901}
            y1={8.81}
            y2={8.81}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#007940" />
            <Stop offset={0.229} stopColor="#00873F" />
            <Stop offset={0.743} stopColor="#40A737" />
            <Stop offset={1} stopColor="#5CB531" />
          </LinearGradient>
          <LinearGradient
            id="e"
            x1={7.16}
            x2={11.468}
            y1={9.53}
            y2={9.53}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#1F286F" />
            <Stop offset={0.475} stopColor="#004E94" />
            <Stop offset={0.826} stopColor="#0066B1" />
            <Stop offset={1} stopColor="#006FBC" />
          </LinearGradient>
          <LinearGradient
            id="f"
            x1={11.886}
            x2={16.07}
            y1={9.53}
            y2={9.53}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#6C2C2F" />
            <Stop offset={0.173} stopColor="#882730" />
            <Stop offset={0.573} stopColor="#BE1833" />
            <Stop offset={0.859} stopColor="#DC0436" />
            <Stop offset={1} stopColor="#E60039" />
          </LinearGradient>
          <ClipPath id="a">
            <Path fill="#fff" d="M6.25 3.53H21.8v12H6.25z" />
          </ClipPath>
        </Defs>
      </Svg>
    );
  }
}
