import React from 'react';
import {Modal, Platform} from 'react-native';
import Styles from '../Styles';
import Localized from 'src/constants/AppStrings';
import AVText from './AVText';
import NavActions from 'src/actions/NavActions';
import {StyleSheet, View, Dimensions} from 'react-native';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import CancelIcon from 'src/components/img/svg/CancelIcon';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';

type SuccessDialogState = {
  showSuccessModal: boolean;
};

export type SuccessDialogProps = {
  title: string;
  desc: string;
};

const screenWidth = Dimensions.get('window').width;
const modalWidth = screenWidth * 0.8;

export class SuccessDialog extends React.Component<
  SuccessDialogProps,
  SuccessDialogState
> {
  constructor(props) {
    super(props);
    this.state = {
      showSuccessModal: true,
    };
  }

  onSuccessModalClose = () => {
    this.setState({showSuccessModal: false}, () => {
      NavActions.pop();
    });
  };

  render() {
    return (
      <>
        {this.state.showSuccessModal && (
          <Modal
            visible={this.state.showSuccessModal}
            onDismiss={this.onSuccessModalClose}
            transparent={true}
          >
            <View style={styles.successModalView}>
              <View style={styles.successModalContainer}>
                <AVTouchableOpacity
                  aria-label={`X icon, Double tap to ${Localized.Buttons.cancel} offer details`}
                  role="button"
                  style={styles.closeModalTitleContainer}
                  onPress={this.onSuccessModalClose}
                  accessible
                  accessibilityLabel={Localized.Buttons.cancel}
                  accessibilityRole="button"
                >
                  <CancelIcon />
                </AVTouchableOpacity>
                <View style={styles.successModalViewContent}>
                  <AVText
                    aria-label={`${Localized.Success.success} label, text`}
                    accessibilityLabel={`${Localized.Success.success} label`}
                    style={
                      getDescriber().getSuccessDialogDescriptor()['header']
                    }
                    accessible={true}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                  >
                    {Localized.Success.success}
                  </AVText>
                  <AVText
                    accessible={true}
                    accessibilityLabel={`${this.props.title} label`}
                    aria-label={`${this.props.title} label, text`}
                    style={[
                      getDescriber().getSuccessDialogDescriptor()['title'],
                      {
                        textAlign: 'center',
                      },
                    ]}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                  >
                    {this.props.title}
                  </AVText>
                </View>
                {getDescriber().getSuccessDialogBtn(this.onSuccessModalClose)}
              </View>
            </View>
          </Modal>
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  successModalViewContent: {
    marginTop: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  successModalView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  successModalContainer: {
    minHeight: Platform.OS == 'ios' ? 220 : 230,
    width: modalWidth,
    backgroundColor: Styles.white,
    borderRadius: Styles.Spacing.m3,
    paddingHorizontal: 25,
  },
  closeModalTitleContainer: {
    justifyContent: 'flex-end',
    position: 'absolute',
    top: Styles.Heights.h1,
    right: Styles.Heights.h1,
  },
});
