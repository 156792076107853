// @ts-nocheck Carousel
import React, {useEffect, useState} from 'react';
import Styles from 'src/components/Styles';

import Carousel, {Pagination, ParallaxImage} from 'react-native-snap-carousel';
import {
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  View,
  Platform,
  ActivityIndicator,
} from 'react-native';
import AccountStore from 'src/stores/AccountStore';
import {IsConnectedProps} from 'src/types/Screen';
import {ensureHttps} from 'src/services/StringUtils';
import {handleClick} from 'src/components/elements/promotions/MoblicoPromotionClickHandler';
import {MoblicoPromotionType} from 'src/types/Promotions';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import Localized from 'src/constants/AppStrings';
import Settings from 'src/Settings';
import HomeScreenTitles from 'src/components/elements/home/HomeScreenTitles';
import {getDescriber} from './descriptor/DescriptorType';

const {
  engagementCarouselMargin,
  engagementCarouselPaginationColor,
  engagementCarouselPaginationInactiveColor,
  engagementCarouselMarginBottom,
  engagementCarouselMarginZeroLength,
  getEngagementCarouselStyles,
} = getDescriber();

type EngagementCarouselProps = IsConnectedProps & {
  showHeader?: boolean;
  onViewAll?: () => void;
  onToastEnable?: () => void;
};

const width = Dimensions.get('window').width;
const promotionHeightRatio = 1.2;
const margin = engagementCarouselMargin();

const EngagementCarousel = (props: EngagementCarouselProps) => {
  //const scrollX = React.useRef(new Animated.Value(0)).current;

  const [promotionsData, setPromotionsData] = useState<MoblicoPromotionType[]>(
    [],
  );
  const [activeSlide, setActiveSlide] = useState(0);
  const [loadingStates, setLoadingStates] = useState([]);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  useEffect(() => {
    getPromotions();
    FirebaseAnalytic.trackEvent('componentDidMount', 'EngagementCarousel', {
      ...props,
    });
    AccountStore.addChangeListener(getPromotions);
    return () => {
      AccountStore.removeChangeListener(getPromotions);
    };
  }, []);

  /** useeffect to Initialize loading states whenever promotionsData changes */
  useEffect(() => {
    if (promotionsData.length > loadingStates.length) {
      setLoadingStates((prev) => [
        ...prev,
        ...Array(promotionsData.length - prev.length).fill(true),
      ]);
    }
  }, [promotionsData]);

  /** useeffect to enable toast message */
  useEffect(() => {
    if (
      Settings.isRefiveAnd365Pay() &&
      promotionsData.length > 0 &&
      !allImagesLoaded
    ) {
      const timeoutId = setTimeout(() => {
        props.onToastEnable();
      }, 10000);

      return () => clearTimeout(timeoutId);
    }
  }, [promotionsData.length, allImagesLoaded]);

  /** Check if all images have loaded */
  useEffect(() => {
    if (loadingStates.length > 0 && loadingStates.every((state) => !state)) {
      setAllImagesLoaded(true);
    }
  }, [loadingStates]);

  /**  Returns results of Moblico Promotions based on Settings */
  const getPromotions = () => {
    if (
      AccountStore.getConsumerEngagementId() &&
      AccountStore.isConsumerEngagementEnabled()
    ) {
      let moblicoPromotions: MoblicoPromotionType[] =
        AccountStore.getMoblicoPromosResponse();
      if (moblicoPromotions.length > 10) {
        moblicoPromotions = moblicoPromotions.slice(0, 11);
      }
      if (Settings.isRefiveAnd365Pay()) {
        moblicoPromotions = moblicoPromotions.slice(0, 5);
      }
      setPromotionsData(moblicoPromotions);
    }
  };

  const handleImageLoad = (index) => {
    setLoadingStates((prev) => {
      const updatedStates = [...prev];
      updatedStates[index] = false;
      return updatedStates;
    });
  };

  /** Returns Enagement Item UI based on Promotion type */
  const renderItem = (
    {
      item,
      index,
    }: {item: MoblicoPromotionType; index: number; dataIndex: number},
    parallaxProps: object,
  ) => {
    const promotionImage = ensureHttps(item.image.url);

    return (
      <TouchableOpacity
        onPress={() => {
          handleClick(item);
        }}
        activeOpacity={1}
        style={[
          promotionsData.length > 1 && {
            // for multiple item
            width: width - 60,
            height: (width - 60) / promotionHeightRatio,
          },
          promotionsData.length === 1 && // for one item carousel
            activeSlide == 0 && {
              left: -12,
              width: width - 40,
              height: (width - 40) / promotionHeightRatio,
            },
          {borderRadius: 1, borderColor: '#C2C2C5'},
          promotionsData.length > 1 &&
            activeSlide == 0 && {
              left: -10,
              padding: -100,
            },
        ]}
        testID={`Promotion${item.name}`}
        accessibilityLabel={`Promotion${item.name}`}
        aria-label={`Promotion${item.name}`}
      >
        {/*@ts-expect-error skip for now*/}
        <ParallaxImage
          source={{uri: promotionImage}}
          containerStyle={[
            styles.imageContainer,
            getEngagementCarouselStyles().parralaxContainer,
          ]}
          style={styles.moblicoImage}
          parallaxFactor={0.0009}
          accessibilityRole="image"
          //Property disabled does not exist on type
          // disabled={!props.isConnected}
          accessibilityLabel={`${item?.caption}`}
          aria-label={`${item?.caption}`}
          {...parallaxProps}
          resizeMode="cover"
          showSpinner={false}
          onLoad={() => handleImageLoad(index)}
        />
        {loadingStates[index] && (
          <View style={styles.spinnerOverlay}>
            <ActivityIndicator size="large" color="white" />
          </View>
        )}
      </TouchableOpacity>
    );
  };

  return promotionsData.length > 0 ? (
    <>
      {props.showHeader && (
        <HomeScreenTitles
          label={Localized.Labels.latest_offers}
          isConnected={props.isConnected}
          showViewAll={promotionsData.length > 1}
          onViewAll={props.onViewAll}
          style={{paddingTop: Styles.Spacing.m2}}
        />
      )}
      <View
        testID="engagement-carousel"
        style={[
          styles.content,
          getEngagementCarouselStyles().imageContentContainer,
          {
            height:
              promotionsData.length > 1
                ? (width - 40) / promotionHeightRatio + 60
                : (width - 40) / promotionHeightRatio,
            marginBottom:
              promotionsData.length > 1
                ? engagementCarouselMarginBottom()
                : engagementCarouselMarginZeroLength(),
          },
        ]}
      >
        <Carousel
          data={promotionsData}
          sliderHeight={width}
          renderItem={renderItem}
          layout={'default'}
          sliderWidth={width}
          itemWidth={width - 50}
          hasParallaxImages={true}
          inactiveSlideOpacity={2}
          inactiveSlideScale={0.99}
          onSnapToItem={(index) => setActiveSlide(index)}
          testID="engagement-carousel"
        />
        {promotionsData.length > 1 ? (
          <Pagination
            dotsLength={promotionsData.length}
            activeDotIndex={activeSlide}
            dotContainerStyle={{
              marginTop: Settings.isRefiveAnd365Pay() ? -60 : -40,
            }}
            dotStyle={[
              {
                marginHorizontal: -5,
                backgroundColor: engagementCarouselPaginationColor(),
              },
              getEngagementCarouselStyles().activeDotStyle,
            ]}
            inactiveDotStyle={[
              {
                marginHorizontal: -5,
                backgroundColor: engagementCarouselPaginationInactiveColor(),
              },
              getEngagementCarouselStyles().inActiveDotStyle,
            ]}
            inactiveDotOpacity={0.9}
            inactiveDotScale={0.9}
          />
        ) : (
          <View />
        )}
      </View>
    </>
  ) : null;
};

const styles = StyleSheet.create({
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: margin,
  },
  carouselItemContainer: {
    width: width - Styles.Heights.h4,
    marginHorizontal: Styles.Heights.h4 / 2,
    flex: 1,
    height: width / promotionHeightRatio,
    overflow: 'hidden',
    borderRadius: Styles.Spacing.m2,
    justifyContent: 'flex-start',
  },
  viewMoreContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: width / promotionHeightRatio - Styles.Heights.h4,
    backgroundColor: '#fff',
    shadowColor: '#000',
    borderRadius: Styles.Spacing.m2,
    shadowOffset: {width: 1, height: 1},
    elevation: 5,
    shadowRadius: 2,
    shadowOpacity: 0.4,
  },
  tileDesign: {
    width: width - 50,
    marginHorizontal: Styles.Heights.h1 / promotionHeightRatio,
    alignItems: 'center',
  },
  viewMore: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
  },
  viewMoreText: {
    fontSize: Styles.Fonts.f2,
    fontWeight: '700',
    color: Styles.darkGreenRevolve,
    marginTop: Styles.Spacing.m3,
  },
  moblicoImage: {
    width: width - Styles.Heights.h3,
    height: (width - Styles.Heights.h3) / promotionHeightRatio,
    borderRadius: Styles.Spacing.m2,
    ...StyleSheet.absoluteFillObject,
    resizeMode: Platform.OS === 'ios' ? 'cover' : 'stretch',
  },
  imageContainer: {
    flex: 1,
    marginBottom: Platform.select({ios: 0, android: 1}), // Prevent a random Android rendering issue
    borderRadius: Styles.Spacing.m2,
    borderColor: '#C2C2C5',
    shadowColor: '#000',
    shadowOffset: {width: 1, height: 1},
    elevation: 5,
    alignItems: 'center',
    shadowRadius: 2,
    shadowOpacity: 0.4,
  },
  spinnerOverlay: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    flex: 1,
    alignSelf: 'center',
  },
});

export default EngagementCarousel;
