import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IProps {
  color: string;
}

function ReadRightMark({color}: IProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M23.2499 4.24902L11.8079 19.505C11.679 19.677 11.5146 19.8193 11.3259 19.9223C11.1372 20.0252 10.9286 20.0864 10.7142 20.1016C10.4998 20.1169 10.2846 20.0859 10.0832 20.0107C9.88182 19.9356 9.69894 19.818 9.54695 19.666L6.56995 16.689"
        stroke={color}
        strokeWidth={2.0}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M6.00001 19.7598C5.86131 19.9016 5.69585 20.0146 5.51322 20.092C5.33058 20.1695 5.1344 20.2099 4.93602 20.211C4.73764 20.2121 4.54101 20.1739 4.35752 20.0985C4.17402 20.0231 4.0073 19.9121 3.86701 19.7718L0.890015 16.7998"
        stroke={color}
        strokeWidth={2.0}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <Path
        d="M17.43 4.35547L9.29004 15.2095"
        stroke={color}
        strokeWidth={2.0}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
}

export default ReadRightMark;
