import moment from 'moment';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useGlobalize} from 'react-native-globalize';
import Localized from 'src/constants/AppStrings';
import Styles from '../../../Styles';
import AVFormattedCurrency from '../../AVFormattedCurrency';
import type {HistoryItem as HistoryItemType} from 'src/types/TransactionDetail';
import AVTouchableOpacity from '../../AVTouchableOpacity';
import AccountStore from 'src/stores/AccountStore';
import AccountConstants from 'src/constants/AccountConstants';
import AVText from '../../AVText';
import RightIcon from 'src/components/img/svg/RightIcon';
import Settings from 'src/Settings';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';
import {getDescriber as getSnackDescriber} from 'src/components/screens/sendSnack/descriptor/sendasnack/DescriptorType';

type NewHistoryItemProps = {
  historyItem: HistoryItemType;
  length?: number;
  index: number;
  onPress: (item: HistoryItemType) => void;
};

export const Reason = {
  SignUp: 'Referral-Signup',
  Referral: 'Referral',
};

const NewHistoryItem = (props: NewHistoryItemProps) => {
  let {Amount} = props.historyItem;
  const {
    TransactionTypeDisplay = Localized.Labels.purchase,
    Type,
    Points,
    currency,
    BalanceTypeDisplay = Localized.Labels.my_account,
    TransDate,
  } = props.historyItem;
  const {formatCurrency} = useGlobalize();

  const formattedCurrency = formatCurrency(Amount, AccountStore.getCurrency(), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })?.replace(AccountStore.getCurrency().substring(0, 2), '');

  if (Type === AccountConstants.SALE_TYPE) {
    Amount *= -1;
  }

  const date = moment(TransDate).format('MMM D');

  const typeArray = [
    AccountConstants.SALE_TYPE,
    AccountConstants.FUNDING_TYPE,
    AccountConstants.REFERRAL_SIGNUP_TYPE,
    AccountConstants.REFERRAL_TYPE,
    AccountConstants.REFUND_TYPE,
    AccountConstants.REDEMPTION_TYPE,
  ];

  const payRedesignTypeArr = [
    AccountConstants.SALE_TYPE,
    AccountConstants.FUNDING_TYPE,
    AccountConstants.REFERRAL_SIGNUP_TYPE,
    AccountConstants.REFERRAL_TYPE,
    AccountConstants.REFUND_TYPE,
    AccountConstants.REDEMPTION_TYPE,
    AccountConstants.SEND_SNACK,
    AccountConstants.SEND_SNACK_CANCEL,
    AccountConstants.SEND_SNACK_REVERSE,
    AccountConstants.SEND_SNACK_ACCEPT,
    AccountConstants.SEND_SNACK_EXPIRE,
  ];
  const checkIs365PAyArr = () => {
    return Settings.isRefiveAnd365Pay() ? payRedesignTypeArr : typeArray;
  };
  const typeArr = checkIs365PAyArr();

  const isItemClickable = typeArr.includes(Type);

  const points = Number(Points);

  const descriptionTextColor = Settings.isRefiveAnd365Pay()
    ? Styles.black
    : Styles.darkColor;
  const getTransactionTypeDisplay = () => {
    if (TransactionTypeDisplay === 'Snack Credit')
      return Localized.Labels.send_a_snack_title;
    else if (['Sign-Up Reward'].indexOf(TransactionTypeDisplay) !== -1)
      return TransactionTypeDisplay.split('-').join(' ');
    else return TransactionTypeDisplay;
  };
  return (
    <AVTouchableOpacity
      key={`${props.index} ${props.historyItem.Id}`}
      accessible={false}
      style={styles.container}
      disabled={!isItemClickable}
      onPress={() => props.onPress(props.historyItem)}
    >
      <View style={styles.mainContainer}>
        <View style={styles.leftViewContainer}>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm13}
            numberOfLines={1}
            style={[
              getDescriber()['historyListStyleDescriptor']()[
                'historyHeaderTitleDes'
              ],
              getSnackDescriber().snackDetailsBtnFontSTyle(),
            ]}
            accessible={true}
            accessibilityLabel={`${TransactionTypeDisplay}, `}
            aria-label={`${TransactionTypeDisplay}, `}
          >
            {getTransactionTypeDisplay()}
          </AVText>

          <AVText
            accessible={true}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm13}
            style={[
              styles.balanceType,
              getDescriber()['historyListStyleDescriptor']()[
                'historyTabFontFamily'
              ],
              getSnackDescriber().snackDetailsBtnFontSTyle(),
            ]}
            aria-label={`${BalanceTypeDisplay}, `}
            accessibilityLabel={`${BalanceTypeDisplay}, `}
          >
            {BalanceTypeDisplay}
          </AVText>

          {!isNaN(points) && points > 0 && (
            <AVText
              style={[
                styles.pointsStyle,
                getDescriber()['historyListStyleDescriptor']()[
                  'historyTabFontFamily'
                ],
                {
                  color:
                    Type === AccountConstants.FUNDING_TYPE
                      ? getDescriber().fundingAmountColor()
                      : descriptionTextColor,
                },
                getSnackDescriber().snackDetailsBtnFontSTyle(),
              ]}
              accessible={true}
              testID={`${Points} ${Localized.Labels.points_earned}`}
              aria-label={`${Points} ${Localized.Labels.points_earned}, text`}
              accessibilityLabel={`${Points} ${Localized.Labels.points_earned}, text`}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm13}
            >
              {`${Points} ${Localized.Labels.points_earned}`}
            </AVText>
          )}
        </View>
        <View style={styles.amountDateView}>
          <AVFormattedCurrency
            accessible={true}
            aria-label={`${formattedCurrency}, `}
            accessibilityLabel={`${formattedCurrency}, `}
            style={[
              styles.amount,
              getDescriber()['historyListStyleDescriptor']()[
                'historyTabFontFamily'
              ],
              {
                color:
                  [
                    AccountConstants.FUNDING_TYPE,
                    AccountConstants.REFERRAL_TYPE,
                    AccountConstants.REDEMPTION_TYPE,
                    AccountConstants.ADJUSTMENT_TYPE,
                    AccountConstants.SEND_SNACK_ACCEPT,
                    AccountConstants.TRANSFER_IN,
                  ].indexOf(Type) > -1 && Amount > 0
                    ? getDescriber().fundingAmountColor()
                    : descriptionTextColor,
              },
              getSnackDescriber().snackDetailsBtnFontSTyle(),
            ]}
            value={Amount}
            currency={currency || ''}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm13}
          />
          <AVText
            accessible={true}
            aria-label={`${date}, `}
            accessibilityLabel={`${date}, `}
            style={[
              styles.dateStyle,
              getDescriber()['historyListStyleDescriptor']()[
                'historyTabFontFamily'
              ],
              getSnackDescriber().snackDetailsBtnFontSTyle(),
            ]}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm13}
          >
            {date}
          </AVText>
        </View>

        <View
          style={styles.arrow}
          accessible={true}
          accessibilityLabel={`Right arrow icon, Double tap to ${Localized.Labels.view_specfic_transaction}`}
          aria-label={`Right arrow icon, Double tap to ${Localized.Labels.view_specfic_transaction}`}
        >
          {isItemClickable && (
            <RightIcon
              size={22}
              color={getDescriber()['historyDateBtnColor']()}
            />
          )}
        </View>
      </View>

      <View
        style={props.index != (props.length || 0) - 1 ? styles.divider : null}
      />
    </AVTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Styles.white,
    flexDirection: 'column',
  },
  mainContainer: {
    flexDirection: 'row',
    padding: Styles.Heights.h1,
    alignItems: 'center',
    backgroundColor: Styles.white,
  },
  leftViewContainer: {
    flex: 1,
  },
  item: {
    alignSelf: 'flex-start',
    color: Styles.darkColor,
    flex: 2,
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  balanceType: {
    alignSelf: 'flex-start',
    color: Styles.black,
    flex: 0.5,
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
    paddingVertical: Styles.Spacing.m1 - 2,
  },
  amountDateView: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingRight: Styles.Spacing.m3,
  },
  amount: {
    alignSelf: 'flex-end',
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    textAlign: 'right',
  },
  dateStyle: {
    alignSelf: 'flex-end',
    color: Settings.isRefiveAnd365Pay()
      ? Styles.footerTabInactive
      : Styles.lightGray,
    flex: 0.5,
    fontSize: Styles.Fonts.f1,
    fontWeight: '400',
  },
  arrow: {
    width: Styles.Fonts.f1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pointsStyle: {
    fontSize: Settings.isRefiveAnd365Pay() ? Styles.Fonts.f7 : Styles.Fonts.f1,
    fontWeight: '400',
    fontStyle: Settings.isRefiveAnd365Pay() ? 'italic' : 'normal',
  },
  divider: {
    borderColor: Settings.isRefiveAnd365Pay()
      ? Styles.revolveBorderColor
      : Styles.borderColor,

    marginHorizontal: 10,
    borderWidth: StyleSheet.hairlineWidth,
    width: Settings.isRefiveAnd365Pay() ? '100%' : '90%',
  },
});

export default NewHistoryItem;
