import * as React from 'react';
import Svg, {Mask, Path, G} from 'react-native-svg';

function OpenEyeIcon(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Mask
        id="a"
        maskUnits="userSpaceOnUse"
        x={2}
        y={6}
        width={20}
        height={12}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 6.5h20v11H2v-11z"
          fill="#fff"
        />
      </Mask>
      <G mask="url(#a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 15.98c-1.679 0-3.136-.424-4.587-1.331-1.1-.69-2.144-1.621-3.213-2.64C6.962 9.466 9.247 8.02 12 8.02c1.687 0 3.31.52 4.96 1.588 1.096.71 2.068 1.575 2.889 2.397-2.02 1.879-4.631 3.973-7.849 3.973zm0-9.48C8.344 6.5 5.496 8.597 2 12c3.009 2.909 5.536 5.5 10 5.5 4.46 0 7.741-3.283 10-5.44-2.313-2.526-5.59-5.56-10-5.56z"
          fill="#707070"
        />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.336a3.664 3.664 0 100 7.328 3.664 3.664 0 000-7.328z"
        fill="#707070"
      />
    </Svg>
  );
}

export default OpenEyeIcon;
