import {VendingStylesDescriptor} from 'src/components/screens/vending/descriptor/Descriptor';
import Styles from 'src/components/Styles';

export function getVendingStyles(): VendingStylesDescriptor {
  return {
    balance: {
      fontSize: Styles.Fonts.f4,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    balanceLabel: {
      fontSize: Styles.Fonts.f2,
      textAlign: 'center',
    },
    timer: {
      fontSize: Styles.Fonts.f4,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    timerLabel: {
      fontSize: Styles.Fonts.f2,
      textAlign: 'center',
      marginBottom: Styles.Spacing.m5,
    },
    locationName: {
      fontSize: Styles.Fonts.f2,
    },
    progressContainer: {
      alignItems: 'center',
      flex: 1,
      marginHorizontal: Styles.Spacing.m1,
      marginTop: Styles.Spacing.m3,
    },
    messageContainer: {
      alignItems: 'center',
      backgroundColor: Styles.Colors.PayNew.primary01,
      borderRadius: 4,
      bottom: Styles.Spacing.m1,
      flexDirection: 'row',
      justifyContent: 'center',
      left: Styles.Spacing.m3,
      opacity: 0.9,
      padding: Styles.Spacing.m2,
      position: 'absolute',
      right: Styles.Spacing.m3,
    },
    messageText: {
      color: Styles.white,
      flex: 1,
      fontSize: Styles.Fonts.f1,
      fontWeight: 'bold',
      textAlign: 'center',
    },
  };
}

export function getTintColor(): string {
  return Styles.Colors.PayNew.primary01;
}
export function getCircleBackgroundColor(): string {
  return Styles.Colors.PayNew.neutralHuesBase09;
}
export function getButtonColor(): string {
  return Styles.Colors.PayNew.primary01;
}

export default {
  getVendingStyles,
  getTintColor,
  getCircleBackgroundColor,
  getButtonColor,
};
