import * as React from 'react';
import Svg, {Circle} from 'react-native-svg';

interface Props {
  color: string;
}

function UnReadIcon(props: Props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Circle cx={12} cy={12} r={12} fill={props.color} />
    </Svg>
  );
}

export default UnReadIcon;
