import React from 'react';
import {StyleSheet, Dimensions, Text} from 'react-native';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import Tooltip from './Tooltip';
import Styles from '../Styles';

const maxWidth = Dimensions.get('screen').width * 0.75;
type InfoIconProps = {
  infoMessage: string | string[];
};

const InfoIcon = (props: InfoIconProps) => {
  return (
    <Tooltip
      accessibilityRole="button"
      accessible={true}
      popover={
        <Text
          accessibilityLabel={props.infoMessage.toString()}
          aria-label={props.infoMessage.toString()}
          style={styles.tooltipText}
        >
          {props.infoMessage}
        </Text>
      }
      backgroundColor={Styles.darkColor}
      width={maxWidth}
    >
      {
        <FontAwesome5Pro
          style={{marginLeft: Styles.Spacing.m1}}
          accessibilityLabel={'Information icon'}
          aria-label={'Information icon'}
          name="info-circle"
          color={Styles.primaryColor}
          size={Styles.Fonts.f1 + Styles.Fonts.f1}
          light
        />
      }
    </Tooltip>
  );
};

const styles = StyleSheet.create({
  tooltipText: {
    color: Styles.white,
    fontSize: Styles.Fonts.f0,
  },
  infoButtonContainer: {
    height: Styles.Heights.touchTargetHeight2,
    width: Styles.Heights.touchTargetHeight2,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default InfoIcon;
