import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';
interface IProps {
  size?: number;
  color?: string;
  isFocused?: boolean;
}
const PayIcon = ({color = '#727179', isFocused = false}: IProps) => {
  return !isFocused ? (
    <Svg width={35} height={34} viewBox="0 0 35 34" fill="none">
      <G clipPath="url(#clip0_15532_1821)">
        <Path
          d="M13.813 7.25H8.936a.815.815 0 00-.812.813v4.874c0 .447.366.813.813.813h4.874a.815.815 0 00.813-.813V8.064a.815.815 0 00-.813-.813zM8.936 5.625h4.876a2.438 2.438 0 012.437 2.438v4.874a2.438 2.438 0 01-2.438 2.438H8.939A2.438 2.438 0 016.5 12.937V8.064a2.438 2.438 0 012.438-2.438zm4.876 14.625H8.936a.815.815 0 00-.812.813v4.875c0 .446.366.812.813.812h4.874a.815.815 0 00.813-.813v-4.875a.815.815 0 00-.813-.812zm-4.876-1.625h4.876a2.438 2.438 0 012.437 2.438v4.875a2.438 2.438 0 01-2.438 2.437H8.939A2.438 2.438 0 016.5 25.937v-4.875a2.438 2.438 0 012.438-2.437zm13-11.375a.815.815 0 00-.812.813v4.874c0 .447.366.813.813.813h4.875a.815.815 0 00.812-.813V8.064a.815.815 0 00-.813-.813h-4.875zm-2.437.813a2.438 2.438 0 012.438-2.438h4.875a2.438 2.438 0 012.437 2.438v4.874a2.438 2.438 0 01-2.438 2.438h-4.875a2.438 2.438 0 01-2.437-2.438V8.064zm0 11.374c0-.446.366-.812.813-.812h3.25c.446 0 .812.366.812.813v3.453h3.25v-3.453c0-.447.366-.813.813-.813.446 0 .812.366.812.813v4.265a.815.815 0 01-.813.813h-4.875a.815.815 0 01-.812-.813V20.25h-1.625v7.719a.815.815 0 01-.813.812.815.815 0 01-.812-.812v-8.532zM10.969 9.281h.812c.447 0 .813.366.813.813v.812a.815.815 0 01-.813.813h-.812a.815.815 0 01-.813-.813v-.812c0-.447.366-.813.813-.813zm-.813 13.813c0-.447.366-.813.813-.813h.812c.447 0 .813.366.813.813v.812a.815.815 0 01-.813.813h-.812a.815.815 0 01-.813-.813v-.812zM23.97 9.28h.812c.447 0 .813.366.813.813v.812a.815.815 0 01-.813.813h-.812a.815.815 0 01-.813-.813v-.812c0-.447.366-.813.813-.813zM22.75 26.75c0-.447.366-.813.813-.813h.812c.447 0 .813.366.813.813v.813a.815.815 0 01-.813.812h-.813a.815.815 0 01-.812-.813v-.812zm4.875-.813h.813c.446 0 .812.366.812.813v.813a.815.815 0 01-.813.812h-.812a.815.815 0 01-.813-.813v-.812c0-.447.366-.813.813-.813z"
          fill={color}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_15532_1821">
          <Path fill="#fff" transform="translate(6.5 4)" d="M0 0H22.75V26H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  ) : (
    <Svg width={34} height={34} viewBox="0 0 34 34" fill="none">
      <Path
        d="M13.393 8.063v4.874H8.464V8.064h4.929zM8.464 5.624C7.104 5.625 6 6.717 6 8.063v4.874c0 1.346 1.104 2.438 2.464 2.438h4.929c1.36 0 2.464-1.092 2.464-2.438V8.064c0-1.346-1.104-2.438-2.464-2.438H8.464zm4.929 15.438v4.875H8.464v-4.875h4.929zm-4.929-2.438C7.104 18.625 6 19.717 6 21.063v4.875c0 1.345 1.104 2.437 2.464 2.437h4.929c1.36 0 2.464-1.092 2.464-2.438v-4.875c0-1.345-1.104-2.437-2.464-2.437H8.464zM21.607 8.062h4.929v4.876h-4.929V8.061zm-2.464 0v4.876c0 1.345 1.104 2.437 2.464 2.437h4.929c1.36 0 2.464-1.092 2.464-2.438V8.064c0-1.346-1.104-2.438-2.464-2.438h-4.929c-1.36 0-2.464 1.092-2.464 2.438zm-9.447 2.032v.812c0 .447.37.813.822.813h.821a.82.82 0 00.822-.813v-.812a.82.82 0 00-.822-.813h-.821a.82.82 0 00-.822.813zm.822 12.187a.82.82 0 00-.822.813v.812c0 .447.37.813.822.813h.821a.82.82 0 00.822-.813v-.812a.82.82 0 00-.822-.813h-.821zm12.321-12.187v.812c0 .447.37.813.822.813h.821a.82.82 0 00.822-.813v-.812a.82.82 0 00-.822-.813h-.821a.82.82 0 00-.822.813zm-3.696 9.344v8.125c0 .446.37.812.821.812h1.643a.82.82 0 00.822-.813v-3.25a.82.82 0 01.821-.812.82.82 0 01.821.813c0 .446.37.812.822.812h3.286a.82.82 0 00.821-.813v-4.875a.82.82 0 00-.821-.812.82.82 0 00-.822.813.82.82 0 01-.821.812h-1.643a.82.82 0 01-.822-.813.82.82 0 00-.821-.812h-3.286a.82.82 0 00-.821.813zm5.75 7.312a.826.826 0 00-.581.238.808.808 0 000 1.149.826.826 0 001.162 0 .808.808 0 000-1.149.826.826 0 00-.581-.238zm3.286 0a.826.826 0 00-.581.238.808.808 0 000 1.149.826.826 0 001.161 0 .808.808 0 000-1.149.826.826 0 00-.58-.238z"
        fill={color}
      />
    </Svg>
  );
};
export default PayIcon;
