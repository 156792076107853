import React from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  InteractionManager,
  Platform,
  Text,
  ActivityIndicator,
  Linking,
} from 'react-native';
import {Modal as ModalPaper} from 'react-native-paper';

import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import MainConsumerContext from '../../MainConsumerContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import withIsConnected from '../../hoc/withIsConnected';
import Styles from '../../Styles';
import type {CCModel} from 'src/types/CCModel';
import type {IsConnectedProps} from 'src/types/Screen';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import CreditCard from 'src/models/CreditCard';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from 'src/redux/store';

import NetInfo from '@react-native-community/netinfo';
import {
  fetchAccountBalanceCreditCards,
  fetchAccountBalances,
} from 'src/redux/slices/accountSlice';
import {PaymentCredentials} from 'src/models/PaymentCredentials';
import {
  initStripe,
  CardForm,
  createPaymentMethod,
} from 'src/nativeModules/Stripe';
import moment from 'moment';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import BaseScreen from 'src/components/screens/BaseScreen';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

import {getDescriber} from 'src/components/screens/funding/descriptor/walletdescriptor/DescriptorType';
import Balance from 'src/models/Balance';
import OffersErrorView from 'src/components/elements/offers/OffersErrorView';

type CardsScreenProps = IsConnectedProps & {
  payrollId: string;
  dispatch: AppDispatch;
  accountId: string;
  hideBackArrow?: boolean;
  defaultBalanceId: string;
  paymentCredentials: PaymentCredentials;
  creditCards: Array<CreditCard>;
  displayBalances: Array<Balance>;
  navigation: NavigationProp<CardsScreen>;
};

type CardsScreenState = {
  showPayroll: boolean;
  payrollPending: boolean;
  isRefreshing: boolean;
  optionVisible: boolean;
  loading: boolean;
  previousRoute: string;
  hideBackArrow: boolean;
  isConnected?: boolean;
};
class CardsScreen extends React.Component<CardsScreenProps, CardsScreenState> {
  static contextType = MainConsumerContext;
  declare context: React.ContextType<typeof MainConsumerContext>;

  constructor(props: CardsScreenProps) {
    super(props);
    this.state = {
      showPayroll: AccountStore.getShowPayroll(),
      payrollPending: !props.payrollId,
      isRefreshing: false,
      optionVisible: false,
      loading: false,
      previousRoute: '',
      hideBackArrow:
        props.hideBackArrow === undefined ? true : props.hideBackArrow,
      isConnected: true,
    };
    this._onRefresh = this._onRefresh.bind(this);
    this.onAddClicked = this.onAddClicked.bind(this);
    this.onCreditCardClick = this.onCreditCardClick.bind(this);
    this.onPayrollClick = this.onPayrollClick.bind(this);
    this.addCardStripe = this.addCardStripe.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getTokenCard = this.getTokenCard.bind(this);
    this.renderAddCardModal = this.renderAddCardModal.bind(this);
  }

  componentDidMount() {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
    this.getNetworkState();
    InteractionManager.runAfterInteractions(() => {
      AccountStore.addCreditCardAddedListener(this.onCreditCardAddedOrDeleted);
      AccountStore.addCreditCardDeletedListener(
        this.onCreditCardAddedOrDeleted,
      );
      AccountStore.addDefaultTokenUpdatedListener(
        this.onCreditCardAddedOrDeleted,
      );
    });
    this.props.dispatch(fetchAccountBalances(this.props.accountId));
  }

  componentWillUnmount() {
    AccountStore.removeCreditCardAddedListener(this.onCreditCardAddedOrDeleted);
    AccountStore.removeCreditCardDeletedListener(
      this.onCreditCardAddedOrDeleted,
    );
    AccountStore.removeDefaultTokenUpdatedListener(
      this.onCreditCardAddedOrDeleted,
    );
  }
  getNetworkState = async () => {
    const networkState = await NetInfo.fetch();
    this.setState({isConnected: networkState?.isConnected});
  };
  onCreditCardAddedOrDeleted = async (params?: any) => {
    const {accountId, defaultBalanceId} = this.props;
    await this.props.dispatch(
      fetchAccountBalanceCreditCards({
        accountId,
        accountBalanceId: defaultBalanceId,
      }),
    );
    if (params?.isDefault && this.props.creditCards.length > 0) {
      let recentCard: CreditCard = this.props.creditCards[0];
      this.props.creditCards.forEach((item) => {
        if (moment(item.dateCreated).isAfter(moment(recentCard.dateCreated))) {
          recentCard = item;
        }
      });
      try {
        await ActionsFactory.getAccountActions().updateDefaultToken(
          accountId || '',
          recentCard.accountBalanceId || '',
          recentCard.id || '',
        );
        FirebaseAnalytic.trackEvent(
          'onCreditCardAddedOrDeleted',
          'CreditCardScreen',
          {
            ...this.props,
            ...this.state,
          },
        );
      } catch (error) {
        CrashlyticsEvents.log(
          'Exception',
          'CreditCardsScreen:OnCreditCardAddedOrDeleted',
          generateErrorMessage(error),
        );
        Events.Error.trackEvent(
          'Exception',
          'CreditCardsScreen:OnCreditCardAddedOrDeleted',
          generateErrorMessage(error),
        );
      }
    }
  };

  async goToAddEGiftCard() {
    const networkState = await NetInfo.fetch();
    if (!networkState.isConnected) {
      this.setState({isConnected: false});
    } else {
      if (Platform.OS === 'web') {
        NavActions.navigate(AppRoutes.AddEGiftCardManual);
      } else {
        NavActions.navigate(AppRoutes.AddEGiftCard);
      }
    }
  }

  onClose() {
    this.setState({optionVisible: false});
  }

  onPayrollClick() {
    NavActions.push(AppRoutes.PayrollDeduct);
  }

  handleMarketCard = async () => {
    const networkState = await NetInfo.fetch();
    if (!networkState.isConnected) {
      this.setState({isConnected: false});
    } else {
      NavActions.push(AppRoutes.EditMarketCard);
    }
  };

  async onAddClicked() {
    const networkState = await NetInfo.fetch();
    if (!networkState.isConnected) {
      this.setState({isConnected: false});
    } else {
      if (!AccountStore.getLocationName()) {
        NavActions.navigate(AppRoutes.ServerErrorDialog, {
          errorTitle: Localized.Labels.error_no_homelocation,
          errorDesc: Localized.Labels.error_no_home_loca_desc,
        });
      } else {
        if (
          this.props.paymentCredentials?.type ===
          Settings.processorsAsString.stripe
        ) {
          if (Platform.OS === 'web') {
            NavActions.push(AppRoutes.CreditCardStripe);
          } else {
            this.setState({optionVisible: true});
          }
        } else {
          NavActions.push(AppRoutes.CreditCard);
        }
      }
    }
  }

  async _onRefresh() {
    this.setState({
      isRefreshing: true,
    });
    this.getNetworkState();
    await this.props.dispatch(fetchAccountBalances(this.props.accountId));
    await this.props.dispatch(
      fetchAccountBalanceCreditCards({
        accountId: this.props.accountId,
        accountBalanceId: this.props.defaultBalanceId,
      }),
    );

    this.setState({
      isRefreshing: false,
    });
  }

  async getTokenCard() {
    try {
      const {paymentMethod: tokenCard, error: errorCard} =
        await createPaymentMethod({
          paymentMethodType: 'Card',
        });
      if (errorCard) {
        this.setState({loading: false});
        return alertError(
          Localized.Errors.error_adding_credit_card,
          errorCard.message,
        );
      }
      return tokenCard;
    } catch (error) {
      // This is when the user cancels out of the screen. Nothing to do here.
      return;
    }
  }

  async addCardStripe() {
    const networkState = await NetInfo.fetch();
    if (!networkState.isConnected) {
      this.setState({optionVisible: false}, () => {
        this.setState({isConnected: false});
      });
    } else {
      this.setState({loading: true});
      await initStripe({
        publishableKey: this.props.paymentCredentials?.key,
      });
      let token: any = {};
      try {
        const response =
          await ActionsFactory.getAccountActions().createSetupIntent(
            AccountStore.getAccountId(),
          );
        Logger.Log.LogAPIEvent(
          'AppAPI',
          'CreateSetupIntent',
          JSON.stringify({accountId: AccountStore.getAccountId()}),
          JSON.stringify(response),
        );

        if (response && response?.clientSecret) {
          token = await this.getTokenCard();
          if (token) {
            const ccModel: CCModel = {
              CardNumberMask: `x${token.Card.last4}`,
              Token: token.id,
              Provider: Settings.processors.stripe,
              AccountId: AccountStore.getAccountId(),
              BillingZip: token.billingDetails.address.postalCode,
              CardNumber: '',
              CvvCode: '',
              ExpirationMonth: token.Card.expMonth,
              ExpirationYear: token.Card.expYear,
              Issuer: token.Card.brand,
            };
            ActionsFactory.getAccountActions()
              .addCreditCardTokenStripe(ccModel)
              .catch(async (error) => {
                const guid = await uuid.getRandomUUID();
                CrashlyticsEvents.log(
                  'Exception',
                  'CreditCardsScreen:AddCardStripe',
                  generateErrorMessage(error),
                  guid,
                );
                Events.Error.trackEvent(
                  'Exception',
                  'CreditCardsScreen:AddCardStripe',
                  generateErrorMessage(error),
                  guid,
                );
                alertError(Localized.Errors.error_adding_credit_card, guid);
              })
              .finally(() =>
                this.setState({loading: false, optionVisible: false}),
              );
          }
        } else {
          alertError(Localized.Errors.error_adding_credit_card);
        }
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'CreditCardsScreen:AddCardStripe',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'CreditCardsScreen:AddCardStripe',
          generateErrorMessage(error),
          guid,
        );
        Logger.Log.LogAPIEvent(
          'AppAPI',
          'CreateSetupIntent',
          JSON.stringify({accountId: AccountStore.getAccountId()}),
          JSON.stringify(error),
        );
        alertError(Localized.Errors.error_adding_credit_card, guid);
      }
    }
  }

  onCreditCardClick(creditCard: CreditCard) {
    NavActions.push(AppRoutes.CreditCardDetail, {
      creditCard,
    });
  }

  renderAddCardModal = () => {
    return (
      <View style={styles.modalContainer}>
        <View style={styles.subModalContainer}>
          <View style={styles.titleContainer}>
            <TouchableOpacity
              accessible={true}
              accessibilityLabel={Localized.Buttons.cancel}
              accessibilityRole="button"
              aria-label={Localized.Buttons.cancel}
              role="button"
              onPress={this.onClose}
            >
              <Text style={styles.cancelText}>{Localized.Buttons.cancel}</Text>
            </TouchableOpacity>

            <Text
              accessible={true}
              accessibilityLabel={Localized.Labels.add_card}
              accessibilityRole="text"
              aria-label={`${Localized.Labels.add_card}, text`}
              style={styles.boldText}
            >
              {Localized.Labels.add_card}
            </Text>
            {this.state.loading ? (
              <View
                accessible={true}
                accessibilityState={{busy: this.state.loading}}
                aria-busy={this.state.loading}
              >
                <ActivityIndicator />
              </View>
            ) : (
              <TouchableOpacity
                accessible={true}
                accessibilityLabel={Localized.Buttons.done}
                accessibilityRole="button"
                accessibilityHint="Double tap to finish adding a new credit card"
                aria-label={Localized.Buttons.done}
                role="button"
                onPress={this.addCardStripe}
              >
                <Text style={styles.boldText}>{Localized.Buttons.done}</Text>
              </TouchableOpacity>
            )}
          </View>
          <CardForm
            autofocus={true}
            style={styles.cardForm}
            cardStyle={styles.cardStyle}
          />
        </View>
      </View>
    );
  };
  renderNoConnectivity = () => (
    <View style={[styles.noCardsView]}>
      <OffersErrorView
        errorText={Localized.Errors.add_credit_network_error}
        buttonLabel={Localized.Buttons.go_to_settings}
        onPress={() => Linking.openSettings()}
        viewStyle={styles.errorView}
      />
    </View>
  );

  render() {
    return (
      <>
        <BaseScreen
          title={
            Settings.isNewUI()
              ? Localized.Labels.wallet
              : Localized.Labels.payment
          }
          previousRoute={this.state.previousRoute}
          accessibilityLabel={
            Settings.isNewUI() ? Localized.Labels.payment : 'Back arrow'
          }
          accessibilityHint={
            Settings.isNewUI()
              ? Localized.Labels.payment
              : `Press to navigate back to the ${this.state.previousRoute} screen`
          }
          hideBack={this.state.hideBackArrow}
        >
          {!this.state.isConnected && this.renderNoConnectivity()}
          {this.state.isConnected && (
            <>
              {getDescriber().getWalletTopButtonsSection([
                this.goToAddEGiftCard,
                this.onAddClicked,
                this.handleMarketCard,
              ])}

              {getDescriber().getWalletCreditCardsSection(
                this.props,
                this.state,
                [
                  this.onCreditCardClick,
                  this.onPayrollClick,
                  this.onAddClicked,
                ],
                this._onRefresh,
              )}
              {getDescriber().getWalletBottomSection(
                this.props.creditCards,
                this.state.showPayroll,
                [this.goToAddEGiftCard, this.onAddClicked],
              )}
            </>
          )}
        </BaseScreen>

        <ModalPaper
          onDismiss={this.onClose}
          visible={this.state.optionVisible}
          style={Platform.select({android: {justifyContent: 'flex-end'}})}
        >
          {this.renderAddCardModal()}
        </ModalPaper>
      </>
    );
  }
}

const styles = StyleSheet.create({
  creditCard: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: Styles.Spacing.m3,
    paddingLeft: Styles.Spacing.m2,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: Styles.lightGray,
  },

  label: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
  },
  modalContainer: {
    alignSelf: 'center',
    width: Styles.ScreenWidth / 1.1,
    borderRadius: 10,
    marginBottom: Styles.Spacing.m3,
  },
  subModalContainer: {
    backgroundColor: Styles.addCardPopupColor,
    paddingHorizontal: Styles.Spacing.m2,
    paddingVertical: Styles.Spacing.m3,
    borderRadius: Styles.Spacing.m2,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelText: {
    color: Styles.primaryColor,
  },
  boldText: {
    fontWeight: 'bold',
    color: Styles.darkColor,
  },
  cardForm: {
    marginTop: Styles.Spacing.m3,
    height: Platform.OS === 'ios' ? 180 : 300,
  },
  cardStyle: {
    backgroundColor: Styles.addCardPopupColor,
  },
  noCardsView: {
    flex: 1,
    alignItems: 'center',
  },
  errorView: {
    justifyContent: 'center',
  },
  addView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: Styles.Spacing.m3 + 8,
    marginLeft: Styles.Spacing.m2,
  },
  addViewWrapper: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: Styles.Spacing.m3,
  },
  addBtn: {
    marginRight: Styles.Spacing.m2 + 2,
    //paddingVertical: Styles.Spacing.m1,
    marginBottom: Styles.Spacing.m1,
  },
  nocardsContainer: {
    width: 300,
    height: 450,
    marginVertical: 'auto',
    padding: 120,
    top: -40,
  },
  addBtnIcon: {
    marginLeft: 8,
  },
  circle: {
    width: 50,
    height: 50,
    borderRadius: 100 / 2,
    backgroundColor: Styles.primaryColor,
  },
  tileDesign: {
    width: '100%',
    height: 82,
    padding: Styles.Spacing.m3,
    flexDirection: 'row',
    backgroundColor: Styles.white,
    shadowColor: '#000',
    shadowOffset: {width: Styles.Spacing.m1, height: Styles.Spacing.m1},
    shadowOpacity: 0.07,
    shadowRadius: Styles.Spacing.m1,
    elevation: 5,
    borderRadius: Styles.Spacing.m2,
    marginBottom: Styles.Spacing.m2 + Styles.Spacing.m1,

    justifyContent: 'space-between',
    display: 'flex',
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  revolveAddTxt: {fontWeight: '400', fontSize: 18, color: Styles.black},
  revolveMainTxt: {fontWeight: '700', fontSize: 18, color: Styles.black},
  revolvePendingRightView: {fontWeight: '400', fontSize: 16},
});

const ConnectedCardsScreen = connect(
  (state: RootState) => ({
    payrollId: state.account.account?.payrollIdentifier?.value,
    accountId: state.account.account.id,
    displayBalances: state.account.account.displayBalances,
    defaultBalanceId: state.account.account.defaultBalance?.id,
    paymentCredentials: state.account.paymentCredentials,
    creditCards: state.account.creditCards,
  }),
  (dispatch: AppDispatch) => ({
    dispatch,
  }),
)(CardsScreen);

export default withForwardedNavigationParams()(
  withIsConnected(ConnectedCardsScreen),
);
