import * as React from 'react';
import {StyleSheet, View, PixelRatio, TouchableOpacity} from 'react-native';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';

import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Settings from 'src/Settings';
import BottomSheet from '@gorhom/bottom-sheet';
import RedeemTab from 'src/components/elements/rewards/RedeemTab';

export type RedeemRewardScreenProps = WithGlobalizeProps & {
  productName: string;
  imageUrl?: string;
  point?: number;
  upc?: string[];
};
enum BottomSheetState {
  NOT_READY = 'NOT_READY',
  FULLY_OPEN = 'FULLY_OPEN',
  FULLY_CLOSED = 'FULLY_CLOSED',
}
class RedeemRewardScreen extends React.Component<RedeemRewardScreenProps> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  bottomSheetRef: React.RefObject<BottomSheet> = React.createRef();
  declare bottomSheetCurrentState: BottomSheetState;
  declare bottomSheetCustomStates: Record<number, BottomSheetState>;
  constructor(props: RedeemRewardScreenProps) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.bottomSheetCustomStates = {
      [-1]: BottomSheetState.FULLY_CLOSED,
      0: BottomSheetState.FULLY_OPEN,
      999: BottomSheetState.NOT_READY,
    };
  }

  componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'RedeemRewardScreen', {
      ...this.props,
    });
    if (this.bottomSheetRef.current) {
      this.bottomSheetRef.current.close();
    }
  }

  onClose() {
    if (!this.bottomSheetRef.current) return;
    this.trackOnCloseEvent();
    this.handleNavigation();
  }

  handleNavigation() {
    if (Settings.isNewUI()) {
      this.handleNewUINavigation();
    } else {
      NavActions.navigate(AppRoutes.Main);
    }
  }

  handleNewUINavigation() {
    if (NavActions.getLastRoute() === AppRoutes.ScanAccount) {
      NavActions.popToRoute(AppRoutes.Scan);
    } else if (NavActions.getLastRoute() === AppRoutes.Balances) {
      NavActions.popToRoute(AppRoutes.Balances);
    } else {
      NavActions.pop();
    }
  }

  handleSheetChange = (index: number) => {
    this.bottomSheetCurrentState = this.bottomSheetCustomStates[index];
    if (index === -1) {
      this.onClose();
    }
  };

  trackOnCloseEvent() {
    FirebaseAnalytic.trackEvent('onClose', 'RedeemRewardScreen', {
      ...this.props,
      navigate: AppRoutes.Main,
    });
  }

  renderBottomSheet() {
    const minHeight = this.props.imageUrl ? 370 : 350;
    return (
      <BottomSheet
        ref={this.bottomSheetRef}
        snapPoints={[minHeight + PixelRatio.getFontScale() * 35]}
        enableHandlePanningGesture={true}
        enablePanDownToClose={true}
        onChange={this.handleSheetChange}
        style={styles.bottomSheet}
        enableContentPanningGesture={true}
      >
        {this.renderInner()}
      </BottomSheet>
    );
  }

  render() {
    return (
      <View style={styles.container} testID="pressable-element">
        <TouchableOpacity
          style={{
            width: '100%',
            height: '60%',
            backgroundColor: 'transparent',
          }}
          onPress={() => {
            if (this.bottomSheetCurrentState == undefined) {
              NavActions.pop();
            } else if (this.bottomSheetRef.current) {
              this.bottomSheetRef.current.forceClose();
            }
          }}
        ></TouchableOpacity>
        {this.renderBottomSheet()}
      </View>
    );
  }

  renderInner() {
    return (
      <RedeemTab
        productName={this.props.productName}
        imageUrl={this.props.imageUrl}
        point={this.props.point}
        upc={this.props.upc}
        cancel={this.onClose}
      />
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  contentContainer: {
    padding: 16,
  },
  bottomSheet: {
    borderRadius: 32, // Set the radius for rounded corners
    overflow: 'hidden', // Ensures child elements are clipped to the rounded corners
  },
});
export default withForwardedNavigationParams()(
  withGlobalize(RedeemRewardScreen),
);
