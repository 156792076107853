import Localized from 'src/constants/AppStrings';
import Welcome365PayLogo from 'src/components/img/svg/Welcome365Logo';
import Styles from 'src/components/Styles';

export function getLogoGradientProps() {
  return {
    colors: ['#FEE0E6', '#E6ECFF'],
    start: {x: 0, y: 0},
    end: {x: 0, y: 1},
  };
}

export function getWelcomeLogoImage() {
  return <></>;
}

export function getLoginLogo(): JSX.Element {
  return (
    <Welcome365PayLogo
      style={{
        width: 136,
        height: 126,
        flexShrink: 0,
      }}
    />
  );
}

export function getWelcomeDescriptionText(): string {
  return Localized.Labels.welcome_text;
}

export function getWelcomeDescriptionTextStyle() {
  return {
    color: Styles.Colors.PayNew.black01,
    textAlign: 'center',
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 24,
  };
}

export function getLoginButtonColor() {
  return Styles.Colors.PayNew.primary01;
}

export default {
  getLogoGradientProps,
  getWelcomeLogoImage,
  getLoginLogo,
  getWelcomeDescriptionTextStyle,
  getWelcomeDescriptionText,
  getLoginButtonColor,
};
