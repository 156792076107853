import * as React from 'react';
import {View, StyleSheet, Dimensions} from 'react-native';
import {List} from 'react-native-paper';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Styles from '../../Styles';
import type {MenuCategoryType, MenuProductType} from 'src/types/Menu';
import SearchProduct from '../../elements/cart/SearchProduct';
import {DNA} from 'src/types/dnaTypes';

type SubMenuProps = {
  menu: MenuCategoryType;
  dnaConfiguration?: DNA;
  onProductPress: (product: MenuProductType) => void;
};

const imageHeight = Dimensions.get('screen').height * 0.2;
export default function SubMenu({
  menu,
  dnaConfiguration,
  onProductPress,
}: SubMenuProps): JSX.Element {
  const count = menu.products?.length ?? 0;
  const [selected, setSelected] = React.useState(false);

  const onPress = () => setSelected(!selected);
  const icon = selected ? 'chevron-up' : 'chevron-down';

  /**
   * NOTE: The accessibilityLabel and aria-label props on the <FontAwesome5Pro />
   *       are empty because this information is not needed because this info is
   *       handled by the accessibilityState and expanded state.
   * Then why not remove those props?
   * Because if those props are removed, then the accessibilityElementsHidden and
   * importantForAccessibility will not ignore the component.
   */
  return (
    <View style={styles.container}>
      <View style={styles.menuContent}>
        <View style={styles.bar} />
        <View
          accessibilityState={{expanded: selected}}
          accessibilityLabel={`${menu.longname} (${count})`}
          role="button"
          aria-label={`${menu.longname} (${count})`}
          aria-expanded={selected}
          style={styles.mainCellContent}
        >
          <List.Accordion
            onPress={onPress}
            expanded={selected}
            right={() => (
              <FontAwesome5Pro
                accessibilityElementsHidden={true}
                importantForAccessibility="no-hide-descendants"
                accessibilityLabel=""
                aria-label=""
                name={icon}
                color={Styles.primaryColor}
                size={Styles.Fonts.f2}
                light
              />
            )}
            style={styles.cellContent}
            descriptionStyle={styles.productPrice}
            description={`(${count})`}
            titleStyle={styles.productName}
            title={`${menu.longname}`}
          >
            {selected &&
              menu.products?.map((product) => {
                return (
                  <View>
                    <SearchProduct
                      onPress={() => onProductPress(product)}
                      name={product.name}
                      price={product.price}
                      item={product}
                      dnaConfiguration={dnaConfiguration}
                      imageUrl={product.smallimageurl}
                      isProductCat={true}
                    />
                  </View>
                );
              })}
          </List.Accordion>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  menuContent: {
    flexDirection: 'row',
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: 1,
    marginBottom: 5,
  },
  cellContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 0,
    paddingVertical: Styles.Spacing.m3,
    paddingLeft: Styles.Spacing.m3 - Styles.Spacing.m1,
    paddingRight: Styles.Spacing.m3,
  },
  bar: {
    backgroundColor: Styles.primaryColor,
    alignSelf: 'stretch',
    width: Styles.Spacing.m1,
  },
  mainCellContent: {
    flex: 1,
    justifyContent: 'space-between',
  },
  productName: {
    fontSize: Styles.Fonts.f1,
    fontWeight: '600',
    color: Styles.darkColor,
  },
  productPrice: {
    fontSize: 14,
    color: Styles.lightGray,
  },
  productImage: {
    resizeMode: 'contain',
    height: imageHeight,
    width: undefined,
  },
  imageContainer: {
    backgroundColor: Styles.lightGray,
    width: '100%',
  },
});
