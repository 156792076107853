import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Localized from 'src/constants/AppStrings';
import ScanditView from '../ScanditView';
import Styles from '../../Styles';
import AVText from '../AVText';
import AVTouchableOpacity from '../AVTouchableOpacity';
import MainConsumerContext from '../../MainConsumerContext';
import {NavigationProp} from '@react-navigation/native';
type ScanAreaProps = {
  barcodeScanned: (barcode: string) => void;
  promotionDescription: string;
  navigation?: NavigationProp<object>;
};
type ScanAreaState = {
  scanning: boolean;
};

class ScanArea extends React.Component<ScanAreaProps, ScanAreaState> {
  didFocusSubscription = null;

  constructor(
    props: ScanAreaProps,
    context: React.ContextType<typeof MainConsumerContext>,
  ) {
    super(props, context);
    this.state = {
      scanning: true,
    };
    this.handleBarcodeScanned = this.handleBarcodeScanned.bind(this);
    this.handleScanNewItem = this.handleScanNewItem.bind(this);
    this.stopScanning = this.stopScanning.bind(this);
  }

  onFocused = () => {
    this.stopScanning();
    setTimeout(() => {
      this.handleScanNewItem();
    }, 100);
  };

  componentDidMount() {
    this.didFocusSubscription = this.props.navigation?.addListener(
      'focus',
      this.onFocused,
    );
  }

  componentWillUnmount() {
    this.stopScanning();
    this.didFocusSubscription?.();
  }

  handleBarcodeScanned(barcode: string) {
    if (this.props.barcodeScanned) {
      this.props.barcodeScanned(barcode);
    }
  }

  stopScanning() {
    this.setState({
      scanning: false,
    });
  }

  handleScanNewItem() {
    this.setState({
      scanning: true,
    });
  }

  render() {
    return (
      <View style={styles.container}>
        {this.state.scanning ? (
          <ScanditView
            accessible={true}
            accessibilityLabel={Localized.Labels.scanning_viewfinder_active}
            aria-label={Localized.Labels.scanning_viewfinder_active}
            style={[styles.scanditContainer]}
            barcodeScanned={this.handleBarcodeScanned}
            scanning={this.state.scanning}
          />
        ) : (
          <View style={[styles.container, styles.innerContainer]}>
            <AVTouchableOpacity
              style={styles.btn}
              onPress={this.handleScanNewItem}
              accessible={true}
              accessibilityRole="button"
              role="button"
            >
              <FontAwesome5Pro
                accessibilityLabel=""
                aria-label=""
                aria-hidden={true}
                accessibilityElementsHidden={true}
                importantForAccessibility="no-hide-descendants"
                name="barcode-read"
                color={Styles.primaryColor}
                size={Styles.Fonts.f3}
                light
              />
              <AVText
                accessibilityLabel={Localized.Labels.open_scanner}
                aria-label={Localized.Labels.open_scanner}
                style={[styles.btnText]}
              >
                {Localized.Labels.open_scanner}
              </AVText>
            </AVTouchableOpacity>
          </View>
        )}
        {this.props.promotionDescription ? (
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            accessible={true}
            accessibilityLabel={this.props.promotionDescription}
            aria-label={this.props.promotionDescription}
            style={styles.promotionText}
          >
            {this.props.promotionDescription}
          </AVText>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: Styles.white,
    borderColor: Styles.primaryColor,
    borderRadius: Styles.Spacing.m2,
    borderWidth: 3,
    flexDirection: 'row',
    height: 120,
    justifyContent: 'center',
    marginBottom: Styles.Spacing.m1,
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    paddingVertical: Styles.Spacing.m4,
  },
  btnText: {
    color: Styles.primaryColor,
    fontSize: Styles.Fonts.f3,
    fontWeight: 'normal',
    marginLeft: Styles.Spacing.m3,
  },
  container: {
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  innerContainer: {
    justifyContent: 'space-around',
  },
  promotionText: {
    alignSelf: 'flex-start',
    color: Styles.positiveColor,
    fontSize: Styles.Fonts.f2,
    marginLeft: Styles.Spacing.m3,
    marginVertical: Styles.Spacing.m1,
  },
  scanditContainer: {
    alignSelf: 'stretch',
    borderRadius: Styles.Spacing.m2,
    height: 120,
    overflow: 'hidden',
  },
});
export default ScanArea;
