import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import Settings from 'src/Settings';

function SuccessIconReferralDetails(props) {
  return (
    <>
      {Settings.is365Pay() ? (
        <Svg
          width={24}
          height={24}
          color={'red'}
          viewBox="0 0 24 24"
          fill="none"
          {...props}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm-1.543 13.543c-.116.116-.28.212-.423.212-.145 0-.308-.101-.428-.216l-2.693-2.693.856-.856 2.27 2.27 6-6.044.84.87-6.422 6.457z"
            fill="#066DFF"
          />
        </Svg>
      ) : (
        <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm-1.543 13.543c-.116.116-.28.212-.423.212-.145 0-.308-.101-.428-.216l-2.693-2.693.856-.856 2.27 2.27 6-6.044.84.87-6.422 6.457z"
            fill="#409F68"
          />
        </Svg>
      )}
    </>
  );
}

export default SuccessIconReferralDetails;
