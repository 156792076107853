import * as React from 'react';
import {Image, StyleSheet, View} from 'react-native';
import Swiper from 'react-native-deck-swiper';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import FontAwesome5Pro, {
  FontAwesomeIconType,
} from '../../icons/FontAwesomeIcon';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import Styles from '../../Styles';
import BaseScreen from '../BaseScreen';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import AVText from '../../elements/AVText';
import AVTouchableOpacity from '../../elements/AVTouchableOpacity';
import Localized from 'src/constants/AppStrings';
import {confirm} from '../../helpers/AlertHelper';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type ProductFeedbackScreenProps = {
  eatItUrl: string;
  eatItLocation: string;
  uniqueId: string;
};
type ProductFeedbackScreenState = {
  cards: Array<CardType>;
  cardIndex: number;
  latitude: number;
  longitude: number;
};
type CardType = {
  uri: string;
  text: string;
  category: string;
  id: string;
};

class ProductFeedbackScreen extends React.Component<
  ProductFeedbackScreenProps,
  ProductFeedbackScreenState
> {
  swiper: Swiper<any>;
  cardsReviewed = 0;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ProductFeedbackScreenProps) {
    super(props);
    this.state = {
      cards: [],
      cardIndex: 0,
      latitude: 0,
      longitude: 0,
    };
  }

  componentDidMount() {
    this.loadMoreCards();
  }

  processResponse = (response: any) => {
    FirebaseAnalytic.trackEvent('processResponse', 'ProductFeedbackScreen', {
      ...this.props,
      ...this.state,
      response,
    });
    if (response.items && response.items.length > 0) {
      const cards = response.items.map((item) => ({
        uri: item.meta.image_url,
        text: item.label.en,
        category: item.label.en,
        id: item.id,
      }));
      this.setState({
        cards: [...this.state.cards, ...cards],
      });
    }
  };
  loadMoreCards = async (count = 5) => {
    try {
      if (count > 1) {
        this.context.actions.showSpinner();
      }
      FirebaseAnalytic.trackEvent('loadMoreCards', 'ProductFeedbackScreen', {
        ...this.props,
        ...this.state,
        count,
      });

      const response =
        await ActionsFactory.getAccountActions().retrieveEatItItems(
          this.props.eatItUrl,
          this.props.eatItLocation,
          this.props.uniqueId,
          count,
        );
      this.processResponse(response);
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ProductFeedbackScreen:LoadMoreCards',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ProductFeedbackScreen:LoadMoreCards',
        generateErrorMessage(error),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  };
  pop = () => {
    NavActions.pop();
  };
  onSwiped = () => {
    this.cardsReviewed = this.cardsReviewed + 1;

    FirebaseAnalytic.trackEvent('onSwiped', 'ProductFeedbackScreen', {
      ...this.props,
      ...this.state,
    });

    if (this.cardsReviewed === 20) {
      this.cardsReviewed = 0;
      confirm(
        Localized.Labels.swipe_on_or_take_a_break,
        this.advanceCard,
        this.pop,
        Localized.Labels.keep_going,
        Localized.Buttons.im_done,
        Localized.Buttons.continue,
      );
    } else {
      this.advanceCard();
    }
  };
  advanceCard = () => {
    FirebaseAnalytic.trackEvent('advanceCard', 'ProductFeedbackScreen', {
      ...this.props,
      ...this.state,
    });
    this.loadMoreCards(1);
    this.setState({
      cardIndex: this.state.cardIndex + 1,
    });
  };
  renderImage = (card: CardType) => {
    return (
      <View style={[styles.card, Styles.Style.shadow]}>
        <Image
          style={styles.image}
          source={{
            uri: card.uri,
          }}
        />
        {this.renderCardText(card)}
      </View>
    );
  };
  renderCardText = (card: CardType) => {
    return (
      <AVText
        accessibilityLabel="Product Label"
        numberOfLines={2}
        style={styles.text}
      >
        {card.text}
      </AVText>
    );
  };
  renderNoImage = (card: CardType) => {
    return (
      <View style={styles.card}>
        <View style={[styles.image, styles.noImage]}>
          <AVText style={styles.noImageText}>
            {Localized.Labels.no_image_available}
          </AVText>
        </View>
        {this.renderCardText(card)}
      </View>
    );
  };
  renderCard = (card: CardType) => {
    if (card) {
      if (card.uri) {
        return this.renderImage(card);
      } else {
        return this.renderNoImage(card);
      }
    }

    return null;
  };
  onSwipedLeft = (cardIndex: number) => {
    this.handleSwipe(cardIndex, false, false);
  };
  onSwipedRight = (cardIndex: number) => {
    this.handleSwipe(cardIndex, false, true);
  };
  onSwipedTop = (cardIndex: number) => {
    this.handleSwipe(cardIndex, true);
  };
  handleSwipe = (cardIndex: number, skip: boolean, like?: boolean) => {
    const card = this.state.cards[cardIndex];

    if (card) {
      ActionsFactory.getAccountActions().voteEatItItem(
        this.props.eatItUrl,
        card.id,
        this.props.eatItLocation,
        skip,
        this.props.uniqueId,
        this.state.latitude,
        this.state.longitude,
        AccountStore.getLocationUserKey(),
        like,
      );
    }
  };
  onSwipedAllCards = () => {
    NavActions.replace(AppRoutes.ProductFeedbackComplete);
  };
  swipeLeft = () => {
    this.swiper.swipeLeft();
  };
  swipeRight = () => {
    this.swiper.swipeRight();
  };
  swipeTop = () => {
    this.swiper.swipeTop();
  };
  getDislikeOverlay = () => {
    return {
      element: (
        <FontAwesome5Pro
          name="times-circle"
          color={Styles.dangerColor}
          size={Styles.Fonts.f4 * 1.5}
          solid
        />
      ),
      style: {
        label: {},
        wrapper: {
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
          paddingRight: Styles.Spacing.m4,
          marginTop: Styles.Spacing.m4,
        },
      },
    };
  };
  getLikeOverlay = () => {
    return {
      element: (
        <FontAwesome5Pro
          name="heart-circle"
          color={Styles.positiveColor}
          size={Styles.Fonts.f4 * 1.5}
          solid
        />
      ),
      style: {
        label: {},
        wrapper: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          paddingLeft: Styles.Spacing.m4,
          marginTop: Styles.Spacing.m4,
        },
      },
    };
  };
  getSkipOverlay = () => {
    return {
      element: (
        <View style={styles.skipContainer}>
          <FontAwesome5Pro
            name="step-forward"
            color={Styles.bgColor}
            size={Styles.Fonts.f4}
            solid
          />
        </View>
      ),
      style: {
        label: {},
        wrapper: {
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          paddingBottom: Styles.Spacing.m3,
        },
      },
    };
  };
  getOverlayLabels = () => {
    return {
      left: this.getDislikeOverlay(),
      right: this.getLikeOverlay(),
      top: this.getSkipOverlay(),
    };
  };
  renderSwiper = () => {
    return (
      <Swiper
        ref={(swiper) => {
          this.swiper = swiper;
        }}
        onSwipedLeft={this.onSwipedLeft}
        onSwipedRight={this.onSwipedRight}
        onSwipedTop={this.onSwipedTop}
        cards={this.state.cards}
        cardIndex={this.state.cardIndex}
        marginBottom={0}
        marginTop={0}
        renderCard={this.renderCard}
        onSwiped={this.onSwiped}
        onSwipedAll={this.onSwipedAllCards}
        stackSize={5}
        disableBottomSwipe
        stackSeparation={0}
        stackScale={0}
        backgroundColor={Styles.bgColor}
        overlayLabels={this.getOverlayLabels()}
        cardStyle={styles.cardContainer}
        animateOverlayLabelsOpacity
        animateCardOpacity
        useViewOverflow={false}
      />
    );
  };
  renderFeedbackButton = (
    name: FontAwesomeIconType,
    onPress: () => void,
    color: string,
    accessibilityLabel: string,
    needsContainer = false,
  ) => {
    return (
      <AVTouchableOpacity
        style={[styles.circleButton, needsContainer && styles.skipContainer]}
        accessibilityLabel={accessibilityLabel}
        onPress={onPress}
      >
        <FontAwesome5Pro
          name={name}
          color={needsContainer ? Styles.bgColor : color}
          size={needsContainer ? Styles.Fonts.f4 : Styles.Fonts.f4 * 1.5}
          solid
        />
      </AVTouchableOpacity>
    );
  };
  renderButtonRow = () => {
    return (
      <View style={styles.buttonRow}>
        {this.renderFeedbackButton(
          'times-circle',
          this.swipeLeft,
          Styles.dangerColor,
          'dislike',
        )}
        {this.renderFeedbackButton(
          'step-forward',
          this.swipeTop,
          Styles.lightGray,
          'skip',
          true,
        )}
        {this.renderFeedbackButton(
          'heart-circle',
          this.swipeRight,
          Styles.positiveColor,
          'like',
        )}
      </View>
    );
  };

  render() {
    return (
      <BaseScreen title={Localized.Labels.eat_it_or_delete_it}>
        <View style={styles.content}>
          <View style={styles.container}>{this.renderSwiper()}</View>
          {this.renderButtonRow()}
        </View>
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m3,
    position: 'relative',
  },
  card: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    borderColor: Styles.bgColor,
    borderRadius: Styles.Spacing.m2,
    borderWidth: 2,
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m3,
    padding: Styles.Spacing.m1,
  },
  cardContainer: {
    bottom: 0,
    height: 'auto',
    left: Styles.Spacing.m3,
    right: Styles.Spacing.m3,
    top: Styles.Spacing.m3,
    width: 'auto',
  },
  skipContainer: {
    alignItems: 'center',
    borderRadius: Styles.Fonts.f4,
    justifyContent: 'center',
    width: Styles.Fonts.f4 * 1.5,
    height: Styles.Fonts.f4 * 1.5,
    backgroundColor: Styles.lightGray,
  },
  circleButton: {
    margin: Styles.Spacing.m3,
  },
  content: {
    flex: 1,
    backgroundColor: Styles.bgColor,
  },
  container: {
    alignSelf: 'stretch',
    flex: 1,
    justifyContent: 'flex-start',
    padding: Styles.Spacing.m2,
    position: 'relative',
  },
  dislike: {
    alignItems: 'center',
    backgroundColor: Styles.dangerColor,
    borderRadius: Styles.Heights.h4,
    height: Styles.Heights.h8,
    justifyContent: 'center',
    paddingTop: Styles.Spacing.m1,
    width: Styles.Heights.h8,
  },
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: Styles.white,
    borderRadius: Styles.Spacing.m1,
    flex: 1,
    height: undefined,
    justifyContent: 'center',
    marginHorizontal: Styles.Spacing.m4,
    marginTop: Styles.Spacing.m4,
    paddingHorizontal: Styles.Spacing.m1,
    resizeMode: 'contain',
    width: undefined,
  },
  like: {
    alignItems: 'center',
    backgroundColor: Styles.positiveColor,
    borderRadius: Styles.Heights.h4,
    height: Styles.Heights.h8,
    justifyContent: 'center',
    paddingTop: Styles.Spacing.m1,
    width: Styles.Heights.h8,
  },
  noImage: {
    backgroundColor: Styles.bgColor,
  },
  noImageText: {
    color: Styles.white,
    fontSize: Styles.Fonts.f5,
    textAlign: 'center',
  },
  skip: {
    alignItems: 'center',
    backgroundColor: Styles.lightGray,
    borderRadius: Styles.Heights.h4,
    height: Styles.Heights.h8,
    justifyContent: 'center',
    paddingTop: Styles.Spacing.m1,
    width: Styles.Heights.h8,
  },
  text: {
    alignSelf: 'stretch',
    fontSize: Styles.Fonts.f2,
    lineHeight: Styles.Fonts.f4,
    marginVertical: Styles.Spacing.m1,
    textAlign: 'center',
  },
});
export default withForwardedNavigationParams<ProductFeedbackScreenProps>()(
  ProductFeedbackScreen,
);
