import React, {useContext, useEffect, useState, useCallback} from 'react';
import {Platform, StyleSheet, View, TouchableOpacity} from 'react-native';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import type {ScreenProps} from 'src/types/Screen';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import Styles from '../../Styles';
import AVText from '../../elements/AVText';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useFocusEffect,
} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import ScanditView from 'src/components/elements/ScanditView';
import BaseAccountSetupScreenRedesign from 'src/components/screens/BaseAccountSetupScreenRedesign';
import {getDescriber} from '../descriptor/DescriptorType';

type RootStackParamList = {
  MarketCardRegistration: {
    onSuccess: () => void;
    email: string;
  };
};

const {actionLinkText} = getDescriber();

type MarketCardRegistrationProps = {
  route: RouteProp<RootStackParamList, 'MarketCardRegistration'>;
  navigation: StackNavigationProp<RootStackParamList, 'MarketCardRegistration'>;
};

function MarketCardRegistration({route}: MarketCardRegistrationProps) {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const {onSuccess, email} = route.params;
  const {actions}: ScreenProps = useContext(ScreenContext) as ScreenProps;
  const [scanning, setScanning] = useState(true);
  const [card, setCard] = useState('');

  useFocusEffect(
    useCallback(() => {
      const handleBeforeRemove = (e) => {
        e.preventDefault();

        if (onSuccess) {
          onSuccess();
        }
        navigation.dispatch(e.data.action);
      };
      navigation.addListener('beforeRemove', handleBeforeRemove);

      return () =>
        navigation.removeListener('beforeRemove', handleBeforeRemove);
    }, [navigation, onSuccess]),
  );

  async function logout() {
    actions.showSpinner();
    Events.Logout.trackEvent();
    await ActionsFactory.getAccountActions().logout();
    actions.hideSpinner();
    actions.showModal(
      Localized.Success.entered_market_card,
      Localized.Success.success,
      Localized.Buttons.ok,
      Styles.Colors.PayNew.primary01,
      2,
      false,
      false,
      undefined,
      () => {
        NavActions.reset(AppRoutes.Welcome, {
          shouldAutoLogin: true,
          autoLoginEmail: email,
        });
      },
    );
  }
  async function onSaveCard(code: string) {
    ///For debugging scanner don't delete
    ///const code = '123451';
    setScanning(false);
    actions.showSpinner();
    const accountId = AccountStore.getAccountId();
    try {
      const response =
        await ActionsFactory.getAccountActions().validateAndMergeAccounts(code);
      FirebaseAnalytic.trackEvent('onSaveCard', 'MarketCardRegistration', {
        card,
        response,
      });
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'AddOrUpdateMarketCard',
        JSON.stringify({}),
        JSON.stringify(response),
      );
      if (!response) {
        alertError(Localized.Errors.please_scan_valid_market_card);
      }

      actions.hideSpinner();
      if (response.statusCode === 412) {
        try {
          const message = JSON.stringify(response.message);
          const regex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
          const emailMatch = message.match(regex);
          const existingEmail = emailMatch.find((x) => x);

          NavActions.push(AppRoutes.ExistingAccountWarningScreen, {
            email: existingEmail,
            overrideEmail: email,
            onSuccess: onSuccess,
            code: code,
            accountId: accountId,
          });
        } catch (err) {
          console.error('Error parsing response message:', err);
        }
        return;
      } else if (response.statusCode === 300) {
        NavActions.push(AppRoutes.MarketCardNotUniqueScreen, {
          onSuccess: onSuccess,
          data: {id: accountId, email: email},
          code: code,
        });
        return;
      }
      switch (response.status) {
        case 'MERGED':
          logout();
          break;
        case 'NOT_MERGED':
          NavActions.push(AppRoutes.RegistrationEnterPinScreen, {
            onSuccess: onSuccess,
            data: {id: accountId, email: email},
            code: code,
          });
          break;
        default:
          throw new Error(Localized.Errors.invalid_market_card);
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      alertError(
        Localized.Errors.please_scan_valid_market_card,
        guid,
        undefined,
        Localized.Errors.invalid_market_card,
      );
      actions.hideSpinner();
      setScanning(true);
      CrashlyticsEvents.log(
        'Exception',
        'EditMarketCardScreen:onSaveCard',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'EditMarketCardScreen:onSaveCard',
        generateErrorMessage(error),
        guid,
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'AddOrUpdateMarketCard',
        JSON.stringify({}),
        JSON.stringify(error),
      );
      FirebaseAnalytic.trackEvent('onSaveCardError', 'MarketCardRegistration', {
        error: generateErrorMessage(error),
        guid,
        props: {route},
        state: {scanning, card},
      });
    }
  }

  useEffect(() => {
    if (isFocused) {
      setScanning(true);
    }
  }, [isFocused]);

  return (
    <BaseAccountSetupScreenRedesign
      headlineText={Localized.Labels.scan}
      instructionText={Localized.Labels.scan_market_card_or_qr_code}
      onBackSelect={onSuccess}
    >
      {Platform.select({
        web: (
          <>
            <View style={Styles.Style.maxWidthContainer}>
              <AllyTextInput
                label={Localized.Labels.market_card}
                value={card}
                accessible={true}
                accessibilityLabel={Localized.Labels.market_card}
                accessibilityValue={{text: card}}
                onChangeText={(text) => setCard(text)}
                autoCapitalize="none"
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
              />
            </View>
            <RoundedButton
              buttonType={ButtonType.action}
              accessibilityLabel="Save"
              onPress={() => onSaveCard(card)}
              text={Localized.Buttons.save}
            />
          </>
        ),
        default: (
          <>
            {isFocused && (
              <ScanditView
                style={styles.scanditContainer}
                barcodeScanned={onSaveCard}
                scanning={scanning}
              />
            )}
          </>
        ),
      })}
      <TouchableOpacity
        onPress={() => {
          if (onSuccess) onSuccess();
          NavActions.pop();
        }}
        style={styles.skipLinkContainer}
      >
        <AVText style={actionLinkText()['actionLinkText']}>
          {Localized.Labels.skip_for_now}
        </AVText>
      </TouchableOpacity>
    </BaseAccountSetupScreenRedesign>
  );
}

const styles = StyleSheet.create({
  skipLinkContainer: {
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 32,
  },
  skipLinkText: {
    color: '#066DFF',
    fontSize: 16,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
    textDecorationLine: 'underline',
  },
  header: {
    color: Styles.Colors.PayNew.black01,
    fontSize: 18,
    fontWeight: '400',
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m3,
    paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontStyle: 'normal',
  },
  scanditContainer: {
    alignSelf: 'stretch',
    marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    borderRadius: Styles.Spacing.m4,
    marginTop: Styles.Spacing.m3,
    overflow: 'hidden',
    flex: 1,
    marginBottom: Styles.Spacing.m3,
  },
});

export default MarketCardRegistration;
