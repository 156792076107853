import {Platform} from 'react-native';
import Styles from 'src/components/Styles';

export function getHeaderBackground(content: JSX.Element): JSX.Element {
  return content;
}

export function headerContainerStyleDescriptor(): {
  [key: string]: {[key: string]: number | string};
} {
  return {
    container: {
      flex: 1,
      flexDirection: 'column',
      backgroundColor: Styles.tabBarBackgroundColor,
    },
    safeArea: {
      backgroundColor: Styles.darkBlueRevolveColor,
    },
    headerTextStyle: {
      textAlign: 'left',
      fontSize: Styles.Fonts.f9,
      color: Styles.white,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.comodoVintage,
      height: Styles.Heights.touchTargetHeight1,
      marginTop: Platform.OS === 'ios' ? Styles.Spacing.m1 : Styles.Spacing.m0,
    },
  };
}

export function getBackIconColor(_transparent: boolean): string {
  return '#A4DB57';
}

export default {
  getHeaderBackground,
  headerContainerStyleDescriptor,
  getBackIconColor,
};
