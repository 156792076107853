import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import React from 'react';
import uuid from 'src/nativeModules/UUID';
import KeyboardAwareScrollView from '../../elements/KeyboardAwareScrollView';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import Styles from '../../Styles';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import BaseAccountSetupScreen from '../BaseAccountSetupScreen';
import ActionsFactory from 'src/actions/ActionsFactory';
import {StyleSheet, View, ScrollView} from 'react-native';
import {linkPayroll} from '../../helpers/LinkPayrollHelper';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type EnterPinProps = {
  code: string;
  data: {id: string};
  importId: string;
  importLocationId: string;
};
type EnterPinState = {
  pin: string;
};

class EnterPin extends React.Component<EnterPinProps, EnterPinState> {
  scrollView: KeyboardAwareScrollView | null;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: EnterPinProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.state = {
      pin: '',
    };
  }

  handleBack() {
    NavActions.pop();
  }

  async handleClick() {
    this.context.actions.showSpinner();
    const errorMessage = this.validate(this.state.pin);

    FirebaseAnalytic.trackEvent('handleClick', 'EnterPinScreen', {
      ...this.props,
      ...this.state,
      errorMessage,
    });

    if (errorMessage) {
      alertError(errorMessage);
      this.context.actions.hideSpinner();
    } else {
      try {
        const response =
          await ActionsFactory.getAccountActions().verifyPinScanCode(
            this.state.pin,
            this.props.code,
          );
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'verifyPinScanCode',
          JSON.stringify({}),
          JSON.stringify(response),
        );

        FirebaseAnalytic.trackEvent(
          'handleClick verifyPinScanCode',
          'EnterPinScreen',
          {
            ...this.props,
            ...this.state,
            response,
          },
        );

        if (response.accountId === this.props.data.id) {
          //Use Tally for now as link an account from KIOSK is without a token
          const res =
            await ActionsFactory.getAccountActions().getAccountInfoByTally(
              response.accountId,
            );
          if (this.props.importId) {
            //Payroll Link API call
            const linkStatus = await linkPayroll(
              this.props.importId,
              this.props.importLocationId,
              res.accountId,
              res.locationId,
            );

            if (!linkStatus) {
              return;
            }
          }

          // The account will have an email if it was linked to payroll data
          if (res.email || this.props.importId) {
            NavActions.replace(AppRoutes.KioskCreatePassword, {
              data: {...res},
            });
          } else {
            NavActions.replace(AppRoutes.KioskChangeEmail, {
              data: {...res},
            });
          }
        } else {
          throw new Error('invalid pin');
        }
      } catch (e) {
        if (!e.showMessage) {
          const guid = await uuid.getRandomUUID();
          CrashlyticsEvents.log(
            'Exception',
            'EnterPinScreen:HandleClick',
            e.message ? e.message : e.toString(),
            guid,
          );
          Events.Error.trackEvent(
            'Exception',
            'EnterPinScreen:HandleClick',
            e.message ? e.message : e.toString(),
            guid,
          );
          alertError(Localized.Errors.invalid_pin, guid);
        } else {
          alertError(e.message);
        }
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'verifyPinScanCode',
          JSON.stringify({}),
          JSON.stringify(e),
        );
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  validate(pin: string) {
    if (!pin) {
      return Localized.Errors.all_fields_required;
    }

    return null;
  }

  render() {
    return (
      <BaseAccountSetupScreen
        headlineText={Localized.Labels.setup_app}
        instructionText={Localized.Labels.enter_pin_to_continue}
      >
        <ScrollView
          style={styles.scrollView}
          keyboardDismissMode="interactive"
          automaticallyAdjustContentInsets={false}
          keyboardShouldPersistTaps="handled"
        >
          <View style={styles.inputsContainer}>
            <AllyTextInput
              label={Localized.Labels.pin}
              value={this.state.pin}
              accessible={true}
              accessibilityLabel={Localized.Labels.pin}
              accessibilityValue={{text: this.state.pin}}
              onChangeText={(text) =>
                this.setState({
                  pin: text,
                })
              }
              secureTextEntry
              onSubmitEditing={this.handleClick}
              returnKeyType="done"
            />
          </View>
        </ScrollView>
        <RoundedButton
          buttonType={ButtonType.action}
          accessible={true}
          accessibilityLabel="Next"
          aria-label="Next"
          onPress={this.handleClick}
          text={Localized.Buttons.next}
          textStyle={styles.nextButtonText}
        />
      </BaseAccountSetupScreen>
    );
  }
}

const styles = StyleSheet.create({
  input: {
    alignSelf: 'stretch',
    backgroundColor: Styles.bgColor,
    borderColor: Styles.white,
    borderRadius: 4,
    borderWidth: 1,
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f3,
    fontWeight: 'bold',
    height: Styles.Heights.h4,
    paddingHorizontal: Styles.Spacing.m1,
  },
  inputsContainer: {
    flexDirection: 'column',
    marginBottom: Styles.Spacing.m5,
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
  },
  negativeBtn: {
    borderColor: Styles.white,
    borderWidth: 1,
    height: Styles.Heights.h5,
    marginVertical: Styles.Spacing.m2,
  },
  override: {
    height: Styles.Heights.h5,
    marginTop: Styles.Spacing.m2,
  },
  scrollView: {
    alignSelf: 'stretch',
  },
  nextButtonText: {
    paddingHorizontal: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams<EnterPinProps>()(EnterPin);
