import {Device, BleError} from 'react-native-ble-plx';
import EventEmitter from 'events';
import BluetoothDeviceTypes from 'src/constants/BluetoothDeviceTypes';
import Events from 'src/logging/Events';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export default class BluetoothDevice extends EventEmitter {
  device: Device;

  constructor(device: Device) {
    super();
    this.device = device;
    this.onDeviceDisconnected = this.onDeviceDisconnected.bind(this);

    if (this.device) {
      this.device.onDisconnected(this.onDeviceDisconnected);
    }
  }

  onDeviceDisconnected(error: BleError | null | undefined) {
    this.handleDeviceDisconnected(error);
  }

  handleDeviceDisconnected(error: BleError | null | undefined) {
    if (error) {
      CrashlyticsEvents.log(
        'Warning',
        'BluetoothDevice:onDeviceDisconnected',
        error.message,
      );
      Events.Error.trackEvent(
        'Warning',
        'BluetoothDevice:onDeviceDisconnected',
        error.message,
      );
    }
  }

  get type(): string {
    return BluetoothDeviceTypes.unknown;
  }

  get name(): string {
    return this.device.name;
  }

  get rssi(): number | null | undefined {
    return this.device.rssi;
  }

  get txPowerLevel(): number | null | undefined {
    return this.device.txPowerLevel;
  }

  get isConnectable(): boolean | null | undefined {
    return this.device.isConnectable;
  }

  async connect() {
    await this.device.connect();
    await this.device.discoverAllServicesAndCharacteristics();
  }

  disconnect() {
    if (this.device) {
      return this.device.cancelConnection();
    }
  }
}
