import {useAppDispatch, useAppSelector} from 'src/redux/hooks';
import React, {useEffect, useState} from 'react';
import {fetchAnnouncements} from 'src/redux/slices/campusLocationSlice';
import AnnouncementsList from 'src/components/elements/home/AnnouncementsList';
import ShadowContainer from 'src/components/elements/ShadowContainer';
import {View, StyleSheet, Platform} from 'react-native';
import Styles from 'src/components/Styles';
import AVText from 'src/components/elements/AVText';
import Localized from 'src/constants/AppStrings';
import {getDescriber} from '../../../../components/screens/inbox/descriptor/DescriptorType';

const AnnouncementScreen = () => {
  //Returns Announcement Item UI
  const [isRefreshing, setIsRefreshing] = useState(true);
  const dispatch = useAppDispatch();
  const announcementsData = useAppSelector(
    (s) => s.campusLocation.announcements,
  );

  const selectedLocation = useAppSelector(
    (s) => s.campusLocation.selectedLocation,
  );

  useEffect(() => {
    setIsRefreshing(false);
  }, [announcementsData]);

  useEffect(() => {
    setIsRefreshing(true);
    dispatch(fetchAnnouncements(selectedLocation?.locationId));
  }, []);

  const isValidDataObtained = () => {
    return !isRefreshing && announcementsData.length > 0;
  };

  const isEmptyDataObtained = () => {
    return !isRefreshing && announcementsData.length == 0;
  };

  const isLoading = () => isRefreshing;

  return (
    <ShadowContainer
      style={Platform.OS === 'web' && styles.announceMentsBgColor}
    >
      {isValidDataObtained() && (
        <View style={styles.announceMentsParentContainer}>
          <AnnouncementsList
            isConnected={true}
            notificationFlow={true}
            announcements={announcementsData}
            showHeader={false}
          />
        </View>
      )}
      {isEmptyDataObtained() && (
        <View style={styles.emptyMessageContainer}>
          <View style={{alignSelf: 'center'}}>
            {getDescriber().announcemetEmptyIcon()}
          </View>
          <AVText style={styles.emptyMessageText}>
            {Localized.Labels.announcement_none}
          </AVText>
        </View>
      )}
      {isLoading() && (
        <View style={styles.emptyMessageContainer}>
          <AVText
            style={
              getDescriber().getDealDetailStyleDescriptor()['emptyMessageText']
            }
          >
            {Localized.Labels.loading}
          </AVText>
        </View>
      )}
    </ShadowContainer>
  );
};

const styles = StyleSheet.create({
  emptyMessageText: {
    marginTop: Styles.Spacing.m2,
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '700',
    color: '#111',
  },
  emptyMessageContainer: {
    alignSelf: 'center',
    textAlignVertical: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flex: 1,
    flexDirection: 'column',
  },
  announceMentsParentContainer: {
    flex: 1,
  },
  announceMentsBgColor: {
    backgroundColor: Styles.white,
  },
});
export default AnnouncementScreen;
