import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

function RefiveSignLogo(props) {
  return (
    <Svg width={127} height={127} viewBox="0 0 127 127" fill="none" {...props}>
      <G clipPath="url(#clip0_14565_34066)">
        <Path
          d="M63.867 126.327c-34.83 0-63.164-28.335-63.164-63.163C.703 28.334 29.038 0 63.867 0a63.03 63.03 0 0142.404 16.348c11.549 10.468 18.797 24.733 20.417 40.17a3.077 3.077 0 01-2.736 3.38 3.078 3.078 0 01-3.381-2.736c-3.043-29.079-27.419-51.005-56.7-51.005C32.429 6.152 6.856 31.725 6.856 63.164c0 31.438 25.578 57.011 57.012 57.011 14.768 0 28.772-5.624 39.436-15.84a3.07 3.07 0 014.346.096 3.07 3.07 0 01-.096 4.346c-11.81 11.318-27.328 17.545-43.691 17.545l.005.005z"
          fill="#022649"
        />
        <Path
          d="M63.7 107.474c24.473 0 44.311-19.838 44.311-44.31 0-24.473-19.838-44.311-44.31-44.311s-44.31 19.838-44.31 44.31c0 24.473 19.838 44.311 44.31 44.311z"
          fill="#A4DB57"
        />
        <Path
          d="M81.06 23.169c.91-.604 1.358-.357 1.358-.357.362.196.453.774.442 1.343l-20.17 85.492c-.247 1.031-1.323 1.142-1.831.568-.287-.322-.272-.774-.262-.945 0-.036.01-.156.02-.352.03-.488.066-.971.1-1.459 1.082-14.346.816-19.92.816-19.92-.368-7.71-.715-15.05-5.01-17.117-3.884-1.871-9.88 1.117-13.838 3.088-2.279 1.132-4.06 2.435-5.287 3.15-3.798 2.192-7.636 4.315-11.413 6.538-.181.106-.634.368-1.082.222a1.044 1.044 0 01-.674-.77 1.08 1.08 0 01.357-1.046C45.461 59.733 79.41 24.265 81.06 23.169z"
          fill="#3070ED"
        />
        <Path
          d="M61.628 112.635c-.875 0-1.72-.367-2.319-1.036-.402-.448-.86-1.238-.784-2.46l.02-.352c.03-.498.065-.991.1-1.489 1.057-13.989.815-19.613.815-19.668-.322-6.79-.654-13.813-3.833-15.342C52.66 70.86 47 73.682 43.615 75.367c-1.428.709-2.69 1.519-3.702 2.173a35.45 35.45 0 01-1.474.915c-1.614.936-3.24 1.856-4.869 2.777-2.153 1.222-4.376 2.48-6.534 3.752-1.187.7-2.168.604-2.782.403a3.124 3.124 0 01-2.062-2.3 3.137 3.137 0 01.94-2.982c54.698-57.298 56.207-58.3 56.776-58.677 1.805-1.197 3.133-.649 3.496-.452.714.387 1.559 1.252 1.534 3.199v.226l-.056.222-20.17 85.492c-.287 1.222-1.152 2.123-2.304 2.419a3.123 3.123 0 01-.78.096v.005zm-7.947-44.879c1.312 0 2.585.231 3.747.795 5.407 2.605 5.785 10.513 6.182 18.893.01.166.136 3.109-.186 10.035l16.63-70.482c-4.442 4.436-16.77 17.107-49.1 50.955.191-.105.378-.216.569-.322 1.615-.915 3.23-1.83 4.839-2.761.362-.212.8-.493 1.308-.815 1.096-.704 2.464-1.585 4.094-2.395 3.275-1.63 7.822-3.893 11.917-3.893v-.01zM111.935 117.298h2.344v9.029h1.308v-9.029h2.333v-1.248h-5.985v1.248zM125.129 116.05l-2.269 5.73-2.279-5.73h-1.559v10.277h1.318v-7.58l2.017 4.984h.966l2.032-4.989v7.585h1.303V116.05h-1.529z"
          fill="#022649"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_14565_34066">
          <Path
            fill="#fff"
            transform="translate(.703)"
            d="M0 0H126V126.327H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default RefiveSignLogo;
