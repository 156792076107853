import MenuService from 'src/services/MenuService';
import Localized from 'src/constants/AppStrings';
import {TimeSlotType} from 'src/types/Menu';
import {LocationType} from 'src/types/Location';
import NavActions from 'src/actions/NavActions';
import CartService from 'src/services/CartService';
import {CartItem} from 'src/types/TransactionDetail';
import moment from 'moment';

export const handleAvailableTimes = (
  availableTimesTimeSlots: Array<TimeSlotType>,
  location: LocationType,
  autoAdvanced: boolean,
  navigateAway: (item) => void,
  alertError: (item) => void,
) => {
  if (availableTimesTimeSlots.length > 0) {
    navigateAway(
      MenuService.getAvailableTimeSlotsFromList(
        availableTimesTimeSlots,
        location.onlineOrderConfig.kitchenSchedule,
      ),
    );
  } else if (!autoAdvanced) {
    alertError(Localized.Errors.order_ahead_unavailable);
  } else {
    alertError(Localized.Errors.no_pickup_times_available);
  }
};

export const handleClearCart = (
  displayTransactionItems: Array<CartItem>,
  sessionStart: moment.Moment | null,
  confirm,
) => {
  if (displayTransactionItems.length > 0) {
    confirm(
      Localized.Labels.cancel_order_confirm,
      () => {
        CartService.clearCart(sessionStart);
        NavActions.pop();
      },
      () => ({}),
      Localized.Labels.cancel_order,
      Localized.Labels.no,
      Localized.Labels.yes,
    );
  } else {
    CartService.clearCart(sessionStart);
    NavActions.pop();
  }
};
