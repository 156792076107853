import React from 'react';
import Svg, {Path, Text} from 'react-native-svg';

function OtpIcon({color = 'white', ...props}) {
  return (
    <Svg width={28} height={28} viewBox="0 0 28 28" fill="none" {...props}>
      {/* 1) Envelope outline */}
      <Path
        d="M3.82576 6.5H24.3106C25.8864 6.5 25.8864 8.07576 25.8864 8.07576V20.6818C25.8864 20.6818 25.8864 22.2576 24.3106 22.2576H3.82576C2.25 22.2576 2.25 20.6818 2.25 20.6818V8.07576C2.25 6.5 3.82576 6.5 3.82576 6.5Z"
        stroke={color}
        strokeWidth={1.5}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      {/* 2) Diagonal “flap” lines */}
      <Path
        d="M25.4977 7.04L15.5042 13.9314C15.082 14.2228 14.5812 14.3789 14.0682 14.3789C13.5552 14.3789 13.0543 14.2228 12.6321 13.9314L2.63867 7.04"
        stroke={color}
        strokeWidth={1.5}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      {/* 3) Three asterisks in the center */}
      <Text
        x="14"
        y="15"
        dy="5"
        fontSize="11"
        fontWeight="bold"
        fill={color}
        textAnchor="middle"
        alignmentBaseline="middle"
      >
        ***
      </Text>
    </Svg>
  );
}

export default OtpIcon;
