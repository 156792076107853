import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IStarIconProps {
  color?: string;
  size?: number;
}
const StarIcon = ({color = '#066DFF', size = 40}: IStarIconProps) => (
  <Svg width={size} height={size} viewBox="0 0 40 40" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.838 17.27l7.282 7.42L9.4 35.198c-.037.188-.037.34-.037.415 0 .302.072.565.216.753.144.227.36.3.61.3.251 0 .537-.073.825-.224l8.97-4.972 9.005 4.972c.288.15.539.225.79.225.286 0 .467-.074.61-.3.143-.189.215-.452.215-.754 0-.151 0-.302-.036-.415L28.881 24.69l7.248-7.42c.358-.376.538-.715.538-1.017 0-.527-.395-.83-1.149-.98l-10.047-1.544-4.483-9.53c-.251-.602-.61-.866-1.006-.866-.395 0-.717.264-.968.866l-4.52 9.53-10.047 1.544c-.753.15-1.113.453-1.113.98 0 .302.18.64.504 1.017z"
      fill={color}
    />
  </Svg>
);
export default StarIcon;
