import * as React from 'react';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';

interface IProps {
  size?: number;
}

function ShopIconPay({size = 32}: IProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 25 20" fill="none">
      <G clipPath="url(#clip0_7179_16200)">
        <Path
          d="M0 7.258c0-.25.062-.496.183-.715L3.2.977A1.86 1.86 0 014.835 0h15.187a1.86 1.86 0 011.635.977l3.018 5.562c.12.219.182.465.182.715 0 .824-.664 1.492-1.483 1.492H22.37v10.316a.933.933 0 11-1.864 0V8.75h-5.593v9.688c0 .863-.695 1.562-1.553 1.562H4.039c-.858 0-1.553-.7-1.553-1.563V8.75H1.484C.664 8.75 0 8.082 0 7.258zM4.35 8.75v3.75h8.7V8.75h-8.7zm15.672-6.875H4.835l-2.71 5h20.608l-2.711-5zM4.35 18.125h8.7v-3.75h-8.7v3.75z"
          fill="#393939"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_7179_16200">
          <Path fill="#fff" d="M0 0H24.8571V20H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default ShopIconPay;
