import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
type NotifyDealIconProps = {
  width: number;
  height: number;
  bgColor: string;
};
export default class NotifyDealIcon extends React.Component<NotifyDealIconProps> {
  render() {
    return (
      <Svg width={42} height={42} viewBox="0 0 42 42" fill="none">
        <Path fill="#fff" d="M0 0H42V42H0z" />
        <Path
          d="M8.071 19.24V9.312c0-.584.466-1.062 1.036-1.062h9.678c.55 0 1.074.226 1.462.624L31.64 20.562c.81.83.81 2.178 0 3.008L23 32.428c-.81.83-2.124.83-2.933 0L8.673 20.742a2.15 2.15 0 01-.608-1.5h.006zM6 19.24c0 1.13.434 2.212 1.21 3.008l11.393 11.688a4.068 4.068 0 005.859 0l8.641-8.865c1.619-1.66 1.619-4.35 0-6.01L21.71 7.373a4.092 4.092 0 00-2.932-1.241h-9.67C7.391 6.125 6 7.552 6 9.312v9.928zm7.25-4.084c.412 0 .807-.168 1.098-.467.292-.299.456-.704.456-1.127 0-.422-.164-.828-.456-1.127a1.534 1.534 0 00-1.098-.466c-.412 0-.807.168-1.098.466a1.615 1.615 0 00-.456 1.127c0 .423.164.829.456 1.127.29.3.686.467 1.098.467z"
          fill="#003349"
        />
      </Svg>
    );
  }
}
