import * as React from 'react';
import Svg, {Path, G, ClipPath, Defs, Rect} from 'react-native-svg';

function Welcome365PayLogo(props) {
  return (
    <Svg
      width={113}
      height={112}
      viewBox="0 0 113 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath="url(#clip0_15902_1119)">
        <Path
          d="M46.0756 60.9649C46.0756 64.9174 42.6786 67.6275 37.2897 67.6275C31.9008 67.6275 28.4707 64.8843 28.4707 59.9846H33.1414C33.2067 62.565 35.0358 63.9371 37.5178 63.9371C39.6411 63.9371 41.3066 62.7287 41.3066 60.6706C41.3066 58.8746 39.9676 57.7307 37.3219 57.7307H35.068V53.975H37.0607C39.5759 53.975 40.8495 52.7013 40.8495 50.9044C40.8495 49.0432 39.2816 48.0629 37.3219 48.0629C35.1011 48.0629 33.5331 49.4349 33.4678 51.5574H28.8625C28.9278 47.2785 32.3901 44.3716 37.3872 44.3716C42.548 44.3716 45.6177 46.7883 45.6177 50.5449C45.6177 53.3212 43.887 54.9544 41.633 55.542C44.0828 56.0322 46.0747 57.6323 46.0747 60.964L46.0756 60.9649Z"
          fill="#066DFF"
        />
        <Path
          d="M47.5881 56.229C47.5881 48.5862 51.2462 44.3726 56.9947 44.3726C61.2736 44.3726 64.6375 46.8224 65.1599 51.0029H60.5223C60.0974 49.1738 58.661 48.1613 56.6351 48.1613C53.6952 48.1613 52.0298 50.5128 52.0298 54.7264V55.2818C52.1281 55.1181 52.1934 54.9876 52.324 54.7917C53.3696 53.1585 55.3615 52.1138 57.7129 52.1138C62.3183 52.1138 65.4533 55.2496 65.4533 59.8541C65.4533 64.5248 62.024 67.6275 56.9285 67.6275C53.7274 67.6275 51.2784 66.3208 49.6774 64.0347C48.3385 62.1081 47.5872 59.3971 47.5872 56.2281L47.5881 56.229ZM56.9616 55.772C54.6424 55.772 53.0422 57.4052 53.0422 59.855C53.0422 62.3048 54.5771 63.9381 56.8963 63.9381C59.2155 63.9381 60.7504 62.2726 60.7504 59.855C60.7504 57.4374 59.2808 55.772 56.9616 55.772Z"
          fill="#066DFF"
        />
        <Path
          d="M84.5287 59.6913C84.5287 64.9496 80.6093 67.6284 75.8733 67.6284C70.5819 67.6284 67.3164 64.2645 67.1196 60.084H71.7903C72.0193 62.1421 73.4557 63.7744 75.9055 63.7744C77.8983 63.7744 79.7927 62.4023 79.7927 59.6913C79.7927 57.2746 78.2579 55.5761 75.8733 55.5761C74.5997 55.5761 72.8359 56.0662 72.0193 57.9606H67.4792L68.1321 44.5693H83.1566V48.5218H72.411L72.1499 53.5189C73.2929 52.4733 74.7956 51.8535 76.8205 51.8535C81.4259 51.8535 84.5287 55.0207 84.5287 59.6913Z"
          fill="#066DFF"
        />
        <Path
          d="M56.5002 111.007C26.1694 111.007 1.49316 86.3309 1.49316 56.0002C1.49316 25.6694 26.1694 0.993164 56.5002 0.993164C86.8309 0.993164 111.507 25.6694 111.507 56.0002H105.246C105.246 29.121 83.3793 7.25412 56.5002 7.25412C29.621 7.25412 7.75412 29.121 7.75412 56.0002C7.75412 82.8793 29.621 104.746 56.5002 104.746V111.007Z"
          fill="#066DFF"
        />
        <Path
          d="M56.5 98.485C33.0741 98.485 14.0149 79.4258 14.0149 56H20.2759C20.2759 75.9742 36.5257 92.2241 56.5 92.2241C76.4742 92.2241 92.7241 75.9742 92.7241 56C92.7241 36.0257 76.4742 19.7759 56.5 19.7759V13.5149C79.9258 13.5149 98.985 32.5741 98.985 56C98.985 79.4258 79.9258 98.485 56.5 98.485Z"
          fill="#066DFF"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_15902_1119">
          <Rect
            width={111.803}
            height={111.803}
            fill="white"
            transform="translate(0.598633 0.0986328)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default Welcome365PayLogo;
