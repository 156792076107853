import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface BackArrow365Props {
  fill?: string;
}

const BackArrow365: React.FC<BackArrow365Props> = ({fill = '#fff'}) => (
  <Svg width={24} height={25} viewBox="0 0 24 25" fill="none">
    <Path
      d="M5.203 11.703a1.12 1.12 0 000 1.59l9 9.004a1.125 1.125 0 001.59-1.59l-8.204-8.202 8.208-8.208a1.125 1.125 0 00-1.59-1.59l-9.004 8.996z"
      fill={fill}
    />
  </Svg>
);

export default BackArrow365;
