import Settings from 'src/Settings';
import Styles from '../../../Styles';
import FontAwesome5Pro from 'react-native-vector-icons/FontAwesome5Pro';
import AVText from 'src/components/elements/AVText';
import React from 'react';
import LocationMarker from 'src/components/img/svg/LocationMarker';
import {HomeLocationProps} from 'src/components/elements/descriptor/DescriptorType';
import NotificationUnReadIcon from 'src/components/img/svg/newui/notificationUnReadLogo';
import NotificationIcon from 'src/components/img/svg/newui/notificationLogo';
import Localized from 'src/constants/AppStrings';
import ShopIconPay from 'src/components/img/svg/ShopIconpay';
import GreetingsAndBalanceView from 'src/components/elements/home/GreetingsAndBalanceView';
import {RewardsWrappedComponent} from 'src/components/screens/home/RewardsHoc';

export function getCampusLocationStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    linkLocationBtnStyle: {
      fontSize: Styles.Fonts.f1 + 2,
      fontWeight: '700',
      color: '#066DFF',
      textDecorationLine: 'none',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    linkView: {
      alignSelf: 'flex-start',
      borderBottomColor: '#066DFF',
      borderBottomWidth: 1.5,
    },
    descriptionTextFont: {
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
  };
}

export function getScanProductName(): string {
  return Localized.Labels.scan_product;
}

export function getScanProductBtnStyle(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    applyButtonText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: Styles.Fonts.f7,
      textAlign: 'right',
      fontWeight: '400',
    },
  };
}

export function backGroundColorHomeScreen(): string {
  return Styles.tabBarBackgroundColor;
}
export function locationHeaderName(): string {
  return Localized.Labels.locations;
}

export function locationListIcon(): JSX.Element {
  return <ShopIconPay size={25} />;
}

export function linkLocationDropDown(props?: HomeLocationProps): JSX.Element {
  return (
    <>
      <LocationMarker
        size={Styles.Fonts.f3}
        color={Settings.isRefiveAnd365Pay() && Styles.Colors.PayNew.white01}
      />
      <AVText
        style={[
          homeLocationHeaderStyleDescriptor()['campusNameText'],
          homeLocationHeaderStyleDescriptor()['locationText365'],
        ]}
        numberOfLines={1}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
      >
        {props.locationNameText}
      </AVText>
      <FontAwesome5Pro
        accessible={true}
        accessibilityLabel={
          'Down arrow icon, Double tap to the Locations Screen'
        }
        aria-label={'Down arrow icon, Double tap to the Locations Screen'}
        name="chevron-down"
        color={Styles.Colors.PayNew.white01}
        size={Styles.Heights.touchTargetHeight0}
        style={[homeLocationHeaderStyleDescriptor()['campusArrow']]}
      />
    </>
  );
}

export function greetingBalanceRewardsView(
  _data?: {
    [index: string]: {[index: string]: number | string};
  },
  location?: string,
): JSX.Element {
  return (
    <>
      <GreetingsAndBalanceView />
      {location && <RewardsWrappedComponent data={_data} />}
    </>
  );
}
export function readUnreadNotificationIcon(
  props?: HomeLocationProps,
): JSX.Element {
  return (
    <>
      {props.readOrUnreadNotificaton ? (
        <NotificationUnReadIcon
          color={
            Settings.isRefiveAnd365Pay()
              ? Styles.Colors.PayNew.white01
              : '#003349'
          }
          size={Styles.Fonts.f4 + 6}
        />
      ) : (
        <NotificationIcon
          color={
            Settings.isRefiveAnd365Pay()
              ? Styles.Colors.PayNew.white01
              : '#003349'
          }
          size={Styles.Fonts.f3}
        />
      )}
    </>
  );
}

export function homeLocationHeaderStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    campusNameText: {
      marginLeft: Styles.Spacing.m0,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      maxWidth: '100%',
      color: Styles.white,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    campusArrow: {
      marginLeft: Styles.Spacing.m2,
      marginTop: 2,
    },
    locationText365: {
      color: Styles.white,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: Styles.Fonts.sectionHeader,
      fontWeight: '700',
    },
  };
}

export default {
  getScanProductName,
  getScanProductBtnStyle,
  getCampusLocationStyles,
  backGroundColorHomeScreen,
  homeLocationHeaderStyleDescriptor,
  linkLocationDropDown,
  locationHeaderName,
  readUnreadNotificationIcon,
  locationListIcon,
  greetingBalanceRewardsView,
};
