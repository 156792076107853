import * as React from 'react';
import Svg, {Circle, Path} from 'react-native-svg';
type GiftBoxUnReadProps = {
  width?: number;
  height?: number;
  color?: string;
};
export default class GiftBoxRefiveUnReadDot extends React.Component<GiftBoxUnReadProps> {
  render() {
    return (
      <>
        <Svg fill="none" height="55" viewBox="0 0 54 55" width="54">
          <Path
            d="M0 25.5C0 11.693 11.193.5 25 .5s25 11.193 25 25-11.193 25-25 25-25-11.193-25-25z"
            fill="#4588FD"
            fillOpacity={0.15}
          />
          <Path
            d="M21.93 16.725l1.63 2.775h-3.435a1.875 1.875 0 110-3.75h.103c.699 0 1.35.37 1.702.975zm-5.93.9c0 .675.164 1.313.45 1.875H14.5c-.83 0-1.5.67-1.5 1.5v3c0 .83.67 1.5 1.5 1.5h21c.83 0 1.5-.67 1.5-1.5v-3c0-.83-.67-1.5-1.5-1.5h-1.95a4.125 4.125 0 00-3.675-6h-.103a4.23 4.23 0 00-3.642 2.081L25 17.508l-1.13-1.922a4.225 4.225 0 00-3.642-2.086h-.103A4.125 4.125 0 0016 17.625zm15.75 0c0 1.036-.84 1.875-1.875 1.875H26.44l1.63-2.775a1.975 1.975 0 011.702-.975h.103c1.036 0 1.875.84 1.875 1.875zM14.5 27v8.25a2.25 2.25 0 002.25 2.25h6.75V27h-9zm12 10.5h6.75a2.25 2.25 0 002.25-2.25V27h-9v10.5z"
            fill="#3070ED"
          />
          <Circle cx={44} cy={10.5} r={10} fill="#A4DB57" />
        </Svg>
      </>
    );
  }
}
