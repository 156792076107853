import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import AccountStore from 'src/stores/AccountStore';
import Styles from '../../Styles';
import AVFormattedCurrency from '../AVFormattedCurrency';
import AVText from '../AVText';
type ReceiptItemProps = {
  desc: string;
  amount: number;
  boldLabel?: boolean;
  accessible?: boolean;
  accessibilityLabel?: string;
};

const ReceiptItem = (props: ReceiptItemProps) => (
  <View accessible={true} style={styles.itemContainer}>
    <AVText
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
      numberOfLines={1}
      accessibilityLabel={props.desc}
      aria-label={props.desc}
      style={[styles.desc, props.boldLabel && styles.bold]}
    >
      {props.desc}
    </AVText>
    <AVFormattedCurrency
      accessibilityLabel={`$${props.amount}`}
      aria-label={`$${props.amount}`}
      style={styles.amount}
      value={props.amount}
      currency={AccountStore.getCurrency()}
    />
  </View>
);

const styles = StyleSheet.create({
  amount: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  bold: {
    fontWeight: 'bold',
  },
  desc: {
    color: Styles.darkColor,
    flex: 1,
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m2,
  },
});
export default ReceiptItem;
