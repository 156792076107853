import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {NearbyLocationType} from 'src/components/elements/home/NearbyLocationView';
import {RecentLocationType} from 'src/components/elements/home/RecentLocationView';
import {AppDispatch, RootState} from 'src/redux/store';
import Events from 'src/logging/Events';
import AppApi from 'src/api/AppApi';
import PlatformApi from 'src/api/PlatformApi';
import {AnnouncementType} from 'src/types/AnnouncementType';
import {generateErrorMessage} from '../../logging/generateErrorMessage';
import CrashlyticsEvents from 'src/logging/Crashlytics';

interface ICampusLocationState {
  selectedLocation?: RecentLocationType;
  recentCampusesAndLocations: Array<RecentLocationType>;
  nearbyCampusesAndLocations: Array<NearbyLocationType>;
  diningLocations: Array<Location>;
  announcements: Array<AnnouncementType>;
  loading: boolean;
}

export const initialState: ICampusLocationState = {
  selectedLocation: null,
  recentCampusesAndLocations: [],
  nearbyCampusesAndLocations: [],
  diningLocations: [],
  announcements: [],
  loading: false,
};

export const fetchRecentCampusesAndLocations = createAsyncThunk(
  'campusLocation/fetchRecentCampusesAndLocations',
  async (accountId: string) => {
    let recentLocationsAndCampusesData;
    try {
      recentLocationsAndCampusesData =
        await AppApi.fetchRecentLocationsAndCampuses(accountId);
      const recentLocationsData = recentLocationsAndCampusesData?.filter(
        (item: RecentLocationType) => item?.locationId !== null,
      );
      if (
        recentLocationsData.length > 0 &&
        recentLocationsData[0].locationId !== null
      ) {
        const selectedLocation: RecentLocationType = recentLocationsData[0];
        return {
          recentLocations: recentLocationsData,
          selectedLocation: selectedLocation,
        };
      } else {
        return {recentLocations: [], selectedCampusLocation: null};
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'campusLocationSlice:fetchRecentCampusesAndLocations',
        generateErrorMessage(error),
        recentLocationsAndCampusesData,
      );
      Events.Error.trackEvent(
        'Exception',
        'campusLocationSlice:fetchRecentCampusesAndLocations',
        generateErrorMessage(error),
      );
      throw error;
    }
  },
);

export const fetchNearbyCampusesAndLocations = createAsyncThunk(
  'campusLocation/fetchNearbyCampusesAndLocations',
  async (position: {lat: number; lon: number}) => {
    let nearbyLocations;
    try {
      nearbyLocations = await AppApi.fetchNearbyLocationsAndCampuses(
        position.lat,
        position.lon,
      );
      return nearbyLocations;
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'campusLocationSlice:fetchNearbyCampusesAndLocations',
        generateErrorMessage(error),
        nearbyLocations,
      );
      Events.Error.trackEvent(
        'Exception',
        'campusLocationSlice:fetchNearbyCampusesAndLocations',
        generateErrorMessage(error),
      );
      throw error;
    }
  },
);

export const fetchCampusLocations = createAsyncThunk(
  'campusLocation/fetchCampusLocations',
  async (locationId: string) => {
    try {
      let diningLocation = [];
      const location = await PlatformApi.fetchLocation(locationId);
      if (
        location?.onlineOrderConfig?.hasDineIn ||
        location?.onlineOrderConfig?.hasToGo
      ) {
        diningLocation = [
          {
            localType: 'market',
            ...location,
            datecreated: location.dateCreated,
            id: location.locationId,
            deviceId: location.locationId,
            org: location.orgId,
            locationType: 'sos',
            orderAhead: true,
          },
        ];
      }
      return diningLocation;
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'campusLocationSlice:fetchCampusLocations',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'campusLocationSlice:fetchCampusLocations',
        generateErrorMessage(error),
      );
      throw error;
    }
  },
);

export const swicthCurrentCampusesAndLocations = createAsyncThunk<
  void,
  {accountId: string; locationId: string},
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  'campusLocation/swicthCurrentCampusesAndLocations',
  async (data: {accountId: string; locationId: string}, {dispatch}) => {
    try {
      await AppApi.switchCurrentLocationOrCampus(
        data.accountId,
        data.locationId,
      );
      dispatch(fetchRecentCampusesAndLocations(data.accountId));
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'campusLocationSlice:swicthCurrentCampusesAndLocations',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'campusLocationSlice:swicthCurrentCampusesAndLocations',
        generateErrorMessage(error),
      );
      throw error;
    }
  },
);

export const fetchAnnouncements = createAsyncThunk(
  'campusLocation/fetchAnnouncements',
  async (locationId: string) => {
    try {
      const announcementsList: AnnouncementType[] =
        await AppApi.fetchAnnouncements(locationId);
      if (
        announcementsList?.length > 0 &&
        announcementsList[0]?.announcementId
      ) {
        return announcementsList;
      } else {
        return [];
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'campusLocationSlice:fetchAnnouncements',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'campusLocationSlice:fetchAnnouncements',
        generateErrorMessage(error),
      );
      throw error;
    }
  },
);

const campusLocationSlice = createSlice({
  name: 'campusLocation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRecentCampusesAndLocations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchRecentCampusesAndLocations.rejected, (state) => {
      state.recentCampusesAndLocations = [];
      state.selectedLocation = null;
      state.loading = false;
    });
    builder.addCase(
      fetchRecentCampusesAndLocations.fulfilled,
      (state, action) => {
        state.recentCampusesAndLocations = action.payload.recentLocations.slice(
          0,
          5,
        );
        state.selectedLocation = action.payload.selectedLocation;
        state.loading = false;
      },
    );
    builder.addCase(fetchNearbyCampusesAndLocations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNearbyCampusesAndLocations.rejected, (state) => {
      state.nearbyCampusesAndLocations = [];
      state.loading = false;
    });
    builder.addCase(
      fetchNearbyCampusesAndLocations.fulfilled,
      (state, action) => {
        if (action.payload?.items) {
          state.nearbyCampusesAndLocations = action.payload.items;
        }
        state.loading = false;
      },
    );
    builder.addCase(swicthCurrentCampusesAndLocations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(swicthCurrentCampusesAndLocations.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(swicthCurrentCampusesAndLocations.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchCampusLocations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCampusLocations.rejected, (state) => {
      state.diningLocations = [];
      state.loading = false;
    });
    builder.addCase(fetchCampusLocations.fulfilled, (state, action) => {
      state.diningLocations = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchAnnouncements.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAnnouncements.rejected, (state) => {
      state.announcements = [];
      state.loading = false;
    });
    builder.addCase(fetchAnnouncements.fulfilled, (state, action) => {
      state.announcements = action.payload;
      state.loading = false;
    });
  },
});
export const {} = campusLocationSlice.actions;
export default campusLocationSlice.reducer;
