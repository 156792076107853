import * as React from 'react';
import {View, StyleSheet, Text, TouchableOpacity} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import moment from 'moment';
import 'moment-timezone';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import BackSubheader from '../../elements/BackSubheader';
import CheckoutBar from '../../elements/orderAhead/CheckoutBar';
import Styles from '../../Styles';
import type {LocationType} from 'src/types/Location';
import type {MenuType, TimeSlotType} from 'src/types/Menu';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import TransactionActions from 'src/actions/TransactionActions';
import TransactionStore from 'src/stores/TransactionStore';
import AccountConstants from 'src/constants/AccountConstants';
import MenuService from 'src/services/MenuService';
import PickupTimeTimer from '../../elements/orderAhead/PickupTimeTimer';
import CartService from 'src/services/CartService';
import CartTypes from 'src/constants/cart/CartTypes';
import {confirm, alertError} from '../../helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import TimeUtils from 'src/services/TimeUtils';
import MainConsumerContext from 'src/components/MainConsumerContext';
import MenuActions from 'src/actions/MenuActions';
import {FlashList} from '@shopify/flash-list';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import uuid from 'src/nativeModules/UUID';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type PickupTimeScreenProps = {
  navigation: NavigationProp<PickupTimeScreen>;
  availableTimes: Array<TimeSlotType>;
  location: LocationType;
  fromCart?: boolean;
};
type PickupTimeScreenState = {
  availableTimes: Array<TimeSlotType>;
  pickupTime?: TimeSlotType;
  previousRoute: string | null;
};

class PickupTimeScreen extends React.Component<
  PickupTimeScreenProps,
  PickupTimeScreenState
> {
  static defaultProps = {
    fromCart: false,
  };
  willFocusSubscription: any;
  sessionStartTime: moment.Moment;
  static contextType = MainConsumerContext;
  declare context: React.ContextType<typeof MainConsumerContext>;

  constructor(props: PickupTimeScreenProps) {
    super(props);

    this.state = {
      availableTimes: props?.availableTimes,
      pickupTime: TransactionStore.getPickupTime(),
      previousRoute: null,
    };

    if (!props?.fromCart) {
      this.sessionStartTime = moment();
    }

    this.renderItem = this.renderItem.bind(this);
    this.timeSelected = this.timeSelected.bind(this);
    this.onCartPressed = this.onCartPressed.bind(this);
    this.cartIsInvalid = this.cartIsInvalid.bind(this);
    this.onBackSelect = this.onBackSelect.bind(this);
    this.onTransactionStoreChanged = this.onTransactionStoreChanged.bind(this);
    this.updateTime = this.updateTime.bind(this);
    TransactionStore.addChangeListener(this.onTransactionStoreChanged);
  }

  componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'PickupTimeScreen', {
      ...this.props,
      ...this.state,
    });

    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});

    this.willFocusSubscription = this.props.navigation.addListener(
      'focus',
      () => {
        this.setState({
          availableTimes: MenuService.getAvailableTimeSlotsFromList(
            TransactionStore.getAvailableTimesFromServer(),
            this.props.location.onlineOrderConfig.kitchenSchedule,
          ),
        });
      },
    );
  }

  componentWillUnmount() {
    this.willFocusSubscription;
    TransactionStore.removeChangeListener(this.onTransactionStoreChanged);
  }

  onCartPressed() {
    if (this.props.fromCart) {
      NavActions.pop();
    } else {
      FirebaseAnalytic.trackEvent('onCartPressed', 'PickupTimeScreen', {
        ...this.props,
        ...this.state,
        marketName: this.props.location.name,
        locationId: this.props.location.locationId,
        locationType: AccountConstants.SOS_LOCATION_TYPE,
        beaconId:
          this.props.location.beaconId ?? this.props.location.locationId,
        cartType: CartTypes.OrderAhead,
        location: this.props.location,
        pickUpLocation: TransactionStore.getPickupLocationName(),
        pickUpTime: TransactionStore.getPickupTime(),
        showPreparationMethod:
          this.props.location.onlineOrderConfig.hasDiningPreference,
        defaultPreparationMethod:
          this.props.location.onlineOrderConfig.diningPreference,
      });

      NavActions.push(AppRoutes.Scan, {
        marketName: this.props.location.name,
        locationId: this.props.location.locationId,
        locationType: AccountConstants.SOS_LOCATION_TYPE,
        beaconId:
          this.props.location.beaconId ?? this.props.location.locationId,
        cartType: CartTypes.OrderAhead,
        location: this.props.location,
        showPreparationMethod:
          this.props.location.onlineOrderConfig.hasDiningPreference,
        defaultPreparationMethod:
          this.props.location.onlineOrderConfig.diningPreference,
      });
    }
  }

  onTransactionStoreChanged() {}

  onBackSelect() {
    const {pickupLocations} = this.props.location;

    if (
      !this.props.fromCart &&
      pickupLocations &&
      pickupLocations.length === 1
    ) {
      const displayItems = TransactionStore.getDisplayItems();
      FirebaseAnalytic.trackEvent('onBackSelect', 'PickupTimeScreen', {
        ...this.props,
        ...this.state,
        displayItems,
      });

      if (displayItems.length > 0) {
        confirm(
          Localized.Labels.cancel_order_confirm,
          () => {
            CartService.clearCart(this.sessionStartTime);
            NavActions.pop();
          },
          () => ({}),
          Localized.Labels.cancel_order,
          Localized.Labels.no,
          Localized.Labels.yes,
        );
      } else {
        CartService.clearCart(this.sessionStartTime);
        NavActions.pop();
      }
    } else {
      NavActions.pop();
    }
  }

  async timeSelected(time: TimeSlotType) {
    const timeZoneOffsetMinutes = MenuService.getTimezoneOffsetMinutes();
    const leadTime = this.props.location.onlineOrderConfig.kitchenSchedule;
    const now = moment()
      .add(leadTime, 'minutes')
      .add(timeZoneOffsetMinutes, 'minutes');
    if (!moment(time.date).isAfter(now)) {
      try {
        this.context.actions.showSpinner();
        await MenuActions.getAvailableTimeSlots(
          this.props.location,
          TransactionStore.getPickupLocationId(),
        );
        alertError(
          Localized.Errors.pickup_time_not_available,
          undefined,
          () => {
            this.setState({
              availableTimes: MenuService.getAvailableTimeSlotsFromList(
                TransactionStore.getAvailableTimesFromServer(),
                this.props.location.onlineOrderConfig.kitchenSchedule,
              ),
            });
          },
        );
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'PickupTimeScreen:PickupTimeSelected',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'PickupTimeScreen:PickupTimeSelected',
          generateErrorMessage(error),
          guid,
        );
      } finally {
        this.context.actions.hideSpinner();
      }
    } else {
      this.context.actions.showSpinner();
      const availableTime = await MenuActions.getAvailableTimeSlots(
        this.props.location,
        TransactionStore.getPickupLocationId(),
      );
      const isTimeSlotPresent = availableTime.some(
        (obj) =>
          obj.timeString === time.timeString &&
          moment(time.date).isSame(moment(obj.date), 'day'),
      );

      const pickupLocationId = TransactionStore.getPickupLocationId();

      if (moment(time.date).isAfter(now) && isTimeSlotPresent) {
        const newMenu = await MenuActions.getMenuByTimeSlot(
          this.props.location.locationId,
          pickupLocationId,
          time,
        );
        const newMenuHasItems = MenuService.menuHasProducts(
          newMenu,
          TransactionStore.getScanCodes(),
        );

        FirebaseAnalytic.trackEvent('timeSelected', 'PickupTimeScreen', {
          ...this.props,
          ...this.state,
          newMenu,
          newMenuHasItems,
          pickUpTime: TransactionStore.getPickupTime(),
        });

        this.context.actions.hideSpinner();
        if (newMenuHasItems) {
          this.updateTime(time, newMenu);
        } else {
          const selectedPickupLocation =
            this.props.location.pickupLocations.find(
              (location) => location.pickupLocationId === pickupLocationId,
            );

          const updatePickupTime = () => {
            if (selectedPickupLocation) {
              TransactionActions.pickupLocationUpdated(selectedPickupLocation);
              this.updateTime(time, newMenu);
            } else {
              NavActions.pop();
            }
          };

          FirebaseAnalytic.trackEvent('timeSelected', 'PickupTimeScreen', {
            newMenu,
            newMenuHasItems,
            pickUpTime: TransactionStore.getPickupTime(),
            msg: Localized.Labels.pickup_time_selected_serving_different_menu,
          });

          confirm(
            Localized.Labels.pickup_time_selected_serving_different_menu,
            () => {
              CartService.clearCart(this.sessionStartTime);
              setTimeout(updatePickupTime, 200);
            },
            () => ({}),
            Localized.Labels.menu_change,
            Localized.Buttons.cancel,
            Localized.Buttons.continue,
          );
        }
      } else {
        this.context.actions.hideSpinner();
        alertError(
          Localized.Errors.order_limit_reached_for_pickup_time,
          undefined,
          () => {
            this.setState({
              availableTimes: MenuService.getAvailableTimeSlotsFromList(
                TransactionStore.getAvailableTimesFromServer(),
                this.props.location.onlineOrderConfig.kitchenSchedule,
              ),
            });
          },
        );
      }
    }
  }

  updateTime(time: TimeSlotType, menu?: MenuType) {
    TransactionActions.pickupTimeUpdated(time);
    FirebaseAnalytic.trackEvent('updateTime', 'PickupTimeScreen', {
      ...this.props,
      ...this.state,
      time,
      menu,
    });

    if (this.props.fromCart) {
      NavActions.pop();
    } else {
      MenuService.setCurrentMenu(menu);
      NavActions.push(AppRoutes.Menu, {
        location: this.props.location,
      });
    }
  }

  cartIsInvalid() {
    FirebaseAnalytic.trackEvent('cartIsInvalid', 'PickupTimeScreen', {
      ...this.props,
      ...this.state,
    });

    CartService.clearCart(this.sessionStartTime);
    alertError(
      Localized.Errors.cart_expired_message,
      null,
      () => ({}),
      Localized.Errors.cart_expired,
    );
    NavActions.popToTop();
  }

  renderItem({item}) {
    const pickupDay = TimeUtils.isDateGreaterThanToday(item.date)
      ? Localized.Labels.tomorrow
      : Localized.Labels.today;

    const accessibleString = `${pickupDay} ${item.dateString}, ${item.time} ${item.timezone}, right arrow icon`;
    return (
      <TouchableOpacity
        testID="select-time"
        style={styles.cell}
        accessible={true}
        accessibilityLabel={accessibleString}
        accessibilityHint="Double tap to select this time"
        accessibilityRole="button"
        aria-label={accessibleString}
        role="button"
        onPress={() => this.timeSelected(item)}
      >
        <View style={styles.mainContent}>
          <View style={styles.row}>
            <Text maxFontSizeMultiplier={3} style={styles.time}>
              {item.time}
            </Text>
            <Text
              maxFontSizeMultiplier={3}
              style={styles.time}
            >{` ${item.timezone}`}</Text>
          </View>
          <Text maxFontSizeMultiplier={3.5} style={styles.day}>
            {pickupDay + ', ' + item.dateString}
          </Text>
        </View>
        <FontAwesome5Pro
          name="chevron-right"
          color={Styles.lightGray}
          size={Styles.Fonts.f2}
          light
        />
      </TouchableOpacity>
    );
  }

  render() {
    let pickupTimer = null;

    if (!this.props.fromCart && this.state.pickupTime) {
      pickupTimer = (
        <PickupTimeTimer
          interval={this.props.location?.onlineOrderConfig.orderSchedule}
          onCartIsInvalid={this.cartIsInvalid}
          leadTime={this.props.location?.onlineOrderConfig.kitchenSchedule}
        />
      );
    }
    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={Localized.Labels.pickup_time}
        onBackSelect={this.onBackSelect}
        pop={false}
      >
        {pickupTimer}
        <View style={styles.content}>
          <FlashList
            estimatedItemSize={50}
            data={this.state.availableTimes}
            renderItem={this.renderItem}
          />
          <CheckoutBar
            location={this.props.location}
            strings={Localized}
            onPress={this.onCartPressed}
          />
        </View>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    backgroundColor: Styles.white,
  },
  time: {
    fontWeight: 'bold',
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
  },
  day: {
    fontSize: Styles.Fonts.f0,
    color: Styles.darkColor,
  },
  cell: {
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: 0.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainContent: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
});
export default withForwardedNavigationParams<PickupTimeScreenProps>()(
  PickupTimeScreen,
);
