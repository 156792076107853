import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function InfoIconImage({outerColor = '#022649', innerColor = '#21AD5A'}) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none">
      <Path
        d="M0 8a8 8 0 018-8h16a8 8 0 018 8v16a8 8 0 01-8 8H8a8 8 0 01-8-8V8z"
        fill={outerColor}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.466 15.9A7.47 7.47 0 0015.9 8.534c-4.123.053-7.42 3.443-7.366 7.566a7.467 7.467 0 0014.932-.2zM16 11.197a1.065 1.065 0 110 2.133 1.065 1.065 0 110-2.133zm1.467 9.436h-2.933v-.367h.733v-5.333h-.733v-.4h2.2v5.733h.733v.367z"
        fill={innerColor}
      />
    </Svg>
  );
}

export default InfoIconImage;
