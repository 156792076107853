import {type PropsWithChildren, useState} from 'react';
import {
  FlatList,
  Image,
  type ImageProps,
  Platform,
  StyleSheet,
  View,
  TouchableWithoutFeedback,
} from 'react-native';
import type {NavigationProp} from '@react-navigation/native';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import {ensureHttps} from 'src/services/StringUtils';
import {LockLightIcon, RewardsIcon} from 'src/components/img/svg/rewards';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import NetInfo from '@react-native-community/netinfo';
import AccountStore from 'src/stores/AccountStore';
import AVText from 'src/components/elements/AVText';
import {getDescriber} from 'src/components/screens/descriptor/DescriptorType';

import {getDescriber as getSnackDescriber} from 'src/components/screens/sendSnack/descriptor/sendasnack/DescriptorType';
interface RedeemPoints4RewardsProps {
  navigation: NavigationProp<Record<string, never>>;
}
const lineHeightMultiplier = 1.14;
const RedeemPoints4Rewards = (_props: RedeemPoints4RewardsProps) => {
  const {rewardTier} = AccountStore.getRewardStatus() || {};
  const {currentYearPoints = 0} = rewardTier || {};
  const {rewardProducts} = AccountStore.getRewardProducts() || {};

  const handleViewClick = async (item, point) => {
    const networkState = await NetInfo.fetch();
    if (networkState?.isConnected) {
      NavActions.navigate(AppRoutes.RedeemRewardPanel, {
        productName: item.name,
        imageUrl: item.imageUrl,
        upc: item.upc,
        point,
      });
    } else {
      NavActions.navigate(AppRoutes.ServerErrorDialog, {
        errorTitle: Localized.Labels.redeem_rewd,
        errorDesc: Localized.Errors.error_rewards_modal,
        errorBtnName: getDescriber().getGoToSettings(),
      });
      return;
    }
  };
  return (
    <View style={styles.container}>
      <View style={styles.titleHead}>
        <AVText
          style={[
            styles.redeemPoints,
            getSnackDescriber()?.snackDetailsBtnFontSTyle(),
          ]}
          accessibilityLabel={Localized.Labels.redeem_points_for_rewards}
        >
          {Localized.Labels.redeem_points_for_rewards}
        </AVText>

        <AVText
          style={[
            styles.redeemAvailablity,
            getSnackDescriber()?.snackDetailsBtnFontSTyle(),
          ]}
          accessibilityLabel={Localized.Labels.redeem_rewards_availability}
        >
          {Localized.Labels.redeem_rewards_availability}
        </AVText>
      </View>

      {rewardProducts?.map((tier) => (
        <View key={tier.tierLevel} style={styles.tierContainer}>
          <View style={styles.productTierPoints}>
            {tier.isLocked && <LockLightIcon />}

            <AVText
              style={[
                styles.pointsReq,
                getSnackDescriber()?.snackDetailsBtnFontSTyle(),
              ]}
            >
              {Localized.Labels.formatString(
                Localized.Labels.points_number,
                tier.pointsRequired,
              )}
            </AVText>
          </View>

          <FlatList
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={styles.productListContainer}
            data={tier.products}
            renderItem={({item}) => (
              <TouchableWithoutFeedback
                onPress={() => {
                  !tier.isLocked && handleViewClick(item, tier.pointsRequired);
                }}
              >
                <View
                  style={[
                    styles.productItemParent,
                    {
                      width: 164,
                      height: 159,
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 12,
                      paddingHorizontal: Styles.Spacing.m3 - 4,
                      paddingBottom: Styles.Spacing.m4,
                      borderRadius: Styles.Spacing.m2 + 2,
                      borderColor: Styles.Colors.PayNew.neutralHuesBase11,
                      backgroundColor: Styles.Colors.PayNew.white01,
                      borderWidth: 1,
                    },
                  ]}
                >
                  <View
                    accessibilityRole="button"
                    accessibilityLabel={item.name}
                    style={[
                      styles.productItem,
                      tier.isLocked && styles.disabled,
                    ]}
                  >
                    <ImageWithPlaceholder
                      source={{uri: ensureHttps(item?.imageUrl || '')}}
                      width={67}
                      height={67}
                    >
                      <RewardsIcon size={64} />
                    </ImageWithPlaceholder>

                    <AVText
                      numberOfLines={3}
                      style={[
                        styles.productName,
                        getSnackDescriber()?.snackDetailsBtnFontSTyle(),
                        {
                          textAlign: 'center', // Ensure the text is centered
                        },
                      ]}
                    >
                      {item?.name}
                    </AVText>
                  </View>
                </View>
              </TouchableWithoutFeedback>
            )}
          />
        </View>
      ))}
    </View>
  );
};

export const ImageWithPlaceholder = ({
  source,
  width,
  height,
  children,
}: PropsWithChildren<
  Pick<ImageProps, 'source'> & {height?: number; width?: number}
>) => {
  const [display, setDisplay] = useState<null | 'none' | 'flex'>(null);

  return (
    <View
      style={{
        width,
        height,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {(!display || display === 'none') && children}

      <Image
        source={source}
        onLoad={(evt) => {
          // FIXME: android issue - onError is never called
          //  instead onLoad is fired with stub base64 image with 1px height
          if (Platform.OS === 'web' || evt.nativeEvent.source?.height > 1) {
            setDisplay('flex');
          } else setDisplay('none');
        }}
        onError={() => setDisplay('none')}
        style={{display, width, height, position: 'absolute', borderRadius: 8}}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: Styles.Spacing.m3,
    gap: Styles.Spacing.m3,
  },
  titleHead: {
    paddingHorizontal: Styles.Spacing.m3,
    gap: Styles.Spacing.m2,
  },
  tierContainer: {
    gap: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m1,
  },
  productItem: {
    width: 164,
    height: 159,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 12,
    paddingHorizontal: Styles.Spacing.m3 - 4,
    paddingTop: Styles.Spacing.m3,
    paddingBottom: Styles.Spacing.m4,
    borderRadius: Styles.Spacing.m2,
    borderColor: Styles.Colors.PayNew.neutralHuesBase11,
    backgroundColor: Styles.Colors.PayNew.white01,
    borderWidth: 1,
  },
  productItemParent: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingBottom: 16,
    borderRadius: 14,
    borderWidth: 1,
    borderColor: '#E7E7E7',
    backgroundColor: '#FFF',
    // Box shadow for iOS
    shadowColor: 'black',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.07,
    shadowRadius: 4,
    // Elevation for Android
    elevation: 3,
  },
  productTierPoints: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    paddingHorizontal: Styles.Spacing.m3,
  },
  productListContainer: {
    gap: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m3,
    paddingBottom: 4, // to avoid shadow clipping
  },
  productName: {
    width: '100%',
    flexGrow: 1,
    textAlign: 'center',
    fontSize: Styles.Fonts.f1 - 2,
    fontWeight: '700',
    lineHeight: (Styles.Fonts.f1 - 2) * lineHeightMultiplier,
  },
  disabled: {
    opacity: 0.6,
  },
  pointsReq: {
    fontSize: Styles.Fonts.f1 + 4,
    fontWeight: '700',
    lineHeight: (Styles.Fonts.f1 + 2) * lineHeightMultiplier,
  },
  redeemAvailablity: {
    fontSize: Styles.Fonts.f1 - 2,
    fontStyle: 'italic',
    lineHeight: (Styles.Fonts.f1 - 2) * lineHeightMultiplier,
  },
  redeemPoints: {
    fontSize: Styles.Fonts.f1 + 2,
    fontWeight: '700',
    lineHeight: (Styles.Fonts.f1 + 2) * lineHeightMultiplier,
  },
});

export default RedeemPoints4Rewards;
