import * as React from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Platform,
  Text,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native';
import Styles from '../../Styles';
import AccountStore from 'src/stores/AccountStore';
import AVText from '../../elements/AVText';
import AVFormattedCurrency from '../../elements/AVFormattedCurrency';
import TrashIcon from '../../img/svg/TrashIcon';
import {CartItem} from '../../../types/TransactionDetail';
import TagLightIcon from 'src/components/img/svg/TagLightIcon';
import Localized from 'src/constants/AppStrings';
import SupLeafIcon from 'src/components/img/svg/SupLeafIcon';
import {scanAndPayTemplate} from './templates/scanandPayThemeHelper';

type CartProductItemProps = {
  trashClick?: (lineNumbers: Array<number>) => void;
  item: CartItem;
  editClicked?: (item: CartItem) => void;
  canEdit: boolean;
  canDelete: boolean;
};

const CartProductItem: React.FC<CartProductItemProps> = ({
  trashClick,
  item,
  editClicked,
  canEdit,
  canDelete,
}) => {
  const handleItemClick = () => {
    if (editClicked) editClicked(item);
  };
  const handleTrashClick = () => {
    if (trashClick) trashClick([item.LineNumber]);
  };

  const getModifierName = (
    SeatNbr: number,
    modifierValue: string,
    type: number,
  ) => {
    const isMessage = isFreeText(SeatNbr, type);
    if (isMessage) {
      return Localized.Labels.message + ': ' + modifierValue;
    } else {
      return modifierValue;
    }
  };

  /**
   * Put Free text modifier to end of the list always
   */
  const moveFreeTextItemToEndOfList = (modifiersList) => {
    let foundUserMessage = false;
    let foundMesssageAtIndex = 0;
    modifiersList.forEach((fruit, index) => {
      const isMessage = isFreeText(
        modifiersList[index].SeqNum,
        modifiersList[index].Type,
      );
      if (isMessage) {
        foundUserMessage = true;
        foundMesssageAtIndex = index;
        return true;
      }
    });

    if (foundUserMessage) {
      const modifierWithMessage = modifiersList.splice(foundMesssageAtIndex, 1); // Remove item and store it
      modifiersList.push(...modifierWithMessage);
    }

    return modifiersList;
  };

  const isFreeText = (SeatNbr: number, type: number) =>
    SeatNbr === 0 && type === 4;

  const modifiersName = () => {
    let modifiers: JSX.Element[] | null = null;

    if (item.Modifiers && item.Modifiers.length > 0) {
      item.Modifiers.sort((a, b) => b.Price - a.Price);
      const finalModifiers = moveFreeTextItemToEndOfList(item.Modifiers);
      modifiers = finalModifiers.map((modifier, index) => {
        const price = parseFloat(`${modifier.Price}`);
        return (
          <View
            style={[
              styles.rowContainer,
              {
                flex: 1,
                alignItems: 'center',
              },
            ]}
          >
            <Text
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              accessible={true}
              accessibilityLabel={`${modifier.Name}`}
              accessibilityRole="text"
              aria-label={`${modifier.Name}, text`}
              style={[
                Styles.Style.description,
                styles.modifierText,
                getModifierMarginStyle(index),
                getModifierFontSize(modifier.SeqNum, modifier.Type),
              ]}
            >
              {getModifierName(modifier.SeqNum, modifier.Name, modifier.Type)}
            </Text>
            {modifier.Price && !isFreeText(modifier.SeqNum, modifier.Type) && (
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                accessible={true}
                accessibilityRole="text"
                accessibilityLabel={`$${price.toFixed(2)}`}
                aria-label={`$${price.toFixed(2)}`}
                style={[styles.priceText]}
              >
                +
                <AVFormattedCurrency
                  style={[
                    Styles.Style.description,
                    styles.modifierText,
                    {fontSize: Styles.Fonts.f7},
                  ]}
                  value={price}
                  currency={AccountStore.getCurrency()}
                />
              </AVText>
            )}
          </View>
        );
      });
    }
    return modifiers;
  };

  const getCartRowStyle = (): StyleProp<ViewStyle> => {
    if (isModifiersPresent()) {
      return {alignItems: 'flex-start'};
    }
    return {alignItems: 'center'};
  };

  const isModifiersPresent = () => {
    if (item.Modifiers?.length > 0) {
      return true;
    }
    return false;
  };

  const getModifierMarginStyle = (index): StyleProp<ViewStyle> => {
    if (index == 0) {
      return {marginTop: 0};
    }
    return {marginTop: Styles.Spacing.m1};
  };

  const getModifierFontSize = (SeatNbr, type): StyleProp<TextStyle> => {
    const isMessage = isFreeText(SeatNbr, type);
    if (isMessage) {
      return {fontSize: Styles.Fonts.f1, lineHeight: 26};
    } else {
      return {fontSize: Styles.Fonts.f7, lineHeight: 18};
    }
  };

  const renderSupFee = () => {
    if (item?.TransactionChargeName && item?.TransactionChargeAmount) {
      return (
        <View style={[styles.cartItemMainKey]}>
          <View style={styles.cartDescriptionSubView}>
            <View style={[styles.cartTagIcon, {marginTop: 4}]}>
              <SupLeafIcon />
            </View>
            <View style={styles.cartPromoTextView}>
              <AVText
                accessibilityLabel={Localized.Labels.sup_fee}
                aria-label={Localized.Labels.sup_fee}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                style={[styles.description, styles.supDescription]}
                numberOfLines={1}
              >
                {Localized.Labels.sup_fee}
              </AVText>
            </View>
          </View>

          <AVFormattedCurrency
            style={styles.cartPricePromo}
            value={item.TransactionChargeAmount}
            accessibilityLabel={`SUP amount $${item.TransactionChargeAmount}`}
            aria-label={`SUP amount $${item.TransactionChargeAmount}, text`}
            currency={AccountStore.getCurrency()}
            numberOfLines={1}
          />
        </View>
      );
    }
    return null;
  };

  const DiscountSection =
    item?.Discount &&
    parseFloat(item?.Discount) > 0 &&
    item?.DiscountDisplayName;

  const row = (
    <TouchableOpacity
      accessible={false}
      accessibilityLabel="Edit product"
      style={styles.container}
      onPress={handleItemClick}
      disabled={!canEdit}
    >
      <View style={[styles.mainSection, getCartRowStyle()]}>
        <View style={styles.cartDeleteIconView}>
          {Platform.OS !== 'web' && (
            <TouchableOpacity
              accessible={true}
              accessibilityLabel="Delete product"
              accessibilityHint={`Double tap to delete ${item.Description} from your cart`}
              style={styles.deleteButton}
              onPress={handleTrashClick}
            >
              <TrashIcon size={44} color={scanAndPayTemplate.deleteIconColor} />
            </TouchableOpacity>
          )}
        </View>

        <View style={styles.cartDescriptionView}>
          <View style={[styles.cartItemMainKey, getCartRowStyle()]}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              style={[styles.description, {flex: 0.8}]}
            >
              {item?.Description}
            </AVText>
            <View style={styles.cartPriceMain}>
              <AVFormattedCurrency
                style={[
                  styles.cartPrice,
                  {
                    textDecorationLine: DiscountSection
                      ? 'line-through'
                      : 'none',
                  },
                ]}
                value={parseFloat(item.Price ?? '0')}
                accessibilityLabel="itemPrice"
                currency={AccountStore.getCurrency()}
                numberOfLines={1}
              />
            </View>
          </View>
          {DiscountSection && (
            <View style={[styles.cartItemMainKey]}>
              <View style={styles.cartDescriptionSubView}>
                <View style={styles.cartTagIcon}>
                  <TagLightIcon color={scanAndPayTemplate.discountTagColor} />
                </View>
                <View style={styles.cartPromoTextView}>
                  <AVText
                    numberOfLines={6}
                    style={[styles.cartPromoText, styles.cartPromoTextMargin]}
                  >
                    {item?.Discount &&
                      parseFloat(item?.Discount) > 0 &&
                      item?.DiscountDisplayName.substring(0, 30) + ' '}
                    (-
                    {
                      <AVFormattedCurrency
                        style={styles.cartPromoText}
                        value={parseFloat(item.Discount)}
                        accessibilityLabel="itemPrice"
                        currency={AccountStore.getCurrency()}
                        numberOfLines={1}
                      />
                    }
                    )
                  </AVText>
                </View>
              </View>

              <AVFormattedCurrency
                style={styles.cartPricePromo}
                value={parseFloat(item.Price) - parseFloat(item.Discount)}
                accessibilityLabel="afterDiscountItemPrice"
                currency={AccountStore.getCurrency()}
                numberOfLines={1}
              />
            </View>
          )}
          {renderSupFee()}
          {isModifiersPresent() && (
            <View
              style={[
                styles.cartItemMainKey,
                {
                  flexDirection: 'column',
                },
              ]}
            >
              {modifiersName()}
            </View>
          )}

          {item.SoldOut && (
            <AVText
              accessible={true}
              accessibilityLabel="Sold out"
              style={styles.status}
            >
              {Localized.Labels.sold_out}
            </AVText>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );

  return canDelete ? <View>{row}</View> : <View>{row}</View>;
};

const styles = StyleSheet.create({
  description: {
    marginTop: Styles.Spacing.m1,
    marginHorizontal: Styles.Spacing.m2,
    justifyContent: 'center',
    fontFamily: scanAndPayTemplate.textFontFamily,
    fontSize: Styles.Fonts.f7,
    lineHeight: 18,
  },
  amount: {
    fontFamily: scanAndPayTemplate.textFontFamily,
    fontSize: Styles.Fonts.f7,
    textAlign: 'right',
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Styles.Spacing.m3,
  },
  status: {
    marginHorizontal: Styles.Spacing.m2,
    color: Styles.dangerColor,
    textTransform: 'uppercase',
  },
  mainSection: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  deleteButton: {
    justifyContent: 'center',
    minHeight: Styles.Heights.touchTargetHeight1,
    minWidth: Styles.Heights.touchTargetHeight1,
  },
  cartDeleteIconView: {
    alignSelf: 'flex-start',
  },
  cartItemMainKey: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
  cartDescriptionView: {
    flex: 1,
    rowGap: 4,
  },
  cartDescriptionSubView: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: Styles.Spacing.m1,
    marginLeft: Styles.Spacing.m2,
  },
  cartTagIcon: {
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  cartPromoTextView: {
    flex: 0.9,
    flexDirection: 'row',
    left: 5,
  },
  cartPromoText: {
    color: scanAndPayTemplate.discountTagColor,
    fontWeight: '400',
    fontSize: Styles.Fonts.f7,
    fontFamily: scanAndPayTemplate.textFontFamily,
  },
  cartPromoTextMargin: {
    marginRight: 5,
  },
  cartPriceMain: {
    flex: 0.4,
    justifyContent: 'center',
    top: -2,
    alignItems: 'flex-end',
    rowGap: 5,
  },
  cartPrice: {
    fontWeight: '400',
    fontSize: Styles.Fonts.sectionHeader,
    fontFamily: scanAndPayTemplate.textFontFamily,
  },
  cartPricePromo: {
    color: Styles.Colors.PayNew.black01,
    fontWeight: '400',
    fontSize: Styles.Fonts.sectionHeader,
    fontFamily: scanAndPayTemplate.textFontFamily,
  },
  priceText: {
    marginTop: Styles.Spacing.m1,
    fontFamily: scanAndPayTemplate.textFontFamily,
    fontWeight: '300',
    flex: 0.4,
    textAlign: 'right',
    color: Styles.Colors.PayNew.darkHuesBase04,
  },
  rowContainer: {
    flexDirection: 'row',
    color: Styles.lightGray,
  },
  modifierText: {
    flex: 0.7,
    color: Styles.Colors.PayNew.darkHuesBase04,
    marginLeft: Styles.Spacing.m2,
    fontFamily: scanAndPayTemplate.textFontFamily,
    textAlignVertical: 'center',
    justifyContent: 'center',
  },
  supDescription: {
    flex: 0.8,
    marginHorizontal: 2,
  },
});

export default CartProductItem;
