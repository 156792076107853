import React from 'react';
import BackSubheader from '../elements/BackSubheader';
import Header from '../elements/Header';
import {StyleProp, ViewStyle} from 'react-native';

type BaseScreenProps = {
  children: React.ReactNode;
  title: string;
  style?: StyleProp<ViewStyle>;
  rightView?: React.ReactNode;
  bottomView?: React.ReactNode;
  hideBack?: boolean;
  previousRoute?: string | null;
  accessibilityLabel?: string;
  accessibilityHint?: string;
  contentStyle?: StyleProp<ViewStyle>;
};
export default class BaseScreen extends React.Component<BaseScreenProps> {
  static defaultProps = {
    hideBack: false,
  };

  render() {
    let HeaderComponent: any = BackSubheader;

    if (this.props.hideBack) {
      HeaderComponent = Header;
    }

    return (
      <HeaderComponent
        title={this.props.title}
        rightView={this.props.rightView}
        bottomView={this.props.bottomView}
        previousRoute={this.props.previousRoute}
        accessibilityLabel={this.props.accessibilityLabel}
        accessibilityHint={this.props.accessibilityHint}
        style={this.props.style}
        contentStyle={this.props.contentStyle}
      >
        {this.props.children}
      </HeaderComponent>
    );
  }
}
