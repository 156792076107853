import {Platform, StyleSheet, View} from 'react-native';
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import Svg, {Path, Circle, type PathProps} from 'react-native-svg';
import Animated, {
  Easing,
  SharedValue,
  useAnimatedProps,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import {UserRewardsTierIcon} from 'src/components/img/svg/rewards';
import AVText from 'src/components/elements/AVText';

import {getDescriber} from 'src/components/screens/sendSnack/descriptor/sendasnack/DescriptorType';
import _ from 'lodash';
interface PointsIndicatorProps {
  availablePoints: number;
  thresholds: number[];
  maxPointValue: number;
  maxPointValueLabel?: string;
  tierName?: string;
  height?: number;
  rewardedColor?: string;
}
const lineHeightMultiplier = 1.14;
const PointsIndicator = ({
  height = 157,
  availablePoints,
  thresholds,
  maxPointValue,
  tierName,
  maxPointValueLabel = '',
  rewardedColor,
}: PointsIndicatorProps) => {
  // calculate width based on height( keep aspect ratio )
  const width = (height * defaultSize.width) / defaultSize.height;
  let adjustedMaxPoints = availablePoints;
  const progressValue = useSharedValue(0);
  useEffect(() => {
    if (!maxPointValue) return;

    adjustedMaxPoints = getAdjustedMaxPoints();

    if (Platform.OS === 'web')
      progressValue.value = adjustedMaxPoints / maxPointValue;
    else
      progressValue.value = withTiming(adjustedMaxPoints / maxPointValue, {
        duration: 1000,
        easing: Easing.bezier(0.65, 0, 0.35, 1),
      });
  }, [progressValue, adjustedMaxPoints, maxPointValue]);

  const isSizeSmall = width < 120;

  const getAdjustedMaxPoints = () => {
    return _.min([availablePoints, maxPointValue]);
  };

  return (
    <View style={styles.container}>
      <View>
        <Svg
          width={width}
          height={height}
          viewBox={[0, 0, defaultSize.width, defaultSize.height].join(' ')}
          fill="none"
        >
          <Path
            d={arcPathD}
            transform="rotate(-45, 19, 136)"
            strokeWidth={13}
            stroke="#C2C2C5"
            testID="svg-path"
          />

          <AnimatedStrokePath
            progress={progressValue}
            d={arcPathD}
            transform="rotate(-45, 19, 136)"
            strokeWidth={13}
            strokeLinejoin="round"
            stroke={rewardedColor}
          />

          {thresholds?.map((threshold) => (
            <Circle
              key={threshold}
              {...calculatePointPosition(threshold / maxPointValue)}
              r="5.74737"
              fill="white"
              testID={`threshold-circle-${threshold}`} // Add testID for thresholds
            />
          ))}
        </Svg>

        <View
          style={[
            styles.availablePoints,
            isSizeSmall && {paddingBottom: Styles.Spacing.m2},
          ]}
        >
          <AVText
            style={[
              styles.availablePointsText,
              {
                fontSize: isSizeSmall ? 16 : 18,
                lineHeight: (isSizeSmall ? 16 : 18) * lineHeightMultiplier,
              },
              getDescriber()?.snackDetailsBtnFontSTyle(),
            ]}
            numberOfLines={2}
          >
            {Localized.Labels.formatString(
              Localized.Labels.points_number,
              availablePoints,
            )}
          </AVText>
        </View>

        <View style={[styles.legend, {width}]}>
          <AVText
            style={[
              styles.ptsNumber,
              getDescriber()?.snackDetailsBtnFontSTyle(),
            ]}
          >
            {isSizeSmall
              ? 0
              : Localized.Labels.formatString(
                  Localized.Labels.points_short_number,
                  0,
                )}
          </AVText>

          <AVText
            style={[
              styles.ptsNumber,
              getDescriber()?.snackDetailsBtnFontSTyle(),
            ]}
          >
            {maxPointValueLabel}
          </AVText>
        </View>
      </View>

      {tierName && <UserTier tierName={tierName} />}
    </View>
  );
};

const AnimatedStrokePath = ({
  progress,
  ...rest
}: PathProps & {progress: SharedValue<number>}) => {
  const svgMaskPathRef = useRef(null);
  const [pathLength, setPathLength] = useState(0);

  useLayoutEffect(() => {
    if (Platform.OS === 'web') {
      const length = (
        svgMaskPathRef.current?.elementRef?.current ?? svgMaskPathRef.current
      )?.getTotalLength?.();
      setPathLength(length || 0);
    }
  }, []);

  const strokeAnimation = useAnimatedProps(() => ({
    strokeDashoffset: pathLength - pathLength * progress.value,
    opacity: pathLength ? 1 : 0, // prevent flickering
  }));

  return (
    <AnimatedPath
      animatedProps={strokeAnimation}
      ref={svgMaskPathRef}
      onLayout={() =>
        setPathLength(svgMaskPathRef.current?.getTotalLength() || 0)
      }
      strokeDasharray={pathLength}
      {...rest}
    />
  );
};

const UserTier = ({tierName}: {tierName: string}) => (
  <View style={styles.userTier}>
    <UserRewardsTierIcon tierName={tierName} size={46} />
    <AVText
      style={[styles.tierName, getDescriber()?.snackDetailsBtnFontSTyle()]}
    >
      {tierName}
    </AVText>
  </View>
);

const defaultSize = {height: 157, width: 183};
const arcRadius = 84.3;
const arcPathD = `M17.3466,155.54 a${arcRadius},${arcRadius} 0 1,1 ${arcRadius},${arcRadius}`;
const arcPointsRange = [(Math.PI * 225) / 180, (Math.PI * -45) / 180];

function calculatePointPosition(
  percent: number,
  center = {x: defaultSize.width / 2, y: defaultSize.width / 2},
) {
  const theta =
    arcPointsRange[0] + (arcPointsRange[1] - arcPointsRange[0]) * percent;
  return {
    cx: center.x + arcRadius * Math.cos(theta),
    cy: center.y - arcRadius * Math.sin(theta),
  };
}

const AnimatedPath = Animated.createAnimatedComponent(Path);

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  legend: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: Styles.Spacing.m3 - 4,
    paddingRight: Styles.Spacing.m1 + 1,
  },
  availablePoints: {
    position: 'absolute',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingHorizontal: Styles.Spacing.m3,
  },
  availablePointsText: {
    textAlign: 'center',
    fontWeight: '700',
    color: Styles.Colors.PayNew.black01,
  },
  userTier: {
    marginTop: Styles.Fonts.f1 - 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  ptsNumber: {
    color: Styles.Colors.PayNew.black01,
    fontSize: Styles.Fonts.f1 - 2,
    lineHeight: (Styles.Fonts.f1 - 2) * lineHeightMultiplier,
  },
  tierName: {
    fontSize: Styles.Fonts.f1 + 2,
    lineHeight: (Styles.Fonts.f1 + 2) * lineHeightMultiplier,
  },
});

export default PointsIndicator;
