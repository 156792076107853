import React from 'react';
import {StyleSheet, View} from 'react-native';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import AVText from 'src/components/elements/AVText';
import CloseIcon from 'src/components/img/svg/CloseIcon';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import {getDescriber} from './descriptor/DescriptorType';

const {offerNoteIcon, offerStyles, getTextColor} = getDescriber();

type OffersNoteProps = {
  onPressClose: () => void;
};

const OffersNote = (props: OffersNoteProps) => {
  return (
    <View
      style={offerStyles().offerNoteContainer}
      accessibilityLabel="Note"
      accessible
    >
      <View accessible={true} style={styles.container}>
        <View style={styles.leftView}>
          <View
            aria-label="Lightbulb Icon"
            accessibilityLabel="Lightbulb Icon"
            accessible
          >
            {offerNoteIcon()}
          </View>
        </View>
        <View style={styles.rightView}>
          <View style={{flexDirection: 'row', flex: 1, flexWrap: 'wrap'}}>
            <AVText
              numberOfLines={2}
              style={offerStyles().noteTextStyle}
              accessibilityLabel={Localized.Labels.offers_note}
              accessible
              aria-label={`${Localized.Labels.offers_note} text`}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              accessibilityRole="text"
            >
              <AVText
                style={offerStyles().noteStyle}
                accessibilityLabel={Localized.Labels.note}
                accessible
                aria-label={`${Localized.Labels.note}, text`}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                accessibilityRole="text"
              >
                {`${Localized.Labels.note} `}
              </AVText>
              {Localized.Labels.offers_note}
            </AVText>
          </View>
          <AVTouchableOpacity
            style={styles.closeIcon}
            accessibilityLabel="Close-Icon"
            accessible
            onPress={props.onPressClose}
            aria-label="X Icon"
          >
            <CloseIcon color={getTextColor()} />
          </AVTouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  closeIcon: {
    position: 'absolute',
    right: 3,
    top: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textView: {
    flexDirection: 'row',
    width: 315,
    top: 3,
    alignItems: 'center',
    left: Styles.Spacing.m2,
    paddingRight: 23,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  leftView: {
    flex: 0.05,
    justifyContent: 'center',
    paddingLeft: 10,
  },
  rightView: {
    flex: 0.95,
    paddingTop: 2,
  },
});

export default OffersNote;
