import Settings from 'src/Settings';
import RedesignDescriptor from './account-elements-redesign';
import RefiveDescriptor from './account-elements-refive';
import CanteenDescriptor from './account-elements-canteen';
import BuildTypes from 'src/constants/BuildTypeConstants';

const typeDescriber = {
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.companykitchen]: CanteenDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
