import * as React from 'react';
import Svg, {Path, ClipPath, G, Defs, Rect} from 'react-native-svg';
type PersonRedesignProps = {
  size: number;
};
export default class PersonRedesign extends React.Component<PersonRedesignProps> {
  render() {
    return (
      <Svg
        width={this.props.size}
        height={this.props.size}
        id="PersonLight"
        x="0px"
        y="0px"
        viewBox="0 0 23 26"
      >
        <G clip-path="url(#clip0_4594_2327)">
          <Path
            d="M16.25 6.5C16.25 5.85981 16.1239 5.22588 15.8789 4.63442C15.6339 4.04296 15.2748 3.50554 14.8221 3.05285C14.3695 2.60017 13.832 2.24108 13.2406 1.99609C12.6491 1.7511 12.0152 1.625 11.375 1.625C10.7348 1.625 10.1009 1.7511 9.50942 1.99609C8.91796 2.24108 8.38054 2.60017 7.92785 3.05285C7.47517 3.50554 7.11608 4.04296 6.87109 4.63442C6.6261 5.22588 6.5 5.85981 6.5 6.5C6.5 7.14019 6.6261 7.77412 6.87109 8.36558C7.11608 8.95704 7.47517 9.49446 7.92785 9.94715C8.38054 10.3998 8.91796 10.7589 9.50942 11.0039C10.1009 11.2489 10.7348 11.375 11.375 11.375C12.0152 11.375 12.6491 11.2489 13.2406 11.0039C13.832 10.7589 14.3695 10.3998 14.8221 9.94715C15.2748 9.49446 15.6339 8.95704 15.8789 8.36558C16.1239 7.77412 16.25 7.14019 16.25 6.5ZM4.875 6.5C4.875 4.77609 5.55982 3.12279 6.77881 1.90381C7.99779 0.68482 9.65109 0 11.375 0C13.0989 0 14.7522 0.68482 15.9712 1.90381C17.1902 3.12279 17.875 4.77609 17.875 6.5C17.875 8.22391 17.1902 9.87721 15.9712 11.0962C14.7522 12.3152 13.0989 13 11.375 13C9.65109 13 7.99779 12.3152 6.77881 11.0962C5.55982 9.87721 4.875 8.22391 4.875 6.5ZM1.625 24.375H21.125C21.0641 20.3277 17.7633 17.0625 13.6957 17.0625H9.0543C4.9918 17.0625 1.69102 20.3277 1.625 24.375ZM0 24.4918C0 19.4898 4.05234 15.4375 9.0543 15.4375H13.6957C18.6977 15.4375 22.75 19.4898 22.75 24.4918C22.75 25.3246 22.0746 26 21.2418 26H1.5082C0.675391 26 0 25.3246 0 24.4918Z"
            fill="#111111"
          />
        </G>
        <Defs>
          <ClipPath id="clip0_4594_2327">
            <Rect width="22.75" height="26" fill="white" />
          </ClipPath>
        </Defs>
      </Svg>
    );
  }
}
