import Settings from 'src/Settings';
import RedesignDescriptor from './header-redesign';
import RefiveDescriptor from './header-refive';
import CanteenDescriptor from './header-canteen';
import CompanyKitchenDescriptor from './header-companykitchen';
import BuildTypes from 'src/constants/BuildTypeConstants';

const typeDescriber = {
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export function getHeaderDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
