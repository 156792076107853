import * as React from 'react';
import Svg, {Path, Rect} from 'react-native-svg';
type LogoProps = {
  size: number;
  style?: Record<string, any>;
}; // tslint:disable

export default class Discover extends React.Component<LogoProps> {
  getWidth() {
    if (this.props.style && this.props.style['size']['width']) {
      return this.props.style['size']['width'];
    } else {
      return 53;
    }
  }

  getHeight() {
    if (this.props.style && this.props.style['size']['height']) {
      return this.props.style['size']['height'];
    } else {
      return 34;
    }
  }

  render() {
    return (
      <>
        <Svg
          width={this.getWidth()}
          height={this.getHeight()}
          viewBox="0 0 53 34"
          fill="none"
        >
          <Path transform="translate(1 1)" fill="#fff" d="M0 0H51V32H0z" />
          <Rect
            x={0.5}
            y={0.5}
            width={52}
            height={33}
            rx={4.5}
            stroke="#C2C2C5"
          />
          <Path
            d="M32.494 17.082a3.915 3.915 0 00-1.166-2.814 4.032 4.032 0 00-2.863-1.149h-.014c-2.255 0-4.044 1.762-4.044 3.984 0 2.2 1.81 3.984 4.044 3.984 2.255 0 4.043-1.762 4.043-3.984v-.021z"
            fill="#F26E21"
          />
          <Path
            d="M23.697 18.806c-.602.603-1.157.838-1.755.85-1.458-.012-2.53-1.092-2.53-2.63 0-.775.294-1.41.759-1.952.435-.413.997-.68 1.642-.68.697 0 1.239.232 1.858.851v-1.78c-.62-.31-1.162-.465-1.858-.465a4.485 4.485 0 00-2.624 1.092 3.874 3.874 0 00-.568.622 3.903 3.903 0 00-.834 2.39c0 2.244 1.78 3.948 4.026 3.948l.032-.002.045.002c.62 0 1.162-.155 1.858-.465l-.051-1.78c-.026.025.026.05 0 .074v-.075zM37.58 14.108l-1.754 4.311-2.013-5.11h-1.626l3.174 7.82h.852l3.329-7.82h-1.626l-.337.8zM40.161 16.716v4.18h4.258v-1.239H41.71v-2.089h2.632V16.25H41.71v-1.626h2.71V13.31H40.16v3.407zM5.168 13.31H3v7.586h2.168c1.161 0 2.013-.31 2.71-.851a3.828 3.828 0 001.393-2.942c0-2.246-1.626-3.794-4.103-3.794zm1.78 5.728c-.464.387-1.083.62-2.012.62H4.47v-5.033h.387c.93 0 1.549.155 2.013.62.542.464.852 1.16.852 1.858.077.697-.232 1.47-.774 1.935zM11.44 13.309H9.967v7.587h1.471v-7.587zM15.155 16.251c-.929-.31-1.161-.542-1.161-.929 0-.464.464-.851 1.084-.851.464 0 .851.155 1.238.62l.775-1.007a3.372 3.372 0 00-2.246-.852c-1.316 0-2.4.93-2.4 2.168 0 1.084.465 1.548 1.858 2.09.62.232.852.31 1.007.465.31.154.464.464.464.774 0 .62-.464 1.084-1.161 1.084a1.805 1.805 0 01-1.626-1.007l-.929.93c.697 1.006 1.549 1.47 2.633 1.47 1.548 0 2.631-1.006 2.631-2.477 0-1.316-.464-1.858-2.167-2.478zM48.6 17.722c1.161-.232 1.78-1.007 1.78-2.168 0-1.393-1.006-2.245-2.709-2.245h-2.245v7.588h1.47v-3.02h.233l2.09 3.02H51l-2.4-3.175zm-1.239-.93h-.464V14.47h.464c.93 0 1.471.387 1.471 1.162 0 .774-.542 1.16-1.47 1.16z"
            fill="#000"
          />
        </Svg>
      </>
    );
  }
}
