import * as React from 'react';
import Svg, {Path, Mask, G} from 'react-native-svg';

type InfoIconProps = {
  size: number;
  color?: string;
}; // tslint:disable

export default class InfoBlueIconFilled extends React.Component<InfoIconProps> {
  render() {
    return (
      <Svg
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 28 28"
        fill="none"
      >
        <G id="Icons/Info">
          <Path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.6652 13.8434C25.5767 7.40128 20.2855 2.25068 13.8434 2.334C7.40125 2.41733 2.25068 7.71375 2.334 14.1559C2.42254 20.5981 7.71372 25.7487 14.1558 25.6653C20.598 25.5768 25.7537 20.2856 25.6652 13.8434ZM13.9996 6.49511C14.9214 6.49511 15.6661 7.23984 15.6661 8.16163C15.6661 9.08343 14.9214 9.82816 13.9996 9.82816C13.0778 9.82816 12.3331 9.08343 12.3331 8.16163C12.3331 7.23984 13.0778 6.49511 13.9996 6.49511ZM16.2911 21.2386H11.7082V20.6658H12.8539V12.3331H11.7082V11.7082H15.1453V20.6658H16.2911V21.2386Z"
            fill={this.props.color ?? '#066DFF'}
          />
          <Mask
            id="mask0_4770_14845"
            maskUnits="userSpaceOnUse"
            x="2"
            y="2"
            width="24"
            height="24"
          >
            <Path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.6652 13.8434C25.5767 7.40128 20.2855 2.25068 13.8434 2.334C7.40125 2.41733 2.25068 7.71375 2.334 14.1559C2.42254 20.5981 7.71372 25.7487 14.1558 25.6653C20.598 25.5768 25.7537 20.2856 25.6652 13.8434ZM13.9996 6.49511C14.9214 6.49511 15.6661 7.23984 15.6661 8.16163C15.6661 9.08343 14.9214 9.82816 13.9996 9.82816C13.0778 9.82816 12.3331 9.08343 12.3331 8.16163C12.3331 7.23984 13.0778 6.49511 13.9996 6.49511ZM16.2911 21.2386H11.7082V20.6658H12.8539V12.3331H11.7082V11.7082H15.1453V20.6658H16.2911V21.2386Z"
              fill="white"
            />
          </Mask>
          <G mask="url(#mask0_4770_14845)"></G>
        </G>
      </Svg>
    );
  }
}
