import React from 'react';
import {Platform} from 'react-native';
import MarketConnectLogoWeb from 'src/components/img/svg/MarketConnectLogoWeb';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import CanteenPantryLogo from '../img/svg/CanteenPantryLogo';
import ConnectAndPayLogo from '../img/svg/ConnectAndPayLogo';
import Logo365Pay from '../img/svg/Logo365Pay';
import MarketConnectLogo from '../img/svg/MarketConnectLogo';
import SocialFeediaLogo from '../img/svg/SocialFeediaLogo';
import Styles from '../Styles';
import Localized from 'src/constants/AppStrings';
import PayWebLogo from 'src/components/img/svg/PayWebLogo';

export default (props: {small?: boolean; width?: number; height?: number}) => {
  const size = props.small ? Styles.Fonts.f5 : Styles.Heights.h6 * 3;
  const width = props.width || size;
  const height = props.height || size;

  switch (Settings.buildType) {
    case BuildTypes.market:
      if (Platform.OS === 'web') {
        return <MarketConnectLogoWeb width={width} height={height} />;
      }
      return <MarketConnectLogo width={width} height={height} />;
    case BuildTypes.pantry:
      return <CanteenPantryLogo width={width} height={height} />;
    case BuildTypes.social:
      return <SocialFeediaLogo width={width} height={height} />;
    case BuildTypes.canteen:
      return <ConnectAndPayLogo width={width} height={height} />;
    default:
      if (Platform.OS === 'web') {
        return <PayWebLogo width={size} height={size} />;
      } else {
        return (
          <Logo365Pay
            accessible={true}
            accessibilityRole={'image'}
            accessibilityLabel={Localized.Labels.logo_365Pay}
            aria-label={Localized.Labels.logo_365Pay}
            width={width}
            height={height}
          />
        );
      }
  }
};
