import React, {useContext, useEffect, useState} from 'react';
import {
  View,
  SafeAreaView,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  RefreshControl,
  Image,
  Dimensions,
  PermissionsAndroid,
  Platform,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import withIsConnected from '../../hoc/withIsConnected';
import Localized from 'src/constants/AppStrings';
import Styles from 'src/components/Styles';
import FontAwesome5Pro from 'src/components/icons/FontAwesomeIcon';
import LocationMarker from 'src/components/img/svg/LocationMarker';
import AVText from 'src/components/elements/AVText';
import EngagementCarousel from 'src/components/elements/home/EngagementCarousel';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import {IsConnectedProps} from 'src/types/Screen';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import {useAppDispatch, useAppSelector} from 'src/redux/hooks';
import {
  fetchAnnouncements,
  fetchRecentCampusesAndLocations,
} from 'src/redux/slices/campusLocationSlice';
import AccountStore from 'src/stores/AccountStore';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import ActionsFactory from 'src/actions/ActionsFactory';
import uuid from 'src/nativeModules/UUID';
import SendASnackView from 'src/components/elements/home/SendASnackView';
import NotificationIcon from 'src/components/img/svg/newui/notificationLogo';
import AccountFundView from 'src/components/elements/home/AccountFundView';
import OffersView from 'src/components/elements/home/OffersView';
import AnnouncementsList from 'src/components/elements/home/AnnouncementsList';
import LocationHandler from 'src/handlers/LocationHandler';
import MainConsumerContext from 'src/components/MainConsumerContext';
import MachineStore from 'src/stores/MachineStore';
import BluetoothDevice from 'src/services/bluetooth/BluetoothDevice';
import BluetoothManager, {
  BluetoothEvents,
  BluetoothStates,
} from 'src/services/bluetooth/BluetoothManager';
import VendingMachineConfig from 'src/models/VendingMachineConfig';
import VEVendSession from 'src/services/VEVendSession';
import TSFInsideVendSession from 'src/services/TSFInsideVendSession';
import VendSession from 'src/services/VEVendSession';
import SyncHelper from 'src/services/SyncService';
import TransactionActions from 'src/actions/TransactionActions';
import {alertError} from 'src/components/helpers/AlertHelper';
import MachineAction from 'src/actions/MachineActions';
import Settings from 'src/Settings';
import BluetoothDeviceTypes from 'src/constants/BluetoothDeviceTypes';
import VendorsExchangeDevice from 'src/services/bluetooth/vendorsExchange/VendorsExchangeDevice';
import BeaconDevice from 'src/services/bluetooth/BeaconDevice';
import TSFInsideDevice, {
  TSFInsideEvents,
} from 'src/services/bluetooth/tsfInside/TSFInsideDevice';
import VendingLocationList from 'src/components/elements/home/VendingLocationList';
import CartTypes from 'src/constants/cart/CartTypes';
import ScanProductBarCode from 'src/components/img/svg/ScanProductBarCode';
import PlatformApi from 'src/api/PlatformApi';
import SnackActions from 'src/constants/sendSnack/SnackActions';
import SuccessModal from 'src/components/elements/SuccessModal';
import PersistentStore from 'src/services/PersistentStoreService';
import {useNavigation} from '@react-navigation/native';
import AppApi from 'src/api/AppApi';
import NotificationUnReadIcon from 'src/components/img/svg/newui/notificationUnReadLogo';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type RevolveHomeScreenProps = IsConnectedProps;

const {width} = Dimensions.get('screen');

const RevolveHomeScreen = (props: RevolveHomeScreenProps) => {
  const navigation = useNavigation();
  const TIMEOUT_MILLISECONDS = 10000;
  const context = useContext(MainConsumerContext);
  const dispatch = useAppDispatch();
  const selectedLocation = useAppSelector(
    (s) => s.campusLocation.selectedLocation,
  );
  const announcementsData = useAppSelector(
    (s) => s.campusLocation.announcements,
  );
  const accountId = useAppSelector((s) => s.account.account.id);

  let connectTimeout;

  const [vendingLocations, setVendingLocations] = useState([]);
  const [scanAndPayBeacons, setScanAndPayBeacons] = useState([]);
  const [bluetoothState, setBluetoothState] = useState(
    BluetoothStates?.PoweredOff,
  );

  let authorizing = false;
  let selectedDevice: BluetoothDevice;
  let currentVendTransactionId: null | string = null;
  const [refreshing, setRefreshing] = useState(false);

  const [showSendSnackModal, setshowSendSnackModal] = useState(false);
  const [showErrorSendSnackModal, setErrorShowSendSnackModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [snackList, SetSnackList] = useState([]);
  const [unreadNotifications, setUnreadNotification] = useState(false);
  const [isFocused, setIsFocused] = useState(true);

  useEffect(() => {
    LocationHandler.getCurrentLocation();
    CrashlyticsEvents.crashLogEnableSetup();
    FirebaseAnalytic.trackEvent('componentDidMount', 'RevolveHomeScreen');
    /** machines */
    MachineStore.addChangeListener(onMachineStoreChanged);
    MachineStore.addConnectionErrorListener(onMachineConnectionError);
    BluetoothManager.addListener(
      BluetoothEvents.onDeviceFound,
      bluetoothDeviceFound,
    );
    BluetoothManager.addListener(
      BluetoothEvents.onBTStateUpdate,
      bluetoothStateUpdated,
    );
    sendaSnackPopUp();
    checkOTSNotification();
    return () => {
      MachineStore.removeChangeListener(onMachineStoreChanged);
      MachineStore.removeConnectionErrorListener(onMachineConnectionError);
      BluetoothManager.removeListener(
        BluetoothEvents.onBTStateUpdate,
        bluetoothStateUpdated,
      );
      BluetoothManager.removeListener(
        BluetoothEvents.onDeviceFound,
        bluetoothDeviceFound,
      );
    };
  }, []);

  useEffect(() => {
    // Show the modal if there is data
    const isNotReadIndexes = [...snackList.keys()].filter(
      (item) => !snackList[item].isRead,
    );
    if (isNotReadIndexes.length > 0) {
      setCurrentIndex(isNotReadIndexes[0]);
      setshowSendSnackModal(true);
    } else {
      setshowSendSnackModal(false);
    }
  }, [snackList]);

  useEffect(() => {
    if (accountId && accountId !== -1 && accountId.length > 2) {
      requestPermissions();
      getRecentLocationsList();
      getUnReadNotificationCount();
    }
  }, [accountId]);

  //Fetch announcements whenever user selects location
  useEffect(() => {
    if (selectedLocation) {
      dispatch(fetchAnnouncements(selectedLocation.locationId));
    }
  }, [selectedLocation]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setIsFocused(true);
      sendaSnackPopUp();
      checkOTSNotification();
      getUnReadNotificationCount();
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      setIsFocused(false);
    });

    return unsubscribe;
  }, []);

  const getUnReadNotificationCount = async () => {
    const messages = await AppApi.getMessages(accountId);
    if (messages) {
      if (messages.length == 0) setUnreadNotification(false);
      else {
        const unreadMessages = messages.filter((item) => item.isRead === false);
        if (unreadMessages.length > 0) setUnreadNotification(true);
        else setUnreadNotification(false);
      }
    } else setUnreadNotification(false);
  };

  const sendaSnackPopUp = async () => {
    const snackData = (await PersistentStore.getSendaSnackArray()) || [];

    /** send a snack pop up */
    const snacksResponse = await PlatformApi.fetchSnacks(
      AccountStore.getLocationId(),
      0,
      10,
      accountId,
    );
    const snackItems = snacksResponse?.items || [];
    const sortedJsObjects = snackItems?.sort(
      (a, b) =>
        new Date(b.lastUpdated).valueOf() - new Date(a.lastUpdated).valueOf(),
    );
    const updatedSnacks = sortedJsObjects
      .filter(
        (item) => item.status === 'S' && item.receiverAccountId === accountId,
      )
      .map((item) => {
        const existingSnack = snackData.find(
          (j) => j.externalServiceId === item.snackId,
        );
        return {
          ...item,
          isRead: existingSnack ? existingSnack.isRead : false,
        };
      });
    const mergedSnacks = [...updatedSnacks];
    snackData.forEach((storedSnack) => {
      if (
        !updatedSnacks.some(
          (snack) => snack.snackId === storedSnack.externalServiceId,
        )
      ) {
        mergedSnacks.push(storedSnack);
      }
    });
    SetSnackList(mergedSnacks.slice(0, 5));
    await PersistentStore.setSendaSnackArray(mergedSnacks.slice(0, 5));
  };

  const checkOTSNotification = async () => {
    const {oneTimeSnackId, oneTimeSnackLocationId} =
      AccountStore.getOTSNotification();

    if (oneTimeSnackId && oneTimeSnackLocationId) {

      const snackDetails = await PlatformApi.loadSnackDetails(
        oneTimeSnackLocationId,
        oneTimeSnackId,
      );
      if (snackDetails.status === 'S') {
        setshowSendSnackModal(true);
      } else if (snackDetails.status === 'C') {
        setErrorShowSendSnackModal(true);
      }
    }
  };

  const onMachineStoreChanged = () => {
    //Fetch Markets (Beacons)
    getMarkets();
    //Fetch Vending Machines
    getMachines();
  };
  const checkBluetoothState = async () => {
    const currentState = await BluetoothManager.getBluetoothState();
    if (currentState !== bluetoothState) {
      setBluetoothState(currentState);
      if (currentState === BluetoothStates?.PoweredOn) {
        BluetoothManager.startScan();
      } else {
        BluetoothManager.stopScan();
        MachineAction.clearMarkets();
        if (NavActions.getLastRoute() === AppRoutes.RevolveHome) {
          MachineAction.clearMachines();
          MachineAction.clearDevices();
        }
      }
    }
  };
  const hideErrorModal = () => {
    setErrorShowSendSnackModal(false);
    AccountStore.resetOTSNotification();
  };
  const hideModal = async () => {
    snackList.map((item, i) => {
      if (i === currentIndex) {
        item.isRead = true;
      }
      return item;
    });
    const snackFilterResult = snackList.filter((item) => item.status !== 'C');
    SetSnackList(snackFilterResult);
    await PersistentStore.setSendaSnackArray(snackFilterResult);
    AccountStore.resetOTSNotification();
  };
  const onAcceptModalBtn = async (currentSnack) => {
    snackList.map((item, i) => {
      if (i === currentIndex) {
        item.isRead = true;
      }
      return item;
    });
    const filterResult = snackList.filter((item) => item.status !== 'C');
    SetSnackList(filterResult);
    await PersistentStore.setSendaSnackArray(filterResult);
    NavActions.navigate(AppRoutes.SnackDetail, {
      snackId: currentSnack.snackId,
      snackLocationId: currentSnack.locationId,
      snackAction: SnackActions.AcceptSnack,
    });
    AccountStore.resetOTSNotification();
  };

  const requestPermissions = async () => {
    if (Platform.OS === 'android') {
      PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
      ).then((result) => {
        if (result === 'granted') {
          PermissionsAndroid.requestMultiple([
            PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_SCAN,
            PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_CONNECT,
            PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_ADVERTISE,
          ]).then(() => {
            LocationHandler.getCurrentLocation();
            checkBluetoothState();
          });
        }
      });
    } else {
      checkBluetoothState();
      LocationHandler.getCurrentLocation();
    }
  };

  const bluetoothDeviceFound = async (device) => {
    if (device.type === BluetoothDeviceTypes.vendorsExchange) {
      MachineAction.deviceAddedVE(device as VendorsExchangeDevice);
    } else if (device.type === BluetoothDeviceTypes.beacon) {
      const beacon = device as BeaconDevice;
      MachineAction.marketScanned(
        beacon.beaconId,
        AccountStore.getAccountId(),
        Settings.buildType,
      );
    } else if (device.type === BluetoothDeviceTypes.tsfInside) {
      const tsfInside = device as TSFInsideDevice;
      if (tsfInside.deviceName) {
        MachineAction.machineAdded(tsfInside);
      } else {
        MachineAction.deviceAdded(tsfInside);
      }
    }
  };
  const bluetoothStateUpdated = () => {
    checkBluetoothState();
  };

  //Fetch Machines(Vending)
  const getMachines = () => {
    const machinesList = MachineStore.getMachines();
    setVendingLocations(machinesList);
    return machinesList;
  };

  //Fetches Scan And Pay Beacons(Markets)
  const getMarkets = () => {
    const marketLocations = MachineStore.getMarkets();
    setScanAndPayBeacons([...marketLocations]);
  };

  const onMachineConnectionError = async () => {
    if (VendSession?.MachineConfig?.TransactionId || currentVendTransactionId) {
      cancelTransaction(
        VendSession?.MachineConfig?.TransactionId ?? currentVendTransactionId,
      );
    }
    const guid = await uuid.getRandomUUID();
    clearTimeout(connectTimeout);
    context.actions.hideSpinner();
    const error = MachineStore.getConnectionError();

    if (error === 'invalidkey') {
      alertError(Localized.Errors.invalid_key, guid);
    } else if (error === 'notavailable') {
      alertError(
        Localized.Errors.notification_sent_to_operator,
        guid,
        undefined,
        Localized.Errors.unable_to_connect_with_vending_machine,
      );
    } else {
      alertError(Localized.Errors.unable_to_connect_with_vending_machine, guid);
    }
    handleDisconnect();
    CrashlyticsEvents.log(
      'Exception',
      'HomesScreen:OnConnectionError',
      error,
      guid,
    );
    Events.Error.trackEvent(
      'Exception',
      'HomesScreen:OnConnectionError',
      error,
      guid,
    );
  };

  //Fetch user's recently selected location
  const getRecentLocationsList = async () => {
    await dispatch(fetchRecentCampusesAndLocations(accountId));
  };

  const getSelectedLocation = () => {
    if (selectedLocation) {
      return selectedLocation?.displayName ?? selectedLocation?.name;
    }
    //User Home Location is not set
    return Localized.Labels.select_a_location;
  };
  const startCartSession = (
    route: AppRoutes,
    machine,
    additionalParams?,
    key?: string,
  ) => {
    const param = context.actions.getScanScreenParams(machine);
    Events.Machines.trackEvent('startCartSession', {
      param,
      additionalParams,
      route,
    });
    NavActions.push(route, {...param, ...additionalParams}, key);
  };

  const onMachineSelect = async (machine) => {
    FirebaseAnalytic.trackEvent('onMachineSelect', 'RevolveHomeScreen', {
      ...props,
    });
    Events.Machines.trackEvent('onMachineSelect', {
      localType: machine.localType,
    });

    // Clear Cart Items
    TransactionActions.shoppingCartCleared();

    if (machine.localType === 'coffee') {
      //Navigate to Coffee Machine Screen
      startCartSession(AppRoutes.CoffeeMachine, machine);
    } else if (machine.localType !== 'market') {
      //Handle Vending Flow
      handleMachineSelected(machine);
    } else {
      //Scan and Pay flow - Naviagte to Scan Screen
      try {
        context.actions.showSpinner();
        const locationResponse =
          await ActionsFactory.getAccountActions().getLocation(machine.id);
        const parameters = {
          cartType: CartTypes.ScanAndPay,
          location: {...machine, ...locationResponse},
        };
        startCartSession(AppRoutes.Scan, machine, parameters);
      } catch (error) {
        CrashlyticsEvents.log(
          'Exception',
          'RevolveHomeScreen:onMachineSelect',
          generateErrorMessage(error),
        );
        Events.Error.trackEvent(
          'Exception',
          'RevolveHomeScreen:onMachineSelect',
          generateErrorMessage(error),
        );
      } finally {
        context.actions.hideSpinner();
      }
    }
  };

  const handleMachineSelected = async (machine) => {
    FirebaseAnalytic.trackEvent('handleMachineSelected', 'RevolveHomeScreen', {
      ...props,
    });
    if (!authorizing) {
      try {
        context.actions.showSpinner(Localized.Labels.authorizing);
        authorizing = true;
        selectedDevice = machine;
        const authResultData = await TransactionActions.authorize(
          machine.deviceId,
        );
        onAuthorize(authResultData);
      } catch (error) {
        if (VEVendSession?.MachineConfig?.TransactionId) {
          cancelTransaction(VendSession?.MachineConfig?.TransactionId);
        }
        CrashlyticsEvents.log(
          'Exception',
          'RevolveHomeScreen:handleMachineSelected',
          generateErrorMessage(error),
        );
        Events.Error.trackEvent(
          'Exception',
          'RevolveHomeScreen:handleMachineSelected',
          generateErrorMessage(error),
        );
      } finally {
        authorizing = false;
      }
    }
  };

  const onAuthorize = async (authResultData) => {
    const validAuthorieResult =
      authResultData &&
      authResultData.AuthResult &&
      !authResultData.AuthResult.ErrorMessage;

    if (validAuthorieResult && authResultData.AuthResult.TransactionId) {
      currentVendTransactionId =
        authResultData?.AuthResult?.TransactionId ??
        VendSession?.MachineConfig?.TransactionId;
      context.actions.showSpinner('', true, handleConnectTimeout);

      if (selectedDevice.type === BluetoothDeviceTypes.vendorsExchange) {
        const vendorsExchangeDevice = selectedDevice as VendorsExchangeDevice;
        await selectedDevice.connect();
        context.actions.hideSpinner();

        if (vendorsExchangeDevice.currency === AccountStore.getCurrency()) {
          const machineConfig = new VendingMachineConfig(
            authResultData.AuthResult,
            authResultData.Settings,
          );
          VEVendSession.startSession(vendorsExchangeDevice, machineConfig);
          NavActions.push(AppRoutes.VendorsExchangeVending);
        } else {
          alertError(Localized.Labels.incompatible_currency);
          handleDisconnect();
        }
      } else {
        connectTimeout = startConnectionTimeout(TIMEOUT_MILLISECONDS * 3);
        const machineConfig = new VendingMachineConfig(
          authResultData.AuthResult,
          authResultData.Settings,
        );
        addDeviceListeners();
        TSFInsideVendSession.startSession(selectedDevice, machineConfig);
      }
    } else {
      context.actions.hideSpinner();
      if (
        authResultData?.AuthResult?.TransactionId ||
        VendSession?.MachineConfig?.TransactionId ||
        currentVendTransactionId
      ) {
        cancelTransaction(
          authResultData?.AuthResult?.TransactionId ??
            VendSession?.MachineConfig?.TransactionId ??
            currentVendTransactionId,
        );
      }

      if (
        !authResultData.AuthResult ||
        !authResultData.AuthResult.ErrorMessage
      ) {
        alertError(Localized.Errors.unable_to_vend);
      } else {
        alertError(authResultData.AuthResult.ErrorMessage);
      }
    }

    authorizing = false;
  };

  const addDeviceListeners = () => {
    if (selectedDevice) {
      SyncHelper.setDevice(selectedDevice);
      selectedDevice.addListener(TSFInsideEvents.onConnected, onMachineConnect);
      selectedDevice.addListener(
        TSFInsideEvents.onConnecting,
        onMachineConnecting,
      );
      selectedDevice.addListener(
        TSFInsideEvents.onHighestPriceReceived,
        onHighestPriceReceived,
      );
    }
  };

  const onHighestPriceReceived = (price: number) => {
    TSFInsideVendSession.setMachineHighestPrice(price);
  };

  const onMachineConnect = () => {
    FirebaseAnalytic.trackEvent('onMachineConnect', 'RevolveHomeScreen', {
      ...props,
    });
    MachineAction.machineConnected();
    clearTimeout(connectTimeout);
    context.actions.hideSpinner();
    NavActions.push(AppRoutes.Vending, {
      returnRoute: AppRoutes.RevolveHome,
    });
    removeDeviceListeners(false);
  };

  const onMachineConnecting = () => {
    clearTimeout(connectTimeout);
    connectTimeout = startConnectionTimeout(3000);
  };

  //Cancel Transcation
  const cancelTransaction = (transId: string) => {
    TransactionActions.cancelTransaction(transId);
    currentVendTransactionId = null;
  };

  const handleDisconnect = () => {
    //Check if any device is connected
    if (selectedDevice) {
      removeDeviceListeners();
      selectedDevice.disconnect();
    }
  };

  const removeDeviceListeners = (removeSyncEvents = true) => {
    if (selectedDevice) {
      if (removeSyncEvents) {
        SyncHelper.removeEvents(selectedDevice);
      }
      //Remove all Device Listners
      selectedDevice.removeListener(
        TSFInsideEvents.onConnecting,
        onMachineConnecting,
      );
      selectedDevice.removeListener(
        TSFInsideEvents.onHighestPriceReceived,
        onHighestPriceReceived,
      );

      selectedDevice.removeListener(
        TSFInsideEvents.onConnected,
        onMachineConnect,
      );
    }
  };

  const handleConnectTimeout = async (error = false) => {
    if (currentVendTransactionId || VendSession?.MachineConfig?.TransactionId) {
      cancelTransaction(
        currentVendTransactionId ?? VendSession?.MachineConfig?.TransactionId,
      );
    }
    const guid = await uuid.getRandomUUID();
    clearTimeout(connectTimeout);
    CrashlyticsEvents.log(
      'Exception',
      'RevolveHomeScreen:HandleConnectTimeout',
      '',
      guid,
    );
    Events.Error.trackEvent(
      'Exception',
      'RevolveHomeScreen:HandleConnectTimeout',
      '',
      guid,
    );
    handleDisconnect();
    context.actions.hideSpinner();

    if (error) {
      alertError(Localized.Errors.unable_to_connect_with_vending_machine, guid);
    }
  };

  const startConnectionTimeout = (timeout: number) => {
    return setTimeout(() => {
      handleConnectTimeout(true);
    }, timeout);
  };

  const onClickScanProduct = () => {
    if (scanAndPayBeacons.length === 1) {
      onMachineSelect(scanAndPayBeacons[0]);
    } else {
      NavActions.navigate(AppRoutes.ChooseLocation, {
        locations: scanAndPayBeacons,
        onLocationClick: onMachineSelect,
      });
    }
  };

  const onRefresh = async () => {
    FirebaseAnalytic.trackEvent('onRefresh', 'RevolveHomeScreen');
    setRefreshing(true);
    try {
      await ActionsFactory.getAccountActions().reloadConsumerData({
        accountBalanceId: AccountStore.getAccountBalanceId(),
        accountId: AccountStore.getAccountId() ?? accountId,
        userInitiated: true,
        email: AccountStore.getEmail(),
      });
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'RevolveHomeScreen:onRefresh',
        generateErrorMessage(error),
        guid,
        {
          ...props,
        },
      );
      Events.Error.trackEvent(
        'Exception',
        'RevolveHomeScreen:onRefresh',
        generateErrorMessage(error),
        guid,
        {
          ...props,
        },
      );
    }
    setRefreshing(false);
  };

  const currentSnack = snackList[currentIndex];

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.headerContainer}>
        <TouchableOpacity
          accessible={true}
          onPress={() => {
            FirebaseAnalytic.trackEvent(
              'selectALocationClick',
              'RevolveHomeScreen',
              {...selectedLocation},
            );
            NavActions.navigate(AppRoutes.CampusLocations);
          }}
          testID="SelectLocationDropdown"
          accessibilityLabel="SelectLocationDropdown"
          accessibilityRole={'button'}
          style={styles.locationView}
          accessibilityHint="Double tap to navigate to the Locations Screen"
          aria-label="SelectLocationDropdown"
        >
          <LocationMarker color={Styles.white} size={Styles.Fonts.headerFont} />
          <AVText
            numberOfLines={1}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            style={styles.locationNameText}
          >
            {getSelectedLocation()}
          </AVText>
          <FontAwesome5Pro
            name="chevron-down"
            accessibilityLabel={
              'Down arrow icon, Double tap to the Locations Screen'
            }
            accessible={true}
            style={styles.locationArrow}
            size={Styles.Heights.touchTargetHeight0}
            color={Styles.white}
            aria-label={'Down arrow icon, Double tap to the Locations Screen'}
          />
        </TouchableOpacity>
        <TouchableOpacity
          accessible={true}
          style={styles.notificationView}
          accessibilityHint="Double tap to navigate to the Notifications screen"
          accessibilityRole={'button'}
          accessibilityLabel="NotificationsIcon"
          aria-label="NotificationsIcon"
          testID="NotificationsIcon"
          onPress={() => {
            FirebaseAnalytic.trackEvent(
              'NotificationsButtonClick',
              'RevolveHomeScreen',
            );

            NavActions.push(AppRoutes.Inbox);
          }}
        >
          <View>
            {!unreadNotifications && (
              <NotificationIcon
                color={Styles.positiveColor}
                size={Styles.Fonts.f3}
              />
            )}
            {unreadNotifications && (
              <NotificationUnReadIcon color={Styles.positiveColor} size={44} />
            )}
          </View>
        </TouchableOpacity>
      </View>
      <ScrollView
        style={styles.content}
        refreshControl={
          props.isConnected && (
            <RefreshControl
              aria-label={refreshing ? 'refreshing' : ''}
              colors={[Styles.primaryColor]}
              accessibilityLabel={refreshing ? 'refreshing' : ''}
              titleColor={Styles.black}
              accessible={true}
              refreshing={refreshing}
              tintColor={Styles.primaryColor}
              onRefresh={onRefresh}
            />
          )
        }
      >
        <Image
          role="img"
          accessible={true}
          accessibilityRole="image"
          resizeMode="contain"
          accessibilityLabel="Revolve Market Name Image"
          aria-label="Revolve Market Name Image"
          style={{
            marginTop: Styles.Spacing.m1,
            height: Styles.Heights.h4,
            width: width - Styles.Spacing.m3,
            alignSelf: 'center',
          }}
          source={require('./../../img/revolveNameHorizontal.png')}
        />
        <View
          style={{
            marginHorizontal: Styles.Spacing.m3,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: Styles.Spacing.m2,
          }}
        >
          <AccountFundView />
          <OffersView />
        </View>
        <EngagementCarousel isConnected={props.isConnected} />
        {vendingLocations && vendingLocations.length > 0 && (
          <VendingLocationList
            locations={vendingLocations}
            isConnected={true}
            onLocationClick={onMachineSelect}
          />
        )}
        <AnnouncementsList
          isConnected={true}
          announcements={announcementsData}
        />
        <SendASnackView />
        <View style={{height: Styles.Heights.h7}} />
      </ScrollView>
      {scanAndPayBeacons.length > 0 && (
        <TouchableOpacity
          accessible={true}
          accessibilityLabel={Localized.Buttons.scan_product}
          accessibilityRole="button"
          accessibilityHint={'Double tap to scan product'}
          role="button"
          aria-label={Localized.Buttons.scan_product}
          style={styles.scanButtonView}
          onPress={() => onClickScanProduct()}
        >
          <ScanProductBarCode color={Styles.white} />
          <AVText
            style={[styles.scanButtonText]}
            numberOfLines={1}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
          >
            {Localized.Buttons.scan_product}
          </AVText>
        </TouchableOpacity>
      )}
      {showSendSnackModal && currentSnack && (
        <SuccessModal
          isModalVisible={showSendSnackModal && isFocused}
          headerTitle={
            Localized.Success.formatString(
              Localized.Labels.one_time_snack_accept_header,
              currentSnack?.amount.toFixed(2).toString(),
              currentSnack?.senderName,
            ) as string
          }
          description={Localized.Labels.one_time_snack_accept_desc}
          buttonTitle={Localized.Labels.one_time_snack_accept_btn}
          descLines={2}
          oneTimeSendSnackPopUp={true}
          customAction={() => onAcceptModalBtn(currentSnack)}
          onModalClose={() => hideModal()}
        />
      )}
      {showErrorSendSnackModal && (
        <SuccessModal
          isModalVisible={showErrorSendSnackModal}
          headerTitle={Localized.Errors.one_time_snack_cancelled_error_header}
          description={Localized.Errors.one_time_snack_cancelled_error_desc}
          buttonTitle={Localized.Buttons.okay}
          descLines={5}
          errorSendSnackPopUp={true}
          onModalClose={() => hideErrorModal()}
        />
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Styles.primaryColor,
    flex: 1,
  },
  content: {
    flex: 1,
    backgroundColor: Styles.tabBarBackgroundColor,
  },
  unreadNotifications: {
    left: 20,
    bottom: 30,
  },
  headerContainer: {
    justifyContent: 'flex-start',
    width: '100%',
    backgroundColor: Styles.primaryColor,
    flexDirection: 'row',
    alignItems: 'center',
    height: Styles.Heights.touchTargetHeight1,
    marginTop: 11,
    paddingBottom: Styles.Spacing.m1,
  },
  notificationView: {
    position: 'absolute',
    height: Styles.Heights.touchTargetHeight1,
    right: Styles.Spacing.m2,
    alignItems: 'center',
    width: Styles.Heights.touchTargetHeight1,
    justifyContent: 'center',
    paddingBottom: Styles.Spacing.m1,
  },
  locationView: {
    marginLeft: Styles.Spacing.m2 + Styles.Spacing.m1,
    height: Styles.Heights.h5,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: Styles.Spacing.m4,
    maxWidth: '70%',
    justifyContent: 'flex-start',
  },
  locationNameText: {
    color: Styles.white,
    marginLeft: Styles.Spacing.m0,
    fontWeight: '400',
    fontSize: Styles.Fonts.sectionHeader,
    maxWidth: '90%',
    fontFamily: Styles.FontFamily.comodoVintage,
  },
  scanButtonText: {
    color: Styles.white,
    marginLeft: Styles.Spacing.m2 + Styles.Spacing.m1,
    fontWeight: '400',
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.comodoVintage,
  },
  locationArrow: {
    marginLeft: Styles.Spacing.m2,
  },
  scanButtonView: {
    position: 'absolute',
    bottom: Styles.Spacing.m3,
    right: Styles.Spacing.m1 + 5,
    height: Styles.Heights.touchTargetHeight2,
    borderRadius: 24,
    backgroundColor: Styles.blueRevolve,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: Styles.Spacing.m3,
  },
});

export default withForwardedNavigationParams()(
  withIsConnected(RevolveHomeScreen),
);
