import OneSignal from 'react-native-onesignal';

interface TagDictionary {
  cart_abandoned?: string;
  purchase_status?: string;
}

class TagManager {
  static async setTags(tags) {
    try {
      await OneSignal.sendTags(tags);

    } catch (error) {
      console.error('Error updating tags:', error);
    }
  }
  static async deleteTag(key) {
    try {
      await OneSignal.deleteTag(key);
    } catch (error) {
      console.error(`Error deleting tag "${key}":`, error);
    }
  }
  static async deleteTags(keys) {
    try {
      await OneSignal.deleteTags(keys);

    } catch (error) {
      console.error('Error deleting tags:', error);
    }
  }
  static async getTags() {
    try {
      return await new Promise((resolve, reject) => {
        OneSignal.getTags((tags) => {
          if (tags) {

            resolve(tags);
          } else {
            reject(new Error('No tags found'));
          }
        });
      });
    } catch (error) {
      console.error('Error fetching tags:', error);
      return {};
    }
  }
  static async updateDynamicTags(event) {
    const dynamicTags: TagDictionary = {};
    switch (event) {
      case 'cart_abandoned':
        dynamicTags.cart_abandoned = 'true';
        break;
      case 'purchase_completed':
        dynamicTags.purchase_status = 'completed';
        dynamicTags.cart_abandoned = 'false';
        break;
      default:

    }
    if (Object.keys(dynamicTags).length > 0) {
      await this.setTags(dynamicTags);
    }
  }
}
export default TagManager;
