import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

type DealIconProps = {
  width?: number;
  height?: number;
  bgColor?: string;
};

export default class DealIconDetailRefive extends React.Component<DealIconProps> {
  render() {
    return (
      <>
        <Svg width={54} height={54} viewBox="0 0 54 54" fill="none">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.6 27.541L27.496 6.28l-.15-.151h-.04c-.835-.794-1.799-1.206-3.004-1.236L14.287 4.52l-.442-.02a4.41 4.41 0 00-3.083 1.316L5.82 10.75c-.904.905-1.316 2.1-1.316 3.286v.01l.03.422.673 9.777v.211c.1.875.452 1.739 1.045 2.452l.552.543 20.722 20.981.311.312a3.022 3.022 0 004.149-.141L48.6 31.863c1.185-1.187 1.205-3.126 0-4.322zm-32.846-6.973a4.824 4.824 0 010-9.647 4.824 4.824 0 010 9.647z"
            fill="#4588FD"
          />
        </Svg>
      </>
    );
  }
}
