import Styles from 'src/components/Styles';
import AVText from 'src/components/elements/AVText';
import {DealDescriberData} from 'src/components/screens/inbox/descriptor/DescriptorType';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Localized from 'src/constants/AppStrings';
import {Platform, View} from 'react-native';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import {Dimensions} from 'react-native';
import {getTabOptions} from 'src/components/screens/inbox/newui/InboxScreenNewUI';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import AnnouncementScreen from 'src/components/screens/inbox/newui/AnnounceMentScreen';
import InboxScreen from 'src/components/screens/inbox/InboxScreen';
import DealIconDetailRefive from 'src/components/img/svg/DealIconDetailRefive';
import DealIconDetailsSucces from 'src/components/img/svg/DealIconDetailsSucces';
import RefiveEmptyAnnouncementIcon from 'src/components/img/svg/inbox/RefiveEmptyAnnouncementIcon';

const Tab = createMaterialTopTabNavigator();

const width = Dimensions.get('window').width;

export function dealDescDescriptor(props: DealDescriberData): JSX.Element {
  return (
    <AVText
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
      style={props.styles.legalese}
      accessibilityLabel={props.deal.legalese}
    >
      {props.deal.legalese}
    </AVText>
  );
}

export function announcemetEmptyIcon(): JSX.Element {
  return <RefiveEmptyAnnouncementIcon />;
}

export function notificationTabscDescriptor(): JSX.Element {
  return (
    <>
      <Tab.Screen
        name={Localized.Labels.announcements}
        component={AnnouncementScreen}
        options={getTabOptions(Localized.Labels.announcements)}
        initialParams={null}
      />
      <Tab.Screen
        name={Localized.Labels.messages}
        component={InboxScreen}
        options={getTabOptions(Localized.Labels.messages)}
        initialParams={null}
      />
    </>
  );
}
export function getStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    expiration: {
      fontSize: Styles.Fonts.f0,
      marginBottom: Styles.Spacing.m3,
      marginTop: Styles.Spacing.m2,
      textAlign: 'center',
    },
    content: {
      flex: 1,
      padding: Styles.Spacing.m3,
    },
    topRow: {
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: Styles.Spacing.m3,
    },
  };
}

export function titleDescriptor(props?: DealDescriberData): string {
  return props.deal.promotionText;
}

export function dealIconDescriptor(): JSX.Element {
  return <DealIconDetailsSucces />;
}
export function dealDefaultIconDescriptor(): JSX.Element {
  return <DealIconDetailRefive />;
}

export function dealAppliedBtn() {
  return (
    <RoundedButton
      buttonType={ButtonType.action}
      text={Localized.Labels.applied}
      color={'#707070'}
      containerStyle={getDealDetailStyleDescriptor().appliedButton}
      textStyle={getDealDetailStyleDescriptor().appliedButtonText}
      onPress={() => {
        return false;
      }}
    />
  );
}

export function dealApplyBtn(applyDeal: () => void) {
  return (
    <RoundedAppButton
      buttonType={RoundedAppButtonType.Solid}
      onPress={applyDeal}
      buttonViewStyle={{
        width: 92,
      }}
      textStyle={{
        fontSize: Styles.Fonts.f1,
        fontFamily: Styles.FontFamily.figtreeRegular,
      }}
      buttonContainerStyle={{alignSelf: 'flex-end'}}
      titleText={Localized.Buttons.apply}
    />
  );
}

export function dealAppliedContainer() {
  return (
    <View style={getDealDetailStyleDescriptor()['alreadyClaimedOffer']}>
      {dealIconDescriptor()}
      <AVText style={getDealDetailStyleDescriptor()['alreadyClaimedOfferText']}>
        {Localized.Labels.offer_already_claimed}
      </AVText>
    </View>
  );
}

export function dealValidityKey() {
  return Localized.Labels.ends_on;
}

export function backHeaderStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    backgroundColor:
      Platform.OS === 'web' ? Styles.white : Styles.tabBarBackgroundColor,
  };
}

export function notificationReadUnreadColor(): string {
  return Styles.Colors.Refive.activeFooterBlue;
}

export function getDealEndDateTime(dealEndDateTime) {
  return (
    <AVText
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
      accessibilityLabel={dealEndDateTime}
      style={getDealDetailStyleDescriptor()['expiration']}
    >
      {dealEndDateTime}
    </AVText>
  );
}

export function getDealDetailStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    topView: {
      marginTop: Styles.Spacing.m3,
    },
    image: {
      width: width / 2.4,
      height: width / 2.4,
      borderRadius: Styles.Spacing.m2,
    },
    iconView: {
      width: 50,
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
    },
    dealTitleRow: {
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m1,
    },
    redeemLimit: {
      marginTop: Styles.Spacing.m3,
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
      alignSelf: 'center',
      color: Styles.Colors.PayNew.darkHuesBase04,
    },
    validUntil: {
      fontSize: Styles.Fonts.f1,
      marginTop: Styles.Spacing.m3,
      fontWeight: '400',
      alignSelf: 'center',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    alreadyClaimedOffer: {
      marginTop: Styles.Spacing.m3,
      padding: Styles.Spacing.m3,
      flex: 1,
      flexDirection: 'row',
      borderRadius: Styles.Spacing.m2,
      backgroundColor: 'rgba(164, 219, 87, 0.30)',
    },
    alreadyClaimedOfferText: {
      fontSize: Styles.Fonts.f8,
      alignSelf: 'center',
      flex: 1,
      marginLeft: Styles.Spacing.m2,
      textAlignVertical: 'center',
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.darkBlueRevolveColor,
    },
    description: {
      fontSize: Styles.Fonts.f1,
      marginTop: Styles.Spacing.m3,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    expiration: {
      fontSize: Styles.Fonts.f8,
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '400',
    },
    name: {
      color: Styles.black,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
      flex: 1,
      fontSize: Styles.Fonts.f7,
      position: 'absolute',
      justifyContent: 'center',
    },
    appliedButton: {
      alignItems: 'center',
      alignSelf: 'center',
      padding: Styles.Heights.h1,
      paddingHorizontal: 8,
      paddingVertical: 8,
      justifyContent: 'center',
    },
    appliedButtonText: {
      color: Styles.white,
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
      textAlign: 'center',
      marginLeft: Styles.Spacing.m1,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    content: {
      paddingVertical: Styles.Spacing.m3,
      paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
      flex: 1,
      backgroundColor:
        Platform.OS === 'web' ? Styles.white : Styles.tabBarBackgroundColor,
    },
    allReadText: {
      fontSize: Styles.Fonts.f1,
      paddingTop: Styles.Spacing.m1,
      color: Styles.Colors.Refive.activeFooterBlue,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '700',
    },
    emptyMessageText: {
      marginTop: Styles.Spacing.m2,
      fontSize: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '700',
      color: '#111',
    },
  };
}

export function dealAppliedBtnIcon() {
  return null;
}

export function allReadTextBgColor(): {[index: string]: string} {
  return {
    backgroundColor: '#DBE7FB',
    color: Styles.tabBarTextColor,
    allReadTextUnderLine: Styles.Colors.Refive.activeFooterBlue,
  };
}

export default {
  dealDescDescriptor,
  getStyleDescriptor,
  titleDescriptor,
  dealIconDescriptor,
  dealApplyBtn,
  dealValidityKey,
  dealAppliedBtn,
  dealAppliedBtnIcon,
  notificationTabscDescriptor,
  announcemetEmptyIcon,
  dealAppliedContainer,
  getDealDetailStyleDescriptor,
  backHeaderStyleDescriptor,
  getDealEndDateTime,
  dealDefaultIconDescriptor,
  notificationReadUnreadColor,
  allReadTextBgColor,
};
