import Svg, {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
  type NumberProp,
  type SvgProps,
} from 'react-native-svg';

export const LockLightIcon = ({
  size,
  ...props
}: SvgProps & {size?: NumberProp}) => {
  return (
    <Svg
      width={size ?? 21}
      height={size ?? 24}
      viewBox="0 0 21 24"
      fill="none"
      {...props}
    >
      <G clipPath="url(#clip0_12346_40345)">
        <Path
          d="M6 6V9H15V6C15 3.51562 12.9844 1.5 10.5 1.5C8.01562 1.5 6 3.51562 6 6ZM4.5 9V6C4.5 2.68594 7.18594 0 10.5 0C13.8141 0 16.5 2.68594 16.5 6V9H17.25C19.3219 9 21 10.6781 21 12.75V20.25C21 22.3219 19.3219 24 17.25 24H3.75C1.67812 24 0 22.3219 0 20.25V12.75C0 10.6781 1.67812 9 3.75 9H4.5ZM1.5 12.75V20.25C1.5 21.4922 2.50781 22.5 3.75 22.5H17.25C18.4922 22.5 19.5 21.4922 19.5 20.25V12.75C19.5 11.5078 18.4922 10.5 17.25 10.5H3.75C2.50781 10.5 1.5 11.5078 1.5 12.75Z"
          fill="black"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_12346_40345">
          <Rect width="21" height="24" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
