import React from 'react';
import {
  PixelRatio,
  ScrollView,
  StyleSheet,
  View,
  TouchableOpacity,
  Platform,
} from 'react-native';
import DashedLine from 'react-native-dashed-line';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import {getTaxDescription} from 'src/services/TranslationService';
import TransactionStore from 'src/stores/TransactionStore';
import AVText from '../../elements/AVText';
import EmptyCart from '../../elements/cart/EmptyCart';
import AVTouchableOpacity from '../../elements/AVTouchableOpacity';
import Styles from '../../Styles';
import AVFormattedCurrency from '../AVFormattedCurrency';
import CartBundle from './CartBundle';
import CartItemView from './CartItem';
import DiscountSection from './DiscountSection';
import {
  BundleType,
  TransactionDetail,
  CartItem,
} from 'src/types/TransactionDetail';
import Localized from 'src/constants/AppStrings';
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';
import BuildTypes from 'src/constants/BuildTypeConstants';
import CartTypes from 'src/constants/cart/CartTypes';
import Util from 'src/Util';
import Icon from '../../icons/FontAwesomeIcon';
import CartService from 'src/services/CartService';
import AccountConstants from 'src/constants/AccountConstants';
import ScreenContext from '../../ScreenContext';
import MenuService from 'src/services/MenuService';
import {MenuProductType} from 'src/types/Menu';
import {CreditCard} from 'src/models/CreditCard';
import {connect} from 'react-redux';
import {RootState} from 'src/redux/store';
import {PreparationMethodValues} from 'src/types/PreparationMethods';
import {getDescriber} from 'src/components/screens/orderAhead/descriptor/DescriptorType';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import {Identifier} from 'src/types/serverTypes/Account';
import {LocationType} from 'src/types/Location';
import PromotionTypes from 'src/constants/cart/PromotionTypes';

const {renderColorNutritionComponent, renderNutritionPromotionComponent} =
  getDescriber();

type ScanItemsProps = WithGlobalizeProps & {
  balance: number;
  trashClick?: (itemNumbers: Array<number>) => void;
  editClicked?: (item: CartItem) => void;
  showGrandTotal: boolean;
  hideYourItems: boolean;
  cartType: CartTypes;
  onAddItem?: () => void;
  locationId?: string;
  noteView?: React.ReactNode;
  preparationMethod: PreparationMethodValues;
  creditCards: Array<CreditCard>;
  hasConsumerRoles: boolean;
  consumerRoleButton?: string;
  validateDiscountCode?: () => void;
  addDiscountToCart?: () => void;
  location?: LocationType;
  discountCodeButtonAction?: () => void;
};

type ScanItemsState = {
  transaction: TransactionDetail | null;
  displayItems: Array<CartItem>;
  consumerRoleIdentifier: Identifier | null;
};

class ScanItems extends React.Component<ScanItemsProps, ScanItemsState> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  cartScroller: ScrollView | null;

  constructor(props: ScanItemsProps) {
    super(props);
    this.state = {
      transaction: TransactionStore.getTransaction(),
      displayItems: TransactionStore.getDisplayItems(),
      consumerRoleIdentifier: AccountStore.getConsumerIdentifier(),
    };
    this.onTransactionStoreChanged = this.onTransactionStoreChanged.bind(this);
    this.onAccountStoreChanged = this.onAccountStoreChanged.bind(this);
    this.onAddItemClick = this.onAddItemClick.bind(this);
    this.updateCart = this.updateCart.bind(this);
    this.getTitle = this.getTitle.bind(this);
  }

  componentDidMount() {
    AccountStore.addChangeListener(this.onAccountStoreChanged);
    TransactionStore.addChangeListener(this.onTransactionStoreChanged);
  }

  componentWillUnmount() {
    AccountStore.removeChangeListener(this.onAccountStoreChanged);
    TransactionStore.removeChangeListener(this.onTransactionStoreChanged);
  }

  onTransactionStoreChanged() {
    const transaction = TransactionStore.getTransaction();
    const displayItems = TransactionStore.getDisplayItems();
    this.setState({
      transaction,
      displayItems,
    });
  }
  onAccountStoreChanged() {
    const consumerRoleIdentifier = AccountStore.getConsumerIdentifier();
    this.setState({
      consumerRoleIdentifier,
    });
  }

  renderTotal(due: number) {
    if (Settings.buildType === BuildTypes.pantry) {
      const totalDue = Math.max(due - this.props.balance, 0);
      const cards = this.props.creditCards;

      if (cards && cards.length > 0) {
        const card = cards[0];
        const last4 = card.name.substring(card.name.length - 4);
        const ccDisplay = `....${last4}`;
        return (
          <View style={Styles.Style.row}>
            <View style={Styles.Style.labelColumn}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                accessibilityElementsHidden={true}
                importantForAccessibility="no"
                style={[
                  Styles.Style.summaryTotalLabel,
                  styles.revolveTextLabelTotal,
                ]}
              >
                {Localized.Labels.total_due}
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                  accessible={true}
                  accessibilityRole="text"
                  accessibilityLabel={`${Localized.Labels.last_four_of_card} ${ccDisplay}`}
                  aria-label={`${Localized.Labels.last_four_of_card} ${ccDisplay}, text`}
                  style={[styles.defaultLabel]}
                >
                  {' '}
                  ({ccDisplay})
                </AVText>
              </AVText>
            </View>
            <View style={Styles.Style.amountColumn}>
              <AVFormattedCurrency
                style={[
                  Styles.Style.summaryTotalLabel,
                  styles.revolveTextLabelTotal,
                ]}
                value={totalDue}
                accessible={true}
                accessibilityLabel={`${Localized.Labels.total_due} $${totalDue}`}
                accessibilityRole="text"
                aria-label={`${Localized.Labels.total_due} $${totalDue}, text`}
                currency={AccountStore.getCurrency()}
              />
            </View>
          </View>
        );
      }

      return (
        <View style={Styles.Style.row}>
          <View style={Styles.Style.labelColumn}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
              style={[
                Styles.Style.summaryTotalLabel,
                styles.revolveTextLabelTotal,
              ]}
            >
              {Localized.Labels.total_due}
            </AVText>
          </View>
          <View style={Styles.Style.amountColumn}>
            <AVFormattedCurrency
              style={[
                Styles.Style.summaryTotalLabel,
                styles.revolveTextLabelTotal,
              ]}
              value={totalDue}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={`${Localized.Labels.total_due} $${totalDue}`}
              aria-label={`${Localized.Labels.total_due} $${totalDue}, text`}
              currency={AccountStore.getCurrency()}
            />
          </View>
        </View>
      );
    }

    return (
      <View style={Styles.Style.row}>
        <View style={Styles.Style.labelColumn}>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            style={[
              Styles.Style.summaryTotalLabel,
              styles.revolveTextLabelTotal,
            ]}
          >
            {Localized.Labels.total}
          </AVText>
        </View>
        <View style={Styles.Style.amountColumn}>
          <AVFormattedCurrency
            style={[
              Styles.Style.summaryTotalLabel,
              styles.revolveTextLabelTotal,
            ]}
            value={due}
            accessible={true}
            accessibilityRole="text"
            accessibilityLabel={`${Localized.Labels.due} $${due}`}
            aria-label={`${Localized.Labels.due} $${due}, text`}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            currency={AccountStore.getCurrency()}
          />
        </View>
      </View>
    );
  }

  renderRemainingCredit() {
    if (Settings.buildType === BuildTypes.pantry) {
      return (
        <View style={Styles.Style.row}>
          <View style={Styles.Style.labelColumn}>
            <AVText
              accessible={true}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
              accessibilityLabel={Localized.Labels.remaining_credit}
              aria-label={Localized.Labels.remaining_credit}
              style={[styles.defaultLabel]}
            >
              {Localized.Labels.remaining_credit}
            </AVText>
          </View>
          <View style={Styles.Style.amountColumn}>
            <AVFormattedCurrency
              style={[styles.defaultLabel]}
              value={this.props.balance}
              accessibilityLabel={`${Localized.Labels.balance} $${this.props.balance}`}
              accessibilityRole="text"
              aria-label={`${Localized.Labels.balance} $${this.props.balance}, text`}
              currency={AccountStore.getCurrency()}
            />
          </View>
        </View>
      );
    }

    return null;
  }

  renderGrandTotal(due: number) {
    if (this.props.showGrandTotal) {
      return (
        <View accessible={true} style={Styles.Style.row}>
          <View style={Styles.Style.labelColumn}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
              accessibilityLabel={Localized.Labels.total}
              aria-label={Localized.Labels.total}
              style={[
                Styles.Style.summaryTotalLabel,
                styles.revolveTextLabelTotal,
              ]}
            >
              {Localized.Labels.total}
            </AVText>
          </View>
          <View style={Styles.Style.amountColumn}>
            <AVFormattedCurrency
              style={[
                Styles.Style.summaryTotalLabel,
                styles.revolveTextLabelTotal,
              ]}
              value={due}
              accessibilityRole="text"
              accessibilityLabel={`${Localized.Labels.due} $${due}`}
              aria-label={`${Localized.Labels.due} $${due}, text`}
              currency={AccountStore.getCurrency()}
            />
          </View>
        </View>
      );
    }

    return null;
  }

  renderCartItem = (item: CartItem) => {
    return (
      <CartItemView
        key={item.LineNumber}
        item={item}
        trashClick={this.props.trashClick}
        editClicked={this.props.editClicked}
        canEdit={this.props.cartType === CartTypes.OrderAhead}
        canDelete={this.props.cartType !== CartTypes.TouchlessCoffee}
        cartType={this.props.cartType}
        supFee={parseFloat(item.SupFee || '')}
      />
    );
  };
  getCartItems = (): Array<JSX.Element> => {
    let items: JSX.Element[] = [];
    const nonBundledItems = this.state.displayItems.filter(
      (item) => !item.IsInBundle,
    );

    if (nonBundledItems && nonBundledItems.length > 0) {
      items = nonBundledItems.map((item) => this.renderCartItem(item));
    }

    return items;
  };
  getBundles = (bundledItems: Array<CartItem>): Array<BundleType> => {
    const bundles: Array<BundleType> = [];
    bundledItems.forEach((item) => {
      const bundle = bundles.find(
        (innerBundle) => innerBundle.id === item.DiscountHeaderId,
      );

      if (!bundle) {
        let id = item.DiscountHeaderId;
        let title = item.DiscountDisplayName;

        if (!id) {
          id = 'id';
        }

        if (!title) {
          title = 'title';
        }

        bundles.push({
          id,
          items: [item],
          lineNumber: item.LineNumber || 0,
          title,
        });
      } else {
        bundle.items.push(item);
      }
    });
    return bundles;
  };
  renderBundle = (bundle: BundleType) => {
    return (
      <CartBundle
        key={bundle.lineNumber}
        bundle={bundle}
        trashClick={this.props.trashClick}
      />
    );
  };
  getCartBundles = () => {
    let renderedBundles: JSX.Element[] = [];
    const bundledItems = this.state.displayItems.filter(
      (item) => item.IsInBundle,
    );

    if (bundledItems && bundledItems.length > 0) {
      const bundles = this.getBundles(bundledItems);
      renderedBundles = bundles.map((bundle) => this.renderBundle(bundle));
    }

    return renderedBundles;
  };
  getCartContents = (): Array<JSX.Element> => {
    const items = this.getCartItems();
    const bundles = this.getCartBundles();
    return bundles.concat(items);
  };

  async updateCart(
    items: Array<CartItem>,
    scancode?: string,
    discountCode?: string,
  ) {
    await CartService.updateCart(
      this.props.locationId ?? '',
      AccountStore.getAccountId(),
      [],
      items,
      AccountConstants.SOS_LOCATION_TYPE,
      scancode || '',
      TransactionStore.getPickupTime(),
      this.props.preparationMethod,
      this.context,
      discountCode,
    );
  }

  // This is called only for Upsell items
  onAddItemClick(item: MenuProductType) {
    const items = [...TransactionStore.getAllItems()];
    items.unshift({
      BarCode: item.scancode,
      Modifiers: [],
      PrintGroups: item.printgroups,
      Upsell: true,
    });
    const discountCode =
      this.props.location?.locationFeatures.is365PayAfterTaxPromo &&
      TransactionStore.isAfterTaxPromoApplied()
        ? PromotionTypes.AfterTaxPromo
        : null;
    this.updateCart(items, item.scancode, discountCode || '');
  }

  getTitle(item: MenuProductType) {
    if (item.price) {
      const price = item.price;

      if (price > 0) {
        return `${item.name} (+${Util.formatCurrency(
          this.props,
          price,
          AccountStore.getCurrency(),
        )})`;
      }
    }

    return item.name;
  }

  getPrice(item: MenuProductType) {
    if (item.price) {
      const price = Util.formatCurrency(
        this.props,
        item.price,
        AccountStore.getCurrency(),
      );
      return price;
    }
    return 0;
  }

  getUpsellItems = () => {
    const upsellItems = TransactionStore.getUpsellItems();

    if (
      this.props.cartType === CartTypes.OrderAhead &&
      upsellItems &&
      upsellItems.length > 0
    ) {
      const upsellItemsInMenu = upsellItems
        .reduce<MenuProductType[]>((result, item) => {
          const menuItem = MenuService.getProductByScanCode(item.ScanCode);

          if (menuItem) {
            result?.push(menuItem);
          }

          return result;
        }, [])
        ?.slice(0, TransactionStore.getUpsellMaxItems());

      if (upsellItemsInMenu && upsellItemsInMenu.length > 0) {
        const items = upsellItemsInMenu?.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              accessible={true}
              accessibilityRole="button"
              accessibilityHint="Double tap to add item to your order"
              role="button"
              onPress={() => this.onAddItemClick(item)}
            >
              <View
                style={styles.upsellItem}
                accessibilityLabel={`${Localized.Buttons.add} ${
                  item.name
                } ${this.getPrice(item)}`}
                aria-label={`${Localized.Buttons.add} ${
                  item.name
                } ${this.getPrice(item)}`}
              >
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                  accessibilityLabel={this.getTitle(item)}
                  accessibilityRole="text"
                  aria-label={`${this.getTitle(item)}, text`}
                  style={[styles.upsellText]}
                >
                  {this.getTitle(item)}
                </AVText>
                <Icon
                  name="plus"
                  size={Styles.Fonts.f1}
                  color={Styles.balanceColor}
                />
              </View>
            </TouchableOpacity>
          );
        });
        return (
          <View style={styles.upsellContainer}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              accessible={true}
              accessibilityLabel={TransactionStore.getUpsellName()}
              aria-label={TransactionStore.getUpsellName()}
              style={styles.upsellTitleText}
            >
              {TransactionStore.getUpsellName()}
            </AVText>
            {items}
            <DashedLine
              dashGap={PixelRatio.roundToNearestPixel(4)}
              dashLength={PixelRatio.roundToNearestPixel(2)}
              dashThickness={1}
              dashColor={'#999'}
              style={styles.dashStyle}
            />
          </View>
        );
      }
    }

    return null;
  };

  getDiscountCodeView = () => {
    if (
      this.props?.location?.locationFeatures?.is365PayAfterTaxPromo &&
      !Settings.is365Pay()
    ) {
      return (
        <RoundedButton
          accessibilityHint={this.props.consumerRoleButton}
          buttonType={ButtonType.normal}
          onPress={() => this.props.addDiscountToCart?.()}
          accessible={true}
          accessibilityLabel={this.props.consumerRoleButton}
          containerStyle={styles.btnDiscountCode}
          accessibilityRole="button"
          aria-label={this.props.consumerRoleButton}
          role="button"
          text={this.props.consumerRoleButton || ''}
        />
      );
    }
    if (this.props?.hasConsumerRoles) {
      const discountCodeAccessibilityHintText =
        Platform.OS === 'web'
          ? Localized.Labels.enter_your_discount_code
          : Localized.Labels.scan_your_discount_code;
      if (this.state?.consumerRoleIdentifier?.value) {
        return (
          <View style={styles.btnView}>
            <View style={styles.btnDiscountCode}>
              <RoundedButton
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                accessible={true}
                accessibilityLabel={Localized.Buttons.use_discount_code}
                accessibilityRole="button"
                aria-label={Localized.Buttons.use_discount_code}
                role="button"
                buttonType={ButtonType.normal}
                onPress={this.props.validateDiscountCode}
                text={Localized.Buttons.use_discount_code}
              />
            </View>
            <AVTouchableOpacity
              accessible={true}
              accessibilityHint={discountCodeAccessibilityHintText}
              accessibilityRole="button"
              role="button"
              onPress={this.props.discountCodeButtonAction}
            >
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                accessibilityLabel={Localized.Buttons.edit_code}
                aria-label={Localized.Buttons.edit_code}
                style={[styles.btnEditCodeText]}
              >
                {Localized.Buttons.edit_code}
              </AVText>
            </AVTouchableOpacity>
          </View>
        );
      }
      return (
        <RoundedButton
          containerStyle={styles.btnDiscountCode}
          buttonType={ButtonType.normal}
          onPress={this.props.discountCodeButtonAction}
          accessible={true}
          accessibilityLabel={this.props.consumerRoleButton}
          accessibilityHint={discountCodeAccessibilityHintText}
          accessibilityRole="button"
          role="button"
          aria-label={this.props.consumerRoleButton}
          text={this.props.consumerRoleButton || ''}
        />
      );
    }
    return null;
  };

  render() {
    const items = this.getCartContents();
    let body: JSX.Element;
    if (items.length === 0) {
      body = (
        <EmptyCart
          style={styles.emptyContent}
          orderAhead={this.props.cartType === CartTypes.OrderAhead}
          onAddItem={this.props.onAddItem ?? (() => ({}))}
        />
      );
    } else {
      let taxSection: JSX.Element[] = [];

      if (
        this.state.transaction != null &&
        this.state.transaction.Taxes != null
      ) {
        taxSection = this.state.transaction.Taxes.map((cur, i) => (
          <View key={i} style={Styles.Style.row}>
            <View style={styles.columnLabel}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                style={[styles.defaultLabel]}
              >
                {getTaxDescription(Localized, cur.Description)}
              </AVText>
            </View>
            <View style={styles.columnAmount}>
              <AVFormattedCurrency
                currency={AccountStore.getCurrency()}
                accessible={true}
                style={[styles.defaultLabel]}
                value={parseFloat(cur.TaxAmount)}
                accessibilityLabel={`${getTaxDescription(
                  Localized,
                  cur.Description,
                )}, Amount $${parseFloat(cur.TaxAmount).toString()}`}
                accessibilityRole="text"
                aria-label={`${getTaxDescription(
                  Localized,
                  cur.Description,
                )}, Amount $${parseFloat(cur.TaxAmount).toString()}, text`}
              />
            </View>
          </View>
        ));
      }

      let deposit = 0;

      if (
        this.state.transaction != null &&
        this.state.transaction.Deposit != null
      ) {
        deposit = parseFloat(this.state.transaction.Deposit);
      }

      //TO DO --
      //if this item has sup, add to total before tax
      // let supTotalFee = 0;
      // if (item.transactioncharges) {
      //   item.transactioncharges.map((productFee) => {
      //     supTotalFee += productFee.transactionchargeamount;
      //   });
      // }
      // {item?.transactioncharges && this.getSUPFee(supTotalFee)}

      let due = 0;

      if (this.state.transaction && this.state.transaction.Due) {
        due = parseFloat(this.state.transaction.Due);
      }

      const subtotal = parseFloat(this.state.transaction?.FPSubTotal ?? '');
      const discount = parseFloat(
        this.state.transaction?.PromotionDiscount ?? '',
      );
      const afterTaxDiscount = parseFloat(
        this.state.transaction?.AfterTaxDiscount ?? '',
      );
      const nutritionDisplayColor =
        this.state.transaction?.NutritionDisplayColor;
      const nutritionDisplayLetter =
        this.state.transaction?.NutritionDisplayLetter;
      const nutritionCalorieTotal = parseFloat(
        this.state.transaction?.NutritionCalorieTotal ?? '',
      );
      const nutritionPromoName = this.state.transaction?.NutritionPromoName;
      const nutritionDiscountAmount = parseFloat(
        this.state.transaction?.NutritionDiscountAmount ?? '',
      );

      let onScreenDiscount = 0;

      if (this.state.transaction?.hasOwnProperty('OnScreenDiscount')) {
        onScreenDiscount = this.state.transaction?.OnScreenDiscount ?? 0;
      }

      let tenderDiscount = discount - onScreenDiscount;
      tenderDiscount = Math.round(tenderDiscount * 100) / 100;
      if (TransactionStore.isAfterTaxPromoApplied()) {
        tenderDiscount -= afterTaxDiscount;
      }
      tenderDiscount = Math.round(tenderDiscount * 100) / 100;
      let discountSection: JSX.Element | null = null;
      let discountRow: JSX.Element | false;

      if (tenderDiscount && tenderDiscount > 0) {
        discountSection = (
          <View style={styles.discountSection}>
            <DiscountSection
              {...this.props}
              discount={tenderDiscount}
              accountType="account"
              currency={AccountStore.getCurrency()}
            />
          </View>
        );
      }

      if (tenderDiscount && tenderDiscount > 0) {
        discountSection = (
          <View style={styles.discountSection}>
            <DiscountSection
              {...this.props}
              discount={tenderDiscount}
              accountType="account"
              currency={AccountStore.getCurrency()}
            />
          </View>
        );
      }

      discountRow = discount > 0 && (
        <View style={Styles.Style.row}>
          <View style={styles.columnLabel}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              style={[Styles.Style.summaryLabel, styles.discountLabel]}
            >
              {Localized.Labels.total_discounts}
            </AVText>
          </View>
          <View style={styles.columnAmount}>
            <AVFormattedCurrency
              currency={AccountStore.getCurrency()}
              style={[Styles.Style.summaryLabel, styles.discountLabel]}
              value={discount * -1}
              accessibilityLabel={`${Localized.Labels.discounts}, $${(
                discount * -1
              ).toString()}`}
              accessibilityRole="text"
              aria-label={`${Localized.Labels.discounts}, $${(
                discount * -1
              ).toString()}, text`}
            />
          </View>
        </View>
      );

      const nutritionCalorieTotalRow = nutritionCalorieTotal > 0 && (
        <View style={Styles.Style.row}>
          {renderColorNutritionComponent(
            nutritionDisplayColor,
            nutritionDisplayLetter,
            nutritionCalorieTotal,
          )}
        </View>
      );
      const nutritionDiscountRow = renderNutritionPromotionComponent(
        nutritionPromoName,
        nutritionDiscountAmount,
      );
      body = (
        <ScrollView
          ref={(view) => {
            this.cartScroller = view;
          }}
          style={styles.scrollList}
        >
          {items}
          <DashedLine
            dashGap={PixelRatio.roundToNearestPixel(4)}
            dashLength={PixelRatio.roundToNearestPixel(2)}
            dashThickness={PixelRatio.roundToNearestPixel(2)}
            dashColor={'#999'}
            style={[
              styles.dashStyle,
              {
                marginVertical: Styles.Spacing.m2,
              },
            ]}
          />

          {this.getUpsellItems()}

          <View style={styles.summaryContainer}>
            {nutritionCalorieTotalRow}
            {this.getDiscountCodeView()}

            {discountSection}
            <View style={styles.summaryTextContainer}>
              {nutritionDiscountRow}
              <View style={Styles.Style.row}>
                <View style={styles.columnLabel}>
                  <AVText
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                    style={[styles.defaultLabel]}
                  >
                    {Localized.Labels.subtotal}
                  </AVText>
                </View>
                <View style={styles.columnAmount}>
                  <AVFormattedCurrency
                    currency={AccountStore.getCurrency()}
                    accessible={true}
                    style={[styles.defaultLabel]}
                    value={subtotal}
                    accessibilityLabel={`${
                      Localized.Labels.subtotal
                    } $${subtotal.toString()}`}
                    accessibilityRole="text"
                    aria-label={`${
                      Localized.Labels.subtotal
                    } $${subtotal.toString()}, text`}
                  />
                </View>
              </View>
              {discountRow}

              {taxSection}
              {deposit > 0 && (
                <View style={Styles.Style.row}>
                  <View style={styles.columnLabel}>
                    <AVText
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                      style={[styles.defaultLabel]}
                    >
                      {Localized.Labels.bottle_deposit}
                    </AVText>
                  </View>
                  <View style={styles.columnAmount}>
                    <AVFormattedCurrency
                      accessible={true}
                      style={[styles.defaultLabel]}
                      value={deposit}
                      accessibilityLabel={`${
                        Localized.Labels.bottle_deposit
                      } $${deposit.toString()}`}
                      accessibilityRole="text"
                      aria-label={`${
                        Localized.Labels.bottle_deposit
                      } $${deposit.toString()}, text`}
                      currency={AccountStore.getCurrency()}
                    />
                  </View>
                </View>
              )}
              {this.renderGrandTotal(due)}
              {this.renderRemainingCredit()}
              <DashedLine
                dashGap={PixelRatio.roundToNearestPixel(4)}
                dashLength={PixelRatio.roundToNearestPixel(2)}
                dashThickness={PixelRatio.roundToNearestPixel(2)}
                dashColor={'#999'}
                style={styles.dashStyle}
              />
              {this.renderTotal(due)}
              <View style={styles.bottom} />
            </View>
          </View>
          {this.props.noteView}
        </ScrollView>
      );
    }

    return <View style={styles.container}>{body}</View>;
  }
}

const styles = StyleSheet.create({
  revolveTextLabelTotal: {
    fontSize: 32,
    fontFamily: Settings.isRefive()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
    fontWeight: '400',
  },
  bottom: {
    height: Styles.Spacing.m5 + Styles.Spacing.m1,
    width: '100%',
  },
  container: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: Styles.white,
    flex: 1,
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    marginBottom: Styles.Spacing.m2,
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    overflow: 'hidden',
    width: '100%',
  },
  discountLabel: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    fontWeight: '300',
  },
  discountSection: {
    flex: 1,
  },
  healthGrade: {
    // marginLeft: 10,
    // paddingTop: 10,
  },
  emptyContent: {
    alignSelf: 'stretch',
    flex: 1,
  },
  scrollList: {
    alignSelf: 'stretch',
    flex: 1,
    marginHorizontal: Styles.Spacing.m2,
  },
  summaryContainer: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    paddingRight: Styles.Spacing.m2,
  },
  summaryTextContainer: {
    flex: 1,
    marginTop: Styles.Spacing.m1,
  },
  upsellContainer: {
    alignSelf: 'stretch',
    flexDirection: 'column',
  },
  upsellTitleText: {
    fontWeight: '600',
    fontSize: Styles.Fonts.f2,
    paddingLeft: Styles.Spacing.m2,
  },
  upsellItem: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: Styles.Spacing.m1,
    paddingLeft: Styles.Spacing.m2,
  },
  upsellText: {
    marginRight: Styles.Spacing.m2,
    flex: 1,
    fontSize: Styles.Fonts.f1,
    fontWeight: '300',
    color: Styles.darkColor,
  },
  icon: {
    marginRight: Styles.Spacing.m2,
  },
  btnView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnDiscountCode: {
    alignSelf: 'flex-start',
    marginTop: Styles.Spacing.m2,
    marginRight: Styles.Spacing.m2,
  },
  btnEditCodeText: {
    color: Styles.primaryColor,
    marginLeft: Styles.Spacing.m1,
    textAlign: 'center',
    marginTop: Styles.Spacing.m2,
  },
  defaultLabel: {
    marginBottom: Styles.Spacing.m1,
    color: '#22212D',
    fontSize: 18,
    fontFamily: Settings.isRefive()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
    fontWeight: '400',
  },
  textFont: {
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  columnLabel: {
    flex: 4,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  columnAmount: {
    flex: 1,
    alignItems: 'flex-end',
  },
});

const ConnectedScanItems = connect((state: RootState) => ({
  creditCards: state.account.creditCards,
  consumerRoleIdentifier: state.account.account.consumerRoleIdentifier,
}))(ScanItems);
type WithGlobalizeType = {
  balance: number;
  trashClick?: (itemNumbers: Array<number>) => void;
  editClicked?: (item: CartItem) => void;
  showGrandTotal: boolean;
  hideYourItems: boolean;
  cartType: CartTypes;
  onAddItem?: () => void;
  locationId?: string;
  noteView?: React.ReactNode;
  preparationMethod: PreparationMethodValues;
  hasConsumerRoles: boolean;
  consumerRoleButton?: string;
  validateDiscountCode?: () => void;
  addDiscountToCart?: () => void;
  location?: LocationType;
  discountCodeButtonAction?: () => void;
} & WithGlobalizeProps;
export default withGlobalize<WithGlobalizeType>(ConnectedScanItems);
