// @ts-nocheck AnimatedQRCode
import React, {useState} from 'react';
import {
  Animated,
  PixelRatio,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Styles from '../../Styles';
import BalanceContainer from '../BalanceContainer';
import AnimatedLogo from './AnimatedLogo';
import AnimatedQRCode from './AnimatedQRCode';
import type {IsConnectedProps} from 'src/types/Screen';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import {WithLocalizedProps} from 'src/types/PropTypes';
const CONTAINER_HEIGHT = 250;

const HeaderBackground = ({
  animationRange,
}: {
  animationRange: Animated.AnimatedInterpolation<number>;
}) => {
  const animateHeader = {
    transform: [
      {
        translateY: animationRange.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 0],
          extrapolate: 'clamp',
        }),
      },
    ],
  };
  const animateOpacity = {
    opacity: animationRange.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
    }),
  };
  return (
    <Animated.View
      style={[styles.headerBackground, animateHeader, animateOpacity]}
    />
  );
};

export type PromotionsHeaderProps = IsConnectedProps &
  WithLocalizedProps & {
    animationScrollY: Animated.Value;
    balance: number;
    qrCode: unknown;
    scrollRangeForAnimation: number;
    hideBalance: boolean;
  };

const PromotionsHeader = ({
  animationScrollY,
  qrCode,
  strings,
  scrollRangeForAnimation,
}: PromotionsHeaderProps) => {
  const [{y: topBuffer}, setLayout] = useState({
    y: 100,
  });
  const animationRange: unknown = animationScrollY.interpolate({
    inputRange: [0, topBuffer, scrollRangeForAnimation + topBuffer],
    outputRange: [0, 0, 1],
    extrapolate: 'clamp',
  });
  const animateHeader = {
    transform: [
      {
        translateY: animationScrollY.interpolate({
          inputRange: [
            0,
            topBuffer,
            scrollRangeForAnimation + topBuffer,
            Number.MAX_SAFE_INTEGER,
          ],
          outputRange: [
            0,
            0,
            0,
            Number.MAX_SAFE_INTEGER - scrollRangeForAnimation - topBuffer,
          ],
          extrapolate: 'clamp',
        }),
      },
    ],
  };
  const animateOpacity = {
    opacity: animationRange.interpolate({
      inputRange: [0, 1],
      outputRange: [1, 0],
      extrapolate: 'clamp',
    }),
  };
  return (
    <Animated.View
      style={[styles.container, animateHeader]}
      onLayout={({nativeEvent: {layout}}) => setLayout(layout)}
    >
      <View style={styles.innerContainer}>
        <HeaderBackground animationRange={animationRange} />
        <AnimatedQRCode
          qrCode={qrCode}
          animationRange={animationRange}
          scrollRangeForAnimation={scrollRangeForAnimation}
          qrCodeSize={105}
          bottomMargin={0}
          scale={0.45}
        />
        <View style={styles.consumerRow}>
          <AnimatedLogo animationRange={animationRange} />
          <TouchableOpacity
            accessible={false}
            onPress={() => {
              NavActions.push(AppRoutes.Balances);
            }}
          >
            <BalanceContainer primaryColor strings={strings} />
          </TouchableOpacity>
        </View>
        <Animated.Image
          source={require('../../img/background.jpg')}
          style={[styles.backgroundImage, animateOpacity]}
          resizeMode="cover"
          accessibilityLabel="Promotion Image"
        />
        <LinearGradient
          colors={['#ffffff00', 'white']}
          style={styles.imageGradient}
        />
        <View style={styles.imageGradientBottom} />
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    flex: 1,
    height: '100%',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 100,
  },
  consumerRow: {
    alignItems: 'flex-end',
    alignSelf: 'stretch',
    bottom: Styles.Spacing.m2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    left: Styles.Spacing.m2,
    position: 'absolute',
    right: Styles.Spacing.m2 + Styles.Spacing.m1,
    zIndex: 102,
  },
  container: {
    height: CONTAINER_HEIGHT,
    zIndex: 1,
  },
  headerBackground: {
    backgroundColor: Styles.white,
    bottom: 0,
    left: 0,
    marginHorizontal: -Styles.Spacing.m2,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  imageGradient: {
    bottom: PixelRatio.roundToNearestPixel(64),
    flex: 1,
    height: PixelRatio.roundToNearestPixel(50),
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    width: '100%',
    zIndex: 101,
  },
  imageGradientBottom: {
    backgroundColor: Styles.white,
    borderBottomLeftRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    borderBottomRightRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    bottom: 0,
    flex: 1,
    height: PixelRatio.roundToNearestPixel(66),
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    width: '100%',
    zIndex: 101,
  },
  innerContainer: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: Styles.white,
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    elevation: 8,
    height: CONTAINER_HEIGHT,
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m2,
    shadowColor: Styles.darkColor,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: Styles.Spacing.m2,
  },
});
export default PromotionsHeader;
