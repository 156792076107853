import Styles from 'src/components/Styles';

export function bottomTabBtnColor(): string {
  return Styles.Colors.CompanyKitchen.blue;
}

export function getFooterNameTextDesc(isFocused): {
  [key: string]: {[key: string]: number | string};
} {
  return {
    footerNameText: {
      fontSize: 10,
      fontWeight: isFocused ? '700' : 'normal',
      textAlign: 'center',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
  };
}

export default {
  bottomTabBtnColor,
  getFooterNameTextDesc,
};
