import * as React from 'react';
import {
  TouchableOpacity,
  StyleSheet,
  GestureResponderEvent,
  View,
  Dimensions,
} from 'react-native';
import {Menu} from 'react-native-paper';
import Styles from '../Styles';

type TooltipState = {
  open: boolean;
  menuCoordinates: {
    x: number;
    y: number;
  };
};

interface TooltipProps {
  popover: React.ReactNode;
  children: React.ReactNode;
  width: number;
  backgroundColor: string;
  title?: string | string[];
  accessible?: boolean;
  accessibilityRole?: string;
}

class Tooltip extends React.Component<TooltipProps, TooltipState> {
  constructor(props: TooltipProps) {
    super(props);
    this.state = {
      open: false,
      menuCoordinates: {
        x: 0,
        y: 0,
      },
    };
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(event?: GestureResponderEvent) {
    const {nativeEvent} = event;
    if (nativeEvent) {
      this.setState((prevState) => {
        return {
          open: !prevState.open,
        };
      });
    }
  }

  // Handle the onLayout event to get the position of the anchor element
  handleLayout = (event) => {
    const {x, y} = event.nativeEvent.layout;
    this.setState({menuCoordinates: {x, y}});
  };

  render() {
    const {menuCoordinates} = this.state;
    const {width} = Dimensions.get('window'); // Get screen width

    return (
      <View
        accessible={false}
        onAccessibilityEscape={this.toggleTooltip}
        accessibilityLabel={`${this.props.title}`}
        accessibilityHint={'reads the title of the current screen'}
        accessibilityRole={'header'}
        onLayout={this.handleLayout}
      >
        <Menu
          visible={this.state.open}
          onDismiss={this.toggleTooltip}
          contentStyle={[
            styles.container,
            {
              backgroundColor: this.props.backgroundColor,
              maxWidth: this.props.width,
            },
            this.props.backgroundColor === 'transparent' && {
              display: 'none',
            },
          ]}
          anchor={this.state.menuCoordinates}
          style={{
            position: 'absolute',
            top: menuCoordinates.y + 80, // Adjust this as needed
            left: Math.min(menuCoordinates.x, width - 200), // Ensure menu does not overflow on the right
            zIndex: 1000, // Ensure the menu is on top of other components
            maxWidth: 250, // Optional: set max width for the menu
          }}
        >
          {this.props.popover}
        </Menu>
        <TouchableOpacity
          accessible={true}
          accessibilityRole={'header'}
          onPress={this.toggleTooltip}
          disabled={this.props.title?.length < 20 ? true : false}
        >
          {this.props.children}
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    padding: Styles.Spacing.m2,
    borderRadius: Styles.Spacing.m2,
  },
});
export default Tooltip;
