import React, {useState} from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  SafeAreaView,
  Platform,
} from 'react-native';
import Tooltip from './Tooltip';
import Styles from '../Styles';
import AVText from './AVText';
import {HeaderProps} from './Header';
import {getHeaderDescriber} from './headerDescriptor/DescriptorType';
import Settings from 'src/Settings';
const maxWidth = Dimensions.get('screen').width * 0.65;

const NewHeader: React.FC<HeaderProps> = (props: HeaderProps) => {
  const [isTextOverflowing, setIsTextOverflowing] = useState(false); // State to track if text overflows
  const textMargin =
    Platform.OS === 'ios'
      ? {
          marginTop: props.leftView ? 2 : 0,
        }
      : {marginTop: 0};

  let bottomView = null;
  if (props.bottomView) {
    bottomView = <View style={styles.bottomView}>{props.bottomView}</View>;
  }
  let leftView = props.leftView;
  if (!leftView) {
    leftView = null;
  }
  const {getHeaderBackground, headerContainerStyleDescriptor} =
    getHeaderDescriber();

  const contentView = () => {
    return getHeaderBackground(headerView(), props.onboardingHeader);
  };
  const handleTextLayout = (e) => {
    const {width} = e.nativeEvent.layout;

    // If text width exceeds maxWidth and title has more than 10 characters, show the tooltip

    const isOverflowing = width > maxWidth;

    const hasLongTitle = props.title?.length > 15;

    setIsTextOverflowing(isOverflowing && hasLongTitle);
  };

  const headerView = () => {
    return (
      <SafeAreaView
        style={
          props.onboardingHeader
            ? {backgroundColor: 'transparent'}
            : [headerContainerStyleDescriptor()['safeArea']]
        }
      >
        {props.content ? (
          props.content
        ) : (
          <View style={[styles.headerContainer, props.contentStyle]}>
            <View
              style={[
                styles.newLeftView,
                {
                  width: props.rightView ? '50%' : '80%',
                },
                props.onboardingHeader && {
                  marginLeft: -8,
                },
              ]}
            >
              {leftView}
              <Tooltip
                title={props.title}
                popover={
                  isTextOverflowing && (
                    <AVText numberOfLines={2} style={styles.tooltipText}>
                      {props.title}
                    </AVText>
                  )
                }
                backgroundColor={
                  isTextOverflowing ? Styles.darkColor : 'transparent'
                }
                width={maxWidth}
              >
                <AVText
                  accessible={true}
                  testID="headerTitleText"
                  accessibilityRole={'text'}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={[
                    headerContainerStyleDescriptor()['headerTextStyle'],
                    textMargin,
                    props?.leftView && {marginLeft: -Styles.Spacing.m2},
                    props.transparentHeader && {
                      color: Styles.Colors.PayNew.black01,
                    },
                    props.checkout && {
                      width: '100%',
                    },
                    props.checkout &&
                      props?.leftView &&
                      !Settings.isRefiveAnd365Pay() && {marginLeft: 0},
                  ]}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                  onLayout={handleTextLayout} // Measure the text width
                >
                  {props.title}
                </AVText>
              </Tooltip>
            </View>

            {props.rightView}
          </View>
        )}
      </SafeAreaView>
    );
  };

  return (
    <View
      style={[
        headerContainerStyleDescriptor()['container'],
        props.style,
        props.transparentHeader && {backgroundColor: 'transparent'},
      ]}
    >
      {contentView()}
      {bottomView}
      {props.children}
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: Styles.Heights.touchTargetHeight2,
    paddingHorizontal: Styles.Spacing.m3,
    paddingTop:
      Platform.OS === 'android' ? Styles.Heights.h2 : Styles.Spacing.m3,
    marginBottom:
      Platform.OS === 'android' ? Styles.Spacing.m2 : Styles.Spacing.m1 / 2,
    marginTop: Platform.OS === 'android' ? 22 : 0,
  },
  bottomView: {
    paddingBottom: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m3,
    backgroundColor: Styles.white,
    alignSelf: 'stretch',
    alignItems: 'flex-start',
  },
  newLeftView: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  tooltipText: {
    color: Styles.white,
    fontSize: Styles.Fonts.f1,
  },
});
export default NewHeader;
