import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const RightArrow = () => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <Path
      d="M18.797 12.797a1.12 1.12 0 000-1.589l-9-9.005a1.125 1.125 0 00-1.59 1.59l8.204 8.203-8.208 8.207a1.125 1.125 0 001.59 1.59l9.004-8.996z"
      fill="#fff"
    />
  </Svg>
);
export default RightArrow;
