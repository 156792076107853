import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, Rect} from 'react-native-svg';
import Settings from 'src/Settings';
interface IProps {
  color?: string;
  width?: number;
  height?: number;
}
const OffersTabIcon = ({
  color = Settings.isRefive() ? '#3070ED' : '#066DFF',
  width = 23,
  height = 26,
}: IProps) => (
  <Svg width={width} height={height} viewBox="0 0 23 26" fill="none">
    <G id="tag-regular" clipPath="url(#clip0_13099_1682)">
      <Path
        id="Vector"
        d="M10.1987 1.625C11.0335 1.625 11.8339 1.96523 12.4232 2.57461L21.0661 11.5121C22.2937 12.7816 22.2937 14.8383 21.0661 16.1078L14.5103 22.8871C13.2826 24.1566 11.2937 24.1566 10.0661 22.8871L1.42321 13.9496C0.829018 13.3402 0.5 12.5176 0.5 11.6543V4.0625C0.5 2.7168 1.5558 1.625 2.85714 1.625H10.1987ZM2.85714 11.6543C2.85714 11.8676 2.94062 12.0758 3.08795 12.2281L11.7308 21.1656C12.0353 21.4805 12.5362 21.4805 12.8406 21.1656L19.3964 14.3863C19.7009 14.0715 19.7009 13.5535 19.3964 13.2387L10.7536 4.30117C10.6062 4.14883 10.4049 4.0625 10.1987 4.0625H2.85714V11.6543ZM6 5.6875C6.41677 5.6875 6.81647 5.8587 7.11117 6.16345C7.40587 6.4682 7.57143 6.88152 7.57143 7.3125C7.57143 7.74348 7.40587 8.1568 7.11117 8.46155C6.81647 8.76629 6.41677 8.9375 6 8.9375C5.58323 8.9375 5.18353 8.76629 4.88883 8.46155C4.59413 8.1568 4.42857 7.74348 4.42857 7.3125C4.42857 6.88152 4.59413 6.4682 4.88883 6.16345C5.18353 5.8587 5.58323 5.6875 6 5.6875Z"
        fill={color}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_13099_1682">
        <Rect width={22} height={26} fill="white" transform="translate(0.5)" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default OffersTabIcon;
