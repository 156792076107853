import React from 'react';
import {View, StyleSheet, ScrollView, Platform, Linking} from 'react-native';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../ScreenContext';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import Util, {getClosestValue} from 'src/Util';
import NetInfo from '@react-native-community/netinfo';
import RoundedButton, {ButtonType} from '../elements/RoundedButton';
import withIsConnected from '../hoc/withIsConnected';
import Styles from '../Styles';
import type {IsConnectedProps} from 'src/types/Screen';
import ActionsFactory from 'src/actions/ActionsFactory';
import UIManager from '../elements/ui/UIManager';
import type {RewardType} from 'src/types/Rewards';
import Localized from 'src/constants/AppStrings';
import {alertError, alertSuccess} from '../helpers/AlertHelper';
import AVText from '../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import {RootState, store} from 'src/redux/store';
import {
  adjustDefaultBalance,
  adjustPoints,
} from 'src/redux/slices/accountSlice';
import {connect} from 'react-redux';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
import BaseScreen from 'src/components/screens/BaseScreen';
import Settings from 'src/Settings';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';

import {getDescriber} from 'src/components/screens/descriptor/DescriptorType';
import LoadingScreen from 'src/components/screens/LoadingScreen';
import OffersErrorView from 'src/components/elements/offers/OffersErrorView';
import {getDescriber as getSnackDescriber} from 'src/components/screens/sendSnack/descriptor/sendasnack/DescriptorType';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';

type RewardsLegacyScreenProps = IsConnectedProps &
  WithGlobalizeProps & {
    availablePoints: number;
    currentBalance: number;
    fulldata?: {[index: string]: {[index: string]: number | string}};
    navigation?: NavigationProp<RewardsLegacyScreen>;
  };
type RewardsLegacyScreenState = {
  rewardSteps: Array<RewardType>;
  availablePoints: number;
  currentBalance: number;
  previousRoute?: string | null;
  nextAvailablePoint: number;
  isConnected?: boolean;
};

class RewardsLegacyScreen extends React.Component<
  RewardsLegacyScreenProps,
  RewardsLegacyScreenState
> {
  redeeming: boolean;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: RewardsLegacyScreenProps) {
    super(props);
    this.state = {
      rewardSteps: [],
      availablePoints: props.availablePoints,
      currentBalance: props.currentBalance,
      previousRoute: null,
      nextAvailablePoint: 0,
      isConnected: true,
    };
    this.redeeming = false;
    this.redeemRewards = this.redeemRewards.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<RewardsLegacyScreenProps>): void {
    if (prevProps.currentBalance !== this.props.currentBalance) {
      this.setState({currentBalance: this.props.currentBalance});
    }
  }
  getNetworkState = async () => {
    const networkState = await NetInfo.fetch();
    this.setState({isConnected: networkState?.isConnected});
  };
  async componentDidMount() {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.getNetworkState();
    let rewardSteps;
    try {
      const response = AccountStore.getThresholdsResponse();

      if (response?.status === 'ok') {
        rewardSteps = response.data;
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'RewardsScreen:Constructor',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'RewardsScreen:Constructor',
        generateErrorMessage(error),
        guid,
      );
      alertError(Localized.Errors.error, guid);
    }

    this.setState(
      {
        rewardSteps,
        previousRoute,
      },
      () => {
        FirebaseAnalytic.trackEvent('componentDidMount', 'RewardsScreen', {
          ...this.props,
          ...this.state,
        });
        const fetchPointsArr = this.state.rewardSteps?.map(
          (item) => item.points,
        );
        if (fetchPointsArr && fetchPointsArr.length > 0) {
          const nextHighestVal = getClosestValue(
            this.state.availablePoints,
            fetchPointsArr,
          );
          this.setState({
            nextAvailablePoint: nextHighestVal - this.state.availablePoints,
          });
        }
      },
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.availablePoints !== prevState.availablePoints) {
      return {
        availablePoints: nextProps.availablePoints,
        currentBalance: nextProps.currentBalance,
      };
    }
  }

  async redeemRewards(points: number, redeemValue: number) {
    if (!this.redeeming) {
      try {
        this.redeeming = true;
        await ActionsFactory.getAccountActions().redeemRewards(
          AccountStore.getAccountId(),
          points,
          Util.getCurrentDate(),
        );
        Events.RewardsRedeem.trackEvent(points, redeemValue);
        alertSuccess(
          Localized.Success.formatString(
            Localized.Success.hasBeenAddedToYourAccount,
            Util.formatCurrency(
              this.props,
              redeemValue,
              AccountStore.getCurrency(),
            ),
          ),
          () => {
            store.dispatch(
              adjustDefaultBalance({
                amount: redeemValue,
                reason: Localized.Labels.redeem,
              }),
            );
            store.dispatch(adjustPoints({points}));
          },
        );
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'RewardsScreen:RedeemRewards',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'RewardsScreen:RedeemRewards',
          generateErrorMessage(error),
          guid,
        );
        alertError(Localized.Errors.error, guid);
      } finally {
        this.redeeming = false;
      }
    }
  }
  /**
   * Renders a view indicating no network connectivity.
   *
   * This function displays an error view with a message for network issues and provides
   * a button for the user to navigate to the account settings to resolve the connectivity problem.
   *
   * @returns {JSX.Element} A styled view with an error message and a navigation button.
   */
  renderNoConnectivity = () => (
    <View style={[styles.noOffersView]}>
      <OffersErrorView
        errorText={Localized.Errors.error_rewards_connection}
        buttonLabel={Localized.Buttons.go_to_settings_receipt}
        onPress={() => Linking.openSettings()}
      />
    </View>
  );
  render() {
    const isNewUi = Settings.isNewUI();
    let i = 0;
    const currency = AccountStore.getDisplayCurrency();

    const redeemList = this.state.rewardSteps?.map((reward) => {
      const available = reward.points <= this.state.availablePoints;
      return (
        <View
          key={i++}
          style={[styles.redeemList, available && styles.enableRedeemList]}
        >
          <View
            accessible={true}
            accessibilityRole={'text'}
            style={styles.amountAndCurrencyContainer}
          >
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              accessible={false}
              style={styles.currency}
            >
              {currency}
            </AVText>
            <AVText
              adjustsFontSizeToFit
              numberOfLines={1}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              accessible={false}
              style={styles.redeemAmount}
            >
              {reward.redeemvalue}
            </AVText>
          </View>

          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            style={styles.redeemExplanation}
          >
            {Localized.Labels.formatString(
              Localized.Labels.redeem_explanation,
              Util.formatNumber(this.props, reward.points, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              `${Util.formatCurrency(
                this.props,
                reward.redeemvalue,
                AccountStore.getCurrency(),
              )}`,
            )}
          </AVText>
          {this.props.isConnected && (
            <RoundedButton
              accessibilityLabel={`Redeem ${reward.points} points`}
              text={Localized.Labels.redeem}
              onPress={() =>
                this.redeemRewards(reward.points, reward.redeemvalue)
              }
              containerStyle={styles.btnStyle}
              disabled={!available}
              buttonType={ButtonType.outline}
            />
          )}
        </View>
      );
    });
    const payredeemList = this.state.rewardSteps?.map((reward) => {
      const available = reward.points <= this.state.availablePoints;
      return (
        <React.Fragment>
          <AVTouchableOpacity
            aria-label="onRewardsClick"
            accessible={true}
            accessibilityLabel="onRewardsClick"
            accessibilityHint="Double tap to navigate to the rewards screen"
            accessibilityRole="button"
            role="button"
          >
            <View
              style={[
                {
                  marginLeft: 6,
                  marginRight: 6,
                  height: Settings.isRefiveAnd365Pay() ? 90 : 100,
                },
                styles.container,
                {paddingHorizontal: 12, paddingVertical: 12},
                {flex: 1, flexDirection: 'row'},
              ]}
            >
              <View style={[styles.leftView]}>
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                  accessible={false}
                  style={[
                    {
                      fontSize: 20,
                      fontFamily: Styles.FontFamily.aeonikRegular,
                      fontWeight: '700',
                      color: '#111',
                    },
                    getSnackDescriber().snackDetailsBtnFontSTyle(),
                  ]}
                >
                  {Util.formatCurrency(
                    this.props,
                    reward.redeemvalue,
                    AccountStore.getCurrency(),
                  )}
                </AVText>
                <View>
                  <AVText
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                    style={[
                      styles.redeemExplanation,
                      {
                        fontSize: 16,
                        fontFamily: Styles.FontFamily.aeonikRegular,
                        fontWeight: '400',
                        color: '#111',
                        marginLeft: 0,
                        paddingTop: Settings.isRefiveAnd365Pay() ? 4 : 10,
                      },
                      getSnackDescriber().snackDetailsBtnFontSTyle(),
                    ]}
                  >
                    {Localized.Labels.formatString(
                      Localized.Labels.redeem_explanation,
                      Util.formatNumber(this.props, reward.points, {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      }),
                      `${Util.formatCurrency(
                        this.props,
                        reward.redeemvalue,
                        AccountStore.getCurrency(),
                      )}`,
                    )}
                  </AVText>
                </View>
              </View>
              <View
                style={[
                  styles.rightView,
                  {
                    marginVertical: Settings.isRefiveAnd365Pay() ? 10 : 20,
                    right: 5,
                  },
                ]}
              >
                {this.props.isConnected && (
                  <>
                    <RoundedAppButton
                      buttonViewStyle={{height: 32, width: 85}}
                      textStyle={[
                        styles.viewOfferbtnStyle,
                        getSnackDescriber().snackDetailsBtnFontSTyle(),
                      ]}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
                      isDisabled={!available}
                      inActiveButtonViewStyle={{
                        backgroundColor: Styles.Colors.PayNew.darkHuesBase04,
                        height: 32,
                        width: 85,
                      }}
                      inActiveTextStyle={{fontSize: Styles.Fonts.f1 - 2}}
                      buttonType={RoundedAppButtonType.Solid}
                      titleText={Localized.Labels.redeem}
                      onPress={() => {
                        if (available)
                          this.redeemRewards(reward.points, reward.redeemvalue);
                      }}
                      accessibilityLabelValue={Localized.Labels.redeem}
                      aria-label={Localized.Labels.redeem}
                    />
                  </>
                )}
              </View>
            </View>
          </AVTouchableOpacity>
        </React.Fragment>
      );
    });
    return (
      <BaseScreen
        title={Localized.Labels.rewards}
        previousRoute={this.state.previousRoute}
        accessibilityLabel={isNewUi ? Localized.Labels.rewards : 'Back arrow'}
        accessibilityHint={
          isNewUi
            ? Localized.Labels.rewards
            : `Press to navigate back to the ${this.state.previousRoute} screen`
        }
        rightView={null}
        hideBack={isNewUi}
      >
        {!this.state.isConnected && this.renderNoConnectivity()}
        {this.state.isConnected && (
          <>
            {this.state.rewardSteps?.length > 0 ? (
              <View style={styles.content}>
                <ScrollView style={styles.list}>
                  <View style={{marginHorizontal: Styles.Spacing.m3}}>
                    {getDescriber().renderYourBalanceRewardLbl(
                      styles,
                      AccountStore,
                    )}
                    {UIManager.getHomeRewardSection(
                      this.state.availablePoints,
                      this.state.rewardSteps,
                      styles.imageContainer,
                      false,
                    )}
                    {getDescriber().renderPointsToNextRewardLbl(
                      styles,
                      this.state.nextAvailablePoint,
                    )}
                  </View>
                  <View style={{paddingHorizontal: 16, paddingVertical: 16}}>
                    {payredeemList}
                  </View>
                </ScrollView>
              </View>
            ) : (
              <LoadingScreen />
            )}
          </>
        )}
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  btnStyle: {
    marginTop: Styles.Spacing.m2,
  },
  content: {
    flex: 1,
  },
  amountAndCurrencyContainer: {
    flex: 0.35,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: Styles.Spacing.m1,
  },
  currency: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  enableRedeemList: {
    borderBottomColor: Styles.positiveColor,
  },
  imageContainer: {
    height: 90,
    marginBottom: Styles.Spacing.m4,
    marginTop: -1 * Styles.Spacing.m2,
  },
  list: {
    flex: 1,
    //padding: Styles.Spacing.m2,
  },
  redeemAmount: {
    color: Styles.black,
    fontSize: Styles.Fonts.f4,
  },
  redeemExplanation: {
    flex: 1,
    flexWrap: 'wrap',
    fontSize: Styles.Fonts.f0,
    marginLeft: Styles.Spacing.m1,
    marginRight: Styles.Spacing.m3,
  },
  redeemList: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: Styles.Spacing.m3,
  },
  payBalance: {
    fontSize: 20,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: '#111',
    position: 'relative',
    width: '100%',
    paddingVertical: 4,
    left: 5,
  },
  container: {
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    marginHorizontal: 24,
    paddingHorizontal: Styles.Spacing.m3,
    borderRadius: Styles.Spacing.m2,
    justifyContent: 'center',
    marginBottom: Styles.Spacing.m3,
    ...Styles.Style.shadow,
  },
  leftView: {
    flex: 0.7,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rightView: {
    flex: 0.3,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  viewOffer: {
    borderRadius: 32,
    borderWidth: Platform.OS === 'android' ? 1.2 : 1,
    paddingVertical: 8,
    paddingHorizontal: 12,
    overflow: 'hidden',
  },
  viewOfferbtnStyle: {
    fontWeight: Platform.OS === 'android' ? 'bold' : '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f0 + 2,
    color: '#fff',
  },
  pointsToNextRwd: {
    fontSize: 15,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '400',
    color: '#111',
    left: 8,
    top: -10,
  },
  noOffersView: {
    flex: 1,
    alignItems: 'center',
    marginTop: 120,
  },
});

const ConnectedRewardsLegacyScreen = connect((state: RootState) => ({
  availablePoints: state.account.account.points,
  currentBalance: state.account.account.defaultBalance.balance,
  fulldata: state.account.account,
}))(RewardsLegacyScreen);

export default withForwardedNavigationParams()(
  withGlobalize(withIsConnected(ConnectedRewardsLegacyScreen)),
);
