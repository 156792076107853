import React from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  StyleProp,
  ViewStyle,
} from 'react-native';
import moment from 'moment';
import Localized from 'src/constants/AppStrings';
import Styles from '../../Styles';
import {ScreenProps} from 'src/types/Screen';
import {DealStatus} from 'src/services/aws/DealRepository';
import DealHelper from '../../helpers/DealHelper';
import Settings from 'src/Settings';
import {getDescriber} from '../descriptor/DescriptorType';
import ChevronRight from 'src/components/img/svg/ChevronRight';
import {GiftItem} from 'src/types/Snack';
import AVText from 'src/components/elements/AVText';
import UnReadlIcon from 'src/components/img/svg/UnReadIcon';
import {getNotificationDescriber} from 'src/components/elements/notificationDescriptor/DescriptorType';

type DealProps = ScreenProps & {
  giftItem: GiftItem;
  selectedItemIndex: number;
  onPress: (giftItem: GiftItem, selectedItemIndex: number) => void;
};
type DealState = {
  expired: boolean;
  completed: boolean;
};

const margin = Styles.Spacing.m3 + Styles.Spacing.m2;

class Deal extends React.Component<DealProps, DealState> {
  acceptingDeal = false;

  constructor(props: DealProps) {
    super(props);
    this.state = {
      expired: moment().isAfter(moment(props.giftItem.endDate)),
      completed: props.giftItem.dealStatus === DealStatus.redeemed,
    };
    this.renderClaimSection = this.renderClaimSection.bind(this);
    this.renderExpirationText = this.renderExpirationText.bind(this);
    this.acceptDeal = this.acceptDeal.bind(this);
    this.onPress = this.onPress.bind(this);
  }

  async acceptDeal() {
    if (!Settings.isRefiveAnd365Pay()) {
      const {...rest} = this.props;
      DealHelper.claimDeal(
        rest,
        this.props.giftItem.externalServiceId as string,
      );
    }
  }

  onPress() {
    if (this.props.onPress) {
      this.props.onPress(this.props.giftItem, 0);
    }
  }

  renderClaimSection() {
    if (this.props.giftItem.dealStatus === DealStatus.pulled) {
      return (
        <View
          style={[
            styles.claimDealBtn,
            {
              top: 10,
            },
          ]}
        >
          <TouchableOpacity
            style={[
              styles.claimButton,
              getNotificationDescriber().getDealClaimButtonViewStyle(),
            ]}
            onPress={this.acceptDeal}
            role="button"
            accessibilityLabel={Localized.Labels.claim}
            aria-label={Localized.Labels.claim}
            accessibilityRole="button"
          >
            <AVText
              style={[
                styles.claimBtnTxt,
                getNotificationDescriber().getDealClaimButtonTextStyle(),
              ]}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            >
              {Localized.Labels.claim}
            </AVText>
          </TouchableOpacity>
        </View>
      );
    } else if (this.props.giftItem.dealStatus === DealStatus.redeemed) {
      let text: string = Localized.Labels.redeemed_1_time;

      if (this.props.giftItem.redeemedCount > 1) {
        text = Localized.Labels.formatString(
          Localized.Labels.redeemed_x_times,
          this.props.giftItem.redeemedCount,
        ) as string;
      }

      return (
        <Text
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          style={[styles.dealTarget, styles.successText]}
        >
          {text}
        </Text>
      );
    }

    return null;
  }

  renderExpirationText() {
    const expirationProps = {
      styles,
      deal: this.props.giftItem,
      expired: this.state.expired,
    };
    return getDescriber().dealRenderExpirationTxtTemplate(expirationProps);
  }
  getCPDateStyle = (): StyleProp<ViewStyle> => {
    if (this.state.completed || this.state.expired) {
      return {left: 0, paddingRight: Styles.Spacing.m3, marginTop: -4};
    } else if (!this.props.giftItem.isRead) {
      return {left: 45, marginTop: -4};
    } else if (this.props.giftItem.isRead) {
      return {left: 22, marginTop: -4};
    }
  };
  renderArrow() {
    if (
      !Settings.isRefiveAnd365Pay() &&
      (this.state.expired || this.state.completed)
    ) {
      return null;
    }
    return (
      <>
        {!Settings.isRefiveAnd365Pay() ? (
          <View
            style={[
              getDescriber().dealStyleDescriptor()['arrow'],
              styles.arrow,
              styles.commonRow,
              !this.props.giftItem.isRead && {
                left: -10,
              },
            ]}
          >
            <View style={{left: -3}}>
              {!this.props.giftItem.isRead && (
                <UnReadlIcon
                  color={getNotificationDescriber().getUnreadMessageIconColor()}
                />
              )}
            </View>
            <ChevronRight />
          </View>
        ) : (
          <View
            style={getDescriber().getSnackItemStyleDescriptor()['chevronRight']}
            accessible
            accessibilityLabel="Snack Item Icon"
          >
            <ChevronRight />
          </View>
        )}
      </>
    );
  }

  render() {
    const describerProps = {
      styles,
      deal: this.props.giftItem,
      expired: this.state.expired,
    };
    return (
      <TouchableOpacity
        disabled={this.state.expired || this.state.completed}
        style={[
          getDescriber().dealStyleDescriptor()['container'],
          !Settings.isCanteen() && Styles.Style.shadow,
          !Settings.isRefiveAnd365Pay() && {
            borderBottomWidth: 1,
            borderBottomColor: '#9A9A9F',
          },
        ]}
        onPress={this.onPress}
      >
        {getDescriber().dealRenderDealIcon(describerProps)}
        <View style={styles.mainContent}>
          {!Settings.isRefiveAnd365Pay() && (
            <View style={styles.titleHeaderView}>
              <Text
                style={getDescriber().dealStyleDescriptor()['titleHeader']}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
                accessibilityLabel={Localized.Labels.offers}
              >
                {Localized.Labels.offers}
              </Text>
              <View style={[styles.dateRow]}>
                {this.renderExpirationText()}
              </View>
            </View>
          )}
          <View style={styles.topSection}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
              accessibilityLabel={getDescriber().dealRenderDealTopSection(
                describerProps,
              )}
              style={getDescriber().dealStyleDescriptor()['dealName']}
              numberOfLines={getDescriber().dealItemNumberDescriptor()}
            >
              {getDescriber().dealRenderDealTopSection(describerProps)}
            </AVText>
            {Settings.isRefiveAnd365Pay() && this.renderExpirationText()}
          </View>

          {getDescriber().dealRenderDealNameDesc(describerProps)}
          {!Settings.isRefiveAnd365Pay() && this.renderClaimSection()}
        </View>
        {this.renderArrow()}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  arrow: {
    width: 24,
    height: 24,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  container: {
    borderRadius: Styles.Spacing.m2,
    marginTop: margin,
    paddingHorizontal: Styles.Spacing.m3,
    flexDirection: 'row',
    paddingVertical: Styles.Spacing.m3,
    flex: 1,
    marginHorizontal: margin,
  },
  icon: {
    borderRadius: 32,
    width: 50,
    height: 50,
    backgroundColor: Styles.primaryColor,
    justifyContent: 'center',
    alignItems: 'center',
  },
  topSection: {
    flexDirection: 'row',
  },
  mainContent: {
    flex: 1,
  },
  rightSection: {
    justifyContent: 'space-between',
  },
  message: {
    color: Styles.black,
    flex: 1,
    fontSize: Styles.Fonts.f8,
    marginLeft: Styles.Spacing.m2 + Styles.Spacing.m1,
  },
  claimButton: {
    paddingHorizontal: 24,
    paddingVertical: 12,
    borderRadius: 32,
    borderWidth: 1,
    overflow: 'hidden',
  },
  dealTarget: {
    fontSize: Styles.Fonts.f1,
    color: Styles.lightGray,
    alignSelf: 'flex-start',
    marginTop: Styles.Spacing.m2,
    marginLeft: Styles.Spacing.m2 + Styles.Spacing.m1,
  },
  successText: {
    color: Styles.blueRevolve,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  claimBtnTxt: {
    fontSize: 16,
    fontWeight: '700',
    textAlign: 'center',
    fontFamily: Settings.isRefive()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
  },
  claimDealBtn: {
    alignSelf: 'flex-end',
  },
  commonRow: {
    flexDirection: 'row',
  },
  dateRow: {
    flex: 1,
    marginBottom: 2,
    alignSelf: 'flex-end',
  },
  titleHeaderView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
});
export default Deal;
