import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  color?: string;
  width?: number;
  height?: number;
}
const CancelIcon = ({color = '#727179', width = 24, height = 24}: IProps) => (
  <Svg width={width} height={height} fill="none">
    <Path
      fill={color}
      fillRule="evenodd"
      d="m22 4-2-2-8 8-8-8-2 2 8 8-8 8 2 2 8-8 8 8 2-2-8-8 8-8Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default CancelIcon;
