import * as React from 'react';
import CreditCardImage from './CreditCardImage';
import Localized from 'src/constants/AppStrings';
import AppRoutes from 'src/AppRoutes';
import NavActions from 'src/actions/NavActions';
import AccountConstants from 'src/constants/AccountConstants';
import NBDropdown from './NBDropdown';
import {CreditCard} from 'src/models/CreditCard';
import Settings from 'src/Settings';
import {getDescriber} from './descriptor/DescriptorType';
import {MenuOption} from 'src/types/MenuOption';
import {Dimensions, Platform} from 'react-native';

type PaymentMethodDropdownProps = {
  cards: Array<CreditCard>;
  applePayAvailable: boolean;
  googlePayAvailable: boolean;
  payrollAvailable: boolean;
  showAddNewPayment: boolean;
  value: string;
  anchorPosition: string;
  onSelect: (value: string) => void;
  onVisibilityChange?: (visible: boolean) => void;
};
const PAYROLL = 'PAYROLL';
export default class PaymentMethodDropdown extends React.Component<PaymentMethodDropdownProps> {
  static defaultProps = {
    showAddNewPayment: true,
  };

  constructor(props: PaymentMethodDropdownProps) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(value: string) {
    if (value === AccountConstants.ADD_PAYMENT_TOKEN) {
      if (Settings.isNewUI() && Platform.OS !== 'web') {
        /**
         * SOS-55790 fix - replace cards to PDcards
         */
        this.props.onVisibilityChange(false);
        this.props.onSelect(value);
        getDescriber().navigateToWalletScreen();
      } else {
        NavActions.nestedPush(
          [AppRoutes.Main, AppRoutes.Account],
          AppRoutes.PDCards,
          {hideBackArrow: false},
        );
      }
    } else {
      this.props.onSelect(value);
    }
  }

  cardIssuerMap(cardIssuerName?: string) {
    if (cardIssuerName?.toLowerCase().indexOf('american') !== -1) {
      return 'AMEX';
    }
    return cardIssuerName;
  }

  formatCardName(str = '') {
    const vis = str?.slice(-4);
    let countNum = '';

    for (let i = 12; i > 0; i--) {
      countNum += '*';
    }
    return countNum + vis;
  }
  render() {
    const {addNewPaymentTemplateDescriptor} = getDescriber();
    const cardOptions: MenuOption[] = this.props.cards?.map((card) => ({
      value: card.id,
      id: Settings.isRefiveAnd365Pay()
        ? this.cardIssuerMap(card.cardIssuer)
        : card.cardIssuer,
      text: this.formatCardName(card?.name),
      image: <CreditCardImage issuer={card.cardIssuer} />,
    }));

    if (this.props.applePayAvailable) {
      cardOptions.unshift({
        value: AccountConstants.APPLE_PAY_TOKEN,
        text: AccountConstants.APPLE_PAY_LABEL as string,
        image: (
          <CreditCardImage
            issuer={AccountConstants.APPLE_PAY_TOKEN as string}
          />
        ),
      });
    }

    if (this.props.payrollAvailable) {
      cardOptions.unshift({
        value: AccountConstants.PAYROLL_TOKEN,
        text: AccountConstants.PAYROLL_LABEL as string,
        image: (
          <CreditCardImage issuer={AccountConstants.PAYROLL_TOKEN as string} />
        ),
      });
    }

    if (this.props.showAddNewPayment) {
      cardOptions.push({
        value: AccountConstants.ADD_PAYMENT_TOKEN,
        text: Localized.Labels.add_new_payment,
        image: addNewPaymentTemplateDescriptor(),
      });
    }

    if (this.props.googlePayAvailable) {
      cardOptions.unshift({
        value: AccountConstants.GOOGLE_PAY_TOKEN,
        text: AccountConstants.GOOGLE_PAY_LABEL as string,
        image: (
          <CreditCardImage
            issuer={AccountConstants.GOOGLE_PAY_TOKEN as string}
          />
        ),
      });
    }
    return (
      <NBDropdown
        dropDownHeader={
          Settings.isNewUI() ? Localized.Labels.payment : undefined
        }
        menuContainerStyle={
          Platform.OS !== 'web' && {
            width: Dimensions.get('screen').width - 100,
          }
        }
        accessibilityLabel={Localized.Labels.payment_method_drop_down}
        accessibilityRole="menu"
        accessibilityValue={{text: this.props.value}}
        aria-label={Localized.Labels.payment_method_drop_down}
        role="menu"
        anchorPosition={this.props.anchorPosition}
        onVisibilityChange={this.props.onVisibilityChange}
        label={Localized.Labels.payment}
        options={cardOptions}
        onSelect={this.onSelect}
        selectedValue={
          this.props.value === PAYROLL
            ? AccountConstants.PAYROLL_TOKEN
            : this.props.value
        }
      />
    );
  }
}
