import moment from 'moment';
import React, {useState, useEffect, useContext} from 'react';
import {
  View,
  FlatList,
  StyleSheet,
  RefreshControl,
  Platform,
  Linking,
} from 'react-native';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import AVText from 'src/components/elements/AVText';
import OfferItem from 'src/components/elements/offers/OfferItem';
import ScreenContext from 'src/components/ScreenContext';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import Events, {PromotionActions} from 'src/logging/Events';
import DealModel from 'src/models/Moblico/Deal';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import DealService from 'src/services/DealService';
import AccountStore from 'src/stores/AccountStore';
import PersistentStore from 'src/services/PersistentStoreService';
import uuid from 'src/nativeModules/UUID';
import MoblicoTokenService from 'src/services/MoblicoTokenService';
import MoblicoApi from 'src/api/MoblicoApi';
import Header from 'src/components/elements/Header';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import NetInfo from '@react-native-community/netinfo';
import {offersTheme} from './offersHelper';
import OffersTabIcon from 'src/components/img/svg/OffersTabIcon';
import OffersErrorView from 'src/components/elements/offers/OffersErrorView';
import {NavigationProp} from '@react-navigation/native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {useFocusEffect} from '@react-navigation/native';
import OffersNote from 'src/components/elements/offers/OffersNote';

type OfferScreenProps = {
  navigation?: NavigationProp<OfferScreenProps>;
  fromOfferDetails?: boolean;
};

export const OffersButtonStates = {
  default: 'default',
  loading: 'loading',
  applied: 'applied',
};

const OfferScreen: React.FC<OfferScreenProps> = (props) => {
  const context = useContext(ScreenContext);
  const [showNote, setShowNote] = useState<boolean>(true);
  const [offersData, setOffersData] = useState<DealModel[]>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [itemStates, setItemStates] = useState({});
  const [isConnected, setIsConnected] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);
  const [genericError, setGenericError] = useState<boolean>(false);

  useEffect(() => {
    loadPersistedValue();
    setRefreshing(true);
    getNetworkState();
    getOffers();
    FirebaseAnalytic.trackEvent('useEffect', 'OfferScreen: useEffect', {
      ...props,
    });
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      if (props.fromOfferDetails) {
        onPulltoRefresh();
      }
    }, [props.fromOfferDetails]),
  );

  const getNetworkState = async () => {
    const networkState = await NetInfo.fetch();
    setIsConnected(networkState?.isConnected);
  };

  /** Retrieve the value from persistent storage to display note. */
  const loadPersistedValue = async () => {
    try {
      const accountId = AccountStore.getAccountId();

      const value = await PersistentStore.getOffersNote();
      if (value !== null) {
        // Assuming the value is an object with accountIds as keys
        const specificValue = value[accountId];
        if (typeof specificValue !== 'undefined') {
          setShowNote(specificValue);
          FirebaseAnalytic.trackEvent(
            'loadPersistedValue',
            'OfferScreen: loadPersistedValue',
            {
              ...props,
              showNote: specificValue,
            },
          );
        }
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'OfferScreen:loadPersistedValue',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'OfferScreen:loadPersistedValue',
        e.message ? e.message : e.toString(),
      );
    }
  };

  /**
   * Initiates a call to the 'Get Offers' API to retrieve the list of offers.
   * It triggers a loading state at the start and removes it upon completion or error.
   * Analytics tracking is performed to monitor the operation's usage and errors.
   */
  const getOffers = async () => {
    try {
      context.actions.showSpinner();
      setServerError(false);
      setGenericError(false);
      await getOffersList();
      FirebaseAnalytic.trackEvent('getOffers', 'OfferScreen: getOffers', {
        ...props,
      });
    } catch (e) {
      setServerError(true);
      setRefreshing(false);
      CrashlyticsEvents.log(
        'Exception',
        'OfferScreen:getOffers',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'OfferScreen:getOffers',
        e.message ? e.message : e.toString(),
      );
    } finally {
      setRefreshing(false);
      setServerError(false);
      context.actions.hideSpinner();
    }
  };
  /** function to sort dates. */
  const getSortDate = (item: DealModel) => {
    return moment(item.endDate).valueOf();
  };

  /** function that handles a call to the 'Get Offers' API. */
  const getOffersList = async () => {
    let deals: DealModel[] = [];
    if (
      AccountStore.getConsumerEngagementId() &&
      AccountStore.isConsumerEngagementEnabled()
    ) {
      await DealService.loadMoblicoDeals(AccountStore.getEmail());
      deals = await DealService.getDeals();
    }

    try {
      validateDealResponse(deals);
    } catch (validationError) {
      setGenericError(true);
      CrashlyticsEvents.log(
        'ValidationError',
        'OfferScreen:getOffersList',
        validationError.message,
      );
      Events.Error.trackEvent(
        'ValidationError',
        'OfferScreen:getOffersList',
        validationError.message,
      );
      return;
    }

    const activeDeals = deals.filter((deal) => DealService.isDealActive(deal));

    activeDeals.sort((a: DealModel, b: DealModel) => {
      const bDate = getSortDate(b);
      const aDate = getSortDate(a);
      return aDate - bDate;
    });
    FirebaseAnalytic.trackEvent('getOffersList', 'OfferScreen: getOffersList', {
      ...props,
      ...activeDeals,
    });
    setOffersData(activeDeals);
  };

  /**
   * Validates the API response to ensure it's a proper array with essential fields.
   * @param response - The API response to validate.
   * @returns DealModel[] - An array of validated Deal objects.
   * @throws Error - If the response is not an array or contains invalid items.
   */
  const validateDealResponse = (response: DealModel[]): void => {
    if (!Array.isArray(response)) {
      throw new Error('Invalid API response: Expected an array.');
    }
    const hasValidDeals = response.every(
      (deal) =>
        deal &&
        (typeof deal?.externalServiceId === 'string' ||
          typeof deal?.externalServiceId === 'number') &&
        (typeof deal?.endDate === 'string' ||
          (typeof deal?.endDate === 'number' && deal?.endDate > 0)),
    );
    if (!hasValidDeals) {
      throw new Error(
        'Invalid API response: Some items are missing essential fields.',
      );
    }
  };

  /** Initiates data refresh when the user performs a pull-to-refresh action. */
  const onPulltoRefresh = async () => {
    FirebaseAnalytic.trackEvent(
      'onPulltoRefresh',
      'OfferScreen: onPulltoRefresh',
      {
        ...props,
      },
    );
    getOffers();
    getNetworkState();
  };

  /** Handles the API call to apply for an offer and updates relevant states accordingly. */
  const onQuickApplyPress = async (offerItem) => {
    /** updating button content state */
    setItemStates((prevStates) => ({
      ...prevStates,
      [offerItem.externalServiceId]: OffersButtonStates.loading,
    }));
    getNetworkState();
    try {
      const token = await MoblicoTokenService.getToken(AccountStore.getEmail());
      await MoblicoApi.acceptDeal(
        token,
        offerItem.externalServiceId.toString(),
      );
      Events.Promotion.trackEvent(
        offerItem.externalServiceId.toString(),
        offerItem.name,
        PromotionActions.ClaimedDeal,
      );
      /** updating button content state */
      setItemStates((prevStates) => ({
        ...prevStates,
        [offerItem.externalServiceId]: OffersButtonStates.applied,
      }));

      FirebaseAnalytic.trackEvent(
        'onQuickApplyPress',
        'OfferScreen: onQuickApplyPress',
        {
          ...props,
          ...offerItem,
        },
      );
    } catch (error) {
      /** updating button content state */
      setItemStates((prevStates) => ({
        ...prevStates,
        [offerItem.externalServiceId]: OffersButtonStates.default,
      }));
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'OffersScreen:onQuickApplyPress',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'OffersScreen:onQuickApplyPress',
        generateErrorMessage(error),
        guid,
      );
    } finally {
      await getOffersList();
    }
  };

  const onGotoSettingsPress = () => {
    if (Platform.OS === 'ios') {
      Linking.openURL('app-settings:');
    } else {
      Linking.openSettings();
    }
  };

  /**
   * Navigates to the Offer Details Screen with the selected offer item.
   * @param {Object} selectedOfferItem - The offer item that was selected by the user.
   */
  const onCardPress = (selectedOfferItem) => {
    getNetworkState();
    NavActions.push(AppRoutes.OfferDetailsScreen, {
      selectedOfferItem,
    });
  };

  /**
   * Asynchronously closes the offer note by updating its visibility state.
   * It sets the 'OffersNote' value in persistent storage to false, effectively hiding the note,
   * and updates the local state to reflect this change.
   */
  const closeNote = async () => {
    const newValue = false;
    await PersistentStore.setOffersNote({
      [AccountStore.getAccountId()]: newValue,
    });
    setShowNote(newValue);
  };

  /**
   * Renders an individual offer item within a list or grid.
   * Determines the current state of the offer's action button (e.g., default, loading, applied)
   * based on the item's status in the `itemStates` object. It then passes the offer item,
   * along with handlers for applying to the offer and opening its details, to the `OfferItem` component.
   *
   * @param {Object} {item} - The current offer item being rendered, provided by the list's rendering method.
   * @returns The `OfferItem` component populated with the offer's data and necessary action handlers.
   */
  const renderOfferItem = ({item, index}) => {
    const currentButtonState =
      itemStates[item.externalServiceId] || OffersButtonStates.default;
    return (
      <OfferItem
        offerItem={item}
        onQuickApplyPress={(offerItem) => onQuickApplyPress(offerItem)}
        openModal={onCardPress}
        buttonState={currentButtonState}
        index={index}
      />
    );
  };

  /**
   * Renders a view indicating no network connectivity.
   *
   * This function displays an error view with a message for network issues and provides
   * a button for the user to navigate to the account settings to resolve the connectivity problem.
   *
   * @returns {JSX.Element} A styled view with an error message and a navigation button.
   */
  const renderNoConnectivity = () => (
    <View style={[styles.noOffersView]}>
      <OffersErrorView
        errorText={Localized.Errors.offers_network_error}
        buttonLabel={Localized.Buttons.go_to_settings}
        onPress={onGotoSettingsPress}
      />
    </View>
  );

  /**
   * Renders a view indicating a server error.
   *
   * This function displays an error view when there is an issue retrieving offers from the server.
   * It also includes a button that allows users to retry the operation by refreshing the data.
   *
   * @returns {JSX.Element} A styled view with a server error message and a retry button.
   */
  const renderServerError = () => (
    <View style={[styles.noOffersView]}>
      <OffersErrorView
        errorText={Localized.Errors.offers_server_error}
        buttonLabel={Localized.Buttons.tap_to_retry}
        onPress={() => onPulltoRefresh()}
      />
    </View>
  );

  /**
   * Renders a view for generic API response errors.
   *
   * This function displays an error view when the API response cannot be parsed.
   *
   * @returns {JSX.Element} A styled view with an error message.
   */
  const renderGenericError = () => (
    <View style={[styles.noOffersView]}>
      <OffersErrorView
        errorText={Localized.Errors.offers_generic_error}
        buttonLabel={Localized.Buttons.tap_to_retry}
        onPress={() => onPulltoRefresh()}
      />
    </View>
  );

  /**
   * Renders a view indicating no available offers.
   *
   * This function displays a message and an icon when the offers list is empty.
   * It ensures that the "No Offers" view is only shown when data is not refreshing.
   *
   * @returns {JSX.Element} A styled view with a no-offers message and an accompanying icon.
   */
  const renderNoOffers = () => {
    return (
      <>
        {!refreshing && (
          <View style={[styles.noOffersView, {top: '30%'}]}>
            <OffersTabIcon width={64} height={74} />
            <AVText
              accessible={true}
              accessibilityLabel={Localized.Labels.no_current_offers}
              aria-label={Localized.Labels.no_current_offers}
              accessibilityRole="text"
              role="presentation"
              style={styles.noOfferText}
            >
              {Localized.Labels.no_current_offers}
            </AVText>
          </View>
        )}
      </>
    );
  };

  return (
    <Header
      title={Localized.Labels.offers}
      accessibilityLabel={Localized.Labels.offers}
    >
      <View style={[styles.offerContainer]}>
        {!isConnected && renderNoConnectivity()}

        {serverError && renderServerError()}
        {genericError && renderGenericError()}
        {isConnected &&
          !serverError &&
          !genericError &&
          offersData.length === 0 &&
          renderNoOffers()}
        {isConnected &&
          !serverError &&
          !genericError &&
          offersData.length > 0 && (
            <>
              {showNote && <OffersNote onPressClose={closeNote} />}
              <FlatList
                data={offersData}
                renderItem={renderOfferItem}
                numColumns={2}
                columnWrapperStyle={{
                  justifyContent: 'space-between',
                }}
                keyExtractor={(item) => item.externalServiceId}
                refreshControl={
                  <RefreshControl
                    accessible={true}
                    accessibilityLabel={'Searching Offers'}
                    accessibilityState={{busy: refreshing}}
                    aria-label={'Searching Offers'}
                    aria-busy={refreshing}
                    refreshing={refreshing}
                    onRefresh={onPulltoRefresh}
                  />
                }
              />
            </>
          )}
      </View>
    </Header>
  );
};

const styles = StyleSheet.create({
  offerContainer: {
    flex: 1,
    backgroundColor: Styles.tabBarBackgroundColor,
  },
  modalView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 25,
    marginRight: 25,
  },
  noOffersView: {
    flex: 1,
    alignItems: 'center',
    marginTop: 100,
  },
  noOfferText: {
    textAlign: 'center',
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    color: offersTheme?.textColor,
    fontFamily: offersTheme?.textFontFamily,
  },
});

export default withForwardedNavigationParams<OfferScreenProps>()(OfferScreen);
