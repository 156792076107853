import React from 'react';
import Localized from 'src/constants/AppStrings';
import {StyleProp, ViewStyle} from 'react-native';
import NBRDropdown from 'src/components/elements/NBRDropdown';

type GenderDropdownProps = {
  selectedValue: string;
  style?: StyleProp<ViewStyle>;
  accessibilityValue?: {[key: string]: string};
  onSelect: (value: string) => void;
  onVisibilityChange: (visible: boolean) => void;
};

const GenderDropdown = (props: GenderDropdownProps) => {
  return (
    <NBRDropdown
      label={Localized.Labels.gender}
      selectedValue={props.selectedValue}
      style={props.style}
      accessible={true}
      accessibilityLabel={Localized.Labels.gender}
      accessibilityRole="menu"
      accessibilityValue={{text: props.selectedValue}}
      aria-label={Localized.Labels.gender}
      onSelect={props.onSelect}
      onVisibilityChange={props.onVisibilityChange}
      options={[
        {
          value: 'Male',
          text: Localized.Labels.male,
        },
        {
          value: 'Female',
          text: Localized.Labels.female,
        },
        {
          value: 'Other',
          text: Localized.Labels.other,
        },
      ]}
    />
  );
};

export default GenderDropdown;
