import Styles from 'src/components/Styles';

export function getStyleWithColorOnly(): {[index: string]: string} {
  return {
    backgroundColor: 'rgba(164, 219, 87, 0.30) 0%',
    color: Styles.darkBlueRevolveColor,
    allReadTextUnderLine: Styles.Colors.PayNew.primary01,
  };
}

export function snackBarColorDescriptor(): string {
  return Styles.Colors.Refive.notificationBorderColor;
}

export function getToastTextStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    allReadText: {
      fontSize: Styles.Fonts.f1,
      paddingTop: Styles.Spacing.m1,
      color: Styles.Colors.Refive.activeFooterBlue,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '700',
    },
  };
}

export function getRightReadMarkIconColor(): string {
  return Styles.Colors.Refive.activeFooterBlue;
}
export function snackBarFontFamily(): string {
  return Styles.FontFamily.figtreeRegular;
}

export default {
  getStyleWithColorOnly,
  snackBarColorDescriptor,
  getToastTextStyleDescriptor,
  getRightReadMarkIconColor,
  snackBarFontFamily,
};
