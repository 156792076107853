import React from 'react';
import {StyleSheet, View, Dimensions, FlatList} from 'react-native';
import Styles from '../../Styles';
import AVText from 'src/components/elements/AVText';
import Localized from 'src/constants/AppStrings';
import RecentLocationView, {
  RecentLocationType,
} from 'src/components/elements/home/RecentLocationView';
import {IsConnectedProps} from 'src/types/Screen';
import Settings from 'src/Settings';
import {getDescriber} from './descriptor/DescriptorType';

type RecentLocationsListProps = IsConnectedProps & {
  locations: RecentLocationType[];
};
const {getCampusLocationStyles} = getDescriber();
const width = Dimensions.get('window').width;

const RecentLocationsList = (props: RecentLocationsListProps) => {
  //Returns Recent Location Item UI
  const renderItem = ({item, index}) => {
    return (
      <RecentLocationView
        recentLocation={item}
        showBorder={index !== props.locations.length - 1}
      />
    );
  };

  //Don't render recent list view when there are no recent locations
  if (props.locations.length <= 0) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerView}>
        <AVText
          style={[
            getCampusLocationStyles()['descriptionText'],
            styles.headerText,
          ]}
          accessible={true}
          accessibilityLabel={Localized.Labels.recents}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.recents}, text`}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        >
          {Localized.Labels.recents}
        </AVText>
      </View>
      <FlatList
        contentContainerStyle={[
          getCampusLocationStyles()['locationListView'],
          Settings.isRefiveAnd365Pay() && styles.tileDesign,
          getCampusLocationStyles()['recentListborder'],
        ]}
        data={props.locations}
        keyExtractor={(item, index) => {
          return index + (item.campusId ?? item.locationId) + item.name;
        }}
        scrollEnabled={false}
        renderItem={renderItem}
        ItemSeparatorComponent={() => (
          <View style={getCampusLocationStyles()['separator']} />
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  tileDesign: {
    borderColor: Styles.white,
    borderRadius: Styles.Spacing.m2,
    ...Styles.Style.shadow,
  },
  separator: {
    width: 288,
    height: 1,
    backgroundColor: '#CFCFCF',
    marginLeft: 50,
  },
  headerView: {
    width: width,
  },
  headerText: {
    color: Styles.black,
    fontSize: Styles.Fonts.sectionHeader,
    fontWeight: '700',
    marginHorizontal: Styles.Spacing.m3,
  },
});
export default RecentLocationsList;
