import {StatusBarStyle, StyleProp, ViewStyle} from 'react-native';
import Styles from 'src/components/Styles';

export function shadowStyleDescriptor(): {
  [key: string]: StyleProp<ViewStyle>;
} {
  return {
    shadow: {
      backgroundColor: '#FFFFFF',
      borderBottomWidth: 0,
      elevation: 6,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.2,
      shadowRadius: 4,
    },
    lightShadow: {
      backgroundColor: '#FFFFFF',
      borderBottomWidth: 0,
      elevation: 3,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.1,
      shadowRadius: 2,
    },
  };
}
export function statusBarStyleDescriptor(): StatusBarStyle {
  return 'light-content';
}
export function welcomeScreenstatusBarStyleDescriptor(): StatusBarStyle {
  return 'dark-content';
}

export function statusBarBackgroundColorDescriptor(): string {
  return Styles.darkBlueRevolveColor;
}

export default {
  shadowStyleDescriptor,
  statusBarStyleDescriptor,
  statusBarBackgroundColorDescriptor,
  welcomeScreenstatusBarStyleDescriptor,
};
