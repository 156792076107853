import React from 'react';
import {StyleSheet, View} from 'react-native';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import AVText from '../AVText';
import {ensureHttps} from 'src/services/StringUtils';
import {ImageWithPlaceholder} from 'src/components/elements/rewards/RedeemPoints4Rewards';
import {RewardsIcon} from 'src/components/img/svg/rewards';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import {getButtonDescriber} from 'src/components/elements/buttonDescriptor/DescriptorType';
import {getDescriber as getSnackDescriber} from 'src/components/screens/sendSnack/descriptor/sendasnack/DescriptorType';
import {ButtonType} from 'src/components/elements/RoundedButton';

type RedeemTabProps = {
  productName: string;
  point: number;
  imageUrl: string;
  upc?: string[];
  cancel: () => void;
};
const lineHeightMultiplier = 1.14;
const RedeemTab = (props: RedeemTabProps) => {
  const {point, imageUrl = '', productName, upc} = props;
  return (
    productName && (
      <View style={styles.container}>
        <View style={styles.titleHead}>
          <AVText
            style={[
              styles.redeemRwd,
              getSnackDescriber()?.snackDetailsBtnFontSTyle(),
            ]}
            accessibilityLabel={Localized.Labels.redeem_rewd}
          >
            {Localized.Labels.redeem_rewd}
          </AVText>
        </View>

        <View style={styles.logoView}>
          <ImageWithPlaceholder
            source={{uri: ensureHttps(imageUrl || 'default')}}
            width={67}
            height={67}
          >
            <RewardsIcon />
          </ImageWithPlaceholder>
        </View>

        <View style={[styles.productNameHead]}>
          <AVText
            style={[
              styles.pdtName,
              getSnackDescriber()?.snackDetailsBtnFontSTyle(),
              !imageUrl && {
                top: -15,
              },
            ]}
            accessibilityLabel={productName}
          >
            {productName}
          </AVText>
        </View>
        <View
          style={[
            {top: -10},
            !imageUrl && {
              top: -25,
            },
          ]}
        >
          <AVText
            style={[
              styles.ptsNumber,
              getSnackDescriber()?.snackDetailsBtnFontSTyle(),
            ]}
            accessibilityLabel={point.toString()}
          >
            {Localized.Labels.formatString(
              Localized.Labels.points_number,
              point,
            )}
          </AVText>
        </View>
        <View
          style={[
            styles.productNameHead,
            !imageUrl
              ? {
                  top: -20,
                }
              : {top: -10},
            {
              justifyContent: 'center',
              alignSelf: 'center',
              alignContent: 'center',
            },
          ]}
        >
          <RoundedAppButton
            accessibilityHintValue="Double tap to open scan at kiosk"
            accessibilityLabelValue={getDescriber().scanAtKioskLbl()}
            aria-label={getDescriber().scanAtKioskLbl()}
            titleText={getDescriber().scanAtKioskLbl()}
            buttonType={RoundedAppButtonType.Outline}
            buttonContainerStyle={{
              overflow: 'hidden',
              alignSelf: 'center',
              justifyContent: 'center',
              alignContent: 'center',
            }}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            onPress={() => {
              NavActions.navigate(AppRoutes.RedeemRewardScanKioskPanel, {
                productName,
                point,
                upc,
                imageUrl,
              });
            }}
            buttonViewStyle={[
              {overflow: 'hidden', width: 150, height: 42, borderWidth: 2},
            ]}
            textStyle={{
              fontWeight: '700',
              fontSize: Styles.Fonts.f1,
              overflow: 'hidden',
            }}
          />
        </View>
        <AVTouchableOpacity
          onPress={props.cancel}
          testID="cancelredeem"
          style={[
            !imageUrl
              ? {
                  top: -15,
                }
              : {top: -10},
            {
              justifyContent: 'center',
              alignSelf: 'center',
              alignContent: 'center',
              paddingBottom: 22,
              bottom: Styles.Spacing.m2,
            },
          ]}
        >
          <View
            style={{
              alignSelf: 'flex-start',
              borderBottomColor: getButtonDescriber().checkRoundedBtnColor(
                ButtonType.outline,
              ),
              borderBottomWidth: 1.5,
            }}
          >
            <AVText
              style={[
                styles.canceltxt,
                {
                  color: getButtonDescriber().checkRoundedBtnColor(
                    ButtonType.outline,
                  ),
                },
                getSnackDescriber()?.snackDetailsBtnFontSTyle(),
              ]}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            >
              {Localized.Buttons.cancel}
            </AVText>
          </View>
        </AVTouchableOpacity>
      </View>
    )
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: Styles.Spacing.m3,
    gap: Styles.Spacing.m3,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: Styles.Spacing.m3,
    padding: 16,
  },
  titleHead: {
    gap: Styles.Spacing.m2,
  },
  productNameHead: {
    gap: Styles.Spacing.m3,
  },
  piggy: {
    height: Styles.Heights.h5,
    width: Styles.Heights.h4,
  },
  logoView: {
    paddingTop: Styles.Spacing.m3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  canceltxt: {
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '700',
    textDecorationLine: 'none',
    paddingTop: Styles.Spacing.m2,
  },
  redeemRwd: {
    fontSize: Styles.Fonts.f1 + 6,
    fontWeight: '700',
    lineHeight: (Styles.Fonts.f1 + 6) * lineHeightMultiplier,
  },
  pdtName: {
    fontSize: Styles.Fonts.f1 + 4,
    fontWeight: '700',
    lineHeight: (Styles.Fonts.f1 + 4) * lineHeightMultiplier,
    textAlign: 'center',
  },
  ptsNumber: {
    fontSize: Styles.Fonts.f1,
    fontWeight: '400',
    lineHeight: Styles.Fonts.f1 * lineHeightMultiplier,
  },
});

export default RedeemTab;
