import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

export default class Awareness extends React.Component {
  render() {
    return (
      <Svg width={38} height={34} viewBox="0 0 38 34" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.527.595C2.57.595-2.128 9.519 2.353 16.118l4.303 6.344-4.814 7.702L7.58 33.77l4.323-6.914.235-.375h14.168l.236.375 4.323 6.915 5.738-3.606-4.815-7.702 4.304-6.344c4.48-6.58-.216-15.523-8.175-15.523-7.958 0-7.074 2.167-8.685 5.18-1.63-2.994-4.775-5.18-8.685-5.18h-.02zM7.07 12.828c-1.65-2.442.078-5.732 3.026-5.732 2.947 0 4.637 3.191 3.085 5.654l-2.987 4.688-3.124-4.61zm24.287 0c1.65-2.442-.079-5.732-3.026-5.732-2.947 0-4.637 3.191-3.085 5.654l2.987 4.688 3.124-4.61zm-12.144 1.32l3.91 6.62h-7.82l3.91-6.62z"
          fill="#066DFF"
        />
      </Svg>
    );
  }
}
