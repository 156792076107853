import {ColorValue, TextStyle} from 'react-native';
import {RoundedBtnDescriberProps} from 'src/components/elements/buttonDescriptor/DescriptorType';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';

export function roundedBtnColorDescriptor(props: RoundedBtnDescriberProps): {
  [index: string]: ColorValue;
} {
  let textColor = Styles.white;
  const disabledColor = Styles.lightGray;
  let fillColor = Styles.primaryColor;
  const buttonColor = props.disabled
    ? disabledColor
    : props.color || Styles.primaryColor;
  if (props.buttonType === 'outline') {
    textColor = props.disabled
      ? disabledColor
      : props.color || Styles.primaryColor;
    fillColor = Styles.transparent;
  } else {
    textColor = Styles.white;
    fillColor = props.disabled
      ? disabledColor
      : props.color || Styles.primaryColor;
  }
  return {fillColor, textColor, buttonColor};
}

export function roundAppButtonSolidTextStyle(_disabled: boolean): TextStyle {
  return null;
}

export function roundAppButtonOutlineTextStyle(_disabled: boolean): TextStyle {
  return null;
}

export function checkRoundedBtnColor(_type?: ButtonType): string {
  return '#003349';
}

export function getSaveBtnManageAccount(handleSave) {
  return (
    <RoundedButton
      buttonType={ButtonType.action}
      text={Localized.Buttons.save}
      onPress={handleSave}
      accessible={true}
      addShadow={false}
      accessibilityLabel={Localized.Buttons.save}
      accessibilityRole="button"
      role="button"
      color={Styles.Colors.Canteen.darkGreen}
      aria-label={Localized.Buttons.save}
    />
  );
}

export function getTertiaryCTATextStyle(): TextStyle {
  return null;
}

export function buttonTextFontFamily(): string {
  return Styles.FontFamily.robotoRegular;
}

export default {
  roundedBtnColorDescriptor,
  roundAppButtonSolidTextStyle,
  roundAppButtonOutlineTextStyle,
  checkRoundedBtnColor,
  getSaveBtnManageAccount,
  getTertiaryCTATextStyle,
  buttonTextFontFamily,
};
