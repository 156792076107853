import React from 'react';
import {FlatList, Platform, ScrollView, StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import withIsConnected from '../../hoc/withIsConnected';
import BackSubheader from '../../elements/BackSubheader';
import ScreenContext from '../../ScreenContext';
import AVText from '../../elements/AVText';
import Styles from '../../Styles';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import type {BlockedUser} from 'src/types/Snack';
import Localized from 'src/constants/AppStrings';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {RootState} from 'src/redux/store';
import {
  getSnackBlockedUsers,
  unblockSnackUser,
  UnblockSnackUserParams,
} from 'src/redux/thunks/snackThunks';
import {getPreviousRouteName} from 'src/Util';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {getDescriber} from './descriptor/blockedusers/DescriptorType';

type SnackBlockedUsersProps = {
  navigation: NavigationProp<SnackBlockedUsersScreen>;
  loadingBlockedUsers: boolean;
  blockedUsers: Array<BlockedUser>;
  getSnackBlockedUsers(): void;
  unblockSnackUser(params: UnblockSnackUserParams): void;
};
type SnackBlockedUsersState = {
  previousRoute?: string | null;
};

const {
  getItemContainerStyle,
  getUnBlockUserStyle,
  getBlockedUsersList,
  getBlockedUserTitle,
  blockedUsersEmptyIcon,
} = getDescriber();
class SnackBlockedUsersScreen extends React.Component<
  SnackBlockedUsersProps,
  SnackBlockedUsersState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.state = {
      previousRoute: null,
    };
    this.renderItem = this.renderItem.bind(this);
    this.getKeyForListItem = this.getKeyForListItem.bind(this);
    this.renderEmpty = this.renderEmpty.bind(this);
    this.renderBlockedUsers = this.renderBlockedUsers.bind(this);
    this.renderNoUsersBlockedView = this.renderNoUsersBlockedView.bind(this);
  }

  componentDidMount() {
    FirebaseAnalytic.trackEvent(
      'componentDidMount',
      'SnackBlockedUsersScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
    this.props.getSnackBlockedUsers();
  }

  getKeyForListItem(item) {
    FirebaseAnalytic.trackEvent(
      'getKeyForListItem',
      'SnackBlockedUsersScreen',
      {
        ...this.props,
        ...this.state,
        item,
      },
    );
    return item.blockedAccountId;
  }

  renderItem({item}) {
    return (
      <View style={styles.row}>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={getItemContainerStyle()['blockedName']}
        >
          {item.blockedName}
        </AVText>
        <RoundedButton
          buttonType={ButtonType.outline}
          text={Localized.Buttons.unblock}
          color={getUnBlockUserStyle()['color'] as string}
          onPress={() => {
            this.props.unblockSnackUser({
              blockedAccountId: item.blockedAccountId,
            });
          }}
          textStyle={getUnBlockUserStyle()['btnUnblock']}
          accessibilityLabel="Unblock User"
          containerStyle={getUnBlockUserStyle()['btnUnblockContainer']}
        />
      </View>
    );
  }

  renderNoUsersBlockedView() {
    return (
      <AVText style={styles.noUsersBlockedMessageTextStyle}>
        {Localized.Labels.no_users_blocked_snack_msg}
      </AVText>
    );
  }

  renderNoUsersRevolveBlockedView = () => {
    return (
      <View
        accessible={true}
        accessibilityLabel={Localized.Labels.logo_noreovolveblockeduser}
        accessibilityRole="image"
        role="img"
        aria-label={Localized.Labels.logo_noreovolveblockeduser}
        style={[styles.nocardsContainer]}
      >
        {blockedUsersEmptyIcon()}
      </View>
    );
  };

  renderEmpty() {
    return (
      <BackSubheader
        title={getBlockedUserTitle()}
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
      />
    );
  }
  ItemSeparator = () => {
    return <View style={styles.separator} />;
  };
  renderBlockedUsers() {
    return (
      <View style={[getBlockedUsersList()['listWrapper']]}>
        <ScrollView>
          <FlatList
            refreshing={this.props.loadingBlockedUsers}
            onRefresh={this.props.getSnackBlockedUsers}
            data={this.props.blockedUsers}
            renderItem={this.renderItem}
            ItemSeparatorComponent={this.ItemSeparator}
          />
        </ScrollView>
      </View>
    );
  }
  checkISRevolve = () => {
    return this.renderNoUsersRevolveBlockedView();
  };
  render() {
    if (this.props.loadingBlockedUsers) {
      return this.renderEmpty();
    } else {
      return (
        <BackSubheader
          title={getBlockedUserTitle()}
          previousRoute={this.state.previousRoute}
          accessibilityLabel={'Back arrow'}
          accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        >
          {this.props.blockedUsers?.length > 0
            ? this.renderBlockedUsers()
            : this.checkISRevolve()}
        </BackSubheader>
      );
    }
  }
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: Styles.Spacing.m3,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: Styles.dividerColor,
  },
  textStyle: {
    fontSize: Styles.Fonts.f7,
    alignSelf: 'center',
    fontWeight: '700',
  },
  revolveTextStyle: {
    fontSize: Styles.Fonts.f1 + 2,
    alignSelf: 'center',
    fontWeight: '700',
    color: Styles.darkColor,
  },
  btnUnblockRevolve: {
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
    color: Styles.darkGreenRevolve,
  },
  noUsersBlockedMessageTextStyle: {
    fontSize: Styles.Fonts.f1,
    marginTop: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m2,
    textAlign: 'center',
  },
  listContainer: {
    flex: 1,
  },
  btnUnblockContainer: {
    borderRadius: Styles.Spacing.m4,
    borderWidth: 1,
    paddingVertical: Styles.Spacing.m3 + Styles.Spacing.m2,
    paddingHorizontal: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  nocardsContainer: {
    width: 300,
    height: 450,
    marginVertical: 'auto',
    padding: 120,
    top: 70,
    ...(Platform.OS === 'web' && {
      alignSelf: 'center',
    }),
  },
  container: {
    backgroundColor: '#fff',
    borderWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRadius: 8,
    overflow: 'hidden',
    margin: 16,
    marginTop: 32,
    borderColor: '#EAEAEB',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
  },

  separator: {
    height: 1,
    backgroundColor: '#EAEAEB',
  },
});

const mapStateToProps = (state: RootState) => {
  return {
    loadingBlockedUsers: state.snack?.loadingBlockedUsers,
    blockedUsers: state.snack?.blockedUsers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSnackBlockedUsers: () => dispatch(getSnackBlockedUsers()),
  unblockSnackUser: (params) => dispatch(unblockSnackUser(params)),
});

export default compose(
  withForwardedNavigationParams(),
  withIsConnected,
  connect(mapStateToProps, mapDispatchToProps),
)(SnackBlockedUsersScreen);
