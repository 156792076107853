import React from 'react';
import {
  Dimensions,
  Image,
  View,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from 'react-native';
import SnackProduct from 'src/components/img/svg/sendSnackStickers/SnackProduct';
import Styles from '../../../Styles';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Localized from 'src/constants/AppStrings';
import AVText from 'src/components/elements/AVText';
import GrpReferral from 'src/components/img/svg/GrpReferral';
import PointsIndicator from 'src/components/elements/rewards/PointsIndicator';
import ChevronRight from 'src/components/img/svg/ChevronRight';
import {UserRewardsTierIcon} from 'src/components/img/svg/rewards';
import ShopIcon from 'src/components/img/svg/ShopIcon';
import {
  SendSnackDescriberData,
  AnnouncementStylesDescriptor,
  CampusLocationListStylesDescriptor,
  ReferralDescriberData,
  EngagementCarouselStylesDescriptor,
  RewardskDescriberData,
  RewardsLegacykDescriberData,
} from 'src/components/elements/home/descriptor/DescriptorType';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import CoffeeIcon from 'src/components/img/svg/coffee/coffeeIcon';
import RewardCanteenHome from 'src/components/img/svg/RewardCanteenHome';

export function getCampusLocationStyles(): {
  [key: string]: {[key: string]: number | string};
} {
  const _width = Dimensions.get('window').width;
  return {
    separator: {
      backgroundColor: Styles.borderColor,
      height: 1,
    },
    recentLocationAddressText: {
      width: _width - Styles.Spacing.m6,
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
      color: Styles.lightGray,
      textAlignVertical: 'center',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    locationDisplatSubText: {},
    recentListborder: {},
    locationListView: {
      margin: Styles.Spacing.m3,
    },
    recentLocationViewLocationNameText: {
      textAlignVertical: 'center',
      width: _width - Styles.Spacing.m6,
      color: Styles.black,
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
    },
    locationDisplatText: {},
    greetNameText: {},
    descriptionText: {
      fontFamily: Styles.FontFamily.robotoRegular,
    },
  };
}
export function rewardsViewDescriptor(
  props: RewardskDescriberData,
): JSX.Element {
  const {
    marketAccountPoints,
    onRewardsClick,
    bgSize,
    tileHeight,
    styles,
    rewardTier,
    maxPointValue,
    productThresholds,
    maxPointsLabel,
  } = props;
  return (
    <React.Fragment>
      <AVTouchableOpacity
        activeOpacity={0.75}
        accessibilityLabel={Localized.Labels.pay_earn_enjoy}
        accessibilityHint="Press to navigate to rewards"
        style={{paddingTop: 20}}
        onPress={onRewardsClick}
      >
        <View
          style={[styles.container, {paddingHorizontal: Styles.Spacing.m3 + 8}]}
        >
          <View style={[styles.shadowWrapper]}>
            <UserRewardsTierIcon
              size={bgSize}
              tierName={rewardTier?.tierName}
              style={styles.tierIcon}
            />
            <PointsIndicator
              rewardedColor={'#84BD00'}
              height={tileHeight - Styles.Spacing.m3 * 3}
              thresholds={productThresholds}
              maxPointValue={maxPointValue}
              availablePoints={marketAccountPoints}
              maxPointValueLabel={maxPointsLabel}
            />

            <View style={styles.content}>
              <AVText
                style={[
                  styles.payEarnEnjoy,
                  {fontFamily: Styles.FontFamily.robotoRegular},
                ]}
              >
                {Localized.Labels.pay_earn_enjoy}
              </AVText>

              <View style={styles.rewardStatus}>
                <AVText
                  style={[
                    styles.rewardStatustxt,
                    {fontFamily: Styles.FontFamily.robotoRegular},
                  ]}
                >
                  {Localized.Labels.rewards_status}
                </AVText>

                <AVText
                  style={[
                    styles.currentTierName,
                    {fontFamily: Styles.FontFamily.robotoRegular},
                  ]}
                >
                  {rewardTier?.tierName}
                </AVText>
              </View>
            </View>
            <ChevronRight
              style={styles.chevron}
              color={Styles.Colors.PayNew.darkHuesBase04}
            />
          </View>
        </View>
      </AVTouchableOpacity>
    </React.Fragment>
  );
}
export function snackViewDescriptor(
  props: SendSnackDescriberData,
): JSX.Element {
  const {onSendASnackClick, styles} = props;
  return (
    <React.Fragment>
      <View style={styles.container}>
        <View style={styles.headerView}>
          <View
            style={[
              styles.iconView,
              {backgroundColor: Styles.Colors.CompanyKitchen.blue + '16'},
            ]}
            accessibilityLabel={'SnackIcon'}
          >
            <SnackProduct
              color={Styles.Colors.CompanyKitchen.blue + '16'}
              iconColor={Styles.Colors.CompanyKitchen.blue}
            />
          </View>
          <AVText
            style={styles.titleText}
            accessibilityRole="text"
            accessibilityLabel={`Text${Localized.Buttons.send_a_snack}`}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
            aria-label={`Text${Localized.Buttons.send_a_snack}`}
          >
            {Localized.Buttons.send_a_snack}
          </AVText>
        </View>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          aria-label={
            Localized.Labels
              .send_your_colleagues_tasty_snacks_to_brighten_their_day
          }
          accessibilityLabel={
            Localized.Labels
              .send_your_colleagues_tasty_snacks_to_brighten_their_day
          }
          style={styles.snackDescriptionText}
          accessibilityRole="text"
        >
          {
            Localized.Labels
              .send_your_colleagues_tasty_snacks_to_brighten_their_day
          }
        </AVText>
        <RoundedButton
          buttonType={ButtonType.outline}
          accessibilityRole="button"
          containerStyle={styles.snackButton}
          text={Localized.Buttons.send_a_snack}
          aria-label={Localized.Buttons.send_a_snack}
          accessibilityLabel={Localized.Buttons.send_a_snack}
          textStyle={styles.snackButtonText}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
          onPress={onSendASnackClick}
        />
      </View>
    </React.Fragment>
  );
}
export function getScanAndPayTile(): {
  [key: string]: {[index: string]: number | string};
} {
  return {
    rowSpace: {
      marginVertical: Styles.Spacing.m2,
    },
    iconViewBackground: {
      backgroundColor: '#3A96DB29',
      width: Styles.Heights.h5,
      height: Styles.Heights.h5,
      margin: Styles.Spacing.m3,
    },
    listContainer: {
      marginTop: Styles.Spacing.m3,
    },
    titleText: {
      marginRight: Styles.Spacing.m6 - Styles.Fonts.f1,
      fontWeight: '700',
      color: Styles.black,
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
    },
    nameText: {
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
      marginRight: Styles.Spacing.m6 - Styles.Fonts.f1,
      fontSize: Styles.Fonts.f7,
      color: Styles.black,
    },
    textView: {
      marginVertical: Styles.Spacing.m3,
      height: Styles.Heights.h5,
    },
  };
}
export function infoIconInnerColor() {
  return Styles.secondaryColor;
}
export function infoIconPrimaryColor() {
  return Styles.primaryColor;
}
export function announcementBtnFont(): string {
  return Styles.FontFamily.robotoRegular;
}
export function announcemnentButtonColor() {
  return Styles.primaryColor;
}
export function getWidthImageAvailable() {
  return '70%';
}

export function getAnnouncementStyles(): AnnouncementStylesDescriptor {
  return StyleSheet.create({
    container: {
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: Styles.Spacing.m3 - 4,
      marginHorizontal: Styles.Spacing.m3,
      backgroundColor: '#fff',
      marginBottom: Styles.Spacing.m2,
      borderRadius: Styles.Spacing.m2,
      ...Styles.Style.shadow,
    },
    titleText: {
      marginRight: Styles.Spacing.m3,
      marginLeft: Styles.Spacing.m2,
      fontSize: Styles.Fonts.headerFont,
      width: '80%',
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
      color: Styles.black,
      textAlign: 'left',
    },
    descriptionText: {
      fontSize: Styles.Fonts.sectionHeader,
      color: Styles.darkColor,
      marginRight: Styles.Spacing.m4,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.aeonikRegular,
      marginLeft: Styles.Spacing.m2,
    },
  });
}
export function rewardsLegacyViewDescriptor(
  props: RewardsLegacykDescriberData,
): JSX.Element {
  const {onRewardsClick, styles, stat, isOfferAvailable} = props;
  return (
    <React.Fragment>
      <AVTouchableOpacity
        aria-label="onRewardsClick"
        accessible={true}
        accessibilityLabel="onRewardsClick"
        accessibilityHint="Double tap to navigate to the rewards screen"
        accessibilityRole="button"
        role="button"
        onPress={onRewardsClick}
        style={{paddingTop: 10}}
      >
        <View
          style={[styles.container, {paddingHorizontal: Styles.Spacing.m3}]}
        >
          <View style={[styles.starIconView, {bottom: -6}]}>
            <View style={[{top: 8}]} accessibilityLabel={'RewardsIcon'}>
              <RewardCanteenHome />
            </View>
            <View
              style={{
                paddingHorizontal: 16,
                paddingTop: 10,
              }}
            >
              <AVText
                accessibilityRole="text"
                style={[
                  {
                    color: Styles.black,
                    fontFamily: Styles.FontFamily.robotoRegular,
                    fontSize: Styles.Fonts.f7,
                    paddingBottom: 10,

                    fontWeight: '700',
                  },
                ]}
                accessibilityLabel={Localized.Labels.rewards}
                aria-label={`${Localized.Labels.rewards}, text`}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
              >
                {Localized.Labels.rewards}
              </AVText>
              <AVText
                accessibilityRole="text"
                aria-label={`${stat}, text`}
                accessibilityLabel={`${stat}, text`}
                style={[
                  {
                    fontFamily: Styles.FontFamily.robotoRegular,
                    fontWeight: '700',
                    color: Styles.black,

                    fontSize: Styles.Fonts.f7,
                  },
                  isOfferAvailable && {
                    paddingBottom: 10,
                  },
                ]}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
              >
                {stat}
              </AVText>
              {isOfferAvailable && (
                <AVText
                  accessibilityRole="text"
                  aria-label={`${stat}, text`}
                  accessibilityLabel={`${stat}, text`}
                  style={[
                    {
                      fontFamily: Styles.FontFamily.robotoRegular,
                      fontWeight: '400',
                      color: Styles.black,
                      fontSize: Styles.Fonts.f1,
                    },
                  ]}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                >
                  {Localized.Labels.offerAvailable}
                </AVText>
              )}
            </View>
          </View>

          <View
            style={[{alignSelf: 'flex-end', top: isOfferAvailable ? -50 : -30}]}
          >
            <ChevronRight />
          </View>
        </View>
      </AVTouchableOpacity>
    </React.Fragment>
  );
}
export function getCampusLocationListStyles(): CampusLocationListStylesDescriptor {
  return StyleSheet.create({
    headerMargin: {
      marginLeft: Styles.Spacing.m3 + Styles.Spacing.m2,
    },
    container: {
      flex: 1,
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    campusLocationListView: {
      marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
      width: '100%',
    },
  });
}
export function referralViewDescriptor(
  props?: ReferralDescriberData,
): JSX.Element {
  const {onInviteClick, styles, title, stat} = props;
  return (
    <React.Fragment>
      <View style={styles.container}>
        <View style={styles.headerView}>
          <View
            style={[
              styles.iconView,
              {backgroundColor: Styles.Colors.CompanyKitchen.blue + '16'},
            ]}
            accessibilityLabel={'SnackIcon'}
          >
            <GrpReferral
              iconColor={Styles.Colors.CompanyKitchen.blue}
              color={Styles.Colors.CompanyKitchen.blue + '16'}
            />
          </View>
          <AVText
            style={[
              {
                fontFamily: Styles.FontFamily.robotoRegular,
                color: '#22212D',
                fontWeight: '700',
                fontSize: 20,
                marginLeft: Styles.Spacing.m3,
              },
            ]}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
            accessibilityLabel={`Text${Localized.Buttons.send_a_snack}`}
            accessibilityRole="text"
            aria-label={`Text${Localized.Buttons.send_a_snack}`}
          >
            {title}
          </AVText>
        </View>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          aria-label={Localized.Labels.receive_cash_to_spend}
          accessibilityLabel={Localized.Labels.receive_cash_to_spend}
          accessibilityRole="text"
          style={[
            styles.snackDescriptionText,
            {
              fontSize: 18,
              fontFamily: Styles.FontFamily.robotoRegular,
              fontWeight: '400',
              color: '#22212D',
            },
          ]}
        >
          {Localized.Labels.receive_cash_to_spend}
        </AVText>
        <AVText
          accessibilityRole="text"
          accessibilityLabel={Localized.Labels.receive_cash_to_spend}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          aria-label={Localized.Labels.receive_cash_to_spend}
          style={[
            styles.snackDescriptionText,
            {
              fontFamily: Styles.FontFamily.robotoRegular,
              fontSize: 18,
              fontWeight: '400',
              color: '#727179',
            },
          ]}
        >
          {stat}
        </AVText>
        <TouchableOpacity
          accessibilityRole="button"
          role="button"
          accessibilityLabel={Localized.Buttons.invite}
          aria-label={Localized.Buttons.invite}
          style={[
            styles.invite,
            {
              borderColor: Styles.Colors.CompanyKitchen.black,
            },
          ]}
          onPress={onInviteClick}
          testID={Localized.Buttons.invite}
        >
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
            style={{
              fontWeight: Platform.OS === 'android' ? 'bold' : '600',
              fontFamily: Styles.FontFamily.robotoRegular,
              color: Styles.Colors.CompanyKitchen.black,
              textTransform: 'capitalize',
              fontSize: Styles.Fonts.f0 + 4,
            }}
            adjustsFontSizeToFit={true}
            testID={Localized.Buttons.invite}
          >
            {Localized.Buttons.invite}
          </AVText>
        </TouchableOpacity>
      </View>
    </React.Fragment>
  );
}
export function engagementCarouselMarginZeroLength(): number | undefined {
  return undefined;
}
export function engagementCarouselMargin(): number {
  return Styles.Spacing.m3 + Styles.Spacing.m2;
}
export function engagementCarouselMarginBottom(): number {
  return -Styles.Spacing.m4;
}
export function engagementCarouselPaginationInactiveColor(): string {
  return Styles.inActiveDotColor;
}
export function engagementCarouselPaginationColor(): string {
  return Styles.secondaryColor;
}

export function getEngagementCarouselStyles(): EngagementCarouselStylesDescriptor {
  return StyleSheet.create({
    imageContentContainer: {
      paddingLeft: 10,
    },
    activeDotStyle: {
      borderRadius: 9,
      width: 14,
      height: 14,
    },
    inActiveDotStyle: {
      width: 14,
      borderRadius: 9,
      height: 14,
    },
    parralaxContainer: {
      backgroundColor: Styles.neutralHues,
    },
  });
}
export function RecentlocationListIcon(): JSX.Element {
  return <ShopIcon size={32} />;
}
export function locationListIcon(): JSX.Element {
  return <ShopIcon size={Styles.Spacing.m4} />;
}
export function getScanAndPayTileIcon(coffeMachineType?: string): JSX.Element {
  if (coffeMachineType && coffeMachineType == 'Bunn') {
    return <CoffeeIcon width={26} height={26} color={Styles.secondaryColor} />;
  } else {
    return (
      <Image
        source={require('src/components/img/scanIconCK.png')}
        style={{
          height: Styles.Heights.h5 / 2,
          width: Styles.Heights.h5 / 2,
        }}
      />
    );
  }
}

export default {
  getAnnouncementStyles,
  engagementCarouselMarginBottom,
  engagementCarouselMarginZeroLength,
  getCampusLocationStyles,
  snackViewDescriptor,
  infoIconPrimaryColor,
  infoIconInnerColor,
  getCampusLocationListStyles,
  rewardsViewDescriptor,
  announcementBtnFont,
  engagementCarouselMargin,
  engagementCarouselPaginationColor,
  RecentlocationListIcon,
  getScanAndPayTile,
  announcemnentButtonColor,
  engagementCarouselPaginationInactiveColor,
  getEngagementCarouselStyles,
  locationListIcon,
  getScanAndPayTileIcon,
  referralViewDescriptor,
  rewardsLegacyViewDescriptor,
  getWidthImageAvailable,
};
