import * as React from 'react';
import {View, StyleSheet, Text, TouchableOpacity} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import ScreenContext from '../../ScreenContext';
import BackSubheader from '../../elements/BackSubheader';
import CheckoutBar from '../../elements/orderAhead/CheckoutBar';
import Styles from '../../Styles';
import type {LocationType, PickupLocation} from 'src/types/Location';
import type {TimeSlotType} from 'src/types/Menu';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import TransactionStore from 'src/stores/TransactionStore';
import AccountConstants from 'src/constants/AccountConstants';
import CartService from 'src/services/CartService';
import CartTypes from 'src/constants/cart/CartTypes';
import TransactionActions from 'src/actions/TransactionActions';
import MenuActions from 'src/actions/MenuActions';
import Locker from '../../img/svg/Locker';
import Localized from 'src/constants/AppStrings';
import {confirm, alertError} from '../../helpers/AlertHelper';
import moment from 'moment';
import uuid from 'src/nativeModules/UUID';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import {FlashList} from '@shopify/flash-list';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {handleAvailableTimes} from './orderAheadUtils';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type PickupLocationScreenProps = {
  navigation: NavigationProp<PickupLocationScreen>;
  location: LocationType;
  availableTimes: Array<TimeSlotType>;
  autoAdvanced: boolean;
  fromCart?: boolean;
};

type PickupLocationScreenState = {
  previousRoute: string | null;
};

class PickupLocationScreen extends React.Component<
  PickupLocationScreenProps,
  PickupLocationScreenState
> {
  static defaultProps = {
    fromCart: false,
  };
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  sessionStartTime: moment.Moment;

  constructor(props) {
    super(props);
    this.state = {
      previousRoute: null,
    };

    if (!props.fromCart) {
      this.sessionStartTime = moment();
    }

    this.renderPickupLocation = this.renderPickupLocation.bind(this);
    this.pickupLocationSelected = this.pickupLocationSelected.bind(this);
    this.onCartPressed = this.onCartPressed.bind(this);
    this.navigateAway = this.navigateAway.bind(this);
    this.onBackSelect = this.onBackSelect.bind(this);
  }

  componentDidMount(): void {
    FirebaseAnalytic.trackEvent('componentDidMount', 'PickupLocationScreen', {
      ...this.props,
      ...this.state,
      pickUpLocation: TransactionStore.getPickupLocationName(),
      pickUpTime: TransactionStore.getPickupTime(),
    });

    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  onBackSelect() {
    if (!this.props.fromCart) {
      const displayItems = TransactionStore.getDisplayItems();
      FirebaseAnalytic.trackEvent('onBackSelect', 'PickupLocationScreen', {
        ...this.props,
        ...this.state,
        displayItems,
      });

      if (displayItems.length > 0) {
        confirm(
          Localized.Labels.cancel_order_confirm,
          () => {
            CartService.clearCart(this.sessionStartTime);
            NavActions.pop();
          },
          () => ({}),
          Localized.Labels.cancel_order,
          Localized.Labels.no,
          Localized.Labels.yes,
        );
      } else {
        CartService.clearCart(this.sessionStartTime);
        NavActions.pop();
      }
    } else {
      NavActions.pop();
    }
  }

  onCartPressed() {
    FirebaseAnalytic.trackEvent('onCartPressed', 'PickupLocationScreen', {
      ...this.props,
      ...this.state,
      marketName: this.props.location.name,
      locationId: this.props.location.locationId,
      locationType: AccountConstants.SOS_LOCATION_TYPE,
      beaconId: this.props.location.beaconId ?? this.props.location.locationId,
      cartType: CartTypes.OrderAhead,
      location: this.props.location,
      navigate: AppRoutes.Scan,
      pickUpLocation: TransactionStore.getPickupLocationName(),
      pickUpTime: TransactionStore.getPickupTime(),
      showPreparationMethod:
        this.props.location.onlineOrderConfig.hasDiningPreference,
      defaultPreparationMethod:
        this.props.location.onlineOrderConfig.diningPreference,
    });
    if (this.props.fromCart) {
      NavActions.pop();
    } else {
      NavActions.push(AppRoutes.Scan, {
        marketName: this.props.location.name,
        locationId: this.props.location.locationId,
        locationType: AccountConstants.SOS_LOCATION_TYPE,
        beaconId:
          this.props.location.beaconId ?? this.props.location.locationId,
        cartType: CartTypes.OrderAhead,
        location: this.props.location,
      });
    }
  }

  async pickupLocationSelected(pickupLocation: PickupLocation) {
    FirebaseAnalytic.trackEvent(
      'pickupLocationSelected',
      'PickupLocationScreen',
      {
        ...this.props,
        ...this.state,
        pickupLocation,
        pickUpTime: TransactionStore.getPickupTime(),
      },
    );

    TransactionActions.pickupLocationUpdated(pickupLocation);
    if (this.props.fromCart) {
      this.navigateAway();
      return;
    }
    try {
      this.context.actions.showSpinner();
      const availableTimes = await MenuActions.getAvailableTimeSlots(
        this.props.location,
        pickupLocation.pickupLocationId,
      );
      FirebaseAnalytic.trackEvent('availableTimes', 'PickupLocationScreen', {
        ...this.props,
        ...this.state,
        pickupLocation,
        availableTimes,
      });
      handleAvailableTimes(
        availableTimes,
        this.props.location,
        this.props.autoAdvanced,
        this.navigateAway,
        alertError,
      );
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'PickupLocationScreen:PickupLocationSelected',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'PickupLocationScreen:PickupLocationSelected',
        generateErrorMessage(error),
        guid,
      );
      alertError(Localized.Errors.order_ahead_unavailable, guid);
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  navigateAway(availableTimes?: Array<TimeSlotType>) {
    if (this.props.fromCart) {
      NavActions.pop();
    } else {
      NavActions.push(AppRoutes.PickupTime, {...this.props, availableTimes});
    }
  }

  renderPickupLocation({item}) {
    const dineIn = item.hasDineIn && (
      <FontAwesome5Pro
        name="utensils"
        color={Styles.primaryColor}
        size={Styles.Fonts.f2}
        solid
        style={styles.icon}
        accessibilityLabel="Dine In, "
        aria-label="Dine In, "
      />
    );

    const togo = item.hasToGo ? (
      <FontAwesome5Pro
        name="running"
        color={Styles.positiveColor}
        size={Styles.Fonts.f2}
        solid
        style={styles.icon}
        accessibilityLabel="To Go, "
        aria-label="To Go, "
      />
    ) : null;

    const locker = item.hasLocker ? (
      <View accessibilityLabel="Locker" aria-label="Locker" style={styles.icon}>
        <Locker size={Styles.Fonts.f2} />
      </View>
    ) : null;

    return (
      <TouchableOpacity
        key={item.id + '-' + Date.now()}
        style={styles.cell}
        onPress={() => this.pickupLocationSelected(item)}
        accessible={true}
        accessibilityRole="button"
        role="button"
      >
        <View accessibilityLabel={item.name + ', '} style={styles.mainContent}>
          <Text
            maxFontSizeMultiplier={4}
            accessibilityRole="text"
            aria-label={item.name}
            style={styles.time}
          >
            {item.name}
          </Text>
        </View>
        {dineIn}
        {togo}
        {locker}

        <FontAwesome5Pro
          accessible={false}
          accessibilityLabel={''}
          name="chevron-right"
          color={Styles.lightGray}
          size={Styles.Fonts.f2}
          light
        />
      </TouchableOpacity>
    );
  }

  render() {
    const data = this.props.location.pickupLocations;
    const renderMethod = this.renderPickupLocation;

    return (
      <BackSubheader
        title={Localized.Labels.pickup_location}
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        onBackSelect={this.onBackSelect}
        pop={false}
      >
        <View style={styles.content}>
          <FlashList
            data={data}
            renderItem={renderMethod}
            estimatedItemSize={53}
          />
          <CheckoutBar
            location={this.props.location}
            strings={Localized}
            onPress={this.onCartPressed}
          />
        </View>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    backgroundColor: Styles.white,
  },
  time: {
    fontWeight: 'bold',
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
  },
  day: {
    fontSize: Styles.Fonts.f0,
    color: Styles.darkColor,
  },
  cell: {
    padding: Styles.Spacing.m3,
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: 0.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainContent: {
    flex: 1,
  },
  icon: {
    marginRight: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams<PickupLocationScreenProps>()(
  PickupLocationScreen,
);
