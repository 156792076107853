import React from 'react';
import {StyleSheet, View, Text, FlatList} from 'react-native';
import moment from 'moment';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import type {
  RecentOrderType,
  RecentOrderItemType,
  RecentOrderItemModifierType,
} from 'src/types/RecentOrders';
import Styles from '../../Styles';
import AVFormattedCurrency from '../AVFormattedCurrency';
import TouchableOpacity from '../AVTouchableOpacity';
import {WithLocalizedProps} from 'src/types/PropTypes';
import AccountStore from 'src/stores/AccountStore';

type RecentOrderCPProps = WithLocalizedProps & {
  addItem: (item: RecentOrderItemType) => void;
  productCellPressed: (item: RecentOrderItemType) => void;
  recentOrder: RecentOrderType;
  addAllItems: (order: RecentOrderType) => void;
};

export default ({
  addItem,
  productCellPressed,
  strings,
  recentOrder,
  addAllItems,
}: RecentOrderCPProps) => {
  const hasAddAllButton =
    recentOrder.items.filter((item) => item.available === true).length > 1;

  return (
    <View style={styles.container}>
      <View style={styles.titleBar}>
        <Text
          style={styles.dateText}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
        >
          {moment(recentOrder.transdate).calendar(null, {
            sameDay: `[${strings.Labels.today}] MMMM D LT`,
            lastDay: `[${strings.Labels.yesterday}] MMMM D LT`,
            lastWeek: 'dddd MMMM D LT',
            sameElse: 'MMMM D LT',
          })}
        </Text>
        {hasAddAllButton && (
          <TouchableOpacity
            accessibilityLabel="Add all"
            onPress={() => addAllItems(recentOrder)}
          >
            <Text
              style={styles.addAllText}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
            >
              {strings.Labels.add_all}
            </Text>
          </TouchableOpacity>
        )}
      </View>

      <FlatList
        data={recentOrder.items}
        keyExtractor={(item) => item.id}
        renderItem={({item}) => {
          return (
            <View
              style={{
                borderBottomColor: Styles.lightGray,
                borderBottomWidth: 0.2,
              }}
            >
              <RecentOrderCPItem
                addItem={addItem}
                strings={strings}
                orderItem={item}
                productCellPressed={productCellPressed}
              />
            </View>
          );
        }}
      />
    </View>
  );
};

type RecentOrderCPItemProps = WithLocalizedProps & {
  orderItem: RecentOrderItemType;
  addItem: (item: RecentOrderItemType) => void;
  productCellPressed: (item: RecentOrderItemType) => void;
};

const RecentOrderCPItem: React.FC<RecentOrderCPItemProps> = ({
  strings,
  orderItem,
  addItem,
  productCellPressed,
}) => {
  return (
    <>
      <TouchableOpacity
        accessibilityLabel="Add"
        onPress={() =>
          orderItem.available ? productCellPressed(orderItem) : {}
        }
        style={{
          marginVertical: 8,
        }}
      >
        <View style={styles.itemRow}>
          <Text
            style={styles.item}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
          >
            {orderItem.name}
          </Text>
          <View style={styles.itemRight}>
            {orderItem.available ? (
              <>
                <AVFormattedCurrency
                  style={styles.itemPrice}
                  value={orderItem.price}
                  accessibilityLabel="price"
                  currency={AccountStore.getCurrency()}
                />
                <TouchableOpacity
                  accessibilityLabel="Add"
                  onPress={() => addItem(orderItem)}
                >
                  <FontAwesome5Pro
                    name="plus-circle"
                    color={Styles.lightGray}
                    size={Styles.Fonts.f2}
                    light
                  />
                </TouchableOpacity>
              </>
            ) : (
              <Text
                style={styles.unavailable}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                {strings.Labels.unavailable}
              </Text>
            )}
          </View>
        </View>
        <FlatList
          data={orderItem.modifiers}
          keyExtractor={(itemm) => itemm.saleItemModifierId}
          renderItem={({item}) => (
            <RecentOrderCPItemModifier strings={strings} modifierCP={item} />
          )}
        />
      </TouchableOpacity>
    </>
  );
};

type RecentOrderCPItemModifierProps = WithLocalizedProps & {
  modifierCP: RecentOrderItemModifierType;
};

const RecentOrderCPItemModifier: React.FC<RecentOrderCPItemModifierProps> = ({
  modifierCP,
  strings,
}) => {
  let label;

  if (modifierCP.price && modifierCP.available) {
    label = (
      <AVFormattedCurrency
        style={[styles.modifierCPText, styles.modifierPrice]}
        value={modifierCP.price}
        currency={AccountStore.getCurrency()}
        accessible={true}
        accessibilityLabel={`${strings.Labels.price}: $${modifierCP.price}`}
        aria-lable={`${strings.Labels.price}: $${modifierCP.price}`}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
      />
    );
  }

  return (
    <View style={styles.modifierCPContainer}>
      <Text
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
        style={styles.modifierCPText}
      >
        {modifierCP.name}
      </Text>
      {label}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Styles.bgColor,
    marginVertical: 2,
  },
  titleBar: {
    backgroundColor: Styles.bgColor,
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Styles.Spacing.m2,
  },
  addAllText: {
    color: Styles.primaryColor,
    fontSize: Styles.Fonts.f1,
  },
  dateText: {
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
  },
  itemRow: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: Styles.Spacing.m1,
    marginHorizontal: Styles.Spacing.m3,
  },
  item: {
    marginVertical: 4,
    color: Styles.darkColor,
    flex: 1,
    fontSize: 18,
    marginRight: Styles.Spacing.m2,
    fontWeight: '300',
  },
  itemPrice: {
    color: Styles.darkColor,
    fontSize: 16,
    marginRight: Styles.Spacing.m2,
    fontWeight: '300',
    paddingRight: Styles.Spacing.m2,
  },
  itemRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  modifierCPContainer: {
    paddingHorizontal: Styles.Spacing.m3,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modifierCPText: {
    color: Styles.lightGray,
    fontSize: 14,
    fontWeight: '300',
    maxWidth: '70%',
  },
  modifierPrice: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginRight: 36,
  },
  unavailable: {
    color: Styles.dangerColor,
    fontSize: Styles.Fonts.f1,
  },
  unavailableModifier: {
    textDecorationStyle: 'solid',
    textDecorationColor: Styles.dangerColor,
    textDecorationLine: 'line-through',
  },
});
