import {createAsyncThunk} from '@reduxjs/toolkit';
import {hideSpinner, showSpinner} from '../../slices/screenSlice';
import {AppDispatch, RootState} from '../../store';
import {alertError} from 'src/components/helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import Logger from 'src/logging/Logger';
import AppApi from 'src/api/AppApi';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export type NotificationMessage = {
  locationId: string;
};
export const markAllMessagesAsRead = createAsyncThunk<
  boolean | undefined,
  NotificationMessage,
  {dispatch: AppDispatch; state: RootState}
>(
  'messages/all-messages-read-status-update',
  async ({locationId}, {dispatch, getState}) => {
    const accountId = getState().account.account.id;
    let response;
    try {
      dispatch(showSpinner({}));
      response = await AppApi.markAllAsReadMessages(accountId, locationId);
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'NotificationMessageParams',
        JSON.stringify({
          locationId: locationId,
          accountId,
        }),
        JSON.stringify(response),
      );

      return true;
    } catch (err) {
      alertError(Localized.Errors.error);
      CrashlyticsEvents.log(
        'Exception',
        'all-messages-read-status-update',
        generateErrorMessage(err),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'all-messages-read-status-update',
        generateErrorMessage(err),
      );
      return false;
    } finally {
      dispatch(hideSpinner());
    }
  },
);
