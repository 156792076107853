import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import Styles from '../../../Styles';
import type {CartItem, TransactionDetail} from 'src/types/TransactionDetail';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';
import Localized from 'src/constants/AppStrings';
import {alertError, alertSuccess} from '../../../helpers/AlertHelper';
import {TimeSlotType} from 'src/types/Menu';
import {connect, ConnectedProps} from 'react-redux';
import {AppDispatch, RootState} from 'src/redux/store';
import {compose} from 'redux';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {EnvironmentKey} from '../../../../models/Environment';
import BaseScreen from '../../BaseScreen';
import AVText from '../../../elements/AVText';
import Util, {formatDate, getTotalDisplayAmount} from 'src/Util';
import RoundedButton, {ButtonType} from '../../../elements/RoundedButton';
import Tag from '../../../img/svg/inbox/Tag';
import MachineStore from '../../../../stores/MachineStore';
import {CampusLocationType} from '../../../elements/home/CampusLocationView';

function transactionItems(
  item: CartItem,
  formatCurrency: (value: number) => string,
) {
  const discount = item?.Discounts?.find((d) => d);
  return (
    <View style={styles.transactionItemsContainer}>
      <View key={item.Id} style={styles.transactionItemsView}>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
          accessible={true}
          accessibilityRole="text"
          accessibilityLabel={item.Description}
          aria-label={item.Description}
          style={[styles.transactionItemsText, {flex: 1}]}
        >
          {item.Description}
        </AVText>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
          accessible={true}
          accessibilityRole="text"
          accessibilityLabel={item.Price}
          aria-label={item.Price}
          style={[
            styles.transactionItemsText,
            Boolean(discount) && {textDecorationLine: 'line-through'},
          ]}
        >
          {formatCurrency(Number(item.Price))}
        </AVText>
      </View>
      {Boolean(discount) && (
        <View key={item.Id} style={styles.transactionPromoView}>
          <Tag
            width={16}
            height={18}
            color={Styles.Colors.PayNew.success}
            style={{marginRight: Styles.Spacing.m1}}
          />
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
            accessible={true}
            accessibilityRole="text"
            accessibilityLabel={item.Description}
            aria-label={item.Description}
            style={[styles.transactionPromoText, {flex: 1}]}
          >
            {discount.displayName} (-{formatCurrency(discount.discount)})
          </AVText>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
            accessible={true}
            accessibilityRole="text"
            accessibilityLabel={item.Price}
            aria-label={item.Price}
            style={styles.transactionPromoText}
          >
            {formatCurrency(Number(item.Price) - discount.discount)}
          </AVText>
        </View>
      )}
    </View>
  );
}

type PurchaseReceiptScreenProps = WithGlobalizeProps &
  PropsFromRedux & {
    transaction: TransactionDetail;
    locationId: string;
    transactionId: string;
    transactionType: string;
    checkout: boolean;
    pickupNotes: string;
    marketAccount?: string | null;
    pickupLocation: string;
    pickupTime: TimeSlotType;
    orderNumber: string;
    location: string;
    locationName: string;
    transactionDate: string;
    discount: number;
    env: EnvironmentKey;
    navigation?: NavigationProp<any>;
    transactionCurrency?: string;
    displayBalances?: string;
  };

type PurchaseReceiptScreenState = {
  balance: number;
};
class ScanPay365ReceiptScreen extends React.Component<
  PurchaseReceiptScreenProps,
  PurchaseReceiptScreenState
> {
  receiptLink: string | null | undefined;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: PurchaseReceiptScreenProps) {
    super(props);
    const totalAmount = getTotalDisplayAmount(props?.balances);

    this.setState({balance: totalAmount});
    this.state = {
      balance: props.displayBalances || totalAmount,
    };
  }

  getLocationAddress = () => {
    if (this.props.pickupLocation) {
      return this.props.pickupLocation;
    }

    const marketsList =
      MachineStore.getMarkets() as unknown as CampusLocationType[];
    const market = marketsList.find(
      (m) => m.locationId === this.props.locationId,
    );

    if (market) {
      return [
        market.address,
        market.city,
        [market.state, market.zip].filter((x) => x).join(' '),
        market.country,
      ]
        .filter((x) => x)
        .join(', ');
    }

    return '';
  };

  handleEmailReceipt = async () => {
    this.context.actions.showSpinner();
    try {
      const response = await ActionsFactory.getAccountActions().emailReceipt(
        AccountStore.getEmail(),
        this.props.transactionId,
        this.props.transactionType,
        AccountStore.getRegion(),
        AccountStore.getPurchaseHistoryItem(this.props.transactionId)
          ?.Balance ?? 0,
      );

      this.context.actions.hideSpinner();
      if (response.statuscode === 200) {
        FirebaseAnalytic.trackEvent(
          'handleEmailReceipt',
          'ReceiptScreen',
          this.props,
        );
        setTimeout(() => {
          alertSuccess(Localized.Success.email_sent);
        }, 300);
      } else {
        throw new Error('Error sending email receipt');
      }
    } catch (error) {
      this.context.actions.hideSpinner();
      const guid = await uuid.getRandomUUID();
      setTimeout(() => {
        alertError(Localized.Errors.error_emailing_receipt, guid);
      }, 300);
      CrashlyticsEvents.log(
        'Exception',
        'ReceiptScreen:HandleEmailReceipt',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'ReceiptScreen:HandleEmailReceipt',
        generateErrorMessage(error),
        guid,
      );
    }
  };

  handleDone = () => {
    if (Settings.isNewUI() && this.props.checkout) {
      NavActions.reset(AppRoutes.NewHome);
    } else {
      NavActions.popToTop();
    }
  };

  formatCurrency = (value: number) => {
    return Util.formatCurrency(this.props, value, AccountStore.getCurrency());
  };
  render() {
    return (
      <BaseScreen
        hideBack={true}
        accessibilityLabel={Localized.Buttons.back_arrow}
        aria-label={Localized.Buttons.back_arrow}
        accessibilityHint={Localized.Buttons.back_arrow}
        title={Localized.Buttons.receipt}
      >
        <ScrollView style={styles.content}>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
            accessible={true}
            accessibilityLabel={this.props.locationName}
            accessibilityRole="text"
            aria-label={this.props.locationName}
            numberOfLines={2}
            style={styles.locationName}
          >
            {this.props.locationName}
          </AVText>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
            accessible={true}
            accessibilityLabel={this.props.pickupLocation}
            accessibilityRole="text"
            aria-label={this.props.pickupLocation}
            style={styles.pickupLocation}
          >
            {this.getLocationAddress()}
          </AVText>

          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
            accessible={true}
            accessibilityLabel={this.props.transactionDate}
            accessibilityRole="text"
            aria-label={this.props.transactionDate}
            style={styles.transactionDate}
          >
            {formatDate(this.props.transactionDate, 'MMMM D, YYYY h:mm A')}
          </AVText>

          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
            accessible={true}
            accessibilityLabel={this.props.transactionId}
            accessibilityRole="text"
            aria-label={this.props.transactionId}
            style={styles.transactionId}
          >
            {this.props.transactionId}
          </AVText>

          <View style={styles.transactionItemsDot}>
            <View style={styles.transactionItemsViewDot} />
          </View>

          {this.props.transaction.Items.map((item) =>
            transactionItems(item, this.formatCurrency),
          )}

          <View style={styles.transactionItemsDot}>
            <View style={styles.transactionItemsViewDot} />
          </View>

          <View style={styles.transactionItemsView}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={Localized.Labels.subtotal}
              aria-label={Localized.Labels.subtotal}
              style={styles.transactionItemsSubtotal}
            >
              {Localized.Labels.subtotal}
            </AVText>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={Localized.Labels.subtotal}
              aria-label={Localized.Labels.subtotal}
              style={styles.transactionItemsSubtotal}
            >
              {this.formatCurrency(Number(this.props.transaction.SubTotal))}
            </AVText>
          </View>

          <View style={styles.transactionItemsView}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={'Tax'}
              aria-label={'Tax'}
              style={styles.transactionItemsSubtotal}
            >
              Tax
            </AVText>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={'Sub total'}
              aria-label={'Sub total'}
              style={styles.transactionItemsSubtotal}
            >
              {this.formatCurrency(
                this.props.transaction.Taxes.reduce(
                  (total, val) => total + +val.TaxAmount,
                  0,
                ),
              )}
            </AVText>
          </View>

          <View style={styles.transactionItemsDot}>
            <View style={styles.transactionItemsViewDot} />
          </View>

          <View style={styles.transactionTotalView}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={Localized.Labels.total}
              aria-label={Localized.Labels.total}
              style={styles.transactionTotal}
            >
              {Localized.Labels.total}
            </AVText>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={'Sub total'}
              aria-label={'Sub total'}
              style={styles.transactionTotal}
            >
              {this.formatCurrency(Number(this.props.transaction.Total))}
            </AVText>
          </View>
          <View style={styles.footer} />
        </ScrollView>

        <View style={styles.floatButton}>
          <RoundedButton
            buttonType={ButtonType.outline}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            accessible={true}
            accessibilityLabel={Localized.Buttons.email_receipt}
            accessibilityRole="button"
            aria-label={Localized.Buttons.email_receipt}
            role="button"
            onPress={this.handleEmailReceipt}
            text={Localized.Buttons.email_receipt}
            backgroundColor={Styles.bgColor}
            textStyle={[
              getDescriber().scanScreenQRCodeStyleDescriptor()[
                'addToWalletText'
              ],
            ]}
            color={'#066DFF'}
            containerStyle={
              getDescriber().scanScreenQRCodeStyleDescriptor()[
                'btnEmailReceipt'
              ]
            }
          />
          <RoundedButton
            buttonType={ButtonType.outline}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            accessible={true}
            accessibilityLabel={Localized.Buttons.done}
            accessibilityRole="button"
            aria-label={Localized.Buttons.done}
            role="button"
            onPress={this.handleDone}
            text={Localized.Buttons.done}
            backgroundColor={Styles.blueRevolve}
            textStyle={[
              getDescriber().scanScreenQRCodeStyleDescriptor()['addFundsText'],
              {color: Styles.white},
            ]}
            containerStyle={[
              getDescriber().scanScreenQRCodeStyleDescriptor()['btnDone'],
              {marginLeft: Styles.Spacing.m3},
            ]}
          />
        </View>
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  floatButton: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: Styles.Spacing.m3,
    right: Styles.Spacing.m3,
  },
  footer: {
    height: 100,
  },
  content: {
    flex: 1,
    paddingHorizontal: Styles.Spacing.m3,
    paddingTop: Styles.Spacing.m3,
  },

  location: {
    marginTop: Styles.Spacing.m3,
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    fontWeight: '400',
    fontFamily: Settings.isRefive()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
  },

  pickupTime: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f0,
  },
  orderNumber: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m2,
  },
  locationName: {
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    color: Styles.darkColor,
  },
  pickupLocation: {
    color: Styles.blackBase01,
    fontSize: Styles.Fonts.f0,
  },
  transactionDate: {
    color: Styles.blackBase01,
    fontSize: Styles.Fonts.f1,
    marginTop: Styles.Spacing.m3,
  },
  transactionId: {
    color: Styles.footerTabInactive,
    fontSize: Styles.Fonts.f8,
    marginBottom: Styles.Spacing.m2,
  },
  transactionItemsDot: {
    height: 1,
    overflow: 'hidden',
    marginTop: Styles.Spacing.m2,
  },
  transactionItemsViewDot: {
    height: 2,
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: Styles.neutralHues,
  },
  transactionTotalView: {
    paddingTop: Styles.Spacing.m2,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  transactionItemsContainer: {
    marginVertical: Styles.Spacing.m1,
  },
  transactionItemsView: {
    paddingTop: Styles.Spacing.m2,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  transactionPromoView: {
    marginTop: Styles.Spacing.m1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  transactionItemsText: {
    color: Styles.blackBase01,
    fontSize: Styles.Fonts.f7,
  },
  transactionPromoText: {
    color: Styles.Colors.PayNew.success,
    fontSize: Styles.Fonts.f7,
  },
  transactionItemsSubtotal: {
    color: Styles.blackBase01,
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
  },
  transactionTotal: {
    fontWeight: 'bold',
    color: Styles.blackBase01,
    fontSize: Styles.Fonts.f10,
  },
});

const mapStateToProps = (state: RootState) => ({
  displayBalances: state.account.displayBalances,
  balances: state.account.account?.balances ?? [],
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  dispatch,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose<React.ComponentType<any>>(
  withForwardedNavigationParams<PurchaseReceiptScreenProps>(),
  withGlobalize,
  connector,
)(ScanPay365ReceiptScreen);
