import {View, PixelRatio, Platform, StyleSheet} from 'react-native';
import Styles from 'src/components/Styles';
import AVFormattedCurrency from 'src/components/elements/AVFormattedCurrency';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';

import PersonRedesign from 'src/components/img/svg/PersonRedesign';
import RewardRedesign from 'src/components/img/svg/RewardRedesign';
import Localized from 'src/constants/AppStrings';
import AccountStore from 'src/stores/AccountStore';
import AVText from 'src/components/elements/AVText';
import SnackDetailsRefive from 'src/components/img/svg/SnackDetailsRefive';

export function getBalanceRollOverIcon(): JSX.Element {
  return <RewardRedesign size={28} />;
}

export function getFundButtonForBalanceScreen(fundBtnCallback): JSX.Element {
  return (
    <RoundedAppButton
      buttonType={RoundedAppButtonType.Outline}
      buttonContainerStyle={getBalanceItemStyleDescriptor()['button']}
      buttonViewStyle={{borderWidth: 2}}
      textStyle={getBalanceItemStyleDescriptor()['fundButtonText']}
      accessibilityLabelValue={Localized.Labels.fund_account}
      aria-label={Localized.Labels.fund_account}
      onPress={fundBtnCallback}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
      titleText={Localized.Labels.fund}
    />
  );
}

export function balanceTypeStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: Styles.Fonts.f7,
    color: Styles.tabBarTextColor,
    fontWeight: '400',
  };
}

export function SaleDetailRowStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    alignItems: 'center',
    paddingLeft: Styles.Spacing.m1,
    justifyContent: 'space-between',
    paddingVertical: Styles.Spacing.m0,
    paddingRight: 0,
    flexDirection: 'row',
  };
}

export function SaleDetailSupLblMaxFontSizeMultipleDescriptor(): number {
  return Styles.FontSizeMultiplier.maxfm10;
}
export function getBalanceStripeIcon(): JSX.Element {
  return <PersonRedesign size={24} />;
}
export function SaleDetailNameStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.figtreeRegular,
    flex: 1,
    fontSize: Styles.Fonts.f7,
    marginRight: Styles.Spacing.m2,
    color: Styles.darkColor,
    flexWrap: 'wrap',
    fontWeight: '400',
  };
}
export function getDashedStyleDescriptor(): {
  [index: string]: {[index: string]: number};
} {
  return {
    dashStyle: {
      dashThickness: PixelRatio.roundToNearestPixel(1),
      dashLength: PixelRatio.roundToNearestPixel(5),
    },
  };
}

export function SaleDetailAmtStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f7,
    color: Styles.darkColor,
    fontFamily: Styles.FontFamily.figtreeRegular,
    right: 3,
    fontWeight: '400',
  };
}
export function SaleDetailModMaxFontSizeMultipleDescriptor(): number {
  return Styles.FontSizeMultiplier.maxfm8;
}
export function SaleDetailModNameStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    flexDirection: 'row',
    marginRight: Styles.Spacing.m2,
    maxWidth: '70%',
    flex: 1,
    fontSize: Styles.Fonts.f1,
    fontFamily: Styles.FontFamily.figtreeRegular,
    color: Styles.Colors.PayNew.darkHuesBase04,
  };
}

export function PurchaseDetailFormateedDateStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.darkColor,
    fontWeight: '400',
    fontSize: Styles.Fonts.f1,
    fontFamily: Styles.FontFamily.figtreeRegular,
  };
}

export function payLocationAddr(isHistory) {
  return (
    isHistory && {
      fontSize: 16,
      fontFamily: Styles.FontFamily.figtreeRegular,
    }
  );
}

export function payOrderStatus(isHistory) {
  return isHistory && {marginBottom: 20};
}

export function payOrderNumber(isHistory) {
  return isHistory && {marginTop: 20};
}

export function getDashColor(): {
  [index: string]: {[index: string]: string};
} {
  return {
    dashStyle: {
      dashColor: Styles.Colors.PayNew.neutralHuesBase09,
    },
  };
}

export function getSaleAmountTextUI(modPrice: number, saleCurrency: string) {
  return (
    <AVFormattedCurrency
      currency={saleCurrency}
      style={getStyleDescriptor()['modifierAmount']}
      value={modPrice}
      aria-label={`+$${modPrice}`}
      accessibilityLabel={`+$${modPrice}`}
    />
  );
}

export function getStyleDescriptor(
  isHistory?: boolean,
  transTypeDsply?: string,
): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    remainingBalance: {
      color: Styles.Colors.PayNew.darkHuesBase04,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.f7,
      fontWeight: '400',
      height: 22,
    },
    buttonSpacing: {
      marginBottom: Styles.Spacing.m1,
    },
    instructions: {
      fontSize: Styles.Fonts.f1,
      marginTop: Styles.Spacing.m1,
      color: Styles.darkColor,
      fontWeight: '600',
    },

    location: {
      marginTop: Styles.Spacing.m3,
      color: Styles.tabBarTextColor,
      fontSize: Styles.Fonts.f1 + 2,
      fontWeight: 'bold',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },

    pickupTime: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1,
      height: 20,
      marginTop: Styles.Spacing.m1,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },

    orderStatus: {
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      color: Styles.darkColor,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    orderStatusValue: {
      fontSize: Styles.Fonts.f7,
      fontWeight: isHistory ? '400' : '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.darkColor,
    },
    shareIconColor: {
      color: Styles.Colors.PayNew.primary01,
    },
    sectionMarginLeftWithZero: {
      marginLeft: -4,
    },
    charityRow: {backgroundColor: 'transparent'},

    points: {
      marginTop: Styles.Spacing.m1,
      color:
        isHistory &&
        ['Market Purchase', 'Mobile Purchase'].indexOf(transTypeDsply) !== -1
          ? '#066DFF'
          : Styles.primaryColor,
      fontSize:
        isHistory &&
        ['Market Purchase', 'Mobile Purchase'].indexOf(transTypeDsply) !== -1
          ? Styles.Fonts.f0 + 2
          : Styles.Fonts.f1,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },

    sectionMarginLeft: {
      marginLeft: -8,
      alignItems: 'flex-start',
    },
    pickupDate: {
      fontSize: Styles.Fonts.f1,
      marginTop: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.tabBarTextColor,
      height: 20,
      fontWeight: isHistory ? '400' : 'normal',
    },
    summaryExtra: {
      marginTop: 4,
      marginBottom: Styles.Spacing.m1,
    },
    summaryTopMargin: {
      marginTop: 0,
    },

    sectionMarginRightWithZero: {
      marginRight: -5,
    },
    modifierAmount: {
      marginRight: 2,
      fontSize: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.Colors.PayNew.darkHuesBase04,
    },
    dashStyleBorder: {
      borderRadius: 100,
      height: PixelRatio.roundToNearestPixel(2),
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m1,
      overflow: 'hidden',
      width: '100%',
    },
    totalLabel: {
      color: Styles.tabBarTextColor,
      fontSize: 24,
      fontWeight: '700',
      marginBottom: Styles.Spacing.m1,
      marginTop: -1 * Styles.Spacing.m1,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },

    rowReverse: {
      flexDirection: 'row-reverse',
      flexWrap: 'wrap',
    },
  };
}
export function balanceItemRoundedBtnColorDescriptor(): string {
  return '';
}
export function PurchaseDetailIdStyleDescriptor(isHistory?: boolean): {
  [index: string]: number | string;
} {
  return {
    marginTop: Styles.Spacing.m1,
    color: Styles.Colors.PayNew.darkHuesBase04,
    fontSize: isHistory ? Styles.Fonts.f1 - 2 : Styles.Fonts.f1,
    marginRight: Styles.Spacing.m3,
    fontWeight: isHistory ? '400' : 'normal',
    fontFamily: isHistory
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
  };
}
export function getActionBtns(
  handleDone: () => void,
  handleEmailReceipt: () => void,
) {
  return (
    <View
      style={[
        Styles.Style.row,
        getStyleDescriptor()['rowReverse'],
        {bottom: 22, position: 'absolute', right: Styles.Spacing.m2},
      ]}
    >
      <RoundedAppButton
        buttonType={RoundedAppButtonType.Solid}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        accessibilityLabelValue={Localized.Buttons.done}
        aria-label={Localized.Buttons.done}
        onPress={handleDone}
        buttonViewStyle={{paddingHorizontal: 24}}
        buttonContainerStyle={[
          {
            paddingHorizontal: Styles.Spacing.m2,
          },
          getStyleDescriptor()['buttonSpacing'],
        ]}
        textStyle={Styles.Style.actionButtonText}
        titleText={Localized.Buttons.done}
      />
      <RoundedAppButton
        buttonType={RoundedAppButtonType.Outline}
        titleText={Localized.Buttons.email_receipt}
        onPress={() => {
          handleEmailReceipt();
        }}
        buttonContainerStyle={[
          {
            paddingHorizontal: Styles.Spacing.m2,
          },
          getStyleDescriptor()['buttonSpacing'],
        ]}
        buttonViewStyle={{
          borderWidth: 2,
          paddingHorizontal: 24,
          backgroundColor: Styles.white,
        }}
        textStyle={{
          fontWeight: Platform.OS === 'android' ? 'bold' : '700',
          fontFamily: Styles.FontFamily.figtreeRegular,
          fontSize: Styles.Fonts.f0 + 4,
        }}
      />
    </View>
  );
}

export function fundingdetailTrans(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.Colors.PayNew.darkHuesBase04,
    fontSize: Styles.Fonts.f0 + 2,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: '400',
    marginBottom: 10,
  };
}
export function fundingdetailAccountFndgTxt(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.tabBarTextColor,
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: '400',
  };
}

export function PurchaseDetailDateFormatStyleDescriptor(): string {
  return 'MMMM D, YYYY [at] h:mm A';
}

export function PurchaseDetailSubtotalStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m3,
    color: Styles.Colors.PayNew.black01,
    fontFamily: Styles.FontFamily.figtreeRegular,
  };
}

export function PurchaseDetailDepositStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.figtreeRegular,
    color: Styles.Colors.PayNew.black01,
    marginBottom: Styles.Spacing.m1,
    fontSize: Styles.Fonts.f1,
  };
}

export function balanceAmountStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: '700',
    fontSize: Styles.Fonts.f7,
  };
}
export function PurchaseDetailtaxStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    marginRight: Styles.Spacing.m3,
    color: Styles.Colors.PayNew.black01,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: Styles.Fonts.f1,
  };
}
export function getBalanceItemStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      justifyContent: 'flex-start',
      paddingHorizontal: Styles.Spacing.m3,
      flex: 1,
      borderBottomColor: Styles.lightGray,
      width: '100%',
      alignItems: 'center',
      paddingVertical: Styles.Spacing.m3,
      flexDirection: 'row',
    },
    button: {
      right: Styles.Spacing.m3,
      top: Styles.Spacing.m3 + 2,
      alignSelf: 'center',
      position: 'absolute',
    },
    fundButtonText: {
      paddingHorizontal: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
    },
    balanceContainer: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      paddingHorizontal: Styles.Spacing.m3,
    },
  };
}
export function PurchaseDetailDiscountStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.figtreeRegular,
    color: Styles.positiveColor,
    fontSize: Styles.Fonts.f7,
  };
}

export function fundingDetailAccount(styles, props) {
  return (
    <View style={styles.rightView}>
      <AVFormattedCurrency
        style={[fundingdetailAccountFndgTxt()]}
        value={props.detail.Amount}
        accessibilityLabel={`$${props.detail.Amount}`}
        aria-label={`$${props.detail.Amount}`}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        currency={AccountStore.getCurrency()}
      />
    </View>
  );
}
export function PurchaseHistorySubtotalStyleDescriptor(isHistory?: boolean): {
  [index: string]: number | string;
} {
  return {
    color: Styles.Colors.PayNew.black01,
    marginBottom: Styles.Spacing.m1,
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: isHistory ? '400' : 'normal',
  };
}

export function fundingHeaderTitle() {
  return Localized.Labels.funding;
}

export function fundingdetailDate(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.tabBarTextColor,
    fontSize: Styles.Spacing.m3,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: '400',
  };
}

export function fundingDetailTotal(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.tabBarTextColor,
    fontSize: Styles.Spacing.m3 + 8,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.figtreeRegular,
  };
}

export function rightViewStyle() {
  return {
    flex: 0.3,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  };
}

export function leftViewStyle() {
  return {flex: 0.7, justifyContent: 'flex-start', alignItems: 'flex-start'};
}

export function historyEmailReceiptBtn(
  handleEmailReceipt,
  styles,
  detailID?: string,
  isUSACheck?: string,
  isHistory?: boolean,
) {
  return (
    isHistory && (
      <RoundedAppButton
        buttonType={RoundedAppButtonType.Solid}
        titleText={Localized.Buttons.email_receipts}
        onPress={() => {
          handleEmailReceipt(detailID || null);
        }}
        buttonContainerStyle={{
          alignSelf: 'flex-end',
          right: Styles.Spacing.m3,
          bottom: 22,
          position: 'absolute',
        }}
        buttonViewStyle={{
          paddingHorizontal: 32,
          paddingVertical: 12,
          minHeight: 42,
        }}
        textStyle={{
          fontWeight: Platform.OS === 'android' ? 'bold' : '700',
          fontFamily: Styles.FontFamily.figtreeRegular,
          fontSize: Styles.Fonts.f1,
          color: Styles.white,
        }}
      />
    )
  );
}

export function fundingDetailContainer() {
  return {flex: 1, flexDirection: 'row'};
}

export function payDashedLine() {
  return Styles.Colors.PayNew.neutralHuesBase09;
}

export function payPrefixTxt() {
  return 'Your ';
}

export function paySnackContainer() {
  return {top: -20};
}

export function paySnackRow() {
  return {paddingLeft: 10};
}

export function giftIconSnack() {
  return <SnackDetailsRefive />;
}

export function payBalanceType(styles) {
  return styles.paybalanceTypeDisplay;
}
export function payDteDisplay(styles) {
  return styles.payDteDisplay;
}

export function paystatus(styles) {
  return {...styles.paydescription, marginTop: -10};
}
export function paydescription(styles) {
  return styles.paydescription;
}

export function getEUDigitalRecipts(vatLabel: string, euTaxPercent: string) {
  return (
    <AVText
      accessibilityLabel={`${vatLabel} ${euTaxPercent}`}
      maxFontSizeMultiplier={SaleDetailModMaxFontSizeMultipleDescriptor()}
      aria-label={`${vatLabel} ${euTaxPercent}`}
      style={styles.euTaxStyle}
    >
      {`${vatLabel} ${euTaxPercent}`}
    </AVText>
  );
}

const styles = StyleSheet.create({
  euTaxStyle: {
    fontSize: Styles.Fonts.f8,
    color: Styles.Colors.PayNew.darkHuesBase04,
    fontFamily: Styles.FontFamily.figtreeRegular,
    paddingLeft: Styles.Spacing.m1,
  },
});

export default {
  payPrefixTxt,
  payDashedLine,
  getStyleDescriptor,
  fundingdetailTrans,
  fundingDetailContainer,
  paySnackContainer,
  getActionBtns,
  PurchaseDetailDepositStyleDescriptor,
  getBalanceRollOverIcon,
  balanceAmountStyleDescriptor,
  leftViewStyle,
  paySnackRow,
  payDteDisplay,
  balanceTypeStyleDescriptor,
  getBalanceItemStyleDescriptor,
  paystatus,
  SaleDetailModMaxFontSizeMultipleDescriptor,
  SaleDetailRowStyleDescriptor,
  SaleDetailSupLblMaxFontSizeMultipleDescriptor,
  SaleDetailNameStyleDescriptor,
  getSaleAmountTextUI,
  getFundButtonForBalanceScreen,
  balanceItemRoundedBtnColorDescriptor,
  payLocationAddr,
  SaleDetailAmtStyleDescriptor,
  SaleDetailModNameStyleDescriptor,
  PurchaseDetailFormateedDateStyleDescriptor,
  PurchaseDetailDateFormatStyleDescriptor,
  PurchaseDetailIdStyleDescriptor,
  getBalanceStripeIcon,
  fundingdetailAccountFndgTxt,
  PurchaseDetailSubtotalStyleDescriptor,
  payBalanceType,
  PurchaseDetailtaxStyleDescriptor,
  PurchaseDetailDiscountStyleDescriptor,
  fundingdetailDate,
  getDashedStyleDescriptor,
  rightViewStyle,
  fundingDetailAccount,
  getDashColor,
  paydescription,
  giftIconSnack,
  PurchaseHistorySubtotalStyleDescriptor,
  fundingHeaderTitle,
  payOrderNumber,
  fundingDetailTotal,
  payOrderStatus,
  historyEmailReceiptBtn,
  getEUDigitalRecipts,
};
