import * as React from 'react';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';

interface Props {
  size?: number;
  color?: string;
}
function TrashIcon({size, color = '#066DFF'}: Props) {
  return (
    <Svg width={size} height={size} viewBox="0 0 44 44" fill="none">
      <Path
        d="M.5 12C.5 5.649 5.649.5 12 .5h20C38.351.5 43.5 5.649 43.5 12v20c0 6.351-5.149 11.5-11.5 11.5H12C5.649 43.5.5 38.351.5 32V12z"
        fill="#fff"
      />
      <Path
        d="M.5 12C.5 5.649 5.649.5 12 .5h20C38.351.5 43.5 5.649 43.5 12v20c0 6.351-5.149 11.5-11.5 11.5H12C5.649 43.5.5 38.351.5 32V12z"
        stroke={color}
      />
      <G clipPath="url(#clip0_2295_28637)">
        <Path
          d="M19.697 12.697l-.717 1.053h7.045l-.722-1.053a.767.767 0 00-.637-.322H20.33a.773.773 0 00-.638.322h.005zm6.881-.726l1.214 1.779h4.458c.413 0 .75.31.75.688 0 .378-.337.687-.75.687h-.75v14.438c0 1.899-1.678 3.437-3.75 3.437h-10.5c-2.072 0-3.75-1.538-3.75-3.438V15.126h-.75c-.412 0-.75-.31-.75-.688 0-.378.338-.687.75-.687h4.458l1.214-1.779c.412-.606 1.134-.971 1.908-.971h4.336c.778 0 1.495.365 1.907.971h.005zM15 15.125v14.438c0 1.138 1.008 2.062 2.25 2.062h10.5c1.242 0 2.25-.924 2.25-2.063V15.126H15zm3.75 3.438v9.625c0 .378-.337.687-.75.687s-.75-.31-.75-.688v-9.625c0-.378.337-.687.75-.687s.75.31.75.688zm4.5 0v9.625c0 .378-.337.687-.75.687s-.75-.31-.75-.688v-9.625c0-.378.337-.687.75-.687s.75.31.75.688zm4.5 0v9.625c0 .378-.337.687-.75.687s-.75-.31-.75-.688v-9.625c0-.378.337-.687.75-.687s.75.31.75.688z"
          fill={color}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_2295_28637">
          <Path fill="#fff" transform="translate(12 11)" d="M0 0H21V22H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default TrashIcon;
