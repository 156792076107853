import Styles from 'src/components/Styles';

export function getHeaderBackground(content: JSX.Element): JSX.Element {
  return content;
}

export function headerContainerStyleDescriptor(): {
  [key: string]: {[index: string]: number | string};
} {
  return {
    safeArea: {
      backgroundColor: Styles.white,
    },
    container: {
      flex: 1,
      backgroundColor: Styles.white,
      flexDirection: 'column',
    },
    headerTextStyle: {
      textAlign: 'left',
      fontSize: Styles.Fonts.headerFont,
      color: Styles.headerTextColor,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.robotoRegular,
      height: Styles.Heights.touchTargetHeight1,
    },
  };
}

export function getBackIconColor(transparent: boolean): string {
  return transparent ? Styles.Colors.PayNew.black01 : Styles.lightGray;
}

export default {
  getHeaderBackground,
  headerContainerStyleDescriptor,
  getBackIconColor,
};
