import Localized from 'src/constants/AppStrings';
import Styles from '../../../Styles';
import {Platform, StyleSheet, View} from 'react-native';
import CommonUtils from '../newui/commonutils';
import UIManager from '../../../elements/ui/UIManager';
import AccountStore from 'src/stores/AccountStore';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import AVText from 'src/components/elements/AVText';
import {ButtonType} from 'src/components/elements/RoundedButton';
import PayAppOneLineLogo from 'src/components/img/svg/PayAppOneLineLogo';
import React from 'react';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import GenderDropdown from 'src/components/elements/account/GenderDropdownRedesign';
import LocaleDropdown from 'src/components/elements/account/LocaleDropdownRedesign';
import Settings from 'src/Settings';
import CustomTextInput from 'src/components/elements/CustomTextInput';
import InfoIconRedesign from 'src/components/elements/InfoIconRedesign';
import Logger from 'src/logging/Logger';

export function backHeaderDescriptor(): string {
  return Localized.Labels.change_email;
}

export function maxFontSizeMultipleDescriptor(): number {
  return Styles.FontSizeMultiplier.maxfm4;
}
export function getDeleteAcntDisclaimer(is365?: boolean) {
  return is365 ? 'voluntary_deletion_1_redesign' : 'voluntary_deletion_1';
}
export function getStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    content: {
      paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
      marginBottom: Styles.Spacing.m6,
    },
    instructions: {
      alignSelf: 'stretch',
      color: Styles.Colors.PayNew.black01,
      fontSize: Styles.Fonts.sectionHeader,
      fontWeight: '700',
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m3,
      textAlign: 'left',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
  };
}

export function getChangePasswordStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    content: {
      flex: 1,
      paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
      paddingTop: Styles.Spacing.m3,
      width: Platform.OS === 'web' ? '50%' : '100%',
    },
  };
}

export function getDeleteAcntStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    disclaimerTextBold: {
      fontWeight: '700',
      marginBottom: Styles.Spacing.m1,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: 16,
      color: '#111',
    },
    disclaimerText: {
      color: '#111',
      marginBottom: Styles.Spacing.m1,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: 16,
    },
    deleteBtn: {
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 24,
      paddingRight: 24,
    },
    deleteBtnTxt: {
      fontWeight: '700',
      fontSize: 16,
      color: '#fff',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    deleteAcntTitle: {
      fontSize: 22,
      fontWeight: '700',
      color: '#111',
      fontFamily: Styles.FontFamily.aeonikRegular,
      textAlign: 'center',
    },
    deleteAcntDesc: {
      paddingTop: 16,
      fontSize: 16,
      fontWeight: '400',
      color: '#111',
      fontFamily: Styles.FontFamily.aeonikRegular,
      textAlign: 'center',
      paddingBottom: 25,
    },
    btndelete: {
      fontSize: 16,
      fontWeight: '700',
      color: '#fff',
      fontFamily: Styles.FontFamily.aeonikRegular,
      textAlign: 'center',
    },
    btndeleteTakeMe: {
      fontSize: 16,
      fontWeight: '700',
      color: '#D7263D',
      fontFamily: Styles.FontFamily.aeonikRegular,
      textAlign: 'center',
      paddingHorizontal: 24,
    },
  };
}

export function getEditMarketCardStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    header: {
      color: Styles.black,
      fontSize: Styles.Fonts.f2,
      fontWeight: '700',
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m3,
      paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    scanditContainer: {
      alignSelf: 'stretch',
      marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
      borderRadius: Styles.Spacing.m4,
      marginTop: Styles.Spacing.m3,
      overflow: 'hidden',
      flex: 1,
      marginBottom: Styles.Spacing.m3 + Styles.Spacing.m2,
    },
  };
}

export function getHelpStyleDescriptor(is365Pay?: boolean): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    answer: {
      fontSize: Styles.Fonts.f7,
      flex: 1,
      flexWrap: 'wrap',
      color: is365Pay ? '#111' : Styles.darkColor,
      fontFamily: is365Pay
        ? Styles.FontFamily.aeonikRegular
        : Styles.FontFamily.robotoRegular,
      fontWeight: '400',
    },
    errBtn: {
      fontSize: 16,
      fontWeight: '700',
      color: '#fff',
      fontFamily: Styles.FontFamily.figtreeRegular,
      textAlign: 'center',
    },
    header: {
      marginTop: Styles.Spacing.m3,
      fontWeight: is365Pay ? '700' : '500',
      color: Styles.darkColor,
      paddingStart: Styles.Spacing.m3,
      fontSize: is365Pay ? Styles.Fonts.sectionHeader + 2 : Styles.Fonts.f2,
      marginBottom: Styles.Spacing.m2,
      fontFamily: is365Pay
        ? Styles.FontFamily.aeonikRegular
        : Styles.FontFamily.robotoRegular,
    },
    question: {
      color: is365Pay ? '#111' : Styles.primaryColor,
      fontSize: is365Pay ? Styles.Fonts.f1 + 2 : Styles.Fonts.f1,
      fontWeight: is365Pay ? '700' : '600',
      marginRight: Styles.Spacing.m1,
      fontFamily: is365Pay
        ? Styles.FontFamily.aeonikRegular
        : Styles.FontFamily.robotoRegular,
    },
    text: {
      marginBottom: Styles.Spacing.m4,
      marginTop: Styles.Spacing.m3,
      paddingHorizontal: Styles.Spacing.m3,
      color: Styles.darkColor,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    link: {
      color: Styles.primaryColor,
      textDecorationLine: 'underline',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    helpErrTitle: {
      fontSize: 22,
      fontWeight: '700',
      color: '#111',
      fontFamily: Styles.FontFamily.figtreeRegular,
      textAlign: 'center',
    },
    helpErrDesc: {
      paddingTop: 16,
      fontSize: 16,
      fontWeight: '400',
      color: '#111',
      fontFamily: Styles.FontFamily.figtreeRegular,
      textAlign: 'center',
    },
  };
}

export function getManageAcntStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    localeContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: Styles.Spacing.m2,
    },
    content: {
      flex: 1,

      paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m1 + 2,
      paddingTop: Styles.Spacing.m1,
      marginBottom: Styles.Spacing.m2,
    },
    saveBtnContainer: {
      flex: 1,
      marginTop: Styles.Spacing.m5 + Styles.Spacing.m4,
      alignSelf: 'flex-end',
    },
    localeIcon: {
      marginRight: Styles.Spacing.m0,
      marginLeft: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
    },
    menu: {
      height: Styles.Heights.h6,
      marginBottom: Styles.Spacing.m5,
      marginTop: Styles.Spacing.m2,
      marginHorizontal: 0,
    },
    localeDropdown: {
      flex: 1,
      marginTop: Styles.Spacing.m2,
    },
    errorLabel: {
      color: Styles.dangerColor,
      fontSize: Styles.Fonts.f8,
      alignSelf: 'flex-start',
      fontFamily: Styles.FontFamily.aeonikRegular,
      marginHorizontal: Styles.Spacing.m3,
      marginTop: Styles.Spacing.m1,
    },
  };
}

export function getUserProfileTextStyleDescriptors(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    content: {
      paddingTop: 4,
    },
    name: {
      fontSize: Styles.Fonts.sectionHeader,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontWeight: '700',
      color: Styles.black,
    },
    emailLocationText: {
      fontSize: Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontWeight: '400',
      marginTop: -4,
      color: Styles.black,
    },
    locationView: {
      marginTop: -6,
    },
    versionFontstyle: {
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
  };
}

export function getFirstNameManageAccount(state, maxCharacters, doSetState) {
  return (
    <CustomTextInput
      label={Localized.Labels.first_name}
      value={state.firstName}
      accessible={true}
      accessibilityLabel={Localized.Labels.first_name}
      accessibilityValue={{text: state.firstName}}
      style={[
        styles.txtInput,
        {marginTop: Styles.Spacing.m3 + Styles.Spacing.m1},
      ]}
      onChangeText={doSetState}
      autoCapitalize="words"
      maxLength={maxCharacters}
      maxFontSizeMultiplier={maxFontSizeMultipleDescriptor()}
      showShadow={true}
    />
  );
}

export function getLastNameManageAccount(state, maxCharacters, doSetState) {
  return (
    <CustomTextInput
      label={Localized.Labels.last_name}
      value={state.lastName}
      accessible={true}
      accessibilityLabel={Localized.Labels.last_name}
      accessibilityValue={{text: state.lastName}}
      style={[styles.txtInput]}
      onChangeText={doSetState}
      autoCapitalize="words"
      maxLength={maxCharacters}
      maxFontSizeMultiplier={maxFontSizeMultipleDescriptor()}
      showShadow={true}
    />
  );
}

export function getGenderDropDownManageAccount(
  state,
  genderSelected,
  handleMenuVisibilityChange,
) {
  return (
    <View
      style={{marginTop: Styles.Spacing.m2}}
      accessible={true}
      accessibilityLabel="Gender"
      accessibilityRole="menu"
      accessibilityValue={{text: state.gender}}
      accessibilityState={{
        expanded: state.genderMenuVisible,
      }}
      accessibilityElementsHidden={
        state.birthMonthMenuVisible || state.birthYearMenuVisible
      }
      importantForAccessibility={
        state.birthMonthMenuVisible || state.birthYearMenuVisible
          ? 'no-hide-descendants'
          : 'yes'
      }
      role="menu"
      aria-label={'Gender'}
      aria-valuetext={state.gender}
      aria-expanded={state.genderMenuVisible}
    >
      <GenderDropdown
        selectedValue={state.gender}
        onSelect={genderSelected}
        onVisibilityChange={() =>
          handleMenuVisibilityChange(!state.genderMenuVisible, 'gender')
        }
      />
    </View>
  );
}

export function getBirthMonthDropDownManageAccount() {
  return null;
}

export function getBirthYearDropDownManageAccount(
  state,
  birthMonthYearSelected,
) {
  return (
    <View
      accessible={true}
      accessibilityLabel="Birth Year"
      accessibilityRole="menu"
      accessibilityValue={{text: state.birthYear}}
      accessibilityState={{
        expanded: state.birthYearMenuVisible,
      }}
      accessibilityElementsHidden={
        state.genderMenuVisible || state.birthMonthMenuVisible
      }
      importantForAccessibility={
        state.genderMenuVisible || state.birthMonthMenuVisible
          ? 'no-hide-descendants'
          : 'yes'
      }
      role="menu"
      aria-label={'Birth Year'}
      aria-valuetext={state.birthYear}
      aria-expanded={state.birthYearMenuVisible}
    >
      <CustomTextInput
        label={Localized.Labels.redesign_birthday}
        value={state.birthMonthYear}
        accessible={true}
        onChangeText={birthMonthYearSelected}
        accessibilityLabel={Localized.Labels.redesign_birthday}
        accessibilityValue={{text: state.birthMonthYear}}
        style={[
          styles.txtInput,
          {marginTop: Styles.Spacing.m3 + Styles.Spacing.m2},
        ]}
        autoCapitalize="none"
        keyboardType="number-pad"
        maxLength={7}
        returnKeyType="done"
        maxFontSizeMultiplier={maxFontSizeMultipleDescriptor()}
        showShadow={true}
      />
      {getBdayError(state)}
    </View>
  );
}

function getBdayError(state) {
  if (state.birthMonthYearError != '') {
    return (
      <AVText
        style={getManageAcntStyleDescriptor()['errorLabel']}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
      >
        {state.birthMonthYearError}
      </AVText>
    );
  } else return null;
}

export function getJobTitleManageAccount() {
  return null;
}

export function getSystemLanguageDropDownManageAccount(
  maxWidth,
  state,
  localeSelected,
  handleMenuVisibilityChange,
) {
  return (
    <View
      style={
        getManageAcntStyleDescriptor() &&
        getManageAcntStyleDescriptor()['localeContainer']
      }
    >
      <View
        style={[
          getManageAcntStyleDescriptor()['localeDropdown'],
          getManageAcntStyleDescriptor()['dropdownContainer'],
          {
            width: maxWidth - Styles.Spacing.m2,
            marginHorizontal: 2,
          },
        ]}
        accessible={true}
        accessibilityLabel="Locale drop down"
        accessibilityRole="menu"
        accessibilityValue={{text: state.locale}}
        accessibilityState={{
          expanded: state.localeMenuVisible,
        }}
        accessibilityElementsHidden={
          state.genderMenuVisible ||
          state.birthMonthMenuVisible ||
          state.birthYearMenuVisible
        }
        importantForAccessibility={
          state.genderMenuVisible ||
          state.birthMonthMenuVisible ||
          state.birthYearMenuVisible
            ? 'no-hide-descendants'
            : 'yes'
        }
        role="menu"
        aria-label="Locale drop down"
        aria-valuetext={state.locale}
        aria-expanded={state.localeMenuVisible}
      >
        <LocaleDropdown
          style={{flex: 1}}
          selectedValue={state.locale}
          onSelect={localeSelected}
          onVisibilityChange={() =>
            handleMenuVisibilityChange(!state.localeMenuVisible, 'locale')
          }
        />
      </View>
      <View
        accessible={true}
        accessibilityLabel="Information"
        accessibilityRole="imagebutton"
        accessibilityHint="Double tap to show the help information"
        aria-label="Information"
        role="button"
        style={
          getManageAcntStyleDescriptor() &&
          getManageAcntStyleDescriptor()['localeIcon']
        }
      >
        <InfoIconRedesign
          infoMessage={Localized.Labels.formatString(
            Localized.Labels.system_language_explanation,
            Settings.appDisplayName,
          )}
        />
      </View>
    </View>
  );
}

export function getChangeEmail() {
  return null;
}

export function deleteBtnColorrDescriptor(): string {
  return Styles.dangerColor;
}

export function showIcon(openWebPage: () => void, brandTitle: string) {
  return (
    <>
      <View style={styles.revolveMarketSingleLineLogo}>
        <PayAppOneLineLogo />
      </View>
      <AVTouchableOpacity onPress={openWebPage}>
        <AVText
          style={styles.payAppLink}
          accessible={true}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
          accessibilityLabel={brandTitle}
          accessibilityRole="text"
          aria-label={`365retailmarkets.com`}
        >
          {brandTitle}
        </AVText>
      </AVTouchableOpacity>
    </>
  );
}

export function signOutColor() {
  return Styles.Colors.PayNew.primary01;
}
export function signOutButtonType() {
  return ButtonType.outline;
}

const styles = StyleSheet.create({
  revolveMarketSingleLineLogo: {
    marginTop: Styles.Spacing.m3,
  },
  payAppLink: {
    marginTop: Styles.Spacing.m1,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '400',
    fontSize: Styles.Fonts.f7,
    textDecorationLine: 'underline',
    color: Styles.Colors.PayNew.primary01,
  },
  txtInput: {
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: Styles.black,
    fontWeight: '400',
    height: 56,
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m1,
  },
  shadow: {
    elevation: 1,
    shadowOffset: {
      height: 1,
      width: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 0.2,
    borderRadius: 8,
  },
});

export function getHelpAndOtherStr() {
  return Localized.Labels.support;
}

export function getSettingsOptions(handlePrivacyPolicyUrl: () => void) {
  const settings = [];
  settings.push({
    accessibilityLabel: Localized.Labels.personal_info,
    accessibilityHint: 'Double tap to view or update your personal information',
    onPressed: CommonUtils.toManageAccount,
    label: Localized.Labels.personal_info,
  });
  settings.push({
    accessibilityLabel: Localized.Labels.link_location,
    accessibilityHint: 'Double tap to go to link location',
    onPressed: CommonUtils.handleLinkLocation,
    label: Localized.Labels.link_location,
  });
  if (
    AccountStore.isConsumerSendSnackEnabled() &&
    AccountStore.isSendSnackEnabledForLocation()
  ) {
    settings.push({
      accessibilityLabel: Localized.Labels.blocked_users,
      accessibilityHint: 'Double tap to navigate to the blocked users screen',
      onPressed: CommonUtils.blockedUsersClicked,
      label: Localized.Labels.blocked_users,
    });
  }
  settings.push({
    accessibilityLabel: Localized.Labels.view_privacy_policy,
    accessibilityHint: 'Double tap to view the privacy policy',
    onPressed: handlePrivacyPolicyUrl,
    label: Localized.Labels.view_privacy_policy,
  }),
    settings.push({
      accessibilityLabel: Localized.Labels.view_terms_and_conditions,
      accessibilityHint: 'Double tap to view terms and conditions',
      onPressed: CommonUtils.handleTerms,
      label: Localized.Labels.view_terms_and_conditions,
    });
  settings.push({
    accessibilityLabel: Localized.Labels.delete_account,
    accessibilityHint: 'Double tap to navigate to the delete account screen',
    onPressed: CommonUtils.handleDeleteAccount,
    label: Localized.Labels.delete_account,
  });
  if (Logger.Log.isLogEnabled()) {
    settings.push({
      accessibilityLabel: Localized.Labels.send_logs_to_app_developers,
      accessibilityHint: 'Double tap to send logs to app developers',
      onPress: () => Logger.Log.shareLogFile(),
      label: Localized.Labels.send_logs_to_app_developers,
      isShareFile: true,
    });
  }
  return settings;
}

export function getParentContainerStyle(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    parentContainer: {
      backgroundColor: Styles.tabBarBackgroundColor,
    },
  };
}

export function getHistoryOptions() {
  const settings = [];
  settings.push({
    accessibilityLabel: Localized.Labels.receipts,
    accessibilityHint: 'Double tap to view receipts',
    onPressed: CommonUtils.toPurchaseHistoryScreen,
    label: Localized.Labels.receipts,
  });
  return settings;
}

export function getSecurityOptions() {
  return null;
}

export function getHelpAndOtherOptions() {
  let helpAndOther = [];
  helpAndOther = [
    {
      accessibilityLabel: UIManager.getHelpLabel(),
      accessibilityHint: 'Double tap to navigate to the help screen',
      onPressed: CommonUtils.contactClicked,
      label: UIManager.getHelpLabel(),
    },
    {
      accessibilityLabel: Localized.Labels.change_email,
      accessibilityHint: 'Double tap to change your email',
      onPressed: CommonUtils.handleChangeEmail,
      label: Localized.Labels.change_email,
    },
    {
      accessibilityLabel: Localized.Labels.change_pin,
      accessibilityHint: 'Double tap to change your pin',
      onPressed: CommonUtils.handleChangePin,
      label: Localized.Labels.change_pin,
    },
  ];
  return helpAndOther;
}

const getPaymentOptions = () => {
  const payment = [];
  payment.push({
    accessibilityLabel: Localized.Labels.wallet,
    accessibilityHint: 'Double tap to view your wallet',
    onPressed: CommonUtils.toWalletScreen,
    label: Localized.Labels.wallet,
  });
  return payment;
};

const getGiftingOptions = (_referralAmount?: string) => {
  const giftOptions: Array<object> = [];
  if (
    AccountStore.isConsumerSendSnackEnabled() &&
    AccountStore.isSendSnackEnabledForLocation()
  ) {
    giftOptions.push({
      accessibilityLabel: Localized.Labels.send_a_snack_title,
      accessibilityHint: 'Double tap to navigate to the send a snack screen',
      onPressed: CommonUtils.sendasnackClicked,
      label: Localized.Labels.send_a_snack_title,
    });
  }

  if (AccountStore?.getReferralCampaign() && AccountStore?.getReferralStat()) {
    const amountWithCurrency = _referralAmount?.split('.')[0];

    giftOptions.push({
      accessibilityLabel: Localized.Labels.formatString(
        Localized.Labels.referral_invite_friends,
        amountWithCurrency,
        amountWithCurrency,
      ),
      accessibilityHint: 'Double tap to navigate to the referral screen',
      onPressed: CommonUtils.referralClicked,
      label: Localized.Labels.formatString(
        Localized.Labels.referral_invite_friends,
        amountWithCurrency,
        amountWithCurrency,
      ),
    });
  }

  return giftOptions;
};

export function getLinkAccountsOptions() {
  const linkAccounts: Array<object> = [];
  if (AccountStore.getMarketCard()) {
    linkAccounts.push({
      accessibilityLabel: Localized.Labels.edit_market_card,
      accessibilityHint: 'Double tap to edit your market card',
      onPressed: CommonUtils.handleMarketCard,
      label: Localized.Labels.edit_market_card,
    });
  } else {
    linkAccounts.push({
      accessibilityLabel: Localized.Labels.scan_market_card,
      accessibilityHint: 'Double tap to scan a market card',
      onPressed: CommonUtils.handleMarketCard,
      label: Localized.Labels.scan_market_card,
    });
  }
  linkAccounts.push({
    accessibilityLabel: Localized.Labels.scan_kiosk_qr_code,
    accessibilityHint: 'Double tap to scan kiosk qr code',
    onPressed: CommonUtils.scanKioskQrCode, //To be done as part of SOS-58257
    label: Localized.Labels.scan_kiosk_qr_code,
  });
  return linkAccounts;
}

export function getBrandWebPage() {
  return 'https://www.365retailmarkets.com/';
}

export function getBrandTitle() {
  return '365retailmarkets.com';
}

export function getSignOutButton(onLogoutSelect: () => void): JSX.Element {
  return (
    <React.Fragment>
      <RoundedAppButton
        titleText={Localized.Buttons.signout}
        onPress={onLogoutSelect}
        buttonViewStyle={{
          height: 38,
          borderWidth: 2,
          backgroundColor: Styles.white,
        }}
        buttonContainerStyle={{marginTop: Styles.Spacing.m2}}
        buttonType={RoundedAppButtonType.Outline}
        accessibilityLabelValue={Localized.Buttons.signout}
        accessibilityHintValue="Double tap to log out"
        aria-label={Localized.Buttons.signout}
        textStyle={{
          fontFamily: Styles.FontFamily.aeonikRegular,
          fontWeight: '700',
          fontSize: Styles.Fonts.f1,
        }}
      />
    </React.Fragment>
  );
}

function generateEmailInputFields({
  labelNewEmail,
  valueNewEmail,
  onChangeNewEmail,
  labelConfirmEmail,
  valueConfirmEmail,
  onChangeConfirmEmail,
  maxFontSizeMultiplier,
}) {
  return (
    <>
      <CustomTextInput
        label={labelNewEmail}
        value={valueNewEmail}
        accessible={true}
        accessibilityLabel={valueNewEmail}
        accessibilityValue={{text: valueNewEmail}}
        style={[styles.txtInput, {marginTop: Styles.Spacing.m3}]}
        onChangeText={onChangeNewEmail}
        autoCapitalize="none"
        keyboardType="email-address"
        maxFontSizeMultiplier={maxFontSizeMultiplier()}
        showShadow={true}
      />

      <CustomTextInput
        label={labelConfirmEmail}
        value={valueConfirmEmail}
        accessible={true}
        accessibilityLabel={labelConfirmEmail}
        accessibilityValue={{text: valueConfirmEmail}}
        style={[styles.txtInput, {marginTop: Styles.Spacing.m3}]}
        onChangeText={onChangeConfirmEmail}
        autoCapitalize="none"
        keyboardType="email-address"
        maxFontSizeMultiplier={maxFontSizeMultiplier()}
        showShadow={true}
      />
    </>
  );
}

function generateChangeEmailRoundedButton({
  accessibilityLabel,
  accessibilityHint,
  ariaLabel,
  buttonText,
  onPressHandler,
  isDisabled,
}) {
  return (
    <RoundedAppButton
      accessibilityLabelValue={accessibilityLabel}
      accessibilityHintValue={accessibilityHint}
      aria-label={ariaLabel}
      buttonType={RoundedAppButtonType.Solid}
      titleText={buttonText}
      onPress={onPressHandler}
      buttonContainerStyle={{
        right: Styles.Spacing.m3,
        bottom: 22,
        position: 'absolute',
      }}
      buttonViewStyle={{height: 42, minWidth: 101}}
      textStyle={{fontSize: Styles.Fonts.f1}}
      isDisabled={isDisabled}
      inActiveButtonViewStyle={{
        height: 42,
        backgroundColor: Styles.Colors.PayNew.darkHuesBase04,
        minWidth: 101,
      }}
      inActiveTextStyle={{fontSize: Styles.Fonts.f1}}
    />
  );
}

export default {
  backHeaderDescriptor,
  maxFontSizeMultipleDescriptor,
  getStyleDescriptor,
  getChangePasswordStyleDescriptor,
  getDeleteAcntStyleDescriptor,
  getEditMarketCardStyleDescriptor,
  getHelpStyleDescriptor,
  getManageAcntStyleDescriptor,
  getParentContainerStyle,
  getDeleteAcntDisclaimer,
  showIcon,
  signOutColor,
  signOutButtonType,
  getSettingsOptions,
  getHistoryOptions,
  getSecurityOptions,
  getHelpAndOtherOptions,
  getPaymentOptions,
  getHelpAndOtherStr,
  getUserProfileTextStyleDescriptors,
  getBrandWebPage,
  getLinkAccountsOptions,
  getGiftingOptions,
  getBrandTitle,
  getSignOutButton,
  getSystemLanguageDropDownManageAccount,
  getJobTitleManageAccount,
  getBirthYearDropDownManageAccount,
  getBirthMonthDropDownManageAccount,
  getGenderDropDownManageAccount,
  getLastNameManageAccount,
  deleteBtnColorrDescriptor,
  getFirstNameManageAccount,
  getChangeEmail,
  generateEmailInputFields,
  generateChangeEmailRoundedButton,
};
