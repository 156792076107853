import React from 'react';
import {Platform, StyleProp, StyleSheet, ViewStyle} from 'react-native';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import NavActions from 'src/actions/NavActions';
import Styles from '../Styles';
import Header from './Header';
import AVTouchableOpacity from './AVTouchableOpacity';
import Settings from 'src/Settings';
import BackIcon from 'src/components/img/svg/BackIcon';
import BuildTypeConstants from 'src/constants/BuildTypeConstants';
import BackArrow365 from 'src/components/img/svg/BackArrow365';
import Localized from 'src/constants/AppStrings';
import {getHeaderDescriber} from './headerDescriptor/DescriptorType';

type BackSubheaderProps = {
  onBackSelect?: () => void | Promise<void>;
  style?: any;
  title: string | string[];
  popToTop?: boolean;
  textStyle?: any;
  pop?: boolean;
  rightView?: React.ReactNode;
  bottomView?: React.ReactNode;
  children?: React.ReactNode;
  previousRoute?: string;
  checkout?: boolean;
  accessibilityLabel?: string;
  accessibilityHint?: string;
  contentStyle?: StyleProp<ViewStyle>;
  transparentHeader?: boolean;
  onboardingHeader?: boolean;
};

const touchTarget = Settings.isNewUI()
  ? Styles.Heights.touchTargetHeight1
  : Styles.Heights.touchTargetHeight2;

const BackSubheader = (props: BackSubheaderProps) => {
  const onBackSelect = () => {
    if (props.pop) {
      if (props.popToTop) {
        NavActions.popToTop();
      } else {
        NavActions.pop();
      }
    }

    if (props.onBackSelect) {
      props.onBackSelect();
    }
  };

  const checkbackArrowColor = () => {
    return Platform.OS === 'web'
      ? Styles.Colors.PayNew.black01
      : Styles.Colors.PayNew.white01;
  };

  const getBackIconView = () => {
    const {getBackIconColor} = getHeaderDescriber();
    if (Settings.isNewUI()) {
      if (
        Settings.buildType === BuildTypeConstants.default ||
        Platform.OS == 'web'
      ) {
        return (
          <BackArrow365
            fill={props.transparentHeader ? '#000' : checkbackArrowColor()}
          />
        );
      } else {
        return (
          <BackIcon
            size={Styles.Heights.headerIconHeight}
            color={
              props.onboardingHeader
                ? '#111111'
                : getBackIconColor(props.transparentHeader)
            }
          />
        );
      }
    }
    return (
      <FontAwesome5Pro
        name="chevron-left"
        color={Styles.Colors.PayNew.black01}
        size={Styles.Fonts.f2}
        style={styles.backIcon}
        light
      />
    );
  };
  return (
    <Header
      title={props.title}
      rightView={props.rightView}
      onTitlePress={onBackSelect}
      style={props.style}
      contentStyle={props.contentStyle}
      transparentHeader={props.transparentHeader}
      onboardingHeader={props.onboardingHeader}
      checkout={props.checkout}
      leftView={
        <AVTouchableOpacity
          testID="backButton"
          style={[
            styles.close,
            {
              paddingLeft: Settings.isRefiveAnd365Pay() ? 0 : Styles.Spacing.m1,
            },
            Settings.is365Pay() && {
              paddingTop: Styles.Spacing.m1 * 1.5,
            },
            Platform.OS === 'web' &&
              props.title === Localized.Labels.notifications && {
                display: 'none',
              },
          ]}
          accessibilityHint={props?.accessibilityHint}
          accessibilityLabel={props?.accessibilityLabel}
          accessibilityRole={'button'}
          onPress={onBackSelect}
        >
          {getBackIconView()}
        </AVTouchableOpacity>
      }
      bottomView={props.bottomView}
    >
      {props.children}
    </Header>
  );
};

BackSubheader.defaultProps = {
  popToTop: false,
  pop: true,
};

const styles = StyleSheet.create({
  backIcon: {
    marginVertical: Styles.Spacing.m2,
  },
  close: {
    alignItems: Platform.OS === 'web' ? 'flex-end' : 'flex-start',
    justifyContent: Platform.OS === 'web' ? 'center' : 'flex-start',

    paddingTop:
      Platform.OS === 'web' ? Styles.Spacing.m1 / 2 : Styles.Spacing.m1,
    height: Platform.OS === 'web' ? undefined : touchTarget,
    width: Platform.OS === 'web' ? undefined : touchTarget,
  },
});

export default BackSubheader;
