import * as React from 'react';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Localized from 'src/constants/AppStrings';
import Styles from 'src/components/Styles';
type GiveCreditFooterProps = {
  onPress: () => void;
};

class GiveCreditFooter extends React.Component<GiveCreditFooterProps> {
  render() {
    return (
      <RoundedButton
        buttonType={ButtonType.action}
        onPress={this.props.onPress}
        accessibilityLabel="Add Credit"
        text={Localized.Labels.start_purchasing}
        color={Styles.Colors.PayNew.primary01}
      />
    );
  }
}

export default GiveCreditFooter;
