import Styles from '../../../Styles';
import {StyleSheet, View} from 'react-native';
import Localized from 'src/constants/AppStrings';
import React from 'react';
import CreditCardInput from 'src/components/elements/funding/CreditCardInput';
import {CreditCard} from 'src/models/CreditCard';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import Star from 'src/components/img/svg/inbox/Star';
import AVText from 'src/components/elements/AVText';
import Switch from 'src/components/elements/Switch';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';

export function getCardDetailsTitle(): string {
  return Localized.Labels.credit_card;
}

export function getCardDetailsView(props: CreditCard): JSX.Element {
  return (
    <React.Fragment>
      <View style={styles.container}>
        <View style={styles.row}>
          <CreditCardInput
            accessibilityLabel={Localized.Labels.card_number}
            label={Localized.Labels.card_number}
            editable={false}
            issuer={props.cardIssuer}
            value={props.name}
          />
        </View>
        <View style={[styles.row, {marginTop: -30}]}>
          <View style={styles.leftTxtInput}>
            <AllyTextInput
              label={Localized.Labels.expiration_month}
              value={props.expirationMonth.toString()}
              defaultValue={props.expirationMonth.toString()}
              editable={false}
              accessible={true}
              accessibilityLabel={Localized.Labels.expiration_month}
              accessibilityValue={{
                text: props.expirationMonth.toString(),
              }}
              accessibilityState={{disabled: true}}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
            />
          </View>
          <View style={styles.rightTxtInput}>
            <AllyTextInput
              label={Localized.Labels.expiration_year}
              value={props.expirationYear.toString()}
              defaultValue={props.expirationYear.toString()}
              editable={false}
              accessible={true}
              accessibilityLabel={Localized.Labels.expiration_year}
              accessibilityValue={{
                text: props.expirationYear.toString(),
              }}
              accessibilityState={{disabled: true}}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
            />
          </View>
        </View>
        <View>
          <AllyTextInput
            label={Localized.Labels.card_type}
            value={props.cardIssuer}
            defaultValue={props.cardIssuer}
            editable={false}
            accessible={true}
            accessibilityLabel={Localized.Labels.card_type}
            accessibilityValue={{text: props.cardIssuer}}
            accessibilityState={{disabled: true}}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
          />
        </View>
      </View>
    </React.Fragment>
  );
}

export function getCardExpiredView(_isExpired: boolean): JSX.Element {
  return null;
}

export function getDefaultStatusView(
  props: CreditCard,
  defaultTokenStatus: boolean,
  switchAction: (value: boolean) => void,
  _isExpired: boolean,
): JSX.Element {
  if (props.isDefault) {
    return (
      <React.Fragment>
        <View style={styles.defaultStatusView}>
          <Star width={Styles.Fonts.f4} height={Styles.Fonts.f4} />
          <AVText
            accessible={true}
            accessibilityLabel={
              Localized.Labels.this_card_is_set_as_your_default
            }
            accessibilityRole="text"
            aria-label={`${Localized.Labels.this_card_is_set_as_your_default}, text`}
            style={styles.defaultLabel}
          >
            {Localized.Labels.this_card_is_set_as_your_default}
          </AVText>
        </View>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <View style={styles.defaultStatusView}>
          <Switch
            accessibilityLabel={Localized.Labels.set_as_default}
            accessibilityState={{checked: defaultTokenStatus}}
            text={Localized.Labels.set_as_default}
            testID="setAsDefault"
            onValueChange={(value) => switchAction(value)}
            value={defaultTokenStatus}
          />
        </View>
      </React.Fragment>
    );
  }
}

export function getDeleteButton(deleteAction: () => void): JSX.Element {
  return (
    <RoundedButton
      buttonType={ButtonType.action}
      color={Styles.dangerColor}
      accessible={true}
      accessibilityLabel={Localized.Buttons.delete_card}
      accessibilityRole="button"
      role="button"
      aria-label={Localized.Buttons.delete_card}
      onPress={deleteAction}
      text={Localized.Buttons.delete_card}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    marginHorizontal: Styles.Spacing.m3,
  },
  leftTxtInput: {
    flex: 0.5,
    flexDirection: 'column',
    marginRight: Styles.Spacing.m1,
  },
  defaultStatusView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m2,
  },
  rightTxtInput: {
    flexDirection: 'column',
    flex: 0.5,
    marginLeft: Styles.Spacing.m1,
  },
  defaultLabel: {
    fontSize: Styles.Fonts.f0,
    marginLeft: Styles.Spacing.m2,
    marginRight: Styles.Spacing.m1,
    fontStyle: 'italic',
  },
  row: {
    flexDirection: 'row',
  },
});

export default {
  getCardDetailsView,
  getDeleteButton,
  getDefaultStatusView,
  getCardExpiredView,
  getCardDetailsTitle,
};
