import AccountStore from 'src/stores/AccountStore';
import DemoAccountActions from './DemoAccountActions';
import AccountActions from './AccountActions';

class ActionsFactory {
  actions = new AccountActions();

  getAccountActions(): AccountActions {
    if (AccountStore.isDemo()) {
      return DemoAccountActions as unknown as AccountActions;
    } else {
      return this.actions;
    }
  }
}

export default new ActionsFactory();
