import React from 'react';
import Commonutils from 'src/components/elements/newui/commonutils';

type SettingsSectionMenuProps = {
  children: React.ReactNode;
  accessibilityLabel?: string;
  title: string;
  switchStatus?: boolean;
  onSwitchToggle?: () => void | null;
};
export default function SettingsMenuSection(props: SettingsSectionMenuProps) {
  return Commonutils.renderCommonSection(props);
}
