import React from 'react';
import {StyleSheet, View} from 'react-native';
import Styles from '../Styles';
import AVText from './AVText';
import Localized from 'src/constants/AppStrings';
import RoundedButton, {ButtonType} from './RoundedButton';
import FontAwesome5Pro from '../icons/FontAwesomeIcon';
import type {ReferralStat} from 'src/types/Referral';
type ReferralSectionProps = {
  stats: ReferralStat;
  onInviteClick: () => void;
};
export default (props: ReferralSectionProps) => {
  const {stats} = props;
  const referralsToDate = Localized.Labels.formatString(
    Localized.Labels.referrals_to_date,
    stats.referrals ?? '',
  );
  const signupsAchieved = Localized.Labels.formatString(
    Localized.Labels.signups_achieved,
    stats.signUps ?? '',
  );
  return (
    <View style={styles.container}>
      <FontAwesome5Pro
        accessibilityElementsHidden={true}
        importantForAccessibility="no-hide-descendants"
        color={Styles.primaryColor}
        name="paper-plane"
        size={Styles.Fonts.f2}
      />
      <View style={styles.mainContent}>
        <AVText
          accessible={true}
          accessibilityRole="text"
          accessibilityLabel={Localized.Labels.invite_your_friends}
          aria-label={`${Localized.Labels.invite_your_friends}, text`}
          style={styles.title}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
        >
          {Localized.Labels.invite_your_friends}
        </AVText>
        <View style={styles.lowerContainer}>
          <View>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={referralsToDate.toString()}
              aria-label={`${referralsToDate}, text`}
              style={styles.label}
            >
              {referralsToDate}
            </AVText>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={signupsAchieved.toString()}
              aria-label={`${signupsAchieved}, text`}
              style={styles.label}
            >
              {signupsAchieved}
            </AVText>
          </View>
          <RoundedButton
            buttonType={ButtonType.normal}
            text={Localized.Labels.invite}
            containerStyle={styles.button}
            onPress={props.onInviteClick}
            role="button"
            aria-label={Localized.Labels.invite}
            accessibilityLabel={Localized.Labels.invite}
            accessibilityRole="button"
            accessible={true}
            color={Styles.Colors.PayNew.primary01}
          />
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    borderColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopWidth: StyleSheet.hairlineWidth,
    flexDirection: 'row',
    paddingVertical: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
  },
  mainContent: {
    marginLeft: Styles.Spacing.m2,
    alignSelf: 'stretch',
    flex: 1,
    marginTop: -Styles.Spacing.m1,
  },
  title: {
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
    marginBottom: Styles.Spacing.m1,
    color: Styles.darkColor,
  },
  label: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
  lowerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    alignSelf: 'flex-end',
  },
});
