import React from 'react';
import {StyleSheet, View, ScrollView, Dimensions} from 'react-native';
import HTML from 'react-native-render-html';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import Styles from '../../Styles';
import SafeAreaView from '../../elements/SafeAreaView';
import PrimaryButton from '../../elements/PrimaryButton';
import Button from '../../elements/Button';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import Header from '../../elements/Header';
import Settings from 'src/Settings';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import Switch from 'src/components/elements/Switch';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';

const {height: deviceHeight} = Dimensions.get('window');
type PrivacyPolicyScreenProps = {
  title: string;
  onDecline: () => void;
  onConfirm: () => void;
  version: string;
  locale: string;
  type: string;
};

type PrivacyPolicyScreenState = {
  acceptedPolicy: boolean;
  acceptDisabled: boolean;
  policyHtml: string;
};

export class PrivacyPolicyScreen extends React.Component<
  PrivacyPolicyScreenProps,
  PrivacyPolicyScreenState
> {
  scrollView: ScrollView;
  scrollHeight: number;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: PrivacyPolicyScreenProps) {
    super(props);
    this.state = {
      acceptedPolicy: false,
      acceptDisabled: true,
      policyHtml: '<html />',
    };
    this.scrollHeight = 0;
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
    this.handlePrivacyScroll = this.handlePrivacyScroll.bind(this);
    this.onContentSizeChange = this.onContentSizeChange.bind(this);
    this.onScrollLayout = this.onScrollLayout.bind(this);
  }

  async componentDidMount() {
    try {
      const html = await Settings.getPrivacyPolicyHtml(
        this.props.locale,
        this.props.version,
        this.props.type,
      );
      this.setState({
        policyHtml: html,
      });
    } catch (error) {
      const url = Settings.getPrivacyPolicyUrl(
        this.props.locale,
        this.props.version,
        this.props.type,
      );
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'PrivacyPolicyScreen:ComponentDidMount',
        generateErrorMessage(error),
        guid,
        {
          url,
        },
      );
      Events.Error.trackEvent(
        'Exception',
        'PrivacyPolicyScreen:ComponentDidMount',
        generateErrorMessage(error),
        guid,
        {
          url,
        },
      );
      alertError(error.toString(), guid);
    }
  }

  handleDecline() {
    FirebaseAnalytic.trackEvent('handleDecline', 'PrivacyPolicyScreen', {
      ...this.props,
      ...this.state,
    });

    this.props.onDecline();
  }

  handleConfirm() {
    if (this.state.acceptedPolicy) {
      this.props.onConfirm();
    } else {
      alertError(Localized.Errors.must_accept_privacy_policy);
    }
  }

  handlePrivacyScroll(e: any) {
    const height = e.nativeEvent.contentSize.height;
    const offset = e.nativeEvent.contentOffset.y;

    if (deviceHeight + offset + 20 >= height) {
      this.setState({
        acceptDisabled: false,
      });
    }
  }

  onContentSizeChange(contentWidth: number, contentHeight: number) {
    if (this.scrollHeight > 0 && contentHeight <= this.scrollHeight) {
      this.setState({
        acceptDisabled: false,
      });
    } else {
      this.setState({
        acceptDisabled: true,
      });
    }
  }

  onScrollLayout({
    nativeEvent: {
      layout: {height},
    },
  }: any) {
    this.scrollHeight = height;
  }

  render() {
    return (
      <SafeAreaView
        style={Styles.Style.flex}
        forceInset={{
          top: 'never',
        }}
      >
        <Header title={this.props.title}>
          <ScrollView
            ref={(scroll) => {
              this.scrollView = scroll;
            }}
            onLayout={this.onScrollLayout}
            style={styles.scroll}
            onContentSizeChange={this.onContentSizeChange}
            onScroll={this.handlePrivacyScroll}
            scrollEventThrottle={16}
          >
            <HTML source={{html: this.state.policyHtml}} />
          </ScrollView>
          <View
            nativeID="privacyPolicySwitchFull"
            style={styles.agreeToggleRow}
          >
            <Switch
              text={Localized.Labels.agree_to_privacy_policy}
              textColor={Styles.lightGray}
              testID={'privacyPolicySwitch'}
              accessibilityLabel="Accept Privacy Policy"
              accessibilityLabelledBy={'privacyPolicySwitchFull'}
              accessibilityState={{
                disabled: this.state.acceptDisabled,
                selected: this.state.acceptedPolicy,
              }}
              value={this.state.acceptedPolicy}
              onValueChange={(value: boolean) => {
                this.setState({
                  acceptedPolicy: !value,
                });
              }}
            />
          </View>
          <View style={styles.buttonRow}>
            <Button
              accessible={true}
              overrideStyles={styles.negativeBtn}
              onPress={this.handleDecline}
              accessibilityLabel="Decline"
              text={Localized.Buttons.decline}
            />
            <PrimaryButton
              accessible={true}
              overrideStyles={styles.override}
              onPress={this.handleConfirm}
              accessibilityLabel="Confirm"
              text={Localized.Buttons.confirm}
            />
          </View>
        </Header>
      </SafeAreaView>
    );
  }
}

const buttonHeight = 60;
const styles = StyleSheet.create({
  agreeLabel: {
    flex: 1,
    marginRight: Styles.Spacing.m1,
  },
  agreeToggleRow: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: Styles.Spacing.m1,
    borderTopColor: 'black',
    borderTopWidth: 1,
    paddingLeft: 10,
  },
  buttonRow: {
    alignItems: 'stretch',
    alignSelf: 'stretch',
    flexDirection: 'row',
    height: buttonHeight,
  },
  content: {
    flex: 1,
  },
  negativeBtn: {
    backgroundColor: Styles.darkColor,
    borderRadius: 0,
    flex: 1,
    height: buttonHeight,
  },
  override: {
    borderRadius: 0,
    flex: 1,
    height: buttonHeight,
    marginLeft: 1,
  },
  scroll: {
    backgroundColor: Styles.white,
    padding: Styles.Spacing.m1,
    height: deviceHeight - buttonHeight - 20,
  },
  switch: {
    backgroundColor: Styles.lightGray,
    borderRadius: Styles.Spacing.m2,
    overflow: 'hidden',
  },
});

export default withForwardedNavigationParams<PrivacyPolicyScreenProps>()(
  PrivacyPolicyScreen,
);
