import {ColorValue, TextStyle} from 'react-native';
import {RoundedBtnDescriberProps} from 'src/components/elements/buttonDescriptor/DescriptorType';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import {ButtonType} from 'src/components/elements/RoundedButton';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';

export function roundedBtnColorDescriptor(props: RoundedBtnDescriberProps): {
  [index: string]: ColorValue;
} {
  const disabledColor = Styles.lightGray;
  const buttonColor = props.disabled
    ? disabledColor
    : props.color || Styles.primaryColor;
  let fillColor = Styles.primaryColor;
  let textColor = Styles.white;

  if (props.buttonType === 'outline') {
    fillColor = Styles.transparent;
    textColor = props.disabled
      ? disabledColor
      : props.color || Styles.primaryColor;
  } else {
    fillColor = props.disabled
      ? disabledColor
      : props.color || Styles.primaryColor;
    textColor = Styles.white;
  }
  return {fillColor, textColor, buttonColor};
}

export function roundAppButtonSolidTextStyle(_disabled: boolean): TextStyle {
  return null;
}

export function roundAppButtonOutlineTextStyle(_disabled: boolean): TextStyle {
  return null;
}

export function checkRoundedBtnColor(_type?: ButtonType): string {
  return '#066DFF';
}

export function getSaveBtnManageAccount(
  handleSave,
  checkDisabled: () => boolean,
) {
  return (
    <RoundedAppButton
      buttonContainerStyle={{right: 16, bottom: 22, position: 'absolute'}}
      buttonViewStyle={{height: 46, minWidth: 100}}
      textStyle={{fontSize: Styles.Fonts.f1}}
      isDisabled={checkDisabled()}
      inActiveButtonViewStyle={{
        backgroundColor: Styles.Colors.PayNew.darkHuesBase04,
        height: 46,
        minWidth: 100,
      }}
      inActiveTextStyle={{fontSize: Styles.Fonts.f1}}
      buttonType={RoundedAppButtonType.Solid}
      titleText={Localized.Buttons.save}
      onPress={handleSave}
      accessibilityLabelValue={Localized.Buttons.save}
      aria-label={Localized.Buttons.save}
    />
  );
}

export function getTertiaryCTATextStyle(): TextStyle {
  return null;
}

export function buttonTextFontFamily(): string {
  return Styles.FontFamily.aeonikRegular;
}

export default {
  roundedBtnColorDescriptor,
  roundAppButtonSolidTextStyle,
  roundAppButtonOutlineTextStyle,
  checkRoundedBtnColor,
  getSaveBtnManageAccount,
  getTertiaryCTATextStyle,
  buttonTextFontFamily,
};
