import React, {useContext} from 'react';
import Styles from 'src/components/Styles';
import {
  Dimensions,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import Localized from 'src/constants/AppStrings';
import AVText from 'src/components/elements/AVText';
import {useAppSelector} from 'src/redux/hooks';
import AccountStore from 'src/stores/AccountStore';
import MainConsumerContext from 'src/components/MainConsumerContext';
import Util from 'src/Util';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
const width = Dimensions.get('window').width;

const AccountFundView = () => {
  const context = useContext(MainConsumerContext);
  const {defaultBalance} = useAppSelector((state) => state.account.account);

  const getAccountBalance = () => {
    return Util.formatCurrency(
      null,
      defaultBalance?.balance ?? 0,
      AccountStore.getCurrency(),
      {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      },
    );
  };

  return (
    <View
      style={styles.container}
      testID="AccountFundView"
      aria-label="AccountFundView"
      accessibilityLabel="AccountFundView"
    >
      <View style={styles.nameTextView}>
        <AVText
          style={styles.nameText}
          accessibilityRole="text"
          accessible={true}
          accessibilityLabel="Hello"
          aria-label={`Hello, text`}
          numberOfLines={1}
          adjustsFontSizeToFit={true}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
        >
          {Localized.Labels.hello}
        </AVText>
        <AVText
          accessibilityRole="text"
          accessible={true}
          accessibilityLabel={AccountStore.getFirstName()}
          aria-label={`${AccountStore.getFirstName()}, text`}
          style={styles.nameText}
          numberOfLines={1}
          adjustsFontSizeToFit={true}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
        >
          {AccountStore.getFirstName().toUpperCase() + '!'}
        </AVText>
      </View>
      <View style={styles.balanceTextView}>
        <AVText
          style={styles.balanceText}
          numberOfLines={1}
          adjustsFontSizeToFit={true}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
          accessibilityRole="text"
          accessible={true}
          accessibilityLabel={Localized.Labels.balance}
          aria-label={`${Localized.Labels.balance}, text`}
        >
          {Localized.Labels.balance + ': ' + getAccountBalance()}
        </AVText>
      </View>
      <View
        style={{
          alignSelf: 'center',
        }}
      >
        <TouchableOpacity
          style={[styles.addfund]}
          onPress={() => {
            FirebaseAnalytic.trackEvent(
              'AddFundTileClicked',
              'AccountFundView',
            );
            context.actions?.navigateToFunding(true, {
              transDate: Util.getCurrentDate(),
            });
          }}
          role="button"
          accessibilityLabel={Localized.Labels.add_funds}
          aria-label={Localized.Labels.add_funds}
          accessibilityRole="button"
        >
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
            adjustsFontSizeToFit={true}
          >
            {Localized.Labels.add_funds}
          </AVText>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: Styles.Spacing.m2,
    shadowColor: Styles.primaryColor,
    elevation: 4,
    shadowOffset: {width: 0, height: 1},
    shadowRadius: 2,
    alignItems: 'flex-start',
    justifyContent: 'center',
    shadowOpacity: 0.4,
    aspectRatio: 1.2,
    width: width / 2 - Styles.Heights.h2,
  },
  addFundsText: {
    width: '100%',
    textAlign: 'center',
    color: Styles.white,
    fontSize: Styles.Fonts.sectionHeader,
    fontWeight: '600',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  addFundsTextView: {
    height: '28%',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: Styles.primaryColor,
    borderBottomLeftRadius: Styles.Spacing.m2,
    borderBottomRightRadius: Styles.Spacing.m2,
  },
  balanceText: {
    textAlign: 'center',
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f7,
    width: '100%',
    fontWeight: '400',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  balanceTextView: {
    flexDirection: 'row',
    height: '28%',
    width: '95%',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  nameText: {
    width: '100%',
    textAlign: 'center',
    color: Styles.darkGreenRevolve,
    fontSize: Styles.Fonts.f7,
    fontWeight: 'bold',
    marginBottom: 2,
    paddingHorizontal: Styles.Spacing.m2,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  nameTextView: {
    height: '44%',
    width: '100%',
    justifyContent: 'flex-end',
    paddingHorizontal: Styles.Spacing.m0,
  },
  addfund: {
    alignItems: 'center',
    borderWidth: Platform.OS === 'android' ? 1.2 : 1,
    borderRightColor: Styles.darkGreenRevolve,
    borderRadius: 32,
    borderBottomColor: Styles.darkGreenRevolve,
    paddingVertical: 10,
    bottom: 10,
    borderTopColor: Styles.darkGreenRevolve,
    borderLeftColor: Styles.darkGreenRevolve,
    paddingHorizontal: 14,
    overflow: 'hidden',
  },
  addfundbtnStyle: {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: Styles.Fonts.f0 + 4,
    fontWeight: Platform.OS === 'android' ? 'bold' : '600',
    textTransform: 'uppercase',
    color: Styles.darkGreenRevolve,
  },
});

export default AccountFundView;
