import * as React from 'react';
import Svg, {Mask, Path, G} from 'react-native-svg';

function ChevronDown() {
  return (
    <Svg width={20} height={12} viewBox="0 0 20 12" fill="none">
      <Mask
        id="a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={12}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 11.75H0V.25h20v11.5z"
          fill="#fff"
        />
      </Mask>
      <G mask="url(#a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.415 2.69l8.569 8.637c.56.564 1.472.564 2.032 0l8.569-8.638A1.431 1.431 0 1017.553.673l-6.537 6.589a1.431 1.431 0 01-2.032 0L2.447.673A1.431 1.431 0 10.415 2.69z"
          fill="#727179"
        />
      </G>
    </Svg>
  );
}

export default ChevronDown;
