import * as React from 'react';
import Svg, {Rect, Path} from 'react-native-svg';
interface IProps {
  color?: string;
  primaryColor?: string;
  size?: number;
}
const DefaultOffers = ({
  color = '#21AD5A',
  size = 50,
  primaryColor = '#022649',
}: IProps) => (
  <Svg width={size} height={size} fill="none">
    <Rect width={size} height={size} fill={primaryColor} rx={25} />
    <Path
      fill={color}
      fillRule="evenodd"
      d="M36.2 25.28 25.257 14.257l-.078-.078h-.02a2.233 2.233 0 0 0-1.558-.641l-5.193-.193-.229-.01a2.287 2.287 0 0 0-1.599.682l-2.562 2.558a2.386 2.386 0 0 0-.683 1.704v.005l.016.22.349 5.069v.11c.052.453.234.9.542 1.27l.286.282 10.745 10.88.161.16c.62.548 1.563.522 2.151-.072l8.615-8.68a1.582 1.582 0 0 0 0-2.241Zm-17.031-3.615a2.501 2.501 0 1 1 .002-5.002 2.501 2.501 0 0 1-.002 5.002Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default DefaultOffers;
