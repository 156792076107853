import * as React from 'react';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';

interface IRewardsIconProps {
  size?: number;
  color?: string;
}
const RewardsDefaultIcon = ({color = '#707070'}: IRewardsIconProps) => (
  <Svg width={35} height={34} viewBox="0 0 35 34" fill="none">
    <G clipPath="url(#clip0_12399_5602)">
      <Path
        d="M23.04 5.625c-.893 0-1.721.457-2.194 1.214L18.561 10.5h4.627a2.438 2.438 0 000-4.875h-.148zM16.44 10.5l-2.286-3.661a2.581 2.581 0 00-2.193-1.214h-.148a2.438 2.438 0 000 4.875h4.627zM17.5 9.129l1.97-3.149A4.218 4.218 0 0123.04 4h.148a4.061 4.061 0 013.25 6.5h1.625a2.438 2.438 0 012.437 2.438v3.25c0 1.06-.68 1.965-1.625 2.3v8.262a3.253 3.253 0 01-3.25 3.25H9.375a3.253 3.253 0 01-3.25-3.25v-8.262a2.444 2.444 0 01-1.625-2.3v-3.25A2.438 2.438 0 016.938 10.5h1.625a4.063 4.063 0 013.25-6.5h.147a4.2 4.2 0 013.57 1.98L17.5 9.13zm-5.688 2.996H6.939a.815.815 0 00-.813.813v3.25c0 .446.366.812.813.812h9.75v-4.875H11.812zm6.5 0V17H28.064a.815.815 0 00.812-.813v-3.25a.815.815 0 00-.813-.812H18.313zm-1.625 6.5H7.75v8.125c0 .899.726 1.625 1.625 1.625h7.313v-9.75zm1.625 9.75h7.313c.899 0 1.625-.726 1.625-1.625v-8.125h-8.938v9.75z"
        fill={color}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_12399_5602">
        <Path fill="#fff" transform="translate(4.5 4)" d="M0 0H26V26H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default RewardsDefaultIcon;
