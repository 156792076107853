import {createAsyncThunk} from '@reduxjs/toolkit';
import {GiftItem as GiftItemType} from 'src/types/Snack';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import {RootState} from '../../store';
import Logger from 'src/logging/Logger';
import AppApi from 'src/api/AppApi';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export type LoadSnacksParams = {
  locationId: string;
  offset?: number;
  limit?: number;
};
export const loadAllMessages = createAsyncThunk<
  Array<GiftItemType>,
  LoadSnacksParams,
  {state: RootState}
>(
  'snack/loadAllMessages',
  async ({locationId, offset = 0, limit = 10}, {getState}) => {
    try {
      const accountId = getState().account.account.id;
      const response = await AppApi.getMessages(accountId);
      Logger.Log.LogAPIEvent(
        'AppApi',
        'FetchMessages',
        JSON.stringify({locationId, offset, limit, accountId}),
        JSON.stringify(response),
      );
      return response ?? [];
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'all/Messages',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'all/Messages',
        generateErrorMessage(error),
      );
      Logger.Log.LogAPIEvent(
        'AppApi',
        'FetchMessages',
        JSON.stringify({locationId, limit, offset}),
        JSON.stringify(error),
      );
    }
  },
);
