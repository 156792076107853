import React, {useEffect, useMemo, useRef, useState} from 'react';
import Localized from 'src/constants/AppStrings';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import AllHistoryList from 'src/components/elements/account/newHistoryUI/AllHistoryList';
import Styles from 'src/components/Styles';
import {Platform, StyleSheet, TextStyle, View, Linking} from 'react-native';
import type {IsConnectedProps} from 'src/types/Screen';
import FundingHistoryList from 'src/components/elements/account/newHistoryUI/FundingHistoryList';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import Settings from 'src/Settings';
import BaseScreen from 'src/components/screens/BaseScreen';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import {getPreviousRouteName} from 'src/Util';
import AVText from 'src/components/elements/AVText';
import MarketHistoryList from 'src/components/elements/account/newHistoryUI/MarketHistoryList';
import MobilePurchaseList from 'src/components/elements/account/newHistoryUI/MobilePurchaseList';
import OrderAheadHistoryList from 'src/components/elements/account/newHistoryUI/OrderAheadHistoryList';
import ReferralsHistoryList from 'src/components/elements/account/newHistoryUI/ReferralsHistoryList';
import SendASnackHistoryList from 'src/components/elements/account/newHistoryUI/SendASnackHistoryList';
import SignUpRewardsHistoryList from 'src/components/elements/account/newHistoryUI/SignUpRewardsHistoryList';
import AccountStore from 'src/stores/AccountStore';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';
import {getButtonDescriber} from 'src/components/elements/buttonDescriptor/DescriptorType';
import LoyaltyCreditHistoryList from 'src/components/elements/account/newHistoryUI/LoyaltyCreditHistoryList';
import NetInfo from '@react-native-community/netinfo';
import OffersErrorView from 'src/components/elements/offers/OffersErrorView';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import EmptyReceipt from 'src/components/img/EmptyReceipt';

type HistoryScreenProps = IsConnectedProps & {
  navigation: NavigationProp<HistoryScreenProps>;
};
const tabs = {
  ['Snack Credit']: Localized.Labels.send_a_snack_title,
  ['Market Purchase']: Localized.Labels.market_purchase,
  ['Mobile Purchase']: Localized.Labels.mobile_purchase,
  ['Order Ahead']: Localized.Labels.order_ahead,
  ['Sign-Up Reward']: Localized.Labels.signup_reward_history,
  ['Referral Reward']: Localized.Labels.referral_reward,
  ['Funding']: Localized.Labels.funding,
  ['Loyalty Credit']: Localized.Labels.loyalty_credits,
};

const HistoryTab = createMaterialTopTabNavigator();
const historyFontWeightVerify = (focused: boolean): TextStyle | null => {
  if (Settings.isRefiveAnd365Pay()) {
    return !focused ? {fontWeight: '400'} : {fontWeight: '700'};
  } else {
    return {fontWeight: '400'};
  }
};

const HistoryScreen: React.FC<HistoryScreenProps> = (props) => {
  const previousRoute = useRef<string | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(true);
  useEffect(() => {
    getNetworkState();
  }, []);
  const components = [
    {
      component: MarketHistoryList,
      name: Localized.Labels.market_purchase,
      tabsname: Localized.Labels.market_purchase,
    },
    {
      component: MobilePurchaseList,
      name: Localized.Labels.mobile_purchase,
      tabsname: Localized.Labels.mobile_purchase,
    },
    {
      component: SignUpRewardsHistoryList,
      name: Localized.Labels.signup_reward_history,
      tabsname: Localized.Labels.signup_reward_history,
    },
    {
      component: SendASnackHistoryList,
      name: Localized.Labels.send_a_snack_title,
      tabsname: Localized.Labels.send_a_snack_title,
    },
    {
      component: ReferralsHistoryList,
      name: Localized.Labels.referral_reward,
      tabsname: Localized.Labels.history_referrals,
    },
    {
      component: OrderAheadHistoryList,
      name: Localized.Labels.order_ahead,
      tabsname: Localized.Labels.order_ahead,
    },
    {
      component: FundingHistoryList,
      name: Localized.Labels.funding,
      tabsname: Localized.Labels.funding,
    },
    {
      component: LoyaltyCreditHistoryList,
      name: Localized.Labels.loyalty_credits,
      tabsname: Localized.Labels.loyalty_credits,
    },
  ];

  const tabBarIndAndroidStyle = () => {
    return {
      height: '100%',
      backgroundColor: getDescriber().activeTabShadowColor(),
      padding: 20,
    };
  };
  const tabBarIndIOSStyle = () => {
    return {
      backgroundColor: 'transparent',
      height: Styles.Heights.h1 * 0.3,
    };
  };

  useEffect(() => {
    const historychange = (data) => {
      setTimeout(() => {
        setHistoryTabs((result) => [...result, ...data.getHistoryTabs()]);
      }, 100);
    };

    // Subscribe to the 'historyChange' event only once
    AccountStore.addHistoryChangedListener((data) => historychange(data));

    // Cleanup: Remove the listener when the component unmounts
    return () => {
      AccountStore.removeHistoryChangedListener(() => historychange);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const [historyTranasactionTypes, setHistoryTransactionTypes] = useState(
    AccountStore.getHistoryTabs(),
  );
  const [historyTabs, setHistoryTabs] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setHistoryTransactionTypes(AccountStore.getHistoryTabs());
    }, 100);
  }, [AccountStore]);

  useEffect(() => {
    const removedDuplc = historyTranasactionTypes
      ?.filter(
        (item, index) => historyTranasactionTypes?.indexOf(item) === index,
      )
      .sort((a, b) => (a > b ? 1 : -1));
    if (removedDuplc?.length > 0)
      setHistoryTabs((result) => [...result, ...removedDuplc]);
  }, [props.navigation, AccountStore]);

  const getEnum = (x = 'Option1') => {
    return tabs[x as keyof typeof tabs];
  };
  const getNetworkState = async () => {
    const networkState = await NetInfo.fetch();
    setIsConnected(networkState?.isConnected);
  };

  const renderNoConnectivity = () => (
    <View style={styles.noCardsView}>
      <OffersErrorView
        errorText={Localized.Errors.receipt_network_error}
        buttonLabel={Localized.Buttons.go_to_settings_receipt}
        onPress={() => Linking.openSettings()}
        isCenter={true}
      />
    </View>
  );

  const _historytabs = useMemo(() => {
    let arr = [];
    historyTabs.map((item) => {
      const obj = {};
      const component = components.filter(
        (items) => items.name === getEnum(item),
      );
      obj['name'] =
        component && component.length > 0 ? component[0].tabsname : '';
      obj['component'] =
        component && component.length > 0 ? component[0].component : '';
      obj['param'] = getEnum(item);
      arr = [...arr, obj];
    });
    return [...new Map(arr.map((item) => [item.name, item])).values()];
  }, [historyTabs]);

  useEffect(() => {
    FirebaseAnalytic.trackEvent('useEffect', 'HistoryScreen', {
      ...props,
      previousRoute,
    });
    const routeName = getPreviousRouteName(
      props.navigation?.getState()?.routes,
    );
    previousRoute.current = routeName;
  }, []);
  const getEmptyView = () => {
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <EmptyReceipt />
      </View>
    );
  };
  const bordercolor = getButtonDescriber().checkRoundedBtnColor();

  const getTabOptions = (label) => ({
    tabBarLabel: ({focused}) => {
      return (
        <View
          style={[
            {
              borderRadius: 32,
              overflow: 'hidden',
              alignItems: 'center',
            },
            !focused
              ? {
                  borderWidth: 1,
                  borderBottomColor: '#BCBCBC',
                  borderTopColor: '#BCBCBC',
                  borderStartColor: '#BCBCBC',
                  borderEndColor: '#BCBCBC',
                  backgroundColor: Styles.white,
                  paddingHorizontal: 16,
                  paddingVertical: 8,
                }
              : {
                  borderWidth: Platform.OS === 'android' ? 0 : 1,
                  borderBottomColor: bordercolor,
                  borderTopColor: bordercolor,
                  borderStartColor: bordercolor,
                  borderEndColor: bordercolor,
                  backgroundColor: getButtonDescriber().checkRoundedBtnColor(),
                  flex: 1,
                  paddingHorizontal: Platform.OS === 'android' ? 0 : 5,
                  paddingVertical: Platform.OS === 'android' ? 0 : 8,
                  justifyContent: 'center',
                },
          ]}
          accessible={true}
          accessibilityHint={label}
          accessibilityRole="button"
          role="button"
          accessibilityLabel={label}
          aria-label={label}
          testID={label}
        >
          <AVText
            style={[
              historyFontWeightVerify(focused),
              getDescriber()['historyListStyleDescriptor']()[
                'historyTabFontFamily'
              ],
              {
                fontSize: 18,
                textTransform: Settings.isRefiveAnd365Pay()
                  ? 'none'
                  : 'uppercase',
              },
              !focused
                ? getDescriber()['historyListStyleDescriptor']()[
                    'historyTabInactiveFontColor'
                  ]
                : getDescriber()['historyListStyleDescriptor']()[
                    'historyTabActiveFontColor'
                  ],
            ]}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            testID={label}
          >
            {label}
          </AVText>
        </View>
      );
    },
    tabBarAccessibilityLabel: `${label}`,
    tabBarTestID: `${label}`,
    tabBarItemStyle: {
      width: 'auto',
      marginLeft: -3,
      top: 10,
      marginRight: -8,
    },
    tabBarStyle: {
      backgroundColor: 'transparent',
      marginBottom: Platform.OS == 'ios' ? 0 : -Styles.Spacing.m2,
      marginLeft: 5,
      marginRight: 5,
      paddingBottom: 12,
    },
  });
  return (
    <BaseScreen
      title={getDescriber().historyHeaderName()}
      accessibilityLabel={getDescriber().historyHeaderName()}
      accessibilityHint={getDescriber().historyHeaderName()}
      hideBack={!Settings.isRefiveAnd365Pay()}
    >
      {!isConnected && renderNoConnectivity()}
      {isConnected && _historytabs.length > 0 && (
        <HistoryTab.Navigator
          backBehavior="none"
          screenOptions={() => ({
            tabBarScrollEnabled: true,
            tabBarActiveTintColor: Styles.primaryColor,
            tabBarInactiveTintColor: Styles.lightGray,
            tabBarStyle: {
              borderBottomColor: Styles.lightGray,
              borderBottomWidth: StyleSheet.hairlineWidth,
            },
            tabBarIndicatorStyle:
              Platform.OS == 'android'
                ? tabBarIndAndroidStyle()
                : tabBarIndIOSStyle(),
          })}
        >
          <HistoryTab.Screen
            name={Localized.Labels.all}
            component={AllHistoryList}
            options={getTabOptions(Localized.Labels.all)}
            listeners={({navigation}) => ({
              tabPress: async (e) => {
                e.preventDefault();
                // Fetch network status
                NetInfo.fetch().then((networkState) => {
                  if (!networkState.isConnected) {
                    NavActions.navigate(AppRoutes.ServerErrorDialog, {
                      errorTitle: Localized.Labels.error_getting_receipt,
                      errorDesc: Localized.Labels.error_server,
                    });
                  } else {
                    // Allow navigation to proceed if connected
                    navigation.navigate(Localized.Labels.all);
                  }
                });
              },
            })}
            initialParams={{...props}}
          />
          {Settings.isRefiveAnd365Pay() ? (
            <>
              {_historytabs?.map((item) => {
                if (item.component) {
                  return (
                    <HistoryTab.Screen
                      name={item.name}
                      component={item.component}
                      options={getTabOptions(item.name)}
                      listeners={({navigation}) => ({
                        tabPress: async (e) => {
                          e.preventDefault();
                          // Fetch network status
                          NetInfo.fetch().then((networkState) => {
                            if (!networkState.isConnected) {
                              NavActions.navigate(AppRoutes.ServerErrorDialog, {
                                errorTitle:
                                  Localized.Labels.error_getting_receipt,
                                errorDesc: Localized.Labels.error_server,
                              });
                            } else {
                              // Allow navigation to proceed if connected
                              navigation.navigate(item.name);
                            }
                          });
                        },
                      })}
                      initialParams={{...props, uniqueKey: item.param}}
                    />
                  );
                }
              })}
            </>
          ) : (
            <>
              <HistoryTab.Screen
                name={Localized.Labels.funding}
                component={FundingHistoryList}
                options={getTabOptions(Localized.Labels.funding)}
                initialParams={{...props}}
              />
            </>
          )}
        </HistoryTab.Navigator>
      )}
      {isConnected && !_historytabs.length && getEmptyView()}
    </BaseScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 0,
  },
  noCardsView: {
    flex: 1,
    alignItems: 'center',
  },
});

export default withForwardedNavigationParams<HistoryScreenProps>()(
  HistoryScreen,
);
