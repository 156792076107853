import * as React from 'react';
import Svg, {ClipPath, Defs, G, Path} from 'react-native-svg';
interface IProps {
  size?: number;
  color?: string;
  iconColor?: string;
}
const GrpReferral = ({
  size = 32,
  color = '#003349',
  iconColor = '#FFFFFF',
}: IProps) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <Path
      d="M0 8a8 8 0 018-8h16a8 8 0 018 8v16a8 8 0 01-8 8H8a8 8 0 01-8-8V8z"
      fill={color}
    />
    <G clipPath="url(#clip0_5329_27827)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.213 10.4a2.427 2.427 0 114.854 0 2.427 2.427 0 01-4.854 0zm9.147-2.426a2.426 2.426 0 100 4.853 2.426 2.426 0 000-4.853zM8.533 15.496v4.238h1.494v4.48h4.853v-7.708s.515-.174 1.12-.174c.605 0 1.12.174 1.12.174v7.708h4.853v-4.48h1.494v-4.238c-2.043-1.639-5.018-2.303-7.467-2.303-2.45 0-5.424.664-7.467 2.303z"
        fill={iconColor}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_5329_27827">
        <Path
          fill="#fff"
          transform="translate(7.04 7.04)"
          d="M0 0H17.92V17.92H0z"
        />
      </ClipPath>
    </Defs>
  </Svg>
);
export default GrpReferral;
