import Settings from 'src/Settings';
import RedesignDescriptor from './footer-redesign';
import RefiveDescriptor from './footer-refive';
import CanteenDescriptor from './footer-canteen';
import CompanyKitchenDescriptor from './footer-companykitchen';
import BuildTypes from 'src/constants/BuildTypeConstants';

const footerTypeDescriber = {
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export function getFooterDescriber() {
  return footerTypeDescriber[
    Settings.buildType as keyof typeof footerTypeDescriber
  ];
}
