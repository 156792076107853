import {createAsyncThunk} from '@reduxjs/toolkit';
import {hideSpinner, showSpinner} from '../../slices/screenSlice';
import {AppDispatch, RootState} from '../../store';
import {GiftItem as GiftItemType} from 'src/types/Snack';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import Util from 'src/Util';
import {alertError} from 'src/components/helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import HttpClient from 'src/api/HttpClient';
import {PlatformApiErrors} from 'src/api/PlatformApi';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import PlatformApi from 'src/api/PlatformApi';
import {SnackStatus} from '../../slices/snackSlice';
import Styles from 'src/components/Styles';
import ScreenContext from 'src/components/ScreenContext';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

export type AcceptSnackParams = {
  snackDetails: GiftItemType;
  context: React.ContextType<typeof ScreenContext>;
};
export const acceptSnack = createAsyncThunk<
  GiftItemType | undefined,
  AcceptSnackParams,
  {dispatch: AppDispatch; state: RootState}
>(
  'snack/acceptSnack',
  async ({snackDetails, context}, {dispatch, getState}) => {
    dispatch(showSpinner({}));
    const accountId = getState().account.account.id;
    const accountBalanceId = getState().account.account.defaultBalance.id;
    let acceptSnackRes;
    const snackId = snackDetails.externalServiceId || snackDetails.snackId;
    try {
      acceptSnackRes = await PlatformApi.acceptSnack(
        snackDetails.locationId,
        snackId,
        accountId,
        accountBalanceId,
        Util.getCurrentDate(),
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'AcceptSnack',
        JSON.stringify({
          locationId: snackDetails.locationId,
          snackId: snackDetails.externalServiceId,
          accountId,
          accountBalanceId,
        }),
        JSON.stringify(acceptSnackRes),
      );

      // alertSuccess(
      //   Localized.Success.formatString(
      //     Localized.Success.accept_amount_has_been_added_to_your_account,
      //     snackDetails.amount.toFixed(2).toString(),
      //   ),
      //   undefined,
      //   Localized.Success.snack_accepted,
      // );
      context.actions.showModal(
        Localized.Success.formatString(
          Localized.Success.accept_amount_has_been_added_to_your_account,
          snackDetails.amount.toFixed(2).toString(),
        ),
        Localized.Success.snack_accepted,
        Localized.Buttons.okay,
        Styles.darkGreenRevolve,
        2,
        true,
      );

      //TODO will remove
      await ActionsFactory.getAccountActions().getBalance(
        AccountStore.getAccountId(),
      );

      return {
        ...snackDetails,
        status: SnackStatus.Accepted,
      };
    } catch (err) {
      const error = HttpClient.parseJsonSafe(generateErrorMessage(err));

      if (error?.code === PlatformApiErrors.SnackAccepted) {
        alertError(Localized.Errors.snack_already_accepted);
      } else {
        alertError(Localized.Errors.error);
        CrashlyticsEvents.log(
          'Exception',
          'snack/acceptSnack',
          generateErrorMessage(error),
          acceptSnackRes,
        );
        Events.Error.trackEvent(
          'Exception',
          'snack/acceptSnack',
          generateErrorMessage(error),
        );
      }
    } finally {
      dispatch(hideSpinner());
    }
  },
);
