import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import Styles from '../../Styles';
import BaseAccountSetupScreen from '../BaseAccountSetupScreen';
import Settings from 'src/Settings';
import {StyleSheet, View, ScrollView} from 'react-native';
import {alertError} from '../../helpers/AlertHelper';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import AppRoutes from 'src/AppRoutes';
import Localized from 'src/constants/AppStrings';
import AccountApi from 'src/api/AccountApi';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';

import {getDescriber} from '../account/descriptor/DescriptorType';
import Logger from 'src/logging/Logger';
type KioskChangeEmailProps = {
  data: {
    accountId: string;
    email: string;
  };
};
type KioskChangeEmailState = {
  newEmail: string;
  confirmEmail: string;
  error: string;
};

class KioskChangeEmail extends React.Component<
  KioskChangeEmailProps,
  KioskChangeEmailState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: KioskChangeEmailProps) {
    super(props);
    this.state = {
      confirmEmail: '',
      newEmail: '',
      error: '',
    };
    this.validate = this.validate.bind(this);
    this.saveClick = this.saveClick.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
  }

  saveClick() {
    if (!this.validate()) {
      alertError(Localized.Errors.emails_do_not_match);
    } else {
      this.changeEmail();
    }
  }

  handleBack() {
    NavActions.pop();
  }

  validate() {
    return (
      this.state.newEmail &&
      this.state.confirmEmail &&
      this.state.newEmail === this.state.confirmEmail
    );
  }

  async changeEmail() {
    this.context.actions.showSpinner();
    let response;
    try {
      response = await AccountApi.changeEmail(
        this.props.data.accountId,
        this.state.newEmail,
        Settings.buildType,
        true,
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'ChangeEmail',
        JSON.stringify({
          accountId: this.props.data.accountId,
          newEmail: this.state.newEmail,
        }),
        JSON.stringify(response),
      );

      FirebaseAnalytic.trackEvent('changeEmail', 'KioskChangeEmailScreen', {
        ...this.props,
        ...this.state,
        response,
        navigate: AppRoutes.CheckEmail,
      });

      NavActions.replace(AppRoutes.CheckEmail);
    } catch (error) {
      if (error.message.includes('Email already exists')) {
        this.setState({
          error: Localized.Errors.email_already_exists,
        });
      } else {
        this.setState({
          error: Localized.Errors.unable_to_change_email,
        });
      }
      CrashlyticsEvents.log(
        'Exception',
        'KioskChangeEmailScreen:ChangeEmail',
        generateErrorMessage(error),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'KioskChangeEmailScreen:ChangeEmail',
        generateErrorMessage(error),
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'ChangeEmail-Error',
        JSON.stringify({
          accountId: this.props.data.accountId,
          email: this.state.newEmail,
        }),
        JSON.stringify(error),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  render() {
    const {maxFontSizeMultipleDescriptor} = getDescriber();
    return (
      <BaseAccountSetupScreen
        headlineText={Localized.Labels.setup_app}
        instructionText={
          Localized.Labels.enter_email_associated_with_this_account
        }
      >
        <ScrollView
          style={styles.content}
          keyboardDismissMode="interactive"
          automaticallyAdjustContentInsets={false}
          keyboardShouldPersistTaps="handled"
        >
          <View style={styles.tableView}>
            <AllyTextInput
              label={Localized.Labels.new_email}
              value={this.state.newEmail}
              accessible={true}
              accessibilityLabel={Localized.Labels.new_email}
              accessibilityValue={{text: this.state.newEmail}}
              onChangeText={(text) => this.setState({newEmail: text})}
              autoCapitalize="none"
              keyboardType="email-address"
              maxFontSizeMultiplier={maxFontSizeMultipleDescriptor()}
            />
            <AllyTextInput
              label={Localized.Labels.confirm_new_email}
              value={this.state.confirmEmail}
              accessible={true}
              accessibilityLabel={Localized.Labels.confirm_new_email}
              accessibilityValue={{text: this.state.confirmEmail}}
              onChangeText={(text) => this.setState({confirmEmail: text})}
              autoCapitalize="none"
              keyboardType="email-address"
              maxFontSizeMultiplier={maxFontSizeMultipleDescriptor()}
            />
          </View>
        </ScrollView>
        <RoundedButton
          buttonType={ButtonType.action}
          accessibilityLabel="Save"
          onPress={this.saveClick}
          text={Localized.Buttons.save}
        />
      </BaseAccountSetupScreen>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    marginVertical: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m2,
  },
  tableView: {
    marginBottom: Styles.Spacing.m5,
  },
});
export default withForwardedNavigationParams<KioskChangeEmailProps>()(
  KioskChangeEmail,
);
