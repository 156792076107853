import React from 'react';
import {StyleSheet, View, TextInput, Dimensions} from 'react-native';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Styles from '../../Styles';

const {width} = Dimensions.get('window');

const PlanogramSearchBar = ({
  value,
  onChangeText,
  placeholder,
  onFocusChange,
}) => {
  const handleFocus = () => {
    onFocusChange && onFocusChange(true);
  };

  const handleBlur = () => {
    onFocusChange && onFocusChange(false);
  };

  return (
    <View style={styles.searchBarContainer}>
      <FontAwesome5Pro
        name="search"
        style={styles.searchIcon}
        color={'#999'}
        size={Styles.Fonts.f2}
      />
      <TextInput
        style={styles.searchInput}
        value={value}
        placeholder={placeholder}
        onChangeText={onChangeText}
        autoCorrect={false}
        autoCapitalize="none"
        placeholderTextColor="#999"
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  searchBarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 25,
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m0,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
    width: width - 40,
    marginVertical: Styles.Spacing.m2,
  },
  searchIcon: {
    marginRight: Styles.Spacing.m2,
  },
  searchInput: {
    flex: 1,
    fontSize: Styles.Fonts.f2,
    color: '#000',
  },
});

export default PlanogramSearchBar;
