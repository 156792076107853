import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import CustomToggleSwitch from 'src/components/elements/CustomToggleSwitch';
import CreditCardInput from 'src/components/elements/funding/CreditCardInput';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';

export function getAddCardDescriptionView(): JSX.Element {
  return null;
}

export function getHeaderTitle(): string {
  return Localized.Labels.add_card_details;
}

export function getAddCardInputView(
  cardNumber: string,
  expMonth: string,
  expYear: string,
  cvv: string,
  zipCode: string,
  _cardNumberError: string | null,
  _expDateError: string | null,
  _cvvError: string | null,
  _zipCodeError: string | null,
  cardNumberAction: (value: string) => void,
  expMonthAction: (value: string) => void,
  expYearAction: (value: string) => void,
  cvvAction: (value: string) => void,
  zipAction: (value: string) => void,
): JSX.Element {
  return (
    <React.Fragment>
      <CreditCardInput
        accessibilityLabel={Localized.Labels.card_number}
        onChangeText={(text) => cardNumberAction(text)}
        onSubmitEditing={() => this.focusInput(this.month)}
        label={Localized.Labels.card_number}
        value={cardNumber}
      />
      <View style={styles.row}>
        <View style={styles.inputRowLeft}>
          <AllyTextInput
            keyboardType="numeric"
            label={Localized.Labels.expiration_month}
            value={expMonth}
            accessible={true}
            maxLength={2}
            accessibilityLabel={Localized.Labels.expiration_month}
            accessibilityValue={{text: expMonth}}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            onChangeText={(text) => expMonthAction(text)}
          />
        </View>
        <View style={styles.inputRowRight}>
          <AllyTextInput
            keyboardType="numeric"
            label={Localized.Labels.expiration_year}
            value={expYear}
            maxLength={4}
            accessible={true}
            accessibilityLabel={Localized.Labels.expiration_year}
            accessibilityValue={{text: expYear}}
            onChangeText={(text) => expYearAction(text)}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.inputRowLeft}>
          <AllyTextInput
            value={cvv}
            label={Localized.Labels.cvv}
            onChangeText={(text) => cvvAction(text)}
            accessibilityLabel={Localized.Labels.cvv}
            keyboardType="numeric"
            accessible={true}
            accessibilityValue={{text: cvv}}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
          />
        </View>
        <View style={styles.inputRowRight}>
          <AllyTextInput
            keyboardType="numeric"
            label={Localized.Labels.zip_code}
            value={zipCode}
            accessible={true}
            accessibilityLabel={Localized.Labels.zip_code}
            accessibilityValue={{text: zipCode}}
            onChangeText={(text) => zipAction(text)}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
          />
        </View>
      </View>
    </React.Fragment>
  );
}

export function getDefaultSwitchStatusView(
  isDefaultTokenDisabled: boolean,
  defaultTokenStatus: boolean,
  switchAction: (value: boolean) => void,
): JSX.Element {
  return (
    <View style={styles.defaultStatusView}>
      <Text
        accessible={true}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
        accessibilityLabel={Localized.Labels.set_as_default}
        accessibilityRole="text"
        aria-label={`${Localized.Labels.set_as_default} label, text`}
        style={styles.setDefaultLabel}
      >
        {Localized.Labels.set_as_default}
      </Text>

      <CustomToggleSwitch
        accessibilityLabel={Localized.Labels.set_as_default}
        testID="setAsDefaultNewCard"
        accessibilityState={{
          checked: defaultTokenStatus,
          disabled: isDefaultTokenDisabled,
        }}
        isDisabled={isDefaultTokenDisabled}
        value={defaultTokenStatus}
        onValueChange={(value) => switchAction(value)}
      />
    </View>
  );
}

export function getCardSaveButton(
  buttonAction: () => void,
  _buttonText: string,
  _isDisabled: boolean,
  _isActive: boolean,
): JSX.Element {
  return (
    <React.Fragment>
      <RoundedButton
        accessible={true}
        accessibilityLabel={'Save card'}
        accessibilityHint="Double tap to save your card information"
        accessibilityRole="button"
        aria-label={'Save card'}
        role="button"
        buttonType={ButtonType.action}
        onPress={buttonAction}
        text={_buttonText}
        containerStyle={null}
        textStyle={null}
      />
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  inputRowRight: {
    flex: 0.5,
    paddingLeft: Styles.Spacing.m1,
  },
  defaultStatusView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m2 + Styles.Spacing.m1,
  },
  inputRowLeft: {
    paddingRight: Styles.Spacing.m2,
    flex: 0.5,
  },
  defaultSwitch: {
    transform: [
      {
        scaleX: 0.85,
      },
      {
        scaleY: 0.85,
      },
    ],
  },
  row: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
  setDefaultLabel: {
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m2,
    marginLeft: Styles.Spacing.m1,
    fontWeight: '400',
  },
});

export default {
  getAddCardDescriptionView,
  getAddCardInputView,
  getHeaderTitle,
  getDefaultSwitchStatusView,
  getCardSaveButton,
};
