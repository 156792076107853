import Api from './Api';
export class CapSvrApi {
  checkCartForZeroInventory(location: string, items: string[]) {
    const capSvrReceipt = this.getCapSvrApiBase();
    const params = {
      locid: location,
      items: items,
    };
    const url = Api.getFullUrl(capSvrReceipt, '/product/checkzeroinventory');
    return Api.fetch(
      url,
      params,
      'POST',
      true,
      false,
      'application/x-www-form-urlencoded',
    ) as unknown as Promise<{
      return: string;
      items: {
        product: string;
        hidezeroinv: string;
        qtyonhand: number;
      }[];
    }>;
  }

  getCapSvrApiBase(): string {
    return Api.getTallyUrl('capsvr');
  }
}
export default new CapSvrApi();
