import AsyncStorage from '@react-native-async-storage/async-storage';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {Credentials} from 'react-native-auth0';
import {RefreshTokenOptions} from 'react-native-auth0/src/types';
import Events from '../logging/Events';

export interface IAuthState {
  accessToken: string | null;
  refreshToken: string | null;
  idToken: string | null;
  email: string | null;
  accountId: string | null;
  expiresAt: number;
  isSSO: boolean;
  isNewUser: boolean | null;
  hasQRCode: boolean | null;
}

export default class AuthStore {
  private accessToken: string | null = null;
  private refreshToken: string | null = null;
  private idToken: string | null = null;
  private email: string | null = null;
  private accountId: string | null = null;
  private expiresAt = 0;
  private isSSO: boolean;
  private isNewUser: boolean | null = null;
  private hasQRCode: boolean | null = null;

  public async clearSession() {
    try {
      await AsyncStorage.removeItem('@auth_session');
    } catch (error) {

    }

    this.accessToken = null;
    this.accountId = null;
    this.email = null;
    this.expiresAt = 0;
    this.idToken = null;
    this.refreshToken = null;
    this.isSSO = false;
    this.isNewUser = null;
    this.hasQRCode = null;
  }

  public async storeSession(session: Credentials) {
    const refreshToken = session.refreshToken?.substring(0, 12);
    const accessToken = session.accessToken.substring(0, 20);
    const expiryAt = session.expiresAt.toString();
    Events.Info.trackEvent('AuthStore:storeSession', {
      refreshToken,
      accessToken,
      expiryAt,
    });
    this.assignValues(session);

    const jsonValue = JSON.stringify(this.getAuthState());
    await AsyncStorage.setItem('@auth_session', jsonValue).catch((e) => {
      Events.Info.trackEvent('AuthStore:storeSession:setItem', e.toString());
    });
  }

  public async storeRefresh(refresh: RefreshTokenOptions) {
    Events.Info.trackEvent('AuthStore:storeRefresh Token', {
      refreshToken: refresh.refreshToken?.substring(0, 12) ?? '',
      expiresIn: refresh.expiresIn,
    });
    this.accessToken = refresh.accessToken;
    this.expiresAt = refresh.expiresAt;

    if (refresh.refreshToken) {
      this.refreshToken = refresh.refreshToken;
    }

    const jsonValue = JSON.stringify(this.getAuthState());
    await AsyncStorage.setItem('@auth_session', jsonValue).catch((e) => {
      Events.Info.trackEvent('AuthStore:storeRefresh:setItem', e.toString());
    });
  }

  private getAuthState() {
    return {
      accessToken: this.accessToken,
      accountId: this.accountId,
      email: this.email,
      expiresAt: this.expiresAt,
      idToken: this.idToken,
      refreshToken: this.refreshToken,
      isSSO: this.isSSO,
      isNewUser: this.isNewUser,
      hasQRCode: this.hasQRCode,
    } as IAuthState;
  }

  private setAuthState(state: IAuthState | null) {
    if (state) {
      this.accessToken = state.accessToken;
      this.accountId = state.accountId;
      this.email = state.email;
      this.expiresAt = state.expiresAt ?? 0;
      this.idToken = state.idToken;
      this.refreshToken = state.refreshToken;
      this.isSSO = state.isSSO;
      this.isNewUser = state.isNewUser;
      this.hasQRCode = state.hasQRCode;
    }
  }

  private assignValues(session: Credentials) {
    this.accessToken = session.accessToken;
    this.refreshToken = session.refreshToken ?? null;
    this.idToken = session.idToken;
    this.expiresAt = session.expiresAt;

    const data: any = jwtDecode<JwtPayload>(session.idToken);
    this.accountId = data['https://365rm.us/accountId'];
    this.email = data.email;
    this.isSSO = data['https://365rm.us/isSSO'];
    this.isNewUser = data['https://365rm.us/isNewUser'];
    this.hasQRCode = data['https://365rm.us/hasQRCode'];
  }

  private async getSessionFromStorage() {
    try {
      const jsonValue = await AsyncStorage.getItem('@auth_session');
      return jsonValue != null ? (JSON.parse(jsonValue) as IAuthState) : null;
    } catch (e) {
      Events.Info.trackEvent(
        'AuthStore:getSessionFromStorage:getItem',
        e.toString(),
      );
      return null;
    }
  }

  private getDefaultSessionState(): IAuthState {
    return {
      accessToken: '',
      accountId: '',
      email: '',
      expiresAt: 0,
      idToken: '',
      refreshToken: '',
      isSSO: false,
      isNewUser: false,
      hasQRCode: false,
    };
  }

  public async getAccessToken() {
    if (this.accessToken === null || this.refreshToken === null) {
      const authState = await this.getSessionFromStorage().catch((e) => {
        Events.Info.trackEvent(
          'AuthStore:getAccessToken:getSessionFromStorage',
          e.toString(),
        );
        return this.getDefaultSessionState();
      });
      this.setAuthState(authState);
    }

    return this.accessToken;
  }

  public async getIsSSO() {
    if (this.accessToken === null || this.refreshToken === null) {
      const authState = await this.getSessionFromStorage().catch((e) => {
        Events.Info.trackEvent(
          'AuthStore:getAccessToken:getSessionFromStorage',
          e.toString(),
        );
        return this.getDefaultSessionState();
      });
      this.setAuthState(authState);
    }

    return this.isSSO;
  }

  public async getRefreshToken() {
    if (this.accessToken === null || this.refreshToken === null) {
      const authState = await this.getSessionFromStorage().catch((e) => {
        Events.Info.trackEvent(
          'AuthStore:getRefreshToken:getSessionFromStorage',
          e.toString(),
        );
        return this.getDefaultSessionState();
      });
      this.setAuthState(authState);
    }

    return this.refreshToken;
  }

  public async getExpiresAt() {
    if (this.accessToken === null || this.expiresAt === 0) {
      const authState = await this.getSessionFromStorage().catch((e) => {
        Events.Info.trackEvent(
          'AuthStore:getExpiresAt:getSessionFromStorage',
          e.toString(),
        );
        return this.getDefaultSessionState();
      });
      this.setAuthState(authState);
    }

    return this.expiresAt;
  }

  public async getAccountId() {
    if (this.accessToken === null || this.accountId === null) {
      const authState = await this.getSessionFromStorage();
      this.setAuthState(authState);
    }

    return this.accountId;
  }

  public async shouldShowQRCode() {
    if (
      this.accessToken === null ||
      this.hasQRCode === null ||
      this.isNewUser === null
    ) {
      const authState = await this.getSessionFromStorage();
      this.setAuthState(authState);
    }

    if (this.hasQRCode == null || this.isNewUser == null) {
      return false;
    }

    return this.hasQRCode && this.isNewUser;
  }
}
