import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import Settings from 'src/Settings';

type ReferralSignUpDetailsIconProps = {
  width?: number;
  height?: number;
  viewBox?: string;
  color?: string;
};
function ReferralSignUpDetailsIcon({
  width = 54,
  height = 54,
  viewBox = '0 0 43 42',
  color = '#003349',
}: ReferralSignUpDetailsIconProps) {
  return (
    <>
      {Settings.is365Pay() ? (
        <Svg width={width} height={height} viewBox={viewBox} fill="none">
          <Path
            clipRule="evenodd"
            d="M21.72.11c-5.566 0-10.078 4.512-10.078 10.079 0 5.566 4.512 10.08 10.079 10.08 5.566 0 10.079-4.513 10.079-10.08S27.287.109 21.72.109zm-1.212 22.152c-7.035 0-13.649 1.79-19.39 4.935l-1.11.603v9.845H25.47v-8.487h4.058v-5.292c-1.827-.75-5.185-1.607-9.02-1.604zm11.88 4.312v5.212h-4.981v4.52l5.142-.034.025 5.246h4.52v-5.212h4.98v-4.52h-4.98v-5.212h-4.706z"
            fillRule="evenodd"
            fill="#066DFF"
          />
        </Svg>
      ) : (
        <Svg width={width} height={height} viewBox={viewBox} fill="none">
          <Path
            clipRule="evenodd"
            d="M21.721.11c-5.566 0-10.079 4.512-10.079 10.079 0 5.566 4.513 10.079 10.08 10.079 5.566 0 10.078-4.513 10.078-10.08C31.8 4.622 27.288.11 21.721.11zM20.508 22.26c-7.034 0-13.648 1.791-19.39 4.936l-1.11.603v9.845h25.463v-8.487h4.058v-5.292c-1.827-.75-5.186-1.608-9.02-1.604zm11.88 4.313v5.212h-4.98v4.52l5.141-.035.025 5.246h4.52v-5.211h4.98v-4.52h-4.98v-5.212H32.39z"
            fill={color}
            fillRule="evenodd"
          />
        </Svg>
      )}
    </>
  );
}

export default ReferralSignUpDetailsIcon;
