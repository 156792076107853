import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IArrowStep1Props {
  color?: string;
}

function ArrowStep1(props: IArrowStep1Props) {
  return (
    <Svg width={164} height={161} viewBox="0 0 164 161" fill="none">
      <Path
        d="M98.203 1.55C87.41 6.568 80.673 16.861 75.795 27.296c-4.194 9.157-7.39 18.77-9.728 28.507-4.519 19.246-5.29 39.406-2.347 58.974 1.672 11.001 4.483 21.916 8.555 32.324.996 2.645 5.113.985 4.047-1.608-6.938-17.89-10.302-37.143-9.987-56.305.297-19.039 4.097-38.075 11.504-55.654 4.564-10.856 10.775-22.726 21.972-27.936 1.069-.472 1.683-1.698 1.281-2.82-.472-1.068-1.82-1.7-2.889-1.228z"
        fill={props.color ?? '#fff'}
      />
      <Path
        d="M92.768 107.69c-4.195 10.471-8.514 20.924-12.709 31.395-.492 1.243-.965 2.364-1.457 3.607-.474 1.12-.79 2.451-1.562 3.467-.947 1.365-2.628.435-3.766-.354-4.586-2.912-8.997-6.173-13.548-9.33-5.234-3.631-10.539-7.208-15.773-10.839-2.275-1.578-5.028 1.905-2.683 3.431 5.007 3.472 10.013 6.945 15.09 10.365 4.779 3.315 9.47 6.804 14.407 9.891 2.013 1.228 4.36 2.316 6.707 1.653 2.873-.838 4.032-3.675 5.015-6.161l6.688-16.494a1864.974 1864.974 0 007.67-18.98c1.054-2.539-2.956-4.242-4.08-1.651z"
        fill={props.color ?? '#fff'}
      />
    </Svg>
  );
}

export default ArrowStep1;
