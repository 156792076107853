import {createSlice, PayloadAction} from '@reduxjs/toolkit';
export interface ISpinner {
  display?: boolean;
  spinnerText?: string;
  spinnerCancel?: boolean;
  spinnerCancelText?: string;
  spinnerCallback?: () => void;
}

export interface IMainConsumerState {
  spinner: ISpinner;
}

export const initialState: IMainConsumerState = {
  spinner: {
    display: false,
    spinnerText: '',
    spinnerCancel: false,
    spinnerCancelText: '',
  },
};

const screenSlice = createSlice({
  name: 'screen',
  initialState,
  reducers: {
    showSpinner: (state, action: PayloadAction<ISpinner>) => {
      state.spinner = {...action.payload, display: true};
    },
    hideSpinner: (state) => {
      state.spinner = initialState.spinner;
    },
  },
});
export const {hideSpinner, showSpinner} = screenSlice.actions;
export default screenSlice.reducer;
