import React from 'react';
import {PixelRatio, StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import BaseScreen from '../../BaseScreen';
import ScreenContext from '../../../ScreenContext';
import Styles from '../../../Styles';
import AccountStore from 'src/stores/AccountStore';
import type {HistoryItem} from 'src/types/TransactionDetail';
import Localized from 'src/constants/AppStrings';
import {GiftItem} from 'src/types/Snack';

import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';

import CrashlyticsEvents from 'src/logging/Crashlytics';
import uuid from 'src/nativeModules/UUID';
import ActionsFactory from 'src/actions/ActionsFactory';
import {alertError} from 'src/components/helpers/AlertHelper';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import SnackDetail from 'src/components/elements/account/newHistoryUI/SnackDetail';
type SnackSummaryScreenProps = WithGlobalizeProps & {
  historyItem: HistoryItem;
};
type SnackSummaryScreenState = {
  summary: GiftItem;
};

class ReferralSummaryScreen extends React.Component<
  SnackSummaryScreenProps,
  SnackSummaryScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);

    this.fetchSnackSummaryDetails = this.fetchSnackSummaryDetails.bind(this);
    this.state = {
      summary: null,
    };
  }

  componentDidMount() {
    this.fetchSnackSummaryDetails();
  }

  componentWillUnmount(): void {
    this.setState({
      summary: null,
    });
  }
  async fetchSnackSummaryDetails() {
    this.context?.actions.showSpinner();
    this.setState({
      summary: null,
    });
    try {
      const response =
        await ActionsFactory.getAccountActions().loadPurchaseDetail(
          AccountStore.getAccountId(),
          this.props.historyItem?.Id,
          this.props.historyItem?.Type,
          this.props.historyItem?.TransDate,
          AccountStore.getLocationId(),
        );

      if (!response || Object.keys(response.Data).length == 0) {
        alertError(Localized.Errors.error_load_snack_Detail);
        this.context?.actions.hideSpinner();
        return;
      }
      FirebaseAnalytic.trackEvent(
        'getSnackDetails',
        'snackHistoryDetailsScreen',
        {
          ...this.props.historyItem,
        },
      );
      this.setState({summary: response.Data as GiftItem});
    } catch (error) {
      const guid = await uuid.getRandomUUID();

      alertError(Localized.Errors.error_load_snack_Detail, guid);
      CrashlyticsEvents.log(
        'Exception',
        'snackHistoryDetailsScreen:getSnackDetails',
        generateErrorMessage(error),
        guid,
        {
          transactionId: this.props.historyItem.referenceId,
        },
      );

      Events.Error.trackEvent(
        'Exception',
        'snackHistoryDetailsScreen:getSnackDetails',
        generateErrorMessage(error),
        guid,
        {
          transactionId: this.props.historyItem.referenceId,
        },
      );
    } finally {
      this.context?.actions.hideSpinner();
    }
  }

  render() {
    return (
      <BaseScreen title={'Send a Snack'}>
        <View style={styles.purchaseContent}>
          {this.state.summary && (
            <SnackDetail
              snackDetail={{
                ...this.state.summary,
                BalanceTypeDisplay: this.props.historyItem?.BalanceTypeDisplay,
                historyId: this.props.historyItem?.Id,
              }}
            />
          )}
        </View>
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  date: {
    fontSize: Styles.Fonts.f0,
    fontWeight: 'bold',
    paddingBottom: Styles.Spacing.m2,
  },
  location: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  purchaseContent: {
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  historyContainer: {
    alignSelf: 'stretch',
    flex: 1,
  },
  transactionId: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
  },
  boldLabel: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f3,
    fontWeight: '300',
    marginBottom: Styles.Spacing.m1,
    marginTop: -1 * Styles.Spacing.m1,
  },
  dashStyle: {
    height: PixelRatio.roundToNearestPixel(2),
    marginTop: Styles.Spacing.m2,
    borderRadius: 100,
    overflow: 'hidden',
    width: '100%',
    marginBottom: Styles.Spacing.m2,
  },
  referral: {
    fontSize: Styles.Fonts.f2,
  },
  user: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
    fontWeight: 'bold',
  },
  summary: {
    marginBottom: Styles.Spacing.m1,
  },
  summaryLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginBottom: Styles.Spacing.m1,
  },
  leftView: {
    flex: 0.7,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rightView: {
    flex: 0.3,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
});
export default withForwardedNavigationParams<SnackSummaryScreenProps>()(
  withGlobalize(ReferralSummaryScreen),
);
