import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IProps {
  size?: number;
  color?: string;
}
const RewardsIcon = ({size = 34, color = '#727179'}: IProps) => (
  <Svg width={size} height={size}>
    <Path
      fill={color}
      fillRule="evenodd"
      d="M12.472.833c-4.346 0-7.87 3.625-7.87 8.096 0 4.47 3.524 8.095 7.87 8.095 4.347 0 7.87-3.625 7.87-8.095 0-4.471-3.523-8.096-7.87-8.096Zm0 14.167c-3.26 0-5.903-2.718-5.903-6.071 0-3.354 2.643-6.072 5.903-6.072s5.903 2.718 5.903 6.072c0 3.353-2.643 6.071-5.903 6.071Zm0-.506c-2.983 0-5.41-2.497-5.41-5.565 0-3.07 2.427-5.566 5.41-5.566 2.984 0 5.411 2.497 5.411 5.566 0 3.068-2.427 5.565-5.41 5.565Zm-6.696.393-5.11 9.22H6.57l2.952 5.06 2.951-6.692 2.069-4.69a8.642 8.642 0 0 1-2.069.25c-2.675 0-5.073-1.22-6.696-3.148Zm9.947 2.515a8.884 8.884 0 0 0 3.446-2.515l5.109 9.22h-5.903l-2.951 5.06-2.453-5.56 2.752-6.205Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default RewardsIcon;
