import * as React from 'react';
import Svg, {G, Circle, Defs, LinearGradient, Stop} from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */
type EllipseCircleProps = {
  firstColor?: string;
  secondColor?: string;
};

function EllipseCircle(props?: EllipseCircleProps) {
  return (
    <Svg width={28} height={28} viewBox="0 0 28 28" fill="none" {...props}>
      <G filter="url(#filter0_d_7162_9933)">
        <Circle cx={14} cy={10} r={10} fill="#fff" />
        <Circle
          cx={14}
          cy={10}
          r={9.5}
          stroke="url(#paint0_linear_7162_9933)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="paint0_linear_7162_9933"
          x1={14}
          y1={0}
          x2={14}
          y2={10.5263}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor={props.firstColor} />
          <Stop offset={1} stopColor={props.secondColor} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default EllipseCircle;
