import * as React from 'react';
import Svg, {G, Path, Mask, Defs, ClipPath, Rect} from 'react-native-svg';

interface IProps {
  size?: number;
}

function LocationMarkerErrorIcon({size = 78}: IProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 78 78" fill="none">
      <G clipPath="url(#clip0_454_9180)">
        <Path
          d="M59.8772 28.3136C59.8772 16.2662 50.1109 6.5 38.0636 6.5C26.0162 6.5 16.25 16.2662 16.25 28.3136C16.25 32.3741 17.3611 36.1745 19.2938 39.4306H19.2928L38.0646 71.5L56.8364 39.4316H56.8354C58.7681 36.1755 59.8792 32.3741 59.8792 28.3136H59.8772ZM38.0636 37.7457C32.8545 37.7457 28.6315 33.5227 28.6315 28.3136C28.6315 23.1045 32.8545 18.8815 38.0636 18.8815C43.2727 18.8815 47.4957 23.1045 47.4957 28.3136C47.4957 33.5227 43.2727 37.7457 38.0636 37.7457Z"
          fill="#727179"
        />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62 2.66669C54.6667 2.66669 48.6667 8.66675 48.6667 16C48.6667 23.3333 54.6667 29.3334 62 29.3334C69.3333 29.3334 75.3333 23.3333 75.3333 16C75.3333 8.66675 69.3333 2.66669 62 2.66669ZM63.5385 22.6667H60.4615V20.1026H63.5385V22.6667ZM63.5385 17.0257H60.4615V8.82053H63.5385V17.0257Z"
        fill="#4A4953"
      />
      <Mask
        height={28}
        x={48}
        id="mask0_454_9180"
        width={28}
        maskUnits="userSpaceOnUse"
        y={2}
      >
        <Path
          fill="white"
          fillRule="evenodd"
          d="M62 2.66669C54.6667 2.66669 48.6667 8.66675 48.6667 16C48.6667 23.3333 54.6667 29.3334 62 29.3334C69.3333 29.3334 75.3333 23.3333 75.3333 16C75.3333 8.66675 69.3333 2.66669 62 2.66669ZM63.5385 22.6667H60.4615V20.1026H63.5385V22.6667ZM63.5385 17.0257H60.4615V8.82053H63.5385V17.0257Z"
          clipRule="evenodd"
        />
      </Mask>
      <G mask="url(#mask0_454_9180)" />
      <Defs>
        <ClipPath id="clip0_454_9180">
          <Rect
            width={43.6272}
            height={65}
            fill="white"
            transform="translate(16.25 6.5)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default LocationMarkerErrorIcon;
