import React, {useEffect} from 'react';
import {
  Dimensions,
  StyleSheet,
  Text,
  View,
  StatusBar,
  Platform,
} from 'react-native';
import Styles from '../Styles';
import Animated, {
  Easing,
  StyleProps,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import LinearGradient from 'react-native-linear-gradient';
import SpinnerInSVG from './SpinnerInSVG';
import SpinnerOutSVG from './SpinnerOutSVG';
import Localized from 'src/constants/AppStrings';

type RedirectLoadingScreenProps = {
  signInMessage?: string;
};
import Settings from 'src/Settings';
import {getOnboardingDescriber} from 'src/components/screens/descriptor/onboarding/DescriptorType';
import AVText from 'src/components/elements/AVText';
import RefiveSignLogo from 'src/components/img/svg/RefiveSignLogo';

const {width} = Dimensions.get('window');
const LOADING_LOGO_OUT = width / 3;
const LOADING_LOGO_IN = width / 3 - 32;
const SPRING_OUT_TIME = 4000;

const SpinnerOutSVGAnimation =
  Animated.createAnimatedComponent<StyleProps>(SpinnerOutSVG);
const SpinnerInSVGAnimation =
  Animated.createAnimatedComponent<StyleProps>(SpinnerInSVG);

const RedirectLoadingScreen: React.FC<RedirectLoadingScreenProps> = (
  props: RedirectLoadingScreenProps,
) => {
  const rotationOut = useSharedValue(0);
  const rotationIn = useSharedValue(0);

  useEffect(() => {
    rotationOut.value = withRepeat(
      withTiming(360, {duration: SPRING_OUT_TIME, easing: Easing.linear}),
      -1,
      false,
    );

    rotationIn.value = withRepeat(
      withTiming(-360, {
        duration: SPRING_OUT_TIME,
        easing: Easing.linear,
      }),
      -1,
      false,
    );
  }, []);

  const spinnerOutStyle = useAnimatedStyle(() => {
    return {
      transform: [{rotate: `${rotationOut.value}deg`}],
    };
  });
  const spinnerInStyle = useAnimatedStyle(() => {
    return {
      transform: [{rotate: `${rotationIn.value}deg`}],
    };
  });

  const gradient = getOnboardingDescriber().getLogoGradientProps();

  return (
    <>
      {Settings.isRefive() ? (
        <LinearGradient {...gradient} style={styles.container}>
          <StatusBar
            translucent={true}
            backgroundColor="transparent"
            barStyle={'dark-content'}
          />
          <View style={styles.refiveLogoContainer}>
            <View style={styles.refiveLogoView}>
              <RefiveSignLogo />
              <AVText style={styles.signInText}>
                {Localized.Labels.refive_welcome_sign_in}
              </AVText>
            </View>
          </View>
        </LinearGradient>
      ) : (
        <LinearGradient {...gradient} style={styles.container}>
          {Settings.is365Pay() ? (
            <>
              <View style={styles.logoView}>
                <View style={styles.absoluteView}>
                  <SpinnerOutSVGAnimation
                    style={spinnerOutStyle}
                    size={LOADING_LOGO_OUT}
                  />
                </View>

                <View style={styles.absoluteView}>
                  <SpinnerInSVGAnimation
                    style={spinnerInStyle}
                    size={LOADING_LOGO_IN}
                  />
                </View>

                <Text style={styles.text365}>365</Text>
              </View>
              <Text style={styles.text}>{props?.signInMessage}</Text>
            </>
          ) : (
            <View style={styles.logoView}>
              {getOnboardingDescriber().getWelcomeLogoImage()}
            </View>
          )}
        </LinearGradient>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  imageMainView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  signInText: {
    color: Styles.Colors.Refive.navy,
    textAlign: 'center',
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: Styles.Spacing.m4,
    fontStyle: 'normal',
    fontWeight: '700',
  },
  refiveLogoView: {
    top: Platform.OS === 'android' ? -50 : -70,
    gap: 32,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  refiveLogoContainer: {
    width: '60%',
    height: LOADING_LOGO_OUT,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  text365: {
    fontSize: 28,
    fontWeight: 'bold',
    color: Styles.Colors.PayNew.primary01,
  },
  absoluteView: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoView: {
    width: LOADING_LOGO_OUT,
    height: LOADING_LOGO_OUT,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontWeight: 'bold',
    fontSize: 32,
    width: '60%',
    textAlign: 'center',
    marginTop: Styles.Spacing.m3,
    color: Styles.Colors.PayNew.black01,
  },
});

export default RedirectLoadingScreen;
