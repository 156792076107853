import React from 'react';
import {Platform, StyleSheet, View, ScrollView} from 'react-native';
import {ScreenWidth} from 'react-native-elements/dist/helpers';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import AVText from 'src/components/elements/AVText';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import BackSubheader from 'src/components/elements/BackSubheader';
import CardBackWhite from 'src/components/img/svg/CardBackWhite';
import PersonWhite from 'src/components/img/svg/PersonWhite';
import ScanWhite from 'src/components/img/svg/ScanWhite';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import {useNavigation} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import {getDescriber as getCommonModuleDescriber} from './descriptor/DescriptorType';

const ICON_SIZE = ScreenWidth * (Platform.OS === 'web' ? 0.1 : 0.14);

const LinkExistingAccountScreen = () => {
  const onScanQR = () => NavActions.push(AppRoutes.ScanKioskQR);
  const onScanMarketCard = () => NavActions.push(AppRoutes.ScanMarketCard);
  const onManuallyEnter = () => NavActions.push(AppRoutes.KioskCreateAccount);

  const navigation = useNavigation();
  const previousRoute = getPreviousRouteName(navigation.getState()?.routes);
  const {linkExistingAccountStyleDescriptor} = getCommonModuleDescriber();
  return (
    <BackSubheader
      previousRoute={previousRoute}
      accessibilityLabel={'Back arrow'}
      accessibilityHint={`Press to navigate back to the ${previousRoute} screen`}
      title={Localized.Labels.link_existing_account}
    >
      <ScrollView horizontal={false} automaticallyAdjustContentInsets={false}>
        <View
          style={
            linkExistingAccountStyleDescriptor() &&
            linkExistingAccountStyleDescriptor()['container']
          }
        >
          <AVText
            style={
              linkExistingAccountStyleDescriptor() &&
              linkExistingAccountStyleDescriptor()['title']
            }
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          >
            {Localized.Labels.select_method_to_link_account}
          </AVText>

          {Platform.OS !== 'web' && (
            <>
              <AVTouchableOpacity
                accessible={true}
                accessibilityLabel="Scan QR code from kiosk"
                aria-label="Scan QR code from kiosk"
                style={styles.box}
                onPress={onScanQR}
              >
                <ScanWhite size={ICON_SIZE} />
                <AVText style={styles.text}>
                  {Localized.Labels.scan_qr_code_from_kiosk}
                </AVText>
              </AVTouchableOpacity>

              <AVTouchableOpacity
                accessible={true}
                accessibilityLabel="Scan your market card"
                aria-label="Scan your market card"
                style={styles.box}
                onPress={onScanMarketCard}
              >
                <CardBackWhite size={ICON_SIZE} />
                <AVText style={styles.text}>
                  {Localized.Labels.scan_market_card}
                </AVText>
              </AVTouchableOpacity>
            </>
          )}

          <AVTouchableOpacity
            accessible={true}
            accessibilityLabel="Manually enter your email and pin"
            aria-label="Manually enter your email and pin"
            style={styles.box}
            onPress={onManuallyEnter}
          >
            <PersonWhite size={ICON_SIZE} />
            <AVText style={styles.text}>
              {Localized.Labels.manually_enter_email_only}
            </AVText>
          </AVTouchableOpacity>
        </View>
      </ScrollView>
    </BackSubheader>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: Styles.Spacing.m3,
    backgroundColor: Styles.tabBarBackgroundColor,
  },
  title: {
    fontSize: Styles.Fonts.sectionHeader,
    color: Styles.darkColor,
    fontWeight: '700',
  },
  box: {
    backgroundColor: Styles.darkColor,
    alignItems: 'center',
    borderRadius: 8,
    padding: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m4,
  },
  text: {
    fontSize: Styles.Fonts.f2,
    color: Styles.white,
    marginTop: Styles.Spacing.m2,
    fontWeight: '500',
  },
});

export default LinkExistingAccountScreen;
