import AsyncStorage from '@react-native-async-storage/async-storage';
import type {
  StartupData,
  UserStartupData,
  AccountDetailsObject,
  AdditionalAccountDetails,
  AccountDetails,
  GmaAccountDetails,
  AdditionalGmaAccountDetails,
  GmaAccountDetailsObject,
  ProcessorInfo,
  UrlsAndEnv,
  AdmPromotionResponse,
  AutoFundResponse,
  PrivacyVersionResponse,
  ThresholdsResponse,
  AccountOfferNoteToggles,
} from 'src/types/PersistentStorageTypes';
import {RewardStatusModel} from 'src/types/RewardsModel';
import {GiftItem as GiftItemType} from 'src/types/Snack';

class PersistentStore {
  async _set<T>(key: string, value: T) {
    // Make sure that the value is not undefined, otherwise iOS will throw an exception.
    const cleanValue = value == null ? null : value;
    return AsyncStorage.setItem(key, JSON.stringify(cleanValue));
  }

  async _multiSet<T extends Record<string, any>>(KVPairs: T): Promise<void> {
    const cleanKVPairs: Array<[string, string]> = [];

    for (const key in KVPairs) {
      const cleanValue = KVPairs[key] == null ? null : KVPairs[key];
      cleanKVPairs.push([key, JSON.stringify(cleanValue)]);
    }

    return AsyncStorage.multiSet(cleanKVPairs);
  }

  async _get<T>(key: string): Promise<T | null> {
    const value = await AsyncStorage.getItem(key);
    return JSON.parse(value);
  }

  async _multiGet<T>(keys: string[]): Promise<T> {
    const values = await AsyncStorage.multiGet(keys);
    return values.reduce((map, arr) => {
      map[arr[0]] = JSON.parse(arr[1]);
      return map;
    }, <T>{});
  }

  async _delete(key: string) {
    return AsyncStorage.removeItem(key);
  }

  setHasLaunched(): Promise<void> {
    return this._set('hasLaunched', true);
  }

  hasLaunched(): Promise<true | null> {
    return this._get<true>('hasLaunched');
  }

  removeSentExternalId(): Promise<void> {
    return this._set('sentExternalId', false);
  }

  setSentExternalId(): Promise<void> {
    return this._set('sentExternalId', true);
  }

  hasSentExternalId(): Promise<true | null> {
    return this._get<true>('sentExternalId');
  }

  setSeenClaimDealMessage(username: string): Promise<void> {
    return this._set(`${username}-claimDealMessage`, true);
  }

  hasSeenClaimDealMessage(username: string): Promise<true | null> {
    return this._get<true>(`${username}-claimDealMessage`);
  }

  setSeenPayrollDeductAvailableMessage(username: string): Promise<void> {
    return this._set(`${username}-payrollDeductAvailableMessage`, true);
  }

  hasSeenPayrollDeductAvailableMessage(username: string): Promise<true | null> {
    return this._get<true>(`${username}-payrollDeductAvailableMessage`);
  }

  setMachineInfo(deviceId: string, machineInfo: any): Promise<void> {
    return this._set(`machineInfo-${deviceId}`, machineInfo);
  }

  getMachineInfo(deviceId: string): Promise<any | null> {
    return this._get<string>(`machineInfo-${deviceId}`);
  }

  removeMachineInfo(deviceId: string): Promise<void> {
    return this._delete(`machineInfo-${deviceId}`);
  }

  setMarketInfo(broadcastId: string, marketInfo: any): Promise<void> {
    return this._set(`marketInfo-${broadcastId}`, marketInfo);
  }

  getMarketInfo(broadcastId: string): Promise<any> {
    return this._get<string>(`marketInfo-${broadcastId}`);
  }

  setIsActiveDex(deviceId: string, isActiveDex: boolean): Promise<void> {
    return this._set(`isActiveDex-${deviceId}`, isActiveDex);
  }

  getIsActiveDex(deviceId: string): Promise<boolean | null> {
    return this._get<boolean>(`isActiveDex-${deviceId}`);
  }

  setLockTime(lockTime: number): Promise<void> {
    return this._set('lockTime', lockTime);
  }

  getLockTime(): Promise<number | null> {
    return this._get<number>('lockTime');
  }

  getVendorsExchangeKeys(): Promise<any> {
    return this._get('vendor-exchange-keys');
  }

  setPlanogram(deviceId: string, planogram: any): Promise<void> {
    return this._set(`planogram-${deviceId}`, planogram);
  }

  getPlanogram(deviceId: string): Promise<any | null> {
    return this._get<any>(`planogram-${deviceId}`);
  }

  setSettings(deviceId: string, settings: any): Promise<void> {
    return this._set(`settings-${deviceId}`, settings);
  }

  getSettings(deviceId: string): Promise<any | null> {
    return this._get<any>(`settings-${deviceId}`);
  }

  setCompanyProducts(companyProducts: any[]): Promise<void> {
    return this._set('companyProducts', companyProducts);
  }

  getCompanyProducts(): Promise<any[] | null> {
    return this._get<any[]>('companyProducts');
  }

  setBtClassic(classic: boolean): Promise<void> {
    return this._set('classic', classic);
  }

  async getBtClassic(): Promise<boolean> {
    return (await this._get<boolean>('classic')) === true;
  }

  setTallyApiKey(tallyApiKey: string): Promise<void> {
    return this._set('tallyApiKey', tallyApiKey);
  }

  getTallyApiKey(): Promise<string | null> {
    return this._get<string>('tallyApiKey');
  }

  setTallyPassword(tallyPassword: string): Promise<void> {
    return this._set('tallyPassword', tallyPassword);
  }

  getTallyPassword(): Promise<string | null> {
    return this._get<string>('tallyPassword');
  }

  setAutoFundResponse(response: AutoFundResponse | null): Promise<void> {
    return this._set('autoFundResponse', response);
  }

  getAutoFundResponse(): Promise<any> {
    return this._get('autoFundResponse');
  }

  setPayrollResponse(payrollResponse: any): Promise<void> {
    return this._set('payrollResponse', payrollResponse);
  }

  getPayrollResponse(): Promise<any> {
    return this._get('payrollResponse');
  }

  setPrivacyVersionResponse(
    response: PrivacyVersionResponse | null,
  ): Promise<void> {
    return this._set('privacyVersionResponse', response);
  }

  getPrivacyVersionResponse(): Promise<PrivacyVersionResponse | null> {
    return this._get<PrivacyVersionResponse>('privacyVersionResponse');
  }

  setPromotionsResponse(response: AdmPromotionResponse | null): Promise<void> {
    return this._set('promotionsResponse', response);
  }

  getPromotionsResponse(): Promise<AdmPromotionResponse | null> {
    return this._get<AdmPromotionResponse>('promotionsResponse');
  }

  setThresholdsResponse(response: ThresholdsResponse | null): Promise<void> {
    return this._set('thresholdsResponse', response);
  }

  setRewardsResponse(response: RewardStatusModel | null): Promise<void> {
    return this._set('rewardsStatusResponse', response);
  }

  getThresholdsResponse(): Promise<unknown> {
    return this._get('thresholdsResponse');
  }

  getRewardsResponse(): Promise<unknown> {
    return this._get('rewardsStatusResponse');
  }

  setIsDebug(isDebug: boolean) {
    return this._set('isDebug', isDebug);
  }

  getStartupData(): Promise<StartupData> {
    return this._multiGet<StartupData>([
      'autoFundResponse',
      'creditCards',
      'payrollResponse',
      'privacyVersionResponse',
      'promotionsResponse',
      'thresholdsResponse',
      'isDebug',
    ]);
  }

  setVisitedEatItOrDeleteItWelcomeScreen(
    username: string,
    visitedScreen: boolean,
  ): Promise<void> {
    return this._set(
      `visitedEatItOrDeleteItWelcomeScreen=${username}`,
      visitedScreen,
    );
  }

  getVisitedEatItOrDeleteItWelcomeScreen(
    username: string,
  ): Promise<boolean | null> {
    return this._get<boolean>(
      `visitedEatItOrDeleteItWelcomeScreen=${username}`,
    );
  }

  setBluetoothChecked(
    username: string,
    bluetoothChecked: boolean,
  ): Promise<void> {
    return this._set(`bluetoothChecked-${username}`, bluetoothChecked);
  }

  getBluetoothChecked(username: string): Promise<boolean | null> {
    return this._get<boolean>(`bluetoothChecked-${username}`);
  }

  setPinRequired(pinRequired: boolean, username: string) {
    return this._set(`${username}-pinRequired`, pinRequired);
  }

  async getUserStartupData(username: string): Promise<UserStartupData> {
    const storage = await this._multiGet<UserStartupData>([
      `visitedEatItOrDeleteItWelcomeScreen=${username}`,
      `bluetoothChecked-${username}`,
      `${username}-pinRequired`,
    ]);
    return {
      visitedEatItOrDeleteItWelcomeScreen:
        storage[`visitedEatItOrDeleteItWelcomeScreen=${username}`],
      bluetoothChecked: storage[`bluetoothChecked-${username}`],
      pinRequired: storage[`${username}-pinRequired`],
    };
  }

  setFireBaseRemoteConfig(remoteConfig: string): Promise<void> {
    return this._set('firebaseRemoteConfig', remoteConfig);
  }

  getFireBaseRemoteConfig(): Promise<string> {
    return this._get<string>(`firebaseRemoteConfig`);
  }

  setLastSoftAppUpdateAlertShown(currentTimeMillis): Promise<void> {
    return this._set('lastSoftAppUpdateAlertShown', currentTimeMillis);
  }

  getLastSoftAppUpdateAlertShown(): Promise<number> {
    return this._get<number>(`lastSoftAppUpdateAlertShown`);
  }

  getSoftDialogRenderCount(): Promise<number> {
    return this._get<number>(`softDialogRenderCount`);
  }

  resetSoftDialogRenderCount(): Promise<void> {
    return this._set('softDialogRenderCount', 0);
  }

  updateSoftDialogRenderCount(count: number): Promise<void> {
    return this._set('softDialogRenderCount', count);
  }

  setAuthorizedDevices(devices: any[]): Promise<void> {
    return this._set('authorizedDevices', devices);
  }

  setDemo(demo: boolean): Promise<void> {
    return this._set('demo', demo);
  }

  setLocationUserKey(locationUserKey: string): Promise<void> {
    return this._set('locationUserKey', locationUserKey);
  }

  setOrgUserKey(orgUserKey: string): Promise<void> {
    return this._set('orgUserKey', orgUserKey);
  }

  setAccountDetails(data: AccountDetails): Promise<void[]> {
    const storage: AccountDetailsObject = {
      currency: data.currency,
      displayCurrency: data.displayCurrency,
      eatItUrl: data.eatItUrl,
      fundingOptions: data.fundingOptions,
      qrValue: data.qrValue,
      region: data.region,
      rememberUsername: data.rememberUsername,
      showEatItOrDeleteIt: data.showEatItOrDeleteIt,
      username: data.username,
      consumerEngagementEnabled: data.consumerEngagementEnabled,
      isConsumerSendSnackEnabled: data.isConsumerSendSnackEnabled,
      accountBalanceId: data.accountBalanceId,
    };
    const individualStorage = {
      accountId: data.accountId,
      accountBalanceId: data.accountBalanceId,
      authorizedDevices: data.authorizedDevices,
      demo: data.demo,
      locationUserKey: data.locationUserKey,
      orgUserKey: data.orgUserKey,
    };
    return Promise.all([
      this._set('AccountDetails', storage),
      this._multiSet(individualStorage),
    ]);
  }

  async getAccountDetails(): Promise<AccountDetails> {
    let storage = await this._get<AccountDetailsObject>('AccountDetails');

    if (storage === null) {
      // for backwards compatibility
      storage = await this._multiGet<AccountDetailsObject>([
        'currency',
        'displayCurrency',
        'eatItUrl',
        'fundingOptions',
        'qrValue',
        'region',
        'rememberUsername',
        'showEatItOrDeleteIt',
        'username',
        'consumerEngagementEnabled',
        'isConsumerSendSnackEnabled',
        'accountBalanceId',
      ]);

      this._set('AccountDetails', storage);
    }

    const individualStorage = await this._multiGet<AdditionalAccountDetails>([
      'accountId',
      'accountBalanceId',
      'authorizedDevices',
      'demo',
      'locationUserKey',
      'orgUserKey',
    ]);
    return {
      ...storage,
      accountId: individualStorage.accountId,
      accountBalanceId: individualStorage.accountBalanceId,
      authorizedDevices: individualStorage.authorizedDevices,
      demo: individualStorage.demo,
      locationUserKey: individualStorage.locationUserKey,
      orgUserKey: individualStorage.orgUserKey,
    };
  }

  getAccountId(): Promise<string | null> {
    return this._get<string>('accountId');
  }

  setConsumerSendSnackStatus(sendSnackStatus: string) {
    return this._set('isConsumerSendSnackEnabled', sendSnackStatus);
  }

  setAccountInfo(accountInfo: any): Promise<void> {
    return this._set('accountInfo', accountInfo);
  }

  setLocationId(location: string): Promise<void> {
    return this._set('location', location);
  }

  setLocationBroadcastId(locationBroadcastId: string): Promise<void> {
    return this._set('locationBroadcastId', locationBroadcastId);
  }

  setLocationName(location: string): Promise<void> {
    return this._set('locationName', location);
  }

  setLocationType(locationType: string): Promise<void> {
    return this._set('locationType', locationType);
  }

  setVendorsExchangeKeys(keys: any) {
    return this._set('vendor-exchange-keys', keys);
  }

  setGmaAccountDetails(data: GmaAccountDetails): Promise<void[]> {
    const storage: GmaAccountDetailsObject = {
      accountBalance: data.accountBalance,
      accountRewards: data.accountRewards,
      dateAccountUpdated: data.dateAccountUpdated,
      org: data.org,
      city: data.city,
      zip: data.zip,
      showPayroll: data.showPayroll,
      payrollAutoComplete: data.payrollAutoComplete,
      taxId: data.taxId,
      moblicoUrl: data.moblicoUrl,
      moblicoApiKey: data.moblicoApiKey,
      marketCard: data.marketCard,
    };
    const individualStorage = {
      accountInfo: data.accountInfo,
      location: data.locationId,
      locationBroadcastId: data.locationBroadcastId,
      locationName: data.locationName,
      locationType: data.locationType,
      orgName: data.orgName,
      consumerEngagementId: data.consumerEngagementId,
    };
    return Promise.all([
      this._set('GmaAccountDetails', storage),
      this._multiSet(individualStorage),
    ]);
  }

  async getGmaAccountDetails(): Promise<GmaAccountDetails> {
    let storage = await this._get<GmaAccountDetailsObject>('GmaAccountDetails');

    if (storage === null) {
      // for backwards compatibility
      storage = await this._multiGet<GmaAccountDetailsObject>([
        'accountBalance',
        'accountRewards',
        'dateAccountUpdated',
        'org',
        'showPayroll',
        'taxId',
      ]);

      this._set('GmaAccountDetails', storage);
    }

    const individualStorage = await this._multiGet<AdditionalGmaAccountDetails>(
      [
        'accountInfo',
        'location',
        'locationBroadcastId',
        'locationName',
        'locationType',
        'orgName',
        'consumerEngagementId',
      ],
    );
    return {
      ...storage,
      accountInfo: individualStorage.accountInfo,
      locationId: individualStorage.location,
      locationBroadcastId: individualStorage.locationBroadcastId,
      locationName: individualStorage.locationName,
      locationType: individualStorage.locationType,
      orgName: individualStorage.orgName,
      consumerEngagementId: individualStorage.consumerEngagementId,
    };
  }

  setProcessorInfo(data: ProcessorInfo): Promise<void> {
    const storage: ProcessorInfo = {
      processorKey: data.processorKey,
      processorType: data.processorType,
      processorTest: data.processorTest,
      processorUrl: data.processorUrl,
      processorMerchantId: data.processorMerchantId,
    };
    return this._set('ProcessorInfo', storage);
  }

  async getProcessorInfo(): Promise<ProcessorInfo> {
    let storage = await this._get<ProcessorInfo>('ProcessorInfo');

    if (storage !== null) {
      return storage;
    }

    // for backwards compatibility
    storage = await this._multiGet<ProcessorInfo>([
      'processorKey',
      'processorMerchantId',
      'processorTest',
      'processorType',
      'processorUrl',
    ]);
    this.setProcessorInfo(storage);
    return storage;
  }

  setUrlsAndEnv(data: UrlsAndEnv): Promise<void> {
    const storage: UrlsAndEnv = {
      urls: data.urls,
      env: data.env,
    };
    return this._set('UrlsAndEnv', storage);
  }

  async getUrlsAndEnv(): Promise<UrlsAndEnv> {
    let storage = await this._get<UrlsAndEnv>('UrlsAndEnv');

    if (storage !== null) {
      return storage;
    }

    // for backwards compatibility
    storage = await this._multiGet<UrlsAndEnv>(['urls', 'env']);
    this.setUrlsAndEnv(storage);
    return storage;
  }
  setOffersNote(offerNoteInfo: AccountOfferNoteToggles): Promise<void> {
    return this._set('toggleOfferNotes', offerNoteInfo);
  }

  getOffersNote(): Promise<AccountOfferNoteToggles> {
    return this._get<AccountOfferNoteToggles>(`toggleOfferNotes`);
  }

  setIsLoggingInNow(logInNow: boolean): Promise<void> {
    return this._set('loggingInNow', logInNow);
  }

  getIsLoggingInNow(): Promise<boolean | null> {
    return this._get('loggingInNow');
  }
  /** Set payroll import data */
  setPayrollImportData(
    data: {importId: string; locationId: string} | null,
  ): Promise<void> {
    return this._set('payrollImportData', data); // Store the object
  }
  removePayrollImportData(): Promise<void> {
    return this._delete('payrollImportData');
  }
  getPayrollImportData(): Promise<{
    importId: string;
    locationId: string;
  } | null> {
    return this._get<{importId: string; locationId: string} | null>(
      'payrollImportData',
    );
  }
  setSendaSnackArray(snacks: GiftItemType[]): Promise<void> {
    return this._set('sendASnackArray', snacks);
  }
  getSendaSnackArray(): Promise<GiftItemType[] | null> {
    return this._get<GiftItemType[]>('sendASnackArray');
  }
  setReferralId(referralId: string | null): Promise<void> {
    return this._set('referralId', referralId);
  }
  getReferralId(): Promise<string | null> {
    return this._get<string | null>('referralId');
  }
}

export default new PersistentStore();
