import * as React from 'react';
import Svg, {Path, Defs, LinearGradient, Stop} from 'react-native-svg';
const PayRocketIcon = (props) => (
  <Svg width={348} height={196} viewBox="0 0 348 196" fill="none" {...props}>
    <Path
      d="M208.405 74.9934L195.998 62.571C185.731 66.5672 172.695 73.2965 161.771 84.2344C160.013 85.9948 158.327 87.757 156.716 89.5173C151.699 94.9672 147.338 100.428 143.773 105.609L165.418 127.282C172.269 122.554 179.592 116.446 186.767 109.261C197.691 98.3228 204.414 85.2707 208.403 74.9897L208.405 74.9934ZM182.602 88.4067C178.852 84.6518 178.843 78.5613 182.593 74.8083C186.343 71.0534 192.435 71.0534 196.183 74.8083C199.933 78.5631 199.924 84.6536 196.174 88.4067C192.424 92.1615 186.352 92.1615 182.602 88.4067ZM213.616 57.3516C213.616 57.3516 208.099 58.2971 200.062 61.0774L209.895 70.9227C212.672 62.8759 213.616 57.3516 213.616 57.3516ZM194.476 86.7026C191.665 89.5173 187.113 89.5173 184.302 86.7026C181.491 83.8878 181.486 79.3235 184.295 76.5087C187.106 73.694 191.672 73.694 194.483 76.5087C197.294 79.3235 197.287 83.8878 194.476 86.7026ZM125.452 105.656L146.597 84.4849L155.039 87.8405C150.022 93.2903 145.662 98.7511 142.096 103.932L125.45 105.656H125.452ZM167.064 128.931C172.238 125.362 177.692 120.997 183.135 115.972L186.486 124.425L165.342 145.597L167.064 128.93V128.931ZM141.172 149.355L138.395 146.575L159.843 125.1L162.619 127.881L141.172 149.355ZM129.137 137.305L126.36 134.525L147.808 113.05L150.584 115.83L129.137 137.305ZM143.795 133.752L141.018 130.971L153.363 118.611L156.14 121.391L143.795 133.752ZM133.612 123.554L130.835 120.774L143.18 108.413L145.957 111.193L133.612 123.554Z"
      fill="url(#paint0_linear_5121_42355)"
    />
    <Path
      d="M208.361 74.972L195.955 62.5496C185.687 66.5458 172.651 73.2752 161.728 84.2131C159.969 85.9734 158.284 87.7356 156.672 89.496C151.655 94.9459 147.295 100.407 143.729 105.588L165.374 127.26C172.226 122.533 179.548 116.424 186.724 109.239C197.648 98.3015 204.37 85.2493 208.36 74.9684L208.361 74.972ZM182.559 88.3853C178.809 84.6305 178.799 78.54 182.55 74.7869C186.3 71.0321 192.391 71.0321 196.14 74.7869C199.89 78.5418 199.881 84.6323 196.131 88.3853C192.381 92.1402 186.309 92.1402 182.559 88.3853ZM213.572 57.332C213.572 57.332 208.055 58.2775 200.018 61.0578L209.851 70.9032C212.628 62.8563 213.572 57.332 213.572 57.332ZM194.43 86.683C191.619 89.4978 187.068 89.4978 184.257 86.683C181.446 83.8683 181.44 79.304 184.25 76.4892C187.061 73.6744 191.627 73.6744 194.438 76.4892C197.249 79.304 197.242 83.8683 194.43 86.683ZM125.409 105.637L146.553 84.4653L154.996 87.8209C149.979 93.2708 145.618 98.7316 142.053 103.913L125.407 105.637H125.409ZM167.02 128.91C172.195 125.34 177.649 120.976 183.091 115.951L186.443 124.404L165.298 145.576L167.02 128.908V128.91Z"
      fill="#066DFF"
    />
    <Path
      d="M41.5371 56.7963L34.655 49.5207L24.9817 52.0923L32.248 45.2014L29.6797 35.5176L36.5618 42.7914L46.2333 40.2198L38.9688 47.1106L41.5371 56.7963Z"
      fill="#066DFF"
    />
    <Path
      d="M282.676 132.585L275.794 125.309L266.12 127.881L273.387 120.99L270.818 111.306L277.7 118.58L287.374 116.008L280.107 122.899L282.676 132.585Z"
      fill="#066DFF"
    />
    <Path
      d="M58.8319 172.511L51.9499 165.235L42.2766 167.807L49.5429 160.916L46.9746 151.232L53.8566 158.506L63.5299 155.934L56.2636 162.825L58.8319 172.511Z"
      fill="#066DFF"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_5121_42355"
        x1={125.452}
        y1={103.353}
        x2={176.962}
        y2={103.353}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#066DFF" />
        <Stop offset={1} stopColor="#58C6BB" />
      </LinearGradient>
    </Defs>
  </Svg>
);
export default PayRocketIcon;
