import Styles from 'src/components/Styles';

export function getStyleWithColorOnly(): {[index: string]: string} {
  return {
    backgroundColor: '#006DFF26',
    color: Styles.tabBarTextColor,
    allReadTextUnderLine: Styles.Colors.PayNew.primary01,
  };
}

export function snackBarColorDescriptor(): string {
  return Styles.Colors.PayNew.primary01;
}

export function getToastTextStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    allReadText: {
      fontSize: Styles.Fonts.f1,
      paddingTop: Styles.Spacing.m1,
      color: Styles.Colors.PayNew.primary01,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontWeight: '700',
    },
  };
}

export function getRightReadMarkIconColor(): string {
  return Styles.Colors.Refive.activeFooterBlue;
}

export function snackBarFontFamily(): string {
  return Styles.FontFamily.aeonikRegular;
}

export default {
  getStyleWithColorOnly,
  snackBarColorDescriptor,
  getToastTextStyleDescriptor,
  snackBarFontFamily,
};
