import RedesignDescriptor from './inbox-redesign';
import RefiveDescriptor from './inbox-refive';
import CanteenDescriptor from './inbox-canteen';
import CompanyKitchenDescriptor from './inbox-companykitchen';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import Deal from 'src/models/Moblico/Deal';

const typeDescriber = {
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.companykitchen]: CompanyKitchenDescriptor,
  [BuildTypes.refive]: RefiveDescriptor,
};

export interface DealDescriberData {
  styles: {[index: string]: {[index: string]: number | string}};
  deal: Deal;
}

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
