import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const RewardRedesignHome = () => (
  <Svg width={50} height={50} viewBox="0 0 50 50" fill="none">
    <Path
      d="M0 25C0 11.193 11.193 0 25 0s25 11.193 25 25-11.193 25-25 25S0 38.807 0 25z"
      fill="#066DFF"
    />
    <Path
      d="M21.93 16.225L23.56 19h-3.435a1.875 1.875 0 110-3.75h.103c.699 0 1.35.37 1.702.975zm-5.93.9c0 .675.164 1.313.45 1.875H14.5c-.83 0-1.5.67-1.5 1.5v3c0 .83.67 1.5 1.5 1.5h21c.83 0 1.5-.67 1.5-1.5v-3c0-.83-.67-1.5-1.5-1.5h-1.95a4.125 4.125 0 00-3.675-6h-.103a4.23 4.23 0 00-3.642 2.081L25 17.008l-1.13-1.922A4.225 4.225 0 0020.228 13h-.103A4.125 4.125 0 0016 17.125zm15.75 0c0 1.036-.84 1.875-1.875 1.875H26.44l1.63-2.775a1.975 1.975 0 011.702-.975h.103c1.036 0 1.875.84 1.875 1.875zM14.5 26.5v8.25A2.25 2.25 0 0016.75 37h6.75V26.5h-9zm12 10.5h6.75a2.25 2.25 0 002.25-2.25V26.5h-9V37z"
      fill="#FEE0E6"
    />
  </Svg>
);
export default RewardRedesignHome;
